import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import {store} from '../../store';

export const saveCurrentAdminRole = payload => ({
  type: adminActionTypes.SAVE_CURRENT_ADMINROLE,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_ADMINROLE_CURRENT_PAGE,
  payload,
});

export const getAdminRoleById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/adminrole/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentAdminRole(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewAdminRole = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/adminrole/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503 && response.type !== 'cors') {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentAdminRole(data));
          dispatch(getAllAdminRoles());
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeAdminRole = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/adminrole/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getAdminRoleById(body.AdminRole_ID));
          dispatch(getAllAdminRoles(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllAdminRoles = payload => ({
  type: adminActionTypes.SAVE_ALL_ADMINROLES,
  payload,
});


export const saveEntireAdminRoles = payload => ({
  type: adminActionTypes.SAVE_ENTIRE_ADMINROLES,
  payload,
});

export const saveAdminRoleSortElement = payload => ({
  type: adminActionTypes.SAVE_ADMINROLE_SORT_ELEMENT,
  payload,
});

export const saveAdminRoleSortByDirection = payload => ({
  type: adminActionTypes.SAVE_ADMINROLE_SORT_BY_DIRECTION,
  payload,
});

export const saveAdminRolePaginationData = payload => ({
  type: adminActionTypes.SAVE_ADMINROLE_PAGINATION_DATA,
  payload,
});

export const getAllAdminRoles = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: ROWS_PER_PAGE,
            Page: store.getState().adminRole.currentPage,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/adminrole/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllAdminRoles(data.Data));
        dispatch(saveAdminRolePaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const getEntireAdminRoles = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: 10000,
            Page: 1,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/adminrole/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveEntireAdminRoles(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteAdminRole = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/adminrole/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else 
            dispatch(getAllAdminRoles(null));
        })
        .catch(error => console.log(error));
    }
  };
};
