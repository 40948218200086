
export const createParticipant = (email, role, tripID, type, message) => {
  let Initials = email && email.substring(0, 2);
  let data = {
    InviteMessage: message || '',
    InviteeEmail: email,
    ParticipantStatusName: null,
    ParticipantStatus_ID: "1",
    PlanningStatusName: null,
    PlanningStatus_ID: null,
    TripParticipantDisplayName: email,
    TripParticipantInitials: Initials,
    TripParticipantUserRole_ID: role,
    TripParticipantUser_ID: email,
    Trip_ID: tripID,
    type: type,
  }
  return data;
}

export const createProxy = (email, role, tripID, type, message) => {
  let Initials = email && email.substring(0, 2);
  let data = {
    InviteMessage: message || '',
    InviteeEmail: email,
    TripProxyAcceptanceStatus: '1',
    TripProxyDisplayName: email,
    TripProxyInitials: Initials,
    TripProxyUserRole_ID: role,
    TripProxyUser_ID: email,
    Trip_ID: tripID,
    type: type,
  }
  return data;
}


export const createOrganizer = (email, role, tripID, type, message) => {
  let Initials = email && email.substring(0, 2);
  let data = {
    InviteMessage: message || '',
    InviteeEmail: email,
    TripOrganizerAcceptanceStatus: "1",
    TripOrganizerDisplayName: email,
    TripOrganizerInitials: Initials,
    TripOrganizerUserRole_ID: role,
    TripOrganizerUser_ID: email,
    Trip_ID: tripID,
    type: type,
  }
  return data;
}