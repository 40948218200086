export const columnsTwo = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 80,
    align: 'center',
    type: 'StartDate',
  },
  { id: 'name', label: 'Trip Title', minWidth: 80, type: 'Name' },
  {
    id: 'owner',
    label: 'Owner',
    minWidth: 80,
    align: 'left',
    type: 'OwnerUser_ID',
  },
  {
    id: 'participants',
    label: 'Participants',
    minWidth: 80,
    align: 'left',
    type: 'Participants',
  },
  {
    id: 'organizers',
    label: 'Organizers',
    minWidth: 80,
    align: 'left',
    type: 'DisplayName',
  },
  {
    id: 'proxies',
    label: 'Proxies',
    minWidth: 80,
    align: 'left',
    type: 'DisplayName',
  },
  {
    id: 'message',
    label: 'Message',
    minWidth: 80,
    align: 'left',
    type: 'Message',
  },
  {
    id: 'numTrippets',
    label: '#Trippets',
    minWidth: 80,
    align: 'center',
    type: 'NumTrippets',
  },
  {
    id: 'lastChanged',
    label: 'Last Changed',
    minWidth: 80,
    align: 'center',
    type: 'ModifiedDate',
  },
];
