import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DatePicker from "../DatePicker/DatePicker";
import TimePicker from "../TimePicker/TimePicker";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Autocomplete } from "@react-google-maps/api";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
// import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

const SmallForm = ({
  classes,
  values,
  t,
  handleInputs,
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  startTime,
  handleStartTime,
  endTime,
  handleEndTime,
  onPlaceChangedFrom,
  onLoadFrom,
  onPlaceChangedTo,
  onLoadTo,
  renderDistance,
  renderTrippetOwner,
  tripTotalDays,
  startTrippetDay,
  endTrippetDay,
  handleParticipantsModalOpen,
  handleResponsibleToModalOpen,
  renderParticipants,
  handleRefresh,
  isEditMode,
  disabledRefreshButton,
  renderDistanceIcon,
  renderTripNameErrorMessage,
  renderTypeErrorMessage,
  isAllDay,
  handleIsAllDayCheckBox,
  disabledFromField,
  showTimeZones,
  handleStartDateCalendar,
  renderTrippetTypes,
  renderTrippetStatus,
  renderResponsible,
  handleInvitationalModalOpen,
  isFromFieldLeft,
  handleSwapDestinationFields,
  parentsTrippet,
  getTheParentName,
}) => (
  <Fragment>
    <Grid
      item
      xs={12}
      md={9}
      container
      direction="column"
      spacing={2}
      className={classes.smallFormLeft}
    >
      <Grid item xs={12} md={12} container alignItems="center">
        <Grid item xs={12} md={4}>
          <TextField
            variant="outlined"
            name="trippetName"
            value={values.trippetName}
            placeholder={t("Trippet Name")}
            label={t("Trippet Name")}
            onChange={handleInputs}
            autoFocus
            required
            className={classes.trippetName}
            id="tripName"
          />
          {renderTripNameErrorMessage}
        </Grid>
        <Grid item xs={12} md={2} className={classes.dayRange}>
          Day {`${startTrippetDay} to ${endTrippetDay}`} of {tripTotalDays}
        </Grid>
        <Grid item xs={12} md={3}/>
        <Grid item xs={12} md={3}>
          {" "}
          <FormControl variant="outlined" className={classes.statusTrippet}>
            <InputLabel id="demo-simple-select-outlined-label">
              {t("Status")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label={t("Status")}
              name="status"
              value={values.status}
              onChange={handleInputs}
            >
              <MenuItem value="">{t("Status")}</MenuItem>
              {renderTrippetStatus}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} container direction="column" spacing={2}>
        <Grid item xs={12} container direction="row" alignItems="center">
          <Grid item xs={12} md={2}>
            {values.isChildOf ? <span>{t('Is a grouped under')} <b>{getTheParentName(values.isChildOf)}</b></span> : <span>{t('Has a group')}</span>}
          </Grid>
          <Grid item xs={12} md={2}>
            {showTimeZones && (
              <FormControl
                variant="outlined"
                className={classes.timeZoneTrippet}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("Time Zone")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label={t("Start Time Zone")}
                  name="startTimeZone"
                  value={values.startTimeZone}
                  onChange={handleInputs}
                >
                  <MenuItem value="Europe/Munich">Europe/Munich</MenuItem>
                  <MenuItem value="Europe/Belgrade">Europe/Belgrade</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} md={1}>
            <FormControlLabel
              className={classes.allDayLabel}
              control={
                <Checkbox
                  checked={isAllDay}
                  onChange={handleIsAllDayCheckBox}
                  name="privateTrip"
                  color="primary"
                />
              }
              label={t("All day")}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            {showTimeZones && (
              <FormControl
                variant="outlined"
                className={classes.timeZoneTrippet}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("Time Zone")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label={t("End Time Zone")}
                  name="endTimeZone"
                  value={values.endTimeZone}
                  onChange={handleInputs}
                >
                  <MenuItem value="Europe/Munich">Europe/Munich</MenuItem>
                  <MenuItem value="Europe/Belgrade">Europe/Belgrade</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={4} className={classes.trippetDistance}>
            {!disabledFromField && renderDistanceIcon()}
            {!disabledFromField && `Distance: ${renderDistance}`}
            {!disabledFromField && isEditMode && (
              <IconButton
                onClick={handleRefresh}
                color="primary"
                disabled={disabledRefreshButton}
              >
                <RefreshIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          spacing={1}
          justify="flex-start"
        >
          <Grid item xs={12} md={2}>
            <FormControl
              variant="outlined"
              className={classes.typeTrippet}
              required
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {t("Trippet type")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label={t("Trippet type")}
                name="type"
                value={values.type}
                onChange={handleInputs}
              >
                {renderTrippetTypes}
              </Select>
            </FormControl>
            {renderTypeErrorMessage}
          </Grid>
          <Grid item xs={12} md={2} className={classes.dateContainers}>
            <DatePicker
              label="Start Date"
              selectedDate={startDate}
              handleDateChange={handleStartDateCalendar}
            />
          </Grid>
          <Grid item xs={12} md={1} className={classes.timeContainers}>
            {!isAllDay && (
              <TimePicker
                label="Start Time"
                selectedDate={startTime}
                handleDateChange={handleStartTime}
              />
            )}
          </Grid>
          <Grid item xs={12} md={2} className={classes.dateContainers}>
            <DatePicker
              label="End Date"
              selectedDate={endDate}
              handleDateChange={handleEndDate}
            />
          </Grid>
          <Grid item xs={12} md={1} className={classes.timeContainers}>
            {!isAllDay && (
              <TimePicker
                label="End Time"
                selectedDate={endTime}
                handleDateChange={handleEndTime}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3} className={classes.destinationFields}>
            {!disabledFromField && (
              <Autocomplete
                onLoad={onLoadFrom}
                onPlaceChanged={onPlaceChangedFrom}
              >
                <TextField
                  variant="outlined"
                  name="from"
                  value={values.from}
                  placeholder={t("From")}
                  label={t("From")}
                  onChange={handleInputs}
                  autoFocus
                  id="fromTrippet"
                />
              </Autocomplete>
            )}
          </Grid>
          {/* <Grid item className={classes.swapIcon}>
              {!disabledFromField && <SwapHorizIcon onClick={handleSwapDestinationFields} />}
            </Grid> */}
          <Grid item xs={12} md={3} className={classes.destinationFields}>
            <Autocomplete onLoad={onLoadTo} onPlaceChanged={onPlaceChangedTo}>
              <TextField
                variant="outlined"
                name="to"
                value={values.to}
                placeholder={t("To")}
                label={t("To")}
                onChange={handleInputs}
                autoFocus
                id="toTrippet"
              />
            </Autocomplete>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid
      item
      xs={12}
      md={3}
      container
      direction="column"
      spacing={2}
      className={classes.smallFormRight}
    >
      <Grid item xs={12} container direction="row" alignItems="center">
        <Grid item xs={12} md={5} container alignItems="center">
          <Grid item xs={12} className={classes.title}>
            {t("Responsibles")}
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              <AvatarGroup max={2} className={classes.avatarGroup}>
                {renderResponsible}
              </AvatarGroup>
            </Grid>
            <Grid item xs={12}>
              <Fab
                variant="extended"
                color="primary"
                className={classes.addParticipants}
                onClick={handleResponsibleToModalOpen}
              >
                <AddIcon /> / <RemoveIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} container></Grid>
        <Grid item xs={12} md={2} container alignItems="center">
          <Grid item xs={12} className={classes.title}>
            {t("Creator")}
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Avatar className={classes.avatar}>{renderTrippetOwner()}</Avatar>
            </Grid>
            <Grid item xs={12} className={classes.placeholder}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container direction="row" alignItems="center">
        <Grid item xs={12} md={6}>
          <Grid item xs={12} className={classes.title}>
            {t("Participants")}
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              <AvatarGroup max={2} className={classes.avatarGroup}>
                {renderParticipants}
              </AvatarGroup>
            </Grid>
            <Grid item xs={12}>
              <Fab
                variant="extended"
                color="primary"
                className={classes.addParticipants}
                onClick={handleParticipantsModalOpen}
              >
                <AddIcon /> / <RemoveIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            color="primary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={handleInvitationalModalOpen}
          >
            Invite new users
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Fragment>
);

export default SmallForm;
