export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const LOGOUT = "LOGOUT";
export const SAVE_GOOGLE_OBJECT = "SAVE_GOOGLE_OBJECT";
export const SAVE_ALL_USER_ROLES = "SAVE_ALL_USER_ROLES";
export const SAVE_DEPARTMENTS = "SAVE_DEPARTMENTS";
export const SAVE_ALL_COUNTIRES = "SAVE_ALL_COUNTIRES";
export const SAVE_ALL_CURRENCIES = "SAVE_ALL_CURRENCIES";
export const SHOW_PERMISSION_TABLE = "SHOW_PERMISSION_TABLE";
export const HIDE_PERMISSION_TABLE = "HIDE_PERMISSION_TABLE";
export const SHOW_BUDDY_TABLE = "SHOW_BUDDY_TABLE";
export const HIDE_BUDDY_TABLE = "HIDE_BUDDY_TABLE";
export const SAVE_CUSTOMER_LOGO = "SAVE_CUSTOMER_LOGO";
export const CLEAR_CUSTOMER_LOGO = "CLEAR_CUSTOMER_LOGO";
// user actions
export const SAVE_USER = "SAVE_USER";
export const SAVE_ALL_USERS = "SAVE_ALL_USERS";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCEEDED = "LOGIN_SUCCEEDED";

// trip actions
export const SAVE_CURRENT_TRIP = "SAVE_CURRENT_TRIP";
export const GET_TRIP_BY_ID = "GET_TRIP_BY_ID";
export const SAVE_ALL_TRIPS = "SAVE_ALL_TRIPS";
export const SAVE_ENTIRE_TRIPS = "SAVE_ENTIRE_TRIPS";
export const SAVE_ALL_TRIP_INVITATIONS = "SAVE_ALL_TRIP_INVITATIONS";
export const SAVE_ALL_TRIP_RECOMMENDATIONS = "SAVE_ALL_TRIP_RECOMMENDATIONS";
export const SAVE_ALL_SENT_TRIP_RECOMMENDATIONS =
  "SAVE_ALL_SENT_TRIP_RECOMMENDATIONS";
export const SAVE_TOTAL_TRIP_RECOMMENDATIONS =
  "SAVE_TOTAL_TRIP_RECOMMENDATIONS";
export const SAVE_TRIP_INVITATIONAL_TOTAL_NUMBER =
  "SAVE_TRIP_INVITATIONAL_TOTAL_NUMBER";
export const SAVE_TRIP_RECIVED_INVITATIONAL = "SAVE_TRIP_RECIVED_INVITATIONAL";
export const SAVE_TRIP_SENT_INVITATIONAL = "SAVE_TRIP_SENT_INVITATIONAL";
export const SAVE_TRIP_PAGINATION_DATA = "SAVE_TRIP_PAGINATION_DATA";
export const SAVE_TRIP_SORT_ELEMENT = "SAVE_TRIP_SORT_ELEMENT";
export const SAVE_TRIP_SORT_BY_DIRECTION = "SAVE_TRIP_SORT_BY_DIRECTION";
export const SAVE_TRIP_INVITATION_SORT_ELEMENT =
  "SAVE_TRIP_INVITATION_SORT_ELEMENT";
export const SAVE_TRIP_INVITATION_SORT_BY_DIRECTION =
  "SAVE_TRIP_INVITATION_SORT_BY_DIRECTION";
export const SAVE_FILTER_TRIP_RESULTS = "SAVE_FILTER_TRIP_RESULTS";
export const CLEAR_FILTER_TRIP_RESULTS = "CLEAR_FILTER_TRIP_RESULTS";
export const SAVE_TRIP_USED_FILTERS = "SAVE_TRIP_USED_FILTERS";
export const SAVE_TRIP_SENT_INVITATION_PAGINATION =
  "SAVE_TRIP_SENT_INVITATION_PAGINATION";
export const SAVE_TRIP_RECIEVED_INVITATION_PAGINATION =
  "SAVE_TRIP_RECIEVED_INVITATION_PAGINATION";
export const SAVE_TRIP_SENT_RECOMMENDATION_PAGINATION =
  "SAVE_TRIP_SENT_RECOMMENDATION_PAGINATION";
export const SAVE_TRIP_RECIEVED_RECOMMENDATION_PAGINATION =
  "SAVE_TRIP_RECIEVED_RECOMMENDATION_PAGINATION";

// trippet actions
export const SAVE_UPLOADED_FILE = "SAVE_UPLOADED_FILE";
export const SAVE_ALL_TRIPPETS = "SAVE_ALL_TRIPPETS";
export const SAVE_CURRENT_TRIPPET = "SAVE_CURRENT_TRIPPET";
export const CLEAR_ALL_TRIPPETS = "CLEAR_ALL_TRIPPETS";
export const CLEAR_FILES = "CLEAR_FILES";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const SHOW_TRIPPET_FORM = "SHOW_TRIPPET_FORM";
export const HIDE_TRIPPET_FORM = "HIDE_TRIPPET_FORM";
export const SAVE_PICKED_TIME_FROM_CALENDAR = "SAVE_PICKED_TIME_FROM_CALENDAR";
export const SAVE_CALENDAR_INSTANCE = "SAVE_CALENDAR_INSTANCE";
export const SAVE_ALL_MY_TRIPPETS = "SAVE_ALL_MY_TRIPPETS";
export const SAVE_ALL_TRIPPETS_INVITATIONS = "SAVE_ALL_TRIPPETS_INVITATIONS";
export const SAVE_ALL_TRIPPET_RECOMMENDATIONS =
  "SAVE_ALL_TRIPPET_RECOMMENDATIONS";
export const SAVE_ALL_SENT_TRIPPET_RECOMMENDATIONS =
  "SAVE_ALL_SENT_TRIPPET_RECOMMENDATIONS";
export const SAVE_TRIPPET_TYPES = "SAVE_TRIPPET_TYPES";
export const SAVE_TRIPPET_STATUS = "SAVE_TRIPPET_STATUS";
export const SAVE_ALL_TRIPPETS_PICTURES = "SAVE_ALL_TRIPPETS_PICTURES";
export const SAVE_ALL_TRIPPETS_DOCUMENTS = "SAVE_ALL_TRIPPETS_DOCUMENTS";
export const SAVE_TOTAL_TRIPPET_RECOMMENDATIONS_NUMBER =
  "SAVE_TOTAL_TRIPPET_RECOMMENDATIONS_NUMBER";
export const CLEAR_ALL_CURRENT_TRIPPET_NOTES =
  "CLEAR_ALL_CURRENT_TRIPPET_NOTES";
export const SAVE_ALL_CURRENT_TRIPPET_NOTES = "SAVE_ALL_CURRENT_TRIPPET_NOTES";
export const SAVE_TRIPPET_PAGINATION_DATA = "SAVE_TRIPPET_PAGINATION_DATA";
export const SAVE_TRIPPET_SORT_ELEMENT = "SAVE_TRIPPET_SORT_ELEMENT";
export const SAVE_TRIPPET_SORT_BY_DIRECTION = "SAVE_TRIPPET_SORT_BY_DIRECTION";
export const SAVE_MY_TRIPPETS_PAGINATION_DATA =
  "SAVE_MY_TRIPPETS_PAGINATION_DATA";
export const SAVE_MY_TRIPPET_SORT_ELEMENT = "SAVE_TRIPPET_SORT_ELEMENT";
export const SAVE_MY_TRIPPET_SORT_BY_DIRECTION =
  "SAVE_TRIPPET_SORT_BY_DIRECTION";
export const SAVE_TRIPPET_PAGE_PAGIONATION = "SAVE_TRIPPET_PAGE_PAGIONATION";
export const SAVE_MY_TRIPPET_PAGE_PAGIONATION =
  "SAVE_MY_TRIPPET_PAGE_PAGIONATION";
export const SAVE_FILTER_TRIPPET_RESULTS = "SAVE_FILTER_TRIPPET_RESULTS";
export const CLEAR_FILTER_TRIPPET_RESULTS = "CLEAR_FILTER_TRIPPET_RESULTS";
export const SAVE_MY_TRIPPET_SENT_INVITATION =
  "SAVE_MY_TRIPPET_SENT_INVITATION";
export const SAVE_MY_TRIPPET_RECEIVED_INVITATION =
  "SAVE_MY_TRIPPET_RECEIVED_INVITATION";
export const SAVE_TRIPPET_INVITATION_SORT_ELEMENT =
  "SAVE_TRIPPET_INVITATION_SORT_ELEMENT";
export const SAVE_TRIPPET_INVITATION_SORT_BY_DIRECTION =
  "SAVE_TRIPPET_INVITATION_SORT_BY_DIRECTION";
export const SAVE_SINGLE_TRIPPET_CHAT = "SAVE_SINGLE_TRIPPET_CHAT";
export const SAVE_TRIPPET_USED_FILTERS = "SAVE_TRIPPET_USED_FILTERS";
export const SAVE_TRIPPET_SENT_INVITATION_PAGINATION =
  "SAVE_TRIPPET_SENT_INVITATION_PAGINATION";
export const SAVE_TRIPPET_RECIEVED_INVITATION_PAGINATION =
  "SAVE_TRIPPET_RECIEVED_INVITATION_PAGINATION";
export const SAVE_TRIPPET_SENT_RECOMMENDATION_PAGINATION =
  "SAVE_TRIPPET_SENT_RECOMMENDATION_PAGINATION";
export const SAVE_TRIPPET_RECIEVED_RECOMMENDATION_PAGINATION =
  "SAVE_TRIPPET_RECIEVED_RECOMMENDATION_PAGINATION";
export const SAVE_TRIPPET_PRIVATE_DOCUMENTS = "SAVE_TRIPPET_PRIVATE_DOCUMENTS";
export const SAVE_TRIPPET_PUBLIC_DOCUMENTS = "SAVE_TRIPPET_PUBLIC_DOCUMENTS";
export const SAVE_TRIPPET_IMAGES = "SAVE_TRIPPET_IMAGES";
export const CLEAR_TRIPPET_IMAGES = "CLEAR_TRIPPET_IMAGES";
export const SAVE_SELECTED_IMAGE = "SAVE_SELECTED_IMAGE";
export const CLEAR_SELECTED_IMAGE = "CLEAR_SELECTED_IMAGE";
export const SAVE_TRIPPET_CATEGORY = "SAVE_TRIPPET_CATEGORY";
export const SVE_TRIP_TOTAL_PRICE = "SVE_TRIP_TOTAL_PRICE";
export const SAVE_PARENT_TRIPPET_WITH_CHILDREN = "SAVE_PARENT_TRIPPET_WITH_CHILDREN";
export const SAVE_PARENT_TRIPPET_CHILDREN = "SAVE_PARENT_TRIPPET_CHILDREN";
export const CLEAR_PARENT_TRIPPET_CHILDREN = "CLEAR_PARENT_TRIPPET_CHILDREN";
export const SAVE_TRIPPET_CHILDREN_STATUS = "SAVE_TRIPPET_CHILDREN_STATUS";
export const CLEAR_TRIPPET_CATEGORIES_AND_TYPES = "CLEAR_TRIPPET_CATEGORIES_AND_TYPES";

// map actions
export const CHANGE_MAP_ZOOM = "CHANGE_MAP_ZOOM";
export const SAVE_PATHS = "SAVE_PATHS";
export const SAVE_MARKERS = "SAVE_MARKERS";

// program settings actions
export const CHANGE_PARTICIPANTS_SORTING_CRITERIA =
  "CHANGE_PARTICIPANTS_SORTING_CRITERIA";
export const CHANGE_CALENDAR_VIEW = "CHANGE_CALENDAR_VIEW";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_COCKPIT_VIEW = "CHANGE_COCKPIT_VIEW";
export const CHANGE_VISIBLE_WEEKS_COUNT = "CHANGE_VISIBLE_WEEKS_COUNT";
export const SET_START_WEEK_MONDAY = "SET_START_WEEK_MONDAY";
export const SET_WORK_WEEK = "SET_WORK_WEEK";
export const SET_TASK_VIEW = "SET_TASK_VIEW";
export const SET_CALENDAR_VIEW_NAME = "SET_CALENDAR_VIEW_NAME";

// profile
export const SAVE_UPLOADED_PROFILE_PICTURE = "SAVE_UPLOADED_PROFILE_PICTURE";
export const DELETE_UPLOADED_PROFILE_PICTURE =
  "DELETE_UPLOADED_PROFILE_PICTURE";
export const SAVE_USER_INFORMATION = "SAVE_USER_INFORMATION";

// profile
export const SAVE_USER_NOTIFICATION = "SAVE_USER_NOTIFICATION";
export const SAVE_USER_NOTIFICATION_INTERVAL =
  "SAVE_USER_NOTIFICATION_INTERVAL";
export const SAVE_USER_NON_SEEN_NOTIFICATION =
  "SAVE_USER_NON_SEEN_NOTIFICATION";
export const SAVE_NOTIFICATION_FILTERS = "SAVE_NOTIFICATION_FILTERS";
export const SAVE_TRIP_LIST_NOTIFICATION = " SAVE_TRIP_LIST_NOTIFICATION";
export const SAVE_TRIPPET_LIST_NOTIFICATION = " SAVE_TRIPPET_LIST_NOTIFICATION";
export const SAVE_CREATOR_LIST_NOTIFICATION = " SAVE_CREATOR_LIST_NOTIFICATION";
export const SAVE_NOTIFICATION_DATA = "SAVE_NOTIFICATION_DATA";
export const SAVE_SINGLE_TRIPPET_INFO = "SAVE_SINGLE_TRIPPET_INFO";
export const SAVE_SINGLE_TRIP_INFO = "SAVE_SINGLE_TRIP_INFO";
