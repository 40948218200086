import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PlaceholderImage from '../../assets/placeholder-image.png';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      position: 'relative',
      width: 380,
      height: 300,
    },
  },
  deleteIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.5);',
    borderRadius: 5,
    transition: 'all .7s',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1);',
    },
  },
  previewImage: {
    width: '100%',
    height: '100%',
  },
}));

const PreviewPicture = ({
  pictureUrl,
  setValues,
  values,
  dispatch,
  deleteProfileImage,
}) => {
  const classes = useStyles();

  const deleteImage = () => {
    setValues({
      ...values,
      pictureUrl: '',
      pictureFile: '',
    });
    dispatch(deleteProfileImage());
  };

  const renderImage = pictureUrl ? (
    <img src={pictureUrl} alt="some alt" className={classes.previewImage} />
  ) : (
    <img
      alt="placeholder"
      src={PlaceholderImage}
      className={classes.previewImage}
    />
  );

  return (
    <div className={classes.previewContainer}>
      <div>
        {renderImage}
        <div className={classes.deleteIcon} onClick={deleteImage}>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default PreviewPicture;
