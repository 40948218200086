import { BASE_URL } from "../../config";
import React from "react";

export const getTrippetTypeIcon = (data, list, classes) => {
  if (list && list.length > 0) {
    let element = list.find(type => data === type.TrippetTypeLook_ID);
    if(element){
      if (element.Icon) {
        return (
          <div className={classes.trippetTypeName}>
            <img
              className={classes.trippetTypeIcon}
              src={`${BASE_URL}${element.Icon}`}
              alt={element.Name}
            />
            {element.Name}
          </div>
        );
      } else {
        return (
          <div className={classes.trippetTypeName}>
            <span className="material-icons">{element.MaterialIO}</span>{" "}
            {element.Name}
          </div>
        );
      }
    }
  }
};

export const getTrippetPlanningStatusIcon = (data, list, classes) => {
  if (list) {
    let element = list.find(type => data === type.PlanningStatus_ID);
    if (element) {
      if (element.Icon && element.Icon !== "") {
        return (
          <div className={classes.trippetTypeName}>
            <img
              className={classes.trippetTypeIcon}
              src={`${BASE_URL}${element.Icon}`}
              alt={element.Name}
            />
            {element.Name}
          </div>
        );
      } else {
        return (
          <div className={classes.trippetTypeName}>
            <span
              className="material-icons"
              style={{ color: `#${element.Color}` }}
            >
              {element.MaterialIO}
            </span>{" "}
            {element.Name}
          </div>
        );
      }
    } else {
      return "-";
    }
  }
};
