import React, { useState, useEffect, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";

const InternalUsersModal = ({
  open,
  handleClose,
  trippet,
  participants,
  handleSaveParticipants,
  type,
  organizers,
  proxies,
  title,
  role,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);
  const [message, setMessage] = useState("");
  const [arrayParticipants, setArrayParticipants] = useState([]);
  const [allTripUsers, setAllTripUsers] = useState([]);
  const [values, setValues] = useState([]);
  const sortCriterium = useSelector(
    state => state.programSettings.participantsSorting,
  );
  const allUsers = useSelector(state => state.app.allUsers);
  useEffect(() => {
    if (allUsers && type) {
      const users = [...allUsers];
      if (sortCriterium === "lastname") {
        users.sort((a, b) => a.LastName.localeCompare(b.LastName));
        setArrayParticipants(users);
      } else if (sortCriterium === "firstname") {
        users.sort((a, b) => a.FirstName.localeCompare(b.FirstName));
        setArrayParticipants(users);
      } else {
        setArrayParticipants(users);
      }
      let tripBuddies = [];
      if (type === "Proxies") {
        tripBuddies = [...proxies];
      } else if (type === "Participants") {
        tripBuddies = [...participants];
      } else if (type === "Organizers") {
        tripBuddies = [...organizers];
      }
      if (tripBuddies && tripBuddies.length > 0) {
        let array = [...users];
        tripBuddies.forEach(part => {
          array.forEach((user, index) => {
            if (
              user.User_ID === part.TripParticipantUser_ID ||
              user.User_ID === part.TripOrganizerUser_ID ||
              user.User_ID === part.TripProxyUser_ID ||
              user.User_ID === part.User_ID
            ) {
              if (
                part.TripParticipantUserRole_ID === role.UserRole_ID ||
                part.TripOrganizerUserRole_ID === role.UserRole_ID ||
                part.TripProxyUserRole_ID === role.UserRole_ID ||
                part.UserRole_ID === role.UserRole_ID
              ) {
                array.splice(index, 1);
              }
            }
          });
        });
        setArrayParticipants(array);
      }
    }
    return () => {
      setValues([]);
      setMessage("");
      setCounter(0);
    };
  }, [
    allUsers,
    participants,
    sortCriterium,
    trippet,
    proxies,
    organizers,
    allTripUsers,
    type,
    role.UserRole_ID,
  ]);

  useEffect(() => {
    let array = [];
    if (organizers) {
      array.push(...organizers);
    }
    if (proxies) {
      array.push(...proxies);
    }
    if (participants) {
      array.push(...participants);
    }
    setAllTripUsers(array);
  }, [organizers, proxies, participants]);

  const handleSave = () => {
    if (message) {
      let array = [...values];
      array.forEach(item => {
        item.InviteMessage = message;
      });
      handleSaveParticipants(array, type, role);
      handleClose();
      setValues([]);
      setMessage("");
      setCounter(0);
    } else {
      handleSaveParticipants(values, type, role);
      handleClose();
      setValues([]);
      setMessage("");
      setCounter(0);
    }
  };

  const handleCloseModal = () => {
    setValues([]);
    setMessage("");
    setCounter(0);
    handleClose();
  };

  const handleChange = (event, values) => {
    setValues(values);
  };

  const renderFirstNameAndLastName = participant =>
    sortCriterium === "lastname"
      ? `${participant.LastName} ${participant.FirstName}`
      : `${participant.FirstName} ${participant.LastName}`;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleMessage = event => {
    setMessage(event.target.value);
    setCounter(event.target.value.length);
  };

  const renderSectionTitle = type
    ? `Select ${title.toLowerCase()} you want to add to this Trip`
    : `Select buddy you want to add to this Trip`;
  const renderSubTitle = type
    ? `(some copy personalized for ${title.toLowerCase()}?)`
    : `(some copy personalized for buddy?)`;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.container}
      >
        <div className={classes.modalTopContainer}>
          <DialogTitle id="alert-dialog-title">
            {`Add ${title}` || ""}
          </DialogTitle>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.modalContentContainer}>
          <div className={classes.topContent}>
            <div>{t(renderSectionTitle)}</div>
            <div>{t(renderSubTitle)}</div>
          </div>
          <div>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={arrayParticipants}
              disableCloseOnSelect
              className={classes.autocomplete}
              onChange={handleChange}
              value={values}
              getOptionLabel={option => renderFirstNameAndLastName(option)}
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    name={option.user_id}
                    color="primary"
                  />
                  {renderFirstNameAndLastName(option)}
                </Fragment>
              )}
              style={{ width: 500 }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t(`${title}`)}
                  placeholder={t(`${title}`)}
                />
              )}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="message"
              label={t("Type your personal message.")}
              name="message"
              autoComplete="trip-message"
              autoFocus
              value={message}
              onChange={handleMessage}
              className={classes.inputs}
              multiline
              rowsMax="4"
            />
            <FormHelperText>{`${counter} / 1500`}</FormHelperText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            {t("Add")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InternalUsersModal;
