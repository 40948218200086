export const validateImageFormat = file => {
  switch (file.type) {
    case 'image/png':
      return true;
    case 'image/jpeg':
      return true;
    case 'image/gif':
      return true;
    default:
      return false;
  }
};

export const validateFileFormat = file => {
  switch (file.type) {
    case 'application/msword':
      return true;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return true;
    case 'text/plain':
      return true;
    case 'application/zip':
      return true;
    case 'application/pdf':
      return true;
    default:
      return false;
  }
};