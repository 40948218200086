import React, { useState } from "react";
import withDashboard from "../../hoc/withDashboard/withDashboard";
import withTitle from "../../hoc/withTitle";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { changeUserPassword } from "../../store/actions/actions/user";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from '@material-ui/core/FormHelperText';

const UserSettings = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user.user);
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [values, setValues] = useState({
    oldPassword: "",
    password: "",
    repeatNewPassword: "",
  });

  const handleInputs = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleChangePassword = event => {
    event.preventDefault();
    if(values.password === values.repeatNewPassword){
      setShowErrorMessage(false);
      let body = {
        User_ID: user.User_ID,
        OldPassword: values.oldPassword,
        Password: values.password,
      };
      dispatch(changeUserPassword(JSON.stringify(body), t));
      history.push('/')
    }else {
      setShowErrorMessage(true);
    }
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.title}
        >
          {t("User Settings")}
        </Typography>
      </Grid>
      <Grid item xs={7} className={classes.container}>
        <form onSubmit={handleChangePassword}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="oldPassword"
            label={t("Old Password")}
            value={values.oldPassword}
            type={showPassword ? "text" : "password"}
            id="old_password"
            autoComplete="current-password"
            onChange={handleInputs}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("New Password")}
            value={values.password}
            type={showPassword ? "text" : "password"}
            id="new_password"
            autoComplete="current-password"
            onChange={handleInputs}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="repeatNewPassword"
            label={t("Repeat New Password")}
            value={values.repeatNewPassword}
            type={showPassword ? "text" : "password"}
            id="repeated_password"
            autoComplete="current-password"
            onChange={handleInputs}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
         {showErrorMessage && <FormHelperText>{t(`Passwords don't match`)}</FormHelperText>}
          <Button color="primary" type="submit" variant="contained">
            {t("Change Password")}
          </Button>
        </form>
      </Grid>
      <Grid item xs={7} container justify="flex-end"></Grid>
    </Grid>
  );
};

export default withRouter(
  withTitle(withDashboard(UserSettings), "User Settings"),
);
