export const columns = [
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    type: 'TrippetTypeLookName',
  },
  { id: 'title', label: 'Trippet Title', minWidth: 80, type: 'Title' },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 80,
    type: 'StartDate',
  },
  {
    id: 'startTime',
    label: 'Time',
    minWidth: 80,
    type: 'StartDate',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 80,
    type: 'EndDate',
  },
  {
    id: 'endTime',
    label: 'Time',
    minWidth: 80,
    type: 'EndDate',
  },
  {
    id: 'trippetOwner',
    label: 'Trippet Owner',
    minWidth: 80,
    type: 'OwnerUser_ID',
  },
  {
    id: 'day',
    label: 'Day',
    minWidth: 80,
    type: 'StartDay',
  },
  {
    id: 'price',
    label: 'Price',
    minWidth: 80,
    type: 'Price',
  },
];
