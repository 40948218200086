export const columns = [
    { id: 'actions', label: '', minWidth: 70, type: 'actions' },
    { id: 'Name', label: 'Name', minWidth: 80, type: 'Name' },
    {
      id: 'Code',
      label: 'Code',
      minWidth: 80,
      align: 'center',
      type: 'Name',
    },
    { id: 'Symbol', label: 'Symbol', minWidth: 80, align: 'center', type: 'Name' },
  ];
  