import React, { useState, useEffect } from "react";
import withAdminDashboard from "../../hoc/withAdminDashboard/withAdminDashboard";
import withTitle from "../../hoc/withTitle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { Editor } from "@tinymce/tinymce-react";
import {
  getAllConfigValues,
  updateValues,
} from "../../store/actions/actions/config";
import {
  clearCustomerLogo,
  getCustomerLogo,
} from "../../store/actions/actions/root";
import { getEntireLanguages } from "../../store/actions/actions/language";
import { useHistory } from "react-router-dom";
import PreviewIcon from "../../components/PreviewIcon/PreviewIcon";

const Config = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const languages = useSelector(state => state.language.entireLanguages);
  const allConfigValues = useSelector(state => state.config.allConfigValues);
  const [allLanguages, setAllLanguages] = useState(null);
  const [values, setValues] = useState({
    Language_ID: "",
    Logo: "",
    CompanyName: "",
    MetaDescription: "",
    MetaKeywords: "",
    TripInviteEmail: "",
    TrippetInviteEmail: "",
    ResetPasswordEmail: "",
    ImageExtensions: "",
    DocumentExtensions: "",
  });

  useEffect(() => {
    if (languages) {
      setAllLanguages(languages);
      if (!values.Language_ID && languages && languages.length)
        setValues({
          ...values,
          Language_ID: languages[0].Language_ID,
        });
      dispatch(
        getAllConfigValues(
          JSON.stringify({ Language_ID: languages[0].Language_ID }),
        ),
      );
    } else {
      dispatch(getEntireLanguages(null));
    }
    // eslint-disable-next-line
  }, [languages, dispatch]);

  /*
  "allConfigValues = Data": [
        {
            "Config_ID": "1",
            "Name": "Logo",
            "Comments": "company logo",
            "Value": "de.png",
            "LongValue": ""
        },
        {
            "Config_ID": "2",
            "Name": "InviteEmail",
            "Comments": "Invitation Email",
            "Value": "",
            "LongValue": "fa fa-file"
        }
    ]
  */
  useEffect(() => {
    if (allConfigValues && allConfigValues.length) {
      var logo = "";
      var companyName = "";
      var metaDescription = "";
      var metaKeywords = "";
      var tripInviteEmail = "";
      var trippetInviteEmail = "";
      var resetPasswordEmail = "";
      var imageExtensions = "";
      var documentExtensions = "";
      for (var i = 0; i < allConfigValues.length; i++) {
        if (parseInt(allConfigValues[i].Config_ID) === 1)
          logo = allConfigValues[i].Value;
        else if (parseInt(allConfigValues[i].Config_ID) === 2)
          companyName = allConfigValues[i].Value;
        else if (parseInt(allConfigValues[i].Config_ID) === 3)
          metaDescription = allConfigValues[i].LongValue;
        else if (parseInt(allConfigValues[i].Config_ID) === 4)
          metaKeywords = allConfigValues[i].LongValue;
        else if (parseInt(allConfigValues[i].Config_ID) === 20)
          tripInviteEmail = allConfigValues[i].LongValue;
        else if (parseInt(allConfigValues[i].Config_ID) === 21)
          trippetInviteEmail = allConfigValues[i].LongValue;
        else if (parseInt(allConfigValues[i].Config_ID) === 22)
          resetPasswordEmail = allConfigValues[i].LongValue;
        else if (parseInt(allConfigValues[i].Config_ID) === 40)
          imageExtensions = allConfigValues[i].Value;
        else if (parseInt(allConfigValues[i].Config_ID) === 41)
          documentExtensions = allConfigValues[i].Value;
      }
      setValues({
        ...values,
        Logo: logo,
        CompanyName: companyName,
        MetaDescription: metaDescription,
        MetaKeywords: metaKeywords,
        TripInviteEmail: tripInviteEmail,
        TrippetInviteEmail: trippetInviteEmail,
        ResetPasswordEmail: resetPasswordEmail,
        ImageExtensions: imageExtensions,
        DocumentExtensions: documentExtensions,
      });
    }
    // eslint-disable-next-line
  }, [allConfigValues, dispatch]);

  const handleInputs = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "Language_ID") {
      dispatch(
        getAllConfigValues(JSON.stringify({ Language_ID: event.target.value })),
      );
    }
  };

  /*
  {
    "Language_ID": 1,
    "Values": [
        {
            "Config_ID": 1,
            "Value": "de.png",
            "LongValue": ""
        },
        {
            "Config_ID": 2,
            "Value": "",
            "LongValue": "I am email",
        }
    ]
  }
*/
  const handleSaveChanges = () => {
    const body = {
      Language_ID: values.Language_ID,
      Values: [
        {
          Config_ID: 1,
          Value: values.Logo,
        },
        {
          Config_ID: 2,
          Value: values.CompanyName,
        },
        {
          Config_ID: 3,
          LongValue: values.MetaDescription,
        },
        {
          Config_ID: 4,
          LongValue: values.MetaKeywords,
        },
        {
          Config_ID: 20,
          LongValue: values.TripInviteEmail,
        },
        {
          Config_ID: 21,
          LongValue: values.TrippetInviteEmail,
        },
        {
          Config_ID: 22,
          LongValue: values.ResetPasswordEmail,
        },
        {
          Config_ID: 40,
          Value: values.ImageExtensions,
        },
        {
          Config_ID: 41,
          Value: values.DocumentExtensions,
        },
      ],
    };
    dispatch(updateValues(JSON.stringify(body), history, t));
    dispatch(clearCustomerLogo());
    dispatch(getCustomerLogo());
  };

  const renderLanguages =
    allLanguages && allLanguages.length > 0 ? (
      allLanguages.map(language => (
        <MenuItem key={language.Language_ID} value={language.Language_ID}>
          {`${language.Name}`}
        </MenuItem>
      ))
    ) : (
      <MenuItem value="">{t("Language")}</MenuItem>
    );

  const clearIcon = () => {
    setValues({ ...values, Logo: "" });
  };

  const setIcon = file => {
    setValues({ ...values, Logo: file });
  };

  const handleTripInviteEmailChange = (content, editor) => {
    setValues({ ...values, TripInviteEmail: content });
  };
  const handleTrippetInviteEmailChange = (content, editor) => {
    setValues({ ...values, TrippetInviteEmail: content });
  };
  const handleResetPasswordEmailChange = (content, editor) => {
    setValues({ ...values, ResetPasswordEmail: content });
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          className={classes.title}
        >
          {t("Config")}
        </Typography>
      </Grid>
      <Grid item container xs={12} className={classes.profilePageContainer}>
        <Grid item xs={12} className={classes.marginBottom}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              {t("Language")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label={t("Language")}
              placeholder={t("Language")}
              name="Language_ID"
              value={values.Language_ID}
              onChange={handleInputs}
            >
              {renderLanguages}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {t("Logo")}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <PreviewIcon
            currentImage={values.Logo}
            pictureUrl={values.Logo}
            clearIcon={clearIcon}
            setIcon={setIcon}
            type="config"
            dispatch={dispatch}
            t={t}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            autoComplete="CompanyName"
            name="CompanyName"
            variant="outlined"
            fullWidth
            label={t("Company Name")}
            autoFocus
            value={values.CompanyName}
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            autoComplete="MetaDescription"
            name="MetaDescription"
            variant="outlined"
            fullWidth
            label={t("MetaDescription")}
            autoFocus
            value={values.MetaDescription}
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            autoComplete="MetaKeywords"
            name="MetaKeywords"
            variant="outlined"
            fullWidth
            label={t("MetaKeywords")}
            autoFocus
            value={values.MetaKeywords}
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            className={classes.title}
          >
            {t("TripInviteEmail")}
          </Typography>
          <Typography
            variant="span"
            component="span"
            gutterBottom
            className={classes.title}
          >
            {t("TripPlaceholders")}
          </Typography>
          <Editor
            apiKey="d4uqmrfnlyscqqfgrcuaoaqnf6nno667p8qbux961yf1vlsm"
            initialValue={values.TripInviteEmail}
            value={values.TripInviteEmail}
            init={{
              height: 400,
              menubar: true,
              relative_urls : false,
              remove_script_host : false,
              convert_urls : true,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                // eslint-disable-next-line
                "undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={handleTripInviteEmailChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            className={classes.title}
          >
            {t("TrippetInviteEmail")}
          </Typography>
          <Typography
            variant="span"
            component="span"
            gutterBottom
            className={classes.title}
          >
            {t("TrippetPlaceholders")}
          </Typography>
          <Editor
            apiKey="d4uqmrfnlyscqqfgrcuaoaqnf6nno667p8qbux961yf1vlsm"
            initialValue={values.TrippetInviteEmail}
            value={values.TrippetInviteEmail}
            init={{
              height: 400,
              menubar: true,
              relative_urls : false,
              remove_script_host : false,
              convert_urls : true,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                // eslint-disable-next-line
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={handleTrippetInviteEmailChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            className={classes.title}
          >
            {t("ResetPasswordEmail")}
          </Typography>
          <Typography
            variant="span"
            component="span"
            gutterBottom
            className={classes.title}
          >
            {t("ResetPasswordPlaceholders")}
          </Typography>
          <Editor
            apiKey="d4uqmrfnlyscqqfgrcuaoaqnf6nno667p8qbux961yf1vlsm"
            initialValue={values.ResetPasswordEmail}
            value={values.ResetPasswordEmail}
            init={{
              height: 400,
              menubar: true,
              relative_urls : false,
              remove_script_host : false,
              convert_urls : true,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                // eslint-disable-next-line
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={handleResetPasswordEmailChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            autoComplete="ImageExtensions"
            name="ImageExtensions"
            variant="outlined"
            fullWidth
            label={t("ImageExtensions")}
            value={values.ImageExtensions}
            placeholder="jpg, jpeg, png, gif, jfif"
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            autoComplete="DocumentExtensions"
            name="DocumentExtensions"
            variant="outlined"
            fullWidth
            label={t("DocumentExtensions")}
            value={values.DocumentExtensions}
            placeholder="txt, doc, docx, xls, xlsx, pdf, zip, rar, jpg, jpeg, png, gif, jfif"
            onChange={handleInputs}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSaveChanges}
            >
              {t("Save Changes")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTitle(withAdminDashboard(Config), "Config");
