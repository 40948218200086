import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  modalTitle: {
    '& > h2': {
      fontWeight: 'bold',
    },
  },
  choose: {
    padding: '25px 10px 0 10px'
  },
  icon: {
    margin: '10px',
    cursor: 'pointer'
  },
}));

export default useStyles;
