import React, { useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewCountry, changeCountry } from '../../store/actions/actions/country';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import {getEntireLanguages} from '../../store/actions/actions/language';
import PreviewIcon from '../PreviewIcon/PreviewIcon';
import IconPicker from '../IconPicker/IconPicker';
import * as userAccess from '../../userAccess';

const CountryModal = ({ open, handleCountryModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [namesError, setNamesError] = useState(false);
  const [formError, setFormError] = useState(false);
  const entireLanguages = useSelector(state => state.language.entireLanguages);
  const currentCountry = useSelector(state => state.country.currentCountry);
  const [values, setValues] = useState({
    Code: '',
    Icon: '',
    MaterialIO: '',
    Names: [],
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure languages is already loaded
    if (!entireLanguages)
    {
      dispatch(getEntireLanguages(null));
      return;
    }
    if (defaultValues) {
        // when there is a new language, the name for that language in 'Names' is missing
        // so we need to iterate through entireLanguages and push missed ones
        const names = currentCountry ? currentCountry.Names : defaultValues.Names;
        if (names && Array.isArray(names))
          entireLanguages.forEach(name => {
            var found = false;
            names.forEach(name2 => {
              if (Number(name2.Language_ID) === Number(name.Language_ID))
                found = true;
            });
            if (!found)
              names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
          });
        if (currentCountry)
        {
            setValues({
              Code: currentCountry.Code,
                Icon: currentCountry.Icon,
                MaterialIO: currentCountry.MaterialIO,
                Names: names,
                Country_ID: currentCountry.Country_ID,
            });
        }
        else
        {
            setValues({
              Code: defaultValues.Code,
                Icon: defaultValues.Icon,
                MaterialIO: defaultValues.MaterialIO,
                Names: names,
                Country_ID: defaultValues.Country_ID,
            });
        }
        
    //   setValues({...values, Names: names});
        handleIsEditMode(true);
    } else {
      
      const names = [];
      entireLanguages.forEach(name => {
        names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
      });
      setValues({
        Country_ID: 0,
        Code: '',
        Icon: '',
        MaterialIO: '',
        Names: names,
      });
      handleIsEditMode(false);
    }
  }, [defaultValues, currentCountry, entireLanguages, dispatch]);

  const validateForm = ({ Code, Names }) => {
    if (Code && Names && Names.length > 0) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };


  const handleSubmit = e => {
    e.stopPropagation();
    if (!codeError  && !namesError) {
        e.preventDefault();
        const data = {
            Country_ID: +values.Country_ID,
            Code: values.Code,
            Icon: values.Icon,
            MaterialIO: values.MaterialIO,
            Names: values.Names,
        };
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changeCountry(JSON.stringify(data)));
          handleCountryModalClose();
        }
      } else {
        if (validateForm(data)) {
          dispatch(createNewCountry(JSON.stringify(data)));
          handleCountryModalClose();
        }
      }
    }
  };

  const clearIcon = () => {
    setValues({...values, Icon: ''});
  };

  const setIcon = file => {
    setValues({...values, Icon: file});
  };
  const setMaterialIO = icon => {
    setValues({...values, MaterialIO: icon});
  };
  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name === 'Code') {
      if (e.target.value.length === 0) {
        setCodeError(true);
      } else {
        setCodeError(false);
      }
    }
    if (e.target.name.indexOf("Name") === 0) // it is Names list
    {
        const names = [];
        values.Names.forEach(name => {
            if ("Name" + name.Language_ID === e.target.name)
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: e.target.value});
            else
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: name.Name});
        });
        setValues({...values, Names: names});
        setNamesError(names.length === 0);
    }
    else
    {
        setValues({...values, [e.target.name]: e.target.value});
    }
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const renderTitle = defaultValues ? t('Edit Country') : t('New Country');
  const renderNames =
    values.Names && values.Names.length > 0
      ? values.Names.map(name => {
          return (
            <TableRow key={name.Language_ID} className={classes.rowContainer}>
                <TableCell>
                    <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id={"Name" + name.Language_ID}
                    label={name.LanguageAbbreviation}
                    name={"Name" + name.Language_ID}
                    value={name.Name}
                    onChange={handleInputs}
                    />
                </TableCell>
              </TableRow>
          );
        })
      : null;
      
  const renderCodeError = codeError
    ? t('Code is required.')
    : null;

  const renderNamesError = namesError
    ? t('Names are required.')
    : null;

  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;


  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_Countries))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_Countries));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.languageName}>
                <Grid container>
                  <Grid item xs={12}className={classes.languageName}>                  
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="Code"
                      label={t('Code')}
                      name="Code"
                      autoComplete="Code"
                      autoFocus
                      value={values.Code}
                      onChange={handleInputs}
                    />
                    <FormHelperText className={classes.errorText}>
                      {renderCodeError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} className={classes.languageName}>
                    <Grid container>
                      <Grid item xs={10} className={classes.languageName}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          id="MaterialIO"
                          label={t('MaterialIO')}
                          name="MaterialIO"
                          autoComplete="MaterialIO"
                          value={values.MaterialIO}
                          onChange={handleInputs}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconPicker selectedIcon={values.MaterialIO} iconSelected={setMaterialIO} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} className={classes.languageName}>
                <PreviewIcon currentImage={values.Icon} pictureUrl={values.Icon} clearIcon={clearIcon} setIcon={setIcon}
                  type='language' dispatch={dispatch} t={t} />
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
              
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  className={classes.title}
                >
                  {t('Languages')}
                </Typography>
              </Grid>
            </Grid>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                   
                    </TableRow>
                </TableHead>
                <TableBody>{renderNames}</TableBody>
                </Table>
            </TableContainer>
            <FormHelperText className={classes.errorText}>
              {renderNamesError}
            </FormHelperText>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleCountryModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default CountryModal;