import React from 'react';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';

const CustomTimePicker = ({
  selectedDate,
  handleDateChange,
  label,
  className,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        clearable
        ampm={false}
        label={t(label)}
        value={selectedDate}
        onChange={handleDateChange}
        inputVariant="outlined"
        className={className}
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomTimePicker;
