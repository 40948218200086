import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Avatar from "@material-ui/core/Avatar";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { columns } from "./columns";
import { columnsTwo } from "./column-two";
import useStyles from "../styles";
import { format } from "date-fns";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import uniqid from "uniqid";
import TripButton from "../TripButton";
import { getTripById } from "../../../store/actions/actions/trip";
import { setLastTrip } from "../../../store/actions/actions/user";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TripInvitationButton from "../TripInvitationButton";
import TripRecommendationButton from "../TripRecommendationButton";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import TripModal from "../../../components/TripForm/TripForm";

const createData = (
  date,
  name,
  participants,
  sender,
  recipient,
  message,
  status,
  lastChanged,
  fullTrip,
  id,
) => {
  return {
    date,
    name,
    participants,
    sender,
    recipient,
    message,
    status,
    lastChanged,
    fullTrip,
    id,
  };
};

const TripTable = ({
  data,
  allUsers,
  type,
  user,
  handleConfirmDeleteModalOpen,
  setDeletedTrip,
  recommendationType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [clickedTrip, setClickedTrip] = useState(null);
  const [isTripModalOpen, setIsTripModalOpen] = useState(false);
  const [usedColumns, setUsedColumns] = useState([]);

  useEffect(() => {
    if (recommendationType === "sent") {
      setUsedColumns(columnsTwo);
    } else {
      setUsedColumns(columns);
    }
  }, [recommendationType]);

  const { t } = useTranslation();

  const findUserInfo = participant => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      allUsers.forEach(user => {
        if (participant.TripParticipantUser_ID === user.User_ID) {
          data = renderUserInfo(user);
        } else {
          if (participant.InviteeEmail) {
            data = renderUserInfo(participant);
          }
        }
      });
      return data;
    }
  };

  const renderUserInfo = participant => {
    if (participant.Picture) {
      return (
        <img
          src={participant.Picture}
          alt={participant.TripParticipantInitials}
          className={classes.avatarImage}
        />
      );
    } else {
      return `${participant.Initials || participant.TripParticipantInitials}`;
    }
  };

  const renderParticipantsStatusColor = status => {
    if (status === "1" || status === null || status === "0") {
      return classes.pending;
    } else if (status === "2") {
      return classes.accepted;
    } else if (status === "3") {
      return classes.rejected;
    }
  };

  const renderParticipants = value => {
    let allParticipants = value;
    return (
      allParticipants &&
      allParticipants.map(participant => (
        <Avatar
          className={renderParticipantsStatusColor(
            participant.ParticipantStatus_ID,
          )}
          key={uniqid()}
        >
          {findUserInfo(participant)}
        </Avatar>
      ))
    );
  };

  const converToLocalTime = date => {
    let newDate = date.split("");
    newDate.push("Z");
    let localTime = new Date(newDate.join(""));
    return format(new Date(localTime), "dd.MM.yyyy HH:mm");
  };

  const getRecommendationStatusName = status => {
    if (status === "1") {
      return "Recommended";
    } else if (status === "2") {
      return "Accepted";
    } else if (status === "3") {
      return "Rejected";
    }
  };

  const findOwner = value =>
    allUsers && allUsers.find(user => user.User_ID === value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        let startDate = format(new Date(item.TripStartDate), "dd.MM.yyyy");
        let endDate = format(new Date(item.TripEndDate), "dd.MM.yyyy");
        elements.push(
          createData(
            `${startDate} - ${endDate}`,
            item.TripName,
            item.TripParticipants,
            item.TripRecommendationSenderUser_ID,
            item.TripRecommendationRecipientUser_ID,
            item.Message,
            getRecommendationStatusName(
              item.TripRecommendationAcceptanceStatus,
            ),
            converToLocalTime(item.ModifiedDate),
            item,
            item.Trip_ID,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedTrip = value => {
    setClickedTrip(value);
  };

  const handleTripModalOpen = () => {
    setIsTripModalOpen(true);
  };

  const handleTripModalClose = () => {
    setIsTripModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === "participants") {
      return (
        <AvatarGroup max={2} className={classes.avatarGroup}>
          {renderParticipants(value)}
        </AvatarGroup>
      );
    } else if (column.id === "actions") {
      if (type === "my-trip") {
        return (
          <TripButton
            data={row}
            type={type}
            allUsers={allUsers}
            user={user}
            handleClickedTrip={handleClickedTrip}
            handleTripModalOpen={handleTripModalOpen}
            handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
            setDeletedTrip={setDeletedTrip}
          />
        );
      } else if (type === "invitations") {
        return <TripInvitationButton data={row} type={type} />;
      } else if (type === "recommendations") {
        return <TripRecommendationButton data={row} type={type} />;
      }
    } else if (column.id === "sender") {
      let user = findOwner(value);
      if (user) {
        if (user.Picture) {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>
                <img
                  src={user.Picture}
                  alt={user.Initials}
                  className={classes.avatarImage}
                />
              </Avatar>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>{`${user.Initials}`}</Avatar>
            </Tooltip>
          );
        }
      }
    } else if (column.id === "recipient") {
      let user = findOwner(value);
      if (user) {
        if (user.Picture) {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>
                <img
                  src={user.Picture}
                  alt={user.Initials}
                  className={classes.avatarImage}
                />
              </Avatar>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>{`${user.Initials}`}</Avatar>
            </Tooltip>
          );
        }
      }
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    if (type === "my-trip") {
      dispatch(getTripById(value.id, user.User_ID));
      const lastTripBody = {
        LastTrip_ID: value.id,
        User_ID: user.User_ID,
      };
      dispatch(setLastTrip(JSON.stringify(lastTripBody)));
      history.push("/");
    }
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={uniqid()}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {usedColumns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0
                    ? classNames(classes.tableCell, classes[row.color])
                    : classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {usedColumns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableHeaderCell}
                  style={{ minWidth: column.minWidth }}
                >
                  {t(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <TripModal
        open={isTripModalOpen}
        handleTripModalClose={handleTripModalClose}
        defaultValues={clickedTrip}
      />
    </Paper>
  );
};

export default TripTable;
