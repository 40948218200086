import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { columns } from "./columns";
import useStyles from "./styles";
import SettingItemButton from "./SettingItemButton";
import { getSettingItemById } from "../../store/actions/actions/settingItem";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import * as userAccess from "../../userAccess";
import SettingItemModal from "../../components/Setting/SettingItemForm";

const createData = (
  SettingItem_ID,
  SettingCategory_ID,
  SettingCategoryName,
  Name,
  DataType,
  Names,
) => {
  return {
    SettingItem_ID,
    SettingCategory_ID,
    SettingCategoryName,
    Name,
    DataType,
    Names,
  };
};

const SettingItemTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedSettingItem,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedSettingItem, setClickedSettingItem] = useState([]);
  const [isSettingItemModalOpen, setIsSettingItemModalOpen] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(
    useSelector(state => state.user.user),
    userAccess.Table_Settings,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.SettingItem_ID,
            item.SettingCategory_ID,
            item.SettingCategoryName,
            item.Name,
            item.DataType,
            item.Names,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedSettingItem = value => {
    setClickedSettingItem(value);
  };

  const handleSettingItemModalOpen = () => {
    setIsSettingItemModalOpen(true);
  };

  const handleSettingItemModalClose = () => {
    setIsSettingItemModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === "actions") {
      return (
        <SettingItemButton
          data={row}
          handleClickedSettingItem={handleClickedSettingItem}
          handleSettingItemModalOpen={handleSettingItemModalOpen}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedSettingItem={setDeletedSettingItem}
          disabledDelete={disableDelete}
        />
      );
    } else if (column.id === "DataType") {
      if (Number(value) === 1) return "Text";
      if (Number(value) === 2) return "Number";
      return "Date";
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    dispatch(getSettingItemById(value.SettingItem_ID));
    let recordBody = {
      SettingItem_ID: value.SettingItem_ID,
      SettingCategory_ID: value.SettingCategory_ID,
      SettingCategoryName: value.SettingCategoryName,
      Name: value.Name,
      DataType: value.Type,
      Names: value.Names,
    };
    handleClickedSettingItem(recordBody);
    handleSettingItemModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.SettingItem_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName = classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== "actions") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id ? sortedBy : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <SettingItemModal
        open={isSettingItemModalOpen}
        handleSettingItemModalClose={handleSettingItemModalClose}
        defaultValues={clickedSettingItem}
      />
    </Paper>
  );
};

export default SettingItemTable;
