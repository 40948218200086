import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import {store} from '../../store';

export const saveCurrentLanguage = payload => ({
  type: adminActionTypes.SAVE_CURRENT_LANGUAGE,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_LANGUAGE_CURRENT_PAGE,
  payload,
});

export const getLanguageById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/language/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentLanguage(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewLanguage = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/language/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503 && response.type !== 'cors') {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentLanguage(data));
          dispatch(getAllLanguages());
          dispatch(getEntireLanguages(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeLanguage = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/language/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getLanguageById(body.Language_ID));
          dispatch(getAllLanguages(null));
          dispatch(getEntireLanguages(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllLanguages = payload => ({
  type: adminActionTypes.SAVE_ALL_LANGUAGES,
  payload,
});
export const saveEntireLanguages = payload => ({
  type: adminActionTypes.SAVE_ENTIRE_LANGUAGES,
  payload,
});

export const saveLanguageSortElement = payload => ({
  type: adminActionTypes.SAVE_LANGUAGE_SORT_ELEMENT,
  payload,
});

export const saveLanguageSortByDirection = payload => ({
  type: adminActionTypes.SAVE_LANGUAGE_SORT_BY_DIRECTION,
  payload,
});

export const saveLanguagePaginationData = payload => ({
  type: adminActionTypes.SAVE_LANGUAGE_PAGINATION_DATA,
  payload,
});

export const getAllLanguages = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: ROWS_PER_PAGE,
            Page: store.getState().language.currentPage,
            OrderBy: 'Abbreviation',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/language/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllLanguages(data.Data));
        dispatch(saveLanguagePaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const getEntireLanguages = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: 10000,
            Page: 1,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/language/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveEntireLanguages(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteLanguage = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/language/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else 
            {
              dispatch(getAllLanguages(null));
              dispatch(getEntireLanguages(null));
            }
        })
        .catch(error => console.log(error));
    }
  };
};
