import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  logo: {
    display: "flex",
    alignItems: "center",
  },
  customerLogo: {
    width: 150,
    marginRight: 15,
    display: "flex",
    alignItems: "center",
    "& > img": {
      width: "100%",
    },
  },
  content: {
    marginLeft: 15,
    marginRight: 70,
    width: "100%",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    "& > span": {
      color: theme.palette.text[100],
      fontWeight: "bold",
    },
  },
  notification: {
    "& > button > span": {
      color: theme.palette.electricBlue[100],
    },
  },
  profilePicture: {
    width: "100%",
    height: "100%",
  },
  showMoreBtn: {
    justifyContent: "center",
    "& > span": {
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: 14,
    },
  },
  singleNotification: {
    minWidth: 170,
    display: "flex",
    alignItems: "center",
    "& > div": {
      marginLeft: 10,
    },
  },
}));

export default useStyles;
