/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Table from "./Table";
import { useSelector } from "react-redux";

const BuddyRoleManagement = ({
  open,
  handleClose,
  participants,
  organizers,
  proxies,
  values,
  setValues,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState({});
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const allRoles = useSelector(state => state.app.allUsersRoles);
  const [copyUsers, setCopyUsers] = useState({});

  useEffect(() => {
    let array = [];
    let allTripUsers = [];
    if (participants && participants.length > 0) {
      let users = [];
      participants.forEach(par => {
        par.type = "participants";
        par.id = par.TripParticipantUser_ID || par.User_ID;
        users.push(par);
      });
      array.push(...users);
      allTripUsers.push(...users);
    }
    if (organizers && organizers.length > 0) {
      let users = [];
      let allOrganizers = [];
      organizers.forEach(par => {
        par.type = "organizers";
        par.id = par.TripOrganizerUser_ID || par.User_ID;
        allOrganizers.push(par);
        if (!array.find(item => item.id === par.id)) {
          users.push(par);
        }
      });
      array.push(...users);
      allTripUsers.push(...allOrganizers);
    }
    if (proxies && proxies.length > 0) {
      let users = [];
      let allProxies = [];
      proxies.forEach(par => {
        par.type = "proxies";
        par.id = par.TripProxyUser_ID || par.User_ID;
        allProxies.push(par);
        if (!array.find(item => item.id === par.id)) {
          users.push(par);
        }
      });
      array.push(...users);
      allTripUsers.push(...allProxies);
    }

    let uniq = [...allTripUsers];
    uniq = uniq.filter(
      (thing, index, self) =>
        self.findIndex(
          test =>
            (test.TripParticipantDisplayName ||
              test.TripProxyDisplayName ||
              test.TripOrganizerDisplayName ||
              test.DisplayName) ===
            (thing.TripParticipantDisplayName ||
              thing.TripProxyDisplayName ||
              thing.TripOrganizerDisplayName ||
              thing.DisplayName),
        ) === index,
    );
    setUniqueUsers(uniq);
    setAllUsers(allTripUsers);
  }, [participants, organizers, proxies]);

  const checkIfAlreadyExist = (id, obj) => `${id}` in obj;

  const overRideUndefinedID = item => {
    let user = { ...item };
    if ("TripParticipantUser_ID" in user) {
      if (!user.TripParticipantUser_ID) {
        user.TripParticipantUser_ID = `${user.InviteeEmail}-${user.TripParticipant_ID}`;
      }
    } else if ("TripOrganizerUser_ID" in user) {
      if (!user.TripOrganizerUser_ID) {
        user.TripOrganizerUser_ID = `${user.InviteeEmail}-${user.TripOrganizer_ID}`;
      }
    } else if ("TripProxyUser_ID" in user) {
      if (!user.TripProxyUser_ID) {
        user.TripProxyUser_ID = `${user.InviteeEmail}-${user.TripProxy_ID}`;
      }
    }
    return user;
  };

  const renderAllRoles = (id, userObj, item) => {
    let obj = {};
    let user = overRideUndefinedID(item);
    let userID =
      user.TripParticipantUser_ID ||
      user.TripOrganizerUser_ID ||
      user.TripProxyUser_ID ||
      user.User_ID;
    obj[userID] = {};
    if (checkIfAlreadyExist(userID, userObj)) {
      if (allRoles) {
        allRoles.forEach(role => {
          if (role.UserRole_ID === id) {
            obj[userID][role.UserRole_ID] = true;
          } else {
            obj[userID][role.UserRole_ID] = userObj[userID][userID][
              role.UserRole_ID
            ]
              ? true
              : false;
          }
        });
      }
    } else {
      if (allRoles) {
        allRoles.forEach(role => {
          if (role.UserRole_ID === id) {
            obj[userID][role.UserRole_ID] = true;
          } else {
            obj[userID][role.UserRole_ID] = false;
          }
        });
      }
    }
    return obj;
  };

  useEffect(() => {
    if (
      allUsers &&
      allUsers.length > 0 &&
      uniqueUsers &&
      uniqueUsers.length > 0 &&
      allRoles &&
      allRoles.length > 0
    ) {
      let userObj = {};
      allUsers.forEach(item => {
        let user = overRideUndefinedID(item);
        let obj = renderAllRoles(
          item.TripParticipantUserRole_ID ||
            item.UserRole_ID ||
            item.TripProxyUserRole_ID ||
            item.TripOrganizerUserRole_ID,
          userObj,
          item,
        );
        userObj[
          user.TripParticipantUser_ID ||
            item.User_ID ||
            user.TripOrganizerUser_ID ||
            user.TripProxyUser_ID
        ] = obj;
      });
      setUsers(userObj);
      setCopyUsers(userObj);
    }
  }, [allUsers, uniqueUsers, allRoles]);

  const handleChangeRadio = (userID, roleID) => event => {
    setCopyUsers({
      ...copyUsers,
      [userID]: {
        ...copyUsers[userID],
        [userID]: {
          ...copyUsers[userID][userID],
          [roleID]: event.target.checked,
        },
      },
    });
  };

  const createParticipant = (item, role) => {
    let name =
      item.TripOrganizerDisplayName ||
      item.DisplayName ||
      item.TripParticipantDisplayName ||
      item.TripProxyDisplayName;
    let Initials =
      item.TripParticipantInitials ||
      item.Initials ||
      item.TripProxyInitials ||
      item.TripOrganizerInitials;
    let id =
      item.TripParticipantUser_ID ||
      item.User_ID ||
      item.TripOrganizerUser_ID ||
      item.TripProxyUser_ID;
    let BuddyID =
      item.TripParticipant_ID ||
      item.User_ID ||
      item.TripOrganizer_ID ||
      item.TripProxy_ID;
    let data = {
      InviteMessage: item.InviteMessage || null,
      InviteeEmail: item.InviteeEmail || null,
      ParticipantStatusName: null,
      ParticipantStatus_ID: item.ParticipantStatus_ID || "1",
      PlanningStatusName: null,
      PlanningStatus_ID: null,
      TripParticipantDisplayName: name,
      TripParticipantInitials: Initials,
      TripParticipantUserRole_ID: role,
      TripParticipantUser_ID: id,
      TripParticipant_ID: BuddyID,
      Trip_ID: item.Trip_ID,
      id: id,
      type: item.type,
    };
    return data;
  };

  const createProxy = (item, role) => {
    let name =
      item.TripOrganizerDisplayName ||
      item.DisplayName ||
      item.TripParticipantDisplayName ||
      item.TripProxyDisplayName;
    let Initials =
      item.TripParticipantInitials ||
      item.Initials ||
      item.TripProxyInitials ||
      item.TripOrganizerInitials;
    let id =
      item.TripParticipantUser_ID ||
      item.User_ID ||
      item.TripOrganizerUser_ID ||
      item.TripProxyUser_ID;
    let BuddyID =
      item.TripParticipant_ID ||
      item.User_ID ||
      item.TripOrganizer_ID ||
      item.TripProxy_ID;
    let data = {
      InviteMessage: item.InviteMessage || null,
      InviteeEmail: item.InviteeEmail || null,
      TripProxyAcceptanceStatus: item.TripProxyAcceptanceStatus || "1",
      TripProxyDisplayName: name,
      TripProxyInitials: Initials,
      TripProxyUserRole_ID: role,
      TripProxyUser_ID: id,
      TripProxy_ID: BuddyID,
      Trip_ID: item.Trip_ID,
      id: id,
      type: item.type,
    };
    return data;
  };

  const createOrganizer = (item, role) => {
    let name =
      item.TripOrganizerDisplayName ||
      item.DisplayName ||
      item.TripParticipantDisplayName ||
      item.TripProxyDisplayName;
    let Initials =
      item.TripParticipantInitials ||
      item.Initials ||
      item.TripProxyInitials ||
      item.TripOrganizerInitials;
    let id =
      item.TripParticipantUser_ID ||
      item.User_ID ||
      item.TripOrganizerUser_ID ||
      item.TripProxyUser_ID;
    let BuddyID =
      item.TripParticipant_ID ||
      item.User_ID ||
      item.TripOrganizer_ID ||
      item.TripProxy_ID;
    let data = {
      InviteMessage: item.InviteMessage || null,
      InviteeEmail: item.InviteeEmail || null,
      TripOrganizerAcceptanceStatus: item.TripOrganizerAcceptanceStatus || "1",
      TripOrganizerDisplayName: name,
      TripOrganizerInitials: Initials,
      TripOrganizerUserRole_ID: role,
      TripOrganizerUser_ID: id,
      TripOrganizer_ID: BuddyID,
      Trip_ID: item.Trip_ID,
      id: id,
      type: item.type,
    };
    return data;
  };

  const handleSave = () => {
    setUsers(copyUsers);
    let participant = [];
    let organizers = [];
    let proxies = [];
    uniqueUsers.forEach(user => {
      let item = overRideUndefinedID(user);
      let id =
        item.TripParticipantUser_ID ||
        user.User_ID ||
        item.TripOrganizerUser_ID ||
        item.TripProxyUser_ID;
      let type = user.type;
      let roles = copyUsers[id][id];
      // eslint-disable-next-line
      allRoles.forEach(role => {
        // eslint-disable-next-line
        Object.keys(roles).map(key => {
          if (role.Type === "1" && roles[key] && key === role.UserRole_ID) {
            if (
              type === "proxies" &&
              user.TripProxyUserRole_ID === role.UserRole_ID
            ) {
              user.TripProxyUserRole_ID = role.UserRole_ID;
              proxies.push(user);
            } else {
              let buddy = createProxy(user, role.UserRole_ID);
              proxies.push(buddy);
            }
          } else if (
            role.Type === "2" &&
            roles[key] &&
            key === role.UserRole_ID
          ) {
            console.log("role.UserRole_ID", role.UserRole_ID, user);
            if (
              type === "participants" &&
              user.TripParticipantUserRole_ID === role.UserRole_ID
            ) {
              participant.push(user);
            } else {
              let buddy = createParticipant(user, role.UserRole_ID);
              participant.push(buddy);
            }
          } else if (
            role.Type === "3" &&
            roles[key] &&
            key === role.UserRole_ID
          ) {
            if (
              type === "organizers" &&
              user.TripOrganizerUserRole_ID === role.UserRole_ID
            ) {
              user.TripOrganizerUserRole_ID = role.UserRole_ID;
              organizers.push(user);
            } else {
              let buddy = createOrganizer(user, role.UserRole_ID);
              organizers.push(buddy);
            }
          }
        });
      });
    });
    setValues({
      ...values,
      participants: participant,
      organizers: organizers,
      proxies: proxies,
    });
    handleClose();
  };

  const handleCloseTable = () => {
    setCopyUsers(users);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.container}
      >
        <div className={classes.modalTopContainer}>
          <DialogTitle id="alert-dialog-title">
            {t("Buddy Role Management")}
          </DialogTitle>
          <IconButton onClick={handleCloseTable}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.modalContentContainer}>
          <Table
            allUsers={allUsers}
            users={copyUsers}
            setUsers={setCopyUsers}
            handleChangeRadio={handleChangeRadio}
            allRoles={allRoles}
            setAllUsers={setAllUsers}
            uniqueUsers={uniqueUsers}
            setUniqueUsers={setUniqueUsers}
          />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseTable} color="primary">
              {t("Cancel")}
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              {t("Save")}
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BuddyRoleManagement;
