import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  modalTitle: {
    '& > h2': {
      fontWeight: 'bold',
    },
  },
  verificationCodeName: {
    marginBottom: 15,
  },
  verificationCodeNameView: {
    marginBottom: 5,
  },
  errorText: {
    color: theme.palette.secondary.main,
  },
  inviteUsersBtn: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 13,
  },
  namesTitle: {
    fontWeight: 'bold',
  },
  sectionTitle: {
    display: 'block',
    fontSize: 12,
    color: 'grey',
  },
  spacing: {
    marginBottom: 10,
  },
  selectView: {
    width: '100%',
  },
}));

export default useStyles;
