import rootReducer from './root';
import tripReducer from './trip';
import programSettingsReducer from './programSettings';
import trippetReducer from './trippet';
import userReducer from './user';
import profileReducer from './profile';
import mapReducer from './map';
import languageReducer from './language';
import planningStatusReducer from './planningStatus';
import participantStatusReducer from './participantStatus';
import countryReducer from './country';
import currencyReducer from './currency';
import settingCategoryReducer from './settingCategory';
import settingItemReducer from './settingItem';
import preferenceCategoryReducer from './preferenceCategory';
import preferenceItemReducer from './preferenceItem';
import trippetTypeCategoryReducer from './trippetTypeCategory';
import trippetTypeLookReducer from './trippetTypeLook';
import departmentReducer from './department';
import adminRoleReducer from './adminRole';
import adminRoleAccessReducer from './adminRoleAccess';
import userRoleReducer from './userRole';
import userRoleAllowReducer from './userRoleAllow';
import verificationCodeReducer from './verificationCode';
import notificationReducer from './notification';
import configReducer from './config';
import { combineReducers } from 'redux';

const reducer = combineReducers({
  app: rootReducer,
  user: userReducer,
  trip: tripReducer,
  trippet: trippetReducer,
  map: mapReducer,
  profile: profileReducer,
  programSettings: programSettingsReducer,
  notification: notificationReducer,
  // ------------------------------------
  // Admin section
  language: languageReducer,
  planningStatus: planningStatusReducer,
  participantStatus: participantStatusReducer,
  country: countryReducer,
  currency: currencyReducer,
  settingCategory: settingCategoryReducer,
  settingItem: settingItemReducer,
  preferenceCategory: preferenceCategoryReducer,
  preferenceItem: preferenceItemReducer,
  trippetTypeCategory: trippetTypeCategoryReducer,
  trippetTypeLook: trippetTypeLookReducer,
  department: departmentReducer,
  adminRole: adminRoleReducer,
  adminRoleAccess: adminRoleAccessReducer,
  userRole: userRoleReducer,
  userRoleAllow: userRoleAllowReducer,
  verificationCode: verificationCodeReducer,
  config: configReducer,
});

export default reducer;
