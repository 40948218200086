export const columns = [
  { id: 'actions', label: '', minWidth: 70 },
  { id: 'date', label: 'Date', minWidth: 80, align: 'center' },
  { id: 'name', label: 'Trip Title', minWidth: 80 },
  {
    id: 'participants',
    label: 'Participants',
    minWidth: 80,
    align: 'left',
  },
  {
    id: 'sender',
    label: 'Sender',
    minWidth: 80,
    align: 'left',
  },
  {
    id: 'recipient',
    label: 'Recipient',
    minWidth: 80,
    align: 'left',
  },
  {
    id: 'message',
    label: 'Message',
    minWidth: 80,
    align: 'left',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
  },
  {
    id: 'lastChanged',
    label: 'Last Changed',
    minWidth: 80,
    align: 'center',
  },
];
