export const columns = [
    { id: 'actions', label: '', minWidth: 70, type: 'actions' },
    {
      id: 'SettingCategoryName',
      label: 'Category',
      minWidth: 80,
      type: 'Name',
    },
    { id: 'Name', label: 'Name', minWidth: 80, type: 'Name' },
    { id: 'DataType', label: 'DataType', minWidth: 80, align: 'center', type: 'DataType' },
  ];
  