import * as actionTypes from "../actionTypes/actionTypes";
import { BASE_URL } from "../../../config";
import { showSnackBar } from "./root";
import { saveUser } from "./user";

export const saveUploadedProfilePicture = payload => ({
  type: actionTypes.SAVE_UPLOADED_PROFILE_PICTURE,
  payload,
});

export const saveUserInformation = payload => ({
  type: actionTypes.SAVE_USER_INFORMATION,
  payload,
});

export const deleteUploadedProfilePicture = payload => ({
  type: actionTypes.DELETE_UPLOADED_PROFILE_PICTURE,
  payload,
});

export const uploadFile = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/upload.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 501) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          } else if (response.status === 402) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "File not provided",
              }),
            );
          } else if (response.status === 404) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Invalid file extension",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          const body = {
            type: "image",
            file: data.File,
            File: data.File,
          };
          dispatch(saveUploadedProfilePicture(body));
        })
        .catch(error => console.log(error));
    }
  };
};

export const updateUserProfile = (body, history, t) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/update.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong!",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveUserInformation(data));
          dispatch(saveUser(data));
          dispatch(
            showSnackBar({
              type: "success",
              message: t("Successfully saved changes"),
            }),
          );
          history.push("/");
        })
        .catch(error => console.log(error));
    }
  };
};

export const deleteUser = (body, history, t) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/delete.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("Something went wrong"),
              }),
            );
          }
        })
        .then(() => {
          if (history) {
            history.push("/login");
          }
        })
        .catch(error => console.log(error));
    }
  };
};
