import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import uniqid from "uniqid";
import { getAllTrippetsFromTheTrip } from "../../store/actions/actions/trippet";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import {StyledTableCell, StyledTableRow} from './TableOne';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  accept: {
    color: "#42ce23",
  },
  decline: {
    color: "#ef2828",
  },
});

const TableTwo = ({ trip_ID, allTrips, user, tripTotalPrice, buddy }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [totalAccepted, setTotalAccepted] = useState(0);
  const [totalDeclined, setTotalDeclined] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [allParticipants, setParticipants] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (selectedTrip) {
      const trippetBody = {
        Language_ID: user.Language_ID,
        Trip_ID: selectedTrip.Trip_ID,
        Type: 2,
        User_ID: user.User_ID,
      };
      dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
    }
  }, [selectedTrip, user, dispatch]);

  useEffect(() => {
    if (selectedTrip) {
      let acceptedParticipants = 0;
      let declinedParticipants = 0;
      let pendingParticipants = 0;
      if (buddy === "participants") {
        let participants = selectedTrip.Participants;
        participants.forEach(buddy => {
          if (
            buddy.ParticipantStatus_ID === "1" ||
            buddy.ParticipantStatus_ID === null ||
            buddy.ParticipantStatus_ID === "0"
          ) {
            pendingParticipants = pendingParticipants + 1;
          } else if (buddy.ParticipantStatus_ID === "2") {
            acceptedParticipants = acceptedParticipants + 1;
          } else if (buddy.ParticipantStatus_ID === "3") {
            declinedParticipants = declinedParticipants + 1;
          }
        });
      } else if (buddy === "organizers") {
        let organizers = selectedTrip.Organizers;
        organizers.forEach(buddy => {
          if (
            buddy.TripOrganizerAcceptanceStatus === "1" ||
            buddy.TripOrganizerAcceptanceStatus === null ||
            buddy.TripOrganizerAcceptanceStatus === "0"
          ) {
            pendingParticipants = pendingParticipants + 1;
          } else if (buddy.TripOrganizerAcceptanceStatus === "2") {
            acceptedParticipants = acceptedParticipants + 1;
          } else if (buddy.TripOrganizerAcceptanceStatus === "3") {
            declinedParticipants = declinedParticipants + 1;
          }
        });
      } else if (buddy === "proxies") {
        let proxies = selectedTrip.Proxies;
        proxies.forEach(buddy => {
          if (
            buddy.TripProxyAcceptanceStatus === "1" ||
            buddy.TripProxyAcceptanceStatus === null ||
            buddy.TripProxyAcceptanceStatus === "0"
          ) {
            pendingParticipants = pendingParticipants + 1;
          } else if (buddy.TripProxyAcceptanceStatus === "2") {
            acceptedParticipants = acceptedParticipants + 1;
          } else if (buddy.TripProxyAcceptanceStatus === "3") {
            declinedParticipants = declinedParticipants + 1;
          }
        });
      }
      setTotalAccepted(acceptedParticipants);
      setTotalDeclined(declinedParticipants);
      setTotalPending(pendingParticipants);
    }
  }, [selectedTrip, buddy]);

  const createData = (name, accepted, declined, pending) => {
    return { name, accepted, declined, pending };
  };

  const calculatePercent = (num, total) => {
    if (num && total) {
      return ((100 * num) / total).toFixed(2);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (trip_ID && allTrips) {
      const trip = allTrips.find(trip => trip.Trip_ID === trip_ID);
      setSelectedTrip(trip);
      setParticipants(trip.Participants);
    }
    return () => {
      setSelectedTrip(null);
    };
  }, [trip_ID, allTrips]);

  const isPending = status => {
    if (status === "1" || status === null || status === "0") {
      return <DoneIcon className={classes.accept} />;
    } else {
      return <CloseIcon className={classes.decline} />;
    }
  };

  const isAccepted = status => {
    if (status === "2") {
      return <DoneIcon className={classes.accept} />;
    } else {
      return <CloseIcon className={classes.decline} />;
    }
  };

  const isDeclined = status => {
    if (status === "3") {
      return <DoneIcon className={classes.accept} />;
    } else {
      return <CloseIcon className={classes.decline} />;
    }
  };

  const renderElements = trip => {
    let allBuddies = [];
    if (buddy === "participants") {
      let participants = trip.Participants;
      participants.forEach(buddy => {
        let element = createData(
          buddy.TripParticipantDisplayName,
          isAccepted(buddy.ParticipantStatus_ID),
          isDeclined(buddy.ParticipantStatus_ID),
          isPending(buddy.ParticipantStatus_ID),
          "100",
        );
        allBuddies.push(element);
      });
    }else if (buddy === "organizers") {
      let organizers = trip.Organizers;
      organizers.forEach(buddy => {
        let element = createData(
          buddy.TripOrganizerDisplayName,
          isAccepted(buddy.TripOrganizerAcceptanceStatus),
          isDeclined(buddy.TripOrganizerAcceptanceStatus),
          isPending(buddy.TripOrganizerAcceptanceStatus),
          "100",
        );
        allBuddies.push(element);
      });
    } else if (buddy === "proxies") {
      let proxies = trip.Proxies;
      proxies.forEach(buddy => {
        let element = createData(
          buddy.TripProxyDisplayName,
          isAccepted(buddy.TripProxyAcceptanceStatus),
          isDeclined(buddy.TripProxyAcceptanceStatus),
          isPending(buddy.TripProxyAcceptanceStatus),
          "100",
        );
        allBuddies.push(element);
      });
    }
    setRows(allBuddies);
  };

  useEffect(() => {
    if (selectedTrip) {
      renderElements(selectedTrip);
    }
    // eslint-disable-next-line
  }, [selectedTrip, buddy]);

  const renderPrice = tripTotalPrice ? tripTotalPrice.SumPrice : "";
  const renderCurrencyName = tripTotalPrice ? tripTotalPrice.CurrencyName : "";
  const renderCurrencySymbol = tripTotalPrice
    ? tripTotalPrice.CurrencySymbol
    : "";

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell rowSpan={1} />
            <StyledTableCell align="left">{t("Name")}</StyledTableCell>
            <StyledTableCell align="left">{t("Accepted")}</StyledTableCell>
            <StyledTableCell align="left">{t("Declined")}</StyledTableCell>
            <StyledTableCell align="left">{t("Pending")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledTableRow key={uniqid()}>
              <TableCell rowSpan={1} />
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.accepted}</TableCell>
              <TableCell align="left">{row.declined}</TableCell>
              <TableCell align="left">{row.pending}</TableCell>
            </StyledTableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2}>{t("Percentage")}</TableCell>
            <TableCell align="left">
              {calculatePercent(totalAccepted, allParticipants.length)}%
            </TableCell>
            <TableCell align="left">
              {calculatePercent(totalDeclined, allParticipants.length)}%
            </TableCell>
            <TableCell align="left">
              {calculatePercent(totalPending, allParticipants.length)}%
            </TableCell>
          </TableRow>
          <StyledTableRow>
            <TableCell colSpan={2}>{t("Total Number")}</TableCell>
            <TableCell align="left">{totalAccepted} / {allParticipants.length}</TableCell>
            <TableCell align="left">{totalDeclined} / {allParticipants.length}</TableCell>
            <TableCell align="left">{totalPending} / {allParticipants.length}</TableCell>
          </StyledTableRow>
          <TableRow>
            <TableCell colSpan={2}>{t("Trip Total Price")}</TableCell>
            <TableCell align="left">
              {renderPrice} {renderCurrencyName} ({renderCurrencySymbol})
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableTwo;
