import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewAdminRoleAccess, changeAdminRoleAccess } from '../../store/actions/actions/adminRoleAccess';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import * as userAccess from '../../userAccess';

const AdminRoleAccessModal = ({ open, handleAdminRoleAccessModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [refTableError, setRefTableError] = useState(false);
  const [formError, setFormError] = useState(false);
  
  const [values, setValues] = useState({
    AdminRole_ID: 0,
    Read: 0,
    Write: 0,
    Change: 0,
    Delete: 0,
    RefTable: 0
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    if (defaultValues) {
        setValues({
            AdminRoleAccess_ID: defaultValues.AdminRoleAccess_ID,
            AdminRole_ID: defaultValues.AdminRole_ID,
            RefTable: defaultValues.RefTable,
            Read: defaultValues.Read,
            Write: defaultValues.Write,
            Change: defaultValues.Change,
            Delete: defaultValues.Delete,
        });
        handleIsEditMode(defaultValues.AdminRoleAccess_ID ? true : false);
        
    } else {
      setValues({
        AdminRoleAccess_ID: 0,
        AdminRole_ID: 0,
        RefTable: 0,
        Read: 0,
        Write: 0,
        Change: 0,
        Delete: 0,
      });
      handleIsEditMode(false);
    }
  }, [defaultValues]);

  const validateForm = ({ RefTable }) => {
    if (RefTable) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };


  const handleSubmit = e => {
    e.stopPropagation();
    if (!refTableError) {
        e.preventDefault();
        const data = {
            AdminRoleAccess_ID: +values.AdminRoleAccess_ID,
            AdminRole_ID: +values.AdminRole_ID,
            RefTable: values.RefTable,
            Read: values.Read,
            Write: values.Write,
            Change: values.Change,
            Delete: values.Delete,
        };
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changeAdminRoleAccess(JSON.stringify(data)));
          handleAdminRoleAccessModalClose();
        }
      } else {
        if (validateForm(data)) {
          dispatch(createNewAdminRoleAccess(JSON.stringify(data)));
          handleAdminRoleAccessModalClose();
        }
      }
    }
  };

  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name === 'RefTable') {
      if (e.target.value > 0) {
        setRefTableError(false);
      } else {
        setRefTableError(true);
      }
    }
    setValues({...values, [e.target.name]: e.target.value});
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const renderTitle = defaultValues ? t('Edit Access') : t('New Access');
  
    
  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;


  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_AdminRoles))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_AdminRoles));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.participantStatusName}>
                <FormControl variant="outlined" className={classes.selectView}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Table')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Table')}
                    name="RefTable"
                    value={values.RefTable}
                    onChange={handleInputs}
                  >
                    <MenuItem value="10">{t('Admin Roles')}</MenuItem>
                    <MenuItem value="20">{t('User Roles')}</MenuItem>
                    <MenuItem value="30">{t('Users')}</MenuItem>
                    <MenuItem value="170">{t('Verification Codes')}</MenuItem>
                    <MenuItem value="180">{t('Config')}</MenuItem>
                    <MenuItem value="40">{t('Settings')}</MenuItem>
                    <MenuItem value="50">{t('Preferences')}</MenuItem>
                    <MenuItem value="60">{t('Countries')}</MenuItem>
                    <MenuItem value="140">{t('Currencies')}</MenuItem>
                    <MenuItem value="70">{t('Languages')}</MenuItem>
                    <MenuItem value="80">{t('Departments')}</MenuItem>
                    <MenuItem value="90">{t('Planning Statuses')}</MenuItem>
                    <MenuItem value="150">{t('Participant Statuses')}</MenuItem>
                    <MenuItem value="160">{t('Trippet Types')}</MenuItem>
                    <MenuItem value="100">{t('Trips')}</MenuItem>
                    <MenuItem value="110">{t('Trippets')}</MenuItem>
                    <MenuItem value="120">{t('TripParticipents')}</MenuItem>
                    <MenuItem value="130">{t('TrippetParticipents')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} className={classes.adminRoleName}>
                <FormControlLabel
                      control={
                      <Checkbox
                          checked={Number(values.Read) === 1}
                          onChange={handleInputs}
                          name="Read"
                          id="Read"
                          color="primary"
                          value={Number(values.Read) === 1 ? 0 : 1}
                      />
                      }
                      label={t('Read')}
                  />
              </Grid>
              <Grid item xs={12} md={12} className={classes.adminRoleName}>
                <FormControlLabel
                      control={
                      <Checkbox
                          checked={Number(values.Write) === 1}
                          onChange={handleInputs}
                          name="Write"
                          id="Write"
                          color="primary"
                          value={Number(values.Write) === 1 ? 0 : 1}
                      />
                      }
                      label={t('Write')}
                  />
              </Grid>
              <Grid item xs={12} md={12} className={classes.adminRoleName}>
                <FormControlLabel
                      control={
                      <Checkbox
                          checked={Number(values.Change) === 1}
                          onChange={handleInputs}
                          name="Change"
                          id="Change"
                          color="primary"
                          value={Number(values.Change) === 1 ? 0 : 1}
                      />
                      }
                      label={t('Change')}
                  />
              </Grid>
              <Grid item xs={12} md={12} className={classes.adminRoleName}>
                <FormControlLabel
                      control={
                      <Checkbox
                          checked={Number(values.Delete) === 1}
                          onChange={handleInputs}
                          name="Delete"
                          id="Delete"
                          color="primary"
                          value={Number(values.Delete) === 1 ? 0 : 1}
                      />
                      }
                      label={t('Delete')}
                  />
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            autoFocus
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleAdminRoleAccessModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default AdminRoleAccessModal;
