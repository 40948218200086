export const columns = [
    { id: 'actions', label: '', minWidth: 70, type: 'actions' },
    { id: 'SortIndex', label: 'Sort#', minWidth: 40, type: 'Name' },
    { id: 'Name', label: 'Name', minWidth: 80, type: 'Name' },
    {
      id: 'Type',
      label: 'Type',
      minWidth: 80,
      align: 'center',
      type: 'Name',
    },
    { id: 'Listing', label: 'Listing', minWidth: 80, type: 'Listing' },
    { id: 'Comments', label: 'Comments', minWidth: 80, type: 'Comments' },
    { id: 'IsDefault', label: 'IsDefault', minWidth: 80, type: 'IsDefault' },
  ];
  