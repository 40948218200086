import * as actionTypes from '../actions/actionTypes/actionTypes';
import { startOfDay, endOfDay, startOfYesterday, endOfYesterday, startOfWeek, endOfWeek, startOfMonth, endOfMonth, format } from 'date-fns';

const initialState = {
  notifications: null,
  seen: null,
  creator: null,
  trippetName: null,
  tripName: null,
  tripNameList: null,
  trippetNameList: null,
  creatorList: null,
  startDate: null,
  endDate: null,
  tripInvitation: 0,
  trippetInvitation: 0,
  singleTrippet: null,
  singleTrip: null,
  interval: 30000 //ms
};

const converToLocalTime = dateTime => {
  let date = format(new Date(dateTime), 'yyyy-MM-dd');
  let time = format(new Date(dateTime), 'HH:mm:ss');
  return `${date}T${time}`;
};

const getDate = date => {
  let startDate = null;
  let endDate = null;
  if(date === 'today'){
    startDate = converToLocalTime(startOfDay(new Date()));
    endDate = converToLocalTime(endOfDay(new Date()));
  }else if(date === 'yesterday'){
    startDate = converToLocalTime(startOfYesterday(new Date()));
    endDate = converToLocalTime(endOfYesterday(new Date()));
  }else if(date === 'thisWeek'){
    startDate = converToLocalTime(startOfWeek(new Date(), { weekStartsOn: 1 }));
    endDate = converToLocalTime(endOfWeek(new Date(), { weekStartsOn: 1 }));
  }
  else if(date === 'thisMonth'){
    startDate = converToLocalTime(startOfMonth(new Date()));
    endDate = converToLocalTime(endOfMonth(new Date()));
  }
  return [startDate, endDate]
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_USER_NOTIFICATION: {
      return {
        ...state,
        notifications: action.payload
      }
    }
    case actionTypes.SAVE_USER_NOTIFICATION_INTERVAL: {
      return {
        ...state,
        interval: action.payload
      }
    }
    case actionTypes.SAVE_TRIP_LIST_NOTIFICATION: {
      return {
        ...state,
        tripNameList: action.payload
      }
    }
    case actionTypes.SAVE_TRIPPET_LIST_NOTIFICATION: {
      return {
        ...state,
        trippetNameList: action.payload
      }
    }
    case actionTypes.SAVE_CREATOR_LIST_NOTIFICATION: {
      return {
        ...state,
        creatorList: action.payload
      }
    }
    case actionTypes.SAVE_NOTIFICATION_DATA: {
      return {
        ...state,
        tripInvitation: action.payload.NumNewTrips,
        trippetInvitation: action.payload.NumNewTrippets,
      }
    }
    case actionTypes.SAVE_SINGLE_TRIPPET_INFO: {
      return {
        ...state,
        singleTrippet: action.payload,
      }
    }
    case actionTypes.SAVE_SINGLE_TRIP_INFO: {
      return {
        ...state,
        singleTrip: action.payload,
      }
    }
    case actionTypes.SAVE_NOTIFICATION_FILTERS: {
      let [startDate, endDate] = getDate(action.payload.date);
      return {
        ...state,
        seen: action.payload.seen,
        creator: action.payload.creator,
        trippetName: action.payload.trippetName,
        tripName: action.payload.tripName,
        startDate: startDate,
        endDate: endDate,
      }
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        notifications: null,
        seen: null,
        creator: null,
        trippetName: null,
        tripName: null,
        tripInvitation: 0,
        trippetInvitation: 0,
        interval: 30000 //ms
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default notificationReducer;
