import React, { useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import { useSelector } from 'react-redux';

const MuiDatePicker = ({
  selectedDate,
  handleDateChange,
  label,
  className,
}) => {
  const { t } = useTranslation();
  const [dateLanguage, setLanguage] = useState('');
  const language = useSelector(state => state.programSettings.language);

  useEffect(() => {
    if (language === 'en') {
      setLanguage(enLocale);
    } else if (language === 'de') {
      setLanguage(deLocale);
    }
  }, [language]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateLanguage}>
      <DatePicker
        label={t(label)}
        value={selectedDate}
        onChange={handleDateChange}
        animateYearScrolling
        autoOk
        name={label}
        format="dd/MM/yyyy"
        inputVariant="outlined"
        className={className}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MuiDatePicker;
