import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import InlinePreferenceItems from '../preferenceItems/InlinePreferenceItems';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import { columns } from './columns';
import { useSelector, useDispatch } from 'react-redux';
import { openRow, closeRow } from '../../store/actions/actions/preferenceCategory';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Row = ({row, renderData, id}) => {
  const dispatch = useDispatch();
  const rows = useSelector(state => state.preferenceCategory.rows)
  const classes = useRowStyles();

  const handleRows = () => {
    if(rows && rows[id]){
      dispatch(closeRow(id));
    }else {
      dispatch(openRow(id));
    }
  }

  return (
    <Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.PreferenceCategory_ID}
        className={classes.root}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={handleRows}>
            {rows && rows[id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column, index) => {
          const value = row[column.id];
          const styleName =
            index === 0
              ? classNames(classes.tableCell, classes[row.color])
              : classes.tableCell;
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={styleName}
            >
              {renderData(column, value, row)}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={rows && rows[id]} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <InlinePreferenceItems PreferenceCategory={row}></InlinePreferenceItems>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default Row;
