export const columns = [
    { id: 'actions', label: '', minWidth: 70, type: 'actions' },
    { id: 'Name', label: 'Name', minWidth: 80, type: 'Name' },
    {
      id: 'Abbreviation',
      label: 'Abbreviation',
      minWidth: 80,
      align: 'center',
      type: 'Name',
    },
    { id: 'Icon', label: 'Icon', minWidth: 80, align: 'center', type: 'Icon' },
    { id: 'IsDefault', label: 'IsDefault', minWidth: 40, align: 'center', type: 'Name' },
  ];
  