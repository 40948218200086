import React, { useEffect, useState } from "react";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Table from "./Table";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";

const TripSelector = ({openTripSelector, setIsOpen, setSelectedTrip}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector(state => state.user.user);
  const entireTrips = useSelector(state => state.trip.entireTrips);
  const allUsers = useSelector(state => state.app.allUsers);
  const [allTrips, setAllTrips] = useState(null);
  
  const [search, setSearch] = useState('');

  useEffect(() => {
    let array = [];
    if (entireTrips && entireTrips.length > 0) {
      entireTrips.forEach(trip => {
        // if (trip.IsDeleted === null || trip.IsDeleted === '0') {
        //   array.push(trip);
        // }
        if (search)
        {
          if ( trip.Name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
            array.push(trip);
        }
        else
        array.push(trip);
      });
      setAllTrips(array);
    } else {
      setAllTrips([]);
    }
  }, [entireTrips, search]);

  const handleSearch = e => {
    e.stopPropagation();
    setSearch(e.target.value);
  };
  
  const handleCancelClick = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={openTripSelector}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {'Trip Selector'}
        </DialogTitle>
        <DialogContent>
          <Grid container justify="center" className={classes.containerMyTrip}>
            <Grid item xs={12} className={classes.languageName}>
                <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    id="search"
                    label={t('search')}
                    name="search"
                    value={search}
                    onChange={handleSearch}
                />
            </Grid>
            <Grid item xs={12}>
              <Table
                data={allTrips}
                allUsers={allUsers}
                type="my-trip"
                user={user}
                setSelectedTrip={setSelectedTrip}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    
  );
};

export default TripSelector;
