export const columns = [
  { id: 'actions', label: '', minWidth: 30, type: 'actions', align: 'center' },
  {
    id: 'type',
    label: 'Type',
    minWidth: 110,
    type: 'TrippetTypeLookName',
    filter: 'typelooks',
  },
  { id: 'title', label: 'Title', minWidth: 80, type: 'Title' },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 110,
    type: 'StartDate',
    filter: 'startdates',
  },
  {
    id: 'startTime',
    label: 'Time',
    minWidth: 110,
    type: 'StartDate',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 110,
    type: 'EndDate',
    filter: 'enddates',
  },
  {
    id: 'endTime',
    label: 'Time',
    minWidth: 110,
    type: 'EndDate',
  },
  {
    id: 'from',
    label: 'From',
    minWidth: 80,
    type: 'FromPlace',
  },
  {
    id: 'to',
    label: 'To',
    minWidth: 80,
    type: 'ToPlace',
  },
  // {
  //   id: 'participants',
  //   label: 'Participants',
  //   minWidth: 80,
  //   type: 'DisplayName',
  //   filter: 'participants',
  // },
  {
    id: 'distance',
    label: 'Distance',
    minWidth: 80,
    type: 'Distance',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 110,
    type: 'OwnerPlanningStatus_ID',
    filter: 'ownerplanningstatuses',
    align: 'center',
  },
  {
    id: 'lastChanged',
    label: 'Last Changed',
    minWidth: 80,
    align: 'center',
    type: 'ModifiedDate',
  },
  {
    id: 'children',
    minWidth: 80,
    align: 'center',
  },
];


export const childrenColumns = [
  { id: 'actions', label: '', minWidth: 110, type: 'actions' },
  {
    id: 'type',
    label: 'Type',
    minWidth: 110,
    type: 'TrippetTypeLookName',
    filter: 'typelooks',
  },
  { id: 'title', label: 'Title', minWidth: 80, type: 'Title' },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 110,
    type: 'StartDate',
    filter: 'startdates',
  },
  {
    id: 'startTime',
    label: 'Time',
    minWidth: 110,
    type: 'StartDate',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 110,
    type: 'EndDate',
    filter: 'enddates',
  },
  {
    id: 'endTime',
    label: 'Time',
    minWidth: 110,
    type: 'EndDate',
  },
  {
    id: 'from',
    label: 'From',
    minWidth: 80,
    type: 'FromPlace',
  },
  {
    id: 'to',
    label: 'To',
    minWidth: 80,
    type: 'ToPlace',
  },
  // {
  //   id: 'participants',
  //   label: 'Participants',
  //   minWidth: 80,
  //   type: 'DisplayName',
  //   filter: 'participants',
  // },
  {
    id: 'distance',
    label: 'Distance',
    minWidth: 80,
    type: 'Distance',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 110,
    type: 'OwnerPlanningStatus_ID',
    filter: 'ownerplanningstatuses',
    align: 'center',
  },
  {
    id: 'lastChanged',
    label: 'Last Changed',
    minWidth: 80,
    align: 'center',
    type: 'ModifiedDate',
  },
  {
    id: 'dnd',
    minWidth: 80,
    align: 'center',
  },
];
