import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL } from '../../../config';

export const saveCurrentAdminRoleAccess = payload => ({
  type: adminActionTypes.SAVE_CURRENT_ADMINROLEACCESS,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_ADMINROLEACCESS_CURRENT_PAGE,
  payload,
});

export const getAdminRoleAccessById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/adminrole/access/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentAdminRoleAccess(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewAdminRoleAccess = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/adminrole/access/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503 && response.type !== 'cors') {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentAdminRoleAccess(data));
          dispatch(getAllAdminRoleAccesses(JSON.stringify({AdminRole_ID: data.AdminRole_ID})));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeAdminRoleAccess = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/adminrole/access/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(body);
          dispatch(getAdminRoleAccessById(body.AdminRoleAccess_ID));
          dispatch(getAllAdminRoleAccesses(JSON.stringify({AdminRole_ID: data.AdminRole_ID})));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllAdminRoleAccesses = payload => ({
  type: adminActionTypes.SAVE_ALL_ADMINROLEACCESSES,
  payload,
});

export const saveAdminRoleAccessSortElement = payload => ({
  type: adminActionTypes.SAVE_ADMINROLEACCESS_SORT_ELEMENT,
  payload,
});

export const saveAdminRoleAccessSortByDirection = payload => ({
  type: adminActionTypes.SAVE_ADMINROLEACCESS_SORT_BY_DIRECTION,
  payload,
});

export const saveAdminRoleAccessPaginationData = payload => ({
  type: adminActionTypes.SAVE_ADMINROLEACCESS_PAGINATION_DATA,
  payload,
});

export const getAllAdminRoleAccesses = body => {
  return dispatch => {
    // AdminRole_ID is required.
    if (!body)
      return;
    fetch(`${BASE_URL}api/adminrole/access/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllAdminRoleAccesses(data.Data));
        dispatch(saveAdminRoleAccessPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

// provide AdminRole_ID to reload relevant data
export const deleteAdminRoleAccess = (body, AdminRole_ID) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/adminrole/access/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else 
            dispatch(getAllAdminRoleAccesses(JSON.stringify({AdminRole_ID: AdminRole_ID})));
        })
        .catch(error => console.log(error));
    }
  };
};
