import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import Table from "./Table";
import TableTwo from "./TableViewsTwo/TableTwo";
import TableThree from "./TableViewsThree/TableThree";
import TableFour from "./TableViewFour/TableFour";
import TableFive from "./TableViewFive/TableFive";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useSelector, useDispatch } from "react-redux";
import {
  hideTrippetForm,
  showTrippetForm,
  clearFiles,
  getAllTrippetsFromTheTrip,
  saveTrippetSortByDirection,
  saveTrippetSortElement,
  savePaginationPage,
  getFilterData,
  clearFilterTrippetResults,
  saveTrippetUsedFilters,
  getAllParentWithChildrensTrippets,
  saveTrippetChildrenStatus,
  duplicateMultipleTrippet,
  duplicateTrippet,
  createTrippet,
  UngroupTrippets,
  saveReorderedChildrenTrippets,
} from "../../store/actions/actions/trippet";
import classNames from "classnames";
import Pagination from "../Pagination/Pagination";
import FilterModal from "../FilterModal/FilterModal";
import TrippetForm from "../TrippetForm/TrippetForm";
import GroupingModal from "./groupingModal";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import RecommendationModal from "../RecommendationModal/RecommendationModal";
import DeleteSingleTrippet from "../DeleteSingleTrippet/DeleteSingleTrippet";
import AddChildTrippetModal from "./addChildTrippet";

const TrippetTable = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [tableView, setTableView] = useState({
    first: true,
    second: false,
    third: false,
    four: false,
    five: false,
  });
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortedElement, setSortedElement] = useState("ModifiedDate");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isAddChildOpen, setIsAddChildOpen] = useState(false);
  const [isDeleteSingleTrippetOpen, setIsDeleteSingleTrippetOpen] = useState(
    false,
  );
  const [showButtons, setShowButtons] = useState(false);
  const [hideActionButtons, setHideActionButtons] = useState(false);
  const [clickedFilter, setClickedFilter] = useState(null);
  const [filterResults, setFilterResults] = useState([]);
  const [usedFilters, setUsedFilets] = useState([]);
  const [checkedTrippets, setCheckedTrippets] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [toogleChilds, setToogleChilds] = useState(false);
  const [deletingType, setDeletingType] = useState(0);
  const [selectedTrippets, setSelectedTrippets] = useState([]);
  const [sortedBy, setSortedBy] = useState({
    Title: "asc",
    TrippetTypeLookName: "asc",
    StartDate: "asc",
    EndDate: "asc",
    FromPlace: "asc",
    ToPlace: "asc",
    DisplayName: "asc",
    Day: "asc",
    Price: "asc",
    Link: "asc",
    Description: "asc",
    IsWholeDay: "asc",
    OwnerUser_ID: "asc",
    Distance: "asc",
    IsPrivate: "asc",
    OwnerPlanningStatus_ID: "asc",
    ModifiedDate: "desc",
    DefaultPicture: "asc",
  });
  const [disabledFilters, setDisabledFilters] = useState({
    startdates: false,
    enddates: false,
    participants: false,
    typelooks: false,
    owners: false,
    ownerplanningstatuses: false,
    title: false,
    link: false,
    isprivates: false,
    startdays: false,
  });
  const [activeFilters, setActiveFilters] = useState({
    startdates: false,
    enddates: false,
    participants: false,
    typelooks: false,
    owners: false,
    ownerplanningstatuses: false,
    title: false,
    link: false,
    isprivates: false,
    startdays: false,
  });
  const isOpenTrippetFrom = useSelector(
    state => state.trippet.isOpenTrippetFrom,
  );
  const calendarInstance = useSelector(state => state.trippet.calendarInstance);
  const sortElement = useSelector(state => state.trippet.sortElement);
  const sortByDirection = useSelector(state => state.trippet.sortByDirection);
  const currentTrippet = useSelector(state => state.trippet.currentTrippet);
  const currentTrip = useSelector(state => state.trip.currentTrip);
  const pickedTime = useSelector(state => state.trippet.pickedTime);
  const user = useSelector(state => state.user.user);
  const currentPage = useSelector(state => state.trippet.page);
  const filterData = useSelector(state => state.trippet.filterData);
  const parentsTrippetsWithChildrenNum = useSelector(
    state => state.trippet.parentsTrippetsWithChildrenNum,
  );
  const trippets = useSelector(
    state => state.trippet.parentsTrippetsWithChildren,
  );
  const allUsers = useSelector(state => state.app.allUsers);
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const [canGroup, setCanGroup] = useState(false);
  const [canUngroup, setCanUngroup] = useState(false);
  const [saveOrdering, setSaveOrdering] = useState(false);
  const [flag, setFlag] = useState(false);
  const [showAddChild, setShowChild] = useState(false);
  const [numberOfTrippets, setNumberOfTrippets] = useState(0);
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(() => {
    if (trippets && trippets.length > 0) {
      setNumberOfTrippets(trippets.length);
    }
  }, [trippets]);

  useEffect(() => {
    if (trippets && trippets.length > 0) {
      if (!flag) {
        let obj = {};
        trippets.forEach(trippet => {
          obj[trippet.Trippet_ID] = false;
          if (trippet.Children.length > 0) {
            trippet.Children.forEach(child => {
              obj[child.Trippet_ID] = false;
            });
          }
        });
        setCheckedTrippets(obj);
        setFlag(true);
      }
    }
    // eslint-disable-next-line
  }, [trippets, currentTrip.Trip_ID, numberOfTrippets]);

  useEffect(() => {
    if (selectedTrippets && selectedTrippets.length > 0) {
      setShowButtons(true);
      if (selectedTrippets.length > 1) {
        setHideActionButtons(true);
      } else {
        setHideActionButtons(false);
      }
    } else {
      setShowButtons(false);
    }
  }, [selectedTrippets]);

  const clearAllSelected = () => {
    setAllChecked(false);
    setSelectedTrippets([]);
    let obj = {};
    trippets.forEach(trippet => {
      obj[trippet.Trippet_ID] = false;
      if (trippet.Children && trippet.Children.length > 0) {
        trippet.Children.forEach(child => (obj[child.Trippet_ID] = false));
      }
    });
    setCheckedTrippets(obj);
  };

  useEffect(() => {
    if(selectedTrippets &&
      selectedTrippets.length > 0 &&
      !allChecked){
        setIndeterminate(true);
      }else {
        setIndeterminate(false);
      }

  }, [selectedTrippets, allChecked])

  const handleAllChecked = event => {
    if(indeterminate){
      setAllChecked(false);
      setSelectedTrippets([]);
      let obj = {};
      trippets.forEach(trippet => {
        obj[trippet.Trippet_ID] = false;
        if (trippet.Children && trippet.Children.length > 0) {
          trippet.Children.forEach(child => (obj[child.Trippet_ID] = false));
        }
      });
      setCheckedTrippets(obj);
    }else {
      setAllChecked(event.target.checked);
      let array = [...trippets];
      trippets.forEach(trippet => {
        if (trippet.Children && trippet.Children.length > 0) {
          array = [...array, ...trippet.Children];
        }
      });
      setSelectedTrippets([...array]);
      let obj = {};
      trippets.forEach(trippet => {
        obj[trippet.Trippet_ID] = true;
        if (trippet.Children && trippet.Children.length > 0) {
          trippet.Children.forEach(child => (obj[child.Trippet_ID] = true));
        }
      });
      setCheckedTrippets(obj);
    }
    if(!event.target.checked){
      setAllChecked(false);
      setSelectedTrippets([]);
      let obj = {};
      trippets.forEach(trippet => {
        obj[trippet.Trippet_ID] = false;
        if (trippet.Children && trippet.Children.length > 0) {
          trippet.Children.forEach(child => (obj[child.Trippet_ID] = false));
        }
      });
      setCheckedTrippets(obj);
      setIndeterminate(true);
    }
  }

  const handleCheckboxChange = (event, item) => {
    event.stopPropagation();
    let obj = { ...checkedTrippets };
    if (event.target.checked) {
      setSelectedTrippets([...selectedTrippets, item]);
    } else {
      let items = [...selectedTrippets];
      let id = "";
      selectedTrippets.forEach((trippet, index) => {
        if (trippet.Trippet_ID === item.Trippet_ID) {
          id = index;
        }
      });
      items.splice(id, 1);
      setSelectedTrippets(items);
    }
    let data = {
      ...obj,
      [event.target.name]: event.target.checked,
    };
    setCheckedTrippets(data);
  };

  useEffect(() => {
    if (user && currentTrip) {
      let body = {
        Language_ID: user.Language_ID,
        Trip_ID: currentTrip.Trip_ID,
        OnlyParents: 1,
        AppendChildren: 1,
        // IsPrivate: 1,
      };
      dispatch(getAllParentWithChildrensTrippets(JSON.stringify(body)));
    }
  }, [user, currentTrip, dispatch]);

  useEffect(() => {
    if (parentsTrippetsWithChildrenNum) {
      setTotalItemsCount(+parentsTrippetsWithChildrenNum);
    }
  }, [parentsTrippetsWithChildrenNum]);

  useEffect(() => {
    if (selectedTrippets && selectedTrippets.length > 0) {
      let array = selectedTrippets.find(
        trippet => trippet.CanBeGrouped === "0" || trippet.CanBeGrouped === 0,
      );
      let parents = selectedTrippets.find(
        trippet => trippet.IsChildOf === "" || trippet.IsChildOf === null,
      );
      if (array) {
        setCanGroup(false);
      } else {
        setCanGroup(true);
      }
      if (parents) {
        setCanUngroup(false);
      } else {
        setCanUngroup(true);
      }
    } else {
      setCanGroup(false);
      setCanUngroup(false);
    }
  }, [selectedTrippets]);

  useEffect(() => {
    if (
      selectedTrippets &&
      selectedTrippets.length > 0 &&
      selectedTrippets.length < 2
    ) {
      if (
        selectedTrippets[0].Children &&
        selectedTrippets[0].Children.length > 0
      ) {
        setShowChild(true);
      }
    } else {
      setShowChild(false);
    }
  }, [selectedTrippets]);

  const handleTrippetFormClose = () => {
    dispatch(hideTrippetForm());
    dispatch(clearFiles());
    calendarInstance.render(true);
  };

  const handleTrippetFormOpen = () => {
    dispatch(showTrippetForm());
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleDeleteSingleTrippetModalClose = () => {
    setIsDeleteSingleTrippetOpen(false);
  };

  const handleDeleteSingleTrippetModalOpen = () => {
    setIsDeleteSingleTrippetOpen(true);
  };

  const handleParticipantsModalOpen = event => {
    event.stopPropagation();
    setIsParticipantsModalOpen(true);
  };

  const handleParticipantsModalClose = event => {
    event.stopPropagation();
    setIsParticipantsModalOpen(false);
  };

  const handleTableView = value => () => {
    setTableView({
      first: false,
      second: false,
      third: false,
      [value]: true,
    });
  };

  const onPaginationChange = activePage => {
    dispatch(savePaginationPage(activePage));
  };

  const resetSoryBy = () => {
    setSortedBy({
      Title: "asc",
      TrippetTypeLookName: "asc",
      StartDate: "asc",
      EndDate: "asc",
      FromPlace: "asc",
      ToPlace: "asc",
      DisplayName: "asc",
      Day: "asc",
      Price: "asc",
      Link: "asc",
      Description: "asc",
      IsWholeDay: "asc",
      OwnerUser_ID: "asc",
      Distance: "asc",
      IsPrivate: "asc",
      OwnerPlanningStatus_ID: "asc",
      ModifiedDate: "asc",
    });
  };

  const handleSortBy = id => () => {
    switch (id) {
      case "type":
        setSortedElement("TrippetTypeLookName");
        dispatch(saveTrippetSortElement("TrippetTypeLookName"));
        if (sortedBy.TrippetTypeLookName === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            TrippetTypeLookName: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              TrippetTypeLookName: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            TrippetTypeLookName: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              TrippetTypeLookName: "asc",
            }),
          );
        }
        break;
      case "lastChanged":
        setSortedElement("ModifiedDate");
        dispatch(saveTrippetSortElement("ModifiedDate"));
        if (sortedBy.ModifiedDate === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            ModifiedDate: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              ModifiedDate: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            ModifiedDate: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              ModifiedDate: "asc",
            }),
          );
        }
        break;
      case "title":
        setSortedElement("Title");
        dispatch(saveTrippetSortElement("Title"));
        if (sortedBy.Title === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Title: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              Title: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Title: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              Title: "asc",
            }),
          );
        }
        break;
      case "startDate":
        setSortedElement("StartDate");
        dispatch(saveTrippetSortElement("StartDate"));
        if (sortedBy.StartDate === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            StartDate: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              StartDate: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            StartDate: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              StartDate: "asc",
            }),
          );
        }
        break;
      case "startTime":
        setSortedElement("StartDate");
        dispatch(saveTrippetSortElement("StartDate"));
        if (sortedBy.StartDate === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            StartDate: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              StartDate: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            StartDate: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              StartDate: "asc",
            }),
          );
        }
        break;
      case "endDate":
        setSortedElement("EndDate");
        dispatch(saveTrippetSortElement("EndDate"));
        if (sortedBy.EndDate === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            EndDate: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              EndDate: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            EndDate: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              EndDate: "asc",
            }),
          );
        }
        break;
      case "endTime":
        setSortedElement("EndDate");
        dispatch(saveTrippetSortElement("EndDate"));
        if (sortedBy.EndDate === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            EndDate: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              EndDate: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            EndDate: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              EndDate: "asc",
            }),
          );
        }
        break;
      case "from":
        setSortedElement("FromPlace");
        dispatch(saveTrippetSortElement("FromPlace"));
        if (sortedBy.FromPlace === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            FromPlace: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              FromPlace: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            FromPlace: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              FromPlace: "asc",
            }),
          );
        }
        break;
      case "to":
        setSortedElement("ToPlace");
        dispatch(saveTrippetSortElement("ToPlace"));
        if (sortedBy.ToPlace === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            ToPlace: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              ToPlace: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            ToPlace: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              ToPlace: "asc",
            }),
          );
        }
        break;
      case "participants":
        setSortedElement("DisplayName");
        dispatch(saveTrippetSortElement("DisplayName"));
        if (sortedBy.DisplayName === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            DisplayName: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              DisplayName: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            DisplayName: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              DisplayName: "asc",
            }),
          );
        }
        break;
      case "responsible":
        setSortedElement("DisplayName");
        dispatch(saveTrippetSortElement("DisplayName"));
        if (sortedBy.DisplayName === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            DisplayName: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              DisplayName: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            DisplayName: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              DisplayName: "asc",
            }),
          );
        }
        break;
      case "trippetOwner":
        setSortedElement("OwnerUser_ID");
        dispatch(saveTrippetSortElement("OwnerUser_ID"));
        if (sortedBy.OwnerUser_ID === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            OwnerUser_ID: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              OwnerUser_ID: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            OwnerUser_ID: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              OwnerUser_ID: "asc",
            }),
          );
        }
        break;
      case "day":
        setSortedElement("StartDay");
        dispatch(saveTrippetSortElement("StartDay"));
        if (sortedBy.StartDay === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            StartDay: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              StartDay: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            StartDay: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              StartDay: "asc",
            }),
          );
        }
        break;
      case "price":
        setSortedElement("Price");
        dispatch(saveTrippetSortElement("Price"));
        if (sortedBy.Price === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Price: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              Price: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Price: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              Price: "asc",
            }),
          );
        }
        break;
      case "link":
        setSortedElement("Link");
        dispatch(saveTrippetSortElement("Link"));
        if (sortedBy.Link === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Link: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              Link: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Link: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              Link: "asc",
            }),
          );
        }
        break;
      case "image":
        setSortedElement("DefaultPicture");
        dispatch(saveTrippetSortElement("DefaultPicture"));
        if (sortedBy.DefaultPicture === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            DefaultPicture: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              DefaultPicture: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            DefaultPicture: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              DefaultPicture: "asc",
            }),
          );
        }
        break;
      case "distance":
        setSortedElement("Distance");
        dispatch(saveTrippetSortElement("Distance"));
        if (sortedBy.Distance === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Distance: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              Distance: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Distance: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              Distance: "asc",
            }),
          );
        }
        break;
      case "isWholeDay":
        setSortedElement("IsWholeDay");
        dispatch(saveTrippetSortElement("IsWholeDay"));
        if (sortedBy.IsWholeDay === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            IsWholeDay: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              IsWholeDay: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            IsWholeDay: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              IsWholeDay: "asc",
            }),
          );
        }
        break;
      case "isPrivate":
        setSortedElement("IsPrivate");
        dispatch(saveTrippetSortElement("IsPrivate"));
        if (sortedBy.IsPrivate === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            IsPrivate: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              IsPrivate: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            IsPrivate: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              IsPrivate: "asc",
            }),
          );
        }
        break;
      case "status":
        setSortedElement("OwnerPlanningStatus_ID");
        dispatch(saveTrippetSortElement("OwnerPlanningStatus_ID"));
        if (sortedBy.OwnerPlanningStatus_ID === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            OwnerPlanningStatus_ID: "desc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              OwnerPlanningStatus_ID: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            OwnerPlanningStatus_ID: "asc",
          });
          dispatch(
            saveTrippetSortByDirection({
              ...sortedBy,
              OwnerPlanningStatus_ID: "asc",
            }),
          );
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (user) {
      let trippetBody;
      if (user.User_ID === currentTrip.OwnerUser_ID) {
        trippetBody = {
          Language_ID: user.Language_ID,
          Trip_ID: +currentTrip.Trip_ID,
          Page: currentPage,
          RowsPerPage: 50,
          OrderBy: sortedElement,
          // Type: 2,
          OrderDir: sortedBy[sortedElement],
          User_ID: user.User_ID,
          OnlyParents: 1,
          AppendChildren: 1,
        };
      } else {
        trippetBody = {
          Language_ID: user.Language_ID,
          Trip_ID: +currentTrip.Trip_ID,
          Page: currentPage,
          RowsPerPage: 50,
          OrderBy: sortedElement,
          OrderDir: sortedBy[sortedElement],
          // Type: 2,
          User_ID: user.User_ID,
          OnlyParents: 1,
          AppendChildren: 1,
        };
      }

      if (filterResults.length > 0) {
        dispatch(saveTrippetUsedFilters(filterResults));
        filterResults.forEach(item => {
          if (item !== [] || item !== "") {
            trippetBody[item.name] = item.body;
          }
        });
      } else {
        dispatch(saveTrippetUsedFilters(null));
      }

      dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
      dispatch(getAllParentWithChildrensTrippets(JSON.stringify(trippetBody)));
    }
  }, [
    sortedElement,
    sortedBy,
    currentTrip.Trip_ID,
    currentPage,
    dispatch,
    user.User_ID,
    filterResults,
    user,
    currentTrip.OwnerUser_ID,
    currentTrip,
  ]);

  useEffect(() => {
    dispatch(savePaginationPage(1));
  }, [dispatch, sortedElement]);

  const handleOpenFilterModal = column => () => {
    if (column.filter) {
      setIsFilterModalOpen(true);
      setClickedFilter(column);
      let body = {
        path: column.filter,
        data: {
          Language_ID: user.Language_ID,
          User_ID: user.User_ID,
          Trip_ID: currentTrip.Trip_ID,
          OrderBy: column.type,
          OrderDir: "asc",
          // Type: "2",
        },
      };
      if (filterResults.length > 0) {
        filterResults.forEach(item => {
          if (item !== [] || item !== "") {
            if (item.type !== column.filter) {
              body.data[item.name] = item.body;
            }
          }
        });
      }
      if (body.path !== "title" && body.path !== "link") {
        dispatch(getFilterData(body));
      }
    }
  };

  const checkUsedFilters = filterName => {
    let status = "";
    if (usedFilters.length > 0) {
      usedFilters.forEach(filter => {
        if (filter === filterName) {
          status = false;
        } else {
          status = true;
        }
      });
    } else {
      status = true;
    }
    return status;
  };

  const checkActiveFilters = filters => {
    let allFilters = {
      startdates: false,
      enddates: false,
      participants: false,
      organizers: false,
      typelooks: false,
      owners: false,
      ownerplanningstatuses: false,
      title: false,
      link: false,
    };
    if (filters.length > 0) {
      filters.forEach(filter => {
        allFilters = {
          ...allFilters,
          [filter]: true,
        };
      });
    } else {
      allFilters = {
        startdates: false,
        enddates: false,
        participants: false,
        organizers: false,
        typelooks: false,
        owners: false,
        ownerplanningstatuses: false,
        title: false,
        link: false,
      };
    }
    setActiveFilters(allFilters);
  };

  useEffect(() => {
    checkActiveFilters(usedFilters);
    if (usedFilters.length >= 3) {
      let filters = {
        startdates: true,
        enddates: true,
        participants: true,
        organizers: true,
        typelooks: true,
        owners: true,
        ownerplanningstatuses: true,
        title: true,
        link: true,
        isprivates: true,
        startdays: true,
      };
      usedFilters.forEach(filter => {
        filters = {
          ...filters,
          [filter]: false,
        };
      });
      setDisabledFilters(filters);
    } else {
      setDisabledFilters({
        startdates: false,
        enddates: false,
        participants: false,
        organizers: false,
        typelooks: false,
        owners: false,
        ownerplanningstatuses: false,
        title: false,
        link: false,
        isprivates: false,
        startdays: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedFilters]);

  const clearFilteredData = name => {
    if (name) {
      let array = [...usedFilters];
      array.forEach((filter, index) => {
        if (filter === name) {
          array.splice(index, 1);
        }
      });
      setUsedFilets(array);
    }
  };

  const handleFilterValues = (item, values) => {
    let array = [...filterResults];
    if (item === "startdates") {
      if (values && values.length === 0) {
        clearFilteredData("startdates");
        array.forEach((item, index) => {
          if (item.type === "startdates") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "startdates") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "StartDates",
          body: [],
          type: "startdates",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.StartDate);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "typelooks") {
      if (values && values.length === 0) {
        clearFilteredData("typelooks");
        array.forEach((item, index) => {
          if (item.type === "typelooks") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "typelooks") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "TrippetTypeLook_IDs",
          body: [],
          type: "typelooks",
          data: values,
        };
        values.forEach(item => {
          data.body.push(+item.TrippetTypeLook_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "enddates") {
      if (values && values.length === 0) {
        clearFilteredData("enddates");
        array.forEach((item, index) => {
          if (item.type === "enddates") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "enddates") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "EndDates",
          body: [],
          type: "enddates",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.EndDate);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "participants") {
      if (values && values.length === 0) {
        clearFilteredData("participants");
        array.forEach((item, index) => {
          if (item.type === "participants") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "participants") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "TrippetParticipantUser_IDs",
          body: [],
          type: "participants",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.TrippetParticipantUser_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "organizers") {
      if (values && values.length === 0) {
        clearFilteredData("organizers");
        array.forEach((item, index) => {
          if (item.type === "organizers") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "organizers") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "TrippetOrganizerUser_IDs",
          body: [],
          type: "organizers",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.TrippetOrganizerUser_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "owners") {
      if (values && values.length === 0) {
        clearFilteredData("owners");
        array.forEach((item, index) => {
          if (item.type === "owners") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "owners") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "OwnerUser_IDs",
          body: [],
          type: "owners",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.OwnerUser_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "ownerplanningstatuses") {
      if (values && values.length === 0) {
        clearFilteredData("ownerplanningstatuses");
        array.forEach((item, index) => {
          if (item.type === "ownerplanningstatuses") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "ownerplanningstatuses") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "OwnerPlanningStatus_IDs",
          body: [],
          type: "ownerplanningstatuses",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.OwnerPlanningStatus_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "startdays") {
      if (values && values.length === 0) {
        clearFilteredData("startdays");
        array.forEach((item, index) => {
          if (item.type === "startdays") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "startdays") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "StartDays",
          body: [],
          type: "startdays",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.StartDay);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "enddays") {
      if (values && values.length === 0) {
        clearFilteredData("enddays");
        array.forEach((item, index) => {
          if (item.type === "enddays") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "enddays") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "EndDays",
          body: [],
          type: "enddays",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.EndDays);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "title") {
      if (values === "") {
        clearFilteredData("title");
        array.forEach((item, index) => {
          if (item.type === "title") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "title") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "Title",
          body: values,
          type: "title",
          data: values,
        };
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "link") {
      if (values === "") {
        clearFilteredData("link");
        array.forEach((item, index) => {
          if (item.type === "link") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "link") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "Link",
          body: values,
          type: "title",
          data: values,
        };
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "isprivates") {
      if (values && values.length === 0) {
        clearFilteredData("isprivates");
        array.forEach((item, index) => {
          if (item.type === "isprivates") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "isprivates") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "IsPrivates",
          body: [],
          type: "isprivates",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.IsPrivate);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    }
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
    dispatch(clearFilterTrippetResults());
  };

  const renderPagination =
    totalItemsCount > 50 ? (
      <Pagination
        activePage={currentPage}
        itemsPerPage={50}
        totalItemsCount={totalItemsCount}
        onChange={onPaginationChange}
      />
    ) : null;

  const getNumberOfActiveFilters = column => {
    let number = 0;
    if (filterResults && filterResults.length > 0) {
      filterResults.forEach(filter => {
        if (filter.type === column.filter) {
          number = filter.data.length;
        }
      });
    }
    return number;
  };

  const handleOpenGroupModal = () => {
    setIsGroupModalOpen(true);
  };

  const handleCloseGroupModal = () => {
    setIsGroupModalOpen(false);
  };

  const handleGroupTrippets = values => {
    if (selectedTrippets && selectedTrippets.length > 0) {
      let body = {
        Trip_ID: currentTrip.Trip_ID,
        Title: values.Title,
        OwnerUser_ID: user.User_ID,
        TrippetTypeLook_ID: values.TrippetTypeLook_ID,
        Children: [],
      };
      selectedTrippets.forEach(trippet =>
        body.Children.push({
          Trippet_ID: trippet.Trippet_ID,
        }),
      );
      dispatch(
        createTrippet(
          JSON.stringify(body),
          null,
          null,
          null,
          currentTrip.Trip_ID,
          user.User_ID,
          null,
          sortElement,
          sortByDirection[sortElement],
          currentTrip.CreatorUser_ID,
          t,
        ),
      );
    }
    clearAllSelected();
    setFlag(false);
  };

  const handleUngroupTrippets = () => {
    if (selectedTrippets && selectedTrippets.length > 0) {
      let body = {
        IsChildOf: "",
        Children: [],
      };
      selectedTrippets.forEach(trippet => {
        body.Children.push({
          Trippet_ID: trippet.Trippet_ID,
        });
      });
      dispatch(
        UngroupTrippets(
          JSON.stringify(body),
          currentTrip.Trip_ID,
          user.User_ID,
          sortElement,
          sortByDirection[sortElement],
          filterResults,
          currentTrip.CreatorUser_ID,
          t,
        ),
      );
    }
    clearAllSelected();
    setFlag(false);
  };

  const renderTables = () => {
    if (tableView.first) {
      return (
        <Table
          sortedElement={sortedElement}
          sortedBy={sortedBy}
          handleSortBy={handleSortBy}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          getNumberOfActiveFilters={getNumberOfActiveFilters}
          handleCheckboxChange={handleCheckboxChange}
          checkedTrippets={checkedTrippets}
          handleAllChecked={handleAllChecked}
          allChecked={allChecked}
          selectedTrippets={selectedTrippets}
          toogleChilds={toogleChilds}
          setSaveOrdering={setSaveOrdering}
          indeterminate={indeterminate}
        />
      );
    } else if (tableView.second) {
      return (
        <TableTwo
          sortedElement={sortedElement}
          sortedBy={sortedBy}
          handleSortBy={handleSortBy}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          getNumberOfActiveFilters={getNumberOfActiveFilters}
          handleCheckboxChange={handleCheckboxChange}
          checkedTrippets={checkedTrippets}
          handleAllChecked={handleAllChecked}
          allChecked={allChecked}
          selectedTrippets={selectedTrippets}
          toogleChilds={toogleChilds}
          setSaveOrdering={setSaveOrdering}
          indeterminate={indeterminate}
        />
      );
    } else if (tableView.third) {
      return (
        <TableThree
          sortedElement={sortedElement}
          sortedBy={sortedBy}
          handleSortBy={handleSortBy}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          getNumberOfActiveFilters={getNumberOfActiveFilters}
          handleCheckboxChange={handleCheckboxChange}
          checkedTrippets={checkedTrippets}
          handleAllChecked={handleAllChecked}
          allChecked={allChecked}
          selectedTrippets={selectedTrippets}
          toogleChilds={toogleChilds}
          setSaveOrdering={setSaveOrdering}
          indeterminate={indeterminate}
        />
      );
    } else if (tableView.four) {
      return (
        <TableFour
          sortedElement={sortedElement}
          sortedBy={sortedBy}
          handleSortBy={handleSortBy}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          getNumberOfActiveFilters={getNumberOfActiveFilters}
          handleCheckboxChange={handleCheckboxChange}
          checkedTrippets={checkedTrippets}
          handleAllChecked={handleAllChecked}
          allChecked={allChecked}
          selectedTrippets={selectedTrippets}
          toogleChilds={toogleChilds}
          setSaveOrdering={setSaveOrdering}
        />
      );
    } else if (tableView.five) {
      return (
        <TableFive
          sortedElement={sortedElement}
          sortedBy={sortedBy}
          handleSortBy={handleSortBy}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          getNumberOfActiveFilters={getNumberOfActiveFilters}
          handleCheckboxChange={handleCheckboxChange}
          checkedTrippets={checkedTrippets}
          handleAllChecked={handleAllChecked}
          allChecked={allChecked}
          selectedTrippets={selectedTrippets}
          toogleChilds={toogleChilds}
          setSaveOrdering={setSaveOrdering}
        />
      );
    }
  };

  const handleClickToogle = () => {
    setToogleChilds(!toogleChilds);
    let obj = { ...trippets };
    // eslint-disable-next-line
    trippets.forEach(trippet => {
      obj[trippet.Trippet_ID] = !toogleChilds;
    });
    dispatch(saveTrippetChildrenStatus(obj));
  };

  useEffect(() => {
    if (trippets && trippets.length > 0) {
      let data = {};
      if (!flag) {
        trippets.forEach(trippet => (data[trippet.Trippet_ID] = false));
        dispatch(saveTrippetChildrenStatus(data));
      }
    }
  }, [trippets, dispatch, flag, currentTrip, currentTrip.Trip_ID]);

  const renderToogleIcon = toogleChilds ? (
    <KeyboardArrowUpIcon />
  ) : (
    <KeyboardArrowDownIcon />
  );

  const renderToogleName = toogleChilds ? t("Close Group") : t("Open Group");

  useEffect(() => {
    if (selectedTrippets && selectedTrippets.length > 1) {
      setDeletingType(1);
    } else {
      setDeletingType(0);
    }
  }, [selectedTrippets]);

  const handleDuplicate = () => {
    if (selectedTrippets && selectedTrippets.length > 1) {
      let body = [];
      selectedTrippets.forEach(trippet => {
        body.push({
          SourceTrippet_ID: trippet.Trippet_ID,
          Title: `${trippet.Title} / duplicated`,
          CreatorUser_ID: user.User_ID,
          User_ID: user.User_ID,
          OwnerUser_ID: user.User_ID,
        });
      });
      dispatch(
        duplicateMultipleTrippet(
          JSON.stringify(body),
          currentTrip.Trip_ID,
          user.User_ID,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
          t,
        ),
      );
      dispatch(savePaginationPage(1));
    } else {
      let id = selectedTrippets[0].Trippet_ID;
      const body = {
        SourceTrippet_ID: id,
        Title: `${selectedTrippets[0].Title} / duplicated`,
        CreatorUser_ID: user.User_ID,
        User_ID: user.User_ID,
        OwnerUser_ID: user.User_ID,
      };
      dispatch(
        duplicateTrippet(
          JSON.stringify(body),
          currentTrip.Trip_ID,
          user.User_ID,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
          t,
        ),
      );
    }
    clearAllSelected();
    setFlag(false);
  };

  const handleSaveOrderd = () => {
    let body = [];
    trippets.forEach(trippet => {
      let data = {
        Parent_ID: trippet.Trippet_ID,
        Children: [],
      };
      if (trippet.Children && trippet.Children.length > 0) {
        trippet.Children.forEach(child => data.Children.push(child.Trippet_ID));
      }
      body.push(data);
    });
    dispatch(saveReorderedChildrenTrippets(JSON.stringify(body), t));
    setSaveOrdering(false);
  };

  const handleAddChildModalOpen = () => {
    setIsAddChildOpen(true);
  };

  const handleAddChildModalClose = () => {
    setIsAddChildOpen(false);
  };

  return (
    <div className={classes.trippetTableContainer}>
      <Fab
        variant="extended"
        className={classes.addTrippetButton}
        onClick={handleTrippetFormOpen}
      >
        <AddIcon />
        <span className={classes.addTripetButtonText}>
          {t("Create new trippet")}
        </span>
      </Fab>
      <div className={classes.dotsContainer}>
        <div>
          {showButtons && (
            <>
              <Button
                color="secondary"
                onClick={handleDeleteSingleTrippetModalOpen}
              >
                {t("Delete")}
              </Button>
              <Button color="primary" onClick={handleDuplicate}>
                {t("Duplicate")}
              </Button>
              {!hideActionButtons && (
                <Button color="primary" onClick={handleParticipantsModalOpen}>
                  {t("Recommend")}
                </Button>
              )}
              {showAddChild && (
                <Button color="primary" onClick={handleAddChildModalOpen}>
                  {t("Add Child")}
                </Button>
              )}
              {canGroup && (
                <Button color="primary" onClick={handleOpenGroupModal}>
                  {t("Group")}
                </Button>
              )}
              {canUngroup && (
                <Button color="primary" onClick={handleUngroupTrippets}>
                  {t("Ungroup")}
                </Button>
              )}
            </>
          )}
          {saveOrdering && (
            <Button
              color="primary"
              onClick={handleSaveOrderd}
              className={classes.saveOrder}
            >
              {t("Save Order")}
            </Button>
          )}
          <Button
            color="primary"
            onClick={handleClickToogle}
            endIcon={renderToogleIcon}
          >
            {renderToogleName}
          </Button>
        </div>
        <div className={classes.dots}>
          <div
            className={classNames(classes.dot, {
              [classes.checked]: tableView.first,
            })}
            onClick={handleTableView("first")}
          />
          <div
            className={classNames(classes.dot, {
              [classes.checked]: tableView.second,
            })}
            onClick={handleTableView("second")}
          />
          <div
            className={classNames(classes.dot, {
              [classes.checked]: tableView.third,
            })}
            onClick={handleTableView("third")}
          />
          {/* <div
            className={classNames(classes.dot, {
              [classes.checked]: tableView.four,
            })}
            onClick={handleTableView('four')}
          />
          <div
            className={classNames(classes.dot, {
              [classes.checked]: tableView.five,
            })}
            onClick={handleTableView('five')}
          /> */}
        </div>
      </div>
      {renderTables()}
      {renderPagination}
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleTrippetFormClose}
        handleClose={handleConfirmModalClose}
      />
      <FilterModal
        open={isFilterModalOpen}
        handleClose={handleCloseFilterModal}
        name={clickedFilter}
        handleFilterValues={handleFilterValues}
        filterResults={filterResults}
        filterData={filterData}
      />
      {isOpenTrippetFrom && (
        <TrippetForm
          open={isOpenTrippetFrom}
          handleClose={handleTrippetFormClose}
          handleConfirmModalOpen={handleConfirmModalOpen}
          currentTrippet={currentTrippet}
          pickedTime={pickedTime}
        />
      )}
      {isGroupModalOpen && (
        <GroupingModal
          open={isGroupModalOpen}
          handleClose={handleCloseGroupModal}
          handleGroupTrippets={handleGroupTrippets}
        />
      )}
      <RecommendationModal
        open={isParticipantsModalOpen}
        handleClose={handleParticipantsModalClose}
        allUsers={allUsers}
        trippet={{
          fullTrippet: selectedTrippets[0],
        }}
      />
      {selectedTrippets && selectedTrippets.length > 0 && (
        <DeleteSingleTrippet
          open={isDeleteSingleTrippetOpen}
          handleClose={handleDeleteSingleTrippetModalClose}
          trippets={selectedTrippets}
          tripId={currentTrip.Trip_ID}
          userId={user.User_ID}
          sortedElement={sortedElement}
          sortedBy={sortedBy}
          filterResults={filterResults}
          clearAllSelected={clearAllSelected}
          type={deletingType}
          CreatorUser_ID={currentTrip.CreatorUser_ID}
        />
      )}
      {isAddChildOpen && (
        <AddChildTrippetModal
          open={isAddChildOpen}
          handleClose={handleAddChildModalClose}
          trippet={selectedTrippets[0]}
          tripId={currentTrip.Trip_ID}
          userId={user.User_ID}
          sortElement={sortElement}
          sortedBy={sortedBy}
          CreatorUser_ID={currentTrip.CreatorUser_ID}
          clearAllSelected={clearAllSelected}
          setFlag={setFlag}
        />
      )}
    </div>
  );
};

export default TrippetTable;
