import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Cockpit from "./pages/cockpit/Cockpit";
import SignIn from "./pages/signIn/SignIn";
import SignUp from "./pages/signUp/SignUp";
import ProgramSettings from "./pages/programSettings/ProgramSettings";
import PrivateRoute from "./components/PrivateRoute";
import VerificationRoute from "./components/VerificationRoute";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import MyTrips from "./pages/myTrips/MyTrips";
import MyTrippets from "./pages/myTrippets/MyTrippets";
import Profile from "./pages/profile/Profile";
import Languages from "./pages/languages/Languages";
import Countries from "./pages/countries/Countries";
import Currencies from "./pages/currencies/Currencies";
import PlanningStatuses from "./pages/planningStatuses/PlanningStatuses";
import ParticipantStatuses from "./pages/participantStatuses/ParticipantStatuses";
import TrippetTypeCategories from "./pages/trippetTypeCategories/TrippetTypeCategories";
import TrippetTypeLooks from "./pages/trippetTypeLooks/TrippetTypeLooks";
import SettingCategories from "./pages/settingCategories/SettingCategories";
import SettingItems from "./pages/settingItems/SettingItems";
import PreferenceCategories from "./pages/preferenceCategories/PreferenceCategories";
import PreferenceItems from "./pages/preferenceItems/PreferenceItems";
import Departments from "./pages/departments/Departments";
import AdminRoles from "./pages/adminRoles/AdminRoles";
import UserRoles from "./pages/userRoles/UserRoles";
import Users from "./pages/users/Users";
import VerificationCodes from "./pages/verificationCodes/VerificationCodes";
import Config from "./pages/config/Config";
import Notifications from "./pages/notifications/Notifications";
import SnackBar from "./components/SnackBar";
import LoadingPage from "./components/LoadingPage";
import Reports from "./pages/reports/Reports";
import { getUserByToken } from "./store/actions/actions/user";
import { saveGoogleObject } from "./store/actions/actions/root";
import { useDispatch, useSelector } from "react-redux";
import { LoadScript } from "@react-google-maps/api";
import Verification from "./pages/verification/Verfication";
import UserSettings from "./pages/userSettings/userSettings";
import ChangePassword from "./pages/changePassword/changePassword";

const LIBRARIES = ["places, map, distance"];

const App = props => {
  const dispatch = useDispatch();
  const showSnackbar = useSelector(state => state.app.isSnackBarOpen);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const body = {
        Token: token,
        type: "autologin",
      };
      dispatch(getUserByToken(JSON.stringify(body, props.history)));
    }
  }, [dispatch, props.history]);

  const renderSnackbar = showSnackbar ? <SnackBar /> : null;

  return (
    <div className="App">
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyAlJOCcAvMBdOEGE8ZOHr6FbufA4RzonYQ"
        libraries={LIBRARIES}
        onLoad={() => dispatch(saveGoogleObject(window.google))}
        loadingElement={<LoadingPage />}
      >
        <Switch>
          <Route exact path="/verification" component={Verification} />
          <PrivateRoute exact path="/login" component={SignIn} />
          <PrivateRoute exact path="/sign-up" component={SignUp} />
          <PrivateRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PrivateRoute
            exact
            path="/reset-password"
            component={ChangePassword}
          />
          <VerificationRoute exact path="/" component={Cockpit} />
          <VerificationRoute exact path="/my-trips" component={MyTrips} />
          <VerificationRoute exact path="/my-trippets" component={MyTrippets} />
          <VerificationRoute exact path="/profile" component={Profile} />
          <VerificationRoute exact path="/languages" component={Languages} />
          <VerificationRoute exact path="/countries" component={Countries} />
          <VerificationRoute exact path="/currencies" component={Currencies} />
          <VerificationRoute
            exact
            path="/planningstatuses"
            component={PlanningStatuses}
          />
          <VerificationRoute
            exact
            path="/participantstatuses"
            component={ParticipantStatuses}
          />
          <VerificationRoute
            exact
            path="/trippettypecategories"
            component={TrippetTypeCategories}
          />
          <VerificationRoute
            exact
            path="/trippettypelooks"
            component={TrippetTypeLooks}
          />
          <VerificationRoute
            exact
            path="/settingcategories"
            component={SettingCategories}
          />
          <VerificationRoute exact path="/settingitems" component={SettingItems} />
          <VerificationRoute
            exact
            path="/preferencecategories"
            component={PreferenceCategories}
          />
          <VerificationRoute
            exact
            path="/preferenceitems"
            component={PreferenceItems}
          />
          <VerificationRoute exact path="/departments" component={Departments} />
          <VerificationRoute exact path="/adminroles" component={AdminRoles} />
          <VerificationRoute exact path="/userroles" component={UserRoles} />
          <VerificationRoute exact path="/users" component={Users} />
          <VerificationRoute exact path="/config" component={Config} />
          <VerificationRoute
            exact
            path="/verificationcodes"
            component={VerificationCodes}
          />
          <VerificationRoute exact path="/reports" component={Reports} />
          <VerificationRoute
            exact
            path="/program-settings"
            component={ProgramSettings}
          />
          <VerificationRoute exact path="/notifications" component={Notifications} />
          <VerificationRoute exact path="/user-settings" component={UserSettings} />
          <Redirect from="*" to="/" />
        </Switch>
        {renderSnackbar}
      </LoadScript>
    </div>
  );
};

export default App;
