import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allUserRoleAllows: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'TakerUserRole_ID',
  sortByDirection: 'asc',
  currentUserRoleAllow: {
    UserRoleAllow_ID: 0,
    GiverUserRole_ID: 0,
    TakerUserRole_ID: 0,
  },
};

const userRoleAllowReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_USERROLEALLOW: {
      return {
        ...state,
        currentUserRoleAllow: {
            UserRoleAllow_ID: action.payload.UserRoleAllow_ID,
            GiverUserRole_ID: action.payload.GiverUserRole_ID,
            TakerUserRole_ID: action.payload.Name,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_USERROLEALLOWS: {
      return {
        ...state,
        allUserRoleAllows: action.payload,
      };
    }
    case adminActionTypes.SAVE_USERROLEALLOW_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_USERROLEALLOW_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_USERROLEALLOW_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_USERROLEALLOW_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allUserRoleAllows: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'TakerUserRole_ID',
        sortByDirection: 'asc',
        currentUserRoleAllow: {
            UserRoleAllow_ID: 0,
            GiverUserRole_ID: 0,
            TakerUserRole_ID: 0,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default userRoleAllowReducer;