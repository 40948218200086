import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import DeleteIcon from "@material-ui/icons/Delete";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MessageUserModal from "../MessageUserModal/MessageUserModal";
import DeleteUserModal from "../DeleteUserModal/DeleteUserModal";
import PermissionTable from "./PermissionTable";
import { useSelector, useDispatch } from "react-redux";
import GavelIcon from "@material-ui/icons/Gavel";
import { showPermissionTable, hidePermissionTable} from '../../store/actions/actions/root';

const useStyles = makeStyles(theme => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    right: "0 !important",
    bottom: "-28px !important",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    "& > button": {
      width: 40,
      height: 40,
      backgroundColor: "#0E84B8",
    },
  },
}));

const ActionButton = ({ user, users, uniqueUsers, setUniqueUsers }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState(null);
  const [buddyRoles, setBuddyRoles] = useState(null);
  const [isOpenMessageUserModal, setIsOpenMessageUserModal] = useState(false);
  const [isOpenDeleteUserModal, setIsOpenDeleteUserModal] = useState(false);
  const allUsersRoles = useSelector(state => state.app.allUsersRoles);
  const permissionTable = useSelector(state => state.app.permissionTable);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenMessageUserModal = () => {
    setIsOpenMessageUserModal(true);
  };

  const handleCloseMessageUserModal = () => {
    setIsOpenMessageUserModal(false);
  };

  const handleClosePermissionTable = () => {
    dispatch(hidePermissionTable())
  };

  const handleOpenDeleteUserModal = () => {
    setIsOpenDeleteUserModal(true);
  };

  const handleCloseDeleteUserModal = () => {
    setIsOpenDeleteUserModal(false);
  };

  const overRideUndefinedID = item => {
    let user = { ...item };
    if ("TripParticipantUser_ID" in user) {
      if (!user.TripParticipantUser_ID) {
        user.TripParticipantUser_ID = `${user.InviteeEmail}-${user.TripParticipant_ID}`;
      }
    } else if ("TripOrganizerUser_ID" in user) {
      if (!user.TripOrganizerUser_ID) {
        user.TripOrganizerUser_ID = `${user.InviteeEmail}-${user.TripOrganizer_ID}`;
      }
    } else if ("TripProxyUser_ID" in user) {
      if (!user.TripProxyUser_ID) {
        user.TripProxyUser_ID = `${user.InviteeEmail}-${user.TripProxy_ID}`;
      }
    }
    return user;
  };

  useEffect(() => {
    if (user && users) {
      let item = overRideUndefinedID(user.item);
      let userID =
        item.TripParticipantUser_ID ||
        user.User_ID ||
        item.TripOrganizerUser_ID ||
        item.TripProxyUser_ID;
      // eslint-disable-next-line
      Object.keys(users).map(key => {
        if (key === userID) {
          setRoles(users[key][key]);
        }
      });
    }
  }, [user, users]);

  useEffect(() => {
    if (roles && allUsersRoles && allUsersRoles.length > 0) {
      let allRoles = [];
      // eslint-disable-next-line
      Object.keys(roles).map(key => {
        if (roles[key]) {
          let fullRole = allUsersRoles.find(role => role.UserRole_ID === key);
          allRoles.push(fullRole);
        }
      });
      setBuddyRoles(allRoles);
    }
  }, [allUsersRoles, roles]);

  const handleClick = (user, action) => event => {
    event.stopPropagation();
    if (action === "message") {
      handleOpenMessageUserModal();
    } else if (action === "delete") {
      handleOpenDeleteUserModal();
    } else if (action === "roles") {
      dispatch(showPermissionTable());
    }
  };

  const actions = [
    { icon: <MailOutlineIcon />, name: t("Message User"), action: "message" },
    { icon: <DeleteIcon />, name: t("Delete User"), action: "delete" },
    { icon: <GavelIcon />, name: t("User Roles"), action: "roles" },
  ];

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="left"
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement="top"
            onClick={handleClick(user, action.action)}
          />
        ))}
      </SpeedDial>
      <MessageUserModal
        open={isOpenMessageUserModal}
        user={user}
        handleClose={handleCloseMessageUserModal}
      />
      <DeleteUserModal
        open={isOpenDeleteUserModal}
        user={user}
        handleClose={handleCloseDeleteUserModal}
        uniqueUsers={uniqueUsers}
        setUniqueUsers={setUniqueUsers}
      />
      <PermissionTable
        open={permissionTable}
        user={user}
        allRoles={buddyRoles}
        handleClose={handleClosePermissionTable}
      />
    </div>
  );
};

export default ActionButton;
