import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import {store} from '../../store';

export const saveCurrentCountry = payload => ({
  type: adminActionTypes.SAVE_CURRENT_COUNTRY,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_COUNTRY_CURRENT_PAGE,
  payload,
});

export const getCountryById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/country/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentCountry(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewCountry = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/country/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentCountry(data));
          dispatch(getAllCountries(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeCountry = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/country/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getCountryById(body.Country_ID));
          dispatch(getAllCountries(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllCountries = payload => ({
  type: adminActionTypes.SAVE_ALL_COUNTRIES,
  payload,
});

export const saveEntrieCountries = payload => ({
  type: adminActionTypes.SAVE_ENTIRE_COUNTRIES,
  payload,
});

export const saveCountrySortElement = payload => ({
  type: adminActionTypes.SAVE_COUNTRY_SORT_ELEMENT,
  payload,
});

export const saveCountrySortByDirection = payload => ({
  type: adminActionTypes.SAVE_COUNTRY_SORT_BY_DIRECTION,
  payload,
});

export const saveCountryPaginationData = payload => ({
  type: adminActionTypes.SAVE_COUNTRY_PAGINATION_DATA,
  payload,
});

export const getAllCountries = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: ROWS_PER_PAGE,
            Page: store.getState().country.currentPage,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/country/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllCountries(data.Data));
        dispatch(saveCountryPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const getEntireCountries = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: 100000,
            Page: 1,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/country/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveEntrieCountries(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteCountry = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/country/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else 
            dispatch(getAllCountries(null));
        })
        .catch(error => console.log(error));
    }
  };
};
