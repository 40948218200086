export const columns = [
  { id: 'actions', label: '', minWidth: 70, type: 'actions' },
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    type: 'TrippetTypeLookName',
    filter: 'typelooks',
  },
  {
    id: 'title',
    label: 'Trippet Title',
    minWidth: 80,
    type: 'Title',
    filter: 'title',
  },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 80,
    type: 'StartDate',
    filter: 'startdates',
  },
  {
    id: 'startTime',
    label: 'Time',
    minWidth: 80,
    type: 'StartDate',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 80,
    type: 'EndDate',
    filter: 'enddates',
  },
  {
    id: 'endTime',
    label: 'Time',
    minWidth: 80,
    type: 'EndDate',
  },
  {
    id: 'trippetOwner',
    label: 'Trippet Creator',
    minWidth: 80,
    type: 'OwnerUser_ID',
    filter: 'owners',
  },
  {
    id: 'day',
    label: 'Day',
    minWidth: 80,
    type: 'StartDay',
    filter: 'startdays',
  },
  {
    id: 'price',
    label: 'Price',
    minWidth: 80,
    type: 'Price',
  },
];
