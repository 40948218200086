export const columns = [
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    type: 'TrippetTypeLook_ID',
  },
  { id: 'title', label: 'Trippet Title', minWidth: 80, type: 'Title' },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 80,
    type: 'StartDate',
  },
  {
    id: 'startTime',
    label: 'Time',
    minWidth: 80,
    type: 'StartDate',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 80,
    type: 'EndDate',
  },
  {
    id: 'endTime',
    label: 'Time',
    minWidth: 80,
    type: 'EndDate',
  },
  {
    id: 'distance',
    label: 'Distance',
    minWidth: 80,
    type: 'Distance',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
    type: 'OwnerPlanningStatus_ID',
  },
  {
    id: 'isPrivate',
    label: 'Private',
    minWidth: 80,
    type: 'IsPrivate',
  },
];
