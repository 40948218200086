import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { duplicateTrip } from '../../store/actions/actions/trip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RecommendationModal from '../../components/RecommendationModal/RecommendationModal';

const useStyles = makeStyles(theme => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    top: '-27px !important',
    left: '-8px !important',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    '& > button': {
      width: 40,
      height: 40,
      backgroundColor: '#0E84B8',
    },
  },
}));

const TripButton = ({
  data,
  type,
  handleClickedTrip,
  handleTripModalOpen,
  allUsers,
  user,
  handleConfirmDeleteModalOpen,
  setDeletedTrip,
  disabledDelete,
  filterResults,
  setUserTripRole,
  handleDelete,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isRecommendationModalOpen, setIsRecommendationModalOpen] = useState(
    false,
  );
  const [tripRecommendationInfo, setTripRecommendationInfo] = useState(null);
  const sortElement = useSelector(state => state.trip.sortElement);
  const sortByDirection = useSelector(state => state.trip.sortByDirection);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleRecommendationModalOpen = event => {
    event.stopPropagation();
    setIsRecommendationModalOpen(true);
  };

  const handleRecommendationModalClose = event => {
    event.stopPropagation();
    setIsRecommendationModalOpen(false);
  };

  const handleSendAsRecommendation = event => {
    event.stopPropagation();
    const body = {
      TripRecommendationSenderUser_ID: user.User_ID,
      Trip_ID: data.id,
    };
    setTripRecommendationInfo(body);
    handleRecommendationModalOpen(event);
  };

  const findTheUser = () => {
    let type = '';
    let object = {};
    let isParticipant = data.fullTrip.Participants.filter(par => user.User_ID === par.TripParticipantUser_ID);
    let isOrganizer = data.fullTrip.Organizers.filter(organizer => user.User_ID === organizer.TripOrganizerUser_ID);
    let isProxies = data.fullTrip.Proxies.filter(proxy => user.User_ID === proxy.TripProxyUser_ID);
    if (isParticipant.length > 0) {
      type = 'Participants';
      object = data.fullTrip.Participants;
    } else if (isOrganizer.length > 0) {
      type = 'Organizers';
      object = data.fullTrip.Organizers;
    } else if (isProxies.length > 0) {
      type = 'Proxies';
      object = data.fullTrip.Proxies;
    }
    return [type, object]
  }

  const handleClick = action => event => {
    event.stopPropagation();
    if (action === 'duplicate') {
      if (type === 'my-trip') {
        const body = {
          SourceTrip_ID: data.fullTrip.Trip_ID,
          CreatorUser_ID: user.User_ID,
          OwnerUser_ID: user.User_ID,
          Name: `${data.fullTrip.Name} / duplicated`,
        };
        dispatch(
          duplicateTrip(
            JSON.stringify(body),
            user.User_ID,
            sortElement,
            sortByDirection[sortElement],
            filterResults,
            t
          ),
        );
      }
    } else if (action === 'delete') {
      if (type === 'my-trip') {
        const body = { Trip_ID: data.id };
        const [type] = findTheUser();
        if (data.owner === user.User_ID) {
          setUserTripRole('owner');
          setDeletedTrip(body);
          handleDelete('owner', data.fullTrip);
        } else if (
          type === "Participants"
        ) {
          data.fullTrip.Participants.forEach(participant => {
            if (participant.TripParticipantUser_ID === user.User_ID) {
              setUserTripRole('participant');
              setDeletedTrip(body);
              handleDelete('participant', data.fullTrip, participant);
            }
          });
        } else if (
          type === "Organizers"
        ) {
          data.fullTrip.Organizers.forEach(participant => {
            if (participant.TripOrganizerUser_ID === user.User_ID) {
              setUserTripRole('organizer');
              setDeletedTrip(body);
              handleDelete('organizer', data.fullTrip, participant);
            }
          });
        } else if (
          type === "Proxies"
        ) {
          data.fullTrip.Proxies.forEach(proxy => {
            if (proxy.TripProxyUser_ID === user.User_ID) {
              setUserTripRole('proxy');
              setDeletedTrip(body);
              handleDelete('proxy', data.fullTrip, proxy);
            }
          });
        }
        // handleConfirmDeleteModalOpen();
      }
    } else if (action === 'open') {
      let tripBody = {
        Name: data.fullTrip.Name,
        Description: data.fullTrip.Description,
        Message: data.fullTrip.Message,
        Trip_ID: data.fullTrip.Trip_ID,
        Participants: data.fullTrip.Participants,
        StartDate: data.fullTrip.StartDate,
        EndDate: data.fullTrip.EndDate,
      };
      handleClickedTrip(tripBody);
      handleTripModalOpen();
    } else if (action === 'recommend') {
      event.stopPropagation();
      handleSendAsRecommendation(event);
    }
  };

  const actions = [
    { icon: <OpenInNewIcon />, name: t('Open'), action: 'open' },
    { icon: <FileCopyIcon />, name: t('Duplicate'), action: 'duplicate' },
    { icon: <ShareIcon />, name: t('Recommend'), action: 'recommend' },
    { icon: <DeleteIcon />, name: t('Delete'), action: 'delete' },
  ];

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="right"
        onClick={event => event.stopPropagation()}
      >
        {actions.map(action => {
          if (action.action === 'delete') {
            return (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipPlacement="top"
                onClick={handleClick(action.action)}
                // disabled={disabledDelete}
              />
            );
          } else {
            return (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipPlacement="top"
                onClick={handleClick(action.action)}
              />
            );
          }
        })}
      </SpeedDial>
      <RecommendationModal
        open={isRecommendationModalOpen}
        handleClose={handleRecommendationModalClose}
        allUsers={allUsers}
        trippet
        trip={tripRecommendationInfo}
      />
    </div>
  );
};

export default TripButton;
