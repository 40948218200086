import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import CloudIcon from '@material-ui/icons/Cloud';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import PowerRoundedIcon from '@material-ui/icons/PowerRounded';
import { useDispatch } from 'react-redux';
import { showSnackBar } from '../../store/actions/actions/root';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Link } from 'react-router-dom';

const FeatureMenu = props => {
  const { open, handleDrawerClose, handleDrawerOpen } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleNavigation = open ? (
    <div className={classes.navigationButton}>
      <IconButton onClick={handleDrawerClose}>
        <ChevronRightIcon />
      </IconButton>
    </div>
  ) : (
    <div className={classes.navigationButton}>
      <IconButton onClick={handleDrawerOpen}>
        <ChevronLeftIcon />
      </IconButton>
    </div>
  );

  const handleClickIcon = () => {
    dispatch(
      showSnackBar({
        type: 'info',
        message: t('The page is under constructor'),
      }),
    );
    handleDrawerClose();
  };

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      className={clsx(classes.drawer, classes.blueMenu, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar} />

      <Divider />
      <List>
        <ListItem className={classes.navigationArrow}>
          {handleNavigation}
        </ListItem>
        <ListItem component={Link} to='/Reports' className={classes.listItem}>
          <ListItemIcon>
            <AssessmentIcon className={classes.blueIcon} />
          </ListItemIcon>
          <ListItemText primary={t('Reports')} />
        </ListItem>
        <ListItem button onClick={handleClickIcon}>
          <ListItemIcon>
            <CloudIcon className={classes.blueIcon} />
          </ListItemIcon>
          <ListItemText primary={t('Weather')} />
        </ListItem>
        <ListItem button onClick={handleClickIcon}>
          <ListItemIcon>
            <CardTravelIcon className={classes.blueIcon} />
          </ListItemIcon>
          <ListItemText primary={t('Visa Info')} />
        </ListItem>
        <ListItem button onClick={handleClickIcon}>
          <ListItemIcon>
            <PowerRoundedIcon className={classes.blueIcon} />
          </ListItemIcon>
          <ListItemText primary={t('Plugins')} />
        </ListItem>
        <ListItem button onClick={handleClickIcon}>
          <ListItemIcon>
            <AddBoxRoundedIcon className={classes.blueIcon} />
          </ListItemIcon>
          <ListItemText primary={t('Medical Info')} />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default FeatureMenu;
