import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Avatar from "@material-ui/core/Avatar";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { columns } from "./column";
import { columnsTwo } from "./column-two";
import useStyles from "../styles";
import { format } from "date-fns";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import uniqid from "uniqid";
import TripInvitationButton from "../TripInvitationButton";
import TripRecommendationButton from "../TripRecommendationButton";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import TripModal from "../../../components/TripForm/TripViewMode";

const createData = (
  date,
  name,
  role,
  owner,
  participants,
  organizers,
  proxies,
  message,
  numTrippets,
  lastChanged,
  fullTrip,
  id,
) => {
  return {
    date,
    name,
    role,
    owner,
    participants,
    organizers,
    proxies,
    message,
    numTrippets,
    lastChanged,
    fullTrip,
    id,
  };
};

const TripInvitation = ({
  data,
  allUsers,
  type,
  user,
  handleConfirmDeleteModalOpen,
  setDeletedTrip,
  sortedElement,
  sortedBy,
  handleSortBy,
  invitationType,
  filterResults,
  sortedElementMyTrips,
  sortedByMyTrips,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [clickedInvitaion, setClickedInvitation] = useState(null);
  const [isTripModalOpen, setIsTripModalOpen] = useState(false);
  const [usedColumns, setUsedColumns] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (invitationType === "sent") {
      setUsedColumns(columnsTwo);
    } else {
      setUsedColumns(columns);
    }
  }, [invitationType]);

  const findUserInfo = participant => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      let id =
        participant.TripParticipantUser_ID ||
        participant.TripProxyUser_ID ||
        participant.TripOrganizerUser_ID;
      allUsers.forEach(user => {
        if (id === user.User_ID) {
          data = renderUserInfo(user);
        } else {
          if (participant.InviteeEmail) {
            data = renderUserInfo(participant);
          }
        }
      });
      return data;
    }
  };

  const renderUserInfo = participant => {
    let initials =
      participant.TripParticipantInitials ||
      participant.TripOrganizerInitials ||
      participant.TripProxyInitials;
    if (participant.Picture) {
      return (
        <img
          src={participant.Picture}
          alt={initials}
          className={classes.avatarImage}
        />
      );
    } else {
      return `${participant.Initials || initials}`;
    }
  };

  const renderParticipantsStatusColor = status => {
    if (status === "1" || status === null || status === "0") {
      return classes.pending;
    } else if (status === "2") {
      return classes.accepted;
    } else if (status === "3") {
      return classes.rejected;
    }
  };

  const renderParticipants = value => {
    let allParticipants = value;
    return (
      allParticipants &&
      allParticipants.map(participant => (
        <Tooltip
          title={participant.TripParticipantDisplayName}
          placement="top"
          key={uniqid()}
          arrow
        >
          <Avatar
            className={renderParticipantsStatusColor(
              participant.ParticipantStatus_ID,
            )}
          >
            {findUserInfo(participant)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const renderOrganizers = value => {
    let allOrganizers = value;
    return (
      allOrganizers &&
      allOrganizers.map(organizer => (
        <Tooltip
          title={organizer.TripOrganizerDisplayName}
          placement="top"
          key={uniqid()}
          arrow
        >
          <Avatar
            className={renderParticipantsStatusColor(
              organizer.TripOrganizerAcceptanceStatus,
            )}
          >
            {findUserInfo(organizer)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const renderProxies = value => {
    let allProxies = value;
    return (
      allProxies &&
      allProxies.map(proxy => (
        <Tooltip
          title={proxy.TripProxyDisplayName}
          placement="top"
          key={uniqid()}
          arrow
        >
          <Avatar
            className={renderParticipantsStatusColor(
              proxy.TripProxyAcceptanceStatus,
            )}
          >
            {findUserInfo(proxy)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const converToLocalTime = date => {
    let newDate = date.split("");
    newDate.push("Z");
    let localTime = new Date(newDate.join(""));
    return format(new Date(localTime), "dd.MM.yyyy HH:mm");
  };

  const findOwner = value =>
    allUsers && allUsers.find(user => user.User_ID === value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        let startDate = format(
          new Date(item.StartDate || item.TripStartDate),
          "dd.MM.yyyy",
        );
        let endDate = format(
          new Date(item.EndDate || item.TripEndDate),
          "dd.MM.yyyy",
        );
        elements.push(
          createData(
            `${startDate} - ${endDate}`,
            item.Name,
            item.UserRoleName,
            item.OwnerUser_ID,
            item.Participants,
            item.Organizers,
            item.Proxies,
            item.Message,
            item.NumTrippets,
            converToLocalTime(item.ModifiedDate),
            item,
            item.Trip_ID,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleTripModalOpen = () => {
    setIsTripModalOpen(true);
  };

  const handleTripModalClose = () => {
    setIsTripModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === "participants") {
      return (
        <AvatarGroup max={2} className={classes.avatarGroup}>
          {renderParticipants(value)}
        </AvatarGroup>
      );
    } else if (column.id === "organizers") {
      return (
        <AvatarGroup max={2} className={classes.avatarGroup}>
          {renderOrganizers(value)}
        </AvatarGroup>
      );
    } else if (column.id === "proxies") {
      return (
        <AvatarGroup max={2} className={classes.avatarGroup}>
          {renderProxies(value)}
        </AvatarGroup>
      );
    } else if (column.id === "actions") {
      if (type === "invitations") {
        return (
          <TripInvitationButton
            data={row}
            type={type}
            user={user}
            filterResults={filterResults}
            sortedElementMyTrips={sortedElementMyTrips}
            sortedByMyTrips={sortedByMyTrips}
          />
        );
      } else if (type === "recommendations") {
        return <TripRecommendationButton data={row} type={type} />;
      }
    } else if (column.id === "sender") {
      let user = findOwner(value);
      if (user) {
        if (user.Picture) {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>
                <img
                  src={user.Picture}
                  alt={user.Initials}
                  className={classes.avatarImage}
                />
              </Avatar>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>{`${user.Initials}`}</Avatar>
            </Tooltip>
          );
        }
      }
    } else if (column.id === "owner") {
      let user = findOwner(value);
      if (user) {
        if (user.Picture) {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>
                <img
                  src={user.Picture}
                  alt={user.Initials}
                  className={classes.avatarImage}
                />
              </Avatar>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>{`${user.Initials}`}</Avatar>
            </Tooltip>
          );
        }
      }
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    setClickedInvitation(value);
    handleTripModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={uniqid()}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {usedColumns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0
                    ? classNames(classes.tableCell, classes[row.color])
                    : classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {usedColumns.map(column => {
                const renederActiveSortingArrow =
                  sortedElement === column.type
                    ? classes.tableHeaderCellActive
                    : classes.tableHeaderCell;
                if (column.id !== "actions") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={renederActiveSortingArrow}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={true}
                        direction={
                          sortedElement === column.type
                            ? sortedBy[sortedElement]
                            : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={renederActiveSortingArrow}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <TripModal
        open={isTripModalOpen}
        handleClose={handleTripModalClose}
        defaultValues={clickedInvitaion}
        type="received"
        filterResults={filterResults}
        sortedElementMyTrips={sortedElementMyTrips}
        sortedByMyTrips={sortedByMyTrips}
      />
    </Paper>
  );
};

export default TripInvitation;
