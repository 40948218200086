import * as actionTypes from "../actions/actionTypes/actionTypes";

const initialState = {
  allTrips: null,
  entireTrips: null,
  paginationData: null,
  allTripRecivedInvitations: null,
  allTripSentInvitations: null,
  tripSentInvitationalPagination: null,
  tripRecivedInvitationalPagination: null,
  totalTripInvitationals: 0,
  allTripRecommendations: null,
  allSentTripRecommendations: null,
  totalTripRecommendations: null,
  tripSentRecommendationPagination: null,
  tripRecivedRecommendationPagination: null,
  sortElement: "ModifiedDate",
  usedFilters: null,
  sortByDirection: {
    Name: "asc",
    StartDate: "asc",
    Message: "asc",
    DisplayName: "asc",
    OwnerUser_ID: "asc",
    OwnerPlanningStatusName: "asc",
    ModifiedDate: "desc",
  },
  sortElementInvitation: "ModifiedDate",
  sortByDirectionInvitaiton: {
    Name: "asc",
    StartDate: "asc",
    Message: "asc",
    DisplayName: "asc",
    OwnerUser_ID: "asc",
    OwnerPlanningStatusName: "asc",
    ModifiedDate: "asc",
    UserRoleName: "asc",
  },
  currentTrip: {
    tripName: "",
    description: "",
    participants: "",
    message: "",
    startDate: "",
    endDate: "",
    OwnerTrip: "",
    LastChanged: "",
  },
  filterData: null,
};

const tripReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CURRENT_TRIP: {
      return {
        ...state,
        currentTrip: {
          tripName: action.payload.Name,
          description: action.payload.Description,
          participants: action.payload.Participants,
          startDate: action.payload.StartDate,
          endDate: action.payload.EndDate,
          message: action.payload.Message,
          ownerTrip: action.payload.OwnerUser_ID,
          LastChanged: action.payload.ModifiedDate,
          tid: action.payload.tid,
          status: action.payload.Status,
          ...action.payload,
        },
      };
    }
    case actionTypes.SAVE_ALL_TRIPS: {
      return {
        ...state,
        allTrips: action.payload,
      };
    }
    case actionTypes.SAVE_ENTIRE_TRIPS: {
      return {
        ...state,
        entireTrips: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_SENT_INVITATION_PAGINATION: {
      return {
        ...state,
        tripSentInvitationalPagination: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_RECIEVED_INVITATION_PAGINATION: {
      return {
        ...state,
        tripRecivedInvitationalPagination: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_SENT_RECOMMENDATION_PAGINATION: {
      return {
        ...state,
        tripSentRecommendationPagination: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_RECIEVED_RECOMMENDATION_PAGINATION: {
      return {
        ...state,
        tripRecivedRecommendationPagination: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_USED_FILTERS: {
      return {
        ...state,
        usedFilters: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_INVITATION_SORT_ELEMENT: {
      return {
        ...state,
        sortElementInvitation: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_INVITATION_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_RECIVED_INVITATIONAL: {
      return {
        ...state,
        allTripRecivedInvitations: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_SENT_INVITATIONAL: {
      return {
        ...state,
        allTripSentInvitations: action.payload,
      };
    }
    case actionTypes.SAVE_ALL_TRIP_RECOMMENDATIONS: {
      return {
        ...state,
        allTripRecommendations: action.payload,
      };
    }
    case actionTypes.SAVE_TOTAL_TRIP_RECOMMENDATIONS: {
      return {
        ...state,
        totalTripRecommendations: action.payload,
      };
    }
    case actionTypes.SAVE_ALL_SENT_TRIP_RECOMMENDATIONS: {
      return {
        ...state,
        allSentTripRecommendations: action.payload,
      };
    }
    case actionTypes.SAVE_TRIP_INVITATIONAL_TOTAL_NUMBER: {
      return {
        ...state,
        totalTripInvitationals: action.payload,
      };
    }
    case actionTypes.SAVE_FILTER_TRIP_RESULTS: {
      return {
        ...state,
        filterData: action.payload,
      };
    }
    case actionTypes.CLEAR_FILTER_TRIP_RESULTS: {
      return {
        ...state,
        filterData: null,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allTrips: null,
        entireTrips: null,
        allTripInvitations: null,
        totalTripRecommendations: null,
        allSentTripRecommendations: null,
        allTripRecommendations: null,
        invitationalTotalNumber: 0,
        tripSentInvitationalPagination: null,
        tripRecivedInvitationalPagination: null,
        tripSentRecommendationPagination: null,
        tripRecivedRecommendationPagination: null,
        paginationData: null,
        filterData: null,
        sortElement: "ModifiedDate",
        sortByDirection: {
          Name: "asc",
          StartDate: "asc",
          Message: "asc",
          DisplayName: "asc",
          OwnerUser_ID: "asc",
          OwnerPlanningStatusName: "asc",
          ModifiedDate: "desc",
        },
        sortElementInvitation: "ModifiedDate",
        sortByDirectionInvitaiton: {
          Name: "asc",
          StartDate: "asc",
          Message: "asc",
          DisplayName: "asc",
          OwnerUser_ID: "asc",
          OwnerPlanningStatusName: "asc",
          ModifiedDate: "asc",
          UserRoleName: "asc",
        },
        currentTrip: {
          tripName: "",
          description: "",
          participants: "",
          message: "",
          startDate: "",
          endDate: "",
          OwnerTrip: "",
          LastChanged: "",
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default tripReducer;
