
import { BASE_URL } from '../../config';
import { showSnackBar } from '../../store/actions/actions/root';
import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { changeVerificationCode, getAllVerificationCodes, saveCurrentVerificationCode } from '../../store/actions/actions/verificationCode';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../admin.css';
import * as userAccess from '../../userAccess';

const VerificationCodeModal = ({ open, handleVerificationCodeModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [customerNameError, setCustomerNameError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [formError, setFormError] = useState(false);
  const currentVerificationCode = useSelector(state => state.verificationCode.currentVerificationCode);
  const [values, setValues] = useState({
    VerificationCode_ID: 0,
    CustomerName: '',
    VerificationCode: '',
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    if (defaultValues) {
        if (currentVerificationCode)
        {
            setValues({
              VerificationCode_ID: currentVerificationCode.VerificationCode_ID,
              CustomerName: currentVerificationCode.CustomerName,
              VerificationCode: currentVerificationCode.VerificationCode,
            });
        }
        else
        {
            setValues({
                VerificationCode_ID: defaultValues.VerificationCode_ID,
                CustomerName: defaultValues.CustomerName,
                VerificationCode: defaultValues.VerificationCode,
            });
        }
        
        handleIsEditMode(true);
    } else {
      setValues({
        VerificationCode_ID: 0,
        CustomerName: '',
        VerificationCode: '',
      });
      handleIsEditMode(false);
    }
  }, [defaultValues, currentVerificationCode, dispatch]);

  const validateForm = ({ CustomerName, VerificationCode }) => {
    var anyError = false;
    if (VerificationCode)
      setCodeError(false);
    else
    {
      setCodeError(true);
      anyError = true;
    }
    if (CustomerName)
      setCustomerNameError(false);
    else
    {
      setCustomerNameError(true);
      anyError = true;
    }
    setFormError(anyError);
    return !anyError;
  };


  const handleSubmit = e => {
    e.stopPropagation();
    if (!customerNameError && !codeError) {
        e.preventDefault();
        const data = {
            VerificationCode_ID: +values.VerificationCode_ID,
            CustomerName: values.CustomerName,
            VerificationCode: values.VerificationCode,
        };
      var jsonData = JSON.stringify(data);
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changeVerificationCode(jsonData));
          handleVerificationCodeModalClose();
        }
      } else {
        if (validateForm(data)) {
          // we need to call createNewVerificationCode localy to handle return value
          // dispatch(createNewVerificationCode(JSON.stringify(data)));
          fetch(`${BASE_URL}api/verification/create.php`, {
            method: 'POST',
            body: jsonData,
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(response => {
              if (response.status === 201) {
                return response.text();
              } 
              if (response.status === 503 && response.type === 'cors')
              {
                return response.text();
              }
              else  {
                dispatch(
                  showSnackBar({
                    type: 'error',
                    message: 'Something went wrong!',
                  }),
                );
              }
            })
            .then(response => {
              if (response)
              {
                const res = JSON.parse(response);
                if (res.ErrorCode)
                {
                  confirmAlert({
                    title: 'New VerificationCode',
                    message: 'Error: Code = ' + res.ErrorCode + ", Message = " + res.ErrorMsg,
                    buttons: [
                      {
                        label: 'Close',
                      }
                    ]
                  });
                }
                else
                {
                  dispatch(saveCurrentVerificationCode(res));
                  dispatch(getAllVerificationCodes());
                  handleVerificationCodeModalClose();
                }
              }
            })
            .catch(error => console.log(error));
        }
      }
    }
  };

  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name === 'CustomerName') {
      if (e.target.value.length === 0) {
        setCustomerNameError(true);
      } else {
        setCustomerNameError(false);
      }
    }
    else if (e.target.name === 'VerificationCode') {
      if (e.target.value.length === 0) {
        setCodeError(true);
      } else {
        setCodeError(false);
      }
    }
    setValues({...values, [e.target.name]: e.target.value});
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const renderTitle = defaultValues ? t('Edit Verification Code') : t('New Verification Code');
      
  const renderCustomerNameError = customerNameError
    ? t('CustomerName is required.')
    : null;

  const renderCodeError = codeError
    ? t('VerificationCode is required.')
    : null;

  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;


  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_VerificationCodes))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_VerificationCodes));


  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={12} className={classes.languageName}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  id="CustomerName"
                  label={t('Customer Name')}
                  name="CustomerName"
                  autoComplete="CustomerName"
                  value={values.CustomerName}
                  onChange={handleInputs}
                />
                <FormHelperText className={classes.errorText}>
                  {renderCustomerNameError}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={12} className={classes.languageName}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  id="VerificationCode"
                  label={t('Verification Code')}
                  name="VerificationCode"
                  autoComplete="VerificationCode"
                  value={values.VerificationCode}
                  onChange={handleInputs}
                />
                <FormHelperText className={classes.errorText}>
                  {renderCodeError}
                </FormHelperText>
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleVerificationCodeModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default VerificationCodeModal;