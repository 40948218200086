import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const ResponsibleModal = ({
  open,
  handleClose,
  handleSaveParticipants,
  allUsers,
  participants,
}) => {
  const [arrayParticipants, setArrayParticipants] = useState([]);
  const [values, setValues] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const sortCriterium = useSelector(
    state => state.programSettings.participantsSorting,
  );

  useEffect(() => {
    if (allUsers) {
      const users = [...allUsers];
      if (sortCriterium === 'lastname') {
        users.sort((a, b) => a.LastName.localeCompare(b.LastName));
        setArrayParticipants(users);
      } else if (sortCriterium === 'firstname') {
        users.sort((a, b) => a.FirstName.localeCompare(b.FirstName));
        setArrayParticipants(users);
      } else {
        setArrayParticipants(users);
      }
      const selectedUsers = [];
      users.forEach(user => {
        if (participants && participants.length > 0) {
          participants.forEach(par => {
            if (
              user.User_ID === par.TrippetOrganizerUser_ID ||
              user.User_ID === par.User_ID
            ) {
              selectedUsers.push(user);
            }
          });
          setValues(selectedUsers);
        }
      });
    }
  }, [allUsers, participants, sortCriterium]);

  const handleSave = () => {
    handleSaveParticipants(values);
    handleClose();
  };

  const handleChange = (event, values) => {
    setValues(values);
  };

  const renderFirstNameAndLastName = participant =>
    sortCriterium === 'lastname'
      ? `${participant.LastName} ${participant.FirstName}`
      : `${participant.FirstName} ${participant.LastName}`;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Who should be responsible?')}
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={arrayParticipants}
            disableCloseOnSelect
            onChange={handleChange}
            value={values}
            getOptionLabel={option => renderFirstNameAndLastName(option)}
            renderOption={(option, { selected }) => (
              <Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  name={option.user_id}
                  color="primary"
                />
                {renderFirstNameAndLastName(option)}
              </Fragment>
            )}
            style={{ width: 500 }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={t('Responsibles')}
                placeholder={t('Responsibles')}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('Exit')}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            autoFocus
            variant="contained"
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResponsibleModal;
