import * as actionTypes from '../actions/actionTypes/actionTypes';

const initialState = {
  paths: null,
  markers: null,
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PATHS: {
      return {
        ...state,
        paths: action.payload,
      };
    }
    case actionTypes.SAVE_MARKERS: {
      return {
        ...state,
        markers: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        paths: null,
        markers: null,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default mapReducer;
