import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Logo from '../../assets/TP-logo-800px.png';
import useStyles from '../signIn/styles';
import Copyright from '../../components/Copyright';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createAccount } from '../../store/actions/actions/user';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';

const SingUp = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [errorPasswordMessage, setErrorPasswordMessage] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    language: '',
    firstName: '',
    lastName: '',
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (values.password === values.repeatPassword) {
      setErrorPasswordMessage(false);
      const body = {
        FirstName: values.firstName,
        LastName: values.lastName,
        Email: values.email,
        DisplayName: `${values.firstName} ${values.lastName}`,
        Password: values.password,
        Gender: '',
        HomeAddress: '',
        PhoneNumber: '',
        Country_ID: '',
        BaseCurrency_ID: '',
        BirthDate: '1960-01-28',
        AdminRole_ID: '1',
        Language_ID: '2',
        Department_ID: '1',
        LastTrip_ID: '',
        TimeZone: '+01:00',
        TimeZoneDesc: 'Europe/Berlin',
        Picture: '',
      };

      dispatch(createAccount(JSON.stringify(body), props.history));
    } else {
      setErrorPasswordMessage(true);
    }
  };

  const handleInputs = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const renderPasswordMessage = errorPasswordMessage ? (
    <FormHelperText className={classes.errorMessage}>
      {t('Please make sure your passwords match.')}
    </FormHelperText>
  ) : null;

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image}>
        <img src={Logo} alt="logo" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        className={classes.formContainer}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Sign up')}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label={t('First Name')}
                  autoFocus
                  value={values.firstName}
                  onChange={handleInputs}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label={t('Last Name')}
                  name="lastName"
                  autoComplete="lname"
                  value={values.lastName}
                  onChange={handleInputs}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label={t('Email Address')}
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  type="email"
                  onChange={handleInputs}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                  required
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Languages')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Language')}
                    name="language"
                    value={values.language}
                    onChange={handleInputs}
                  >
                    <MenuItem value="EN">EN</MenuItem>
                    <MenuItem value="DE">DE</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label={t('Password')}
                  value={values.password}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={handleInputs}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="repeatPassword"
                  label={t('Repeat Password')}
                  value={values.repeatPassword}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={handleInputs}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {renderPasswordMessage}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t('Sign up')}
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link to="/login" variant="body2" className={classes.link}>
                  {t('Already have an account? Sign in')}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default withRouter(SingUp);
