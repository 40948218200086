import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { format } from 'date-fns';

const TripFilterModal = ({
  open,
  handleClose,
  name,
  handleFilterValues,
  filterResults,
}) => {
  const [data, setData] = useState([]);
  const [values, setValues] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [text, setText] = useState('');
  const [isSelect, setIsSelect] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const filterData = useSelector(state => state.trip.filterData);

  useEffect(() => {
    if (name) {
      if (filterData) {
        let array = [...filterData];
        setIsSelect(true);
        let defaultData = [];
        array.forEach(filter => {
          if (filterResults && filterResults.length > 0) {
            filterResults.forEach(item => {
              if (item.type === name.filter) {
                if (item.data.length > 0) {
                  item.data.forEach(el => {
                    if (el.StartDate) {
                      if (el.StartDate === filter.StartDate) {
                        defaultData.push(filter);
                      }
                    } else if (el.EndDate) {
                      if (el.EndDate === filter.EndDate) {
                        defaultData.push(filter);
                      }
                    } else if (el.TripParticipantUser_ID) {
                      if (
                        el.TripParticipantUser_ID ===
                        filter.TripParticipantUser_ID
                      ) {
                        defaultData.push(filter);
                      }
                    } else if (el.OwnerUser_ID) {
                      if (el.OwnerUser_ID === filter.OwnerUser_ID) {
                        defaultData.push(filter);
                      }
                    } else if (el.OwnerPlanningStatus_ID) {
                      if (
                        el.OwnerPlanningStatus_ID ===
                        filter.OwnerPlanningStatus_ID
                      ) {
                        defaultData.push(filter);
                      }
                    } else if (el.EndDays) {
                      if (el.EndDays === filter.EndDays) {
                        defaultData.push(filter);
                      }
                    } else if (el.IsPrivate) {
                      if (el.IsPrivate === filter.IsPrivate) {
                        defaultData.push(filter);
                      }
                    }
                  });
                }
              }
            });
            setValues(defaultData);
          }
        });
        setData(array);
      } else {
        setIsSelect(false);
        if (filterResults && filterResults.length > 0) {
          let defaultData = [];
          filterResults.forEach(item => {
            if (item.type === name.filter) {
              defaultData = [...defaultData, ...item.data];
            }
            setValues(defaultData);
          });
        }
      }
    } else {
      setData([]);
    }
  }, [filterData, filterResults, name]);

  const handleSave = () => {
    handleClose();
    let data = isSelect ? values : text;
    handleFilterValues(name.filter, data);
    setValues([]);
    setIsSelect(true);
  };

  const handleInputs = event => {
    setText(event.target.value);
  };

  const handleChange = (event, values) => {
    setValues(values);
  };

  const handleSelectAll = event => {
    setIsAllSelected(event.target.checked);
    if (event.target.checked === true) {
      setValues([...filterData]);
    } else {
      setValues([]);
    }
  };

  const renderOptions = option => {
    if (name.filter === 'startdates') {
      if (option.StartDate !== ':empty:') {
        let convertedDate = new Date(option.StartDate);
        let date = format(new Date(convertedDate), 'EEEEEE.dd MMM');
        return date;
      } else {
        return `${option.StartDate}`;
      }
    } else if (name.filter === 'enddates') {
      if (option.EndDate !== ':empty:') {
        let convertedDate = new Date(option.EndDate);
        let date = format(new Date(convertedDate), 'EEEEEE.dd MMM');
        return date;
      } else {
        return `${option.EndDate}`;
      }
    } else if (name.filter === 'owners') {
      return `${option.OwnerDisplayName}`;
    } else if (name.filter === 'typelooks') {
      return `${option.TrippetTypeLookName}`;
    } else if (name.filter === 'ownerplanningstatuses') {
      return `${option.OwnerPlanningStatusName}`;
    } else if (name.filter === 'startdays') {
      return `${option.StartDay}`;
    } else if (name.filter === 'enddays') {
      return `${option.EndDay}`;
    } else if (name.filter === 'participants') {
      return `${option.TripParticipantDisplayName}`;
    } else if (name.filter === 'isprivates') {
      if (option.IsPrivate === 1 || option.IsPrivate === '1') {
        return t('Set private');
      } else {
        return t('Not private');
      }
    }
  };

  const handleDeleteFilter = () => {
    setIsAllSelected(false);
    setValues([]);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const renderElement = isSelect ? (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={isAllSelected}
            onChange={handleSelectAll}
            name="privateTrip"
            color="primary"
          />
        }
        label={t('Select all')}
      />
      <Button color="primary" onClick={handleDeleteFilter}>
        {t('Delete Filter')}
      </Button>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={data}
        disableCloseOnSelect
        onChange={handleChange}
        value={values}
        getOptionLabel={option => renderOptions(option)}
        renderOption={(option, { selected }) => (
          <Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              name={option.user_id}
              color="primary"
            />
            {renderOptions(option)}
          </Fragment>
        )}
        style={{ width: 500 }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={t('Filter')}
            placeholder={t('Filter')}
          />
        )}
      />
    </Fragment>
  ) : (
    <TextField
      variant="outlined"
      name={name.type}
      value={text}
      placeholder={t('Filter for type')}
      fullWidth
      style={{ width: 420 }}
      onChange={handleInputs}
      label={t('Filter for type')}
      id="pictureTitleId"
    />
  );

  const onClose = () => {
    handleClose();
    setValues([]);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Filter for')} {` ${name && name.label}`}
        </DialogTitle>
        <DialogContent>{renderElement}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose} color="primary">
            {t('Exit')}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            autoFocus
            variant="contained"
          >
            {t('Apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TripFilterModal;
