import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSettingItems,
  deleteSettingItem,
  saveCurrentPage,
} from "../../store/actions/actions/settingItem";
import Table from "./Table";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import { ROWS_PER_PAGE } from "../../config";
import * as userAccess from "../../userAccess";
import SettingItemModal from "../../components/Setting/SettingItemForm";

const SettingItems = ({SettingCategory}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const allSettingItems = useSelector(
    state => state.settingItem.allSettingItems,
  );
  const paginationData = useSelector(state => state.settingItem.paginationData);
  const currentPage = useSelector(state => state.settingItem.currentPage);
  const [deletedSettingItem, setDeletedSettingItem] = useState(null);
  const [isOpenConfirmDeleteModal, setisOpenConfirmDeleteModal] = useState(
    false,
  );
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortedElement, setSortedElement] = useState("Name");
  const [sortedDir, setSortedDir] = useState("asc");
  const [isSettingItemModalOpen, setIsSettingItemModalOpen] = useState(false);

  useEffect(() => {
    const bodySearch = {
      Page: currentPage,
      RowsPerPage: ROWS_PER_PAGE,
      OrderBy: sortedElement,
      Language_ID: "2",
      SettingCategory_ID: SettingCategory.SettingCategory_ID,
      OrderDir: sortedDir,
    };

    dispatch(getAllSettingItems(JSON.stringify(bodySearch)));
  }, [sortedElement, sortedDir, currentPage, dispatch, SettingCategory.SettingCategory_ID]);

  
  if (allSettingItems && allSettingItems.length)
  {
    if (Number(allSettingItems[0].SettingCategory_ID) === Number(SettingCategory.SettingCategory_ID))
        SettingCategory.Items = allSettingItems;
  }

  useEffect(() => {
    if (paginationData) {
      setTotalItemsCount(paginationData.Num);
    }
  }, [paginationData]);

  const handleSortDir = id => () => {
    setSortedDir(sortedElement === id && sortedDir === "asc" ? "desc" : "asc");
    setSortedElement(id);
  };

  const onPaginationChange = activePage => {
    dispatch(saveCurrentPage(activePage));
  };

  const handleConfirmDeleteModalClose = () => {
    setisOpenConfirmDeleteModal(false);
  };

  const handleConfirmDeleteModalOpen = () => {
    setisOpenConfirmDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteSettingItem(JSON.stringify(deletedSettingItem)));
  };

  const handleSettingItemModalOpen = () => {
    setIsSettingItemModalOpen(true);
  };

  const handleSettingItemModalClose = () => {
    setIsSettingItemModalOpen(false);
  };

  const handleNewClick = () => {
    handleSettingItemModalOpen();
  };

  const newItemValues = {SettingCategory_ID: SettingCategory.SettingCategory_ID};
  return userAccess.canRead(user, userAccess.Table_Settings) ? (
    <Grid container justify="center" className={classes.containerMyTrip}>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {t("Setting Items")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Fab
            variant="extended"
            className={classes.addTripButton}
            onClick={handleNewClick}
            disabled={!userAccess.canWrite(user, userAccess.Table_Settings)}
          >
            <AddIcon className={classes.extendedIcon} />
            {t("Setting Item")}
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          data={SettingCategory.Items}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedSettingItem={setDeletedSettingItem}
          sortedElement={sortedElement}
          sortedBy={sortedDir}
          handleSortBy={handleSortDir}
        />
      </Grid>
      <AdminPagination
        activePage={currentPage}
        itemsPerPage={ROWS_PER_PAGE}
        totalItemsCount={totalItemsCount}
        onChange={onPaginationChange}
        title="setting items"
      />
      <ConfirmDeleteModal
        open={isOpenConfirmDeleteModal}
        handleClose={handleConfirmDeleteModalClose}
        handleDelete={handleDelete}
      />
      <SettingItemModal
        open={isSettingItemModalOpen}
        handleSettingItemModalClose={handleSettingItemModalClose}
        defaultValues={newItemValues}
      />
    </Grid>
  ) : (
    <div>NO ACCESS</div>
  );
};

export default SettingItems;
