// config actions
export const SAVE_ALL_CONFIGS = 'SAVE_ALL_CONFIGS';

// user actions
export const SAVE_CURRENT_USER = 'SAVE_CURRENT_USER';
export const SAVE_ALL_USERS = 'SAVE_ALL_USERS';
export const SAVE_USER_PAGINATION_DATA = 'SAVE_USER_PAGINATION_DATA';
export const SAVE_USER_SORT_ELEMENT = 'SAVE_USER_SORT_ELEMENT';
export const SAVE_USER_SORT_BY_DIRECTION = 'SAVE_USER_SORT_BY_DIRECTION';
export const SAVE_USER_CURRENT_PAGE = 'SAVE_USER_CURRENT_PAGE';

// verification codes actions
export const SAVE_CURRENT_VERIFICATIONCODE = 'SAVE_CURRENT_VERIFICATIONCODE';
export const SAVE_ALL_VERIFICATIONCODES = 'SAVE_ALL_VERIFICATIONCODES';
export const SAVE_VERIFICATIONCODE_PAGINATION_DATA = 'SAVE_VERIFICATIONCODE_PAGINATION_DATA';
export const SAVE_VERIFICATIONCODE_SORT_ELEMENT = 'SAVE_VERIFICATIONCODE_SORT_ELEMENT';
export const SAVE_VERIFICATIONCODE_SORT_BY_DIRECTION = 'SAVE_VERIFICATIONCODE_SORT_BY_DIRECTION';
export const SAVE_VERIFICATIONCODE_CURRENT_PAGE = 'SAVE_VERIFICATIONCODE_CURRENT_PAGE';

// trippet type actions
export const SAVE_CURRENT_CATEGORY = 'SAVE_CURRENT_CATEGORY';
export const SAVE_ALL_CATEGORIES = 'SAVE_ALL_CATEGORIES';
export const SAVE_CATEGORY_PAGINATION_DATA = 'SAVE_CATEGORY_PAGINATION_DATA';
export const SAVE_CATEGORY_SORT_ELEMENT = 'SAVE_CATEGORY_SORT_ELEMENT';
export const SAVE_CATEGORY_SORT_BY_DIRECTION = 'SAVE_CATEGORY_SORT_BY_DIRECTION';
export const SAVE_CATEGORY_CURRENT_PAGE = 'SAVE_CATEGORY_CURRENT_PAGE';

// language actions
export const SAVE_CURRENT_LANGUAGE = 'SAVE_CURRENT_LANGUAGE';
export const SAVE_ALL_LANGUAGES = 'SAVE_ALL_LANGUAGES';
export const SAVE_ENTIRE_LANGUAGES = 'SAVE_ENTIRE_LANGUAGES';
export const SAVE_LANGUAGE_PAGINATION_DATA = 'SAVE_LANGUAGE_PAGINATION_DATA';
export const SAVE_LANGUAGE_SORT_ELEMENT = 'SAVE_LANGUAGE_SORT_ELEMENT';
export const SAVE_LANGUAGE_SORT_BY_DIRECTION = 'SAVE_LANGUAGE_SORT_BY_DIRECTION';
export const SAVE_LANGUAGE_CURRENT_PAGE = 'SAVE_LANGUAGE_CURRENT_PAGE';

// planningStatus actions
export const SAVE_CURRENT_PLANNINGSTATUS = 'SAVE_CURRENT_PLANNINGSTATUS';
export const SAVE_ALL_PLANNINGSTATUSES = 'SAVE_ALL_PLANNINGSTATUSES';
export const SAVE_PLANNINGSTATUS_FILTERTRIPID = 'SAVE_PLANNINGSTATUS_FILTERTRIPID';
export const SAVE_PLANNINGSTATUS_FILTERTRIPS = 'SAVE_PLANNINGSTATUS_FILTERTRIPS';
export const SAVE_PLANNINGSTATUS_PAGINATION_DATA = 'SAVE_PLANNINGSTATUS_PAGINATION_DATA';
export const SAVE_PLANNINGSTATUS_SORT_ELEMENT = 'SAVE_PLANNINGSTATUS_SORT_ELEMENT';
export const SAVE_PLANNINGSTATUS_SORT_BY_DIRECTION = 'SAVE_PLANNINGSTATUS_SORT_BY_DIRECTION';
export const SAVE_PLANNINGSTATUS_CURRENT_PAGE = 'SAVE_PLANNINGSTATUS_CURRENT_PAGE';

// planningStatus actions
export const SAVE_CURRENT_PARTICIPANTSTATUS = 'SAVE_CURRENT_PARTICIPANTSTATUS';
export const SAVE_ALL_PARTICIPANTSTATUSES = 'SAVE_ALL_PARTICIPANTSTATUSES';
export const SAVE_PARTICIPANTSTATUS_PAGINATION_DATA = 'SAVE_PARTICIPANTTATUS_PAGINATION_DATA';
export const SAVE_PARTICIPANTSTATUS_SORT_ELEMENT = 'SAVE_PARTICIPANTSTATUS_SORT_ELEMENT';
export const SAVE_PARTICIPANTSTATUS_SORT_BY_DIRECTION = 'SAVE_PARTICIPANTSTATUS_SORT_BY_DIRECTION';
export const SAVE_PARTICIPANTSTATUS_CURRENT_PAGE = 'SAVE_PARTICIPANTSTATUS_CURRENT_PAGE';

// country actions
export const SAVE_CURRENT_COUNTRY = 'SAVE_CURRENT_COUNTRY';
export const SAVE_ALL_COUNTRIES = 'SAVE_ALL_COUNTRIES';
export const SAVE_ENTIRE_COUNTRIES = 'SAVE_ENTIRE_COUNTRIES';
export const SAVE_COUNTRY_PAGINATION_DATA = 'SAVE_COUNTRY_PAGINATION_DATA';
export const SAVE_COUNTRY_SORT_ELEMENT = 'SAVE_COUNTRY_SORT_ELEMENT';
export const SAVE_COUNTRY_SORT_BY_DIRECTION = 'SAVE_COUNTRY_SORT_BY_DIRECTION';
export const SAVE_COUNTRY_CURRENT_PAGE = 'SAVE_COUNTRY_CURRENT_PAGE';

// currency actions
export const SAVE_CURRENT_CURRENCY = 'SAVE_CURRENT_CURRENCY';
export const SAVE_ALL_CURRENCIES = 'SAVE_ALL_CURRENCIES';
export const SAVE_CURRENCY_PAGINATION_DATA = 'SAVE_CURRENCY_PAGINATION_DATA';
export const SAVE_CURRENCY_SORT_ELEMENT = 'SAVE_CURRENCY_SORT_ELEMENT';
export const SAVE_CURRENCY_SORT_BY_DIRECTION = 'SAVE_CURRENCY_SORT_BY_DIRECTION';
export const SAVE_CURRENCY_CURRENT_PAGE = 'SAVE_CURRENCY_CURRENT_PAGE';

// settingcategory actions
export const SAVE_CURRENT_SETTINGCATEGORY = 'SAVE_CURRENT_SETTINGCATEGORY';
export const SAVE_ALL_SETTINGCATEGORIES = 'SAVE_ALL_SETTINGCATEGORIES';
export const SAVE_SETTINGCATEGORY_PAGINATION_DATA = 'SAVE_SETTINGCATEGORY_PAGINATION_DATA';
export const SAVE_SETTINGCATEGORY_SORT_ELEMENT = 'SAVE_SETTINGCATEGORY_SORT_ELEMENT';
export const SAVE_SETTINGCATEGORY_SORT_BY_DIRECTION = 'SAVE_SETTINGCATEGORY_SORT_BY_DIRECTION';
export const SAVE_SETTINGCATEGORY_CURRENT_PAGE = 'SAVE_SETTINGCATEGORY_CURRENT_PAGE';
export const OPEN_SETTINGCATEGORY_ROW = "OPEN_SETTINGCATEGORY_ROW";
export const CLOSE_SETTINGCATEGORY_ROW = "CLOSE_SETTINGCATEGORY_ROW";
export const SAVE_SETTINGCATEGORY_ROW = "SAVE_SETTINGCATEGORY_ROW";

// settingitem actions
export const SAVE_CURRENT_SETTINGITEM = 'SAVE_CURRENT_SETTINGITEM';
export const SAVE_ALL_SETTINGITEMS = 'SAVE_ALL_SETTINGITEMS';
export const SAVE_SETTINGITEM_PAGINATION_DATA = 'SAVE_SETTINGITEM_PAGINATION_DATA';
export const SAVE_SETTINGITEM_SORT_ELEMENT = 'SAVE_SETTINGITEM_SORT_ELEMENT';
export const SAVE_SETTINGITEM_SORT_BY_DIRECTION = 'SAVE_SETTINGITEM_SORT_BY_DIRECTION';
export const SAVE_SETTINGITEM_CURRENT_PAGE = 'SAVE_SETTINGITEM_CURRENT_PAGE';

// preferencecategory actions
export const SAVE_CURRENT_PREFERENCECATEGORY = 'SAVE_CURRENT_PREFERENCECATEGORY';
export const SAVE_ALL_PREFERENCECATEGORIES = 'SAVE_ALL_PREFERENCECATEGORIES';
export const SAVE_PREFERENCECATEGORY_PAGINATION_DATA = 'SAVE_PREFERENCECATEGORY_PAGINATION_DATA';
export const SAVE_PREFERENCECATEGORY_SORT_ELEMENT = 'SAVE_PREFERENCECATEGORY_SORT_ELEMENT';
export const SAVE_PREFERENCECATEGORY_SORT_BY_DIRECTION = 'SAVE_PREFERENCECATEGORY_SORT_BY_DIRECTION';
export const SAVE_PREFERENCECATEGORY_CURRENT_PAGE = 'SAVE_PREFERENCECATEGORY_CURRENT_PAGE';
export const OPEN_PREFERENCECATEGORY_ROW = "OPEN_PREFERENCECATEGORY_ROW";
export const CLOSE_PREFERENCECATEGORY_ROW = "CLOSE_PREFERENCECATEGORY_ROW";
export const SAVE_PREFERENCECATEGORY_ROW = "SAVE_PREFERENCECATEGORY_ROW";

// preferenceitem actions
export const SAVE_CURRENT_PREFERENCEITEM = 'SAVE_CURRENT_PREFERENCEITEM';
export const SAVE_ALL_PREFERENCEITEMS = 'SAVE_ALL_PREFERENCEITEMS';
export const SAVE_PREFERENCEITEM_PAGINATION_DATA = 'SAVE_PREFERENCEITEM_PAGINATION_DATA';
export const SAVE_PREFERENCEITEM_SORT_ELEMENT = 'SAVE_PREFERENCEITEM_SORT_ELEMENT';
export const SAVE_PREFERENCEITEM_SORT_BY_DIRECTION = 'SAVE_PREFERENCEITEM_SORT_BY_DIRECTION';
export const SAVE_PREFERENCEITEM_CURRENT_PAGE = 'SAVE_PREFERENCEITEM_CURRENT_PAGE';

// trippettypecategory actions
export const SAVE_CURRENT_TRIPPETTYPECATEGORY = 'SAVE_CURRENT_TRIPPETTYPECATEGORY';
export const SAVE_ALL_TRIPPETTYPECATEGORIES = 'SAVE_ALL_TRIPPETTYPECATEGORIES';
export const SAVE_TRIPPETTYPECATEGORY_PAGINATION_DATA = 'SAVE_TRIPPETTYPECATEGORY_PAGINATION_DATA';
export const SAVE_TRIPPETTYPECATEGORY_SORT_ELEMENT = 'SAVE_TRIPPETTYPECATEGORY_SORT_ELEMENT';
export const SAVE_TRIPPETTYPECATEGORY_SORT_BY_DIRECTION = 'SAVE_TRIPPETTYPECATEGORY_SORT_BY_DIRECTION';
export const SAVE_TRIPPETTYPECATEGORY_CURRENT_PAGE = 'SAVE_TRIPPETTYPECATEGORY_CURRENT_PAGE';
export const OPEN_TRIPPETTYPECATEGORY_ROW = "OPEN_TRIPPETTYPECATEGORY_ROW";
export const CLOSE_TRIPPETTYPECATEGORY_ROW = "CLOSE_TRIPPETTYPECATEGORY_ROW";
export const SAVE_TRIPPETTYPECATEGORY_ROW = "SAVE_TRIPPETTYPECATEGORY_ROW";
export const SAVE_TRIPPETTYPECATEGORY_FILTERTRIPID = "SAVE_TRIPPETTYPECATEGORY_FILTERTRIPID";
export const SAVE_TRIPPETTYPECATEGORY_FILTERTRIPS = "SAVE_TRIPPETTYPECATEGORY_FILTERTRIPS";

// trippettypelook actions
export const SAVE_CURRENT_TRIPPETTYPELOOK = 'SAVE_CURRENT_TRIPPETTYPELOOK';
export const SAVE_ALL_TRIPPETTYPELOOKS = 'SAVE_ALL_TRIPPETTYPELOOKS';
export const SAVE_TRIPPETTYPELOOK_PAGINATION_DATA = 'SAVE_TRIPPETTYPELOOK_PAGINATION_DATA';
export const SAVE_TRIPPETTYPELOOK_SORT_ELEMENT = 'SAVE_TRIPPETTYPELOOK_SORT_ELEMENT';
export const SAVE_TRIPPETTYPELOOK_SORT_BY_DIRECTION = 'SAVE_TRIPPETTYPELOOK_SORT_BY_DIRECTION';
export const SAVE_TRIPPETTYPELOOK_CURRENT_PAGE = 'SAVE_TRIPPETTYPELOOK_CURRENT_PAGE';

// department actions
export const SAVE_CURRENT_DEPARTMENT = 'SAVE_CURRENT_DEPARTMENT';
export const SAVE_ALL_DEPARTMENTS = 'SAVE_ALL_DEPARTMENTS';
export const SAVE_ENTIRE_DEPARTMENTS = 'SAVE_ENTIRE_DEPARTMENTS';
export const SAVE_DEPARTMENT_PAGINATION_DATA = 'SAVE_DEPARTMENT_PAGINATION_DATA';
export const SAVE_DEPARTMENT_SORT_ELEMENT = 'SAVE_DEPARTMENT_SORT_ELEMENT';
export const SAVE_DEPARTMENT_SORT_BY_DIRECTION = 'SAVE_DEPARTMENT_SORT_BY_DIRECTION';
export const SAVE_DEPARTMENT_CURRENT_PAGE = 'SAVE_DEPARTMENT_CURRENT_PAGE';

// adminRole actions
export const SAVE_CURRENT_ADMINROLE = 'SAVE_CURRENT_ADMINROLE';
export const SAVE_ALL_ADMINROLES = 'SAVE_ALL_ADMINROLES';
export const SAVE_ENTIRE_ADMINROLES = 'SAVE_ENTIRE_ADMINROLES';
export const SAVE_ADMINROLE_PAGINATION_DATA = 'SAVE_ADMINROLE_PAGINATION_DATA';
export const SAVE_ADMINROLE_SORT_ELEMENT = 'SAVE_ADMINROLE_SORT_ELEMENT';
export const SAVE_ADMINROLE_SORT_BY_DIRECTION = 'SAVE_ADMINROLE_SORT_BY_DIRECTION';
export const SAVE_ADMINROLE_CURRENT_PAGE = 'SAVE_ADMINROLE_CURRENT_PAGE';

// adminRoleAcces actions
export const SAVE_CURRENT_ADMINROLEACCESS = 'SAVE_CURRENT_ADMINROLEACCESS';
export const SAVE_ALL_ADMINROLEACCESSES = 'SAVE_ALL_ADMINROLEACCESSES';
export const SAVE_ADMINROLEACCESS_PAGINATION_DATA = 'SAVE_ADMINROLEACCESS_PAGINATION_DATA';
export const SAVE_ADMINROLEACCESS_SORT_ELEMENT = 'SAVE_ADMINROLEACCESS_SORT_ELEMENT';
export const SAVE_ADMINROLEACCESS_SORT_BY_DIRECTION = 'SAVE_ADMINROLEACCESS_SORT_BY_DIRECTION';
export const SAVE_ADMINROLEACCESS_CURRENT_PAGE = 'SAVE_ADMINROLEACCESS_CURRENT_PAGE';

// userRole actions
export const SAVE_CURRENT_USERROLE = 'SAVE_CURRENT_USERROLE';
export const SAVE_ALL_USERROLES = 'SAVE_ALL_USERROLES';
export const SAVE_ENTIRE_USERROLES = 'SAVE_ENTIRE_USERROLES';
export const SAVE_USERROLE_PAGINATION_DATA = 'SAVE_USERROLE_PAGINATION_DATA';
export const SAVE_USERROLE_SORT_ELEMENT = 'SAVE_USERROLE_SORT_ELEMENT';
export const SAVE_USERROLE_SORT_BY_DIRECTION = 'SAVE_USERROLE_SORT_BY_DIRECTION';
export const SAVE_USERROLE_CURRENT_PAGE = 'SAVE_USERROLE_CURRENT_PAGE';

// adminRoleAcces actions
export const SAVE_CURRENT_USERROLEALLOW = 'SAVE_CURRENT_USERROLEALLOW';
export const SAVE_ALL_USERROLEALLOWS = 'SAVE_ALL_USERROLEALLOWS';
export const SAVE_USERROLEALLOW_PAGINATION_DATA = 'SAVE_USERROLEALLOW_PAGINATION_DATA';
export const SAVE_USERROLEALLOW_SORT_ELEMENT = 'SAVE_USERROLEALLOW_SORT_ELEMENT';
export const SAVE_USERROLEALLOW_SORT_BY_DIRECTION = 'SAVE_USERROLEALLOW_SORT_BY_DIRECTION';
export const SAVE_USERROLEALLOW_CURRENT_PAGE = 'SAVE_USERROLEALLOW_CURRENT_PAGE';
