import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useDispatch } from "react-redux";
import { onLogout } from "../../store/actions/actions/root";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavigationMenu = props => {
  const { anchorEl, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("verificationCode");
    dispatch(onLogout());
    handleClose();
    props.history.push("/verification");
  };

  const handleProgramSettings = () => {
    props.history.push("/program-settings");
  };

  const handleProfile = () => {
    props.history.push("/profile");
  };

  const handleUserSettings = () => {
    props.history.push("/user-settings");
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MenuItem onClick={handleProfile}>{t("Profile")}</MenuItem>
      <MenuItem onClick={handleUserSettings}>{t("User Settings")}</MenuItem>
      <MenuItem onClick={handleProgramSettings}>
        {t("Program Settings")}
      </MenuItem>
      <MenuItem onClick={handleLogut}>{t("Logout")}</MenuItem>
    </Menu>
  );
};

export default withRouter(NavigationMenu);
