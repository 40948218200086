import React, { useEffect, useState } from 'react';
import withAdminDashboard from '../../hoc/withAdminDashboard/withAdminDashboard';
import withTitle from '../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import useStyle from './styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllTrippetTypeLooks,
    deleteTrippetTypeLook,
    saveCurrentPage,
} from '../../store/actions/actions/trippetTypeLook';
import Table from './Table';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import AdminPagination from '../../components/AdminPagination/AdminPagination';
import { ROWS_PER_PAGE } from '../../config';
import * as userAccess from '../../userAccess';
import TrippetTypeLookModal from '../../components/TrippetType/TrippetTypeLookForm';

const TrippetTypeLooks = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const allTrippetTypeLooks = useSelector(state => state.trippetTypeLook.allTrippetTypeLooks);
  const paginationData = useSelector(state => state.trippetTypeLook.paginationData);
  const currentPage = useSelector(state => state.trippetTypeLook.currentPage);
  const [deletedTrippetTypeLook, setDeletedTrippetTypeLook] = useState(null);
  const [isOpenConfirmDeleteModal, setisOpenConfirmDeleteModal] = useState(
    false,
  );

  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortedElement, setSortedElement] = useState('Name');
  const [sortedDir, setSortedDir] = useState('asc');
  const [isTrippetTypeLookModalOpen, setIsTrippetTypeLookModalOpen] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    const bodySearch = {
      Page: currentPage,
      RowsPerPage: ROWS_PER_PAGE,
      OrderBy: sortedElement,
      Language_ID: '2',
      OrderDir: sortedDir,
    };

    dispatch(getAllTrippetTypeLooks(JSON.stringify(bodySearch)));
  }, [sortedElement, sortedDir, currentPage, dispatch]);

  useEffect(() => {
    if (paginationData) {
      setTotalItemsCount(paginationData.Num);
    }
  }, [paginationData]);




  const handleSortDir = id => () => {
    setSortedDir(sortedElement === id && sortedDir === 'asc' ? 'desc' : 'asc');
    setSortedElement(id);
  };

  const onPaginationChange = activePage => {
    dispatch(saveCurrentPage(activePage));
  };

  const handleConfirmDeleteModalClose = () => {
    setisOpenConfirmDeleteModal(false);
  };

  const handleConfirmDeleteModalOpen = () => {
    setisOpenConfirmDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteTrippetTypeLook(JSON.stringify(deletedTrippetTypeLook)));
  };

  const handleTrippetTypeLookModalOpen = () => {
    setIsTrippetTypeLookModalOpen(true);
  };

  const handleTrippetTypeLookModalClose = () => {
    setIsTrippetTypeLookModalOpen(false);
  };

  const handleNewClick = () => {
    setDefaultValues(null);
    handleTrippetTypeLookModalOpen();
  };

  return userAccess.canRead(user, userAccess.Table_TrippetTypes) ? (
    <Grid container justify="center" className={classes.containerMyTrip}>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {t('Trippet Type Looks')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Fab
            variant="extended"
            className={classes.addTripButton}
            onClick={handleNewClick}
            disabled={!userAccess.canWrite(user, userAccess.Table_TrippetTypes)}
          >
            <AddIcon className={classes.extendedIcon} />
            {t('Type Look')}
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          data={allTrippetTypeLooks}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedTrippetTypeLook={setDeletedTrippetTypeLook}
          sortedElement={sortedElement}
          sortedBy={sortedDir}
          handleSortBy={handleSortDir}
        />
      </Grid>
      <AdminPagination
        activePage={currentPage}
        itemsPerPage={ROWS_PER_PAGE}
        totalItemsCount={totalItemsCount}
        onChange={onPaginationChange}
        title='trippet type looks'
      />
      <ConfirmDeleteModal
        open={isOpenConfirmDeleteModal}
        handleClose={handleConfirmDeleteModalClose}
        handleDelete={handleDelete}
      />
      <TrippetTypeLookModal
        open={isTrippetTypeLookModalOpen}
        handleTrippetTypeLookModalClose={handleTrippetTypeLookModalClose}
        defaultValues={defaultValues}
      />
    </Grid>
  )
  : <div>NO ACCESS</div>;
};

export default withTitle(withAdminDashboard(TrippetTypeLooks), 'Admin TrippetTypeLooks');
