import * as actionTypes from '../actionTypes/actionTypes';
import { BASE_URL } from '../../../config';
import { showSnackBar } from './root';


export const saveUserNotification = payload => ({
  type: actionTypes.SAVE_USER_NOTIFICATION,
  payload,
});

export const saveUserNotificationInterval = payload => ({
  type: actionTypes.SAVE_USER_NOTIFICATION_INTERVAL,
  payload,
});

export const saveUserNonSeenNotification = payload => ({
  type: actionTypes.SAVE_USER_NON_SEEN_NOTIFICATION,
  payload,
})

export const saveNotficationData = payload => ({
  type: actionTypes.SAVE_NOTIFICATION_DATA,
  payload,
})

export const saveSingleTrippetInfo = payload => ({
  type: actionTypes.SAVE_SINGLE_TRIPPET_INFO,
  payload,
})

export const saveSingleTripInfo = payload => ({
  type: actionTypes.SAVE_SINGLE_TRIP_INFO,
  payload,
})

export const getUserNotification = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/log/user/search.php`, {
      method: 'POST',
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveUserNotification(data.Data));
        dispatch(saveNotficationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteNotification = ({body, User_ID}) => {
  return dispatch => {
    fetch(`${BASE_URL}api/log/user/delete.php`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong',
            }),
          );
        }
      })
      .then(() => {
        let body = {
          User_ID: User_ID,
          OrderBy: "LogDate",
          OrderDir: 'desc',
        };
        dispatch(getUserNotification(JSON.stringify(body)))
      })
      .catch(error => console.log(error));
  };
};

export const deleteMultipleNotification = ({body, User_ID}) => {
  return dispatch => {
    fetch(`${BASE_URL}api/log/user/multidelete.php`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong',
            }),
          );
        }
      })
      .then(() => {
        let body = {
          User_ID: User_ID,
          OrderBy: "LogDate",
          OrderDir: 'desc',
        };
        dispatch(getUserNotification(JSON.stringify(body)))
      })
      .catch(error => console.log(error));
  };
};

export const updateNotification = ({body, User_ID}) => {
  return dispatch => {
    fetch(`${BASE_URL}api/log/user/update.php`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong',
            }),
          );
        }
      })
      .then(() => {
        let body = {
          User_ID: User_ID,
          OrderBy: "LogDate",
          OrderDir: 'desc',
        };
        dispatch(getUserNotification(JSON.stringify(body)))
      })
      .catch(error => console.log(error));
  };
};

export const getTripNotificationList = (body) => {
  return dispatch => {
    fetch(`${BASE_URL}api/log/user/filter/trips.php`, {
      method: 'POST',
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong',
            }),
          );
        }
      })
      .then((response) => {
        const data = JSON.parse(response);
        dispatch(saveTripListNotification(data));
      })
      .catch(error => console.log(error));
  };
};

export const getTrippetNotificationList = (body) => {
  return dispatch => {
    fetch(`${BASE_URL}api/log/user/filter/trippets.php`, {
      method: 'POST',
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong',
            }),
          );
        }
      })
      .then((response) => {
        const data = JSON.parse(response);
        dispatch(saveTrippetListNotification(data));
      })
      .catch(error => console.log(error));
  };
};

export const getCreatorNotificationList = (body) => {
  return dispatch => {
    fetch(`${BASE_URL}api/log/user/filter/logusers.php`, {
      method: 'POST',
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong',
            }),
          );
        }
      })
      .then((response) => {
        const data = JSON.parse(response);
        dispatch(saveCreatorListNotification(data));
      })
      .catch(error => console.log(error));
  };
};

export const saveNotificationFilters = payload => ({
  type: actionTypes.SAVE_NOTIFICATION_FILTERS,
  payload
})

export const saveTripListNotification = payload => ({
  type: actionTypes.SAVE_TRIP_LIST_NOTIFICATION,
  payload
})

export const saveTrippetListNotification = payload => ({
  type: actionTypes.SAVE_TRIPPET_LIST_NOTIFICATION,
  payload
})

export const saveCreatorListNotification = payload => ({
  type: actionTypes.SAVE_CREATOR_LIST_NOTIFICATION,
  payload
})

export const getSingleTrippetInfo = id => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/getfull.php?id=${id}`, {
      method: 'POST',
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveSingleTrippetInfo(data));
      })
      .catch(error => console.log(error));
  };
};

export const getSingleTripInfo = id => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/getfull.php?id=${id}`, {
      method: 'POST',
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveSingleTripInfo(data));
      })
      .catch(error => console.log(error));
  };
};
