import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import ForceDeleteModal from '../ForceDeleteModal/ForceDeleteModal';
import InfoIcon from '@material-ui/icons/Info';

const OwnerConfirmDeleteModal = ({
  open,
  handleClose,
  handleForceDelete,
  trip,
  handleDeleteParticipants,
  userTripRole,
  handleDeleteAsOwner,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const allMyTrippets = useSelector(state => state.trippet.allMyTrippets);
  const { t } = useTranslation();
  const [tripTrippets, setTripTrippets] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isForceDeleteModalOpen, setIsForceDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (trip) {
      if (trip.HasTrippet === '0') {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [trip]);

  useEffect(() => {
    if (trip && allMyTrippets) {
      let allTrippets = [];
      if (allMyTrippets && allMyTrippets.length > 0) {
        allMyTrippets.forEach(trippet => {
          if (trippet.Trip_ID === trip.Trip_ID) {
            allTrippets.push(trippet);
          }
        });
      }
      setTripTrippets(allTrippets);
    }
  }, [trip, allMyTrippets]);

  const handleDeleteButton = event => {
    event.stopPropagation();
    if (userTripRole === 'owner') {
      handleDeleteAsOwner();
      handleClose();
    } else {
      handleDeleteParticipants();
      handleClose();
    }
  };

  const handleForceDeleteButton = event => {
    event.stopPropagation();
    handleForceDeleteModalOpen();
  };

  const handleForceDeleteModalClose = () => {
    setIsForceDeleteModalOpen(false);
    handleClose();
  };

  const handleForceDeleteModalOpen = () => {
    setIsForceDeleteModalOpen(true);
  };

  const iconStyles = {
    position: 'absolute',
    fontSize: 15,
    top: -20,
    right: 0,
    cursor: 'help',
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText style={{ marginBottom: 30 }}>
            {t('Are you sure you want to delete?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
          <div style={{ position: 'relative' }}>
            <Tooltip
              title={'The button is disabled when the trip contains trippets'}
              placement="top"
              arrow
            >
              <InfoIcon style={iconStyles} />
            </Tooltip>
            <Button
              onClick={handleDeleteButton}
              color="primary"
              variant="contained"
              disabled={isDisabled}
            >
              {t('Delete')}
            </Button>
          </div>
          <div style={{ position: 'relative' }}>
            <Tooltip
              title={'With the force delete you can keep trippets'}
              placement="top"
              arrow
            >
              <InfoIcon style={iconStyles} />
            </Tooltip>
            <Button
              onClick={handleForceDeleteButton}
              color="primary"
              variant="contained"
            >
              {t('Force Delete')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <ForceDeleteModal
        open={isForceDeleteModalOpen}
        handleClose={handleForceDeleteModalClose}
        trip={trip}
        allMyTrippets={tripTrippets}
        handleForceDelete={handleForceDelete}
      />
    </div>
  );
};

export default OwnerConfirmDeleteModal;
