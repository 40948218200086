import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { hideSnackbar } from '../store/actions/actions/root';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBar = () => {
  const open = useSelector(state => state.app.isSnackBarOpen);
  const type = useSelector(state => state.app.snackBarType);
  const message = useSelector(state => state.app.snackBarMessage);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideSnackbar());
  };
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity={type || 'error'}>
          {message || 'Something went wrong!'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBar;
