export const updateTrippetOrganizers = (
  action,
  trippet,
  user,
  dispatch,
  updateOrganizersStatus,
) => {
  if (action === "accept") {
    if (trippet) {
      if (
        trippet &&
        trippet.TrippetOrganizers &&
        trippet.TrippetOrganizers.length > 0
      ) {
        trippet.TrippetOrganizers.forEach(participant => {
          if (participant.TrippetOrganizerUser_ID === user.User_ID) {
            let body = {
              TrippetOrganizerUser_ID: participant.TrippetOrganizerUser_ID,
              TrippetOrganizerUserRole_ID:
                participant.TrippetOrganizerUserRole_ID,
              TrippetOrganizerAcceptanceStatus: 2,
              TrippetOrganizer_ID: participant.TrippetOrganizer_ID,
            };
            dispatch(
              updateOrganizersStatus(
                JSON.stringify(body),
                user.User_ID,
                null,
                null,
                null,
                trippet.CreatorUser_ID,
              ),
            );
          }
        });
      }
    }
  }
  if (action === "decline") {
    if (
      trippet &&
      trippet.TrippetOrganizers &&
      trippet.TrippetOrganizers.length > 0
    ) {
      trippet.TrippetOrganizers.forEach(participant => {
        if (participant.TrippetOrganizerUser_ID === user.User_ID) {
          let body = {
            TrippetOrganizerUser_ID: participant.TrippetOrganizerUser_ID,
            TrippetOrganizerUserRole_ID:
              participant.TrippetOrganizerUserRole_ID,
            TrippetOrganizerAcceptanceStatus: 3,
            TrippetOrganizer_ID: participant.TrippetOrganizer_ID,
          };
          dispatch(
            updateOrganizersStatus(
              JSON.stringify(body),
              user.User_ID,
              null,
              null,
              null,
              trippet.CreatorUser_ID,
            ),
          );
        }
      });
    }
  }
};

export const updateTrippetParticipants = (
  action,
  trippet,
  user,
  dispatch,
  updateParticipantStatus,
) => {
  if (action === "accept") {
    if (trippet) {
      if (
        trippet.TrippetParticipants &&
        trippet.TrippetParticipants.length > 0
      ) {
        trippet.TrippetParticipants.forEach(participant => {
          if (participant.TrippetParticipantUser_ID === user.User_ID) {
            let body = {
              TrippetParticipant_ID: participant.TrippetParticipant_ID,
              TrippetParticipantUserRole_ID:
                participant.TrippetParticipantUserRole_ID,
              ParticipantStatus_ID: 2,
            };
            dispatch(
              updateParticipantStatus(
                JSON.stringify(body),
                user.User_ID,
                null,
                null,
                null,
                trippet.CreatorUser_ID,
              ),
            );
          }
        });
      }
    }
  } else if (action === "decline") {
    if (trippet) {
      if (
        trippet.TrippetParticipants &&
        trippet.TrippetParticipants.length > 0
      ) {
        trippet.TrippetParticipants.forEach(participant => {
          if (participant.TrippetParticipantUser_ID === user.User_ID) {
            let body = {
              TrippetParticipant_ID: participant.TrippetParticipant_ID,
              TrippetParticipantUserRole_ID:
                participant.TrippetParticipantUserRole_ID,
              ParticipantStatus_ID: 3,
            };
            dispatch(
              updateParticipantStatus(
                JSON.stringify(body),
                user.User_ID,
                null,
                null,
                null,
                trippet.CreatorUser_ID,
              ),
            );
          }
        });
      }
    }
  }
};

export const updateNotificatoinTripParticipants = (
  action,
  data,
  user,
  dispatch,
  updateTripParticipantStatus,
) => {
  if (action === "accept") {
    data.TripParticipants.forEach(participant => {
      if (participant.TripParticipantUser_ID === user.User_ID) {
        let body = {
          TripParticipant_ID: participant.TripParticipant_ID,
          TripParticipantUserRole_ID: participant.TripParticipantUserRole_ID,
          ParticipantStatus_ID: 2,
        };
        dispatch(
          updateTripParticipantStatus(
            JSON.stringify(body),
            user.User_ID,
            null,
            null,
            null,
          ),
        );
      }
    });
  } else if (action === "decline") {
    data.TripParticipants.forEach(participant => {
      if (participant.TripParticipantUser_ID === user.User_ID) {
        let body = {
          TripParticipant_ID: participant.TripParticipant_ID,
          TripParticipantUserRole_ID: participant.TripParticipantUserRole_ID,
          ParticipantStatus_ID: 3,
        };
        dispatch(
          updateTripParticipantStatus(
            JSON.stringify(body),
            user.User_ID,
            null,
            null,
            null,
          ),
        );
      }
    });
  }
};

export const updateNotificationTripProxies = (
  action,
  data,
  user,
  dispatch,
  updateTripProxyStatus,
) => {
  if (action === "accept") {
    data.TripProxies.forEach(proxy => {
      if (proxy.TripProxyUser_ID === user.User_ID) {
        let body = {
          TripProxy_ID: proxy.TripProxy_ID,
          TripProxyUserRole_ID: proxy.TripProxyUserRole_ID,
          TripProxyAcceptanceStatus: 2,
          TripProxyEnableStatus: proxy.TripProxyEnableStatus,
        };
        dispatch(
          updateTripProxyStatus(
            JSON.stringify(body),
            user.User_ID,
            null,
            null,
            null,
          ),
        );
      }
    });
  } else if (action === "decline") {
    data.TripProxies.forEach(proxy => {
      if (proxy.TripProxyUser_ID === user.User_ID) {
        let body = {
          TripProxy_ID: proxy.TripProxy_ID,
          TripProxyUserRole_ID: proxy.TripProxyUserRole_ID,
          TripProxyAcceptanceStatus: 3,
          TripProxyEnableStatus: proxy.TripProxyEnableStatus,
        };
        dispatch(
          updateTripProxyStatus(
            JSON.stringify(body),
            user.User_ID,
            null,
            null,
            null,
          ),
        );
      }
    });
  }
};

export const updateNotificationTripOrganizer = (
  action,
  data,
  user,
  dispatch,
  updateTripOrganizerStatus,
) => {
  if (action === "accept") {
    data.TripOrganizers.forEach(organizer => {
      if (organizer.TripOrganizerUser_ID === user.User_ID) {
        let body = {
          TripOrganizer_ID: organizer.TripOrganizer_ID,
          TripOrganizerUserRole_ID: organizer.TripOrganizerUserRole_ID,
          TripOrganizerAcceptanceStatus: 2,
          TripOrganizerEnableStatus: organizer.TripOrganizerEnableStatus,
        };
        dispatch(
          updateTripOrganizerStatus(
            JSON.stringify(body),
            user.User_ID,
            null,
            null,
            null,
          ),
        );
      }
    });
  } else if (action === "decline") {
    data.TripOrganizers.forEach(organizer => {
      if (organizer.TripOrganizerUser_ID === user.User_ID) {
        if (organizer.TripOrganizerUser_ID === user.User_ID) {
          let body = {
            TripOrganizer_ID: organizer.TripOrganizer_ID,
            TripOrganizerUserRole_ID: organizer.TripOrganizerUserRole_ID,
            TripOrganizerAcceptanceStatus: 3,
            TripOrganizerEnableStatus: organizer.TripOrganizerEnableStatus,
          };
          dispatch(
            updateTripOrganizerStatus(
              JSON.stringify(body),
              user.User_ID,
              null,
              null,
              null,
            ),
          );
        }
      }
    });
  }
};
