import React, { useState, useEffect, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import {
  createNewTrippetTypeLook,
  changeTrippetTypeLook,
} from "../../store/actions/actions/trippetTypeLook";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import FormHelperText from "@material-ui/core/FormHelperText";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { getEntireLanguages } from "../../store/actions/actions/language";
import { getAllTrippetTypeCategories } from "../../store/actions/actions/trippetTypeCategory";
import PreviewIcon from "../PreviewIcon/PreviewIcon";
import IconPicker from '../IconPicker/IconPicker';
import { SketchPicker } from "react-color";
import colorStyles from "../ColorPicker/styles";
import * as userAccess from "../../userAccess";

const TrippetTypeLookModal = ({
  open,
  handleTrippetTypeLookModalClose,
  defaultValues,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [namesError, setNamesError] = useState(false);
  const [formError, setFormError] = useState(false);
  const entireLanguages = useSelector(state => state.language.entireLanguages);
  const allTrippetTypeCategories = useSelector(
    state => state.trippetTypeCategory.allTrippetTypeCategories,
  );
  const currentTrippetTypeLook =  null;//useSelector(state => state.trippetTypeLook.currentTrippetTypeLook);
  const [values, setValues] = useState({
    TrippetTypeCategory_ID: -1,
    MaterialIO: "",
    Comments: "",
    Color: "",
    Icon: "",
    SortIndex: 0,
    Names: [],
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure languages is already loaded
    if (!entireLanguages) {
      dispatch(getEntireLanguages(null));
      return;
    }
    if (!allTrippetTypeCategories) {
      dispatch(getAllTrippetTypeCategories(null));
      return;
    }
    if (defaultValues) {
      // when there is a new language, the name for that language in 'Names' is missing
      // so we need to iterate through entireLanguages and push missed ones
      const names = currentTrippetTypeLook
        ? currentTrippetTypeLook.Names
        : defaultValues.Names ? defaultValues.Names : [];
      if (names && Array.isArray(names))
        entireLanguages.forEach(name => {
          var found = false;
          names.forEach(name2 => {
            if (Number(name2.Language_ID) === Number(name.Language_ID))
              found = true;
          });
          if (!found)
            names.push({
              LanguageAbbreviation: name.Abbreviation,
              Language_ID: name.Language_ID,
              Name: "",
            });
        });
      if (currentTrippetTypeLook) {
        setValues({
          TrippetTypeLook_ID: currentTrippetTypeLook.TrippetTypeLook_ID,
          TrippetTypeCategory_ID: currentTrippetTypeLook.TrippetTypeCategory_ID,
          MaterialIO: currentTrippetTypeLook.MaterialIO,
          Comments: currentTrippetTypeLook.Comments,
          Color: currentTrippetTypeLook.Color,
          Icon: currentTrippetTypeLook.Icon,
          SortIndex: currentTrippetTypeLook.SortIndex,
          Names: names,
        });
      } else {
        setValues({
          TrippetTypeLook_ID: defaultValues.TrippetTypeLook_ID,
          TrippetTypeCategory_ID: defaultValues.TrippetTypeCategory_ID,
          MaterialIO: defaultValues.MaterialIO,
          Comments: defaultValues.Comments,
          Color: defaultValues.Color,
          Icon: defaultValues.Icon,
          SortIndex: defaultValues.SortIndex,
          Names: names,
        });
      }

      handleIsEditMode(defaultValues.TrippetTypeLook_ID && defaultValues.TrippetTypeLook_ID > 0);
    } else {
      const names = [];
      entireLanguages.forEach(name => {
        names.push({
          LanguageAbbreviation: name.Abbreviation,
          Language_ID: name.Language_ID,
          Name: "",
        });
      });
      setValues({
        TrippetTypeLook_ID: 0,
        TrippetTypeCategory_ID: -1,
        MaterialIO: "",
        Comments: "",
        Color: "",
        Icon: "",
        SortIndex: 0,
        Names: names,
      });
      handleIsEditMode(false);
    }
  }, [
    entireLanguages,
    allTrippetTypeCategories,
    defaultValues,
    currentTrippetTypeLook,
    dispatch,
  ]);

  const validateForm = ({ Names }) => {
    if (Names && Names.length > 0) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };

  const handleSubmit = e => {
    e.stopPropagation();
    if (!namesError) {
      e.preventDefault();
      const data = {
        TrippetTypeLook_ID: +values.TrippetTypeLook_ID,
        TrippetTypeCategory_ID: values.TrippetTypeCategory_ID,
        MaterialIO: values.MaterialIO,
        Comments: values.Comments,
        Color: values.Color,
        Icon: values.Icon,
        SortIndex: values.SortIndex,
        Names: values.Names,
      };
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changeTrippetTypeLook(JSON.stringify(data)));
          handleTrippetTypeLookModalClose();
        }
      } else {
        if (validateForm(data)) {
          dispatch(createNewTrippetTypeLook(JSON.stringify(data)));
          handleTrippetTypeLookModalClose();
        }
      }
    }
  };

  const clearIcon = () => {
    setValues({ ...values, Icon: "" });
  };

  const setIcon = file => {
    setValues({ ...values, Icon: file });
  };
  const setMaterialIO = icon => {
    setValues({...values, MaterialIO: icon});
  };
  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name.indexOf("Name") === 0) {
      // it is Names list
      const names = [];
      values.Names.forEach(name => {
        if ("Name" + name.Language_ID === e.target.name)
          names.push({
            LanguageAbbreviation: name.LanguageAbbreviation,
            Language_ID: name.Language_ID,
            Name: e.target.value,
          });
        else
          names.push({
            LanguageAbbreviation: name.LanguageAbbreviation,
            Language_ID: name.Language_ID,
            Name: name.Name,
          });
      });
      setValues({ ...values, Names: names });
      setNamesError(names.length === 0);
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = color => {
    setValues({ ...values, Color: color.hex.substring(1) });
  };

  const colorChooserStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "2px",
    background: `#${values.Color}`,
  };

  const renderTitle = defaultValues
    ? t("Edit Trippet Type Look")
    : t("New Trippet Type Look");
  const renderNames =
    values.Names && values.Names.length > 0
      ? values.Names.map(name => {
          return (
            <TableRow key={name.Language_ID} className={classes.rowContainer}>
              <TableCell>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id={"Name" + name.Language_ID}
                  label={name.LanguageAbbreviation}
                  name={"Name" + name.Language_ID}
                  value={name.Name}
                  onChange={handleInputs}
                />
              </TableCell>
            </TableRow>
          );
        })
      : null;

  const renderCategories =
    allTrippetTypeCategories && allTrippetTypeCategories.length > 0
      ? allTrippetTypeCategories.map(cat => {
          return (
            <MenuItem
              key={cat.TrippetTypeCategory_ID}
              value={cat.TrippetTypeCategory_ID}
            >
              {cat.Name}
            </MenuItem>
          );
        })
      : null;

  const renderCategoryError = namesError ? t("Caregory is required.") : null;

  const renderFormError = formError
    ? t("All fields with * are required.")
    : null;

  const user = useSelector(state => state.user.user);
  const disableSave =
    (isEditMode &&
      !userAccess.canChange(user, userAccess.Table_TrippetTypes)) ||
    (!isEditMode && !userAccess.canWrite(user, userAccess.Table_TrippetTypes));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.trippetTypeLookName}>
                <Grid container>
                  <Grid item xs={12} className={classes.trippetTypeLookName}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectView}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        {t("Type")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t("Category")}
                        autoFocus
                        name="TrippetTypeCategory_ID"
                        value={values.TrippetTypeCategory_ID}
                        onChange={handleInputs}
                      >
                        {renderCategories}
                      </Select>
                    </FormControl>
                    <FormHelperText className={classes.errorText}>
                      {renderCategoryError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} className={classes.languageName}>
                    <Grid container>
                      <Grid item xs={10} className={classes.languageName}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          margin="normal"
                          id="Color"
                          label={t("Color")}
                          name="Color"
                          autoComplete="Color"
                          autoFocus
                          value={values.Color}
                          onChange={handleInputs}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <div
                          style={colorStyles.swatch}
                          onClick={handleColorClick}
                        >
                          <div style={colorChooserStyle} />
                        </div>
                        {displayColorPicker ? (
                          <div style={colorStyles.popover}>
                            <div
                              style={colorStyles.cover}
                              onClick={handleColorClose}
                            />
                            <SketchPicker
                              color={values.Color}
                              onChange={handleColorChange}
                            />
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid><Grid item xs={12} className={classes.languageName}>
                    <Grid container>
                      <Grid item xs={10} className={classes.languageName}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          id="MaterialIO"
                          label={t('MaterialIO')}
                          name="MaterialIO"
                          autoComplete="MaterialIO"
                          value={values.MaterialIO}
                          onChange={handleInputs}
                        />
                        <FormHelperText>
                          <a
                            className={classes.link}
                            href="https://material.io/resources/icons/?icon=account_circle&style=baseline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("Here you can find more icons.")}
                          </a>
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={2}>
                        <IconPicker selectedIcon={values.MaterialIO} iconSelected={setMaterialIO} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.languageName}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="MaterialIO"
                      label={t("MaterialIO")}
                      name="MaterialIO"
                      autoComplete="MaterialIO"
                      value={values.MaterialIO}
                      onChange={handleInputs}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <PreviewIcon
                  currentImage={values.Icon}
                  pictureUrl={values.Icon}
                  clearIcon={clearIcon}
                  setIcon={setIcon}
                  type="language"
                  dispatch={dispatch}
                  t={t}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.languageName}>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                  id="SortIndex"
                  label={t("SortIndex")}
                  name="SortIndex"
                  autoComplete="SortIndex"
                  value={values.SortIndex}
                  onChange={handleInputs}
                />
              </Grid>
              <Grid item xs={12} md={9} className={classes.planningStatusName}>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  id="Comments"
                  label={t("Meaning (please describe briefly)")}
                  name="Comments"
                  autoComplete="Comments"
                  value={values.Comments}
                  onChange={handleInputs}
                />
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
            </Grid>
            <Grid container>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  className={classes.title}
                >
                  {t("Languages")}
                </Typography>
              </Grid>
            </Grid>
            <TableContainer className={classes.container}>
              <Table>
                <TableBody>{renderNames}</TableBody>
              </Table>
            </TableContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t("Exit")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleTrippetTypeLookModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default TrippetTypeLookModal;
