import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import {store} from '../../store';

export const saveCurrentDepartment = payload => ({
  type: adminActionTypes.SAVE_CURRENT_DEPARTMENT,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_DEPARTMENT_CURRENT_PAGE,
  payload,
});

export const getDepartmentById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/department/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentDepartment(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewDepartment = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/department/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503 && response.type !== 'cors') {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentDepartment(data));
          dispatch(getAllDepartments());
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeDepartment = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/department/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getDepartmentById(body.Department_ID));
          dispatch(getAllDepartments(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllDepartments = payload => ({
  type: adminActionTypes.SAVE_ALL_DEPARTMENTS,
  payload,
});

export const saveEntireDepartments = payload => ({
  type: adminActionTypes.SAVE_ENTIRE_DEPARTMENTS,
  payload,
});

export const saveDepartmentSortElement = payload => ({
  type: adminActionTypes.SAVE_DEPARTMENT_SORT_ELEMENT,
  payload,
});

export const saveDepartmentSortByDirection = payload => ({
  type: adminActionTypes.SAVE_DEPARTMENT_SORT_BY_DIRECTION,
  payload,
});

export const saveDepartmentPaginationData = payload => ({
  type: adminActionTypes.SAVE_DEPARTMENT_PAGINATION_DATA,
  payload,
});

export const getAllDepartments = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: ROWS_PER_PAGE,
            Page: store.getState().department.currentPage,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/department/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllDepartments(data.Data));
        dispatch(saveDepartmentPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const getEntireDepartments = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: 100000,
            Page: 1,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/department/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveEntireDepartments(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteDepartment = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/department/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else 
            dispatch(getAllDepartments(null));
        })
        .catch(error => console.log(error));
    }
  };
};
