import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { columns } from './columns';
import useStyles from '../../styles';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import TrippetButton from '../../TrippetButton';
import uniqid from 'uniqid';
import Avatar from '@material-ui/core/Avatar';
import TrippetInvitationButton from '../../TrippetInvitationButton';
import TrippetRecommendationButton from '../../TrippetRecommendationButton';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import { getTrippetTypeIcon } from '../../helper';
import TrippetViewMode from "../../../../components/TrippetForm/TrippetViewMode";

const createData = (
  type,
  title,
  startDate,
  startTime,
  endDate,
  endTime,
  trippetOwner,
  day,
  price,
  color,
  fullTrippet,
) => {
  return {
    type,
    title,
    startDate,
    startTime,
    endDate,
    endTime,
    trippetOwner,
    day,
    price,
    color,
    fullTrippet,
  };
};

const TrippetDataTableTwo = ({
  trippets,
  handleTrippetFormOpen,
  isOpenTrippetFrom,
  type,
  handleConfirmDeleteModalOpen,
  setDeletedTrippet,
  findTheTrippetPictureId,
  findPublicTrippetDocument,
  findPrivateTrippetDocument,
  allUsers,
  sortedElement,
  sortedBy,
  handleSortBy,
  handleOpenFilterModal,
  disabledFilters,
  activeFilters,
  user,
  filterResultsMyTrippets,
  sortedElementMyTrippets,
  sortedByMyTrippets,
  invitationType,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dateLanguage, setLanguage] = useState('');
  const language = useSelector(state => state.programSettings.language);
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const { t } = useTranslation();
  const [isOpenTrippetViewMode, setIsOpenTrippetViewMode] = useState(false);
  const [clickedElement, setClickedElement] = useState(null);

  useEffect(() => {
    if (language === 'en') {
      setLanguage('');
    } else if (language === 'de') {
      setLanguage(deLocale);
    }
  }, [language]);

  const getTrippetTypeColor = data => {
    if (trippetTypes) {
      let element = trippetTypes.find(type => data === type.TrippetTypeLook_ID);
      return element.Color;
    }
  };

  const renderDays = (start, end) => {
    if (start && end) {
      return `${start} to ${end}`;
    } else {
      return '';
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (trippets && trippets.length) {
      trippets.forEach(trippet => {
        elements.push(
          createData(
            getTrippetTypeIcon(trippet.TrippetTypeLook_ID, trippetTypes, classes) || "",
            trippet.Title,
            format(new Date(trippet.StartDate), 'EEEEEE.dd MMM', {
              locale: dateLanguage,
            }),
            format(new Date(trippet.StartDate), 'HH:mm'),
            format(new Date(trippet.EndDate), 'EEEEEE.dd MMM', {
              locale: dateLanguage,
            }),
            format(new Date(trippet.EndDate), 'HH:mm'),
            trippet.OwnerUser_ID,
            renderDays(trippet.StartDay, trippet.EndDay),
            trippet.Price,
            getTrippetTypeColor(trippet.TrippetTypeLook_ID),
            trippet,
          ),
        );
      });
      setRows(elements);
    }
  };

  const findOwner = value => allUsers.find(user => user.User_ID === value);

  useEffect(() => {
    if (trippets && trippets.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trippets, trippetTypes]);

  const renderData = (column, value, row) => {
    if (column.id === 'actions') {
      if (type === 'my-trippets') {
        return (
          <TrippetButton
            data={row}
            isOpenTrippetFrom={isOpenTrippetFrom}
            allUsers={allUsers}
            handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
            setDeletedTrippet={setDeletedTrippet}
          />
        );
      } else if (type === 'invitations') {
        return (
          <TrippetInvitationButton
            data={row}
            isOpenTrippetFrom={isOpenTrippetFrom}
            filterResultsMyTrippets={filterResultsMyTrippets}
            sortedElementMyTrippets={sortedElementMyTrippets}
            sortedByMyTrippets={sortedByMyTrippets}
          />
        );
      } else if (type === 'recommendations') {
        return (
          <TrippetRecommendationButton
            data={row}
            isOpenTrippetFrom={isOpenTrippetFrom}
            user={user}
          />
        );
      }
    } else if (column.id === 'trippetOwner') {
      let user = findOwner(value);
      if (user) {
        if (user.Picture) {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>
                <img
                  src={user.Picture}
                  alt={user.Initials}
                  className={classes.avatarImage}
                />
              </Avatar>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>{`${user.Initials}`}</Avatar>
            </Tooltip>
          );
        }
      }
    } else {
      return value;
    }
  };

  const handleOpenTrippetViewMode = () => {
    setIsOpenTrippetViewMode(true);
  };

  const handleCloseTrippetViewMode = () => {
    setIsOpenTrippetViewMode(false);
    setClickedElement(null);
  };

  const handleRowClick = rowData => () => {
    handleOpenTrippetViewMode();
    setClickedElement(rowData);
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.fullTrippet.Trippet_ID}
              onClick={handleRowClick(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0 ? `5px solid #${row.color}` : classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={classes.tableCell}
                    style={{ borderLeft: styleName }}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                const renederActiveSortingArrow = sortedElement === column.type ? classes.tableHeaderCellActive : classes.tableHeaderCell;

                if (column.id !== 'actions') {
                  if (column.filter) {
                    let activeColor = activeFilters[column.filter]
                      ? classes.activeFilter
                      : '';
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : 'asc'
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                        <IconButton
                          onClick={handleOpenFilterModal(column)}
                          disabled={disabledFilters[column.filter]}
                          className={activeColor}
                        >
                          <FilterListIcon />
                        </IconButton>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : 'asc'
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                      </TableCell>
                    );
                  }
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      {isOpenTrippetViewMode && (
        <TrippetViewMode
          defaultValues={clickedElement}
          open={isOpenTrippetViewMode}
          handleClose={handleCloseTrippetViewMode}
          filterResults={filterResultsMyTrippets}
          sortedElementMyTrippets={sortedElementMyTrippets}
          sortedByMyTrippets={sortedByMyTrippets}
          type={invitationType}
        />
      )}
    </Paper>
  );
};

export default TrippetDataTableTwo;
