export const columns = [
    { id: 'actions', label: '', minWidth: 70, type: 'actions' },
    { id: 'TripName', label: 'Trip', minWidth: 40, type: 'Name', filter: 'tripnames', },
    { id: 'SortIndex', label: 'Sort#', minWidth: 40, type: 'Name' },
    { id: 'Name', label: 'Name', minWidth: 80, type: 'Name' },
    {
      id: 'Color',
      label: 'Color',
      minWidth: 40,
      align: 'center',
      type: 'Name',
    },
    {
      id: 'Type',
      label: 'Type',
      minWidth: 80,
      align: 'center',
      type: 'Name',
    },
    { id: 'Comments', label: 'Comments', minWidth: 80, type: 'Comments' },
    { id: 'IsFinal', label: 'IsFinal', minWidth: 80, type: 'IsFinal' },
  ];
  