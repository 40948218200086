import * as actionTypes from "../actionTypes/actionTypes";
import { BASE_URL } from "../../../config";
import { showSnackBar } from "./root";

export const uploadFile = (body, type, name, t) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/upload.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 501) {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("Something went wrong"),
              }),
            );
          }
        })
        .then(response => {
          // const data = JSON.parse(response);
          // if (type === 'image') {
          //   const body = {
          //     type: 'image',
          //     file: data.File,
          //     File: data.File,
          //     Title: name,
          //   };
          //   dispatch(saveUploadedFile(body));
          // } else if (type === 'publicDocument') {
          //   // const body = {
          //   //   type: 'publicDocument',
          //   //   file: data.File,
          //   //   File: data.File,
          //   //   name: name,
          //   // };
          //   // dispatch(saveUploadedFile(body));
          // } else if (type === 'personalDocument') {
          //   const body = {
          //     type: 'personalDocument',
          //     file: data.File,
          //     File: data.File,
          //     name: name,
          //   };
          //   dispatch(saveUploadedFile(body));
          // }
        })
        .catch(error => console.log(error));
    }
  };
};

export const getAllTrippets = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/search.php`, {
      method: "POST",
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllMyTrippets(data.Data));
        dispatch(saveMyTrippetsPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const saveParentTrippetWithChildren = payload => ({
  type: actionTypes.SAVE_PARENT_TRIPPET_WITH_CHILDREN,
  payload,
});

export const saveParentTrippetChildren = payload => ({
  type: actionTypes.SAVE_PARENT_TRIPPET_CHILDREN,
  payload,
});

export const clearParentTrippetChildren = payload => ({
  type: actionTypes.CLEAR_PARENT_TRIPPET_CHILDREN,
  payload,
});

export const getAllParentWithChildrensTrippets = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/search.php`, {
      method: "POST",
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveParentTrippetWithChildren(data));
      })
      .catch(error => console.log(error));
  };
};

export const saveReorderedChildrenTrippets = (body, t) => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/setorder.php`, {
      method: "POST",
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: t("Something went wrong"),
            }),
          );
        }
      })
      .then(() => {
        dispatch(
          showSnackBar({
            type: "success",
            message: "Successfully saved trippets order",
          }),
        );
      })
      .catch(error => console.log(error));
  };
};

export const getAllParentTrippets = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/search.php`, {
      method: "POST",
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveParentTrippetChildren(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const updateParticipantStatus = (
  body,
  userId,
  sortElement,
  sortDirection,
  filterResults,
  creator,
) => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/updateparticipant.php`, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong!",
            }),
          );
        }
      })
      .then(() => {
        let getAllTrippetsBody;
        if (creator === userId) {
          getAllTrippetsBody = {
            Language_ID: 2,
            Page: 1,
            Type: 2,
            RowsPerPage: 10,
            OrderBy: sortElement,
            OrderDir: sortDirection,
            User_ID: userId,
          };
        } else {
          getAllTrippetsBody = {
            Language_ID: 2,
            Page: 1,
            Type: 2,
            RowsPerPage: 10,
            OrderBy: sortElement,
            OrderDir: sortDirection,
            User_ID: userId,
          };
        }
        if (filterResults) {
          filterResults.forEach(filter => {
            getAllTrippetsBody[filter.name] = filter.body;
          });
        }
        dispatch(getAllTrippets(JSON.stringify(getAllTrippetsBody)));

        const receivedInvitation = {
          Language_ID: 2,
          Type: 3,
          User_ID: userId,
          OrderBy: "ModifiedDate",
          OrderDir: "desc",
        };
        dispatch(
          getMyTrippetReceivedInvitation(JSON.stringify(receivedInvitation)),
        );
      })
      .catch(error => console.log(error));
  };
};

export const updateOrganizersStatus = (
  body,
  userId,
  sortElement,
  sortDirection,
  filterResults,
  creator,
) => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/updateorganizer.php`, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong!",
            }),
          );
        }
      })
      .then(() => {
        let getAllTrippetsBody;
        if (creator === userId) {
          getAllTrippetsBody = {
            Language_ID: 2,
            Page: 1,
            Type: 2,
            RowsPerPage: 10,
            OrderBy: sortElement,
            OrderDir: sortDirection,
            User_ID: userId,
          };
        } else {
          getAllTrippetsBody = {
            Language_ID: 2,
            Page: 1,
            Type: 2,
            RowsPerPage: 10,
            OrderBy: sortElement,
            OrderDir: sortDirection,
            User_ID: userId,
            IsPrivate: 0,
          };
        }
        if (filterResults) {
          filterResults.forEach(filter => {
            getAllTrippetsBody[filter.name] = filter.body;
          });
        }
        dispatch(getAllTrippets(JSON.stringify(getAllTrippetsBody)));

        const receivedInvitation = {
          Language_ID: 2,
          Type: 3,
          User_ID: userId,
          OrderBy: "ModifiedDate",
          OrderDir: "desc",
        };
        dispatch(
          getMyTrippetReceivedInvitation(JSON.stringify(receivedInvitation)),
        );
      })
      .catch(error => console.log(error));
  };
};

export const createTrippet = (
  body,
  trippetImages,
  trippetPublicDocuments,
  trippetPrivateDocuments,
  tripId,
  userId,
  Notes,
  sortElement,
  sortDirection,
  CreatorUser_ID,
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/create.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (trippetImages && trippetImages.length > 0) {
            trippetImages.forEach(image => {
              image.Trippet_ID = data.Trippet_ID;
              dispatch(createTrippetPicture(JSON.stringify(image)));
            });
          }
          if (trippetPublicDocuments && trippetPublicDocuments.length > 0) {
            trippetPublicDocuments.forEach(document => {
              document.Trippet_ID = data.Trippet_ID;
              dispatch(createTrippetDocument(JSON.stringify(document)));
            });
          }
          if (trippetPrivateDocuments && trippetPrivateDocuments.length > 0) {
            trippetPrivateDocuments.forEach(document => {
              document.Trippet_ID = data.Trippet_ID;
              dispatch(createTrippetDocument(JSON.stringify(document)));
            });
          }
          dispatch(saveCurrentTrippet(data));
          let trippetBody;
          let getAllTrippetsBody;
          if (CreatorUser_ID === userId) {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +tripId,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: "ModifiedDate",
              OrderDir: "desc",
              // User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: "ModifiedDate",
              OrderDir: "desc",
              User_ID: userId,
            };
          } else {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +tripId,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: "ModifiedDate",
              OrderDir: "desc",
              // Type: 2,
              User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: "ModifiedDate",
              OrderDir: "desc",
              User_ID: userId,
              IsPrivate: 0,
            };
          }

          let getAllParentWithChildrensTrippetsData = {
            Language_ID: "2",
            Trip_ID: +tripId,
            OrderBy: sortElement,
            OrderDir: sortDirection,
            OnlyParents: 1,
            AppendChildren: 1,
            User_ID: userId,
            Page: 1,
            RowsPerPage: 50,
          };
          dispatch(
            getAllParentWithChildrensTrippets(
              JSON.stringify(getAllParentWithChildrensTrippetsData),
            ),
          );
          dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
          dispatch(getAllTrippets(JSON.stringify(getAllTrippetsBody)));
          const sentInvitation = {
            Language_ID: 2,
            Type: 4,
            User_ID: userId,
            OrderBy: "ModifiedDate",
            OrderDir: "desc",
          };
          dispatch(getMyTrippetSentInvitation(JSON.stringify(sentInvitation)));
          dispatch(getAllTrippetPictures());
          dispatch(getAllTrippetDocuments());
          if (Notes) {
            const notesBody = {
              Trippet_ID: data.Trippet_ID,
              TrippetNoteUser_ID: userId,
              Notes: Notes,
            };
            dispatch(createTrippetNotes(JSON.stringify(notesBody)));
          }
        })
        .catch(error => console.log(error));
    }
  };
};

export const UngroupTrippets = (
  body,
  id,
  userId,
  orderBy,
  orderDir,
  filterResults,
  CreatorUser_ID,
  t,
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/setchildof.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          let getAllParentWithChildrensTrippetsData = {
            Language_ID: "2",
            Trip_ID: +id,
            OnlyParents: 1,
            AppendChildren: 1,
            Page: 1,
            OrderBy: orderBy,
            OrderDir: orderDir,
            User_ID: userId,
            RowsPerPage: 50,
          };
          dispatch(
            getAllParentWithChildrensTrippets(
              JSON.stringify(getAllParentWithChildrensTrippetsData),
            ),
          );
          dispatch(
            showSnackBar({
              type: "success",
              message: t("You successfully ungrouped selected trippets"),
            }),
          );
        })
        .catch(error => console.log(error));
    }
  };
};

export const duplicateTrippet = (
  body,
  id,
  userId,
  orderBy,
  orderDir,
  filterResults,
  CreatorUser_ID,
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/duplicate.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          let trippetBody;
          let getAllTrippetsBody;
          if (CreatorUser_ID === userId) {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +id,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: orderBy,
              OrderDir: orderDir,
              // User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: orderBy,
              OrderDir: orderDir,
              User_ID: userId,
            };
          } else {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +id,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: orderBy,
              OrderDir: orderDir,
              // Type: 2,
              User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: orderBy,
              OrderDir: orderDir,
              User_ID: userId,
              IsPrivate: 0,
            };
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              trippetBody[filter.name] = filter.body;
            });
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllTrippetsBody[filter.name] = filter.body;
            });
          }
          let getAllParentWithChildrensTrippetsData = {
            Language_ID: "2",
            Trip_ID: +id,
            OnlyParents: 1,
            AppendChildren: 1,
            Page: 1,
            OrderBy: orderBy,
            OrderDir: orderDir,
            User_ID: userId,
            RowsPerPage: 50,
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllParentWithChildrensTrippetsData[filter.name] = filter.body;
            });
          }
          dispatch(
            getAllParentWithChildrensTrippets(
              JSON.stringify(getAllParentWithChildrensTrippetsData),
            ),
          );
          dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
          dispatch(getAllTrippets(JSON.stringify(getAllTrippetsBody)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const duplicateMultipleTrippet = (
  body,
  id,
  userId,
  orderBy,
  orderDir,
  filterResults,
  CreatorUser_ID,
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/multiduplicate.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          let trippetBody;
          let getAllTrippetsBody;
          if (CreatorUser_ID === userId) {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +id,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: orderBy,
              OrderDir: orderDir,
              // User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: orderBy,
              OrderDir: orderDir,
              User_ID: userId,
            };
          } else {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +id,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: orderBy,
              OrderDir: orderDir,
              // Type: 2,
              User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: orderBy,
              OrderDir: orderDir,
              User_ID: userId,
              IsPrivate: 0,
            };
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              trippetBody[filter.name] = filter.body;
            });
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllTrippetsBody[filter.name] = filter.body;
            });
          }
          let getAllParentWithChildrensTrippetsData = {
            Language_ID: "2",
            Trip_ID: +id,
            OnlyParents: 1,
            AppendChildren: 1,
            Page: 1,
            OrderBy: orderBy,
            OrderDir: orderDir,
            User_ID: userId,
            RowsPerPage: 50,
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllParentWithChildrensTrippetsData[filter.name] = filter.body;
            });
          }
          dispatch(
            getAllParentWithChildrensTrippets(
              JSON.stringify(getAllParentWithChildrensTrippetsData),
            ),
          );
          dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
          dispatch(getAllTrippets(JSON.stringify(getAllTrippetsBody)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const deleteTrippet = (
  body,
  id,
  userId,
  orderBy,
  orderDir,
  filterResults,
  CreatorUser_ID,
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/delete.php`, {
        method: "POST",
        body: JSON.stringify({
          Trippet_ID: body,
        }),
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          let trippetBody;
          let getAllTrippetsBody;
          if (CreatorUser_ID === userId) {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +id,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: orderBy,
              OrderDir: orderDir,
              // User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: orderBy,
              OrderDir: orderDir,
              User_ID: userId,
            };
          } else {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +id,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: orderBy,
              OrderDir: orderDir,
              // Type: 2,
              User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: orderBy,
              OrderDir: orderDir,
              User_ID: userId,
              IsPrivate: 0,
            };
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              trippetBody[filter.name] = filter.body;
            });
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllTrippetsBody[filter.name] = filter.body;
            });
          }
          let getAllParentWithChildrensTrippetsData = {
            Language_ID: "2",
            Trip_ID: +id,
            OnlyParents: 1,
            AppendChildren: 1,
            Page: 1,
            OrderBy: orderBy,
            OrderDir: orderDir,
            User_ID: userId,
            RowsPerPage: 50,
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllParentWithChildrensTrippetsData[filter.name] = filter.body;
            });
          }
          dispatch(
            getAllParentWithChildrensTrippets(
              JSON.stringify(getAllParentWithChildrensTrippetsData),
            ),
          );
          dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
          dispatch(getAllTrippets(JSON.stringify(getAllTrippetsBody)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const deleteSingleTrippet = (
  body,
  id,
  userId,
  orderBy,
  orderDir,
  filterResults,
  CreatorUser_ID,
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/delete.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          let trippetBody;
          let getAllTrippetsBody;
          if (CreatorUser_ID === userId) {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +id,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: orderBy,
              OrderDir: orderDir,
              // User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: orderBy,
              OrderDir: orderDir,
              User_ID: userId,
            };
          } else {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +id,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: orderBy,
              OrderDir: orderDir,
              // Type: 2,
              User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: orderBy,
              OrderDir: orderDir,
              User_ID: userId,
              IsPrivate: 0,
            };
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              trippetBody[filter.name] = filter.body;
            });
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllTrippetsBody[filter.name] = filter.body;
            });
          }
          let getAllParentWithChildrensTrippetsData = {
            Language_ID: "2",
            Trip_ID: +id,
            OnlyParents: 1,
            AppendChildren: 1,
            Page: 1,
            OrderBy: orderBy,
            OrderDir: orderDir,
            User_ID: userId,
            RowsPerPage: 50,
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllParentWithChildrensTrippetsData[filter.name] = filter.body;
            });
          }
          dispatch(
            getAllParentWithChildrensTrippets(
              JSON.stringify(getAllParentWithChildrensTrippetsData),
            ),
          );
          dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
          dispatch(getAllTrippets(JSON.stringify(getAllTrippetsBody)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const deleteMultipleTrippet = (
  body,
  id,
  userId,
  orderBy,
  orderDir,
  filterResults,
  CreatorUser_ID,
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/multidelete.php`, {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          let trippetBody;
          let getAllTrippetsBody;
          if (CreatorUser_ID === userId) {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +id,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: orderBy,
              OrderDir: orderDir,
              // User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: orderBy,
              OrderDir: orderDir,
              User_ID: userId,
            };
          } else {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +id,
              Page: 1,
              RowsPerPage: 50,
              OrderBy: orderBy,
              OrderDir: orderDir,
              // Type: 2,
              User_ID: userId,
            };
            getAllTrippetsBody = {
              Language_ID: 2,
              Page: 1,
              Type: 2,
              RowsPerPage: 10,
              OrderBy: orderBy,
              OrderDir: orderDir,
              User_ID: userId,
              IsPrivate: 0,
            };
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              trippetBody[filter.name] = filter.body;
            });
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllTrippetsBody[filter.name] = filter.body;
            });
          }
          let getAllParentWithChildrensTrippetsData = {
            Language_ID: "2",
            Trip_ID: +id,
            OnlyParents: 1,
            AppendChildren: 1,
            Page: 1,
            OrderBy: orderBy,
            OrderDir: orderDir,
            User_ID: userId,
            RowsPerPage: 50,
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllParentWithChildrensTrippetsData[filter.name] = filter.body;
            });
          }
          dispatch(
            getAllParentWithChildrensTrippets(
              JSON.stringify(getAllParentWithChildrensTrippetsData),
            ),
          );
          dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
          dispatch(getAllTrippets(JSON.stringify(getAllTrippetsBody)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeTrippet = (
  body,
  trippetImages,
  trippetPublicDocuments,
  trippetPrivateDocuments,
  tripId,
  userId,
  sortElement,
  sortDirection,
  filterResults,
  creator,
) => {
  return dispatch => {
    if (body) {
      let updatedBody = JSON.parse(body);
      updatedBody.CurrentUser_ID = userId;
      fetch(`${BASE_URL}api/trippet/updatefull.php`, {
        method: "POST",
        body: JSON.stringify(updatedBody),
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (trippetImages && trippetImages.length > 0) {
            trippetImages.forEach(image => {
              image.Trippet_ID = data.Trippet_ID;
              dispatch(createTrippetPicture(JSON.stringify(image)));
            });
          }
          if (trippetPublicDocuments && trippetPublicDocuments.length > 0) {
            trippetPublicDocuments.forEach(document => {
              document.Trippet_ID = data.Trippet_ID;
              dispatch(createTrippetDocument(JSON.stringify(document)));
            });
          }
          if (trippetPrivateDocuments && trippetPrivateDocuments.length > 0) {
            trippetPrivateDocuments.forEach(document => {
              document.Trippet_ID = data.Trippet_ID;
              dispatch(createTrippetDocument(JSON.stringify(document)));
            });
          }
          dispatch(saveCurrentTrippet(data));
          // const trippetBody = {
          //   Language_ID: 2,
          //   Trip_ID: +tripId,
          //   Page: 1,
          //   RowsPerPage: 50,
          //   OrderBy: sortElement,
          //   OrderDir: sortDirection,
          //   // User_ID: userId,
          // };
          // const allMyTrippets = {
          //   Language_ID: 2,
          //   Type: 2,
          //   User_ID: userId,
          //   OrderBy: 'Title',
          //   OrderDir: 'asc',
          //   Page: 1,
          //   RowsPerPage: 10,
          // };
          const sentInvitation = {
            Language_ID: 2,
            Type: 4,
            User_ID: userId,
            OrderBy: "ModifiedDate",
            OrderDir: "desc",
          };
          dispatch(getMyTrippetSentInvitation(JSON.stringify(sentInvitation)));
          // dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
          // dispatch(getAllTrippets(JSON.stringify(allMyTrippets)));
          let trippetBody;
          let getAllTrippetsBody;
          trippetBody = {
            Language_ID: 2,
            Trip_ID: +tripId,
            Page: 1,
            RowsPerPage: 50,
            OrderBy: sortElement,
            OrderDir: sortDirection,
            User_ID: userId,
          };
          getAllTrippetsBody = {
            Language_ID: 2,
            Page: 1,
            Type: 2,
            RowsPerPage: 10,
            OrderBy: sortElement,
            OrderDir: sortDirection,
            User_ID: userId,
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              trippetBody[filter.name] = filter.body;
            });
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllTrippetsBody[filter.name] = filter.body;
            });
          }
          let getAllParentWithChildrensTrippetsData = {
            Language_ID: "2",
            Trip_ID: +tripId,
            OnlyParents: 1,
            OrderBy: sortElement,
            OrderDir: sortDirection,
            AppendChildren: 1,
            Page: 1,
            RowsPerPage: 50,
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllParentWithChildrensTrippetsData[filter.name] = filter.body;
            });
          }
          dispatch(
            getAllParentWithChildrensTrippets(
              JSON.stringify(getAllParentWithChildrensTrippetsData),
            ),
          );
          dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
          dispatch(getAllTrippets(JSON.stringify(getAllTrippetsBody)));
          dispatch(getAllTrippetDocuments());
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeTrippetWithoutParticipants = (
  body,
  trippetImages,
  trippetPublicDocuments,
  trippetPrivateDocuments,
  tripId,
  userId,
  sortElement,
  sortDirection,
  filterResults,
  creator,
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/update.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (trippetImages && trippetImages.length > 0) {
            trippetImages.forEach(image => {
              image.Trippet_ID = data.Trippet_ID;
              dispatch(createTrippetPicture(JSON.stringify(image)));
            });
          }
          if (trippetPublicDocuments && trippetPublicDocuments.length > 0) {
            trippetPublicDocuments.forEach(document => {
              document.Trippet_ID = data.Trippet_ID;
              dispatch(createTrippetDocument(JSON.stringify(document)));
            });
          }
          if (trippetPrivateDocuments && trippetPrivateDocuments.length > 0) {
            trippetPrivateDocuments.forEach(document => {
              document.Trippet_ID = data.Trippet_ID;
              dispatch(createTrippetDocument(JSON.stringify(document)));
            });
          }
          dispatch(saveCurrentTrippet(data));
          const sentInvitation = {
            Language_ID: 2,
            Type: 4,
            User_ID: userId,
            OrderBy: "ModifiedDate",
            OrderDir: "desc",
          };
          dispatch(getMyTrippetSentInvitation(JSON.stringify(sentInvitation)));
          let trippetBody;
          let getAllTrippetsBody;
          trippetBody = {
            Language_ID: 2,
            Trip_ID: +tripId,
            Page: 1,
            RowsPerPage: 50,
            OrderBy: sortElement,
            OrderDir: sortDirection,
            User_ID: userId,
          };
          getAllTrippetsBody = {
            Language_ID: 2,
            Page: 1,
            Type: 2,
            RowsPerPage: 10,
            OrderBy: sortElement,
            OrderDir: sortDirection,
            User_ID: userId,
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              trippetBody[filter.name] = filter.body;
            });
          }
          if (filterResults) {
            filterResults.forEach(filter => {
              getAllTrippetsBody[filter.name] = filter.body;
            });
          }
          dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
          dispatch(getAllTrippets(JSON.stringify(getAllTrippetsBody)));
          dispatch(getAllTrippetDocuments());
        })
        .catch(error => console.log(error));
    }
  };
};

export const createTrippetRecommendation = (body, userId, t) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/recommendation/create.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          } else if (response.status === 503) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          dispatch(
            showSnackBar({
              type: "success",
              message: "The recommendation has been successfully sent",
            }),
          );
          const receivedRecommencations = {
            TrippetRecommendationRecipientUser_ID: userId,
          };
          dispatch(
            getAllReceivedRecommencations(
              JSON.stringify(receivedRecommencations),
            ),
          );
          const sendRecommendations = {
            TrippetRecommendationSenderUser_ID: userId,
          };
          dispatch(
            getAllTrippetSendRecommendations(
              JSON.stringify(sendRecommendations),
            ),
          );
          const totalNumberOfTripRecommendations = { User_ID: userId };

          dispatch(
            getTotalTrippetRecommendationsNumber(
              JSON.stringify(totalNumberOfTripRecommendations),
            ),
          );
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveTrippetSentInvitationPagination = payload => ({
  type: actionTypes.SAVE_TRIPPET_SENT_INVITATION_PAGINATION,
  payload,
});

export const saveTrippetRecievedInvitationPagination = payload => ({
  type: actionTypes.SAVE_TRIPPET_RECIEVED_INVITATION_PAGINATION,
  payload,
});

export const saveTrippetSentRecommendationPagination = payload => ({
  type: actionTypes.SAVE_TRIPPET_SENT_RECOMMENDATION_PAGINATION,
  payload,
});

export const saveTrippetRecievedRecommendationPagination = payload => ({
  type: actionTypes.SAVE_TRIPPET_RECIEVED_RECOMMENDATION_PAGINATION,
  payload,
});

export const saveTrippetChildrenStatus = payload => ({
  type: actionTypes.SAVE_TRIPPET_CHILDREN_STATUS,
  payload,
});

export const getMyTrippetSentInvitation = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/search.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveTrippetSentInvitationPagination(data));
          dispatch(saveMyTrippetSentInvitation(data.Data));
        })
        .catch(error => console.log(error));
    }
  };
};

// export const getMyTrippetReceivedInvitation = body => {
//   return dispatch => {
//     if (body) {
//       fetch(`${BASE_URL}api/trippet/search.php`, {
//         method: "POST",
//         body: body,
//       })
//         .then(response => {
//           if (response.status === 200) {
//             return response.text();
//           } else if (response.status === 304) {
//             dispatch(
//               showSnackBar({
//                 type: "error",
//                 message: "Something went wrong",
//               }),
//             );
//           }
//         })
//         .then(response => {
//           const data = JSON.parse(response);
//           dispatch(saveTrippetRecievedInvitationPagination(data));
//           dispatch(saveMyTrippetReceivedInvitation(data.Data));
//         })
//         .catch(error => console.log(error));
//     }
//   };
// };

export const getMyTrippetReceivedInvitation = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/receivedinvitations.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveTrippetRecievedInvitationPagination(data));
          dispatch(saveMyTrippetReceivedInvitation(data.Data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const getAllTrippetSendRecommendations = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/recommendation/search.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveTrippetSentRecommendationPagination(data));
          dispatch(saveAllTrippetSentRecommendations(data.Data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const getAllReceivedRecommencations = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/recommendation/search.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveTrippetRecievedRecommendationPagination(data));
          dispatch(saveAllTrippetRecommendations(data.Data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveTotalTrippetRecommendations = payload => ({
  type: actionTypes.SAVE_TOTAL_TRIPPET_RECOMMENDATIONS_NUMBER,
  payload,
});

export const getTotalTrippetRecommendationsNumber = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/recommendation/getnum.php`, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong!",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveTotalTrippetRecommendations(data.Num));
      })
      .catch(error => console.log(error));
  };
};

export const createTrippetNotes = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/note/create.php`, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.status === 201) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong!",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        const body = {
          Trippet_ID: data.Trippet_ID,
          TrippetNoteUser_ID: data.TrippetNoteUser_ID,
        };
        dispatch(getAllCurrentTrippetNotes(JSON.stringify(body)));
      })
      .catch(error => console.log(error));
  };
};

export const deleteTrippetNotes = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/note/delete.php`, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.status === 201) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong!",
            }),
          );
        }
      })
      .then(() => {})
      .catch(error => console.log(error));
  };
};

export const getAllCurrentTrippetNotes = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/note/search.php`, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong!",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveCurrentTrippetNotes(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const saveCurrentTrippetNotes = payload => ({
  type: actionTypes.SAVE_ALL_CURRENT_TRIPPET_NOTES,
  payload,
});

export const saveTrippetSortElement = payload => ({
  type: actionTypes.SAVE_TRIPPET_SORT_ELEMENT,
  payload,
});

export const saveMyTrippetSentInvitation = payload => ({
  type: actionTypes.SAVE_MY_TRIPPET_SENT_INVITATION,
  payload,
});

export const saveMyTrippetReceivedInvitation = payload => ({
  type: actionTypes.SAVE_MY_TRIPPET_RECEIVED_INVITATION,
  payload,
});

export const saveTrippetSortByDirection = payload => ({
  type: actionTypes.SAVE_TRIPPET_SORT_BY_DIRECTION,
  payload,
});

export const saveTrippetInvitationSortByDirection = payload => ({
  type: actionTypes.SAVE_TRIPPET_INVITATION_SORT_BY_DIRECTION,
  payload,
});

export const saveTrippetInvitationSortElement = payload => ({
  type: actionTypes.SAVE_TRIPPET_INVITATION_SORT_ELEMENT,
  payload,
});

export const clearCurrentTrippetNotes = () => ({
  type: actionTypes.CLEAR_ALL_CURRENT_TRIPPET_NOTES,
});

export const saveUploadedFile = payload => ({
  type: actionTypes.SAVE_UPLOADED_FILE,
  payload,
});

export const saveAllTrippets = payload => ({
  type: actionTypes.SAVE_ALL_TRIPPETS,
  payload,
});

export const saveAllMyTrippets = payload => ({
  type: actionTypes.SAVE_ALL_MY_TRIPPETS,
  payload,
});

export const saveAllTrippetInvitatins = payload => ({
  type: actionTypes.SAVE_ALL_TRIPPETS_INVITATIONS,
  payload,
});

export const saveAllTrippetRecommendations = payload => ({
  type: actionTypes.SAVE_ALL_TRIPPET_RECOMMENDATIONS,
  payload,
});

export const saveAllTrippetSentRecommendations = payload => ({
  type: actionTypes.SAVE_ALL_SENT_TRIPPET_RECOMMENDATIONS,
  payload,
});

export const saveCurrentTrippet = payload => ({
  type: actionTypes.SAVE_CURRENT_TRIPPET,
  payload,
});

export const clearAllTrippet = () => ({
  type: actionTypes.CLEAR_ALL_TRIPPETS,
});

export const showTrippetForm = payload => ({
  type: actionTypes.SHOW_TRIPPET_FORM,
  payload,
});

export const hideTrippetForm = () => ({
  type: actionTypes.HIDE_TRIPPET_FORM,
});

export const savePickedTime = payload => ({
  type: actionTypes.SAVE_PICKED_TIME_FROM_CALENDAR,
  payload,
});

export const saveCalendarInstance = payload => ({
  type: actionTypes.SAVE_CALENDAR_INSTANCE,
  payload,
});

export const savePaginationPage = payload => ({
  type: actionTypes.SAVE_TRIPPET_PAGE_PAGIONATION,
  payload,
});

export const saveMyTrippetPaginationPage = payload => ({
  type: actionTypes.SAVE_MY_TRIPPET_PAGE_PAGIONATION,
  payload,
});

export const saveFilterTrippetResults = payload => ({
  type: actionTypes.SAVE_FILTER_TRIPPET_RESULTS,
  payload,
});

export const clearFilterTrippetResults = payload => ({
  type: actionTypes.CLEAR_FILTER_TRIPPET_RESULTS,
  payload,
});

// trippet documents

export const deleteTrippetDocument = data => {
  return dispatch => {
    if (data.body) {
      fetch(`${BASE_URL}api/trippet/document/delete.php`, {
        method: "POST",
        body: JSON.stringify(data.body),
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          if (data.type === "private") {
            dispatch(
              getAllPrivateDocumentsFromTheSingleTrippet(
                JSON.stringify(data.query),
              ),
            );
          } else {
            dispatch(
              getAllPublicDocumentsFromTheSingleTrippet(
                JSON.stringify(data.query),
              ),
            );
          }
        })
        .catch(error => console.log(error));
    }
  };
};

export const createTrippetDocument = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/document/create.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          dispatch(getAllTrippetDocuments());
          //
        })
        .catch(error => console.log(error));
    }
  };
};

export const getAllTrippetDocuments = () => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/document/getall.php`, {
      method: "GET",
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllTrippetsDocuments(data));
      })
      .catch(error => console.log(error));
  };
};

export const saveTrippetPrivateDocuments = payload => ({
  type: actionTypes.SAVE_TRIPPET_PRIVATE_DOCUMENTS,
  payload,
});

export const saveTrippetPublicDocuments = payload => ({
  type: actionTypes.SAVE_TRIPPET_PUBLIC_DOCUMENTS,
  payload,
});

export const getAllPrivateDocumentsFromTheSingleTrippet = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/document/search.php`, {
      method: "POST",
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveTrippetPrivateDocuments(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const getAllPublicDocumentsFromTheSingleTrippet = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/document/search.php`, {
      method: "POST",
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveTrippetPublicDocuments(data.Data));
        // dispatch(saveAllTrippetsDocuments(data));
      })
      .catch(error => console.log(error));
  };
};

export const clearFiles = () => ({
  type: actionTypes.CLEAR_FILES,
});

export const saveAllTrippetsDocuments = payload => ({
  type: actionTypes.SAVE_ALL_TRIPPETS_DOCUMENTS,
  payload,
});

// trippet pictures
export const createTrippetPicture = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/picture/create.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          //
        })
        .catch(error => console.log(error));
    }
  };
};

export const updateTrippetPicture = data => {
  return dispatch => {
    if (data) {
      fetch(`${BASE_URL}api/trippet/picture/update.php`, {
        method: "POST",
        body: JSON.stringify(data.body),
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(() => {
          const trippetImage = {
            Trippet_ID: data.Trippet_ID,
          };
          dispatch(getTrippetImages(JSON.stringify(trippetImage)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveTrippetImages = payload => ({
  type: actionTypes.SAVE_TRIPPET_IMAGES,
  payload,
});

export const clearTrippetImages = payload => ({
  type: actionTypes.CLEAR_TRIPPET_IMAGES,
  payload,
});

export const saveSelectedImage = payload => ({
  type: actionTypes.SAVE_SELECTED_IMAGE,
  payload,
});

export const clearSelectedImage = payload => ({
  type: actionTypes.CLEAR_SELECTED_IMAGE,
  payload,
});

export const getTrippetImages = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/picture/search.php`, {
      method: "POST",
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveTrippetImages(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const getTrippetPicture = id => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/trippet/picture/get.php?id=${id}`, {
        method: "POST",
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          data.type = "image";
          dispatch(saveUploadedFile(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllTrippetsPictures = payload => ({
  type: actionTypes.SAVE_ALL_TRIPPETS_PICTURES,
  payload,
});

export const getAllTrippetPictures = () => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/picture/getall.php`, {
      method: "GET",
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllTrippetsPictures(data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteImage = () => ({
  type: actionTypes.DELETE_IMAGE,
});

export const deleteTrippetImage = data => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/picture/delete.php`, {
      method: "POST",
      body: JSON.stringify(data.body),
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 503) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        } else if (response.status === 404) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Image not found",
            }),
          );
        }
      })
      .then(() => {
        dispatch(clearSelectedImage());
        dispatch(getTrippetImages(JSON.stringify(data.query)));
      })
      .catch(error => console.log(error));
  };
};

// trippet types

export const saveTrippetTypes = payload => ({
  type: actionTypes.SAVE_TRIPPET_TYPES,
  payload,
});

export const getTrippetTypes = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/typelook/search.php`, {
      method: "POST",
      body: body
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveTrippetTypes(data.Data));
      })
      .catch(error => console.log(error));
  };
};

// trippet categories

export const saveTrippetCategory = payload => ({
  type: actionTypes.SAVE_TRIPPET_CATEGORY,
  payload,
});

// export const getTrippetCategory = () => {
//   return dispatch => {
//     fetch(`${BASE_URL}api/trippet/typecategory/getall.php?language_id=2`, {
//       method: "GET",
//     })
//       .then(response => {
//         if (response.status === 200) {
//           return response.text();
//         } else if (response.status === 304) {
//           dispatch(
//             showSnackBar({
//               type: "error",
//               message: "Something went wrong",
//             }),
//           );
//         }
//       })
//       .then(response => {
//         const data = JSON.parse(response);
//         dispatch(saveTrippetCategory(data));
//       })
//       .catch(error => console.log(error));
//   };
// };

export const clearTrippetCategoriesAndTypes = () => ({
  type: actionTypes.CLEAR_TRIPPET_CATEGORIES_AND_TYPES,
})


export const getTrippetCategory = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/typecategory/search.php`, {
      method: "POST",
      body: body
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveTrippetCategory(data.Data));
      })
      .catch(error => console.log(error));
  };
};

// trippet status

export const saveTrippetStatus = payload => ({
  type: actionTypes.SAVE_TRIPPET_STATUS,
  payload,
});

export const getTrippetStatus = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/planningstatus/search.php`, {
      method: "POST",
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveTrippetStatus(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const saveTrippetPaginationData = payload => ({
  type: actionTypes.SAVE_TRIPPET_PAGINATION_DATA,
  payload,
});

export const saveMyTrippetsPaginationData = payload => ({
  type: actionTypes.SAVE_MY_TRIPPETS_PAGINATION_DATA,
  payload,
});

export const saveMyTrippetsSortElement = payload => ({
  type: actionTypes.SAVE_MY_TRIPPET_SORT_ELEMENT,
  payload,
});

export const saveMyTrippetsSortByDirection = payload => ({
  type: actionTypes.SAVE_MY_TRIPPET_SORT_BY_DIRECTION,
  payload,
});

export const getAllTrippetsFromTheTrip = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/search.php`, {
      method: "POST",
      body: body,
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllTrippets(data.Data));
        dispatch(saveTrippetPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

// trippet filters

export const saveTrippetUsedFilters = payload => ({
  type: actionTypes.SAVE_TRIPPET_USED_FILTERS,
  payload,
});

export const getFilterData = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/filter/${body.path}.php`, {
        method: "POST",
        body: JSON.stringify(body.data),
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveFilterTrippetResults(data));
        })
        .catch(error => console.log(error));
    }
  };
};

// trippet chat

export const saveCurrentTrippetChatHistory = payload => ({
  type: actionTypes.SAVE_SINGLE_TRIPPET_CHAT,
  payload,
});

export const getCurrentTrippetChat = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/chat/search.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentTrippetChatHistory(data.Data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveTripTotalPrice = payload => ({
  type: actionTypes.SVE_TRIP_TOTAL_PRICE,
  payload: payload,
});

export const getTotalPrice = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/gettotalprice.php`, {
        method: "POST",
        body: body,
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveTripTotalPrice(data));
        })
        .catch(error => console.log(error));
    }
  };
};
