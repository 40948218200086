import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tripBoard: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  marginTop: {
    marginTop: 20,
  },
  participants: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      fontSize: 14,
      marginLeft: 5,
      '& > div': {
        fontSize: 14,
        width: 41,
        height: 41,
      },
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  editTripButton: {
    width: 35,
    height: 35,
    marginLeft: 10,
    position: 'absolute',
    right: 15,
    top: 0,
  },
  pointer: {
    cursor: 'pointer',
  },
  actionButtonsContainer: {
    position: 'relative',
  },
  accepted: {
    border: `4px solid #579e38 !important`,
  },
  pending: {
    border: `4px solid ${theme.palette.electricBlue[100]} !important`,
  },
  rejected: {
    border: `4px solid #cc3333 !important`,
  },
  avatarImage: {
    width: '100%',
    height: '100%',
  },
  tripNameContainer: {
    marginTop: 10,
    padding: '5px 25px',
    cursor: 'pointer',
    transition: 'all .4s',
    "&:hover": {
      backgroundColor: 'white',
      boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
    }
  },
  tripName: {
    " & > h2": {
      color: '#008DE4',
      fontSize: 23,
    }
  },
  tripDate: {
    "& > div > span": {
      color: '#008DE4',
      marginLeft: 10,
    }
  },
  secondRowContainer: {
    marginTop: 10,
    padding: '5px 25px',
    cursor: 'pointer',
    transition: 'all .4s',
    "&:hover": {
      backgroundColor: 'white',
      boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
    }
  },
  proxy: {
    display: 'flex',
    alignItems: 'center',
    "& > div": {
      marginRight: 30,
    }
  },
  buddies: {
    display: 'flex',
    alignItems: 'center',
    "& > div": {
      marginLeft: 10,
    }
  }
}));

export default useStyles;
