export const columnsTwo = [
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    type: 'TrippetTypeLookName',
  },
  { id: 'title', label: 'Trippet Title', minWidth: 80, type: 'Title' },
  { id: 'tripTitle', label: 'Trip Title', minWidth: 100, type: 'TripName' },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 80,
    type: 'StartDate',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 80,
    type: 'EndDate',
  },
  {
    id: 'from',
    label: 'From',
    minWidth: 80,
    type: 'FromPlace',
  },
  {
    id: 'to',
    label: 'To',
    minWidth: 80,
    type: 'ToPlace',
  },
  {
    id: 'participants',
    label: 'Participants',
    minWidth: 80,
    type: 'DisplayName',
  },
  {
    id: 'organizers',
    label: 'Responsible',
    minWidth: 80,
    type: 'DisplayName',
  },
  // {
  //   id: 'proxies',
  //   label: 'Proxies',
  //   minWidth: 80,
  //   type: 'DisplayName',
  // },
  {
    id: 'status',
    label: 'Status',
    minWidth: 110,
    type: 'OwnerPlanningStatus_ID',
    align: 'center',
  },
  {
    id: 'lastChanged',
    label: 'Last Changed',
    minWidth: 80,
    align: 'center',
    type: 'ModifiedDate',
  },
];
