import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { columns } from './columns';
import useStyles from './styles';
import TrippetTypeCategoryButton from './TrippetTypeCategoryButton';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import { BASE_URL } from '../../config';
import * as userAccess from '../../userAccess';
import TrippetTypeCategoryModal from '../../components/TrippetType/TrippetTypeCategoryForm';
import Row from './Row';
import { saveRows } from '../../store/actions/actions/trippetTypeCategory';
import TripFilter from "./TripFilter";

const createData = (
    TrippetTypeCategory_ID,
    Trip_ID,
    Color,
    Icon,
    MaterialIO,
    Comments,
    IsTransport,
    SortIndex,
    Name,
    TripName,
    Names,
    Looks,
) => {
  return {
    TrippetTypeCategory_ID,
    Trip_ID,
    Color,
    Icon,
    MaterialIO,
    Comments,
    IsTransport,
    SortIndex,
    Name,
    TripName,
    Names,
    Looks,
  };
};

const TrippetTypeCategoryTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedTrippetTypeCategory,
  sortedElement,
  sortedBy,
  handleSortBy
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const allTrippetTypeCategories = useSelector(state => state.trippetTypeCategory.allTrippetTypeCategories)
  const filterTrip_ID = useSelector(state => state.trippetTypeCategory.filterTrip_ID)
  const [clickedTrippetTypeCategory, setClickedTrippetTypeCategory] = useState([]);
  const [isTrippetTypeCategoryModalOpen, setIsTrippetTypeCategoryModalOpen] = useState(false);
  const [openTripFilter, setOpenTripFilter] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(useSelector(state => state.user.user), userAccess.Table_TrippetTypes);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.TrippetTypeCategory_ID,
            item.Trip_ID,
            item.Color,
            item.Icon,
            item.MaterialIO,
            item.Comments,
            item.IsTransport,
            item.SortIndex,
            item.Name,
            item.TripName,
            item.Names,
            item.Looks,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedTrippetTypeCategory = value => {
    setClickedTrippetTypeCategory(value);
  };

  const handleTrippetTypeCategoryModalOpen = () => {
    setIsTrippetTypeCategoryModalOpen(true);
  };

  const handleTrippetTypeCategoryModalClose = () => {
    setIsTrippetTypeCategoryModalOpen(false);
  };
  
  // just for TripName
  const handleShowFilter = (id) => {
    setOpenTripFilter(true);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);
  
  const setIsOpen = (isOpen) =>
  {
    setOpenTripFilter(isOpen);
  }

  const setSelectedTrip = (trip) =>
  {
    console.log(trip.fullTrip.Trip_ID);
    setOpenTripFilter(false);
  }
  const renderData = (column, value, row) => {
    if (column.id === 'actions') {
        return (
            <TrippetTypeCategoryButton
              data={row}
              handleClickedTrippetTypeCategory={handleClickedTrippetTypeCategory}
              handleTrippetTypeCategoryModalOpen={handleTrippetTypeCategoryModalOpen}
              handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
              setDeletedTrippetTypeCategory={setDeletedTrippetTypeCategory}
              disabledDelete={disableDelete}
            />
          );
    }
    else if (column.id === 'Icon' && value)
    {
      return <img src={`${BASE_URL}${value.substr(1)}`} className={classes.avatar} alt='Icon' />
    }
    else if (column.id === 'Color' && value)
    {
      return <span>{value} <span style={{backgroundColor: '#' + value, width: '30px', display: 'inline-block'}}>&nbsp;</span></span>
    }
    else if (column.id === 'IsTransport' && value)
    {
      return Number.parseInt(value) === 1 ? <CheckIcon /> : null;
    }
    else {
      return value;
    }
  };

  useEffect(() => {
    if(allTrippetTypeCategories && allTrippetTypeCategories.length > 0){
      let data = {};
      allTrippetTypeCategories.forEach(category => {
        data[category.TrippetTypeCategory_ID] = false;
      });
      dispatch(saveRows(data));
    }
  },[allTrippetTypeCategories, dispatch])

  const renderRows =
  rows && rows.length
    ? rows.map(row => {
        return (<Row id={row.TrippetTypeCategory_ID} key={row.TrippetTypeCategory_ID} row={row} renderData={renderData}/>)
    })
    : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map(column => {
                if (column.id !== 'actions') {
                  let activeColor = filterTrip_ID > 0
                      ? classes.activeFilter
                      : "";

                    return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.tableHeaderCell}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            onClick={handleSortBy(column.id)}
                            active={sortedElement === column.id}
                            direction={
                              sortedElement === column.id
                                ? sortedBy
                                : 'asc'
                            }
                          >
                            {t(column.label)}
                          </TableSortLabel>
                          {
                            column.filter ? 
                          (<IconButton
                            onClick={(id) => handleShowFilter(column.id)}
                            className={activeColor}
                            hidden={true}
                            >
                              <FilterListIcon />
                          </IconButton>)
                          : null
                          }
                        </TableCell>
                      );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <TrippetTypeCategoryModal
        open={isTrippetTypeCategoryModalOpen}
        handleTrippetTypeCategoryModalClose={handleTrippetTypeCategoryModalClose}
        defaultValues={clickedTrippetTypeCategory}
      />
      <TripFilter
        openTripFilter={openTripFilter}
        setIsOpen={setIsOpen}
        setSelectedTrip={setSelectedTrip}
      />
    </Paper>
  );
};

export default TrippetTypeCategoryTable;
