import React, { useState, useEffect } from "react";
import withDashboard from "../../hoc/withDashboard/withDashboard";
import withTitle from "../../hoc/withTitle";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Notification from "./Notification";
import ScrollToTop from "react-scroll-up";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useSelector, useDispatch } from "react-redux";
import { isToday, isYesterday, isSameWeek, isThisMonth } from "date-fns";
import {
  saveNotificationFilters,
  deleteMultipleNotification,
  getCreatorNotificationList,
  getTripNotificationList,
  getTrippetNotificationList,
} from "../../store/actions/actions/notifications";
import { hideTrippetForm } from "../../store/actions/actions/trippet";
import TrippetForm from "../../components/TrippetForm/TrippetForm";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import TripModal from "../../components/TripForm/TripForm";

const Notifications = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allNotifications = useSelector(
    state => state.notification.notifications,
  );
  const allUsers = useSelector(state => state.app.allUsers);
  const user = useSelector(state => state.user.user);
  const isOpenTrippetFrom = useSelector(
    state => state.trippet.isOpenTrippetFrom,
  );
  const allTrips = useSelector(state => state.trip.allTrips);
  const currentTrippet = useSelector(state => state.trippet.currentTrippet);
  const creatorList = useSelector(state => state.notification.creatorList);
  const tripNameList = useSelector(state => state.notification.tripNameList);
  const trippetNameList = useSelector(
    state => state.notification.trippetNameList,
  );
  const interval = useSelector(state => state.notification.interval);
  const [notifications, setNotifications] = useState(null);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isTripModalOpen, setIsTripModalOpen] = useState(false);
  const [clickedTrip, setClickedTrip] = useState(null);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [filters, setFilters] = useState({
    selectAll: false,
    date: "",
    tripName: "",
    trippetName: "",
    creator: "",
    seen: "",
  });
  const [checkBoxes, setCheckBoxes] = useState(null);

  useEffect(() => {
    if (user) {
      let filterBody = {
        User_ID: user.User_ID,
      };
      dispatch(getCreatorNotificationList(JSON.stringify(filterBody)));
      dispatch(getTrippetNotificationList(JSON.stringify(filterBody)));
      dispatch(getTripNotificationList(JSON.stringify(filterBody)));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      let id = setInterval(() => {
        let filterBody = {
          User_ID: user.User_ID,
        };
        dispatch(getCreatorNotificationList(JSON.stringify(filterBody)));
        dispatch(getTrippetNotificationList(JSON.stringify(filterBody)));
        dispatch(getTripNotificationList(JSON.stringify(filterBody)));
      }, interval);
      return () => clearInterval(id);
    }
  }, [user, dispatch, interval]);

  useEffect(() => {
    if (allNotifications) {
      let object = {};
      allNotifications.forEach(notification => {
        object[notification.LogUser_ID] = false;
      });
      setCheckBoxes(object);
    }
  }, [allNotifications]);

  const handleNotificationCheckBox = LogUser_ID => () => {
    setCheckBoxes({
      ...checkBoxes,
      [LogUser_ID]: !checkBoxes[LogUser_ID],
    });
  };

  const handleChange = event => {
    const name = event.target.name;
    setFilters({
      ...filters,
      [name]: event.target.value,
    });
  };

  useEffect(() => {
    if (allNotifications) {
      if (filters.date === "today") {
        let array = [];
        allNotifications.forEach(notification => {
          if (isToday(new Date(`${notification.LogDate}`))) {
            array.push(notification);
          }
        });
        setNotifications(array);
      } else if (filters.date === "yesterday") {
        let array = [];
        allNotifications.forEach(notification => {
          if (isYesterday(new Date(`${notification.LogDate}`))) {
            array.push(notification);
          }
        });
        setNotifications(array);
      } else if (filters.date === "lastWeek") {
        let array = [];
        allNotifications.forEach(notification => {
          if (isSameWeek(new Date(`${notification.LogDate}`), new Date())) {
            array.push(notification);
          }
        });
        setNotifications(array);
      } else if (filters.date === "thisMonth") {
        let array = [];
        allNotifications.forEach(notification => {
          if (isThisMonth(new Date(`${notification.LogDate}`), new Date())) {
            array.push(notification);
          }
        });
        setNotifications(array);
      } else {
        setNotifications(allNotifications);
      }
    }
  }, [filters.date, allNotifications]);

  useEffect(() => {
    let data = {
      tripName: filters.tripName,
      trippetName: filters.trippetName,
      creator: filters.creator,
      seen: filters.seen,
      date: filters.date,
    };
    dispatch(saveNotificationFilters(data));
  }, [filters, dispatch]);

  useEffect(() => {
    if (allNotifications) {
      setNotifications(allNotifications);
    }
  }, [allNotifications]);

  const handleSelectAll = event => {
    setFilters({
      ...filters,
      selectAll: event.target.checked,
    });
    if (event.target.checked) {
      let object = {};
      let array = [];
      notifications.forEach(notification => {
        let id = notification.LogUser_ID;
        object[id] = true;
        array.push(id);
      });
      setSelectedNotifications(array);
      setCheckBoxes(object);
    } else {
      let object = {};
      notifications.forEach(notification => {
        let id = notification.LogUser_ID;
        object[id] = false;
      });
      setSelectedNotifications([]);
      setCheckBoxes(object);
    }
  };

  useEffect(() => {
    if (checkBoxes) {
      let array = [];
      Object.keys(checkBoxes).forEach(key => {
        if (checkBoxes[key]) {
          array.push(key);
        }
      });
      setSelectedNotifications(array);
    }
  }, [checkBoxes]);

  const handleDeleteSelectedNotification = () => {
    if (selectedNotifications) {
      let data = {
        body: selectedNotifications,
        User_ID: user.User_ID,
      };
      dispatch(deleteMultipleNotification(data));
    }
  };

  const handleTrippetFormClose = () => {
    dispatch(hideTrippetForm());
  };

  const handleConfirmModalOpen = () => {
    setIsOpenConfirmModal(true);
  };

  const handleConfirmModalClose = () => {
    setIsOpenConfirmModal(false);
  };

  const handleTripModalOpen = () => {
    setIsTripModalOpen(true);
  };

  const handleTripModalClose = () => {
    setIsTripModalOpen(false);
  };

  const renderDeleteButton =
    selectedNotifications && selectedNotifications.length > 0 ? (
      <Button
        variant="contained"
        color="secondary"
        className={classes.marginRight}
        onClick={handleDeleteSelectedNotification}
      >
        Delete
      </Button>
    ) : null;

  const renderNotification =
    notifications && notifications.length > 0 ? (
      notifications.map(notification => (
        <Grid item xs={12} key={notification.Log_ID}>
          <Notification
            notification={notification}
            allUsers={allUsers}
            user={user}
            handleNotificationCheckBox={handleNotificationCheckBox}
            checkBoxes={checkBoxes}
            allTrips={allTrips}
            setClickedTrip={setClickedTrip}
            handleTripModalOpen={handleTripModalOpen}
          />
        </Grid>
      ))
    ) : (
      <div>{t("No results")}</div>
    );

  const renderTripNames =
    tripNameList && tripNameList.length > 0
      ? tripNameList.map(trip => (
          <option key={trip.Trip_ID} value={trip.Trip_ID}>
            {trip.TripName}
          </option>
        ))
      : null;

  const renderTrippetNames =
    trippetNameList && trippetNameList.length > 0
      ? trippetNameList.map(trippet => (
          <option key={trippet.Trippet_ID} value={trippet.Trippet_ID}>
            {trippet.TrippetTitle}
          </option>
        ))
      : null;

  const renderCreatorNames =
    creatorList && creatorList.length > 0
      ? creatorList.map(creator => (
          <option key={creator.LoggerUser_ID} value={creator.LoggerUser_ID}>
            {creator.LoggerUserDisplayName}
          </option>
        ))
      : null;

  return (
    <Grid container justify="center">
      <Grid item xs={12} className={classes.backToCockpit}>
        <Link to="/">
          <KeyboardBackspaceIcon />
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            className={classes.title}
            id={"Invitations"}
          >
            {t("Back to Cockpit")}
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          className={classes.title}
        >
          {t("Notifications")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classes.filterContainer}
        justify="center"
        alignItems="center"
      >
        <ScrollToTop showUnder={50} style={{ right: 100 }}>
          <span className={classes.backToTop}>
            <KeyboardArrowUpIcon />
          </span>
        </ScrollToTop>
        {renderDeleteButton}
        <Grid item className={classes.marginRight}>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.selectAll}
                onChange={handleSelectAll}
                name="selectAll"
                color="primary"
              />
            }
            label="Select All"
          />
        </Grid>
        <Grid item className={classes.marginRight}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              {t("Date")}
            </InputLabel>
            <Select
              native
              value={filters.date}
              onChange={handleChange}
              label="Filter"
              inputProps={{
                name: "date",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value="today">{t("Today")}</option>
              <option value="yesterday">{t("Yesterday")}</option>
              <option value="thisWeek">{t("This Week")}</option>
              <option value="thisMonth">{t("This Month")}</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.marginRight}>
          <FormControl
            variant="outlined"
            className={classes.formControlTripName}
          >
            <InputLabel htmlFor="outlined-age-native-simple">
              {t("Trip Name")}
            </InputLabel>
            <Select
              native
              value={filters.tripName}
              onChange={handleChange}
              label="Filter"
              inputProps={{
                name: "tripName",
                id: "outlined-age-native-simple",
              }}
            >
              {renderTripNames}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.marginRight}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              {t("Trippet Name")}
            </InputLabel>
            <Select
              native
              value={filters.trippetName}
              onChange={handleChange}
              label="Filter"
              inputProps={{
                name: "trippetName",
                id: "outlined-age-native-simple",
              }}
            >
              {renderTrippetNames}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.marginRight}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              {t("Creator")}
            </InputLabel>
            <Select
              native
              value={filters.creator}
              onChange={handleChange}
              label="Filter"
              inputProps={{
                name: "creator",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              {renderCreatorNames}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.marginRight}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              {t("Seen")}
            </InputLabel>
            <Select
              native
              value={filters.seen}
              onChange={handleChange}
              label="Filter"
              inputProps={{
                name: "seen",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value="0">Non-Seen</option>
              <option value="1">Seen</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classes.notificationContainer}
        justify="center"
      >
        {renderNotification}
      </Grid>
      <Grid item xs={12} className={classes.trippetFormContainer}>
        {isOpenTrippetFrom && (
          <TrippetForm
            open={isOpenTrippetFrom}
            handleClose={handleTrippetFormClose}
            handleConfirmModalOpen={handleConfirmModalOpen}
            currentTrippet={currentTrippet}
            pickedTime={null}
          />
        )}
        <ConfirmModal
          open={isOpenConfirmModal}
          handleCloseParenModal={handleTrippetFormClose}
          handleClose={handleConfirmModalClose}
        />
      </Grid>
      <TripModal
        open={isTripModalOpen}
        handleTripModalClose={handleTripModalClose}
        defaultValues={clickedTrip}
      />
    </Grid>
  );
};

export default withTitle(withDashboard(Notifications), "Notifications");
