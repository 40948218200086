import React from 'react';
import withDashboard from '../../hoc/withDashboard/withDashboard';
import withTitle from '../../hoc/withTitle';
import TripBoard from '../../components/TripBoard/TripBoard';
import TrippetTable from '../../components/TrippetTable/TrippetTable';
import Calendar from '../../components/Calendar/Calendar';
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

const Cockpit = () => {
  const classes = useStyles();
  const currentTrip = useSelector(state => state.trip.currentTrip);
  const cockpitView = useSelector(state => state.programSettings.cockpitView);

  const renderView =
    cockpitView && cockpitView === 1 ? (
      <>
        <Grid item xs={12} md={6}>
          <Calendar />
        </Grid>
        <Grid item xs={12} md={6}>
          <GoogleMap />
        </Grid>
      </>
    ) : (
      <>
        <Grid item xs={12} md={6}>
          <GoogleMap />
        </Grid>
        <Grid item xs={12} md={6}>
          <Calendar />
        </Grid>
      </>
    );

  return (
    <div className={classes.cockpit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TripBoard />
        </Grid>
        {renderView}
      </Grid>
      <Grid item xs={12}>
        {currentTrip && currentTrip.Name && <TrippetTable />}
      </Grid>
    </div>
  );
};

export default withTitle(withDashboard(Cockpit));
