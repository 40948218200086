import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#0074BC' },
    electricBlue: {
      100: '#008De5',
    },
    blueJeans: {
      100: '#75CAFF',
    },
    freshAir: {
      100: '#A5DCFF',
    },
    veroneseGreen: {
      100: '#A5DCFF',
    },
    seaGreen: {
      100: '#11B29F',
    },
    mediumTurquoise: {
      100: '#67CEC1',
    },
    greenPowder: {
      100: '#A6E6E1',
    },
    darkGold: {
      100: '#C78501',
    },
    vividOrangePeel: {
      100: '#F2A100',
    },
    topaz: {
      100: '#F8CD78',
    },
    bananaMania: {
      100: '#FBE5B9',
    },
    text: {
      100: '#302D2E',
    },
  },
});

export default theme;
