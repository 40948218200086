import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import { updateParticipantStatus, updateProxyStatus, updateOrganizerStatus } from '../../store/actions/actions/trip';
import { showSnackBar } from '../../store/actions/actions/root';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    top: '-27px !important',
    left: '-8px !important',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    '& > button': {
      width: 40,
      height: 40,
      backgroundColor: '#0E84B8',
    },
  },
}));

const TripInvitationButton = ({
  data,
  user,
  filterResults,
  sortedElementMyTrips,
  sortedByMyTrips,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const updateParticipants = (action) => {
    if (action === 'accept') {
      if (data) {
        data &&
          data.participants.forEach(participant => {
            if (participant.TripParticipantUser_ID === user.User_ID) {
              let body = {
                TripParticipant_ID: participant.TripParticipant_ID,
                TripParticipantUserRole_ID:
                  participant.TripParticipantUserRole_ID,
                ParticipantStatus_ID: 2,
              };
              dispatch(
                updateParticipantStatus(
                  JSON.stringify(body),
                  user.User_ID,
                  filterResults,
                  sortedElementMyTrips,
                  sortedByMyTrips,
                ),
              );
            }
            dispatch(
              showSnackBar({
                type: 'success',
                message: t('You successfully accepted the trip invitation'),
              }),
            );
          });
      }
    } else if (action === 'decline') {
      if (data) {
        data &&
          data.participants.forEach(participant => {
            if (participant.TripParticipantUser_ID === user.User_ID) {
              let body = {
                TripParticipant_ID: participant.TripParticipant_ID,
                TripParticipantUserRole_ID:
                  participant.TripParticipantUserRole_ID,
                ParticipantStatus_ID: 3,
              };
              dispatch(
                updateParticipantStatus(
                  JSON.stringify(body),
                  user.User_ID,
                  filterResults,
                  sortedElementMyTrips,
                  sortedByMyTrips,
                ),
              );
              dispatch(
                showSnackBar({
                  type: 'success',
                  message: t('You successfully rejected the trip invitation'),
                }),
              );
            }
          });
      }
    }
  }

  const updateProxies = (action) => {
    if (action === 'accept') {
      if (data) {
        data &&
          data.fullTrip.Proxies.forEach(proxy => {
            if (proxy.TripProxyUser_ID === user.User_ID) {
              let body = {
                TripProxy_ID: proxy.TripProxy_ID,
                TripProxyUserRole_ID:
                  proxy.TripProxyUserRole_ID,
                  TripProxyAcceptanceStatus: 2,
                TripProxyEnableStatus: proxy.TripProxyEnableStatus
              };
              dispatch(
                updateProxyStatus(
                  JSON.stringify(body),
                  user.User_ID,
                  filterResults,
                  sortedElementMyTrips,
                  sortedByMyTrips,
                ),
              );
            }
            dispatch(
              showSnackBar({
                type: 'success',
                message: 'You successfully accepted the trip invitation.',
              }),
            );
          });
      }
    } else if (action === 'decline') {
      if (data) {
        data &&
          data.fullTrip.Proxies.forEach(proxy => {
            if (proxy.TripProxyUser_ID === user.User_ID) {
              let body = {
                TripProxy_ID: proxy.TripProxy_ID,
                TripProxyUserRole_ID:
                  proxy.TripProxyUserRole_ID,
                  TripProxyAcceptanceStatus: 3,
                TripProxyEnableStatus: proxy.TripProxyEnableStatus
              };
              dispatch(
                updateProxyStatus(
                  JSON.stringify(body),
                  user.User_ID,
                  filterResults,
                  sortedElementMyTrips,
                  sortedByMyTrips,
                ),
              );
              dispatch(
                showSnackBar({
                  type: 'success',
                  message: 'You successfully rejected the trip invitation',
                }),
              );
            }
          });
      }
    }
  }

  const updateOrganizer = (action) => {
    if (action === 'accept') {
      if (data) {
        data &&
          data.fullTrip.Organizers.forEach(organizer => {
            if (organizer.TripOrganizerUser_ID === user.User_ID) {
              let body = {
                TripOrganizer_ID: organizer.TripOrganizer_ID,
                TripOrganizerUserRole_ID:
                  organizer.TripOrganizerUserRole_ID,
                TripOrganizerAcceptanceStatus: 2,
                TripOrganizerEnableStatus: organizer.TripOrganizerEnableStatus
              };
              dispatch(
                updateOrganizerStatus(
                  JSON.stringify(body),
                  user.User_ID,
                  filterResults,
                  sortedElementMyTrips,
                  sortedByMyTrips,
                ),
              );
            }
            dispatch(
              showSnackBar({
                type: 'success',
                message: 'You successfully accepted the trip invitation.',
              }),
            );
          });
      }
    } else if (action === 'decline') {
      if (data) {
        data &&
          data.fullTrip.Organizers.forEach(organizer => {
            if (organizer.TripOrganizerUser_ID === user.User_ID) {
              if (organizer.TripOrganizerUser_ID === user.User_ID) {
                let body = {
                  TripOrganizer_ID: organizer.TripOrganizer_ID,
                  TripOrganizerUserRole_ID:
                    organizer.TripOrganizerUserRole_ID,
                  TripOrganizerAcceptanceStatus: 3,
                  TripOrganizerEnableStatus: organizer.TripOrganizerEnableStatus
                };
              dispatch(
                updateOrganizerStatus(
                  JSON.stringify(body),
                  user.User_ID,
                  filterResults,
                  sortedElementMyTrips,
                  sortedByMyTrips,
                ),
              );
              dispatch(
                showSnackBar({
                  type: 'success',
                  message: 'You successfully rejected the trip invitation',
                }),
              );
            }
          }
        });
      }
    }
  }

  const handleClick = action => event => {
    event.stopPropagation();
    updateParticipants(action)
    updateProxies(action)
    updateOrganizer(action)

  };

  const actions = [
    { icon: <CheckIcon />, name: t('Copy to My Trips'), action: 'accept' },
    { icon: <CloseIcon />, name: t('Decline'), action: 'decline' },
  ];

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="right"
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement="top"
            onClick={handleClick(action.action)}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default TripInvitationButton;
