import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    '& > div > div': {
      maxWidth: 1350,
      width: 1050
    }
  },
  modalTopContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 12px 18px -19px rgba(0,0,0,0.75)',
    marginBottom: 15,
    "& > button":{
      marginRight: 5,
    }
  },
  modalContentContainer: {
    minWidth: 650,
    marginBottom: 15,
    paddingBottom: 30,
    boxShadow: '0px 12px 18px -19px rgba(0,0,0,0.75)',
    "& > div": {
      boxShadow: 'none'
    }
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      fontSize: 14,
      width: 35,
      height: 35,
      marginLeft: 10,
    },
  },
  avatarImage: {
    width: '100%',
    height: '100%',
  },
  avatarSize: {
    fontSize: 14,
  },
  tableContainer: {
    maxHeight: 640
  }
}));

export default useStyles;