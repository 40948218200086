import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import {store} from '../../store';

export const saveCurrentTrippetTypeCategory = payload => ({
  type: adminActionTypes.SAVE_CURRENT_TRIPPETTYPECATEGORY,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_TRIPPETTYPECATEGORY_CURRENT_PAGE,
  payload,
});

export const saveRows = payload => ({
  type: adminActionTypes.SAVE_TRIPPETTYPECATEGORY_ROW,
  payload
});

export const openRow = payload => ({
  type: adminActionTypes.OPEN_TRIPPETTYPECATEGORY_ROW,
  payload,
});

export const closeRow = payload => ({
  type: adminActionTypes.CLOSE_TRIPPETTYPECATEGORY_ROW,
  payload,
});

export const getTrippetTypeCategoryById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/trippet/typecategory/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentTrippetTypeCategory(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewTrippetTypeCategory = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/typecategory/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentTrippetTypeCategory(data));
          dispatch(getAllTrippetTypeCategories(null));
          dispatch(getFilterTrips());
        })
        .catch(error => console.log(error));
    }
  };
};


export const createPerTripTrippetTypeCategory = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/typecategory/createpertrip.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getAllTrippetTypeCategories(null));
          dispatch(getFilterTrips());
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeTrippetTypeCategory = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/typecategory/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getTrippetTypeCategoryById(body.TrippetTypeCategory_ID));
          dispatch(getAllTrippetTypeCategories(null));
          dispatch(getFilterTrips());
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllTrippetTypeCategories = payload => ({
  type: adminActionTypes.SAVE_ALL_TRIPPETTYPECATEGORIES,
  payload,
});

export const saveFilterTrip_ID = payload => ({
  type: adminActionTypes.SAVE_TRIPPETTYPECATEGORY_FILTERTRIPID,
  payload,
});

export const saveFilterTrips = payload => ({
  type: adminActionTypes.SAVE_TRIPPETTYPECATEGORY_FILTERTRIPS,
  payload,
});

export const saveTrippetTypeCategorySortElement = payload => ({
  type: adminActionTypes.SAVE_TRIPPETTYPECATEGORY_SORT_ELEMENT,
  payload,
});

export const saveTrippetTypeCategorySortByDirection = payload => ({
  type: adminActionTypes.SAVE_TRIPPETTYPECATEGORY_SORT_BY_DIRECTION,
  payload,
});

export const saveTrippetTypeCategoryPaginationData = payload => ({
  type: adminActionTypes.SAVE_TRIPPETTYPECATEGORY_PAGINATION_DATA,
  payload,
});

export const getAllTrippetTypeCategories = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            Trip_ID: store.getState().trippetTypeCategory.filterTrip_ID,
            RowsPerPage: ROWS_PER_PAGE,
            Page: store.getState().trippetTypeCategory.currentPage,
            OrderBy: 'SortIndex',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/trippet/typecategory/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllTrippetTypeCategories(data.Data));
        dispatch(saveTrippetTypeCategoryPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};


export const getFilterTrips = () => {
  return dispatch => {
    fetch(`${BASE_URL}api/trippet/typecategory/filter/trips.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveFilterTrips(data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteTrippetTypeCategory = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trippet/typecategory/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else
          {
            dispatch(getAllTrippetTypeCategories(null));
            dispatch(getFilterTrips());
          }
        })
        .catch(error => console.log(error));
    }
  };
};
