import React, { useState, useEffect } from "react";
import withDashboard from "../../hoc/withDashboard/withDashboard";
import withTitle from "../../hoc/withTitle";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import {
  changeParticipantsSortingCriteria,
  changeCalendarView,
  changeLanguage,
  changeMapZoom,
  changeCockpitView,
} from "../../store/actions/actions/programSettings";
import { showSnackBar } from "../../store/actions/actions/root";
import { updateUserProfile } from "../../store/actions/actions/profile";

const ProgramSettings = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user.user);
  const defaultProgramSettings = useSelector(state => state.programSettings);
  const [values, setValues] = useState({
    participantsSorting: "lastname",
    language: "en",
    calendarView: "month",
    cockpitView: 1,
    zoom: 5,
  });

  useEffect(() => {
    if (defaultProgramSettings) {
      const {
        participantsSorting,
        calendarView,
        cockpitView,
        zoom,
      } = defaultProgramSettings;
      setValues({
        ...values,
        participantsSorting: participantsSorting,
        calendarView: calendarView,
        cockpitView: cockpitView,
        zoom: zoom,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultProgramSettings]);

  useEffect(() => {
    if (user) {
      if (user.Language_ID === "1") {
        setValues({
          ...values,
          language: "de",
        });
      } else if (user.Language_ID === "2") {
        setValues({
          ...values,
          language: "en",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.Language_ID]);

  const handleInputs = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSaveButton = () => {
    i18n.changeLanguage(values.language);
    dispatch(changeLanguage(values.language));
    dispatch(changeParticipantsSortingCriteria(values.participantsSorting));
    dispatch(changeMapZoom(values.zoom));
    dispatch(changeCockpitView(values.cockpitView));
    dispatch(changeCalendarView(values.calendarView));
    dispatch(
      showSnackBar({
        type: "success",
        message: t("Successfully saved changes"),
      }),
    );
    let body = {
      User_ID: user.User_ID,
      Language_ID: values.language === "en" ? "2" : "1",
    };
    console.log('values.language', values.language);
    const language_ID = values.language === "en" ? "2" : "1";
    localStorage.setItem("language-TP", language_ID);
    dispatch(updateUserProfile(JSON.stringify(body), history, t));
    props.history.push("/");
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.title}
        >
          {t("Program Settings")}
        </Typography>
      </Grid>
      <Grid item xs={7} className={classes.container}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">
            {t("Participants Sorting Criterium")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label={t("Participants Sorting Criterium")}
            placeholder={t("Participants Sorting Criterium")}
            name="participantsSorting"
            value={values.participantsSorting}
            onChange={handleInputs}
          >
            <MenuItem value="lastname">{t("Last Name")}</MenuItem>
            <MenuItem value="firstname">{t("First Name")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7} className={classes.container}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">
            {t("Default Calendar View")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label={t("Default Calendar View")}
            placeholder={t("Default Calendar View")}
            name="calendarView"
            value={values.calendarView}
            onChange={handleInputs}
          >
            <MenuItem value="month">{t("Month")}</MenuItem>
            <MenuItem value="week">{t("Week")}</MenuItem>
            <MenuItem value="day">{t("Day")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7} className={classes.container}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">
            {t("Language")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label={t("Language")}
            placeholder={t("Language")}
            name="language"
            value={values.language}
            onChange={handleInputs}
          >
            <MenuItem value="en">{t("English")}</MenuItem>
            <MenuItem value="de">{t("German")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7} className={classes.container}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">
            {t("Cockpit View")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label={t("Cockpit View")}
            placeholder={t("Cockpit View")}
            name="cockpitView"
            value={values.cockpitView}
            onChange={handleInputs}
          >
            <MenuItem value={1}>{t("Calendar left / Map right")}</MenuItem>
            <MenuItem value={2}>{t("Map left / Calendar right")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7} className={classes.container}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">
            {t("Default Map zoom")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label={t("Default Map zoom")}
            placeholder={t("Default Map zoom")}
            name="zoom"
            value={values.zoom}
            onChange={handleInputs}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={13}>13</MenuItem>
            <MenuItem value={14}>14</MenuItem>
            <MenuItem value={15}>15</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7} container justify="flex-end">
        <Button color="primary" variant="contained" onClick={handleSaveButton}>
          {t("Save Changes")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default withRouter(
  withTitle(withDashboard(ProgramSettings), "Program Settings"),
);
