import React, { useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewSettingItem, changeSettingItem } from '../../store/actions/actions/settingItem';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import {getEntireLanguages} from '../../store/actions/actions/language';
import {getAllSettingCategories} from '../../store/actions/actions/settingCategory';

const SettingItemModal = ({ open, handleSettingItemModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [namesError, setNamesError] = useState(false);
  const [formError, setFormError] = useState(false);
  const entireLanguages = useSelector(state => state.language.entireLanguages);
  const allSettingCategories = useSelector(state => state.settingCategory.allSettingCategories);
  const currentSettingItem = null;// useSelector(state => state.settingItem.currentSettingItem);
  const [values, setValues] = useState({
    SettingCategory_ID: -1,
    DataType: 0,
    Names: [],
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure languages is already loaded
    if (!entireLanguages)
    {
      dispatch(getEntireLanguages(null));
      return;
    }
    if (!allSettingCategories)
    {
      dispatch(getAllSettingCategories(null));
      return;
    }
    if (defaultValues) {
        // when there is a new language, the name for that language in 'Names' is missing
        // so we need to iterate through entireLanguages and push missed ones
        const names = currentSettingItem 
          ? currentSettingItem.Names 
          : defaultValues.Names ? defaultValues.Names : [];
        if (names && Array.isArray(names))
          entireLanguages.forEach(name => {
            var found = false;
            names.forEach(name2 => {
              if (Number(name2.Language_ID) === Number(name.Language_ID))
                found = true;
            });
            if (!found)
              names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
        });
        if (currentSettingItem)
        {
            setValues({
                SettingItem_ID: currentSettingItem.SettingItem_ID,
                SettingCategory_ID: currentSettingItem.SettingCategory_ID,
                DataType: currentSettingItem.DataType,
                Names: names,
            });
        }
        else
        {
            setValues({
                SettingItem_ID: defaultValues.SettingItem_ID,
                SettingCategory_ID: defaultValues.SettingCategory_ID,
                DataType: defaultValues.DataType,
                Names: names,
            });
        }

        handleIsEditMode(defaultValues.SettingItem_ID && defaultValues.SettingItem_ID > 0);
    } else {

      const names = [];
      entireLanguages.forEach(name => {
        names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
      });
      setValues({
        SettingItem_ID: 0,
        SettingCategory_ID: -1,
        DataType: 0,
        Names: names,
      });
      handleIsEditMode(false);
    }
  }, [entireLanguages, allSettingCategories, defaultValues, currentSettingItem, dispatch]);

  const validateForm = ({ Names }) => {
    if (Names && Names.length > 0) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };


  const handleSubmit = e => {
    e.stopPropagation();
    if (!namesError) {
        e.preventDefault();
        const data = {
            SettingItem_ID: +values.SettingItem_ID,
            SettingCategory_ID: values.SettingCategory_ID,
            DataType: values.DataType,
            Names: values.Names,
        };
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changeSettingItem(JSON.stringify(data)));
          handleSettingItemModalClose();
        }
      } else {
        if (validateForm(data)) {
          dispatch(createNewSettingItem(JSON.stringify(data)));
          handleSettingItemModalClose();
        }
      }
    }
  };

  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name.indexOf("Name") === 0) // it is Names list
    {
        const names = [];
        values.Names.forEach(name => {
            if ("Name" + name.Language_ID === e.target.name)
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: e.target.value});
            else
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: name.Name});
        });
        setValues({...values, Names: names});
        setNamesError(names.length === 0);
    }
    else
    {
        setValues({...values, [e.target.name]: e.target.value});
    }
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const renderTitle = defaultValues ? t('Edit Setting Item') : t('New Setting Item');
  const renderNames =
    values.Names && values.Names.length > 0
      ? values.Names.map(name => {
          return (
            <TableRow key={name.Language_ID} className={classes.rowContainer}>
                <TableCell>
                    <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id={"Name" + name.Language_ID}
                    label={name.LanguageAbbreviation}
                    name={"Name" + name.Language_ID}
                    value={name.Name}
                    onChange={handleInputs}
                    />
                </TableCell>
              </TableRow>
          );
        })
      : null;

    const renderCategories =
    allSettingCategories && allSettingCategories.length > 0
      ? allSettingCategories.map(cat => {
          return (
            <MenuItem key={cat.SettingCategory_ID} value={cat.SettingCategory_ID}>{cat.Name}</MenuItem>
          );
        })
      : null;

  const renderCategoryError = namesError
    ? t('Category is required.')
    : null;

  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.settingItemName}>
                <FormControl variant="outlined" className={classes.selectView}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Type')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Category')}
                    name="SettingCategory_ID"
                    autoFocus
                    value={values.SettingCategory_ID}
                    onChange={handleInputs}
                  >
                    {renderCategories}
                  </Select>
                </FormControl>
                <FormHelperText className={classes.errorText}>
                  {renderCategoryError}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={6} className={classes.settingItemName}>
                <FormControl variant="outlined" className={classes.selectView}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Data Type')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Data Type')}
                    name="DataType"
                    value={values.DataType}
                    onChange={handleInputs}
                  >
                    <MenuItem value="1">{t('Text')}</MenuItem>
                    <MenuItem value="2">{t('Number')}</MenuItem>
                    <MenuItem value="3">{t('Date')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>

              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  className={classes.title}
                >
                  {t('Languages')}
                </Typography>
              </Grid>
            </Grid>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>

                    </TableRow>
                </TableHead>
                <TableBody>{renderNames}</TableBody>
                </Table>
            </TableContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            autoFocus
            variant="contained"
            onClick={handleSubmit}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleSettingItemModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default SettingItemModal;
