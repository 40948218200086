import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { columns } from "./columns";
import useStyles from "./styles";
import UserButton from "./UserButton";
import { getUserById } from "../../store/actions/actions/user";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import DeleteButton from "@material-ui/icons/Clear";
import * as userAccess from "../../userAccess";
import UserModal from "../../components/UserForm/UserForm";

const createData = (
  User_ID,
  FirstName,
  LastName,
  Email,
  DisplayName,
  Gender,
  HomeAddress,
  PhoneNumber,
  Country_ID,
  BaseCurrency_ID,
  BirthDate,
  AdminRole_ID,
  Language_ID,
  Department_ID,
  TimeZone,
  TimeZoneDesc,
  Status,
  Picture,
  CountryName,
  BaseCurrencyName,
  DepartmentName,
  AdminRoleName,
) => {
  return {
    User_ID,
    FirstName,
    LastName,
    Email,
    DisplayName,
    Gender,
    HomeAddress,
    PhoneNumber,
    Country_ID,
    BaseCurrency_ID,
    BirthDate,
    AdminRole_ID,
    Language_ID,
    Department_ID,
    TimeZone,
    TimeZoneDesc,
    Status,
    Picture,
    CountryName,
    BaseCurrencyName,
    DepartmentName,
    AdminRoleName,
  };
};

const UserTable = ({
  data,
  handleConfirmDeleteModalOpen,
  handleConfirmForceDeleteModalOpen,
  setDeletedUser,
  setForceDeletedUser,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedUser, setClickedUser] = useState([]);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(
    useSelector(state => state.user.user),
    userAccess.Table_Users,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.User_ID,
            item.FirstName,
            item.LastName,
            item.Email,
            item.DisplayName,
            item.Gender,
            item.HomeAddress,
            item.PhoneNumber,
            item.Country_ID,
            item.BaseCurrency_ID,
            item.BirthDate,
            item.AdminRole_ID,
            item.Language_ID,
            item.Department_ID,
            item.TimeZone,
            item.TimeZoneDesc,
            item.Status,
            item.Picture,
            item.CountryName,
            item.BaseCurrencyName,
            item.DepartmentName,
            item.AdminRoleName,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedUser = value => {
    setClickedUser(value);
  };

  const handleUserModalOpen = () => {
    setIsUserModalOpen(true);
  };

  const handleUserModalClose = () => {
    setIsUserModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === "actions") {
      return (
        <UserButton
          data={row}
          handleClickedUser={handleClickedUser}
          handleUserModalOpen={handleUserModalOpen}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          handleConfirmForceDeleteModalOpen={handleConfirmForceDeleteModalOpen}
          setDeletedUser={setDeletedUser}
          setForceDeletedUser={setForceDeletedUser}
          disabledDelete={disableDelete}
        />
      );
    } else if (column.id === "Status" && value) {
      if (Number(value) === 1) return "Enabled";
      if (Number(value) === 2) return "Disabled";
      if (Number(value) === 3) return <DeleteButton />;
      return value;
    } else if (column.id === "Picture" && value) {
      return <img src={value} className={classes.avatar} alt="Icon" />;
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    dispatch(getUserById(value.User_ID));
    let recordBody = {
      User_ID: value.User_ID,
      FirstName: value.FirstName,
      LastName: value.LastName,
      Email: value.Email,
      DisplayName: value.DisplayName,
      Gender: value.Gender,
      HomeAddress: value.HomeAddress,
      PhoneNumber: value.PhoneNumber,
      Country_ID: value.Country_ID,
      BaseCurrency_ID: value.BaseCurrency_ID,
      BirthDate: value.BirthDate,
      AdminRole_ID: value.AdminRole_ID,
      Language_ID: value.Language_ID,
      Department_ID: value.Department_ID,
      TimeZone: value.TimeZone,
      TimeZoneDesc: value.TimeZoneDesc,
      Status: value.Status,
      Picture: value.Picture,
      CountryName: value.CountryName,
      BaseCurrencyName: value.BaseCurrencyName,
      DepartmentName: value.DepartmentName,
      AdminRoleName: value.AdminRoleName,
    };
    handleClickedUser(recordBody);
    handleUserModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.User_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0
                    ? classNames(classes.tableCell, classes[row.color])
                    : classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== "actions") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id ? sortedBy : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <UserModal
        open={isUserModalOpen}
        handleUserModalClose={handleUserModalClose}
        defaultValues={clickedUser}
      />
    </Paper>
  );
};

export default UserTable;
