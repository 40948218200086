import React, { useState, useEffect } from 'react';
import withDashboard from '../../hoc/withDashboard/withDashboard';
import withTitle from '../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import useStyle from './styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import UploadPicture from './UploadPicture';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BASE_URL } from '../../config';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  updateUserProfile,
  deleteUser,
} from '../../store/actions/actions/profile';
import { useHistory } from 'react-router-dom';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import FormHelperText from '@material-ui/core/FormHelperText';

const Profile = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const profileImage = useSelector(state => state.profile.profilePictureURL);
  const user = useSelector(state => state.user.user);
  const language = useSelector(state => state.programSettings.language);
  const departments = useSelector(state => state.app.departments);
  const countries = useSelector(state => state.app.countries);
  const currencies = useSelector(state => state.app.currencies);
  const [dateLanguage, setLanguage] = useState('');
  const [allDepartments, setAllDepartments] = useState(null);
  const [allCountries, setAllCountries] = useState(null);
  const [allCurrencies, setAllCurrencies] = useState(null);
  const [wrongEmailFormat, setWrongEmailFormat] = useState(false);
  const [values, setValues] = useState({
    pictureUrl: '',
    pictureFile: '',
    FirstName: '',
    LastName: '',
    Email: '',
    Gender: '',
    HomeAddress: '',
    PhoneNumber: '',
    dob: null,
    Department_ID: '',
    Country_ID: '',
    BaseCurrency_ID: '',
    DisplayName: '',
  });

  useEffect(() => {
    if (language === 'en') {
      setLanguage('');
    } else if (language === 'de') {
      setLanguage(deLocale);
    }
  }, [language]);

  useEffect(() => {
    if (departments) {
      setAllDepartments(departments);
    }
  }, [departments]);

  useEffect(() => {
    if (countries) {
      setAllCountries(countries);
    }
  }, [countries]);

  useEffect(() => {
    if (currencies) {
      setAllCurrencies(currencies);
    }
  }, [currencies]);

  useEffect(() => {
    if (profileImage) {
      setValues({
        ...values,
        pictureUrl: `${BASE_URL}${profileImage}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImage]);

  useEffect(() => {
    if (user) {
      if (user.BirthDate) {
        setValues({
          ...values,
          FirstName: user.FirstName,
          LastName: user.LastName,
          Email: user.Email,
          Gender: user.Gender,
          HomeAddress: user.HomeAddress,
          PhoneNumber: user.PhoneNumber,
          Picture: values.pictureUrl,
          Department_ID: user.Department_ID === null ? '' : user.Department_ID,
          Country_ID: user.Country_ID === null ? '' : user.Country_ID,
          BaseCurrency_ID:
            user.BaseCurrency_ID === null ? '' : user.BaseCurrency_ID,
          pictureUrl: user.Picture ? user.Picture : '',
          dob: user.BirthDate ? new Date(user.BirthDate) : null,
          DisplayName: user.DisplayName,
        });
      } else {
        setValues({
          ...values,
          FirstName: user.FirstName,
          LastName: user.LastName,
          Email: user.Email,
          Gender: user.Gender,
          HomeAddress: user.HomeAddress,
          PhoneNumber: user.PhoneNumber,
          Department_ID: user.Department_ID === null ? '' : user.Department_ID,
          Country_ID: user.Country_ID === null ? '' : user.Country_ID,
          BaseCurrency_ID:
            user.BaseCurrency_ID === null ? '' : user.BaseCurrency_ID,
          Picture: values.pictureUrl,
          pictureUrl: user.Picture ? user.Picture : '',
          dob: null,
          DisplayName: user.DisplayName,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleDateChange = event => {
    setValues({
      ...values,
      dob: event,
    });
  };

  const handleInputs = event => {
    if (typeof event === 'string') {
      setValues({
        ...values,
        PhoneNumber: event,
      });
    } else {
      if (event.target.name === 'Email') {
        if (
          new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
            event.target.value,
          )
        ) {
          setWrongEmailFormat(false);
          setValues({
            ...values,
            [event.target.name]: event.target.value,
          });
        } else {
          setWrongEmailFormat(true);
          setValues({
            ...values,
            [event.target.name]: event.target.value,
          });
        }
      } else {
        setValues({
          ...values,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  const handleDeleteUser = () => {
    const body = {
      User_ID: user.User_ID,
    };
    dispatch(deleteUser(JSON.stringify(body), history, t));
  };

  const handleSaveChanges = () => {
    if (!wrongEmailFormat) {
      const body = {
        FirstName: values.FirstName,
        LastName: values.LastName,
        Email: values.Email,
        Gender: values.Gender,
        HomeAddress: values.HomeAddress,
        PhoneNumber: values.PhoneNumber,
        Picture: values.pictureUrl,
        User_ID: user.User_ID,
        Department_ID: values.Department_ID,
        Country_ID: values.Country_ID,
        BaseCurrency_ID: values.BaseCurrency_ID,
        BirthDate: values.dob ? format(values.dob, 'yyyy-MM-dd') : '',
        DisplayName: values.DisplayName,
      };
      dispatch(updateUserProfile(JSON.stringify(body), history, t));
    }
  };

  const renderDepartments =
    allDepartments && allDepartments.length > 0 ? (
      allDepartments.map(dep => (
        <MenuItem key={dep.Department_ID} value={dep.Department_ID}>
          {dep.Name}
        </MenuItem>
      ))
    ) : (
      <MenuItem value="">{t('Department')}</MenuItem>
    );

  const renderCountries =
    allCountries && allCountries.length > 0 ? (
      allCountries.map(country => (
        <MenuItem key={country.Country_ID} value={country.Country_ID}>
          {country.Name}
        </MenuItem>
      ))
    ) : (
      <MenuItem value="">{t('Country')}</MenuItem>
    );
  const renderCurrencies =
    allCurrencies && allCurrencies.length > 0 ? (
      allCurrencies.map(currency => (
        <MenuItem key={currency.Currency_ID} value={currency.Currency_ID}>
          {`${currency.Name} (${currency.Code})`}
        </MenuItem>
      ))
    ) : (
      <MenuItem value="">{t('Base Currency')}</MenuItem>
    );

  return (
    <Grid container justify="center">
      <Grid item xs={12} className={classes.backToCockpit}>
        <Link to="/">
          <KeyboardBackspaceIcon />
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            className={classes.title}
            id={'Invitations'}
          >
            {t('Back to Cockpit')}
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          className={classes.title}
        >
          {t('Profile')}
        </Typography>
      </Grid>
      <Grid item container xs={12} className={classes.profilePageContainer}>
        <Grid item xs={12} className={classes.marginBottom}>
          <UploadPicture t={t} values={values} setValues={setValues} />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            autoComplete="fname"
            name="FirstName"
            variant="outlined"
            fullWidth
            id="firstName"
            label={t('First Name')}
            autoFocus
            value={values.FirstName}
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            autoComplete="fname"
            name="LastName"
            variant="outlined"
            fullWidth
            id="lastName"
            label={t('Last Name')}
            autoFocus
            value={values.LastName}
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            autoComplete="dname"
            name="DisplayName"
            variant="outlined"
            fullWidth
            id="lastName"
            label={t('Display Name')}
            autoFocus
            value={values.DisplayName}
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            autoComplete="fname"
            name="Email"
            variant="outlined"
            fullWidth
            id="email"
            type="email"
            label={t('Email')}
            autoFocus
            value={values.Email}
            onChange={handleInputs}
          />
          {wrongEmailFormat && (
            <FormHelperText className={classes.error}>
              {t('Wrong Email Format')}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              {t('Gender')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label={t('Gender')}
              placeholder={t('Gender')}
              name="Gender"
              value={values.Gender}
              onChange={handleInputs}
            >
              <MenuItem value={'0'}>{t('Male')}</MenuItem>
              <MenuItem value={'1'}>{t('Female')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <PhoneInput
            country={'de'}
            value={values.PhoneNumber}
            onChange={handleInputs}
            name="PhoneNumber"
            containerClass={classes.phoneInput}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="fname"
            name="HomeAddress"
            variant="outlined"
            fullWidth
            id="homeAddress"
            label={t('Home Address')}
            autoFocus
            value={values.HomeAddress}
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateLanguage}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label={t('Date of Birth')}
              format="dd/MM/yyyy"
              value={values.dob}
              onChange={handleDateChange}
              inputVariant="outlined"
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              {t('Department')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label={t('Department')}
              placeholder={t('Department')}
              name="Department_ID"
              value={values.Department_ID}
              onChange={handleInputs}
            >
              <MenuItem value="">{t('Department')}</MenuItem>
              {renderDepartments}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              {t('Country')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label={t('Country')}
              placeholder={t('Country')}
              name="Country_ID"
              value={values.Country_ID}
              onChange={handleInputs}
            >
              <MenuItem value="">{t('Country')}</MenuItem>
              {renderCountries}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              {t('Base Currency')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label={t('Base Currency')}
              placeholder={t('Base Currency')}
              name="BaseCurrency_ID"
              value={values.BaseCurrency_ID}
              onChange={handleInputs}
            >
              <MenuItem value="">{t('Base Currency')}</MenuItem>
              {renderCurrencies}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSaveChanges}
            >
              {t('Save Changes')}
            </Button>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDeleteUser}
            >
              {t('Delete Account')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTitle(withDashboard(Profile), 'Profile');
