import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { columns } from "./columns";
import useStyles from "./styles";
import UserRoleAllowButton from "./UserRoleAllowButton";
import { getUserRoleAllowById } from "../../store/actions/actions/userRoleAllow";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import * as userAccess from "../../userAccess";
import UserRoleAllowModal from "../../components/UserRole/UserRoleAllowForm";

const createData = (
  UserRoleAllow_ID,
  GiverUserRole_ID,
  TakerUserRole_ID,
  TakerUserRoleName,
) => {
  return {
    UserRoleAllow_ID,
    GiverUserRole_ID,
    TakerUserRole_ID,
    TakerUserRoleName,
  };
};

const UserRoleAllowTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedUserRoleAllow,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedUserRoleAllow, setClickedUserRoleAllow] = useState([]);
  const [isUserRoleAllowModalOpen, setIsUserRoleAllowModalOpen] = useState(
    false,
  );
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(
    useSelector(state => state.user.user),
    userAccess.Table_UserRoles,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.UserRoleAllow_ID,
            item.GiverUserRole_ID,
            item.TakerUserRole_ID,
            item.TakerUserRoleName,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedUserRoleAllow = value => {
    setClickedUserRoleAllow(value);
  };

  const handleUserRoleAllowModalOpen = () => {
    setIsUserRoleAllowModalOpen(true);
  };

  const handleUserRoleAllowModalClose = () => {
    setIsUserRoleAllowModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === "actions") {
      return (
        <UserRoleAllowButton
          data={row}
          handleClickedUserRoleAllow={handleClickedUserRoleAllow}
          handleUserRoleAllowModalOpen={handleUserRoleAllowModalOpen}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedUserRoleAllow={setDeletedUserRoleAllow}
          disabledDelete={disableDelete}
        />
      );
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    dispatch(getUserRoleAllowById(value.UserRoleAllow_ID));
    let recordBody = {
      UserRoleAllow_ID: value.UserRoleAllow_ID,
      GiverUserRole_ID: value.GiverUserRole_ID,
      TakerUserRole_ID: value.TakerUserRole_ID,
    };
    handleClickedUserRoleAllow(recordBody);
    handleUserRoleAllowModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.UserRoleAllow_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName = classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== "actions") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id ? sortedBy : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <UserRoleAllowModal
        open={isUserRoleAllowModalOpen}
        handleUserRoleAllowModalClose={handleUserRoleAllowModalClose}
        defaultValues={clickedUserRoleAllow}
      />
    </Paper>
  );
};

export default UserRoleAllowTable;
