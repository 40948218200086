import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Avatar from "@material-ui/core/Avatar";
import TableRow from "@material-ui/core/TableRow";
import { columns } from "./RecommendationColumns";
import { columnsTwo } from "./RecommendationColumnsTwo";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import deLocale from "date-fns/locale/de";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import TrippetRecommendationButton from "./TrippetRecommendationButton";
import uniqid from "uniqid";
import Tooltip from "@material-ui/core/Tooltip";

const createData = (
  title,
  tripTitle,
  startDate,
  startTime,
  endDate,
  endTime,
  from,
  to,
  sender,
  participants,
  status,
  lastChanged,
  fullTrippet,
) => {
  return {
    title,
    tripTitle,
    startDate,
    startTime,
    endDate,
    endTime,
    from,
    to,
    sender,
    participants,
    status,
    lastChanged,
    fullTrippet,
  };
};

const RecommendationTable = ({
  trippets,
  isOpenTrippetFrom,
  type,
  allUsers,
  recommendationType,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dateLanguage, setLanguage] = useState("");
  const language = useSelector(state => state.programSettings.language);
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const [usedColumns, setUsedColumns] = useState([]);

  useEffect(() => {
    if (language === "en") {
      setLanguage("");
    } else if (language === "de") {
      setLanguage(deLocale);
    }
  }, [language]);

  useEffect(() => {
    if (recommendationType === "sent") {
      setUsedColumns(columnsTwo);
    } else {
      setUsedColumns(columns);
    }
  }, [recommendationType]);

  const findUserInfo = participant => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      allUsers.forEach(user => {
        if (participant.TrippetParticipantUser_ID === user.User_ID) {
          data = renderUserInfo(user);
        }
      });
      return data;
    }
  };

  const renderUserInfo = participant => {
    if (participant.Picture) {
      return (
        <img
          src={participant.Picture}
          alt={participant.TrippetParticipantInitials}
          className={classes.avatarImage}
        />
      );
    } else {
      return `${participant.Initials}`;
    }
  };

  const renderParticipantsStatusColor = status => {
    if (status === "1" || status === null || status === "0") {
      return classes.pending;
    } else if (status === "2") {
      return classes.accepted;
    } else if (status === "3") {
      return classes.rejected;
    }
  };

  const renderParticipants = value => {
    return (
      value &&
      value.map(participant => (
        <Tooltip
          title={participant.TrippetParticipantDisplayName}
          placement="top"
          key={participant.TrippetParticipantUser_ID}
          arrow
        >
          <Avatar
            className={renderParticipantsStatusColor(
              participant.ParticipantStatus_ID,
            )}
          >
            {findUserInfo(participant)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const findOwner = value =>
    allUsers && allUsers.find(user => user.User_ID === value);

  const getRecommendationStatusName = status => {
    if (status === "1") {
      return "Recommended";
    } else if (status === "2") {
      return "Accepted";
    } else if (status === "3") {
      return "Rejected";
    }
  };

  const converToLocalTime = date => {
    let newDate = date.split("");
    newDate.push("Z");
    let localTime = new Date(newDate.join(""));
    return format(new Date(localTime), "dd.MM.yyyy HH:mm");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (trippets && trippets.length) {
      trippets.forEach(trippet => {
        elements.push(
          createData(
            trippet.TrippetTitle,
            trippet.TripName,
            format(new Date(trippet.TrippetStartDate), "EEEEEE.dd MMM", {
              locale: dateLanguage,
            }),
            format(new Date(trippet.TrippetStartDate), "HH:mm"),
            format(new Date(trippet.TrippetEndDate), "EEEEEE.dd MMM", {
              locale: dateLanguage,
            }),
            format(new Date(trippet.TrippetEndDate), "HH:mm"),
            trippet.TrippetFromPlace,
            trippet.TrippetToPlace,
            trippet.TrippetRecommendationSenderUser_ID,
            trippet.Participants,
            getRecommendationStatusName(
              trippet.TrippetRecommendationAcceptanceStatus,
            ),
            converToLocalTime(trippet.ModifiedDate),
            trippet,
          ),
        );
      });
      setRows(elements);
    }
  };

  useEffect(() => {
    if (trippets && trippets.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trippets, trippetTypes]);

  const renderData = (column, value, row) => {
    if (column.id === "participants") {
      return (
        <AvatarGroup max={2} className={classes.avatarGroup}>
          {renderParticipants(value)}
        </AvatarGroup>
      );
    } else if (column.id === "actions") {
      if (type === "recommendations") {
        return (
          <TrippetRecommendationButton
            data={row}
            isOpenTrippetFrom={isOpenTrippetFrom}
          />
        );
      }
    } else if (column.id === "sender") {
      let user = findOwner(value);
      if (user) {
        if (user.Picture) {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>
                <img
                  src={user.Picture}
                  alt={user.Initials}
                  className={classes.avatarImage}
                />
              </Avatar>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatar}>{`${user.Initials}`}</Avatar>
            </Tooltip>
          );
        }
      }
    } else {
      return value;
    }
  };

  const handleRowClick = rowData => () => {
    //
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={uniqid()}
              onClick={handleRowClick(row)}
              className={classes.rowContainer}
            >
              {usedColumns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0 ? `5px solid #${row.color}` : classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={classes.tableCell}
                    style={{ borderLeft: styleName }}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {usedColumns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableHeaderCell}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RecommendationTable;
