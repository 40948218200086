import * as actionTypes from '../actionTypes/actionTypes';
import { BASE_URL } from '../../../config';

export const hideSnackbar = () => ({
  type: actionTypes.HIDE_SNACKBAR,
});

export const showSnackBar = payload => ({
  type: actionTypes.SHOW_SNACKBAR,
  payload,
});

export const saveGoogleObject = payload => ({
  type: actionTypes.SAVE_GOOGLE_OBJECT,
  payload,
});

export const onLogout = () => ({
  type: actionTypes.LOGOUT,
});
export const showBuddyTable = () => ({
  type: actionTypes.SHOW_BUDDY_TABLE,
});

export const hideBuddyTable = () => ({
  type: actionTypes.HIDE_BUDDY_TABLE,
});

export const showPermissionTable = () => ({
  type: actionTypes.SHOW_PERMISSION_TABLE,
});

export const hidePermissionTable = () => ({
  type: actionTypes.HIDE_PERMISSION_TABLE,
});

export const getAllUsers = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/getall.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveAllUsers(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllUsers = payload => ({
  type: actionTypes.SAVE_ALL_USERS,
  payload,
});

export const saveAllUsersRoles = payload => ({
  type: actionTypes.SAVE_ALL_USER_ROLES,
  payload,
});

export const getUserRoles = () => {
  return dispatch => {
    fetch(`${BASE_URL}api/userrole/getall.php`, {
      method: 'GET',
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllUsersRoles(data));
      })
      .catch(error => console.log(error));
  };
};

export const saveDepartments = payload => ({
  type: actionTypes.SAVE_DEPARTMENTS,
  payload,
});

export const saveAllCountires = payload => ({
  type: actionTypes.SAVE_ALL_COUNTIRES,
  payload,
});

export const saveAllCurrencies = payload => ({
  type: actionTypes.SAVE_ALL_CURRENCIES,
  payload,
});

export const getUserDepartments = () => {
  return dispatch => {
    fetch(`${BASE_URL}api/department/getall.php`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveDepartments(data));
      })
      .catch(error => console.log(error));
  };
};

export const getAllCountries = () => {
  return dispatch => {
    fetch(`${BASE_URL}api/country/getall.php?language_id=2`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllCountires(data));
      })
      .catch(error => console.log(error));
  };
};

export const getAllCurrencies = () => {
  return dispatch => {
    fetch(`${BASE_URL}api/currency/getall.php?language_id=2`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllCurrencies(data));
      })
      .catch(error => console.log(error));
  };
};

export const clearCustomerLogo = payload => ({
  type: actionTypes.CLEAR_CUSTOMER_LOGO,
  payload,
});

export const saveCustomerLogo = payload => ({
  type: actionTypes.SAVE_CUSTOMER_LOGO,
  payload,
});

export const getCustomerLogo = () => {
  return dispatch => {
    fetch(`${BASE_URL}api/config/get.php?id=1`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveCustomerLogo(data));
      })
      .catch(error => console.log(error));
  };
};
