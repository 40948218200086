export const columns = [
  { id: "actions", label: "", minWidth: 60, type: "actions" },
  {
    id: "type",
    label: "Type",
    minWidth: 110,
    type: "TrippetTypeLookName",
    filter: "typelooks",
  },
  { id: "title", label: "Title", minWidth: 80, type: "Title" },
  // {
  //   id: 'startDate',
  //   label: 'Start Date',
  //   minWidth: 80,
  //   type: 'StartDate',
  //   filter: 'startdates',
  // },
  // {
  //   id: 'startTime',
  //   label: 'Time',
  //   minWidth: 80,
  //   type: 'StartDate',
  // },
  // {
  //   id: 'endDate',
  //   label: 'End Date',
  //   minWidth: 80,
  //   type: 'EndDate',
  //   filter: 'enddates',
  // },
  // {
  //   id: 'endTime',
  //   label: 'Time',
  //   minWidth: 80,
  //   type: 'EndDate',
  // },
  {
    id: "trippetOwner",
    label: "Creator",
    minWidth: 110,
    type: "OwnerUser_ID",
    filter: "owners",
    align: "left",
  },
  {
    id: "responsible",
    label: "Responsible",
    minWidth: 110,
    type: "DisplayName",
    filter: "organizers",
  },
  {
    id: "participants",
    label: "Participants",
    minWidth: 110,
    type: "DisplayName",
    filter: "participants",
  },
  // {
  //   id: 'status',
  //   label: 'Status',
  //   minWidth: 110,
  //   type: 'OwnerPlanningStatus_ID',
  //   filter: 'ownerplanningstatuses',
  //   align: 'center',
  // },
  {
    id: "isPrivate",
    label: "Private",
    minWidth: 80,
    type: "IsPrivate",
    filter: "isprivates",
    align: "center",
  },
  {
    id: "link",
    label: "Link",
    minWidth: 80,
    type: "Link",
  },
  {
    id: "image",
    label: "Image",
    minWidth: 120,
    align: "center",
    type: "DefaultPicture",
  },
  {
    id: "price",
    label: "Price",
    minWidth: 80,
    type: "Price",
    align: "center",
  },
  {
    id: "children",
    minWidth: 80,
    align: "center",
  },
  // {
  //   id: "day",
  //   label: "Day",
  //   minWidth: 80,
  //   type: "StartDay",
  //   filter: "startdays",
  // },
  // {
  //   id: "price",
  //   label: "Price",
  //   minWidth: 80,
  //   type: "Price",
  // },
];

export const childrenColumns = [
  { id: "actions", label: "", minWidth: 60, type: "actions" },
  {
    id: "type",
    label: "Type",
    minWidth: 110,
    type: "TrippetTypeLookName",
    filter: "typelooks",
  },
  { id: "title", label: "Title", minWidth: 80, type: "Title" },
  // {
  //   id: 'startDate',
  //   label: 'Start Date',
  //   minWidth: 80,
  //   type: 'StartDate',
  //   filter: 'startdates',
  // },
  // {
  //   id: 'startTime',
  //   label: 'Time',
  //   minWidth: 80,
  //   type: 'StartDate',
  // },
  // {
  //   id: 'endDate',
  //   label: 'End Date',
  //   minWidth: 80,
  //   type: 'EndDate',
  //   filter: 'enddates',
  // },
  // {
  //   id: 'endTime',
  //   label: 'Time',
  //   minWidth: 80,
  //   type: 'EndDate',
  // },
  {
    id: "trippetOwner",
    label: "Creator",
    minWidth: 110,
    type: "OwnerUser_ID",
    filter: "owners",
    align: "left",
  },
  {
    id: "responsible",
    label: "Responsible",
    minWidth: 110,
    type: "DisplayName",
    filter: "organizers",
  },
  {
    id: "participants",
    label: "Participants",
    minWidth: 110,
    type: "DisplayName",
    filter: "participants",
  },
  // {
  //   id: 'status',
  //   label: 'Status',
  //   minWidth: 110,
  //   type: 'OwnerPlanningStatus_ID',
  //   filter: 'ownerplanningstatuses',
  //   align: 'center',
  // },
  {
    id: "isPrivate",
    label: "Private",
    minWidth: 80,
    type: "IsPrivate",
    filter: "isprivates",
    align: "center",
  },
  {
    id: "link",
    label: "Link",
    minWidth: 80,
    type: "Link",
  },
  {
    id: "image",
    label: "Image",
    minWidth: 120,
    align: "center",
    type: "DefaultPicture",
  },
  {
    id: "price",
    label: "Price",
    minWidth: 80,
    type: "Price",
    align: "center",
  },
  {
    id: 'dnd',
    minWidth: 80,
    align: 'center',
  },
];
