import React, { useState } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PublicIcon from '@material-ui/icons/Public';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import MapSharpIcon from '@material-ui/icons/MapSharp';
import LanguageIcon from '@material-ui/icons/Language';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SettingsIcon from '@material-ui/icons/Settings';
import Badge from '@material-ui/core/Badge';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  saveTripUsedFilters,
} from '../../store/actions/actions/trip';
import {
  saveTrippetUsedFilters,
} from '../../store/actions/actions/trippet';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TripModal from '../TripForm/TripForm';

const Navigation = props => {
  const { open, handleDrawerClose, handleDrawerOpen } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.user.user);
  const tripInvitation = useSelector(state => state.notification.tripInvitation);
  const trippetInvitation = useSelector(state => state.notification.trippetInvitation);
  const [isTripModalOpen, setIsTripModalOpen] = useState(false);
  const handleNavigation = open ? (
    <div className={classes.navigationButton}>
      <IconButton onClick={handleDrawerClose}>
        <ChevronLeftIcon />
      </IconButton>
    </div>
  ) : (
      <div className={classes.navigationButton}>
        <IconButton onClick={handleDrawerOpen}>
          <ChevronRightIcon />
        </IconButton>
      </div>
    );

  const handleMyTrips = () => {
    if (user) {
      // const recivedInvitation = {
      //   Language_ID: user.Language_ID,
      //   Type: 3,
      //   User_ID: user.User_ID,
      //   ParticipantStatus_ID: 1,
      //   IsDeleted: 0,
      //   OrderBy: 'ModifiedDate',
      //   OrderDir: 'desc',
      // };
      // const sentInvitation = {
      //   Language_ID: user.Language_ID,
      //   Type: 4,
      //   User_ID: user.User_ID,
      //   ParticipantStatus_ID: 1,
      //   IsDeleted: 0,
      //   OrderBy: 'ModifiedDate',
      //   OrderDir: 'desc',
      // };
      dispatch(saveTrippetUsedFilters(null));
      dispatch(saveTripUsedFilters(null));
      // dispatch(getAllReceivedInvitation(JSON.stringify(recivedInvitation)));
      // dispatch(getAllSentInvitation(JSON.stringify(sentInvitation)));
      history.push('/my-trips');
    }
  };

  const handleMyTrippets = () => {
    history.push('/my-trippets');
    dispatch(saveTrippetUsedFilters(null));
    dispatch(saveTripUsedFilters(null));
  };


  const handleTripModalOpen = () => {
    setIsTripModalOpen(true);
  };

  const handleAdmin = () => {
    history.push('/languages');
  };

  const handleTripModalClose = () => {
    setIsTripModalOpen(false);
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar} />

      <Divider />
      <List>
        <Fab
          variant="extended"
          className={classNames(classes.addTripButtonNavigation, {[classes.addTripButtonNavigationClosed]: !open})}
          onClick={handleTripModalOpen}
        >
          <AddIcon className={classes.extendedIcon} />
          <span className={classNames(classes.addTripButtonText, {[classes.addTripButtonTextClosed]: !open})}>{t('Create New Trip')}
          </span>
        </Fab>
        <ListItem button disabled>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary={t('TRIPS')} />
        </ListItem>
        <ListItem button onClick={handleMyTrips}>
          <ListItemIcon>
            <Badge badgeContent={0} color="secondary">
              <BeachAccessIcon className={classes.blueIcon} />
            </Badge>
          </ListItemIcon>
          <ListItemText
            primary={t('My Trips')}
            className={classes.myTripsTitle}
          />
        </ListItem>
        <ListItem button onClick={handleMyTrips}>
          <ListItemIcon>
            <Badge badgeContent={+tripInvitation || 0} color="secondary">
              <PublicIcon className={classes.blueIcon} />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={t('Invitations')} />
        </ListItem>
        <ListItem button onClick={handleMyTrips}>
          <ListItemIcon>
            <Badge badgeContent={0} color="secondary">
              <ThumbUpAltOutlinedIcon className={classes.blueIcon} />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={t('Recommendations')} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button disabled>
          <ListItemIcon>
            <BlurCircularIcon />
          </ListItemIcon>
          <ListItemText primary={t('TRIPPETS')} />
        </ListItem>
        <ListItem button onClick={handleMyTrippets}>
          <ListItemIcon>
            <Badge badgeContent={0} color="secondary">
              <AccountTreeIcon className={classes.blueIcon} />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={t('My Trippets')} />
        </ListItem>
        <ListItem button onClick={handleMyTrippets}>
          <ListItemIcon>
            <Badge badgeContent={+trippetInvitation || 0} color="secondary">
              <MapSharpIcon className={classes.blueIcon} />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={t('Invitations')} />
        </ListItem>
        <ListItem button onClick={handleMyTrippets}>
          <ListItemIcon>
            <Badge badgeContent={0} color="secondary">
              <ThumbUpAltOutlinedIcon className={classes.blueIcon} />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={t('Recommendations')} />
        </ListItem>
        <Divider />
        <ListItem button onClick={handleAdmin}>
          <ListItemIcon>
            <Badge badgeContent={0} color="secondary">
              <SettingsIcon className={classes.blueIcon} />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={t('Admin')} />
        </ListItem>
        <Divider />
        <ListItem className={classes.navigationArrow}>
          {handleNavigation}
        </ListItem>
      </List>
      <TripModal
        open={isTripModalOpen}
        handleTripModalClose={handleTripModalClose}
      />
    </Drawer>
  );
};

export default Navigation;
