import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './languages/en.json';
import translationDE from './languages/ge.json';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};
const language_ID = localStorage.getItem("language-TP");
const defaultLanguage = language_ID === '1' ? 'de' : 'en';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage ? defaultLanguage : 'en',
    fallbackLng: defaultLanguage ? defaultLanguage : 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
