export const columns = [
  { id: 'actions', label: '', minWidth: 60, type: 'actions' },
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    type: 'TrippetTypeLookName',
    filter: 'typelooks',
  },
  { id: 'title', label: 'Title', minWidth: 80, type: 'Title' },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 80,
    type: 'StartDate',
    filter: 'startdates',
  },
  {
    id: 'startTime',
    label: 'Time',
    minWidth: 80,
    type: 'StartDate',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 80,
    type: 'EndDate',
    filter: 'enddates',
  },
  {
    id: 'endTime',
    label: 'Time',
    minWidth: 80,
    type: 'EndDate',
  },
  {
    id: 'image',
    label: 'Image',
    minWidth: 120,
    align: 'center'
  },
  {
    id: 'documents',
    label: 'Number of docs',
    minWidth: 80,
    align: 'center'
  },
];
