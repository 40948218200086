import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import {store} from '../../store';

export const saveCurrentPreferenceItem = payload => ({
  type: adminActionTypes.SAVE_CURRENT_PREFERENCEITEM,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_PREFERENCEITEM_CURRENT_PAGE,
  payload,
});

export const getPreferenceItemById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/preference/item/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentPreferenceItem(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewPreferenceItem = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/preference/item/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503 && response.type !== 'cors') {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentPreferenceItem(data));
          
          // just the items of my category
          const searchBody = {
              PreferenceCategory_ID: data.PreferenceCategory_ID,
              RowsPerPage: ROWS_PER_PAGE,
              Page: store.getState().preferenceItem.currentPage,
              OrderBy: store.getState().preferenceItem.sortElement,
              OrderDir: store.getState().preferenceItem.sortByDirection
          };
          body = JSON.stringify(searchBody);          
          dispatch(getAllPreferenceItems(body));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changePreferenceItem = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/preference/item/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getPreferenceItemById(body.PreferenceItem_ID));
          // just the items of my category
          const item = JSON.parse(body);
          const searchBody = {
              PreferenceCategory_ID: item.PreferenceCategory_ID,
              RowsPerPage: ROWS_PER_PAGE,
              Page: store.getState().preferenceItem.currentPage,
              OrderBy: store.getState().preferenceItem.sortElement,
              OrderDir: store.getState().preferenceItem.sortByDirection
          };
          body = JSON.stringify(searchBody);          
          dispatch(getAllPreferenceItems(body));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllPreferenceItems = payload => ({
  type: adminActionTypes.SAVE_ALL_PREFERENCEITEMS,
  payload,
});

export const savePreferenceItemSortElement = payload => ({
  type: adminActionTypes.SAVE_PREFERENCEITEM_SORT_ELEMENT,
  payload,
});

export const savePreferenceItemSortByDirection = payload => ({
  type: adminActionTypes.SAVE_PREFERENCEITEM_SORT_BY_DIRECTION,
  payload,
});

export const savePreferenceItemPaginationData = payload => ({
  type: adminActionTypes.SAVE_PREFERENCEITEM_PAGINATION_DATA,
  payload,
});

export const getAllPreferenceItems = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: ROWS_PER_PAGE,
            Page: store.getState().preferenceItem.currentPage,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/preference/item/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllPreferenceItems(data.Data));
        dispatch(savePreferenceItemPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const deletePreferenceItem = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/preference/item/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else 
            {
              // just the items of my category
              const item = JSON.parse(body);
              const searchBody = {
                  PreferenceCategory_ID: item.PreferenceCategory_ID,
                  RowsPerPage: ROWS_PER_PAGE,
                  Page: store.getState().preferenceItem.currentPage,
                  OrderBy: store.getState().preferenceItem.sortElement,
                  OrderDir: store.getState().preferenceItem.sortByDirection
              };
              body = JSON.stringify(searchBody);          
              dispatch(getAllPreferenceItems(body));
            }
        })
        .catch(error => console.log(error));
    }
  };
};
