import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    '& > div > div': {
      maxWidth: 1050,
    }
  },
  modalTopContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 12px 18px -19px rgba(0,0,0,0.75)',
    marginBottom: 15,
    "& > button":{
      marginRight: 5,
    }
  },
  modalContentContainer: {
    minWidth: 650,
    marginBottom: 15,
    paddingBottom: 30,
    boxShadow: '0px 12px 18px -19px rgba(0,0,0,0.75)',
    "& > div": {
      boxShadow: 'none'
    }
  },
  topContent: {
    textAlign: 'center',
    color: theme.palette.electricBlue[100],
    transition: 'all .4s',
    marginBottom: 15,
  },
  inputs: {
    "& > div ": {
      minHeight: 100
    },
    "& > div > div": {
      minHeight: 100
    }
  },
}));

export default useStyles;