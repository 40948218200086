import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { columns } from "./columns";
import useStyles from "./styles";
import LanguageButton from "./LanguageButton";
import { getLanguageById } from "../../store/actions/actions/language";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CheckIcon from "@material-ui/icons/Check";
import { BASE_URL } from "../../config";
import * as userAccess from "../../userAccess";
import LanguageModal from "../../components/LanguageForm/LanguageForm";

const createData = (
  Language_ID,
  Abbreviation,
  Icon,
  MaterialIO,
  IsDefault,
  Name,
  Names,
) => {
  return {
    Language_ID,
    Abbreviation,
    Icon,
    MaterialIO,
    IsDefault,
    Name,
    Names,
  };
};

const LanguageTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedLanguage,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedLanguage, setClickedLanguage] = useState([]);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(
    useSelector(state => state.user.user),
    userAccess.Table_Languages,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.Language_ID,
            item.Abbreviation,
            item.Icon,
            item.MaterialIO,
            item.IsDefault,
            item.Name,
            item.Names,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedLanguage = value => {
    setClickedLanguage(value);
  };

  const handleLanguageModalOpen = () => {
    setIsLanguageModalOpen(true);
  };

  const handleLanguageModalClose = () => {
    setIsLanguageModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === "actions") {
      return (
        <LanguageButton
          data={row}
          handleClickedLanguage={handleClickedLanguage}
          handleLanguageModalOpen={handleLanguageModalOpen}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedLanguage={setDeletedLanguage}
          disabledDelete={disableDelete || Number(row.IsDefault) === 1}
        />
      );
    } else if (column.id === "Icon" && value) {
      return (
        <img
          src={`${BASE_URL}${value.substr(1)}`}
          className={classes.avatar}
          alt="Icon"
        />
      );
    } else if (column.id === "IsDefault" && value) {
      return Number.parseInt(value) === 1 ? <CheckIcon /> : null;
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    dispatch(getLanguageById(value.Language_ID));
    let languageBody = {
      Language_ID: value.Language_ID,
      Abbreviation: value.Abbreviation,
      Icon: value.Icon,
      MaterialIO: value.MaterialIO,
      IsDefault: value.IsDefault,
      Names: value.Names,
    };
    handleClickedLanguage(languageBody);
    handleLanguageModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.Language_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0
                    ? classNames(classes.tableCell, classes[row.color])
                    : classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== "actions") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id ? sortedBy : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <LanguageModal
        open={isLanguageModalOpen}
        handleLanguageModalClose={handleLanguageModalClose}
        defaultValues={clickedLanguage}
      />
    </Paper>
  );
};

export default LanguageTable;
