import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

const TitleComponent = ({ title }) => {
  var defaultTitle = 'TRIP*PERFECT';
  return (
    <Helmet>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
    </Helmet>
  );
};

const withTitle = (Component, title) => props => {
  return (
    <Fragment>
      <TitleComponent title={title} />
      <Component {...props} />
    </Fragment>
  );
};

export default withTitle;
