import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allPreferenceCategories: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'Name',
  sortByDirection: 'asc',
  rows: null,
  currentPreferenceCategory: {
    PreferenceCategory_ID: 0,
    Comments: '',
    Name: '',
    Names: [],
    Items: [],
  },
};

const preferenceCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_PREFERENCECATEGORY: {
      return {
        ...state,
        currentPreferenceCategory: {
            PreferenceCategory_ID: action.payload.PreferenceCategory_ID,
            Comments: action.payload.Comments,
            Name: action.payload.Name,
            Names: action.payload.Names,
            Items: action.payload.Items,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_PREFERENCECATEGORIES: {
      return {
        ...state,
        allPreferenceCategories: action.payload,
      };
    }
    case adminActionTypes.SAVE_PREFERENCECATEGORY_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_PREFERENCECATEGORY_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.OPEN_PREFERENCECATEGORY_ROW: {
      let data = {...state.rows};
      Object.keys(data).forEach(function(key) {
        data[key] = false;
      })
      data[action.payload] = true;
      return {
        ...state,
        rows: data,
      };
    }
    case adminActionTypes.CLOSE_PREFERENCECATEGORY_ROW: {
      let data = {...state.rows};
      Object.keys(data).forEach(function(key) {
        data[key] = false;
      })
      return {
        ...state,
        rows: data,
      };
    }
    case adminActionTypes.SAVE_PREFERENCECATEGORY_ROW: {
      return {
        ...state,
        rows: action.payload,
      };
    }
    case adminActionTypes.SAVE_PREFERENCECATEGORY_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_PREFERENCECATEGORY_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allPreferenceCategories: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentPreferenceCategory: {
            PreferenceCategory_ID: 0,
            Comments: '',
            Name: '',
            Names: [],
            Items: [],
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default preferenceCategoryReducer;