import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '15px 0 10px 0',
    textAlign: 'center',
  },
  container: {
    margin: 10,
  },
  backToCockpit: {
    '& > a': {
      display: 'flex',
      width: 250,
      flex: '1',
      alignItems: 'center',
      color: '#000000',
      textDecoration: 'none',
      fontWeight: 'normal',
      '& > svg': {
        fontSize: 32,
      },
      '& > h2': {
        paddingLeft: 10,
      },
    },
  },
  filterContainer: {
    width: 1000,
    maxWidth: 1000,
    margin: '0 auto',
    marginTop: 15,
  },
  marginRight: {
    marginRight: 8
  },
  notificationContainer: {
    width: 1000,
    maxWidth: 1000,
    margin: '0 auto',
    marginTop: 15,
  },
  backToTop: {
    backgroundColor: theme.palette.electricBlue[100],
    borderRadius: '50%',
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    color: 'white',
  },
  trippetFormContainer: {
    position: 'relative',
  },
  formControlTripName: {
    minWidth: 120,
    maxWidth: 120,
  },
}));

export default useStyles;
