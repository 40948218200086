import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '15px 0',
    textAlign: 'center',
  },
  containerTrippetTypeCategory: {
    paddingBottom: 50,
  },
  rootPaper: {
    width: '100%',
  },
  container: {
    maxHeight: 740,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  space: {
    margin: '15px 0',
  },
  tableHeaderCell: {
    fontWeight: 'bold !important',
    zIndex: 3,
  },
  rowContainer: {
    cursor: 'pointer',
  },
  tableCell: {
    border: '1px solid #aeaeae',
    borderLeft: `5px solid transparent`,
  },
  avatar: {
    width: 35,
    height: 35,
    fontSize: 14,
  },
  avatarGroup: {
    '& > div': {
      width: 35,
      height: 35,
      fontSize: 14,
    },
  },
  activeButton: {
    color: theme.palette.electricBlue[100],
  },
  activeFilter: {
    color: '#009bfc',
  },
}));

export default useStyles;
