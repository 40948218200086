import React, { useEffect, useState } from 'react';
import withAdminDashboard from '../../hoc/withAdminDashboard/withAdminDashboard';
import withTitle from '../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import useStyle from './styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllVerificationCodes,
    deleteVerificationCode,
    saveCurrentPage,
} from '../../store/actions/actions/verificationCode';
import Table from './Table';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import AdminPagination from '../../components/AdminPagination/AdminPagination';
import { ROWS_PER_PAGE } from '../../config';
import * as userAccess from '../../userAccess';
import VerificationCodeModal from '../../components/VerificationCodeForm/VerificationCodeForm';

const VerificationCodes = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const allVerificationCodes = useSelector(state => state.verificationCode.allVerificationCodes);
  const paginationData = useSelector(state => state.verificationCode.paginationData);
  const currentPage = useSelector(state => state.verificationCode.currentPage);
  const [deletedVerificationCode, setDeletedVerificationCode] = useState(null);
  const [isOpenConfirmDeleteModal, setisOpenConfirmDeleteModal] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortedElement, setSortedElement] = useState('CustomerName');
  const [sortedDir, setSortedDir] = useState('asc');
  const [isVerificationCodeModalOpen, setIsVerificationCodeModalOpen] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    const bodySearch = {
      Page: currentPage,
      RowsPerPage: ROWS_PER_PAGE,
      OrderBy: sortedElement,
      Language_ID: '2',
      OrderDir: sortedDir,
    };

    dispatch(getAllVerificationCodes(JSON.stringify(bodySearch)));
  }, [sortedElement, sortedDir, currentPage, dispatch]);

  useEffect(() => {
    if (paginationData) {
      setTotalItemsCount(paginationData.Num);
    }
  }, [paginationData]);

  const handleSortDir = id => () => {
    setSortedDir(sortedElement === id && sortedDir === 'asc' ? 'desc' : 'asc');
    setSortedElement(id);
  };

  const onPaginationChange = activePage => {
    dispatch(saveCurrentPage(activePage));
  };

  const handleConfirmDeleteModalClose = () => {
    setisOpenConfirmDeleteModal(false);
  };

  const handleConfirmDeleteModalOpen = () => {
    setisOpenConfirmDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteVerificationCode(JSON.stringify(deletedVerificationCode)));
  };

  const handleVerificationCodeModalOpen = () => {
    setIsVerificationCodeModalOpen(true);
  };

  const handleVerificationCodeModalClose = () => {
    setIsVerificationCodeModalOpen(false);
  };

  const handleNewClick = () => {
    setDefaultValues(null);
    handleVerificationCodeModalOpen();
  };

  return userAccess.canRead(user, userAccess.Table_VerificationCodes) ? (
    <Grid container justify="center" className={classes.containerMyTrip}>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {t('Verification Codes')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Fab
            variant="extended"
            className={classes.addTripButton}
            onClick={handleNewClick}
            disabled={!userAccess.canWrite(user, userAccess.Table_VerificationCodes)}
          >
            <AddIcon className={classes.extendedIcon} />
            {t('Verification Code')}
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          data={allVerificationCodes}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedVerificationCode={setDeletedVerificationCode}
          sortedElement={sortedElement}
          sortedBy={sortedDir}
          handleSortBy={handleSortDir}
        />
      </Grid>
      <AdminPagination
        activePage={currentPage}
        itemsPerPage={ROWS_PER_PAGE}
        totalItemsCount={totalItemsCount}
        onChange={onPaginationChange}
        title='verificationCodes'
      />
      <ConfirmDeleteModal
        open={isOpenConfirmDeleteModal}
        handleClose={handleConfirmDeleteModalClose}
        handleDelete={handleDelete}
      />
     <VerificationCodeModal
        open={isVerificationCodeModalOpen}
        handleVerificationCodeModalClose={handleVerificationCodeModalClose}
        defaultValues={defaultValues}
      />
    </Grid>
  )
  : <div>NO ACCESS</div>;
};

export default withTitle(withAdminDashboard(VerificationCodes), 'Admin VerificationCodes');
