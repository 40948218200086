import React, { useState, useEffect, Fragment } from "react";
import TableCell from "@material-ui/core/TableCell";
import Avatar from "@material-ui/core/Avatar";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {
  getTrippetImages,
  showTrippetForm,
  getAllPrivateDocumentsFromTheSingleTrippet,
  getAllPublicDocumentsFromTheSingleTrippet,
  saveTrippetChildrenStatus,
  saveParentTrippetWithChildren,
} from "../../store/actions/actions/trippet";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Checkbox from "@material-ui/core/Checkbox";
import uniqid from "uniqid";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Row = ({
  checkedTrippets,
  handleCheckboxChange,
  row,
  toogleChilds,
  renderChildrenElements,
  columns,
  childrenColumns,
  setSaveOrdering,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const allUsers = useSelector(state => state.app.allUsers);
  const trippets = useSelector(
    state => state.trippet.parentsTrippetsWithChildren,
  );
  const user = useSelector(state => state.user.user);
  const trippetChildrenStatus = useSelector(
    state => state.trippet.trippetChildrenStatus,
  );
  const isOpenTrippetFrom = useSelector(
    state => state.trippet.isOpenTrippetFrom,
  );
  const parentsTrippetsWithChildrenNum = useSelector(
    state => state.trippet.parentsTrippetsWithChildrenNum,
  );
  const [childrenRows, setChildrenRows] = useState([]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const saveReordering = items => {
    setSaveOrdering(true);
    if (trippets && trippets.length > 0) {
      let children = [];
      items.forEach(child => children.push(child.fullTrippet));
      let newOrderTrippets = [...trippets];
      newOrderTrippets.forEach(trippet => {
        if (trippet.Trippet_ID === children[0].IsChildOf) {
          trippet.Children = children;
        }
      });
      let body = {
        Data: newOrderTrippets,
        Num: parentsTrippetsWithChildrenNum,
      };
      dispatch(saveParentTrippetWithChildren(body));
    }
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      childrenRows,
      result.source.index,
      result.destination.index,
    );
    setChildrenRows(items);
    saveReordering(items);
  };

  useEffect(() => {
    if (toogleChilds) {
      setOpen(true);
      renderChildrenElements(row.fullTrippet.Children, setChildrenRows);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line
  }, [toogleChilds]);

  useEffect(() => {
    if (trippetChildrenStatus) {
      // eslint-disable-next-line
      Object.keys(trippetChildrenStatus).map(key => {
        if (trippetChildrenStatus[key]) {
          if (+key === +row.fullTrippet.Trippet_ID) {
            renderChildrenElements(row.fullTrippet.Children, setChildrenRows);
            setOpen(true);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      row &&
      row.fullTrippet.Children &&
      row.fullTrippet.Children.length > 0
    ) {
      renderChildrenElements(row.fullTrippet.Children, setChildrenRows);
    } else {
      setChildrenRows([]);
    }
    // eslint-disable-next-line
  }, [row, row.fullTrippet.Children]);

  const findUserInfo = participant => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      allUsers.forEach(user => {
        if (participant.TrippetParticipantUser_ID === user.User_ID) {
          data = renderUserInfo(user);
        }
      });
      return data;
    }
  };

  const renderUserInfo = participant => {
    if (participant.Picture) {
      return (
        <img
          src={participant.Picture}
          alt={participant.TrippetParticipantInitials}
          className={classes.avatarImage}
        />
      );
    } else {
      return `${participant.Initials}`;
    }
  };

  const findOrganizerInfo = organizer => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      allUsers.forEach(user => {
        if (organizer.TrippetOrganizerUser_ID === user.User_ID) {
          data = renderOrganizerInfo(user);
        }
      });
      return data;
    }
  };

  const renderOrganizerInfo = organizer => {
    if (organizer.Picture) {
      return (
        <img
          src={organizer.Picture}
          alt={organizer.TrippetOrganizerInitials}
          className={classes.avatarImage}
        />
      );
    } else {
      return `${organizer.Initials}`;
    }
  };

  const renderParticipantsStatusColor = status => {
    if (status === "1" || status === null || status === "0") {
      return classes.pending;
    } else if (status === "2") {
      return classes.accepted;
    } else if (status === "3") {
      return classes.rejected;
    }
  };

  const renderParticipants = value => {
    let allParticipants = value;
    return (
      allParticipants &&
      allParticipants.map(participant => (
        <Tooltip
          title={participant.TrippetParticipantDisplayName}
          placement="top"
          key={participant.TrippetParticipantUser_ID}
          arrow
        >
          <Avatar className={classes.avatar}>
            {findUserInfo(participant)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const findOwner = value => allUsers.find(user => user.User_ID === value);

  const renderOrganizers = value => {
    let allOrganizers = value;
    return (
      allOrganizers &&
      allOrganizers.map(organizer => (
        <Tooltip
          title={organizer.TrippetOrganizerDisplayName}
          placement="top"
          key={organizer.TrippetOrganizerUser_ID}
          arrow
        >
          <Avatar
            className={renderParticipantsStatusColor(
              organizer.TrippetOrganizerAcceptanceStatus,
            )}
          >
            {findOrganizerInfo(organizer)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const renderData = (column, value, row, hasArrow, provided) => {
    if (column.id === "participants") {
      return (
        <AvatarGroup max={2} className={classes.avatarGroup}>
          {renderParticipants(value)}
        </AvatarGroup>
      );
    } else if (column.id === "actions") {
      return (
        <div onClick={e => e.stopPropagation()}>
          <Checkbox
            onChange={e => handleCheckboxChange(e, row.fullTrippet)}
            color="primary"
            name={row.fullTrippet.Trippet_ID}
            checked={checkedTrippets[row.fullTrippet.Trippet_ID]}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
      );
    } else if (column.id === "children" && hasArrow) {
      if (
        row.fullTrippet &&
        row.fullTrippet.Children &&
        row.fullTrippet.Children.length > 0
      ) {
        return (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={event =>
              handleClickExpandRow(
                event,
                row.fullTrippet.Trippet_ID,
                row.fullTrippet.Children,
              )
            }
          >
            {trippetChildrenStatus[row.fullTrippet.Trippet_ID] ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        );
      } else {
        return "";
      }
    } else if (column.id === "dnd") {
      return (
        <span onClick={e => e.stopPropagation()} {...provided.dragHandleProps}>
          <OpenWithIcon className={classes.dndIcon} />
        </span>
      );
    } else if (column.id === "responsible") {
      return (
        <AvatarGroup max={2} className={classes.avatarGroup}>
          {renderOrganizers(value)}
        </AvatarGroup>
      );
    } else if (column.id === "trippetOwner") {
      let user = findOwner(value);
      if (user) {
        if (user.Picture) {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar className={classes.avatarOwner}>
                <img
                  src={user.Picture}
                  alt={user.Initials}
                  className={classes.avatarImage}
                />
              </Avatar>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={user.DisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar
                className={classes.avatarOwner}
              >{`${user.Initials}`}</Avatar>
            </Tooltip>
          );
        }
      }
    } else {
      return value;
    }
  };

  const handleTrippetFormOpen = rowData => {
    dispatch(showTrippetForm(rowData));
  };

  const handleRowClick = rowData => () => {
    if (!isOpenTrippetFrom) {
      const privateDocument = {
        Trippet_ID: rowData.fullTrippet.Trippet_ID,
        IsPrivate: "1",
        CreatorUser_ID: user.User_ID,
      };
      dispatch(
        getAllPrivateDocumentsFromTheSingleTrippet(
          JSON.stringify(privateDocument),
        ),
      );

      const publicDocument = {
        Trippet_ID: rowData.fullTrippet.Trippet_ID,
        IsPrivate: "0",
      };
      dispatch(
        getAllPublicDocumentsFromTheSingleTrippet(
          JSON.stringify(publicDocument),
        ),
      );

      // dispatch(getTrippetPicture(pictureId));
      const trippetImage = {
        Trippet_ID: rowData.fullTrippet.Trippet_ID,
      };
      dispatch(getTrippetImages(JSON.stringify(trippetImage)));
      handleTrippetFormOpen(rowData);
    }
  };

  const handleClickExpandRow = (event, id, children) => {
    if(!isOpenTrippetFrom){
      event.stopPropagation();
      let trippets = { ...trippetChildrenStatus };
      setOpen(!open);
      // eslint-disable-next-line
      Object.keys(trippets).map(key => {
        if (+key === +id) {
          if (!trippets[key]) {
            renderChildrenElements(children, setChildrenRows);
          }
          trippets[key] = !trippets[key];
        }
      });
      dispatch(saveTrippetChildrenStatus(trippets));
    }
  };

  const getLinkValue = value => {
    let link = "";
    if (value) {
      if (value.substring(0, 7) === "http://") {
        let newLink = value.substring(7);
        link = newLink;
      } else if (value.substring(0, 8) === "https://") {
        let newLink = value.substring(8);
        link = newLink;
      } else {
        link = value;
      }
    }
    return link;
  };

  const renderChildrenRows = () => {
    if (childrenRows && childrenRows.length > 0) {
      return (
        <Fragment>
          {childrenRows.map((row, index) => (
            <Draggable
              key={row.fullTrippet.Trippet_ID}
              draggableId={row.fullTrippet.Trippet_ID}
              index={index}
              isDragDisabled={isOpenTrippetFrom}
            >
              {(provided, snapshot) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.fullTrippet.Trippet_ID}
                  onClick={handleRowClick(row)}
                  className={classes.rowContainer}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                  )}
                >
                  {childrenColumns.map((column, index) => {
                    const value = row[column.id];
                    const styleName =
                      index === 0
                        ? `5px solid #${row.color}`
                        : classes.tableCell;
                    if (column.label === "To") {
                      return (
                        <Tooltip
                          arrow
                          title={`${value} ${
                            JSON.parse(row.fullTrippet.ToData).formatted_address
                          }`}
                          placement="top"
                          key={column.id}
                        >
                          <TableCell
                            align={column.align}
                            className={styleName}
                            style={{ backgroundColor: "#eae9e5" }}
                          >
                            {renderData(column, value, row, false, provided)}
                          </TableCell>
                        </Tooltip>
                      );
                    } else if (column.label === "From") {
                      if (JSON.parse(row.fullTrippet.FromData)) {
                        return (
                          <Tooltip
                            arrow
                            title={`${value} ${
                              JSON.parse(row.fullTrippet.FromData)
                                .formatted_address
                            }`}
                            placement="top"
                            key={column.id}
                          >
                            <TableCell
                              align={column.align}
                              className={styleName}
                              style={{ backgroundColor: "#eae9e5" }}
                            >
                              {renderData(column, value, row, false, provided)}
                            </TableCell>
                          </Tooltip>
                        );
                      } else {
                        return (
                          <TableCell
                            align={column.align}
                            className={styleName}
                            key={column.id}
                            style={{ backgroundColor: "#eae9e5" }}
                          >
                            {renderData(column, value, row, false, provided)}
                          </TableCell>
                        );
                      }
                    } else {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.tableCell}
                          style={{
                            borderLeft: styleName,
                            backgroundColor: "#eae9e5",
                          }}
                        >
                          {renderData(column, value, row, false, provided)}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              )}
            </Draggable>
          ))}
        </Fragment>
      );
    }
  };

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "" : "white",
  });

  return (
    <DragDropContext onDragEnd={onDragEnd} key={row.fullTrippet.Trippet_ID}>
      <Droppable droppableId={`droppable${row.fullTrippet.Trippet_ID}`}>
        {(provided, snapshot) => (
          <TableBody {...provided.droppableProps} ref={provided.innerRef}>
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.fullTrippet.Trippet_ID}
              onClick={handleRowClick(row)}
              className={classes.rowContainer}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0 ? `5px solid #${row.color}` : classes.tableCell;
                if (column.label === "To") {
                  return (
                    <Tooltip
                      arrow
                      title={`${value} ${
                        JSON.parse(row.fullTrippet.ToData).formatted_address
                      }`}
                      placement="top"
                      key={column.id}
                    >
                      <TableCell
                        align={column.align}
                        className={styleName}
                        style={{ fontSize: 14 }}
                      >
                        {renderData(column, value, row, true)}
                      </TableCell>
                    </Tooltip>
                  );
                } else if (column.label === "From") {
                  if (JSON.parse(row.fullTrippet.FromData)) {
                    return (
                      <Tooltip
                        arrow
                        title={`${value} ${
                          JSON.parse(row.fullTrippet.FromData).formatted_address
                        }`}
                        placement="top"
                        key={column.id}
                      >
                        <TableCell
                          align={column.align}
                          className={styleName}
                          style={{ fontSize: 14 }}
                        >
                          {renderData(column, value, row, true)}
                        </TableCell>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <TableCell
                        align={column.align}
                        className={styleName}
                        key={column.id}
                        style={{ fontSize: 14 }}
                      >
                        {renderData(column, value, row, true)}
                      </TableCell>
                    );
                  }
                }
                if (column.id === "link") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableCell}
                    >
                      <a
                        href={`//${getLinkValue(value)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e => e.stopPropagation()}
                      >
                        {value}
                      </a>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableCell}
                      style={{ borderLeft: styleName, fontSize: 14 }}
                    >
                      {renderData(column, value, row, true)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
            {open && renderChildrenRows()}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Row;
