import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allSettingItems: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'Name',
  sortByDirection: 'asc',
  currentSettingItem: {
    SettingItem_ID: 0,
    SettingCategory_ID: 0,
    SettingCategoryName: '',
    DataType: '',
    Name: '',
    Names: []
  },
};

const settingItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_SETTINGITEM: {
      return {
        ...state,
        currentSettingItem: {
            SettingItem_ID: action.payload.SettingItem_ID,
            SettingCategory_ID: action.payload.SettingCategory_ID,
            SettingCategoryName: action.payload.SettingCategoryName,
            DataType: action.payload.DataType,
            Name: action.payload.Name,
            Names: action.payload.Names,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_SETTINGITEMS: {
      return {
        ...state,
        allSettingItems: action.payload,
      };
    }
    case adminActionTypes.SAVE_SETTINGITEM_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_SETTINGITEM_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_SETTINGITEM_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_SETTINGITEM_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allSettingItems: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentSettingItem: {
            SettingItem_ID: 0,
            SettingCategory_ID: 0,
            SettingCategoryName: '',
            DataType: '',
            Name: '',
            Names: []
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default settingItemReducer;