import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Logo from "../../assets/TP-logo-800px.png";
import useStyles from "../signIn/styles";
import Copyright from "../../components/Copyright";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { requestChangeUserPassword } from '../../store/actions/actions/user';
import { useDispatch } from 'react-redux';

const ForgotPassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = event => {
    event.preventDefault();
    let body = {
      Email: email,
    };
    dispatch(requestChangeUserPassword(JSON.stringify(body), t));
  };

  const handleInput = event => {
    setEmail(event.target.value);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image}>
        <img src={Logo} alt="logo" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        className={classes.formContainer}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("Reset Password")}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("Email Address")}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleInput}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t("Reset password")}
            </Button>
            <Grid container>
              <Grid item>
                <Link to="/login" variant="body2" className={classes.link}>
                  {t("Already have an account? Sign in")}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
