import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { columns, childrenColumns } from "./columns";
import useStyles from "../styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import { BASE_URL } from "../../../config";
import PictureThumbnail from "../../../assets/thumbnail.png";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import classNames from "classnames";
import { getTrippetTypeIcon } from "../../../pages/myTrippets/helper";
import Checkbox from "@material-ui/core/Checkbox";
import Row from "../Row";

const createData = (
  type,
  title,
  trippetOwner,
  responsible,
  participants,
  isPrivate,
  link,
  image,
  price,
  color,
  fullTrippet,
) => {
  return {
    type,
    title,
    trippetOwner,
    responsible,
    participants,
    isPrivate,
    link,
    image,
    price,
    color,
    fullTrippet,
  };
};

const TrippetDataTableTwo = ({
  handleSortBy,
  sortedBy,
  sortedElement,
  handleOpenFilterModal,
  disabledFilters,
  activeFilters,
  filterResults,
  getNumberOfActiveFilters,
  checkedTrippets,
  handleCheckboxChange,
  handleAllChecked,
  allChecked,
  selectedTrippets,
  toogleChilds,
  setSaveOrdering,
  indeterminate
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const trippets = useSelector(
    state => state.trippet.parentsTrippetsWithChildren,
  );
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const trippetChildrenStatus = useSelector(
    state => state.trippet.trippetChildrenStatus,
  );
  const { t } = useTranslation();

  const getTrippetTypeColor = data => {
    if (trippetTypes) {
      let element = trippetTypes.find(type => data === type.TrippetTypeLook_ID);
      return element.Color;
    }
  };

  // const renderDays = (start, end) => {
  //   if (start && end) {
  //     return `${start} to ${end}`;
  //   } else {
  //     return "";
  //   }
  // };

  const renderImage = path => {
    if (path) {
      return (
        <img
          style={{ width: 60 }}
          src={`${BASE_URL}${path && path.substr(1)}`}
          alt="trippetImage"
        />
      );
    } else {
      return (
        <img style={{ width: 60 }} src={PictureThumbnail} alt="thumbnail" />
      );
    }
  };

  const renderElements = () => {
    let elements = [];
    if (trippets && trippets.length) {
      trippets.forEach(trippet => {
        elements.push(
          createData(
            getTrippetTypeIcon(
              trippet.TrippetTypeLook_ID,
              trippetTypes,
              classes,
            ),
            trippet.Title,
            trippet.CreatorUser_ID,
            trippet.Organizers,
            trippet.Participants,
            trippet.IsPrivate === "1" ? (
              <CheckCircleIcon className={classes.accept} />
            ) : (
              <CancelIcon className={classes.decline} />
            ),
            trippet.Link,
            renderImage(trippet.Thumbnail),
            trippet.Price,
            getTrippetTypeColor(trippet.TrippetTypeLook_ID),
            trippet,
          ),
        );
      });
      setRows(elements);
    }
  };

  const renderChildrenElements = (list, setChildrenRows) => {
    let elements = [];
    if (list && list.length) {
      list.forEach(trippet => {
        elements.push(
          createData(
            getTrippetTypeIcon(
              trippet.TrippetTypeLook_ID,
              trippetTypes,
              classes,
            ),
            trippet.Title,
            trippet.CreatorUser_ID,
            trippet.Organizers,
            trippet.Participants,
            trippet.IsPrivate === "1" ? (
              <CheckCircleIcon className={classes.accept} />
            ) : (
              <CancelIcon className={classes.decline} />
            ),
            trippet.Link,
            renderImage(trippet.Thumbnail),
            trippet.Price,
            getTrippetTypeColor(trippet.TrippetTypeLook_ID),
            trippet,
          ),
        );
      });
      setChildrenRows(elements);
    }
  };

  useEffect(() => {
    if (trippets && trippets.length && trippetChildrenStatus) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trippets, trippetTypes, trippetChildrenStatus]);

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <Row
              key={row.fullTrippet.Trippet_ID}
              row={row}
              checkedTrippets={checkedTrippets}
              handleCheckboxChange={handleCheckboxChange}
              toogleChilds={toogleChilds}
              renderChildrenElements={renderChildrenElements}
              columns={columns}
              childrenColumns={childrenColumns}
              setSaveOrdering={setSaveOrdering}
            />
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id === "documents") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ width: 150 }}
                    >
                      {t(column.label)}
                      <Tooltip
                        title={t("Total number of public documents")}
                        placement="top"
                        arrow
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </TableCell>
                  );
                } else if (
                  column.id !== "actions" &&
                  column.id !== "children"
                ) {
                  const renederActiveSortingArrow =
                    sortedElement === column.type
                      ? classNames(
                          classes.tableHeader,
                          classes.tableHeaderCellActive,
                        )
                      : classNames(
                          classes.tableHeader,
                          classes.tableHeaderCell,
                        );
                  if (column.filter) {
                    let activeColor = activeFilters[column.filter]
                      ? classNames(classes.filterButton, classes.activeFilter)
                      : classes.filterButton;

                    const renderNumber = getNumberOfActiveFilters(column);
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : "asc"
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                        <IconButton
                          className={activeColor}
                          onClick={handleOpenFilterModal(column)}
                          disabled={disabledFilters[column.filter]}
                        >
                          <Badge badgeContent={renderNumber} color="primary">
                            <FilterListIcon />
                          </Badge>
                        </IconButton>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : "asc"
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                      </TableCell>
                    );
                  }
                } else {
                  if (column.id === "actions") {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeaderCell}
                        style={{ minWidth: column.minWidth }}
                      >
                        <Checkbox
                          checked={allChecked}
                          onChange={handleAllChecked}
                          color="primary"
                          indeterminate={indeterminate}
                        />
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeaderCell}
                        style={{ minWidth: column.minWidth }}
                      >
                        {t(column.label)}
                      </TableCell>
                    );
                  }
                }
              })}
            </TableRow>
          </TableHead>
          {renderRows}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TrippetDataTableTwo;
