import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allParticipantStatuses: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'SortIndex',
  sortByDirection: 'asc',
  currentParticipantStatus: {
    ParticipantStatus_ID: 0,
    Type: 3,
    Icon: '',
    MaterialIO: '',
    IsDefault: false,
    Listing: 0,
    SortIndex: 0,
    Comments: '',
    Names: []
  },
};

const participantStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_PARTICIPANTSTATUS: {
      return {
        ...state,
        currentParticipantStatus: {
            ParticipantStatus_ID: action.payload.ParticipantStatus_ID,
            Type: action.payload.Type,
            Icon: action.payload.Icon,
            MaterialIO: action.payload.MaterialIO,
            IsDefault: action.payload.IsDefault,
            Listing: action.payload.Listing,
            SortIndex: action.payload.SortIndex,
            Comments: action.payload.Comments,
            Names: action.payload.Names,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_PARTICIPANTSTATUSES: {
      return {
        ...state,
        allParticipantStatuses: action.payload,
      };
    }
    case adminActionTypes.SAVE_PARTICIPANTSTATUS_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_PARTICIPANTSTATUS_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_PARTICIPANTSTATUS_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_PARTICIPANTSTATUS_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allParticipantStatuses: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'SortIndex',
        sortByDirection: 'asc',
        currentParticipantStatus: {
            ParticipantStatus_ID: 0,
            Type: 1,
            Icon: '',
            MaterialIO: '',
            IsDefault: false,
            Listing: 0,
            SortIndex: 0,
            Comments: '',
            Names: []
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default participantStatusReducer;