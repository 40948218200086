import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: 650,
    paddingTop: 67,
  },
  infoWindow: {
    backgroundColor: 'transparent',
    padding: 5,
    fontSize: 14,
    '& > h4': {
      margin: '5px 0',
      fontWeight: 400,
      '& > span': {
        fontWeight: 'bold',
        minWidth: 40,
        display: 'inline-block',
      },
    },
  },
  infoButton: {
    marginLeft: 'auto',
    width: '100%',
  },
}));

export default useStyles;
