import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allConfigValues: null,
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_ALL_CONFIGS: {
      return {
        ...state,
        allConfigValues: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allConfigValues: null,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default configReducer;