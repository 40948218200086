import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '15px 0 10px 0',
    textAlign: 'center',
  },
  container: {
    margin: 10,
  },
  backToCockpit: {
    '& > a': {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      color: '#000000',
      textDecoration: 'none',
      fontWeight: 'normal',
      '& > svg': {
        fontSize: 32,
      },
      '& > h2': {
        paddingLeft: 10,
      },
    },
  },
  profilePageContainer: {
    maxWidth: 540,
    margin: '0 auto',
    paddingBottom: 60,
  },
  marginBottom: {
    marginBottom: 10,
  },
  phoneInput: {
    '& > input': {
      width: '100% !important',
      height: '56px !important',
    },
  },
  error: {
    color: theme.palette.secondary.main,
  },
}));

export default useStyles;
