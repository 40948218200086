import { BASE_URL } from '../../config';
import { showSnackBar } from '../../store/actions/actions/root';
import React, { useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewLanguage, changeLanguage, getEntireLanguages } from '../../store/actions/actions/language';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import PreviewIcon from '../PreviewIcon/PreviewIcon';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../admin.css';
import IconPicker from '../IconPicker/IconPicker';
import * as userAccess from '../../userAccess';

const LanguageModal = ({ open, handleLanguageModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [abbreviationError, setAbbreviationError] = useState(false);
  const [namesError, setNamesError] = useState(false);
  const [formError, setFormError] = useState(false);
  const entireLanguages = useSelector(state => state.language.entireLanguages);
  const currentLanguage = useSelector(state => state.language.currentLanguage);
  const [values, setValues] = useState({
    Abbreviation: '',
    Icon: '',
    MaterialIO: '',
    IsDefault: '',
    Names: [],
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure languages is already loaded
    if (!entireLanguages)
    {
      dispatch(getEntireLanguages(null));
      return;
    }
    if (defaultValues) {
        // when there is a new language, the name for that language in 'Names' is missing
        // so we need to iterate through entireLanguages and push missed ones
        const names = currentLanguage ? currentLanguage.Names : defaultValues.Names;
        if (names && Array.isArray(names))
          entireLanguages.forEach(name => {
            var found = false;
            names.forEach(name2 => {
              if (Number(name2.Language_ID) === Number(name.Language_ID))
                found = true;
            });
            if (!found)
              names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
          });
        if (currentLanguage)
        {
            setValues({
                Abbreviation: currentLanguage.Abbreviation,
                Icon: currentLanguage.Icon,
                MaterialIO: currentLanguage.MaterialIO,
                IsDefault: currentLanguage.IsDefault,
                Names: names,
                Language_ID: currentLanguage.Language_ID,
            });
        }
        else
        {
            setValues({
                Abbreviation: defaultValues.Abbreviation,
                Icon: defaultValues.Icon,
                MaterialIO: defaultValues.MaterialIO,
                IsDefault: defaultValues.IsDefault,
                Names: names,
                Language_ID: defaultValues.Language_ID,
            });
        }
        
    //   setValues({...values, Names: names});
        handleIsEditMode(true);
    } else {
      
      const names = [];
      entireLanguages.forEach(name => {
        names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
      });
      setValues({
        Language_ID: 0,
        Abbreviation: '',
        Icon: '',
        MaterialIO: '',
        IsDefault: '',
        Names: names,
      });
      handleIsEditMode(false);
    }
  }, [defaultValues, currentLanguage, entireLanguages, dispatch]);

  const validateForm = ({ Abbreviation, Names }) => {
    if (Abbreviation && Names && Names.length > 0) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };

  const submitValues = () => {
    const data = {
      Language_ID: +values.Language_ID,
      Abbreviation: values.Abbreviation,
      Icon: values.Icon,
      MaterialIO: values.MaterialIO,
      IsDefault: values.IsDefault,
      Names: values.Names,
    };
    if (isEditMode) {
      if (validateForm(data)) {
        dispatch(changeLanguage(JSON.stringify(data)));
        handleLanguageModalClose();
      }
    } else {
      if (validateForm(data)) {
        dispatch(createNewLanguage(JSON.stringify(data)));          
        handleLanguageModalClose();
      }
    }
  };

  const handleSubmit = e => {
    e.stopPropagation();
    if (!abbreviationError  && !namesError) {
        e.preventDefault();
       
      // check IsDefault
      if (Number(values.IsDefault) === 1)
      {
        fetch(`${BASE_URL}api/language/search.php`, {
          method: 'POST',
          body: JSON.stringify({IsDefault: 1}),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => {
            if (response.status === 200) {
              return response.text();
            } else if (response.status === 304) {
              dispatch(showSnackBar({type: 'error', message: 'Something went wrong!'}));
            }
          })
          .then(response => {
            const data = JSON.parse(response);
            if (data.Num > 0 && Number(data.Data[0].Language_ID) !== Number(values.Language_ID))
            {
              // we have already another default language.
              // get confirm
              confirmAlert({
                title: 'Change default language',
                message: 'There is already a default language. Do you want to replace it?.',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => submitValues()
                  },
                  {
                    label: 'No',
                  }
                ]
              });
            }
            else
            {
              // it is the only default value
              submitValues();
            }
          })
          .catch(error => console.log(error));
      }
      else
        submitValues();
    }
  };

  const setMaterialIO = icon => {
    setValues({...values, MaterialIO: icon});
  };
  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name === 'Abbreviation') {
      if (e.target.value.length === 0) {
        setAbbreviationError(true);
      } else {
        setAbbreviationError(false);
      }
    }
    if (e.target.name.indexOf("Name") === 0) // it is Names list
    {
        const names = [];
        values.Names.forEach(name => {
            if ("Name" + name.Language_ID === e.target.name)
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: e.target.value});
            else
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: name.Name});
        });
        setValues({...values, Names: names});
        setNamesError(names.length === 0);
    }
    else
    {
        setValues({...values, [e.target.name]: e.target.value});
    }
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const clearIcon = () => {
    setValues({...values, Icon: ''});
  };

  const setIcon = file => {
    setValues({...values, Icon: file});
  };

  const renderTitle = defaultValues ? t('Edit Language') : t('New Language');
  const renderNames =
    values.Names && values.Names.length > 0
      ? values.Names.map(name => {
          return (
            <TableRow key={name.Language_ID} className={classes.rowContainer}>
                <TableCell>
                    <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id={"Name" + name.Language_ID}
                    label={name.LanguageAbbreviation}
                    name={"Name" + name.Language_ID}
                    value={name.Name}
                    onChange={handleInputs}
                    />
                </TableCell>
              </TableRow>
          );
        })
      : null;
      
  const renderAbbreviationError = abbreviationError
    ? t('Abbreviation is required.')
    : null;

  const renderNamesError = namesError
    ? t('Names are required.')
    : null;

  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;


  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_Languages))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_Languages));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.languageName}>
                <Grid container>
                  <Grid item xs={12} className={classes.languageName}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="Abbreviation"
                      label={t('Abbreviation')}
                      name="Abbreviation"
                      autoComplete="Abbreviation"
                      autoFocus
                      value={values.Abbreviation}
                      onChange={handleInputs}
                    />
                    <FormHelperText className={classes.errorText}>
                      {renderAbbreviationError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} className={classes.languageName}>
                    <Grid container>
                      <Grid item xs={10} className={classes.languageName}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          id="MaterialIO"
                          label={t('MaterialIO')}
                          name="MaterialIO"
                          autoComplete="MaterialIO"
                          value={values.MaterialIO}
                          onChange={handleInputs}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconPicker selectedIcon={values.MaterialIO} iconSelected={setMaterialIO} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.languageName}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.IsDefault) === 1}
                            onChange={handleInputs}
                            name="IsDefault"
                            id="IsDefault"
                            color="primary"
                            value={Number(values.IsDefault) === 1 ? 0 : 1}
                        />
                        }
                        label={t('IsDefault')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <PreviewIcon currentImage={values.Icon} pictureUrl={values.Icon} clearIcon={clearIcon} setIcon={setIcon}
                type='language' dispatch={dispatch} t={t} />
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
              
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  className={classes.title}
                >
                  {t('Languages')}
                </Typography>
              </Grid>
            </Grid>
            <TableContainer className={classes.container}>
                <Table>
                <TableBody>{renderNames}</TableBody>
                </Table>
            </TableContainer>
            <FormHelperText className={classes.errorText}>
              {renderNamesError}
            </FormHelperText>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleLanguageModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default LanguageModal;
