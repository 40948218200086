import React, { useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewTrippetTypeCategory, changeTrippetTypeCategory } from '../../store/actions/actions/trippetTypeCategory';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {getEntireLanguages} from '../../store/actions/actions/language';
import {getEntireTrips} from '../../store/actions/actions/trip';
import PreviewIcon from '../PreviewIcon/PreviewIcon';
import IconPicker from '../IconPicker/IconPicker';
import { SketchPicker } from 'react-color'
import colorStyles from '../ColorPicker/styles';
import * as userAccess from '../../userAccess';

const TrippetTypeCategoryModal = ({ open, handleTrippetTypeCategoryModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [namesError, setNamesError] = useState(false);
  const [formError, setFormError] = useState(false);
  const entireLanguages = useSelector(state => state.language.entireLanguages);
  const entireTrips = useSelector(state => state.trip.entireTrips);
  const currentTrippetTypeCategory = useSelector(state => state.trippetTypeCategory.currentTrippetTypeCategory);
  const [values, setValues] = useState({
    Trip_ID: '',
    Icon: '',
    MaterialIO: '',
    Comments: '',
    IsTransport: 0,
    SortIndex: 0,
    Color: '',
    Names: [],
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure languages is already loaded
    if (!entireLanguages)
    {
      dispatch(getEntireLanguages(null));
      return;
    }
    // make sure trips are already loaded
    if (!entireTrips)
    {
      dispatch(getEntireTrips(null));
      return;
    }
    if (defaultValues) {
        // when there is a new language, the name for that language in 'Names' is missing
        // so we need to iterate through entireLanguages and push missed ones
        const names = currentTrippetTypeCategory ? currentTrippetTypeCategory.Names : defaultValues.Names;
        if (names && Array.isArray(names))
          entireLanguages.forEach(name => {
            var found = false;
            names.forEach(name2 => {
              if (Number(name2.Language_ID) === Number(name.Language_ID))
                found = true;
            });
            if (!found)
              names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
          });
        if (currentTrippetTypeCategory)
        {
            setValues({
                Trip_ID: currentTrippetTypeCategory.Trip_ID,
                Icon: currentTrippetTypeCategory.Icon,
                MaterialIO: currentTrippetTypeCategory.MaterialIO,
                Comments: currentTrippetTypeCategory.Comments,
                IsTransport: currentTrippetTypeCategory.IsTransport,
                SortIndex: currentTrippetTypeCategory.SortIndex,
                Color: currentTrippetTypeCategory.Color,
                Names: names,
                TrippetTypeCategory_ID: currentTrippetTypeCategory.TrippetTypeCategory_ID,
            });
        }
        else
        {
            setValues({
                Trip_ID: defaultValues.Trip_ID,
                Icon: defaultValues.Icon,
                MaterialIO: defaultValues.MaterialIO,
                Comments: defaultValues.Comments,
                IsTransport: defaultValues.IsTransport,
                SortIndex: defaultValues.SortIndex,
                Color: defaultValues.Color,
                Names: names,
                TrippetTypeCategory_ID: defaultValues.TrippetTypeCategory_ID,
            });
        }
        
    //   setValues({...values, Names: names});
        handleIsEditMode(true);
    } else {
      
      const names = [];
      entireLanguages.forEach(name => {
        names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
      });
      setValues({
        TrippetTypeCategory_ID: 0,
        Trip_ID: '',
        Icon: '',
        MaterialIO: '',
        Comments: '',
        IsTransport: 0,
        SortIndex: 0,
        Color: '',
        Names: names,
      });
      handleIsEditMode(false);
    }
  }, [defaultValues, currentTrippetTypeCategory, entireLanguages, entireTrips, dispatch]);

  const validateForm = ({ Names }) => {
    if (Names && Names.length > 0) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };


  const handleSubmit = e => {
    e.stopPropagation();
    if (!namesError) {
        e.preventDefault();
        const data = {
            TrippetTypeCategory_ID: +values.TrippetTypeCategory_ID,
            Trip_ID: +values.Trip_ID,
            Icon: values.Icon,
            MaterialIO: values.MaterialIO,
            Comments: values.Comments,
            IsTransport: values.IsTransport,
            SortIndex: values.SortIndex,
            Color: values.Color,
            Names: values.Names,
        };
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changeTrippetTypeCategory(JSON.stringify(data)));
          handleTrippetTypeCategoryModalClose();
        }
      } else {
        if (validateForm(data)) {
          dispatch(createNewTrippetTypeCategory(JSON.stringify(data)));
          handleTrippetTypeCategoryModalClose();
        }
      }
    }
  };

  const clearIcon = () => {
    setValues({...values, Icon: ''});
  };

  const setIcon = file => {
    setValues({...values, Icon: file});
  };
  const setMaterialIO = icon => {
    setValues({...values, MaterialIO: icon});
  };
  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name.indexOf("Name") === 0) // it is Names list
    {
        const names = [];
        values.Names.forEach(name => {
            if ("Name" + name.Language_ID === e.target.name)
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: e.target.value});
            else
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: name.Name});
        });
        setValues({...values, Names: names});
        setNamesError(names.length === 0);
    }
    else
    {
        setValues({...values, [e.target.name]: e.target.value});
    }
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (color) => {
    setValues({...values, Color: color.hex.substring(1)});
  };
  
  const colorChooserStyle =  {
    width: '100%',
    height:'100%',
    borderRadius: '2px',
    background: `#${values.Color}`,
  };

  const renderTitle = defaultValues ? t('Edit Trippet Type Category') : t('New Trippet Type Category');
  const renderNames =
    values.Names && values.Names.length > 0
      ? values.Names.map(name => {
          return (
            <TableRow key={name.Language_ID} className={classes.rowContainer}>
                <TableCell>
                    <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id={"Name" + name.Language_ID}
                    label={name.LanguageAbbreviation}
                    name={"Name" + name.Language_ID}
                    value={name.Name}
                    onChange={handleInputs}
                    />
                </TableCell>
              </TableRow>
          );
        })
      : null;

  const renderNamesError = namesError
    ? t('Names are required.')
    : null;

  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;

  const renderTrips =
    entireTrips && entireTrips.length > 0
    ? entireTrips.map(trip => {
        return (
          <MenuItem value={trip.Trip_ID}>{trip.Name}</MenuItem>
        );
      })
    : null;

  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_TrippetTypes))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_TrippetTypes));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.languageName}>
                <Grid container>
                  
                  <Grid item xs={12} className={classes.languageName}>
                    <FormControl variant="outlined" className={classes.selectView}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        {t('Trip')}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t('Trip')}
                        name="Trip_ID"
                        value={values.Trip_ID}
                        onChange={handleInputs}
                        margin="none"
                        autoFocus
                      >
                        {renderTrips}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={classes.languageName}>
                    <Grid container>
                      <Grid item xs={10} className={classes.languageName}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="Color"
                          label={t('Color')}
                          name="Color"
                          autoComplete="Color"
                          autoFocus
                          value={values.Color}
                          onChange={handleInputs}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <div style={ colorStyles.swatch } onClick={ handleColorClick }>
                          <div style={ colorChooserStyle } />
                        </div>
                        { displayColorPicker ? <div style={ colorStyles.popover }>
                        <div style={ colorStyles.cover } onClick={ handleColorClose }/>
                          <SketchPicker color={ values.Color } onChange={ handleColorChange } />
                        </div> : null }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.languageName}>
                    <Grid container>
                      <Grid item xs={10} className={classes.languageName}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          id="MaterialIO"
                          label={t('MaterialIO')}
                          name="MaterialIO"
                          autoComplete="MaterialIO"
                          value={values.MaterialIO}
                          onChange={handleInputs}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconPicker selectedIcon={values.MaterialIO} iconSelected={setMaterialIO} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}  className={classes.languageName}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      type='number'
                      id="SortIndex"
                      label={t('SortIndex')}
                      name="SortIndex"
                      autoComplete="SortIndex"
                      value={values.SortIndex}
                      onChange={handleInputs}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.languageName}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.IsTransport) === 1}
                            onChange={handleInputs}
                            name="IsTransport"
                            id="IsTransport"
                            color="primary"
                            value={Number(values.IsTransport) === 1 ? 0 : 1}
                        />
                        }
                        label={t('IsTransport')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <PreviewIcon currentImage={values.Icon} pictureUrl={values.Icon} clearIcon={clearIcon} setIcon={setIcon}
                type='language' dispatch={dispatch} t={t} />
              </Grid>
              <Grid item xs={12} md={12} className={classes.planningStatusName}>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  id="Comments"
                  label={t('Meaning (please describe briefly)')}
                  name="Comments"
                  autoComplete="Comments"
                  value={values.Comments}
                  onChange={handleInputs}
                />
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  className={classes.title}
                >
                  {t('Languages')}
                </Typography>
              </Grid>
            </Grid>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                   
                    </TableRow>
                </TableHead>
                <TableBody>{renderNames}</TableBody>
                </Table>
            </TableContainer>
            <FormHelperText className={classes.errorText}>
              {renderNamesError}
            </FormHelperText>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleTrippetTypeCategoryModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default TrippetTypeCategoryModal;