import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import {store} from '../../store';

export const saveCurrentCurrency = payload => ({
  type: adminActionTypes.SAVE_CURRENT_CURRENCY,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_CURRENCY_CURRENT_PAGE,
  payload,
});

export const getCurrencyById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/currency/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentCurrency(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewCurrency = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/currency/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentCurrency(data));
          dispatch(getAllCurrencies(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeCurrency = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/currency/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getCurrencyById(body.Currency_ID));
          dispatch(getAllCurrencies(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllCurrencies = payload => ({
  type: adminActionTypes.SAVE_ALL_CURRENCIES,
  payload,
});

export const saveCurrencySortElement = payload => ({
  type: adminActionTypes.SAVE_CURRENCY_SORT_ELEMENT,
  payload,
});

export const saveCurrencySortByDirection = payload => ({
  type: adminActionTypes.SAVE_CURRENCY_SORT_BY_DIRECTION,
  payload,
});

export const saveCurrencyPaginationData = payload => ({
  type: adminActionTypes.SAVE_CURRENCY_PAGINATION_DATA,
  payload,
});

export const getAllCurrencies = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: ROWS_PER_PAGE,
            Page: store.getState().currency.currentPage,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/currency/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllCurrencies(data.Data));
        dispatch(saveCurrencyPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteCurrency = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/currency/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else 
            dispatch(getAllCurrencies(null));
        })
        .catch(error => console.log(error));
    }
  };
};
