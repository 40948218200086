import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { columns } from "./columns";
import useStyles from "./styles";
import CountryButton from "./CountryButton";
import { getCountryById } from "../../store/actions/actions/country";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { BASE_URL } from "../../config";
import * as userAccess from "../../userAccess";
import CountryModal from "../../components/CountryForm/CountryForm";

const createData = (Country_ID, Code, Icon, MaterialIO, Name, Names) => {
  return {
    Country_ID,
    Code,
    Icon,
    MaterialIO,
    Name,
    Names,
  };
};

const CountryTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedCountry,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedCountry, setClickedCountry] = useState([]);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(
    useSelector(state => state.user.user),
    userAccess.Table_Countries,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.Country_ID,
            item.Code,
            item.Icon,
            item.MaterialIO,
            item.Name,
            item.Names,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedCountry = value => {
    setClickedCountry(value);
  };

  const handleCountryModalOpen = () => {
    setIsCountryModalOpen(true);
  };

  const handleCountryModalClose = () => {
    setIsCountryModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === "actions") {
      return (
        <CountryButton
          data={row}
          handleClickedCountry={handleClickedCountry}
          handleCountryModalOpen={handleCountryModalOpen}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedCountry={setDeletedCountry}
          disabledDelete={disableDelete}
        />
      );
    } else if (column.id === "Icon" && value) {
      return (
        <img
          src={`${BASE_URL}${value.substr(1)}`}
          className={classes.avatar}
          alt="Icon"
        />
      );
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    //dispatch(getCountryById(value.Country_ID));
    //history.push('/countries');
    dispatch(getCountryById(value.Country_ID));
    let recordBody = {
      Country_ID: value.Country_ID,
      Code: value.Code,
      Icon: value.Icon,
      MaterialIO: value.MaterialIO,
      Names: value.Names,
    };
    handleClickedCountry(recordBody);
    handleCountryModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.Country_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0
                    ? classNames(classes.tableCell, classes[row.color])
                    : classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== "actions") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id ? sortedBy : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <CountryModal
        open={isCountryModalOpen}
        handleCountryModalClose={handleCountryModalClose}
        defaultValues={clickedCountry}
      />
    </Paper>
  );
};

export default CountryTable;
