import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { columns } from './columns';
import useStyles from './styles';
import CurrencyButton from './CurrencyButton';
import { getCurrencyById } from '../../store/actions/actions/currency';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import * as userAccess from '../../userAccess';
import CurrencyModal from '../../components/CurrencyForm/CurrencyForm';

const createData = (
    Currency_ID,
    Code,
    Symbol,
    Name,
    Names,
) => {
  return {
    Currency_ID,
    Code,
    Symbol,
    Name,
    Names,
  };
};

const CurrencyTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedCurrency,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedCurrency, setClickedCurrency] = useState([]);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(useSelector(state => state.user.user), userAccess.Table_Currencies);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.Currency_ID,
            item.Code,
            item.Symbol,
            item.Name,
            item.Names,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedCurrency = value => {
    setClickedCurrency(value);
  };

  const handleCurrencyModalOpen = () => {
    setIsCurrencyModalOpen(true);
  };

  const handleCurrencyModalClose = () => {
    setIsCurrencyModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === 'actions') {
        return (
            <CurrencyButton
              data={row}
              handleClickedCurrency={handleClickedCurrency}
              handleCurrencyModalOpen={handleCurrencyModalOpen}
              handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
              setDeletedCurrency={setDeletedCurrency}
              disabledDelete={disableDelete}
            />
          );
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    dispatch(getCurrencyById(value.Currency_ID));
    let recordBody = {
      Currency_ID: value.Currency_ID,
      Code: value.Code,
      Icon: value.Icon,
      MaterialIO: value.MaterialIO,
      Names: value.Names,
    };
    handleClickedCurrency(recordBody);
    handleCurrencyModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.Currency_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0
                    ? classNames(classes.tableCell, classes[row.color])
                    : classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== 'actions') {
                    return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.tableHeaderCell}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            onClick={handleSortBy(column.id)}
                            active={sortedElement === column.id}
                            direction={
                              sortedElement === column.id
                                ? sortedBy
                                : 'asc'
                            }
                          >
                            {t(column.label)}
                          </TableSortLabel>
                        </TableCell>
                      );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <CurrencyModal
        open={isCurrencyModalOpen}
        handleCurrencyModalClose={handleCurrencyModalClose}
        defaultValues={clickedCurrency}
      />
    </Paper>
  );
};

export default CurrencyTable;
