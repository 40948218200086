export const columns = [
  { id: "actions", label: "", minWidth: 60, type: "actions" },
  {
    id: "type",
    label: "Type",
    minWidth: 80,
    type: "TrippetTypeLookName",
    filter: "typelooks",
  },
  { id: "title", label: "Title", minWidth: 80, type: "Title" },
  {
    id: "day",
    label: "Day",
    minWidth: 80,
    type: "StartDay",
    filter: "startdays",
  },
  {
    id: "startDate",
    label: "Start Date",
    minWidth: 80,
    type: "StartDate",
    filter: "startdates",
  },
  {
    id: "startTime",
    label: "Time",
    minWidth: 80,
    type: "StartDate",
  },
  {
    id: "endDate",
    label: "End Date",
    minWidth: 80,
    type: "EndDate",
    filter: "enddates",
  },
  {
    id: "endTime",
    label: "Time",
    minWidth: 80,
    type: "EndDate",
  },
  // {
  //   id: 'link',
  //   label: 'Link',
  //   minWidth: 80,
  //   type: 'Link',
  // },
  // {
  //   id: 'distance',
  //   label: 'Distance',
  //   minWidth: 80,
  //   type: 'Distance',
  // },
  {
    id: "isWholeDay",
    label: "All day",
    minWidth: 80,
    type: "IsWholeDay",
    align: "center",
  },
  {
    id: 'documents',
    label: 'Number of docs',
    minWidth: 80,
    align: 'center'
  },
  {
    id: 'children',
    minWidth: 80,
    align: 'center',
  },
];

export const childrenColumns = [
  { id: "actions", label: "", minWidth: 60, type: "actions" },
  {
    id: "type",
    label: "Type",
    minWidth: 80,
    type: "TrippetTypeLookName",
    filter: "typelooks",
  },
  { id: "title", label: "Title", minWidth: 80, type: "Title" },
  {
    id: "day",
    label: "Day",
    minWidth: 80,
    type: "StartDay",
    filter: "startdays",
  },
  {
    id: "startDate",
    label: "Start Date",
    minWidth: 80,
    type: "StartDate",
    filter: "startdates",
  },
  {
    id: "startTime",
    label: "Time",
    minWidth: 80,
    type: "StartDate",
  },
  {
    id: "endDate",
    label: "End Date",
    minWidth: 80,
    type: "EndDate",
    filter: "enddates",
  },
  {
    id: "endTime",
    label: "Time",
    minWidth: 80,
    type: "EndDate",
  },
  // {
  //   id: 'link',
  //   label: 'Link',
  //   minWidth: 80,
  //   type: 'Link',
  // },
  // {
  //   id: 'distance',
  //   label: 'Distance',
  //   minWidth: 80,
  //   type: 'Distance',
  // },
  {
    id: "isWholeDay",
    label: "All day",
    minWidth: 80,
    type: "IsWholeDay",
    align: "center",
  },
  {
    id: 'documents',
    label: 'Number of docs',
    minWidth: 80,
    align: 'center'
  },
  {
    id: 'dnd',
    minWidth: 80,
    align: 'center',
  },
];
