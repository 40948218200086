import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '15px 0 10px 0',
    textAlign: 'center',
  },
  container: {
    margin: 10,
  },
  backToCockpit: {
    '& > a': {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      color: '#000000',
      textDecoration: 'none',
      fontWeight: 'normal',
      '& > svg': {
        fontSize: 32,
      },
      '& > h2': {
        paddingLeft: 10,
      },
    },
  },
  reportsPageContainer: {
    maxWidth: 1200,
    margin: '0 auto',
    paddingBottom: 60,
  },
  tableMarginTop: {
    marginTop: 34,
  },
  tableRowMarginTop:{
    marginTop: 50,
  },
  chartMargin: {
    marginTop: 34,
    paddingLeft: 30
  },
  tripSelect: {
    minWidth: 300,
  },
  bodySelect: {
    marginLeft: 15,
    minWidth: 200
  },
}));

export default useStyles;
