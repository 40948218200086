import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import {store} from '../../store';

export const saveCurrentSettingItem = payload => ({
  type: adminActionTypes.SAVE_CURRENT_SETTINGITEM,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_SETTINGITEM_CURRENT_PAGE,
  payload,
});

export const getSettingItemById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/setting/item/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentSettingItem(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewSettingItem = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/setting/item/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503 && response.type !== 'cors') {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentSettingItem(data));
          // just the items of my category
          const searchBody = {
            SettingCategory_ID: data.SettingCategory_ID,
              RowsPerPage: ROWS_PER_PAGE,
              Page: store.getState().settingItem.currentPage,
              OrderBy: store.getState().settingItem.sortElement,
              OrderDir: store.getState().settingItem.sortByDirection
          };
          body = JSON.stringify(searchBody);
          dispatch(getAllSettingItems(body));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeSettingItem = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/setting/item/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getSettingItemById(body.SettingItem_ID));
          // just the items of my category
          const item = JSON.parse(body);
          const searchBody = {
              SettingCategory_ID: item.SettingCategory_ID,
              RowsPerPage: ROWS_PER_PAGE,
              Page: store.getState().settingItem.currentPage,
              OrderBy: store.getState().settingItem.sortElement,
              OrderDir: store.getState().settingItem.sortByDirection
          };
          body = JSON.stringify(searchBody);
          dispatch(getAllSettingItems(body));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllSettingItems = payload => ({
  type: adminActionTypes.SAVE_ALL_SETTINGITEMS,
  payload,
});

export const saveSettingItemSortElement = payload => ({
  type: adminActionTypes.SAVE_SETTINGITEM_SORT_ELEMENT,
  payload,
});

export const saveSettingItemSortByDirection = payload => ({
  type: adminActionTypes.SAVE_SETTINGITEM_SORT_BY_DIRECTION,
  payload,
});

export const saveSettingItemPaginationData = payload => ({
  type: adminActionTypes.SAVE_SETTINGITEM_PAGINATION_DATA,
  payload,
});

export const getAllSettingItems = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: ROWS_PER_PAGE,
            Page: store.getState().settingItem.currentPage,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/setting/item/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllSettingItems(data.Data));
        dispatch(saveSettingItemPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteSettingItem = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/setting/item/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else
          {
            // just the items of my category
            const item = JSON.parse(body);
            const searchBody = {
                SettingCategory_ID: item.SettingCategory_ID,
                RowsPerPage: ROWS_PER_PAGE,
                Page: store.getState().settingItem.currentPage,
                OrderBy: store.getState().settingItem.sortElement,
                OrderDir: store.getState().settingItem.sortByDirection
            };
            body = JSON.stringify(searchBody);
            dispatch(getAllSettingItems(body));
          }
        })
        .catch(error => console.log(error));
    }
  };
};
