import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allTrippetTypeLooks: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'SortIndex',
  sortByDirection: 'asc',
  currentTrippetTypeLook: {
    TrippetTypeLook_ID: 0,
    TrippetTypeCategory_ID: 0,
    TrippetTypeCategoryName: '',
    Icon: '',
    SortIndex: 0,
    MaterialIO: '',
    Comments: '',
    Color: '',
    Name: '',
    Names: []
  },
};

const trippetTypeLookReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_TRIPPETTYPELOOK: {
      return {
        ...state,
        currentTrippetTypeLook: {
            TrippetTypeLook_ID: action.payload.TrippetTypeLook_ID,
            TrippetTypeCategory_ID: action.payload.TrippetTypeCategory_ID,
            TrippetTypeCategoryName: action.payload.TrippetTypeCategoryName,
            Icon: action.payload.Icon,
            SortIndex: action.payload.SortIndex,
            MaterialIO: action.payload.MaterialIO,
            Comments: action.payload.Comments,
            Color: action.payload.Color,
            Name: action.payload.Name,
            Names: action.payload.Names,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_TRIPPETTYPELOOKS: {
      return {
        ...state,
        allTrippetTypeLooks: action.payload,
      };
    }
    case adminActionTypes.SAVE_TRIPPETTYPELOOK_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_TRIPPETTYPELOOK_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_TRIPPETTYPELOOK_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_TRIPPETTYPELOOK_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allTrippetTypeLooks: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentTrippetTypeLook: {
            TrippetTypeLook_ID: 0,
            TrippetTypeCategory_ID: 0,
            TrippetTypeCategoryName: '',
            Icon: '',
            SortIndex: 0,
            MaterialIO: '',
            Comments: '',
            Color: '',
            Name: '',
            Names: []
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default trippetTypeLookReducer;