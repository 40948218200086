import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  trippetFormContainer: {
    backgroundColor: 'white',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    marginBottom: 30,
  },
  smallFormLeft: {
    borderRight: '1px solid #aeaeae',
    borderBottom: '1px solid #aeaeae',
  },
  avatar: {
    width: 41,
    height: 41,
    fontSize: 14,
  },
  dropZoneLabel: {
    fontSize: 15,
  },
  errorText: {
    color: theme.palette.secondary.main,
  },
  smallFormRight: {
    marginLeft: 9,
    paddingBottom: 10,
    paddingLeft: 10,
    borderBottom: '1px solid #aeaeae',
  },
  dayRange: {
    fontSize: 22,
    paddingLeft: 10,
  },
  trippetName: {
    width: '100%',
  },
  statusTrippet: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 10,
  },
  typeTrippet: {
    width: '100%',
  },
  timeZoneTrippet: {
    width: '95%',
  },
  trippetDistance: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    '& > svg': {
      marginRight: 5,
    },
    '& > span': {
      marginLeft: 5,
      paddingRight: 5,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  avatarGroup: {
    minHeight: 35,
    '& > div': {
      width: 41,
      height: 41,
      fontSize: 14,
    },
  },
  addParticipants: {
    width: 60,
    height: 35,
    fontSize: 14,
  },
  placeholder: {
    width: 60,
    height: 35,
  },
  expandIcon: {
    marginLeft: 'auto',
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    [theme.breakpoints.up('lg')]: {
      marginRight: 40,
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: '20%',
    },
  },
  messagesContainer: {
    position: 'relative',
    display: 'flex',
    // top: -56,
  },
  allMessagesPlaceholder: {
    position: 'relative',
    display: 'flex',
    paddingBottom: 56,
    border: '2px solid #e0dede',
    height: 500,
    width: '95%',
    marginTop: 10,
    backgroundColor: '#efefef',
    "& > div": {
      margin: 'auto'
    }
  },
  accepted: {
    border: `4px solid #579e38 !important`,
  },
  pending: {
    border: `4px solid ${theme.palette.electricBlue[100]} !important`,
  },
  rejected: {
    border: `4px solid #cc3333 !important`,
  },
  allMessages: {
    position: 'relative',
    display: 'flex',
    paddingBottom: 56,
    border: '2px solid #e0dede',
    height: 500,
    width: '95%',
    marginTop: 10,
  },
  chatMessage: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  typing: {
    paddingLeft: 10,
  },
  messages: {
    padding: 15,
    width: '100%',
    backgroundColor: '#efefef',
    overflowY: 'scroll',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      backgroundColor: '#FFFFFF',
      padding: '5px 10px',
      marginLeft: 10,
      border: `1px solid #e0dede`,
      borderRadius: 10,
      wordWrap: 'break-word',
      display: 'flex',
      alignItems: 'center',
      maxWidth: 180,
      'word-break': 'break-word',
    },
    '& > h3': {
      fontSize: 12,
    },
  },
  leftMessage: {
    justifyContent: 'flex-start',
  },
  rightMessage: {
    justifyContent: 'flex-end',
    '& > h3': {
      marginLeft: 10,
    },
    '& > div': {
      marginLeft: 10,
    },
    '& > p': {
      backgroundColor: theme.palette.electricBlue[100],
      color: '#FFFFFF',
    },
  },
  containerInputs: {
    padding: '20px 10px',
  },
  inputAdornment: {
    '& > div > input': {
      paddingLeft: 10,
    },
  },
  // addButton: {
  //   // borderRadius: '50%',
  //   // height: 60,
  // },
  addTripetButtonText: {
    transition: 'all .7s',
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 170,
    paddingLeft: 8,
  },
  addButton: {
    width: 30,
    transition: 'all .7s',
    backgroundColor: '#008De5',
    paddingLeft: 25,
    borderRadius: 30,
    '& > svg': {
      marginRight: 20,
      marginLeft: 20,
    },
    '&:hover': {
      width: 220,
    },
  },
  bigFormRight: {
    marginTop: 55,
    paddingLeft: 20,
  },
  bigFormLeft: {
    borderRight: '1px solid #aeaeae',
    paddingRight: 1,
  },
  inviteUsersBtn: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 13,
  },
  actionInformation: {
    marginRight: 'auto',
    marginLeft: 25,
    '& > div': {
      '& > span': {
        fontWeight: 900,
      },
    },
  },
  uploadDocument: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    '& > span': {
      marginLeft: 10,
    },
  },
  personalAreaTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  borderTop: {
    borderTop: '1px solid #aeaeae',
  },
  allDayLabel: {
    '& > span.MuiFormControlLabel-label': {
      fontSize: 15,
    },
  },
  linkIcon: {
    cursor: 'pointer',
    color: theme.palette.electricBlue[100],
    transition: 'all .7s',
    '&:hover': {
      color: theme.palette.blueJeans[100],
    },
  },
  leftChat: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > p': {
      borderRadius: 12,
      padding: '5px 10px',
      backgroundColor: '#bab6b6',
      color: 'white',
    },
  },
  joinChat: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > p': {
      borderRadius: 12,
      padding: '5px 10px',
      backgroundColor: theme.palette.freshAir[100],
      color: 'white',
    },
  },
  allChatUsers: {
    borderRight: '2px solid gray',
    flexDirection: 'column',
    display: 'flex',
    width: '250px',
    backgroundColor: '#efefef',
    marginTop: 10,
  },
  chatUser: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    height: 35,

    '& > p': {
      fontSize: 14,
      marginRight: 10,
    },
    '& > span': {
      width: 6,
      height: 6,
      borderRadius: '50%',
    },
  },
  offline: {
    backgroundColor: 'red',
  },
  online: {
    backgroundColor: 'green',
  },
  chatTitle: {
    width: '100%',
    borderBottom: '2px solid gray',
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    backgroundColor: '#bcb1b1',
  },
  avatarImage: {
    height: '100%',
    width: '100%',
  },
  avatarChatImage: {
    borderRadius: '50%',
    width: 40,
    height: 40,
    overflow: 'hidden',
  },
  singleMessage: {
    '& > span': {
      backgroundColor: '#FFFFFF',
      padding: '5px 10px',
      marginLeft: 10,
      border: `1px solid #e0dede`,
      borderRadius: 10,
      wordWrap: 'break-word',
      display: 'inline',
      alignItems: 'center',
      maxWidth: 180,
      'word-break': 'break-word',
    },
  },
  messageTime: {
    '& > p': {
      marginLeft: 10,
      'word-break': 'break-word',
    },
  },
  messageFullName: {
    '& > p': {
      marginLeft: 10,
      'word-break': 'break-word',
      fontSize: 11,
    },
  },
  chatImageContainerRight: {
    width: 40,
    marginLeft: 10,
    '& > div > div': {
      width: 50,
      height: 50,
    },
  },
  chatImageContainerLeft: {
    width: 40,
    marginRight: 10,
    '& > div > div': {
      width: 50,
      height: 50,
    },
  },
  listItemChat: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  listItemChatRight: {
    paddingLeft: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ChatUserInformation: {
    width: '100%',
    alignItems: 'center',
  },
  messageTimeRight: {
    marginLeft: 10,
    '& > p': {
      fontSize: 12,
      cursor: 'help',
    },
  },
  dropZoneDocument: {
    padding: '0 10px 0 0',
    height: 150,
    overflowY: 'scroll',
  },
  infoIcon: {
    fontSize: 20,
    cursor: 'help'
  },
  singleDocument: {
    display: 'flex',
    alignItems: 'center',
    '& > a': {
      marginRight: 1,
    },
    '& > svg': {
      fontSize: 18,
      cursor: 'pointer',
    },
  },
  documentIcon: {
    fontSize: 18,
    marginRight: 7,
  },
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    cursor: 'pointer',
    '& > div': {
      position: 'relative',
      textAlign: 'center',
      maxHeight: 350,
      overflow: 'hidden',
      width: '100%',
      marginBottom: 10,
    },
  },
  deleteIcon: {
    position: 'absolute',
    zIndex: 1,
    top: 10,
    right: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.5);',
    borderRadius: 5,
    transition: 'all .7s',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1);',
    },
  },
  previewImage: {
    width: '350px',
    minHeight: '350px'
  },
  fullSizePreviewImage: {
    width: '100%'
  },
  cameraIcon: {
    width: 60,
    height: 56,
    border: '1px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    marginLeft: 20,
    cursor: 'pointer',
    transition: 'all .4s',
    "&:hover": {
      border: '1px solid black'
    }
  },
  timeContainers: {
    maxWidth: 86
  },
  dateContainers: {
    maxWidth: 160
  },
  destinationFields: {
    maxWidth: '18%',
    "& > div > div": {
      width: '100%'
    }
  },
  swapIcon: {
    "& > svg": {
      position: 'relative',
      top: 15,
      cursor: 'pointer',
      transition: 'all .4s',
      backgroundColor: 'transparent',
      "&:hover": {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: 2,
      }
    }
  },
  tripViewAvatar: {
    width: '41px !important',
    height: '41px !important',
    marginLeft: 5,
    fontSize: '13px !important',
  }
}));

export default useStyles;
