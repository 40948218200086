import React from 'react';
import { Route } from 'react-router-dom';
import VerificationPage from '../pages/verification/Verfication';
import SignIn from '../pages/signIn/SignIn';

function VerificationRoute({ component: Component, ...rest }) {
  const isAuth = localStorage.getItem('token');
  const isVerified = localStorage.getItem('verificationCode');

  const renderPage = (props) => {
    if(isVerified){
      if(isAuth){
        return <Component {...props} />
      }else {
        return <SignIn />
      }
    }else {
      return <VerificationPage />
    }
  }
  return (
    <Route
      {...rest}
      render={props => renderPage(props)}
    />
  );
}

export default VerificationRoute;
