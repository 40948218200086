import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(() => ({
  container: {
    "& > div > div": {
      widt: 280,
    },
  },
  accept: {
    color: "#42ce23",
  },
  decline: {
    color: "#ef2828",
  },
  row: {
    "&nth-child(even)": {
      backgroundColor: "#CCC",
    },
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const PermissionTable = ({ open, handleClose, allRoles, user }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [totalRole, setTotalRole] = useState(null);
  const [rows, setRows] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (allRoles) {
      let role = { ...allRoles[0] };
      // eslint-disable-next-line
      Object.keys(role).map(key => {
        if (role[key] === "0" || role[key] === null) {
          allRoles.forEach(rol => {
            if (rol[key] === "1") {
              role[key] = "1";
            }
          });
        }
      });
      setTotalRole(role);
    }
  }, [allRoles]);

  const findTheIcon = value => {
    if (value === "1") {
      return <CheckCircleOutlineIcon className={classes.accept} />;
    } else {
      return <CancelIcon className={classes.decline} />;
    }
  };

  const createData = (name, permission) => {
    return { name, permission };
  };

  useEffect(() => {
    if (totalRole) {
      renderUserRole();
    }
    // eslint-disable-next-line
  }, [totalRole]);

  const renderUserRole = () => {
    if (totalRole) {
      let object = { ...totalRole };
      delete object.Name;
      delete object.Type;
      delete object.UserRole_ID;
      let allRows = [];
      // eslint-disable-next-line
      Object.keys(object).map(key => {
        let row = createData(key, object[key]);
        allRows.push(row);
      });
      setRows(allRows);
    }
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.container}
      >
        <DialogContent>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Permission</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map(row => (
                    <StyledTableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {findTheIcon(row.permission)}
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PermissionTable;
