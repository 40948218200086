import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { columns } from "./columns";
import useStyles from "./styles";
import PlanningStatusButton from "./PlanningStatusButton";
import { getPlanningStatusById } from "../../store/actions/actions/planningStatus";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import * as userAccess from "../../userAccess";
import PlanningStatusModal from "../../components/PlanningStatusForm/PlanningStatusForm";
import TripFilter from "./TripFilter";

const createData = (
  PlanningStatus_ID,
  Trip_ID,
  Type,
  Name,
  Comments,
  IsFinal,
  SortIndex,
  MaterialIO,
  Color,
  Icon,
  TripName,
  Names,
) => {
  return {
    PlanningStatus_ID,
    Trip_ID,
    Type,
    Name,
    Comments,
    IsFinal,
    SortIndex,
    MaterialIO,
    Color,
    Icon,
    TripName,
    Names,
  };
};

const PlanningStatusTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedPlanningStatus,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedPlanningStatus, setClickedPlanningStatus] = useState([]);
  const [isPlanningStatusModalOpen, setIsPlanningStatusModalOpen] = useState(
    false,
  );
  const filterTrip_ID = useSelector(state => state.planningStatus.filterTrip_ID)
  const [openTripFilter, setOpenTripFilter] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(
    useSelector(state => state.user.user),
    userAccess.Table_PlanningStatuses,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.PlanningStatus_ID,
            item.Trip_ID,
            item.Type,
            item.Name,
            item.Comments,
            item.IsFinal,
            item.SortIndex,
            item.MaterialIO,
            item.Color,
            item.Icon,
            item.TripName,
            item.Names,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedPlanningStatus = value => {
    setClickedPlanningStatus(value);
  };

  const handlePlanningStatusModalOpen = () => {
    setIsPlanningStatusModalOpen(true);
  };

  const handlePlanningStatusModalClose = () => {
    setIsPlanningStatusModalOpen(false);
  };

  // just for TripName
  const handleShowFilter = (id) => {
    setOpenTripFilter(true);
  };

  const setIsOpen = (isOpen) =>
  {
    setOpenTripFilter(isOpen);
  }

  const setSelectedTrip = (trip) =>
  {
    console.log(trip.fullTrip.Trip_ID);
    setOpenTripFilter(false);
  }

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === "actions") {
      return (
        <PlanningStatusButton
          data={row}
          handleClickedPlanningStatus={handleClickedPlanningStatus}
          handlePlanningStatusModalOpen={handlePlanningStatusModalOpen}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedPlanningStatus={setDeletedPlanningStatus}
          disabledDelete={disableDelete}
        />
      );
    } else if (column.id === "Type") {
      if (Number(value) === 1) return "Trips";
      if (Number(value) === 2) return "Trippets";
      return "Both";
    } else if (column.id === 'Color' && value)
    {
      return <span>{value} <span style={{backgroundColor: '#' + value, width: '30px', display: 'inline-block'}}>&nbsp;</span></span>
    } else if (column.id === "IsFinal" && value) {
      return Number.parseInt(value) === 1 ? <CheckIcon /> : null;
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    //dispatch(getPlanningStatusById(value.PlanningStatus_ID));
    //history.push('/planningStatuses');
    dispatch(getPlanningStatusById(value.PlanningStatus_ID));
    let recordBody = {
      PlanningStatus_ID: value.PlanningStatus_ID,
      Trip_ID: value.Trip_ID,
      Type: value.Type,
      Name: value.Name,
      Comments: value.Comments,
      IsFinal: value.IsFinal,
      SortIndex: value.SortIndex,
      MaterialIO: value.MaterialIO,
      Color: value.Color,
      Icon: value.Icon,
      TripName: value.TripName,
      Names: value.Names,
    };
    handleClickedPlanningStatus(recordBody);
    handlePlanningStatusModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.PlanningStatus_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0
                    ? classNames(classes.tableCell, classes[row.color])
                    : classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== "actions") {
                  let activeColor = filterTrip_ID > 0
                      ? classes.activeFilter
                      : "";
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id ? sortedBy : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                      {
                        column.filter ? 
                        (<IconButton
                          onClick={(id) => handleShowFilter(column.id)}
                          className={activeColor}
                          hidden={true}
                          >
                            <FilterListIcon />
                        </IconButton>)
                        : null
                      }
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <PlanningStatusModal
        open={isPlanningStatusModalOpen}
        handlePlanningStatusModalClose={handlePlanningStatusModalClose}
        defaultValues={clickedPlanningStatus}
      />
      <TripFilter
        openTripFilter={openTripFilter}
        setIsOpen={setIsOpen}
        setSelectedTrip={setSelectedTrip}
      />
    </Paper>
  );
};

export default PlanningStatusTable;
