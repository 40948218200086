import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "@material-ui/core/Select";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { useSelector } from "react-redux";
import uniqid from "uniqid";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  modalTitle: {
    paddingBottom: 0,
    "& > h2": {
      fontWeight: "bold",
    },
  },
  errorText: {
    color: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  textField: {
    width: "100%",
  },
  marginBottom: {
    marginBottom: 10,
  }
}));

const GroupingModal = ({ open, handleClose, handleGroupTrippets }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const trippetCategories = useSelector(
    state => state.trippet.trippetCategories,
  );
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const classes = useStyles();
  const [trippetTypesWithCategories, setTrippetTypesWithCategories] = useState(
    null,
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [values, setValues] = useState({
    Title: "",
    TrippetTypeLook_ID: "",
  });

  useEffect(() => {
    if (trippetCategories && trippetTypes) {
      let array = [];
      trippetCategories.forEach(trippetCategory => {
        let category = {
          type: "category",
          name: trippetCategory.Name,
          id: trippetCategory.TrippetTypeCategory_ID,
        };
        array.push(category);
        trippetTypes.forEach(trippetType => {
          if (
            trippetType.TrippetTypeCategory_ID ===
            trippetCategory.TrippetTypeCategory_ID
          ) {
            let type = {
              type: "type",
              name: trippetType.Name,
              id: trippetType.TrippetTypeLook_ID,
            };
            array.push(type);
          }
        });
      });
      setTrippetTypesWithCategories(array);
    }
  }, [trippetTypes, trippetCategories]);

  const renderTrippetTypes =
    trippetTypesWithCategories && trippetTypesWithCategories
      ? trippetTypesWithCategories.map(type => {
          if (type.type === "category") {
            return (
              <MenuItem key={uniqid()} value={`${type.id}+C`} disabled>
                {type.name}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={uniqid()} value={type.id}>
                {type.name}
              </MenuItem>
            );
          }
        })
      : null;

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleGroup = () => {
    if (values.Title && values.TrippetTypeLook_ID) {
      handleGroupTrippets(values);
      handleClose();
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
    }
  };

  const renderError = errorMessage ? (
    <FormHelperText className={classes.errorText}>
      {t("Fields are required.")}
    </FormHelperText>
  ) : null;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
      styles={{ zIndex: 9999 }}
    >
      {" "}
      <DialogTitle id="responsive-dialog-title" className={classes.modalTitle}>
        {t("Group Trippets")}
      </DialogTitle>
      <DialogContent>
        <form className={classes.form}>
          <Grid container>
            <Grid item xs={12} className={classes.marginBottom}>
              <TextField
                variant="outlined"
                name="Title"
                value={values.Title}
                placeholder={t("Trippet Name")}
                label={t("Trippet Name")}
                onChange={handleChange}
                autoFocus
                required
                className={classes.textField}
                id="tripName"
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.marginBottom}>
              <FormControl
                variant="outlined"
                required
                className={classes.textField}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("Trippet type")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label={t("Trippet type")}
                  name="TrippetTypeLook_ID"
                  value={values.TrippetTypeLook_ID}
                  onChange={handleChange}
                  required
                  className={classes.textField}
                >
                  {renderTrippetTypes}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {renderError}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {t("Exit")}
        </Button>
        <Button onClick={handleGroup} color="primary" autoFocus>
          {t("Group")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupingModal;
