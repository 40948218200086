import React, { useEffect, useState } from "react";
import withDashboard from "../../hoc/withDashboard/withDashboard";
import withTitle from "../../hoc/withTitle";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTrippets,
  getAllTrippetPictures,
  getAllTrippetDocuments,
  saveMyTrippetsSortElement,
  saveMyTrippetsSortByDirection,
  clearFilterTrippetResults,
  getFilterData,
  saveTrippetInvitationSortElement,
  saveTrippetInvitationSortByDirection,
  saveTrippetUsedFilters,
  getAllTrippetSendRecommendations,
  getAllReceivedRecommencations,
  getMyTrippetReceivedInvitation,
  getMyTrippetSentInvitation,
} from "../../store/actions/actions/trippet";
import Table from "./Table";
import InvitationTableOne from "./Invitation/Table";
import InvitationTableTwo from "./Invitation/TableViewsTwo/TableTwo";
import InvitationTableThree from "./Invitation/TableViewsThree/TableThree";
import InvitationTableFour from "./Invitation/TableViewsFour/TableFour";
import RecommendationTable from "./RecommendationTable";
import Button from "@material-ui/core/Button";
import TrippetForm from "../../components/TrippetForm/TrippetForm";
import Pagination from "../../components/Pagination/Pagination";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import classNames from "classnames";
import TableTwoView from "./TableViewsTwo/TableTwo";
import TableThreeView from "./TableViewsThree/TableThree";
import TableFourView from "./TableViewsFour/TableFour";
import TableFiveView from "./TableViewsFive/TableFive";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import FilterModal from "../../components/FilterModal/FilterModal";
import { deleteTrippet } from "../../store/actions/actions/trippet";

const MyTrippets = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenTrippetFrom, setIsOpenTrippetForm] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [pageMyTrippets, setPageMyTrippets] = useState(1);
  const [pageMyTrippetsInvitation, setPageMyTrippetsInvitation] = useState(1);
  const [
    pageMyTrippetsRecommendation,
    setPageMyTrippetsRecommendation,
  ] = useState(1);
  const [totalItemsCountMyTrippets, setTotalItemsCountMyTrippets] = useState(0);
  const [
    totalItemsCountMyTrippetsInvitation,
    setTotalItemsCountMyTrippetsInvitation,
  ] = useState(0);
  const [
    totalItemsCountMyTrippetsRecommendation,
    setTotalItemsCountMyTrippetsRecommendation,
  ] = useState(0);
  const [sortedElementMyTrippets, setSortedElementMyTrippets] = useState(
    "ModifiedDate",
  );
  const [sortedByMyTrippets, setSortedByMyTrippets] = useState({
    Title: "asc",
    TrippetTypeLookName: "asc",
    StartDate: "asc",
    EndDate: "asc",
    FromPlace: "asc",
    ToPlace: "asc",
    DisplayName: "asc",
    Day: "asc",
    Price: "asc",
    Link: "asc",
    Description: "asc",
    IsWholeDay: "asc",
    OwnerUser_ID: "asc",
    Distance: "asc",
    TripName: "asc",
    ModifiedDate: "desc",
  });
  const [sortedElementInvitation, setSortedElementInvitation] = useState(
    "ModifiedDate",
  );
  const [sortedByInvitation, setSortedByInvitation] = useState({
    Title: "asc",
    TrippetTypeLookName: "asc",
    StartDate: "asc",
    EndDate: "asc",
    FromPlace: "asc",
    ToPlace: "asc",
    DisplayName: "asc",
    Day: "asc",
    Price: "asc",
    Link: "asc",
    Description: "asc",
    IsWholeDay: "asc",
    OwnerUser_ID: "asc",
    Distance: "asc",
    TripName: "asc",
    ModifiedDate: "desc",
  });
  const [currentTrippet, setCurrentTrippet] = useState(null);
  const user = useSelector(state => state.user.user);
  const allUsers = useSelector(state => state.app.allUsers);
  const trippets = useSelector(state => state.trippet.allMyTrippets);
  const trippetSentInvitationPagination = useSelector(
    state => state.trippet.trippetSentInvitationPagination,
  );
  const trippetRecievedInvitationPagination = useSelector(
    state => state.trippet.trippetRecievedInvitationPagination,
  );
  const trippetSentRecommendationPagination = useSelector(
    state => state.trippet.trippetSentRecommendationPagination,
  );
  const trippetRecievedRecommendationPagination = useSelector(
    state => state.trippet.trippetRecievedRecommendationPagination,
  );

  const paginationDataMyTrippets = useSelector(
    state => state.trippet.paginationDataMyTrippets,
  );
  const allTrippetRecommendations = useSelector(
    state => state.trippet.allTrippetRecommendations,
  );
  const allSentTrippetRecommendations = useSelector(
    state => state.trippet.allSentTrippetRecommendations,
  );
  const allTrippetsPictures = useSelector(
    state => state.trippet.allTrippetsPictures,
  );
  const allTrippetsDocuments = useSelector(
    state => state.trippet.allTrippetsDocuments,
  );
  const trippetSentInvitation = useSelector(
    state => state.trippet.trippetSentInvitation,
  );
  const trippetReceivedInvitation = useSelector(
    state => state.trippet.trippetReceivedInvitation,
  );
  const filterData = useSelector(state => state.trippet.filterData);
  const [isMyRecommendations, setIsMyRecommendations] = useState(true);
  const [recommendationType, setRecommendationType] = useState("received");
  const [deletedTrippet, setDeletedTrippet] = useState(null);
  const [allAppUsers, setAllAppUsers] = useState(null);
  const [isOpenConfirmDeleteModal, setisOpenConfirmDeleteModal] = useState(
    false,
  );
  const [myTrippetsView, setMyTrippetsView] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
    fiveth: false,
  });
  const [myTrippetsInvitationsView, setMyTrippetsInvitationsView] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
  });
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [clickedFilter, setClickedFilter] = useState(null);
  const [filterResults, setFilterResults] = useState([]);
  const [usedFilters, setUsedFilets] = useState([]);
  const [disabledFilters, setDisabledFilters] = useState({
    startdates: false,
    enddates: false,
    participants: false,
    organizers: false,
    proxies: false,
    typelooks: false,
    owners: false,
    ownerplanningstatuses: false,
    title: false,
    link: false,
    isprivates: false,
    startdays: false,
  });
  const [activeFilters, setActiveFilters] = useState({
    startdates: false,
    enddates: false,
    participants: false,
    organizers: false,
    proxies: false,
    typelooks: false,
    owners: false,
    ownerplanningstatuses: false,
    title: false,
    link: false,
    isprivates: false,
    startdays: false,
  });
  const [lastUsedInvitationTable, setLastUsedInvitationTable] = useState(
    "received",
  );
  const [tripppetInvitationTable, setTripppetInvitationTable] = useState(null);
  const [invitationActiveClass, setInvitationActiveClass] = useState({
    sent: false,
    received: true,
  });
  const [recommendationctiveClass, setRecommendationctiveClass] = useState({
    sent: false,
    received: true,
  });

  const takeAllMyTrippets = () => {
    const trippetBody = {
      Language_ID: user.Language_ID,
      Page: pageMyTrippets,
      RowsPerPage: 10,
      Type: 2,
      OrderBy: sortedElementMyTrippets,
      OrderDir: sortedByMyTrippets[sortedElementMyTrippets],
      User_ID: user.User_ID,
    };

    if (filterResults.length > 0) {
      dispatch(saveTrippetUsedFilters(filterResults));
      filterResults.forEach(item => {
        if (item !== [] || item !== "") {
          trippetBody[item.name] = item.body;
        }
      });
    } else {
      dispatch(saveTrippetUsedFilters(null));
    }
    dispatch(getAllTrippets(JSON.stringify(trippetBody)));
  };

  useEffect(() => {
    if (user) {
      dispatch(getAllTrippetPictures());
      dispatch(getAllTrippetDocuments());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      if (lastUsedInvitationTable === "sent") {
        const sentInvitation = {
          Language_ID: user.Language_ID,
          Type: 4,
          User_ID: user.User_ID,
          OrderBy: "ModifiedDate",
          OrderDir: "desc",
          RowsPerPage: 10,
          Page: pageMyTrippetsInvitation,
        };

        dispatch(getMyTrippetSentInvitation(JSON.stringify(sentInvitation)));
      } else {
        const receivedInvitation = {
          Language_ID: user.Language_ID,
          Type: 3,
          User_ID: user.User_ID,
          OrderBy: "ModifiedDate",
          OrderDir: "desc",
          RowsPerPage: 10,
          Page: pageMyTrippetsInvitation,
        };
        dispatch(
          getMyTrippetReceivedInvitation(JSON.stringify(receivedInvitation)),
        );
      }
    }
  }, [dispatch, user, pageMyTrippetsInvitation, lastUsedInvitationTable]);

  useEffect(() => {
    if (paginationDataMyTrippets) {
      setTotalItemsCountMyTrippets(+paginationDataMyTrippets.Num);
    }
  }, [paginationDataMyTrippets]);

  useEffect(() => {
    if (
      lastUsedInvitationTable &&
      trippetRecievedInvitationPagination &&
      trippetSentInvitationPagination
    ) {
      if (lastUsedInvitationTable === "sent") {
        setTotalItemsCountMyTrippetsInvitation(
          +trippetSentInvitationPagination.Num,
        );
      } else {
        setTotalItemsCountMyTrippetsInvitation(
          +trippetRecievedInvitationPagination.Num,
        );
      }
    }
  }, [
    trippetRecievedInvitationPagination,
    lastUsedInvitationTable,
    trippetSentInvitationPagination,
  ]);

  useEffect(() => {
    if (
      recommendationType &&
      trippetRecievedRecommendationPagination &&
      trippetSentRecommendationPagination
    ) {
      if (recommendationType === "sent") {
        setTotalItemsCountMyTrippetsRecommendation(
          +trippetSentRecommendationPagination.Num,
        );
      } else {
        setTotalItemsCountMyTrippetsRecommendation(
          +trippetRecievedRecommendationPagination.Num,
        );
      }
    }
  }, [
    trippetRecievedRecommendationPagination,
    recommendationType,
    trippetSentRecommendationPagination,
  ]);

  useEffect(() => {
    setAllAppUsers(allUsers);
  }, [allUsers]);

  useEffect(() => {
    if (user) {
      if (recommendationType === "sent") {
        const body = {
          TrippetRecommendationSenderUser_ID: user.User_ID,
          Page: pageMyTrippetsRecommendation,
          RowsPerPage: 10,
        };
        dispatch(getAllTrippetSendRecommendations(JSON.stringify(body)));
      } else {
        const body = {
          TrippetRecommendationRecipientUser_ID: user.User_ID,
          Page: pageMyTrippetsRecommendation,
          RowsPerPage: 10,
        };
        dispatch(getAllReceivedRecommencations(JSON.stringify(body)));
      }
    }
  }, [dispatch, pageMyTrippetsRecommendation, recommendationType, user]);

  const handleTrippetFormClose = () => {
    setIsOpenTrippetForm(false);
    takeAllMyTrippets();
  };

  const handleTrippetFormOpen = trippet => {
    setIsOpenTrippetForm(true);
    setCurrentTrippet(trippet);
  };

  const handleConfirmModalClose = () => {
    setIsOpenConfirmModal(false);
  };

  const handleConfirmModalOpen = () => {
    setIsOpenConfirmModal(true);
  };

  const handleRecommendations = value => () => {
    setIsMyRecommendations(value);
    if (value) {
      setRecommendationType("received");
      setPageMyTrippetsRecommendation(1);
      setRecommendationctiveClass({
        received: true,
        sent: false,
      });
    } else {
      setRecommendationType("sent");
      setPageMyTrippetsRecommendation(1);
      setRecommendationctiveClass({
        received: false,
        sent: true,
      });
    }
  };

  const handleConfirmDeleteModalClose = () => {
    setisOpenConfirmDeleteModal(false);
  };

  const handleConfirmDeleteModalOpen = () => {
    setisOpenConfirmDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(
      deleteTrippet(
        deletedTrippet.body,
        deletedTrippet.trip_id,
        user.User_ID,
        sortedElementMyTrippets,
        sortedByMyTrippets[sortedElementMyTrippets],
        filterResults,
      ),
    );
    takeAllMyTrippets();
  };

  const findTheTrippetPictureId = trippetId => {
    let id = "";
    allTrippetsPictures &&
      allTrippetsPictures.forEach(trippetPicture => {
        if (trippetPicture.Trippet_ID === trippetId) {
          id = trippetPicture.TrippetPicture_ID;
        }
      });

    return id;
  };

  const findPublicTrippetDocument = trippetId => {
    let document = "";
    allTrippetsDocuments &&
      allTrippetsDocuments.forEach(trippetDocument => {
        if (trippetDocument.Trippet_ID === trippetId) {
          if (trippetDocument.IsPrivate === "0") {
            document = trippetDocument;
          }
        }
      });

    return document;
  };

  const findPrivateTrippetDocument = trippetId => {
    let document = "";
    allTrippetsDocuments &&
      allTrippetsDocuments.forEach(trippetDocument => {
        if (trippetDocument.Trippet_ID === trippetId) {
          if (trippetDocument.IsPrivate === "1") {
            document = trippetDocument;
          }
        }
      });

    return document;
  };

  const renderTable = isMyRecommendations
    ? allTrippetRecommendations
    : allSentTrippetRecommendations;

  const handleTableViewAllTrippets = view => () => {
    setMyTrippetsView({
      first: false,
      second: false,
      third: false,
      fourth: false,
      fiveth: false,
      [view]: true,
    });
  };

  const handleTableViewAllTrippetsInvitations = view => () => {
    setMyTrippetsInvitationsView({
      first: false,
      second: false,
      third: false,
      fourth: false,
      [view]: true,
    });
  };

  const resetSortByMyTrippets = () => {
    setSortedByMyTrippets({
      Title: "asc",
      TrippetTypeLookName: "asc",
      StartDate: "asc",
      EndDate: "asc",
      FromPlace: "asc",
      ToPlace: "asc",
      DisplayName: "asc",
      Day: "asc",
      Price: "asc",
      Link: "asc",
      Description: "asc",
      IsWholeDay: "asc",
      OwnerUser_ID: "asc",
      Distance: "asc",
      TripName: "asc",
      ModifiedDate: "asc",
    });
  };

  const resetSortByInvitation = () => {
    setSortedByInvitation({
      Title: "asc",
      TrippetTypeLookName: "asc",
      StartDate: "asc",
      EndDate: "asc",
      FromPlace: "asc",
      ToPlace: "asc",
      DisplayName: "asc",
      Day: "asc",
      Price: "asc",
      Link: "asc",
      Description: "asc",
      IsWholeDay: "asc",
      OwnerUser_ID: "asc",
      Distance: "asc",
      TripName: "asc",
      ModifiedDate: "asc",
    });
  };

  useEffect(() => {
    if (user) {
      const trippetBody = {
        Language_ID: user.Language_ID,
        Page: pageMyTrippets,
        RowsPerPage: 10,
        Type: 2,
        OrderBy: sortedElementMyTrippets,
        OrderDir: sortedByMyTrippets[sortedElementMyTrippets],
        User_ID: user.User_ID,
      };

      if (filterResults.length > 0) {
        dispatch(saveTrippetUsedFilters(filterResults));
        filterResults.forEach(item => {
          if (item !== [] || item !== "") {
            trippetBody[item.name] = item.body;
          }
        });
      } else {
        dispatch(saveTrippetUsedFilters(null));
      }

      dispatch(getAllTrippets(JSON.stringify(trippetBody)));
    }
  }, [
    dispatch,
    filterResults,
    pageMyTrippets,
    sortedByMyTrippets,
    sortedElementMyTrippets,
    user,
    user.User_ID,
  ]);

  const handleSortByMyTrippets = id => () => {
    switch (id) {
      case "type":
        setSortedElementMyTrippets("TrippetTypeLookName");
        dispatch(saveMyTrippetsSortElement("TrippetTypeLookName"));
        if (sortedByMyTrippets.TrippetTypeLookName === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            TrippetTypeLookName: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              TrippetTypeLookName: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            TrippetTypeLookName: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              TrippetTypeLookName: "asc",
            }),
          );
        }
        break;
      case "lastChanged":
        setSortedElementMyTrippets("ModifiedDate");
        dispatch(saveMyTrippetsSortElement("ModifiedDate"));
        if (sortedByMyTrippets.ModifiedDate === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            ModifiedDate: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              ModifiedDate: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            ModifiedDate: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              ModifiedDate: "asc",
            }),
          );
        }
        break;
      case "tripTitle":
        setSortedElementMyTrippets("TripName");
        dispatch(saveMyTrippetsSortElement("TripName"));
        if (sortedByMyTrippets.TripName === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            TripName: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              TripName: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            TripName: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              TripName: "asc",
            }),
          );
        }
        break;
      case "title":
        setSortedElementMyTrippets("Title");
        dispatch(saveMyTrippetsSortElement("Title"));
        if (sortedByMyTrippets.Title === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            Title: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              Title: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            Title: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              Title: "asc",
            }),
          );
        }
        break;
      case "startDate":
        setSortedElementMyTrippets("StartDate");
        dispatch(saveMyTrippetsSortElement("StartDate"));
        if (sortedByMyTrippets.StartDate === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            StartDate: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              StartDate: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            StartDate: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              StartDate: "asc",
            }),
          );
        }
        break;
      case "startTime":
        setSortedElementMyTrippets("StartDate");
        dispatch(saveMyTrippetsSortElement("StartDate"));
        if (sortedByMyTrippets.StartDate === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            StartDate: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              StartDate: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            StartDate: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              StartDate: "asc",
            }),
          );
        }
        break;
      case "endDate":
        setSortedElementMyTrippets("EndDate");
        dispatch(saveMyTrippetsSortElement("EndDate"));
        if (sortedByMyTrippets.EndDate === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            EndDate: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              EndDate: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            EndDate: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              EndDate: "asc",
            }),
          );
        }
        break;
      case "endTime":
        setSortedElementMyTrippets("EndDate");
        dispatch(saveMyTrippetsSortElement("EndDate"));
        if (sortedByMyTrippets.EndDate === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            EndDate: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              EndDate: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            EndDate: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              EndDate: "asc",
            }),
          );
        }
        break;
      case "from":
        setSortedElementMyTrippets("FromPlace");
        dispatch(saveMyTrippetsSortElement("FromPlace"));
        if (sortedByMyTrippets.FromPlace === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            FromPlace: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              FromPlace: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            FromPlace: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              FromPlace: "asc",
            }),
          );
        }
        break;
      case "to":
        setSortedElementMyTrippets("ToPlace");
        dispatch(saveMyTrippetsSortElement("ToPlace"));
        if (sortedByMyTrippets.ToPlace === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            ToPlace: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              ToPlace: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            ToPlace: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              ToPlace: "asc",
            }),
          );
        }
        break;
      case "participants":
        setSortedElementMyTrippets("DisplayName");
        dispatch(saveMyTrippetsSortElement("DisplayName"));
        if (sortedByMyTrippets.DisplayName === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            DisplayName: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              DisplayName: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            DisplayName: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              DisplayName: "asc",
            }),
          );
        }
        break;
      case "trippetOwner":
        setSortedElementMyTrippets("OwnerUser_ID");
        dispatch(saveMyTrippetsSortElement("OwnerUser_ID"));
        if (sortedByMyTrippets.OwnerUser_ID === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            OwnerUser_ID: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              OwnerUser_ID: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            OwnerUser_ID: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              OwnerUser_ID: "asc",
            }),
          );
        }
        break;
      case "day":
        setSortedElementMyTrippets("Days");
        dispatch(saveMyTrippetsSortElement("Days"));
        if (sortedByMyTrippets.Days === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            Days: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              Days: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            Days: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              Days: "asc",
            }),
          );
        }
        break;
      case "price":
        setSortedElementMyTrippets("Price");
        dispatch(saveMyTrippetsSortElement("Price"));
        if (sortedByMyTrippets.Price === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            Price: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              Price: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            Price: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              Price: "asc",
            }),
          );
        }
        break;
      case "link":
        setSortedElementMyTrippets("Link");
        dispatch(saveMyTrippetsSortElement("Link"));
        if (sortedByMyTrippets.Link === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            Link: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              Link: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            Link: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              Link: "asc",
            }),
          );
        }
        break;
      case "distance":
        setSortedElementMyTrippets("Distance");
        dispatch(saveMyTrippetsSortElement("Distance"));
        if (sortedByMyTrippets.Distance === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            Distance: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              Distance: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            Distance: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              Distance: "asc",
            }),
          );
        }
        break;
      case "isWholeDay":
        setSortedElementMyTrippets("IsWholeDay");
        dispatch(saveMyTrippetsSortElement("IsWholeDay"));
        if (sortedByMyTrippets.IsWholeDay === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            IsWholeDay: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              IsWholeDay: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            IsWholeDay: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              IsWholeDay: "asc",
            }),
          );
        }
        break;
      case "isPrivate":
        setSortedElementMyTrippets("IsPrivate");
        dispatch(saveMyTrippetsSortElement("IsPrivate"));
        if (sortedByMyTrippets.IsPrivate === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            IsPrivate: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              IsPrivate: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            IsPrivate: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              IsPrivate: "asc",
            }),
          );
        }
        break;
      case "status":
        setSortedElementMyTrippets("OwnerPlanningStatus_ID");
        dispatch(saveMyTrippetsSortElement("OwnerPlanningStatus_ID"));
        if (sortedByMyTrippets.OwnerPlanningStatus_ID === "asc") {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            OwnerPlanningStatus_ID: "desc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              OwnerPlanningStatus_ID: "desc",
            }),
          );
        } else {
          resetSortByMyTrippets();
          setSortedByMyTrippets({
            ...sortedByMyTrippets,
            OwnerPlanningStatus_ID: "asc",
          });
          dispatch(
            saveMyTrippetsSortByDirection({
              ...sortedByMyTrippets,
              OwnerPlanningStatus_ID: "asc",
            }),
          );
        }
        break;
      default:
        break;
    }
  };

  const handleSortByMyInvitation = id => () => {
    switch (id) {
      case "type":
        setSortedElementInvitation("TrippetTypeLookName");
        dispatch(saveTrippetInvitationSortElement("TrippetTypeLookName"));
        if (sortedByMyTrippets.TrippetTypeLookName === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            TrippetTypeLookName: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              TrippetTypeLookName: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            TrippetTypeLookName: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              TrippetTypeLookName: "asc",
            }),
          );
        }
        break;
      case "lastChanged":
        setSortedElementInvitation("ModifiedDate");
        dispatch(saveTrippetInvitationSortElement("ModifiedDate"));
        if (sortedByMyTrippets.ModifiedDate === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            ModifiedDate: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              ModifiedDate: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            ModifiedDate: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              ModifiedDate: "asc",
            }),
          );
        }
        break;
      case "tripTitle":
        setSortedElementInvitation("TripName");
        dispatch(saveTrippetInvitationSortElement("TripName"));
        if (sortedByMyTrippets.TripName === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            TripName: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              TripName: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            TripName: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              TripName: "asc",
            }),
          );
        }
        break;
      case "title":
        setSortedElementInvitation("Title");
        dispatch(saveTrippetInvitationSortElement("Title"));
        if (sortedByMyTrippets.Title === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            Title: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              Title: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            Title: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              Title: "asc",
            }),
          );
        }
        break;
      case "startDate":
        setSortedElementInvitation("StartDate");
        dispatch(saveTrippetInvitationSortElement("StartDate"));
        if (sortedByMyTrippets.StartDate === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            StartDate: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              StartDate: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            StartDate: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              StartDate: "asc",
            }),
          );
        }
        break;
      case "startTime":
        setSortedElementInvitation("StartDate");
        dispatch(saveTrippetInvitationSortElement("StartDate"));
        if (sortedByMyTrippets.StartDate === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            StartDate: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              StartDate: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            StartDate: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              StartDate: "asc",
            }),
          );
        }
        break;
      case "endDate":
        setSortedElementInvitation("EndDate");
        dispatch(saveTrippetInvitationSortElement("EndDate"));
        if (sortedByMyTrippets.EndDate === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            EndDate: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              EndDate: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            EndDate: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              EndDate: "asc",
            }),
          );
        }
        break;
      case "endTime":
        setSortedElementInvitation("EndDate");
        dispatch(saveTrippetInvitationSortElement("EndDate"));
        if (sortedByMyTrippets.EndDate === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            EndDate: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              EndDate: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            EndDate: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              EndDate: "asc",
            }),
          );
        }
        break;
      case "from":
        setSortedElementInvitation("FromPlace");
        dispatch(saveTrippetInvitationSortElement("FromPlace"));
        if (sortedByMyTrippets.FromPlace === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            FromPlace: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              FromPlace: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            FromPlace: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              FromPlace: "asc",
            }),
          );
        }
        break;
      case "to":
        setSortedElementInvitation("ToPlace");
        dispatch(saveTrippetInvitationSortElement("ToPlace"));
        if (sortedByMyTrippets.ToPlace === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            ToPlace: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              ToPlace: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            ToPlace: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              ToPlace: "asc",
            }),
          );
        }
        break;
      case "participants":
        setSortedElementInvitation("DisplayName");
        dispatch(saveTrippetInvitationSortElement("DisplayName"));
        if (sortedByMyTrippets.DisplayName === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            DisplayName: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              DisplayName: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            DisplayName: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              DisplayName: "asc",
            }),
          );
        }
        break;
      case "trippetOwner":
        setSortedElementInvitation("OwnerUser_ID");
        dispatch(saveTrippetInvitationSortElement("OwnerUser_ID"));
        if (sortedByMyTrippets.OwnerUser_ID === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            OwnerUser_ID: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              OwnerUser_ID: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            OwnerUser_ID: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              OwnerUser_ID: "asc",
            }),
          );
        }
        break;
      case "day":
        setSortedElementInvitation("Days");
        dispatch(saveTrippetInvitationSortElement("Days"));
        if (sortedByMyTrippets.Days === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            Days: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              Days: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            Days: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              Days: "asc",
            }),
          );
        }
        break;
      case "price":
        setSortedElementInvitation("Price");
        dispatch(saveTrippetInvitationSortElement("Price"));
        if (sortedByMyTrippets.Price === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            Price: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              Price: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            Price: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              Price: "asc",
            }),
          );
        }
        break;
      case "link":
        setSortedElementInvitation("Link");
        dispatch(saveTrippetInvitationSortElement("Link"));
        if (sortedByMyTrippets.Link === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            Link: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              Link: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            Link: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              Link: "asc",
            }),
          );
        }
        break;
      case "distance":
        setSortedElementInvitation("Distance");
        dispatch(saveTrippetInvitationSortElement("Distance"));
        if (sortedByMyTrippets.Distance === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            Distance: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              Distance: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            Distance: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              Distance: "asc",
            }),
          );
        }
        break;
      case "isWholeDay":
        setSortedElementInvitation("IsWholeDay");
        dispatch(saveTrippetInvitationSortElement("IsWholeDay"));
        if (sortedByMyTrippets.IsWholeDay === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            IsWholeDay: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              IsWholeDay: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            IsWholeDay: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              IsWholeDay: "asc",
            }),
          );
        }
        break;
      case "isPrivate":
        setSortedElementInvitation("IsPrivate");
        dispatch(saveTrippetInvitationSortElement("IsPrivate"));
        if (sortedByMyTrippets.IsPrivate === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            IsPrivate: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              IsPrivate: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            IsPrivate: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              IsPrivate: "asc",
            }),
          );
        }
        break;
      case "status":
        setSortedElementInvitation("OwnerPlanningStatus_ID");
        dispatch(saveTrippetInvitationSortElement("OwnerPlanningStatus_ID"));
        if (sortedByMyTrippets.OwnerPlanningStatus_ID === "asc") {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            OwnerPlanningStatus_ID: "desc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              OwnerPlanningStatus_ID: "desc",
            }),
          );
        } else {
          resetSortByInvitation();
          setSortedByInvitation({
            ...sortedByMyTrippets,
            OwnerPlanningStatus_ID: "asc",
          });
          dispatch(
            saveTrippetInvitationSortByDirection({
              ...sortedByMyTrippets,
              OwnerPlanningStatus_ID: "asc",
            }),
          );
        }
        break;
      default:
        break;
    }
  };

  const getNumberOfActiveFilters = column => {
    let number = 0;
    if (filterResults && filterResults.length > 0) {
      filterResults.forEach(filter => {
        if (filter.type === column.filter) {
          number = filter.data.length;
        }
      });
    }
    return number;
  };

  const renderTableAllMyTrippetsTables = () => {
    if (myTrippetsView.first) {
      return (
        <Table
          trippets={trippets}
          handleTrippetFormOpen={handleTrippetFormOpen}
          takeAllMyTrippets={takeAllMyTrippets}
          isOpenTrippetFrom={isOpenTrippetFrom}
          type="my-trippets"
          allUsers={allUsers}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedTrippet={setDeletedTrippet}
          findTheTrippetPictureId={findTheTrippetPictureId}
          findPublicTrippetDocument={findPublicTrippetDocument}
          findPrivateTrippetDocument={findPrivateTrippetDocument}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          sortedElement={sortedElementMyTrippets}
          sortedBy={sortedByMyTrippets}
          handleSortBy={handleSortByMyTrippets}
          getNumberOfActiveFilters={getNumberOfActiveFilters}
        />
      );
    } else if (myTrippetsView.second) {
      return (
        <TableTwoView
          trippets={trippets}
          handleTrippetFormOpen={handleTrippetFormOpen}
          takeAllMyTrippets={takeAllMyTrippets}
          isOpenTrippetFrom={isOpenTrippetFrom}
          type="my-trippets"
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedTrippet={setDeletedTrippet}
          findTheTrippetPictureId={findTheTrippetPictureId}
          findPublicTrippetDocument={findPublicTrippetDocument}
          findPrivateTrippetDocument={findPrivateTrippetDocument}
          allUsers={allAppUsers}
          sortedElement={sortedElementMyTrippets}
          sortedBy={sortedByMyTrippets}
          handleSortBy={handleSortByMyTrippets}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          getNumberOfActiveFilters={getNumberOfActiveFilters}
        />
      );
    } else if (myTrippetsView.third) {
      return (
        <TableThreeView
          trippets={trippets}
          handleTrippetFormOpen={handleTrippetFormOpen}
          takeAllMyTrippets={takeAllMyTrippets}
          isOpenTrippetFrom={isOpenTrippetFrom}
          allUsers={allUsers}
          type="my-trippets"
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedTrippet={setDeletedTrippet}
          findTheTrippetPictureId={findTheTrippetPictureId}
          findPublicTrippetDocument={findPublicTrippetDocument}
          findPrivateTrippetDocument={findPrivateTrippetDocument}
          sortedElement={sortedElementMyTrippets}
          sortedBy={sortedByMyTrippets}
          handleSortBy={handleSortByMyTrippets}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          getNumberOfActiveFilters={getNumberOfActiveFilters}
        />
      );
    } else if (myTrippetsView.fourth) {
      return (
        <TableFourView
          trippets={trippets}
          handleTrippetFormOpen={handleTrippetFormOpen}
          takeAllMyTrippets={takeAllMyTrippets}
          isOpenTrippetFrom={isOpenTrippetFrom}
          allUsers={allUsers}
          type="my-trippets"
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedTrippet={setDeletedTrippet}
          findTheTrippetPictureId={findTheTrippetPictureId}
          findPublicTrippetDocument={findPublicTrippetDocument}
          findPrivateTrippetDocument={findPrivateTrippetDocument}
          sortedElement={sortedElementMyTrippets}
          sortedBy={sortedByMyTrippets}
          handleSortBy={handleSortByMyTrippets}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          getNumberOfActiveFilters={getNumberOfActiveFilters}
        />
      );
    } else if (myTrippetsView.fiveth) {
      return (
        <TableFiveView
          trippets={trippets}
          handleTrippetFormOpen={handleTrippetFormOpen}
          takeAllMyTrippets={takeAllMyTrippets}
          isOpenTrippetFrom={isOpenTrippetFrom}
          allUsers={allUsers}
          type="my-trippets"
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedTrippet={setDeletedTrippet}
          findTheTrippetPictureId={findTheTrippetPictureId}
          findPublicTrippetDocument={findPublicTrippetDocument}
          findPrivateTrippetDocument={findPrivateTrippetDocument}
          sortedElement={sortedElementMyTrippets}
          sortedBy={sortedByMyTrippets}
          handleSortBy={handleSortByMyTrippets}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          getNumberOfActiveFilters={getNumberOfActiveFilters}
        />
      );
    }
  };

  const handleOpenFilterModal = column => () => {
    if (column.filter) {
      setIsFilterModalOpen(true);
      setClickedFilter(column);
      let body = {
        path: column.filter,
        data: {
          Language_ID: user.Language_ID,
          User_ID: user.User_ID,
          OrderBy: column.type,
          OrderDir: "asc",
          // Type: '2',
        },
      };
      if (filterResults.length > 0) {
        filterResults.forEach(item => {
          if (item !== [] || item !== "") {
            if (item.type !== column.filter) {
              body.data[item.name] = item.body;
            }
          }
        });
      }
      if (body.path !== "title" && body.path !== "link") {
        dispatch(getFilterData(body));
      }
    }
  };

  const checkUsedFilters = filterName => {
    let status = "";
    if (usedFilters.length > 0) {
      usedFilters.forEach(filter => {
        if (filter === filterName) {
          status = false;
        } else {
          status = true;
        }
      });
    } else {
      status = true;
    }
    return status;
  };

  const checkActiveFilters = filters => {
    let allFilters = {
      startdates: false,
      enddates: false,
      participants: false,
      typelooks: false,
      owners: false,
      ownerplanningstatuses: false,
      title: false,
      link: false,
    };
    if (filters.length > 0) {
      filters.forEach(filter => {
        allFilters = {
          ...allFilters,
          [filter]: true,
        };
      });
    } else {
      allFilters = {
        startdates: false,
        enddates: false,
        participants: false,
        organizers: false,
        proxies: false,
        typelooks: false,
        owners: false,
        ownerplanningstatuses: false,
        title: false,
        link: false,
      };
    }
    setActiveFilters(allFilters);
  };

  useEffect(() => {
    checkActiveFilters(usedFilters);
    if (usedFilters.length >= 3) {
      let filters = {
        startdates: true,
        enddates: true,
        participants: true,
        organizers: true,
        proxies: true,
        typelooks: true,
        owners: true,
        ownerplanningstatuses: true,
        title: true,
        link: true,
        isprivates: true,
        startdays: true,
      };
      usedFilters.forEach(filter => {
        filters = {
          ...filters,
          [filter]: false,
        };
      });
      setDisabledFilters(filters);
    } else {
      setDisabledFilters({
        startdates: false,
        enddates: false,
        participants: false,
        organizers: false,
        proxies: false,
        typelooks: false,
        owners: false,
        ownerplanningstatuses: false,
        title: false,
        link: false,
        isprivates: false,
        startdays: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedFilters]);

  const clearFilteredData = name => {
    if (name) {
      let array = [...usedFilters];
      array.forEach((filter, index) => {
        if (filter === name) {
          array.splice(index, 1);
        }
      });
      setUsedFilets(array);
    }
  };

  const handleFilterValues = (item, values) => {
    let array = [...filterResults];
    if (item === "startdates") {
      if (values && values.length === 0) {
        clearFilteredData("startdates");
        array.forEach((item, index) => {
          if (item.type === "startdates") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "startdates") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "StartDates",
          body: [],
          type: "startdates",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.StartDate);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "typelooks") {
      if (values && values.length === 0) {
        clearFilteredData("typelooks");
        array.forEach((item, index) => {
          if (item.type === "typelooks") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "typelooks") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "TrippetTypeLook_IDs",
          body: [],
          type: "typelooks",
          data: values,
        };
        values.forEach(item => {
          data.body.push(+item.TrippetTypeLook_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "enddates") {
      if (values && values.length === 0) {
        clearFilteredData("enddates");
        array.forEach((item, index) => {
          if (item.type === "enddates") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "enddates") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "EndDates",
          body: [],
          type: "enddates",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.EndDate);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "participants") {
      if (values && values.length === 0) {
        clearFilteredData("participants");
        array.forEach((item, index) => {
          if (item.type === "participants") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "participants") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "TrippetParticipantUser_IDs",
          body: [],
          type: "participants",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.TrippetParticipantUser_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "organizers") {
      if (values && values.length === 0) {
        clearFilteredData("organizers");
        array.forEach((item, index) => {
          if (item.type === "organizers") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "organizers") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "TrippetOrganizerUser_IDs",
          body: [],
          type: "organizers",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.TrippetOrganizerUser_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "proxies") {
      if (values && values.length === 0) {
        clearFilteredData("proxies");
        array.forEach((item, index) => {
          if (item.type === "proxies") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "proxies") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "TrippetProxyUser_IDs",
          body: [],
          type: "proxies",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.TrippetProxyUser_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "owners") {
      if (values && values.length === 0) {
        clearFilteredData("owners");
        array.forEach((item, index) => {
          if (item.type === "owners") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "owners") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "OwnerUser_IDs",
          body: [],
          type: "owners",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.OwnerUser_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "ownerplanningstatuses") {
      if (values && values.length === 0) {
        clearFilteredData("ownerplanningstatuses");
        array.forEach((item, index) => {
          if (item.type === "ownerplanningstatuses") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "ownerplanningstatuses") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "OwnerPlanningStatus_IDs",
          body: [],
          type: "ownerplanningstatuses",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.OwnerPlanningStatus_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "startdays") {
      if (values && values.length === 0) {
        clearFilteredData("startdays");
        array.forEach((item, index) => {
          if (item.type === "startdays") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "startdays") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "StartDays",
          body: [],
          type: "startdays",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.StartDay);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "enddays") {
      if (values && values.length === 0) {
        clearFilteredData("enddays");
        array.forEach((item, index) => {
          if (item.type === "enddays") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "enddays") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "EndDays",
          body: [],
          type: "enddays",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.EndDays);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "title") {
      if (values === "") {
        clearFilteredData("title");
        array.forEach((item, index) => {
          if (item.type === "title") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "title") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "Title",
          body: values,
          type: "title",
          data: values,
        };
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "link") {
      if (values === "") {
        clearFilteredData("link");
        array.forEach((item, index) => {
          if (item.type === "link") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "link") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "Link",
          body: values,
          type: "title",
          data: values,
        };
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "isprivates") {
      if (values && values.length === 0) {
        clearFilteredData("isprivates");
        array.forEach((item, index) => {
          if (item.type === "isprivates") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "isprivates") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "IsPrivates",
          body: [],
          type: "isprivates",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.IsPrivate);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    }
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
    dispatch(clearFilterTrippetResults());
  };

  useEffect(() => {
    if (trippetReceivedInvitation && lastUsedInvitationTable === "received") {
      setTripppetInvitationTable(trippetReceivedInvitation);
      setInvitationActiveClass({
        sent: false,
        received: true,
      });
    } else {
      setTripppetInvitationTable(trippetSentInvitation);
      setInvitationActiveClass({
        sent: true,
        received: false,
      });
    }
  }, [
    trippetReceivedInvitation,
    trippetSentInvitation,
    lastUsedInvitationTable,
  ]);

  const handleTripppetInvitationTable = value => () => {
    setLastUsedInvitationTable(value);
    if (value === "sent") {
      setPageMyTrippetsInvitation(1);
      setTripppetInvitationTable(trippetSentInvitation);
      setInvitationActiveClass({
        sent: true,
        received: false,
      });
    } else {
      setPageMyTrippetsInvitation(1);
      setTripppetInvitationTable(trippetReceivedInvitation);
      setInvitationActiveClass({
        sent: false,
        received: true,
      });
    }
  };
  const renderTableAllMyTrippetsInvitationsTables = () => {
    if (myTrippetsInvitationsView.first) {
      return (
        <InvitationTableOne
          trippets={tripppetInvitationTable}
          handleTrippetFormOpen={handleTrippetFormOpen}
          takeAllMyTrippets={takeAllMyTrippets}
          isOpenTrippetFrom={isOpenTrippetFrom}
          sortedElement={sortedElementInvitation}
          sortedBy={sortedByInvitation}
          handleSortBy={handleSortByMyInvitation}
          invitationType={lastUsedInvitationTable}
          type="invitations"
          user={user}
          allUsers={allUsers}
          filterResultsMyTrippets={filterResults}
          sortedElementMyTrippets={sortedElementMyTrippets}
          sortedByMyTrippets={sortedByMyTrippets}
          activeFilters={activeFilters}
        />
      );
    } else if (myTrippetsInvitationsView.second) {
      return (
        <InvitationTableTwo
          trippets={tripppetInvitationTable}
          handleTrippetFormOpen={handleTrippetFormOpen}
          takeAllMyTrippets={takeAllMyTrippets}
          isOpenTrippetFrom={isOpenTrippetFrom}
          sortedElement={sortedElementInvitation}
          sortedBy={sortedByInvitation}
          allUsers={allUsers}
          handleSortBy={handleSortByMyInvitation}
          invitationType={lastUsedInvitationTable}
          type="invitations"
          user={user}
          filterResultsMyTrippets={filterResults}
          sortedElementMyTrippets={sortedElementMyTrippets}
          sortedByMyTrippets={sortedByMyTrippets}
          activeFilters={activeFilters}
        />
      );
    } else if (myTrippetsInvitationsView.third) {
      return (
        <InvitationTableThree
          trippets={tripppetInvitationTable}
          handleTrippetFormOpen={handleTrippetFormOpen}
          takeAllMyTrippets={takeAllMyTrippets}
          isOpenTrippetFrom={isOpenTrippetFrom}
          sortedElement={sortedElementInvitation}
          sortedBy={sortedByInvitation}
          handleSortBy={handleSortByMyInvitation}
          user={user}
          invitationType={lastUsedInvitationTable}
          type="invitations"
          allUsers={allUsers}
          filterResultsMyTrippets={filterResults}
          sortedElementMyTrippets={sortedElementMyTrippets}
          sortedByMyTrippets={sortedByMyTrippets}
          activeFilters={activeFilters}
        />
      );
    } else if (myTrippetsInvitationsView.fourth) {
      return (
        <InvitationTableFour
          trippets={tripppetInvitationTable}
          handleTrippetFormOpen={handleTrippetFormOpen}
          takeAllMyTrippets={takeAllMyTrippets}
          isOpenTrippetFrom={isOpenTrippetFrom}
          sortedElement={sortedElementInvitation}
          sortedBy={sortedByInvitation}
          handleSortBy={handleSortByMyInvitation}
          invitationType={lastUsedInvitationTable}
          type="invitations"
          user={user}
          allUsers={allUsers}
          filterResultsMyTrippets={filterResults}
          sortedElementMyTrippets={sortedElementMyTrippets}
          sortedByMyTrippets={sortedByMyTrippets}
          activeFilters={activeFilters}
        />
      );
    }
  };

  const onPaginationChangeMyTrippets = activePage => {
    setPageMyTrippets(activePage);
    const body = {
      Language_ID: user.Language_ID,
      Type: 2,
      User_ID: user.User_ID,
      OrderBy: "ModifiedDate",
      OrderDir: "desc",
      Page: activePage,
      RowsPerPage: 10,
    };
    if (filterResults) {
      filterResults.forEach(filter => {
        body[filter.name] = filter.body;
      });
    }
    dispatch(getAllTrippets(JSON.stringify(body)));
  };

  const onPaginationChangeMyTrippetsInvitation = activePage => {
    setPageMyTrippetsInvitation(activePage);
  };

  const onPaginationChangeMyTrippetsRecommendation = activePage => {
    setPageMyTrippetsRecommendation(activePage);
  };

  return (
    <Grid container justify="center" className={classes.containerMyTrip}>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid item xs={12} className={classes.backToCockpit}>
        <Link to="/">
          <KeyboardBackspaceIcon />
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            className={classes.title}
            id={"Invitations"}
          >
            {t("Back to Cockpit")}
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.title}
        >
          {t("My Trippets")}
        </Typography>
      </Grid>
      <div className={classes.dotsContainer}>
        <div className={classes.dots}>
          <div
            className={classNames(classes.dot, {
              [classes.checked]: myTrippetsView.first,
            })}
            onClick={handleTableViewAllTrippets("first")}
          />
          <div
            className={classNames(classes.dot, {
              [classes.checked]: myTrippetsView.second,
            })}
            onClick={handleTableViewAllTrippets("second")}
          />
          <div
            className={classNames(classes.dot, {
              [classes.checked]: myTrippetsView.third,
            })}
            onClick={handleTableViewAllTrippets("third")}
          />
          <div
            className={classNames(classes.dot, {
              [classes.checked]: myTrippetsView.fourth,
            })}
            onClick={handleTableViewAllTrippets("fourth")}
          />
          <div
            className={classNames(classes.dot, {
              [classes.checked]: myTrippetsView.fiveth,
            })}
            onClick={handleTableViewAllTrippets("fiveth")}
          />
        </div>
      </div>
      <Grid item xs={12}>
        {renderTableAllMyTrippetsTables()}
      </Grid>
      <Grid item xs={12}>
        <Pagination
          activePage={pageMyTrippets}
          itemsPerPage={10}
          totalItemsCount={totalItemsCountMyTrippets}
          onChange={onPaginationChangeMyTrippets}
          name="trippets"
        />
      </Grid>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.title}
          id={"Invitations"}
        >
          {t("Invitations")}
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Button
          onClick={handleTripppetInvitationTable("received")}
          className={invitationActiveClass.received ? classes.activeButton : ""}
        >
          {t("Received")}
        </Button>
        <Button
          onClick={handleTripppetInvitationTable("sent")}
          className={invitationActiveClass.sent ? classes.activeButton : ""}
        >
          {t("Sent")}
        </Button>
      </Grid>
      <div className={classes.dotsContainer}>
        <div className={classes.dots}>
          <div
            className={classNames(classes.dot, {
              [classes.checked]: myTrippetsInvitationsView.first,
            })}
            onClick={handleTableViewAllTrippetsInvitations("first")}
          />
          <div
            className={classNames(classes.dot, {
              [classes.checked]: myTrippetsInvitationsView.second,
            })}
            onClick={handleTableViewAllTrippetsInvitations("second")}
          />
          <div
            className={classNames(classes.dot, {
              [classes.checked]: myTrippetsInvitationsView.third,
            })}
            onClick={handleTableViewAllTrippetsInvitations("third")}
          />
          <div
            className={classNames(classes.dot, {
              [classes.checked]: myTrippetsInvitationsView.fourth,
            })}
            onClick={handleTableViewAllTrippetsInvitations("fourth")}
          />
        </div>
      </div>
      <Grid item xs={12}>
        {renderTableAllMyTrippetsInvitationsTables()}
      </Grid>
      <Pagination
        activePage={pageMyTrippetsInvitation}
        itemsPerPage={10}
        totalItemsCount={totalItemsCountMyTrippetsInvitation}
        onChange={onPaginationChangeMyTrippetsInvitation}
        name="trippets"
      />
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.title}
          id={"Recommendations"}
        >
          {t("Recommendations")}
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Button
          onClick={handleRecommendations(true)}
          className={
            recommendationctiveClass.received ? classes.activeButton : ""
          }
        >
          {t("Received")}
        </Button>
        <Button
          onClick={handleRecommendations(false)}
          className={recommendationctiveClass.sent ? classes.activeButton : ""}
        >
          {t("Sent")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <RecommendationTable
          trippets={renderTable}
          handleTrippetFormOpen={handleTrippetFormOpen}
          takeAllMyTrippets={takeAllMyTrippets}
          isOpenTrippetFrom={isOpenTrippetFrom}
          recommendationType={recommendationType}
          allUsers={allUsers}
          type="recommendations"
        />
      </Grid>
      <Pagination
        activePage={pageMyTrippetsRecommendation}
        itemsPerPage={10}
        totalItemsCount={totalItemsCountMyTrippetsRecommendation}
        onChange={onPaginationChangeMyTrippetsRecommendation}
        name="trippets"
      />
      <Grid item xs={12} className={classes.trippetFormContainer}>
        {isOpenTrippetFrom && (
          <TrippetForm
            open={isOpenTrippetFrom}
            handleClose={handleTrippetFormClose}
            handleConfirmModalOpen={handleConfirmModalOpen}
            currentTrippet={currentTrippet}
            pickedTime={null}
          />
        )}
        <ConfirmModal
          open={isOpenConfirmModal}
          handleCloseParenModal={handleTrippetFormClose}
          handleClose={handleConfirmModalClose}
        />
      </Grid>
      <ConfirmDeleteModal
        open={isOpenConfirmDeleteModal}
        handleClose={handleConfirmDeleteModalClose}
        handleDelete={handleDelete}
      />
      <FilterModal
        open={isFilterModalOpen}
        handleClose={handleCloseFilterModal}
        name={clickedFilter}
        handleFilterValues={handleFilterValues}
        filterResults={filterResults}
        filterData={filterData}
      />
    </Grid>
  );
};

export default withTitle(withDashboard(MyTrippets), "My Trippets");
