import React, {
  Fragment,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import Calendar from "@toast-ui/react-calendar";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import "tui-calendar/dist/tui-calendar.css";
import useStyles from "./styles";
import { CUSTOM_THEME } from "./themes";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import {
  changeCalendarView,
  changeVisibleWeeksCount,
  setStartWeekMonday,
  setWorkWeek,
  setTaskView,
  setCalendarViewName,
} from "../../store/actions/actions/programSettings";
import {
  changeTrippet,
  showTrippetForm,
  hideTrippetForm,
  getAllPrivateDocumentsFromTheSingleTrippet,
  getAllPublicDocumentsFromTheSingleTrippet,
  getTrippetImages,
} from "../../store/actions/actions/trippet";
import { changeTripWithoutParticipants } from "../../store/actions/actions/trip";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  format,
  addMonths,
  addWeeks,
  addDays,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import {
  savePickedTime,
  saveCalendarInstance,
} from "../../store/actions/actions/trippet";
import uniqid from "uniqid";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-GB";
import { differenceInCalendarDays } from "date-fns";
import ListItemText from "@material-ui/core/ListItemText";
import TripModal from "../TripForm/TripForm";
import { BASE_URL } from "../../config";

const TRIP = {
  bgColor: "#9D9D9D",
  borderColor: "#9D9D9D",
  color: "#FFFFFF",
  dragBgColor: "#9D9D9D",
};

const MuiCalendar = () => {
  const calendarRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [calendarView, setCalendarView] = useState({
    name: "month",
    value: "month",
    visibleWeeksCount: 0,
    startWeekMonday: true,
    workWeek: false,
    taskView: true,
  });
  const [dateLanguage, setLanguage] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [trip, setTrip] = useState([]);
  const [trippets, setTrippets] = useState([]);
  const [isTripModalOpen, setIsTripModalOpen] = useState(false);
  const [clickedTrippet, setClickedTrippet] = useState(null);
  const [startCalendarDate, setStartCalendarDate] = useState(null);
  const currentTrip = useSelector(state => state.trip.currentTrip);
  const currentTrippets = useSelector(state => state.trippet.allTrippets);
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const user = useSelector(state => state.user.user);
  const defaultCalendarView = useSelector(
    state => state.programSettings.calendarView,
  );
  const language = useSelector(state => state.programSettings.language);
  const visibleWeeksCount = useSelector(
    state => state.programSettings.visibleWeeksCount,
  );
  const calendarViewName = useSelector(
    state => state.programSettings.calendarViewName,
  );
  const taskViewProgramSettings = useSelector(
    state => state.programSettings.taskView,
  );
  const workWeekProgramSettings = useSelector(
    state => state.programSettings.workWeek,
  );
  const startWeekMondayProgramSettings = useSelector(
    state => state.programSettings.startWeekMonday,
  );
  const isOpenTrippetFrom = useSelector(
    state => state.trippet.isOpenTrippetFrom,
  );

  useEffect(() => {
    if (language === "en") {
      setLanguage(enLocale);
    } else if (language === "de") {
      setLanguage(deLocale);
    }
  }, [language]);

  useEffect(() => {
    if (
      visibleWeeksCount ||
      (visibleWeeksCount === 0 && calendarViewName && defaultCalendarView)
    ) {
      setCalendarView({
        ...calendarView,
        name: calendarViewName,
        value: defaultCalendarView,
        visibleWeeksCount: visibleWeeksCount,
        startWeekMonday: startWeekMondayProgramSettings,
        workWeek: workWeekProgramSettings,
        taskView: taskViewProgramSettings,
      });
    } else {
      setCalendarView({
        ...calendarView,
        workWeek: workWeekProgramSettings,
        taskView: taskViewProgramSettings,
        startWeekMonday: startWeekMondayProgramSettings,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    visibleWeeksCount,
    calendarViewName,
    defaultCalendarView,
    workWeekProgramSettings,
    taskViewProgramSettings,
    startWeekMondayProgramSettings,
  ]);

  useEffect(() => {
    calendarRef.current.getRootElement().classList.add(classes.calendarRoot);
    dispatch(saveCalendarInstance(calendarRef.current.getInstance()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentTrip && currentTrip.StartDate) {
      const calendarInstance = calendarRef.current.getInstance();
      if (currentTrip.StartDate) {
        let startDay = startOfMonth(new Date(currentTrip.StartDate));
        calendarInstance.setDate(startDay);
        setStartCalendarDate(startOfMonth(new Date(currentTrip.StartDate)));
        setCurrentDate(startOfMonth(new Date(currentTrip.StartDate)));
      } else {
        calendarInstance.setDate(new Date());
        setStartCalendarDate(new Date());
        setCurrentDate(new Date());
      }
    }
  }, [currentTrip]);

  useEffect(() => {
    const calendarInstance = calendarRef.current.getInstance();
    setStartCalendarDate(new Date(currentTrip.StartDate));
    calendarInstance.setDate(new Date(currentTrip.StartDate));
  }, [currentTrip.StartDate, currentTrip.EndDate]);

  const formatEventDate = (date, type) => {
    if (date) {
      if (type === "trip") {
        return `${format(new Date(date), "yyyy-MM-dd")}T00:00:00}`;
      } else {
        return `${format(new Date(date), "yyyy-MM-dd")}T${format(
          new Date(date),
          "HH:mm:ss",
        )}`;
      }
    }
  };

  const getEventInformation = data => {
    if (trippetTypes && trippetTypes.length > 0) {
      let element = trippetTypes.find(type => data === type.TrippetTypeLook_ID);
      if (element) {
        if (element.Icon) {
          return {
            color: element.Color,
            icon: element.Icon,
            typePicture: true,
          };
        } else {
          return {
            color: element.Color,
            typePicture: false,
            icon: element.MaterialIO,
          };
        }
      }
    }
  };

  useEffect(() => {
    if (currentTrip) {
      let {
        tripName,
        description,
        startDate,
        endDate,
        participants,
      } = currentTrip;
      let event = {
        calendarId: "0",
        id: uniqid(),
        category: "allday",
        title: tripName,
        body: description,
        start: formatEventDate(startDate, "trip"),
        end: formatEventDate(endDate, "trip"),
        bgColor: TRIP.bgColor,
        color: TRIP.color,
        dragBgColor: TRIP.dragBgColor,
        borderColor: TRIP.borderColor,
        attendees: participants,
        type: "trip",
        raw: {
          type: "trip",
          trip: currentTrip,
        },
      };
      setTrip([event]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrip]);

  const renderIcon = information => {
    if (information.typePicture) {
      const path = information.icon.substring(1);
      return `${BASE_URL}${path}`;
    } else {
      return information.icon;
    }
  };

  useEffect(() => {
    if (currentTrippets && trippetTypes) {
      let array = [];
      currentTrippets.forEach(trippet => {
        const {
          Title,
          Description,
          StartDate,
          EndDate,
          TrippetTypeLook_ID,
          Participants,
          Trippet_ID,
          IsWholeDay,
        } = trippet;
        const inforomation = getEventInformation(TrippetTypeLook_ID);
        const renderCategory = IsWholeDay === "1" ? "allday" : "time";
        if (inforomation && inforomation.icon) {
          let event = {
            calendarId: "0",
            id: uniqid(),
            category: renderCategory,
            title: Title,
            body: Description,
            start: formatEventDate(StartDate, "trippet"),
            end: formatEventDate(EndDate, "trippet"),
            bgColor: `#${inforomation && inforomation.color}`,
            color: "#ffffff",
            dragBgColor: `#${inforomation && inforomation.color}`,
            borderColor: `#${inforomation && inforomation.color}`,
            attendees: Participants,
            raw: {
              type: "trippet",
              trippetId: Trippet_ID,
              trippetType: TrippetTypeLook_ID,
              trippet: currentTrip,
              typePicture: inforomation.typePicture,
              // icon: inforomation && inforomation.icon,
              icon: renderIcon(inforomation),
            },
          };
          array.push(event);
        }
      });
      setTrippets(array);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrippets, trippetTypes]);

  const handleTripModalOpen = () => {
    setIsTripModalOpen(true);
  };

  const handleTripModalClose = () => {
    setIsTripModalOpen(false);
  };

  // handling clicking on event
  const clickSchedule = ({ schedule }) => {
    if (schedule && schedule.raw && schedule.raw.type === "trippet") {
      let selectedTrippet = currentTrippets.find(
        tripppet => tripppet.Trippet_ID === schedule.raw.trippetId,
      );
      let body = {
        fullTrippet: {
          ...selectedTrippet,
        },
      };
      const privateDocument = {
        Trippet_ID: schedule.raw.trippetId,
        IsPrivate: "1",
        CreatorUser_ID: user.User_ID,
      };
      dispatch(
        getAllPrivateDocumentsFromTheSingleTrippet(
          JSON.stringify(privateDocument),
        ),
      );

      const publicDocument = {
        Trippet_ID: schedule.raw.trippetId,
        IsPrivate: "0",
      };
      dispatch(
        getAllPublicDocumentsFromTheSingleTrippet(
          JSON.stringify(publicDocument),
        ),
      );

      const trippetImage = {
        Trippet_ID: schedule.raw.trippetId,
      };
      dispatch(getTrippetImages(JSON.stringify(trippetImage)));
      dispatch(showTrippetForm(body));
    } else if (schedule && schedule.raw && schedule.raw.type === "trip") {
      let trip = currentTrip;
      setClickedTrippet(trip);
      handleTripModalOpen();
    }
  };

  const handleChangeView = event => {
    const calendarInstance = calendarRef.current.getInstance();
    switch (event.target.value) {
      case "1 week":
        setCalendarView({
          ...calendarView,
          name: "1 week",
          value: "month",
          visibleWeeksCount: 1,
          startWeekMonday: calendarView.startWeekMonday,
          workWeek: calendarView.workWeek,
        });
        calendarInstance.setDate(new Date(currentTrip.StartDate));
        setCurrentDate(currentTrip.StartDate);
        dispatch(changeCalendarView("month"));
        dispatch(changeVisibleWeeksCount(1));
        dispatch(setCalendarViewName("1 week"));
        break;
      // eslint-disable-next-line no-fallthrough
      case "2 weeks":
        setCalendarView({
          ...calendarView,
          name: "2 weeks",
          value: "month",
          visibleWeeksCount: 2,
          startWeekMonday: calendarView.startWeekMonday,
          workWeek: calendarView.workWeek,
        });
        calendarInstance.setDate(new Date(currentTrip.startDate));
        setCurrentDate(currentTrip.StartDate);
        dispatch(changeCalendarView("month"));
        dispatch(changeVisibleWeeksCount(2));
        dispatch(setCalendarViewName("2 weeks"));
        break;
      // eslint-disable-next-line no-fallthrough
      case "3 weeks":
        setCalendarView({
          ...calendarView,
          name: "3 weeks",
          value: "month",
          visibleWeeksCount: 3,
          startWeekMonday: calendarView.startWeekMonday,
          workWeek: calendarView.workWeek,
        });
        calendarInstance.setDate(new Date(currentTrip.startDate));
        setCurrentDate(currentTrip.StartDate);
        dispatch(changeCalendarView("month"));
        dispatch(changeVisibleWeeksCount(3));
        dispatch(setCalendarViewName("3 weeks"));
        break;
      // eslint-disable-next-line no-fallthrough
      case "startWeek":
        setCalendarView({
          ...calendarView,
          startWeekMonday: !calendarView.startWeekMonday,
        });
        setCurrentDate(startCalendarDate);
        dispatch(setStartWeekMonday(!calendarView.startWeekMonday));

        break;
      // eslint-disable-next-line no-fallthrough
      case "workWeek":
        setCalendarView({
          ...calendarView,
          workWeek: !calendarView.workWeek,
        });
        setCurrentDate(startCalendarDate);
        dispatch(setWorkWeek(!calendarView.workWeek));
        break;
      // eslint-disable-next-line no-fallthrough
      case "hideTasks":
        setCalendarView({
          ...calendarView,
          taskView: !calendarView.taskView,
        });
        calendarInstance.toggleTaskView(!calendarView.taskView);
        setCurrentDate(startCalendarDate);
        dispatch(setTaskView(!calendarView.taskView));
        break;
      // eslint-disable-next-line no-fallthrough
      default:
        setCalendarView({
          ...calendarView,
          name: event.target.value,
          value: event.target.value,
          visibleWeeksCount: 0,
        });
        setCurrentDate(startCalendarDate);
        if (event.target.value === "day") {
          calendarInstance.setDate(new Date(currentTrip.startDate));
        } else if (event.target.value === "week") {
          calendarInstance.setDate(
            startOfWeek(new Date(currentTrip.startDate), { weekStartsOn: 1 }),
          );
        } else {
          calendarInstance.setDate(startCalendarDate);
        }
        dispatch(changeCalendarView(event.target.value));
        dispatch(setCalendarViewName(event.target.value));
    }
  };

  const getTimeTemplate = schedule => {
    if (schedule) {
      if (schedule.raw.type === "trip") {
        let html = [];
        html.push(schedule.title);
        return html.join("");
      } else if (schedule.raw.type === "trippet") {
        let html = [];
        if (schedule.raw.typePicture) {
          html.push(
            `<span class="material-icons ${classes.eventImage}"><img src=${schedule.raw.icon} /></span>`,
          );
        } else {
          html.push(
            `<span class="material-icons ${classes.eventIcon}">${schedule.raw.icon}</span>`,
          );
        }
        html.push(schedule.title);
        return html.join("");
      }
    }
  };

  const handleAllDayEvent = schedule => {
    if (schedule) {
      if (schedule.raw.type === "trip") {
        let html = [];
        html.push(schedule.title);
        return html.join("");
      } else if (schedule.raw.type === "trippet") {
        let html = [];
        if (schedule.raw.typePicture) {
          html.push(
            `<span class="material-icons ${classes.eventImage}"><img src=${schedule.raw.icon} /></span>`,
          );
        } else {
          html.push(
            `<span class="material-icons ${classes.eventIcon}">${schedule.raw.icon}</span>`,
          );
        }
        html.push(schedule.title);
        return html.join("");
      }
    }
  };

  const handleToday = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.today();
    setCurrentDate(new Date());
  };

  const handlePrev = () => {
    const calendarInstance = calendarRef.current.getInstance();
    const { name } = calendarView;
    if (name === "month") {
      calendarInstance.prev();
      setCurrentDate(addMonths(currentDate, -1));
    } else if (name === "day") {
      calendarInstance.prev();
      setCurrentDate(addDays(currentDate, -1));
    } else if (name === "week") {
      calendarInstance.prev();
      setCurrentDate(addWeeks(currentDate, -1));
    } else if (name === "1 week") {
      calendarInstance.prev();
      setCurrentDate(addWeeks(currentDate, -1));
    } else if (name === "2 weeks") {
      calendarInstance.prev();
      setCurrentDate(addWeeks(currentDate, -2));
    } else if (name === "3 weeks") {
      calendarInstance.prev();
      setCurrentDate(addWeeks(currentDate, -3));
    } else {
      calendarInstance.prev();
      setCurrentDate(addMonths(currentDate, -1));
    }
  };

  const handleNext = () => {
    const calendarInstance = calendarRef.current.getInstance();
    const { name } = calendarView;
    if (name === "month") {
      calendarInstance.next();
      setCurrentDate(addMonths(currentDate, 1));
    } else if (name === "day") {
      calendarInstance.next();
      setCurrentDate(addDays(currentDate, 1));
    } else if (name === "week") {
      calendarInstance.next();
      setCurrentDate(addWeeks(startCalendarDate, 1));
    } else if (name === "1 week") {
      calendarInstance.next();
      setCurrentDate(addWeeks(startCalendarDate, 1));
    } else if (name === "2 weeks") {
      calendarInstance.next();
      setCurrentDate(addWeeks(startCalendarDate, 2));
    } else if (name === "3 weeks") {
      calendarInstance.next();
      setCurrentDate(addWeeks(startCalendarDate, 3));
    } else {
      calendarInstance.next();
      setCurrentDate(addMonths(currentDate, 1));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateAllTripetsDaysOnTripDnD = (tripStartDate, tripEndDate) => {
    currentTrippets.forEach(trippet => {
      const trippetBody = {
        Trippet_ID: trippet.Trippet_ID,
        Days: "",
      };
      let tripTotalDays = differenceInCalendarDays(
        new Date(tripStartDate.slice(0, 16)),
        new Date(tripEndDate.slice(0, 16)),
      );

      let startTrippetDayDifference = differenceInCalendarDays(
        new Date(tripEndDate.slice(0, 16)),
        new Date(trippet.StartDate),
      );

      let endTrippetDayDifference = differenceInCalendarDays(
        new Date(trippet.EndDate),
        new Date(tripEndDate.slice(0, 16)),
      );

      let renderStartDay = 1;
      if (startTrippetDayDifference === Math.abs(tripTotalDays) + 1) {
        renderStartDay =
          Math.abs(tripTotalDays) + 1 - startTrippetDayDifference - 1;
      } else if (startTrippetDayDifference > Math.abs(tripTotalDays) + 1) {
        renderStartDay =
          Math.abs(tripTotalDays) + 1 - startTrippetDayDifference - 1;
      } else if (startTrippetDayDifference > 0) {
        renderStartDay =
          Math.abs(tripTotalDays) + 1 - startTrippetDayDifference;
      } else if (startTrippetDayDifference < 0) {
        renderStartDay =
          Math.abs(tripTotalDays) + 1 + Math.abs(startTrippetDayDifference);
      } else if (startTrippetDayDifference === 0) {
        renderStartDay = Math.abs(tripTotalDays) + 1;
      } else {
        renderStartDay =
          Math.abs(tripTotalDays) + 1 - Math.abs(startTrippetDayDifference) - 1;
      }

      let renderEndDay = 1;

      if (endTrippetDayDifference > Math.abs(tripTotalDays) + 1) {
        renderEndDay = endTrippetDayDifference + Math.abs(tripTotalDays) + 1;
      } else if (endTrippetDayDifference === 0) {
        renderEndDay = Math.abs(tripTotalDays) + 1;
      } else if (endTrippetDayDifference > 0) {
        renderEndDay = endTrippetDayDifference + Math.abs(tripTotalDays) + 1;
      } else if (endTrippetDayDifference < 0) {
        if (Math.abs(tripTotalDays) - Math.abs(endTrippetDayDifference) < 0) {
          renderEndDay =
            Math.abs(tripTotalDays) - Math.abs(endTrippetDayDifference);
        } else {
          renderEndDay =
            Math.abs(tripTotalDays) + 1 - Math.abs(endTrippetDayDifference);
        }
      }
      trippetBody.Days = `${renderStartDay} to ${renderEndDay}`;
      dispatch(
        changeTrippet(
          JSON.stringify(trippetBody),
          null,
          null,
          null,
          currentTrip.Trip_ID,
        ),
      );
    });
  };

  // handling clicking on empty event in calendar
  const onBeforeCreateSchedule = useCallback(
    scheduleData => {
      const calendarInstance = calendarRef.current.getInstance();
      if (!isOpenTrippetFrom) {
        if (scheduleData && scheduleData.start && scheduleData.end) {
          let picked = {
            start: new Date(`${scheduleData.start._date}`).toISOString(),
            end: new Date(`${scheduleData.end._date}`).toISOString(),
          };
          dispatch(savePickedTime(picked));
          dispatch(showTrippetForm());
        }
      } else {
        calendarInstance.render(true);
      }
    },
    [dispatch, isOpenTrippetFrom],
  );

  const onBeforeUpdateSchedule = useCallback(
    event => {
      const getCurrentTrippet = id =>
        currentTrippets &&
        currentTrippets.find(trippet => trippet.Trippet_ID === id);
      const calendarInstance = calendarRef.current.getInstance();
      const { schedule, changes } = event;
      if (schedule.raw.type === "trip") {
        if (changes) {
          const { Trip_ID, StartDate } = currentTrip;
          const tripBody = {
            Trip_ID: Trip_ID,
            StartDate: "",
            EndDate: "",
          };
          if (changes.start && changes.end) {
            let startDate = format(changes.start._date, "yyyy-MM-dd");
            let startTime = format(changes.start._date, "HH:mm");
            let endDate = format(changes.end._date, "yyyy-MM-dd");
            let endTime = format(changes.end._date, "HH:mm");
            tripBody.StartDate = `${startDate}T${startTime}Z+01:00`;
            tripBody.EndDate = `${endDate}T${endTime}Z+01:00`;
          } else if (changes.end) {
            let endDate = format(changes.end._date, "yyyy-MM-dd");
            let endTime = format(changes.end._date, "HH:mm");
            tripBody.StartDate = StartDate;
            tripBody.EndDate = `${endDate}T${endTime}Z+01:00`;
          }
          dispatch(
            changeTripWithoutParticipants(
              JSON.stringify(tripBody),
              user.User_ID,
            ),
          );
          updateAllTripetsDaysOnTripDnD(tripBody.StartDate, tripBody.EndDate);
        }
        calendarInstance.updateSchedule(
          schedule.id,
          schedule.calendarId,
          changes,
        );
      } else if (schedule.raw.type === "trippet") {
        dispatch(hideTrippetForm());
        let fullTrippet = getCurrentTrippet(schedule.raw.trippetId);
        const { Trippet_ID, StartDate } = fullTrippet;
        const trippetBody = {
          Trippet_ID: Trippet_ID,
          StartDate: "",
          EndDate: "",
        };
        if (changes) {
          if (changes.start && changes.end) {
            let startDate = format(changes.start._date, "yyyy-MM-dd");
            let startTime = format(changes.start._date, "HH:mm");
            let endDate = format(changes.end._date, "yyyy-MM-dd");
            let endTime = format(changes.end._date, "HH:mm");
            trippetBody.StartDate = `${startDate}T${startTime}Z+01:00`;
            trippetBody.EndDate = `${endDate}T${endTime}Z+01:00`;
            let tripTotalDays = differenceInCalendarDays(
              new Date(currentTrip.StartDate),
              new Date(currentTrip.EndDate),
            );

            let startTrippetDayDifference = differenceInCalendarDays(
              new Date(currentTrip.EndDate),
              new Date(changes.start._date),
            );
            let endTrippetDayDifference = differenceInCalendarDays(
              new Date(changes.end._date),
              new Date(currentTrip.EndDate),
            );

            let renderStartDay = 1;
            if (startTrippetDayDifference === Math.abs(tripTotalDays) + 1) {
              renderStartDay =
                Math.abs(tripTotalDays) + 1 - startTrippetDayDifference - 1;
            } else if (
              startTrippetDayDifference >
              Math.abs(tripTotalDays) + 1
            ) {
              renderStartDay =
                Math.abs(tripTotalDays) + 1 - startTrippetDayDifference - 1;
            } else if (startTrippetDayDifference > 0) {
              renderStartDay =
                Math.abs(tripTotalDays) + 1 - startTrippetDayDifference;
            } else if (startTrippetDayDifference < 0) {
              renderStartDay =
                Math.abs(tripTotalDays) +
                1 +
                Math.abs(startTrippetDayDifference);
            } else if (startTrippetDayDifference === 0) {
              renderStartDay = Math.abs(tripTotalDays) + 1;
            } else {
              renderStartDay =
                Math.abs(tripTotalDays) +
                1 -
                Math.abs(startTrippetDayDifference) -
                1;
            }

            let renderEndDay = 1;
            if (endTrippetDayDifference > Math.abs(tripTotalDays) + 1) {
              renderEndDay =
                endTrippetDayDifference + Math.abs(tripTotalDays) + 1;
            } else if (endTrippetDayDifference === 0) {
              renderEndDay = Math.abs(tripTotalDays) + 1;
            } else if (endTrippetDayDifference > 0) {
              renderEndDay =
                endTrippetDayDifference + Math.abs(tripTotalDays) + 1;
            } else if (endTrippetDayDifference < 0) {
              if (
                Math.abs(tripTotalDays) - Math.abs(endTrippetDayDifference) <
                0
              ) {
                renderEndDay =
                  Math.abs(tripTotalDays) - Math.abs(endTrippetDayDifference);
              } else {
                renderEndDay =
                  Math.abs(tripTotalDays) +
                  1 -
                  Math.abs(endTrippetDayDifference);
              }
            }
            trippetBody.Days = `${renderStartDay} to ${renderEndDay}`;
          } else if (changes.end) {
            let endDate = format(changes.end._date, "yyyy-MM-dd");
            let endTime = format(changes.end._date, "HH:mm");
            trippetBody.StartDate = StartDate;
            trippetBody.EndDate = `${endDate}T${endTime}Z+01:00`;

            let tripTotalDays = differenceInCalendarDays(
              new Date(currentTrip.StartDate),
              new Date(currentTrip.EndDate),
            );

            let startTrippetDayDifference = differenceInCalendarDays(
              new Date(currentTrip.EndDate),
              new Date(StartDate),
            );

            let endTrippetDayDifference = differenceInCalendarDays(
              new Date(changes.end._date),
              new Date(currentTrip.EndDate),
            );

            let renderStartDay = 1;
            if (startTrippetDayDifference === Math.abs(tripTotalDays) + 1) {
              renderStartDay =
                Math.abs(tripTotalDays) + 1 - startTrippetDayDifference - 1;
            } else if (
              startTrippetDayDifference >
              Math.abs(tripTotalDays) + 1
            ) {
              renderStartDay =
                Math.abs(tripTotalDays) + 1 - startTrippetDayDifference - 1;
            } else if (startTrippetDayDifference > 0) {
              renderStartDay =
                Math.abs(tripTotalDays) + 1 - startTrippetDayDifference;
            } else if (startTrippetDayDifference < 0) {
              renderStartDay =
                Math.abs(tripTotalDays) +
                1 +
                Math.abs(startTrippetDayDifference);
            } else if (startTrippetDayDifference === 0) {
              renderStartDay = Math.abs(tripTotalDays) + 1;
            } else {
              renderStartDay =
                Math.abs(tripTotalDays) +
                1 -
                Math.abs(startTrippetDayDifference) -
                1;
            }

            let renderEndDay = 1;

            if (endTrippetDayDifference > Math.abs(tripTotalDays) + 1) {
              renderEndDay =
                endTrippetDayDifference + Math.abs(tripTotalDays) + 1;
            } else if (endTrippetDayDifference === 0) {
              renderEndDay = Math.abs(tripTotalDays) + 1;
            } else if (endTrippetDayDifference > 0) {
              renderEndDay =
                endTrippetDayDifference + Math.abs(tripTotalDays) + 1;
            } else if (endTrippetDayDifference < 0) {
              if (
                Math.abs(tripTotalDays) - Math.abs(endTrippetDayDifference) <
                0
              ) {
                renderEndDay =
                  Math.abs(tripTotalDays) - Math.abs(endTrippetDayDifference);
              } else {
                renderEndDay =
                  Math.abs(tripTotalDays) +
                  1 -
                  Math.abs(endTrippetDayDifference);
              }
            }

            trippetBody.Days = `${renderStartDay} to ${renderEndDay}`;
          }
          dispatch(
            changeTrippet(
              JSON.stringify(trippetBody),
              null,
              null,
              null,
              currentTrip.Trip_ID,
            ),
          );
        }
        calendarInstance.updateSchedule(
          schedule.id,
          schedule.calendarId,
          changes,
        );
      }
    },
    [
      currentTrip,
      currentTrippets,
      dispatch,
      updateAllTripetsDaysOnTripDnD,
      user.User_ID,
    ],
  );

  const renderDate = () => {
    if (calendarView.name === "month") {
      return (
        <FormControl className={classes.date}>
          {format(currentDate, "MMMM yyyy", { locale: dateLanguage })}
        </FormControl>
      );
    } else if (calendarView.name === "day") {
      return (
        <FormControl className={classes.date}>
          {format(currentDate, "do MMMM yyyy", { locale: dateLanguage })}
        </FormControl>
      );
    } else if (
      calendarView.name === "1 week" ||
      calendarView.name === "2 weeks" ||
      calendarView.name === "3 weeks"
    ) {
      const startDate = calendarRef.current.getInstance().getDate();
      return (
        <FormControl className={classes.date}>
          {format(startDate._date, "MMMM yyyy", {
            locale: dateLanguage,
          })}
        </FormControl>
      );
    } else {
      return (
        <FormControl className={classes.date}>
          {format(currentDate, "MMMM yyyy", { locale: dateLanguage })}
        </FormControl>
      );
    }
  };

  return (
    <Fragment>
      <FormControl variant="outlined" className={classes.selectView}>
        <InputLabel id="demo-simple-select-outlined-label">
          {t("Select View")}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label={t("Select View")}
          name="language"
          value={calendarView.name}
          onChange={handleChangeView}
        >
          <MenuItem value="month">{t("Month")}</MenuItem>
          <MenuItem value="week">{t("Week")}</MenuItem>
          <MenuItem value="day">{t("Day")}</MenuItem>
          <MenuItem value="1 week">{t("1 week")}</MenuItem>
          <MenuItem value="2 weeks">{t("2 weeks")}</MenuItem>
          <MenuItem value="3 weeks">{t("3 weeks")}</MenuItem>
          <MenuItem value="startWeek" className={classes.leftPadding}>
            <Checkbox checked={calendarView.startWeekMonday} color="primary" />
            <ListItemText primary={t("Start Week Monday")} />
          </MenuItem>
          <MenuItem value="workWeek" className={classes.leftPadding}>
            <Checkbox checked={calendarView.workWeek} color="primary" />
            <ListItemText primary={t("Hide weekends")} />
          </MenuItem>
          <MenuItem value="hideTasks" className={classes.leftPadding}>
            <Checkbox checked={calendarView.taskView} color="primary" />
            <ListItemText primary={t("Show Task bar")} />
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.actionButtons}>
        <div>
          <Button
            onClick={handleToday}
            variant="outlined"
            className={classes.todayBtn}
          >
            {t("Today")}
          </Button>
          <IconButton
            onClick={handlePrev}
            variant="outlined"
            className={classes.prevBtn}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            variant="outlined"
            className={classes.nextBtn}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </FormControl>
      {renderDate()}
      <Calendar
        usageStatistics={false}
        ref={calendarRef}
        disableDblClick={true}
        calendars={[
          {
            id: "0",
            name: "T*P",
            bgColor: TRIP.bgColor,
            borderColor: TRIP.borderColor,
            color: TRIP.color,
          },
        ]}
        week={{
          daynames: [
            t("Sun"),
            t("Mon"),
            t("Tue"),
            t("Wed"),
            t("Thu"),
            t("Fri"),
            t("Sat"),
          ],
          startDayOfWeek: calendarView.startWeekMonday ? 1 : 0,
          visibleWeeksCount: calendarView.visibleWeeksCount,
          workweek: calendarView.workWeek,
        }}
        taskView={calendarView.taskView}
        month={{
          daynames: [
            t("Sun"),
            t("Mon"),
            t("Tue"),
            t("Wed"),
            t("Thu"),
            t("Fri"),
            t("Sat"),
          ],
          startDayOfWeek: calendarView.startWeekMonday ? 1 : 0,
          visibleWeeksCount: calendarView.visibleWeeksCount,
          workweek: calendarView.workWeek,
        }}
        day={{
          daynames: [
            t("Sun"),
            t("Mon"),
            t("Tue"),
            t("Wed"),
            t("Thu"),
            t("Fri"),
            t("Sat"),
          ],
        }}
        schedules={[...trip, ...trippets]}
        template={{
          milestone(schedule) {
            return `<span style="color:#fff;background-color: ${schedule.bgColor};">${schedule.title}</span>`;
          },
          milestoneTitle() {
            return "Milestone";
          },
          allday(schedule) {
            return handleAllDayEvent(schedule, false);
          },
          alldayTitle(schedule) {
            return "All Day";
          },
          time: function(schedule) {
            return getTimeTemplate(schedule, false);
          },
          monthGridHeaderExceed: function(hiddenSchedules) {
            return `<span class=${classes.moreTitle}>${hiddenSchedules} more</span>`;
          },
        }}
        theme={CUSTOM_THEME}
        view={calendarView.value}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        onClickSchedule={e => clickSchedule(e)}
        onBeforeCreateSchedule={onBeforeCreateSchedule}
        onBeforeUpdateSchedule={onBeforeUpdateSchedule}
      />
      <TripModal
        open={isTripModalOpen}
        handleTripModalClose={handleTripModalClose}
        defaultValues={clickedTrippet}
      />
    </Fragment>
  );
};

export default MuiCalendar;
