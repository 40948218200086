import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import {store} from '../../store';

export const saveCurrentSettingCategory = payload => ({
  type: adminActionTypes.SAVE_CURRENT_SETTINGCATEGORY,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_SETTINGCATEGORY_CURRENT_PAGE,
  payload,
});

export const saveRows = payload => ({
  type: adminActionTypes.SAVE_SETTINGCATEGORY_ROW,
  payload
});

export const openRow = payload => ({
  type: adminActionTypes.OPEN_SETTINGCATEGORY_ROW,
  payload,
});

export const closeRow = payload => ({
  type: adminActionTypes.CLOSE_SETTINGCATEGORY_ROW,
  payload,
});

export const getSettingCategoryById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/setting/category/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentSettingCategory(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewSettingCategory = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/setting/category/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentSettingCategory(data));
          dispatch(getAllSettingCategories(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeSettingCategory = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/setting/category/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getSettingCategoryById(body.SettingCategory_ID));
          dispatch(getAllSettingCategories(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllSettingCategories = payload => ({
  type: adminActionTypes.SAVE_ALL_SETTINGCATEGORIES,
  payload,
});

export const saveSettingCategorySortElement = payload => ({
  type: adminActionTypes.SAVE_SETTINGCATEGORY_SORT_ELEMENT,
  payload,
});

export const saveSettingCategorySortByDirection = payload => ({
  type: adminActionTypes.SAVE_SETTINGCATEGORY_SORT_BY_DIRECTION,
  payload,
});

export const saveSettingCategoryPaginationData = payload => ({
  type: adminActionTypes.SAVE_SETTINGCATEGORY_PAGINATION_DATA,
  payload,
});

export const getAllSettingCategories = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: ROWS_PER_PAGE,
            Page: store.getState().settingCategory.currentPage,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/setting/category/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllSettingCategories(data.Data));
        dispatch(saveSettingCategoryPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteSettingCategory = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/setting/category/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else 
            dispatch(getAllSettingCategories(null));
        })
        .catch(error => console.log(error));
    }
  };
};
