import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import uniqid from "uniqid";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { showSnackBar } from "../../store/actions/actions/root";
import {
  updateProxyStatus,
  updateParticipantStatus,
  updateOrganizerStatus,
} from "../../store/actions/actions/trip";

const TripViewMode = ({
  open,
  handleClose,
  defaultValues,
  type,
  filterResults,
  sortedElementMyTrips,
  sortedByMyTrips,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector(state => state.user.user);
  const allUsersRoles = useSelector(state => state.app.allUsersRoles);
  const classes = useStyles();
  const [values, setValues] = useState({
    tripName: "",
    description: "",
    participants: "",
    organizers: "",
    proxies: "",
    message: "",
    tid: "",
    startDate: "",
    endDate: "",
    type: "",
    userRole: "",
    userRole_ID: "",
    userRoleName: "",
    userTrip_ID: "",
  });

  useEffect(() => {
    if (defaultValues && allUsersRoles && allUsersRoles.length > 0) {
      let userRoleId = defaultValues.fullTrip.UserRole_ID;
      let typeId = "";
      allUsersRoles.forEach(role => {
        if (role.UserRole_ID === userRoleId) {
          typeId = role.Type;
        }
      });
      let userTrip_ID = "";
      if (typeId === "1") {
        userTrip_ID = defaultValues.fullTrip.TripProxy_ID;
      } else if (typeId === "2") {
        userTrip_ID = defaultValues.fullTrip.TripParticipant_ID;
      } else if (typeId === "3") {
        userTrip_ID = defaultValues.fullTrip.TripOrganizer_ID;
      };

      setValues({
        tripName: defaultValues.fullTrip.Name,
        description: defaultValues.fullTrip.Description,
        participants: defaultValues.fullTrip.Participants,
        organizers: defaultValues.fullTrip.Organizers,
        proxies: defaultValues.fullTrip.Proxies,
        message: defaultValues.fullTrip.Message,
        tid: defaultValues.fullTrip.Trip_ID,
        startDate: defaultValues.fullTrip.StartDate,
        endDate: defaultValues.fullTrip.EndDate,
        type: typeId,
        userRole_ID: defaultValues.fullTrip.UserRole_ID,
        userRoleName: defaultValues.fullTrip.UserRoleName,
        userTrip_ID: userTrip_ID,
      });
    }
  }, [
    defaultValues,
    user.FirstName,
    user.Initials,
    user.LastName,
    user.User_ID,
    allUsersRoles,
  ]);

  const renderParticipantsStatusColor = status => {
    if (status === "1" || status === null || status === "0") {
      return classes.pending;
    } else if (status === "2") {
      return classes.accepted;
    } else if (status === "3") {
      return classes.rejected;
    }
  };

  const renderParticipants =
    values.participants && values.participants.length > 0
      ? values.participants.map(participant => (
          <Avatar
            key={
              participant.TripParticipantUser_ID ||
              participant.User_ID ||
              participant.key
            }
            className={classNames(
              classes.tripViewAvatar,
              renderParticipantsStatusColor(participant.ParticipantStatus_ID),
            )}
          >
            {`${participant.Initials || participant.TripParticipantInitials}`}
          </Avatar>
        ))
      : null;

  const renderOrganizers =
    values.organizers && values.organizers.length > 0
      ? values.organizers.map(organizer => (
          <Avatar
            key={uniqid()}
            className={classNames(
              classes.tripViewAvatar,
              renderParticipantsStatusColor(
                organizer.TripOrganizerAcceptanceStatus,
              ),
            )}
          >
            {`${organizer.Initials || organizer.TripOrganizerInitials}`}
          </Avatar>
        ))
      : null;

  const renderProxies =
    values.proxies && values.proxies.length > 0
      ? values.proxies.map(proxy => (
          <Avatar
            key={uniqid()}
            className={classNames(
              classes.tripViewAvatar,
              renderParticipantsStatusColor(proxy.TripProxyAcceptanceStatus),
            )}
          >
            {`${proxy.Initials || proxy.TripProxyInitials}`}
          </Avatar>
        ))
      : null;

  const handleDecline = () => {
    if (values.type === "1") {
      let body = {
        TripProxy_ID: values.userTrip_ID,
        TripProxyUserRole_ID: values.userRole_ID,
        TripProxyAcceptanceStatus: 3,
      };
      dispatch(
        updateProxyStatus(
          JSON.stringify(body),
          user.User_ID,
          filterResults,
          sortedElementMyTrips,
          sortedByMyTrips,
        ),
      );
    } else if (values.type === "2") {
      let body = {
        TripParticipant_ID: values.userTrip_ID,
        TripParticipantUserRole_ID: values.userRole_ID,
        ParticipantStatus_ID: 3,
      };
      dispatch(
        updateParticipantStatus(
          JSON.stringify(body),
          user.User_ID,
          filterResults,
          sortedElementMyTrips,
          sortedByMyTrips,
        ),
      );
    } else if (values.type === "3") {
      let body = {
        TripOrganizer_ID: values.userTrip_ID,
        TripOrganizerUserRole_ID: values.userRole_ID,
        TripOrganizerAcceptanceStatus: 3,
      };
      dispatch(
        updateOrganizerStatus(
          JSON.stringify(body),
          user.User_ID,
          filterResults,
          sortedElementMyTrips,
          sortedByMyTrips,
        ),
      );
    }
    dispatch(
      showSnackBar({
        type: "success",
        message: t("You successfully saved the trip invitation"),
      }),
    );
    handleClose();
  };

  const handleAccept = () => {
    if (values.type === "1") {
      let body = {
        TripProxy_ID: values.userTrip_ID,
        TripProxyUserRole_ID: values.userRole_ID,
        TripProxyAcceptanceStatus: 2,
      };
      dispatch(
        updateProxyStatus(
          JSON.stringify(body),
          user.User_ID,
          filterResults,
          sortedElementMyTrips,
          sortedByMyTrips,
        ),
      );
    } else if (values.type === "2") {
      let body = {
        TripParticipant_ID: values.userTrip_ID,
        TripParticipantUserRole_ID: values.userRole_ID,
        ParticipantStatus_ID: 2,
      };
      dispatch(
        updateParticipantStatus(
          JSON.stringify(body),
          user.User_ID,
          filterResults,
          sortedElementMyTrips,
          sortedByMyTrips,
        ),
      );
    } else if (values.type === "3") {
      let body = {
        TripOrganizer_ID: values.userTrip_ID,
        TripOrganizerUserRole_ID: values.userRole_ID,
        TripOrganizerAcceptanceStatus: 2,
      };
      dispatch(
        updateOrganizerStatus(
          JSON.stringify(body),
          user.User_ID,
          filterResults,
          sortedElementMyTrips,
          sortedByMyTrips,
        ),
      );
    }
    dispatch(
      showSnackBar({
        type: "success",
        message: t("You successfully saved the trip invitation"),
      }),
    );
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
      styles={{ zIndex: 9999 }}
    >
      {" "}
      <DialogTitle id="responsive-dialog-title" className={classes.modalTitle}>
        {t("Trip Overview")}
      </DialogTitle>
      <DialogContent>
        <form className={classes.form}>
          <Grid container>
            <Grid item xs={12} md={7} className={classes.tripNameView}>
              <p className={classes.sectionTitle}>{t("Trip Name")}:</p>
              <h3>{values && values.tripName}</h3>
            </Grid>
            <Grid
              container
              item
              xs={12}
              spacing={2}
              className={classes.spacing}
            >
              <Grid item xs={6}>
                <p className={classes.sectionTitle}>{t("Start Date")}:</p>
                {values &&
                  values.startDate &&
                  format(new Date(values.startDate), "dd.MM.yyyy")}
              </Grid>
              <Grid item xs={6}>
                <p className={classes.sectionTitle}>{t("End Date")}:</p>
                {values &&
                  values.endDate &&
                  format(new Date(values.endDate), "dd.MM.yyyy")}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.descriptionView}>
              <p className={classes.sectionTitle}>{t("Description")}:</p>
              {values && values.description}
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              className={classes.spacingRoles}
            >
              <div className={classes.participantsTitle}>
                {t("Participants")}:
              </div>
              {renderParticipants}
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              className={classes.spacingRoles}
            >
              <div className={classes.participantsTitle}>
                {t("Organizers")}:
              </div>
              {renderOrganizers}
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              className={classes.spacingRoles}
            >
              <div className={classes.participantsTitle}>{t("Proxies")}:</div>
              {renderProxies}
            </Grid>
            <Grid item xs={12} className={classes.spacing}>
              <p className={classes.sectionTitle}>{t("Message")}:</p>
              {values && values.message}
            </Grid>
            <Grid item xs={12} className={classes.spacing}>
              <p className={classes.sectionTitle}>{t("Role Name")}:</p>
              {values && values.userRoleName}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {t("Exit")}
        </Button>
        {type === "received" && (
          <Button autoFocus onClick={handleDecline} color="secondary">
            {t("Decline")}
          </Button>
        )}
        {type === "received" && (
          <Button onClick={handleAccept} color="primary" variant="contained">
            {t("Accept")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TripViewMode;
