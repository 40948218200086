export const columns = [
    { id: 'actions', label: '', minWidth: 70, type: 'actions' },
    {
      id: 'ParentDepartmentName',
      label: 'Parent',
      minWidth: 80,
      type: 'Name',
    },
    { id: 'Name', label: 'Name', minWidth: 80, type: 'Name' },
    { id: 'Acronym', label: 'Acronym', minWidth: 40, type: 'Name' },
  ];
  