import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allLanguages: null,
  entireLanguages: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'Name',
  sortByDirection: 'asc',
  currentLanguage: {
    Language_ID: 0,
    Abbreviation: '',
    Icon: '',
    MaterialIO: '',
    IsDefault: false,
    Names: []
  },
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_LANGUAGE: {
      return {
        ...state,
        currentLanguage: {
            Language_ID: action.payload.Language_ID,
            Abbreviation: action.payload.Abbreviation,
            Icon: action.payload.Icon,
            MaterialIO: action.payload.MaterialIO,
            IsDefault: action.payload.IsDefault,
            Names: action.payload.Names,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_LANGUAGES: {
      return {
        ...state,
        allLanguages: action.payload,
      };
    }
    case adminActionTypes.SAVE_ENTIRE_LANGUAGES: {
      return {
        ...state,
        entireLanguages: action.payload,
      };
    }
    case adminActionTypes.SAVE_LANGUAGE_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_LANGUAGE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_LANGUAGE_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_LANGUAGE_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allLanguages: null,
        entireLanguages: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentLanguage: {
            Language_ID: 0,
            Abbreviation: '',
            Icon: '',
            MaterialIO: '',
            IsDefault: false,
            Names: []
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default languageReducer;