import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  // this one is for login/logout
  user: '',

  // these are for user management in admin section
  allUsers: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'DisplayName',
  sortByDirection: 'asc',
  // since we don't edit all fields, all fields are not listed (like LastTripID)
  currentUser: {
    User_ID: 0,
    FirstName: '',
    LastName: '',
    Email: '',
    DisplayName: '',
    Gender: '',
    HomeAddress: '',
    PhoneNumber: '',
    Country_ID: null,
    BaseCurrency_ID: null,
    BirthDate: '',
    AdminRole_ID: null,
    Language_ID: null,
    Department_ID: null,
    TimeZone: '',
    TimeZoneDesc: '',
    Status: '',
    Picture: '',
    CountryName: '',
    BaseCurrencyName: '',
    DepartmentName: '',
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_USER:
      return {
        ...state,
        user: action.payload,
      };
    // user management actions
    case adminActionTypes.SAVE_CURRENT_USER: {
      return {
        ...state,
        currentUser: {
            User_ID: action.payload.User_ID,
            FirstName: action.payload.FirstName,
            LastName: action.payload.LastName,
            Email: action.payload.Email,
            DisplayName: action.payload.DisplayName,
            Gender: action.payload.Gender,
            HomeAddress: action.payload.HomeAddress,
            PhoneNumber: action.payload.PhoneNumber,
            Country_ID: action.payload.Country_ID,
            BaseCurrency_ID: action.payload.BaseCurrency_ID,
            BirthDate: action.payload.BirthDate,
            AdminRole_ID: action.payload.AdminRole_ID,
            Language_ID: action.payload.Language_ID,
            Department_ID: action.payload.Department_ID,
            TimeZone: action.payload.TimeZone,
            TimeZoneDesc: action.payload.TimeZoneDesc,
            Status: action.payload.Status,
            Picture: action.payload.Picture,
            CountryName: action.payload.CountryName,
            BaseCurrencyName: action.payload.BaseCurrencyName,
            DepartmentName: action.payload.DepartmentName,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_USERS: {
      return {
        ...state,
        allUsers: action.payload,
      };
    }
    case adminActionTypes.SAVE_USER_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_USER_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_USER_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_USER_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        user: '',
        allUsers: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentUser: {
          User_ID: 0,
          FirstName: '',
          LastName: '',
          Email: '',
          DisplayName: '',
          Gender: '',
          HomeAddress: '',
          PhoneNumber: '',
          Country_ID: null,
          BaseCurrency_ID: null,
          BirthDate: '',
          AdminRole_ID: null,
          Language_ID: null,
          Department_ID: null,
          TimeZone: '',
          TimeZoneDesc: '',
          Status: '',
          Picture: '',
          CountryName: '',
          BaseCurrencyName: '',
          DepartmentName: '',
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default userReducer;
