import React, { useState, useEffect } from "react";
import withDashboard from "../../hoc/withDashboard/withDashboard";
import withTitle from "../../hoc/withTitle";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllTrips } from "../../store/actions/actions/trip";
import { getTotalPrice } from "../../store/actions/actions/trippet";
import TableOne from "./TableOne";
import TableTwo from "./TableTwo";
import ReportOne from "./ReportOne";
import ReportTwo from "./ReportTwo";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const Reports = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [trip, setTrip] = useState("");
  const [buddy, setBuddy] = useState("participants");
  const [allUserTrips, setAllUserTrips] = useState([]);
  const allTrips = useSelector(state => state.trip.allTrips);
  const user = useSelector(state => state.user.user);
  const usedFilters = useSelector(state => state.trip.usedFilters);
  const sortElementTrip = useSelector(state => state.trip.sortElement);
  const sortByDirectionTrip = useSelector(state => state.trip.sortByDirection);
  const tripTotalPrice = useSelector(state => state.trippet.tripTotalPrice);

  useEffect(() => {
    if (user) {
      const bodyTrips = {
        User_ID: user.User_ID,
        Type: 2,
        Page: 1,
        RowsPerPage: 10,
        OrderBy: sortElementTrip,
        OrderDir: sortByDirectionTrip[sortElementTrip],
        Language_ID: user.Language_ID,
        IsDeleted: "0",
      };
      if (usedFilters && usedFilters.length > 0) {
        usedFilters.forEach(filter => {
          bodyTrips[filter.name] = filter.body;
        });
      }

      dispatch(getAllTrips(JSON.stringify(bodyTrips)));
    }
  }, [user, dispatch, sortByDirectionTrip, sortElementTrip, usedFilters]);

  useEffect(() => {
    if (trip) {
      let totalPrice = {
        Language_ID: user.Language_ID,
        Trip_ID: trip,
      };
      dispatch(getTotalPrice(JSON.stringify(totalPrice)));
    }
  }, [trip, dispatch, user.Language_ID]);

  useEffect(() => {
    if (allTrips && allTrips.length > 0) {
      setAllUserTrips(allTrips);
    } else {
      setAllUserTrips([]);
    }
  }, [allTrips]);

  const handleChange = event => {
    setTrip(event.target.value);
  };

  const handleChangeBuddy = event => {
    setBuddy(event.target.value);
  };

  const renderTrips = () => {
    if (allUserTrips && allUserTrips.length > 0) {
      return allUserTrips.map(trip => (
        <MenuItem key={trip.Trip_ID} value={trip.Trip_ID}>
          {trip.Name}
        </MenuItem>
      ));
    }
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} className={classes.backToCockpit}>
        <Link to="/">
          <KeyboardBackspaceIcon />
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            className={classes.title}
            id={"Invitations"}
          >
            {t("Back to Cockpit")}
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          className={classes.title}
        >
          {t("Reports")}
        </Typography>
      </Grid>
      <Grid item container xs={12} className={classes.reportsPageContainer}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <FormControl className={classes.tripSelect} variant="outlined">
              <InputLabel id="demo-simple-select-label">
                {t("Select a Trip")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={trip}
                onChange={handleChange}
              >
                <MenuItem disabled value="">
                  {t("Select Trip")}
                </MenuItem>
                {renderTrips()}
              </Select>
            </FormControl>
            {trip && <FormControl className={classes.bodySelect} variant="outlined">
              <InputLabel id="demo-simple-select-label">
                {t("Select Buddy Type")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={buddy}
                onChange={handleChangeBuddy}
              >
                <MenuItem value="participants">
                  {t("Participants")}
                </MenuItem>
                <MenuItem value="organizers">
                  {t("Organizers")}
                </MenuItem>
                <MenuItem value="proxies">
                  {t("Proxies")}
                </MenuItem>
              </Select>
            </FormControl>}
          </Grid>
          <Grid item xs={7} className={classes.tableMarginTop}>
            {trip && <TableOne trip_ID={trip} allTrips={allTrips} buddy={buddy} />}
          </Grid>
          <Grid item xs={5}>
            {trip && <ReportOne trip_ID={trip} allTrips={allTrips} buddy={buddy}/>}
          </Grid>
          <Grid item xs={12} container className={classes.tableRowMarginTop}>
            <Grid item xs={7} className={classes.tableMarginTop}>
              {trip && (
                <TableTwo trip_ID={trip} allTrips={allTrips} user={user} tripTotalPrice={tripTotalPrice} buddy={buddy}/>
              )}
            </Grid>
            <Grid item xs={5} className={classes.chartMargin}>
              {trip && <ReportTwo trip_ID={trip} allTrips={allTrips} buddy={buddy}/>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTitle(withDashboard(Reports), "Reports");
