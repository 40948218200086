import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewUserRoleAllow, changeUserRoleAllow } from '../../store/actions/actions/userRoleAllow';
import { getEntireUserRoles } from '../../store/actions/actions/userRole';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import * as userAccess from '../../userAccess';

const UserRoleAllowModal = ({ open, handleUserRoleAllowModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [refTableError, setTakerUserRole_IDError] = useState(false);
  const [formError, setFormError] = useState(false);
  const entireUserRoles = useSelector(state => state.userRole.entireUserRoles);
  
  const [values, setValues] = useState({
    GiverUserRole_ID: 0,
    TakerUserRole_ID: 0
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure UserRoles are already loaded
    if (!entireUserRoles)
    {
      dispatch(getEntireUserRoles(null));
      return;
    }
    if (defaultValues) {
      setValues({
          UserRoleAllow_ID: defaultValues.UserRoleAllow_ID,
          GiverUserRole_ID: defaultValues.GiverUserRole_ID,
          TakerUserRole_ID: defaultValues.TakerUserRole_ID,
      });
      handleIsEditMode(defaultValues.UserRoleAllow_ID ? true : false);
        
    } else {
      setValues({
        UserRoleAllow_ID: 0,
        GiverUserRole_ID: 0,
        TakerUserRole_ID: 0,
      });
      handleIsEditMode(false);
    }
  }, [defaultValues, entireUserRoles, dispatch]);

  const validateForm = ({ TakerUserRole_ID }) => {
    if (TakerUserRole_ID) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };


  const handleSubmit = e => {
    e.stopPropagation();
    if (!refTableError) {
        e.preventDefault();
        const data = {
            UserRoleAllow_ID: +values.UserRoleAllow_ID,
            GiverUserRole_ID: +values.GiverUserRole_ID,
            TakerUserRole_ID: values.TakerUserRole_ID,
        };
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changeUserRoleAllow(JSON.stringify(data)));
          handleUserRoleAllowModalClose();
        }
      } else {
        if (validateForm(data)) {
          dispatch(createNewUserRoleAllow(JSON.stringify(data)));
          handleUserRoleAllowModalClose();
        }
      }
    }
  };

  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name === 'TakerUserRole_ID') {
      if (e.target.value > 0) {
        setTakerUserRole_IDError(false);
      } else {
        setTakerUserRole_IDError(true);
      }
    }
    setValues({...values, [e.target.name]: e.target.value});
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const renderTitle = defaultValues && defaultValues.UserRoleAllow_ID ? t('Edit Allow') : t('Add New Allow');
  
  const renderUserRoles =
    entireUserRoles && entireUserRoles.length > 0
      ? entireUserRoles.map(cat => {
          return (
            <MenuItem key={cat.UserRole_ID} value={cat.UserRole_ID}>{cat.Name}</MenuItem>
          );
        })
      : null;

  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;

  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_Users))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_Users));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={12} className={classes.departmentName}>
                <FormControl variant="outlined" className={classes.selectView}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Taker User Role')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Taker User Role')}
                    name="TakerUserRole_ID"
                    value={values.TakerUserRole_ID}
                    onChange={handleInputs}
                  >
                    {renderUserRoles}
                  </Select>
                </FormControl>
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleUserRoleAllowModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default UserRoleAllowModal;
