export const columns = [
    { id: 'actions', label: '', minWidth: 70, type: 'actions' },
    {
      id: 'PreferenceCategoryName',
      label: 'Category',
      minWidth: 80,
      type: 'Name',
    },
    { id: 'Name', label: 'Name', minWidth: 80, type: 'Name' },
  ];
  