import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import DatePicker from '../DatePicker/DatePicker';
import { createNewTrip, changeTripWithoutParticipants } from '../../store/actions/actions/trip';
import { changeTrippetWithoutParticipants } from '../../store/actions/actions/trippet';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { format } from 'date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import RecommendationModal from '../RecommendationModal/RecommendationModal';
import { differenceInCalendarDays } from 'date-fns';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const TripModal = ({ open, handleTripModalClose, defaultValues }) => {
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isRecommendationModalOpen, setIsRecommendationModalOpen] = useState(
    false,
  );
  const [tripRecommendationInfo, setTripRecommendationInfo] = useState(null);
  const user = useSelector(state => state.user.user);
  const [startDate, handleStartDate] = useState(new Date());
  const [isEditMode, handleIsEditMode] = useState(false);
  const [descriptionDigitsCounter, setDescriptionDigitsCounter] = useState(0);
  const [messageDigitsCounter, setMessageDigitsCounter] = useState(0);
  const [endDate, handleEndDate] = useState(new Date());
  const [descriptionError, setDescriptionError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [formError, setFormError] = useState(false);
  const allUsers = useSelector(state => state.app.allUsers);
  const currentTrippets = useSelector(state => state.trippet.allTrippets);
  const usedFilters = useSelector(state => state.trip.usedFilters);
  const sortElement = useSelector(state => state.trip.sortElement);
  const sortByDirection = useSelector(state => state.trip.sortByDirection);

  const [values, setValues] = useState({
    tripName: '',
    description: '',
    participants: '',
    message: '',
    tid: '',
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    if (defaultValues) {
      setValues({
        tripName: defaultValues.Name,
        description: defaultValues.Description,
        participants: defaultValues.Participants,
        message: defaultValues.Message,
        tid: defaultValues.Trip_ID,
      });
      setDescriptionDigitsCounter(defaultValues.Description.length);
      setMessageDigitsCounter(defaultValues.Message.length);
      handleStartDate(new Date(defaultValues.StartDate));
      handleEndDate(new Date(defaultValues.EndDate));
      handleIsEditMode(true);
    } else {
      setValues({
        tripName: '',
        description: '',
        participants: [],
        message: '',
      });
      handleStartDate(new Date());
      handleEndDate(new Date());
      handleIsEditMode(false);
      setDescriptionDigitsCounter(0);
      setMessageDigitsCounter(0);
    }
  }, [
    defaultValues,
    user.FirstName,
    user.Initials,
    user.LastName,
    user.User_ID,
    user.firstname,
    user.lastname,
    user.uid,
    user.user_id,
  ]);

  const validateForm = ({ Description, StartDate, EndDate, Name }) => {
    if (Description && EndDate && StartDate && Name) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };

  const handleStartDateInput = date => {
    handleStartDate(date);
    handleEndDate(date);
  };

  const updateAllTripetsDaysOnTripDnD = (tripStartDate, tripEndDate) => {
    currentTrippets.forEach(trippet => {
      const trippetBody = {
        Trippet_ID: trippet.Trippet_ID,
        Days: '',
      };
      let tripTotalDays = differenceInCalendarDays(
        new Date(tripStartDate),
        new Date(tripEndDate),
      );

      let startTrippetDayDifference = differenceInCalendarDays(
        new Date(tripEndDate),
        new Date(trippet.StartDate),
      );

      let endTrippetDayDifference = differenceInCalendarDays(
        new Date(trippet.EndDate),
        new Date(tripEndDate),
      );

      let renderStartDay = 1;
      if (startTrippetDayDifference === Math.abs(tripTotalDays) + 1) {
        renderStartDay =
          Math.abs(tripTotalDays) + 1 - startTrippetDayDifference - 1;
      } else if (startTrippetDayDifference > Math.abs(tripTotalDays) + 1) {
        renderStartDay =
          Math.abs(tripTotalDays) + 1 - startTrippetDayDifference - 1;
      } else if (startTrippetDayDifference > 0) {
        renderStartDay =
          Math.abs(tripTotalDays) + 1 - startTrippetDayDifference;
      } else if (startTrippetDayDifference < 0) {
        renderStartDay =
          Math.abs(tripTotalDays) + 1 + Math.abs(startTrippetDayDifference);
      } else if (startTrippetDayDifference === 0) {
        renderStartDay = Math.abs(tripTotalDays) + 1;
      } else {
        renderStartDay =
          Math.abs(tripTotalDays) + 1 - Math.abs(startTrippetDayDifference) - 1;
      }

      let renderEndDay = 1;

      if (endTrippetDayDifference > Math.abs(tripTotalDays) + 1) {
        renderEndDay = endTrippetDayDifference + Math.abs(tripTotalDays) + 1;
      } else if (endTrippetDayDifference === 0) {
        renderEndDay = Math.abs(tripTotalDays) + 1;
      } else if (endTrippetDayDifference > 0) {
        renderEndDay = endTrippetDayDifference + Math.abs(tripTotalDays) + 1;
      } else if (endTrippetDayDifference < 0) {
        if (Math.abs(tripTotalDays) - Math.abs(endTrippetDayDifference) < 0) {
          renderEndDay =
            Math.abs(tripTotalDays) - Math.abs(endTrippetDayDifference);
        } else {
          renderEndDay =
            Math.abs(tripTotalDays) + 1 - Math.abs(endTrippetDayDifference);
        }
      }
      trippetBody.EndDay = +renderEndDay;
      trippetBody.StartDay = +renderStartDay;
      dispatch(
        changeTrippetWithoutParticipants(
          JSON.stringify(trippetBody),
          null,
          null,
          null,
          values.tid,
          user.User_ID
        ),
      );
    });
  };

  const handleSubmit = e => {
    e.stopPropagation();
    if (!descriptionError && !messageError) {
      if (isEditMode) {
        e.preventDefault();
        const trip = {
          Trip_ID: +defaultValues.Trip_ID,
          Name: values.tripName,
          Description: values.description,
          Message: values.message,
          StartDate: `${format(startDate, 'yyyy-MM-dd')}T${format(
            startDate,
            'hh:mm',
          )}Z+01:00`,
          EndDate: `${format(endDate, 'yyyy-MM-dd')}T${format(
            endDate,
            'hh:mm',
          )}Z+01:00`,
          IsDeleted: '0',
          OwnerPlanningStatus_ID: '1',
          // Participants: tripParticipants,
        };
        if (validateForm(trip)) {
          dispatch(
            changeTripWithoutParticipants(
              JSON.stringify(trip),
              user.User_ID,
              sortElement,
              sortByDirection[sortElement],
              usedFilters,
            ),
          );
          updateAllTripetsDaysOnTripDnD(startDate, endDate);
          handleTripModalClose();
        }
      } else {
        e.preventDefault();
        const trip = {
          OwnerUser_ID: user.User_ID,
          CreatorUser_ID: user.User_ID,
          Name: values.tripName,
          Description: values.description,
          Message: values.message,
          IsDeleted: '0',
          StartDate: `${format(startDate, 'yyyy-MM-dd')}T${format(
            startDate,
            'hh:mm',
          )}Z+01:00`,
          StartTimeZoneDesc: 'Europe/Berlin',
          EndDate: `${format(endDate, 'yyyy-MM-dd')}T${format(
            endDate,
            'hh:mm',
          )}Z+01:00`,
          EndTimeZoneDesc: 'Europe/Berlin',
          OwnerPlanningStatus_ID: '',
          // Participants: tripParticipants,
        };
        if (validateForm(trip)) {
          dispatch(createNewTrip(JSON.stringify(trip), user.User_ID));
          handleTripModalClose();
          history.push('/')
        }
      }
    }
  };

  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name === 'description') {
      setDescriptionDigitsCounter(e.target.value.length);
      if (e.target.value.length > 1500) {
        setDescriptionError(true);
      } else {
        setDescriptionError(false);
      }
    } else if (e.target.name === 'message') {
      setMessageDigitsCounter(e.target.value.length);
      if (e.target.value.length > 1500) {
        setMessageError(true);
      } else {
        setMessageError(false);
      }
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleRecommendationModalOpen = event => {
    event.stopPropagation();
    setIsRecommendationModalOpen(true);
  };

  const handleRecommendationModalClose = event => {
    event.stopPropagation();
    setIsRecommendationModalOpen(false);
    handleTripModalClose();
  };

  const handleSendAsRecommendation = event => {
    event.stopPropagation();
    const participantsIds = [];
    values.participants.forEach(participant =>
      participantsIds.push({
        TripRecommendationRecipientUser_ID: `${participant.user_id}`,
      }),
    );
    const body = {
      Trip_ID: defaultValues.Trip_ID,
      TripRecommendationSenderUser_ID: `${user.User_ID}`,
      Message: values.message,
      Recipients: participantsIds,
    };
    setTripRecommendationInfo(body);
    handleRecommendationModalOpen(event);
  };

  const renderTitle = defaultValues ? t('Edit Trip') : t('New Trip');

  const renderDescriptionError = descriptionError
    ? t('Too many digits. The limit is 1500.')
    : null;

  const renderMessageError = messageError
    ? t('Too many digits. The limit is 1500.')
    : null;

  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          <div className={classes.modalTopContainer}>
            {renderTitle}
            <IconButton onClick={handleConfirmModalOpen}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={12} className={classes.tripName}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="tripName"
                  label={t('Trip Name')}
                  name="tripName"
                  autoComplete="tripName"
                  autoFocus
                  value={values.tripName}
                  onChange={handleInputs}
                />
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6} className={classes.pickers}>
                  <DatePicker
                    label="Start Date"
                    selectedDate={startDate}
                    handleDateChange={handleStartDateInput}
                  />
                </Grid>
                <Grid item xs={6} className={classes.pickers}>
                  <DatePicker
                    label="End Date"
                    selectedDate={endDate}
                    handleDateChange={handleEndDate}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="description"
                  label={t('Description')}
                  placeholder={t('Write something explaining your trip title.')}
                  name="description"
                  autoComplete="trip-description"
                  autoFocus
                  value={values.description}
                  onChange={handleInputs}
                  multiline
                  rowsMax="6"
                  rows={6}
                />
                <FormHelperText>
                  {`${descriptionDigitsCounter} / 1500`}
                </FormHelperText>
                <FormHelperText className={classes.errorText}>
                  {renderDescriptionError}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="message"
                  label={t('Message')}
                  placeholder={t('Message to participants.')}
                  name="message"
                  autoComplete="trip-message"
                  autoFocus
                  value={values.message}
                  onChange={handleInputs}
                  // className={classes.textAreaField}
                  multiline
                  rowsMax="6"
                  rows={6}
                />
                <FormHelperText>
                  {`${messageDigitsCounter} / 1500`}
                </FormHelperText>
                <FormHelperText className={classes.errorText}>
                  {renderMessageError}
                </FormHelperText>
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            onClick={handleSendAsRecommendation}
            color="primary"
            variant="contained"
            disabled={!defaultValues}
          >
            {t('Send as Recommendation')}
          </Button>
          <Button
            color="primary"
            autoFocus
            variant="contained"
            onClick={handleSubmit}
          >
            {t('Save and Share')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleTripModalClose}
        handleClose={handleConfirmModalClose}
      />
      {/* <ParticipantsModal
        open={isParticipantsModalOpen}
        handleClose={handleParticipantsModalClose}
        handleSaveParticipants={handleSaveParticipants}
        allUsers={allUsers}
        participants={values.participants}
      /> */}
      <RecommendationModal
        open={isRecommendationModalOpen}
        handleClose={handleRecommendationModalClose}
        allUsers={allUsers}
        trippet
        trip={tripRecommendationInfo}
      />
      {/* <InvitationalModal
        open={isInvitationalModalOpen}
        handleClose={handleInvitationalModalClose}
        allUsers={allUsers}
        trippet
        trip={tripRecommendationInfo}
        handleSend={handleSaveExternalUsersEmail}
      /> */}
    </Fragment>
  );
};

export default TripModal;
