import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from 'react-i18next';

const ReportOne = ({trip_ID, allTrips, buddy}) => {
  const { t } = useTranslation();
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [accepted, setAccepted] = useState(0);
  const [declined, setDeclined] = useState(0);
  const [pending, setPending] = useState(0);
  const [allParticipants, setParticipants] = useState([]);
  const [data, setData] = useState({});

  const calculatePercent = (num, total) => {
    if(num && total){
      return ((100 * num) / total).toFixed(2)
    }else{
      return 0
    }
  };

  useEffect(() => {
    setData({
      labels: ["Accepted", "Declined", "Pending"],
      datasets: [
        {
          label: "Status In Percents",
          data: [calculatePercent(accepted, allParticipants.length), calculatePercent(declined, allParticipants.length), calculatePercent(pending, allParticipants.length)],
          backgroundColor: [
            "rgba(77, 175, 124, 1)",
            "rgba(241, 130, 141,1)",
            "rgba(137, 196, 244, 1)",
          ],
        },
      ],
    })
  }, [accepted, declined, pending, allParticipants])

  useEffect(() => {
    if (selectedTrip) {
      let acceptedParticipants = 0;
      let declinedParticipants = 0;
      let pendingParticipants = 0;
      if (buddy === "participants") {
      setParticipants(selectedTrip.Participants);
        let participants = selectedTrip.Participants;
        participants.forEach(buddy => {
          if (
            buddy.ParticipantStatus_ID === "1" ||
            buddy.ParticipantStatus_ID === null ||
            buddy.ParticipantStatus_ID === "0"
          ) {
            pendingParticipants = pendingParticipants + 1;
          } else if (buddy.ParticipantStatus_ID === "2") {
            acceptedParticipants = acceptedParticipants + 1;
          } else if (buddy.ParticipantStatus_ID === "3") {
            declinedParticipants = declinedParticipants + 1;
          }
        });
      } else if (buddy === "organizers") {
      setParticipants(selectedTrip.Organizers);
        let organizers = selectedTrip.Organizers;
        organizers.forEach(buddy => {
          if (
            buddy.TripOrganizerAcceptanceStatus === "1" ||
            buddy.TripOrganizerAcceptanceStatus === null ||
            buddy.TripOrganizerAcceptanceStatus === "0"
          ) {
            pendingParticipants = pendingParticipants + 1;
          } else if (buddy.TripOrganizerAcceptanceStatus === "2") {
            acceptedParticipants = acceptedParticipants + 1;
          } else if (buddy.TripOrganizerAcceptanceStatus === "3") {
            declinedParticipants = declinedParticipants + 1;
          }
        });
      } else if (buddy === "proxies") {
      setParticipants(selectedTrip.Proxies);
        let proxies = selectedTrip.Proxies;
        proxies.forEach(buddy => {
          if (
            buddy.TripProxyAcceptanceStatus === "1" ||
            buddy.TripProxyAcceptanceStatus === null ||
            buddy.TripProxyAcceptanceStatus === "0"
          ) {
            pendingParticipants = pendingParticipants + 1;
          } else if (buddy.TripProxyAcceptanceStatus === "2") {
            acceptedParticipants = acceptedParticipants + 1;
          } else if (buddy.TripProxyAcceptanceStatus === "3") {
            declinedParticipants = declinedParticipants + 1;
          }
        });
      }
      setAccepted(acceptedParticipants);
      setDeclined(declinedParticipants);
      setPending(pendingParticipants);
    }
  }, [selectedTrip, buddy]);

  useEffect(() => {
    if (trip_ID && allTrips) {
      const trip = allTrips.find(trip => trip.Trip_ID === trip_ID);
      setSelectedTrip(trip);
    }
    return () => {
      setSelectedTrip(null);
    };
  }, [trip_ID, allTrips]);

  const renderTitle = () => {
    if(buddy === 'proxies'){
      return 'Proxies Status'
    }else if(buddy === 'organizers'){
      return 'Organizers Status'
    }else if(buddy === 'participants'){
      return 'Participants Status'
    }
  }


  return (
    <div>
      <Bar
        data={data}
        options={{
          title: {
            display: true,
            text: `${t(renderTitle())}`,
            fontSize: 16,
          },
          legend: {
            display: true,
            position: 'bottom'
          },
        }}
      />
    </div>
  );
};

export default ReportOne;
