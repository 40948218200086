import React, { useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewSettingCategory, changeSettingCategory } from '../../store/actions/actions/settingCategory';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import {getEntireLanguages} from '../../store/actions/actions/language';

const SettingCategoryModal = ({ open, handleSettingCategoryModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [namesError, setNamesError] = useState(false);
  const [formError, setFormError] = useState(false);
  const entireLanguages = useSelector(state => state.language.entireLanguages);
  const currentSettingCategory = useSelector(state => state.settingCategory.currentSettingCategory);
  const [values, setValues] = useState({
    Comments: '',
    Names: [],
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure languages is already loaded
    if (!entireLanguages)
    {
      dispatch(getEntireLanguages(null));
      return;
    }
    if (defaultValues) {
        // when there is a new language, the name for that language in 'Names' is missing
        // so we need to iterate through entireLanguages and push missed ones
        const names = currentSettingCategory ? currentSettingCategory.Names : defaultValues.Names;
        if (names && Array.isArray(names))
          entireLanguages.forEach(name => {
            var found = false;
            names.forEach(name2 => {
              if (Number(name2.Language_ID) === Number(name.Language_ID))
                found = true;
            });
            if (!found)
              names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
          });
        if (currentSettingCategory)
        {
            setValues({
                Names: names,
                SettingCategory_ID: currentSettingCategory.SettingCategory_ID,
                Comments: currentSettingCategory.Comments,
            });
        }
        else
        {
            setValues({
                Names: names,
                SettingCategory_ID: defaultValues.SettingCategory_ID,
                Comments: defaultValues.Comments,
            });
        }
        
    //   setValues({...values, Names: names});
        handleIsEditMode(true);
    } else {
      
      const names = [];
      entireLanguages.forEach(name => {
        names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
      });
      setValues({
        SettingCategory_ID: 0,
        Comments: '',
        Names: names,
      });
      handleIsEditMode(false);
    }
  }, [dispatch, defaultValues, currentSettingCategory, entireLanguages]);

  const validateForm = ({ Names }) => {
    if (Names && Names.length > 0) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };


  const handleSubmit = e => {
    e.stopPropagation();
    if (!namesError) {
        e.preventDefault();
        const data = {
            SettingCategory_ID: +values.SettingCategory_ID,
            Comments: values.Comments,
            Names: values.Names,
        };
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changeSettingCategory(JSON.stringify(data)));
          handleSettingCategoryModalClose();
        }
      } else {
        if (validateForm(data)) {
          dispatch(createNewSettingCategory(JSON.stringify(data)));
          handleSettingCategoryModalClose();
        }
      }
    }
  };

  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name.indexOf("Name") === 0) // it is Names list
    {
        const names = [];
        values.Names.forEach(name => {
            if ("Name" + name.Language_ID === e.target.name)
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: e.target.value});
            else
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: name.Name});
        });
        setValues({...values, Names: names});
        setNamesError(names.length === 0);
    }
    else
    {
        setValues({...values, [e.target.name]: e.target.value});
    }
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };


  const renderTitle = defaultValues ? t('Edit Setting Category') : t('New Setting Category');
  const renderNames =
    values.Names && values.Names.length > 0
      ? values.Names.map(name => {
          return (
            <TableRow key={name.Language_ID} className={classes.rowContainer}>
                <TableCell>
                    <TextField
                    variant="outlined"
                    fullWidth
                    id={"Name" + name.Language_ID}
                    label={name.LanguageAbbreviation}
                    name={"Name" + name.Language_ID}
                    value={name.Name}
                    onChange={handleInputs}
                    />
                </TableCell>
              </TableRow>
          );
        })
      : null;

  const renderNamesError = namesError
    ? t('Names are required.')
    : null;

  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <Grid container>
          <Grid item xs={12} md={12} className={classes.planningStatusName}>
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              id="Comments"
              label={t('Meaning (please describe briefly)')}
              name="Comments"
              autoComplete="Comments"
              value={values.Comments}
              onChange={handleInputs}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              {t('Languages')}
            </Typography>
          </Grid>
        </Grid>
        <DialogContent>
          <form className={classes.form}>
            <TableContainer className={classes.container}>
                <Table>
                <TableBody>{renderNames}</TableBody>
                </Table>
            </TableContainer>
            <FormHelperText className={classes.errorText}>
              {renderNamesError}
            </FormHelperText>
            <FormHelperText className={classes.errorText}>
              {renderFormError}
            </FormHelperText>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleSettingCategoryModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default SettingCategoryModal;