export const columns = [
  { id: 'actions', label: '', minWidth: 70, type: 'actions', width: '2%' },
  {
    id: 'date',
    label: 'Date',
    minWidth: 50,
    align: 'center',
    type: 'StartDate',
    filter: 'startdates',
    width: '13%',
  },
  { id: 'name', label: 'Trip Title', minWidth: 50, type: 'Name', width: '5%' },
  {
    id: 'owner',
    label: 'Owner',
    minWidth: 50,
    align: 'left',
    type: 'OwnerUser_ID',
    filter: 'owners',
    width: '10%'
  },
  {
    id: 'participants',
    label: 'Participants',
    minWidth: 50,
    align: 'left',
    type: 'DisplayName',
    filter: 'participants',
    width: '13%',
  },
  {
    id: 'organizers',
    label: 'Organizer',
    minWidth: 80,
    align: 'left',
    type: 'DisplayName',
    filter: 'organizer',
    width: '12%',
  },
  {
    id: 'proxies',
    label: 'Proxies',
    minWidth: 80,
    align: 'left',
    type: 'DisplayName',
    filter: 'proxies',
    width: '11%',
  },
  {
    id: 'message',
    label: 'Message',
    minWidth: 140,
    align: 'left',
    type: 'Message',
    width: '28%',
  },
  {
    id: 'numTrippets',
    label: '#Trippets',
    minWidth: 80,
    align: 'center',
    type: 'NumTrippets',
    width: '2%',
  },
  {
    id: 'lastChanged',
    label: 'Last Changed',
    minWidth: 80,
    align: 'center',
    type: 'ModifiedDate',
    width: '5%',
  },
];
