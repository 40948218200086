export const columns = [
    { id: 'actions', label: '', minWidth: 70, type: 'actions' },
    { id: 'Picture', label: 'Picture', minWidth: 80, align: 'center',type: 'Icon' },
    { id: 'DisplayName', label: 'DisplayName', minWidth: 80, type: 'Name' }, 
    {
      id: 'Email',
      label: 'Email',
      minWidth: 80,
      align: 'center',
      type: 'Name',
    },
    { id: 'DepartmentName', label: 'Department', minWidth: 80, align: 'center',type: 'Name' },
    { id: 'AdminRoleName', label: 'Admin Role', minWidth: 80, align: 'center',type: 'Name' },
    { id: 'Status', label: 'Status', minWidth: 80, align: 'center',type: 'Name' },
  ];
  