import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { columns, childrenColumns } from "./columns";
import useStyles from "../styles";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import deLocale from "date-fns/locale/de";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Badge from "@material-ui/core/Badge";
import classNames from "classnames";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import { getTrippetTypeIcon } from "../../../pages/myTrippets/helper";
import Checkbox from "@material-ui/core/Checkbox";
import Row from "../Row";

const createData = (
  type,
  title,
  day,
  startDate,
  startTime,
  endDate,
  endTime,
  // link,
  // distance,
  isWholeDay,
  documents,
  color,
  fullTrippet,
) => {
  return {
    type,
    title,
    day,
    startDate,
    startTime,
    endDate,
    endTime,
    // link,
    // distance,
    isWholeDay,
    documents,
    color,
    fullTrippet,
  };
};

const TrippetDataTableThree = ({
  handleSortBy,
  sortedBy,
  sortedElement,
  handleOpenFilterModal,
  disabledFilters,
  activeFilters,
  filterResults,
  getNumberOfActiveFilters,
  checkedTrippets,
  handleCheckboxChange,
  handleAllChecked,
  allChecked,
  selectedTrippets,
  toogleChilds,
  setSaveOrdering,
  indeterminate
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dateLanguage, setLanguage] = useState("");
  const trippets = useSelector(
    state => state.trippet.parentsTrippetsWithChildren,
  );
  const language = useSelector(state => state.programSettings.language);
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const trippetChildrenStatus = useSelector(
    state => state.trippet.trippetChildrenStatus,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (language === "en") {
      setLanguage("");
    } else if (language === "de") {
      setLanguage(deLocale);
    }
  }, [language]);

  const getTrippetTypeColor = data => {
    if (trippetTypes) {
      let element = trippetTypes.find(type => data === type.TrippetTypeLook_ID);
      return element.Color;
    }
  };

  const renderDays = (start, end) => {
    if (start && end) {
      return `${start} to ${end}`;
    } else {
      return "";
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (trippets && trippets.length) {
      trippets.forEach(trippet => {
        elements.push(
          createData(
            getTrippetTypeIcon(
              trippet.TrippetTypeLook_ID,
              trippetTypes,
              classes,
            ),
            trippet.Title,
            renderDays(trippet.StartDay, trippet.EndDay),
            format(new Date(trippet.StartDate), "EEEEEE.dd MMM", {
              locale: dateLanguage,
            }),
            format(new Date(trippet.StartDate), "HH:mm"),
            format(new Date(trippet.EndDate), "EEEEEE.dd MMM", {
              locale: dateLanguage,
            }),
            format(new Date(trippet.EndDate), "HH:mm"),
            // trippet.Link,
            // trippet.Distance,
            trippet.IsWholeDay === "1" ? (
              <CheckCircleIcon className={classes.accept} />
            ) : (
              <CancelIcon className={classes.decline} />
            ),
            trippet.NumPublicDocuments,
            getTrippetTypeColor(trippet.TrippetTypeLook_ID),
            trippet,
          ),
        );
      });
      setRows(elements);
    }
  };

  const renderChildrenElements = (list, setChildrenRows) => {
    let elements = [];
    if (list && list.length) {
      list.forEach(trippet => {
        elements.push(
          createData(
            getTrippetTypeIcon(
              trippet.TrippetTypeLook_ID,
              trippetTypes,
              classes,
            ),
            trippet.Title,
            renderDays(trippet.StartDay, trippet.EndDay),
            format(new Date(trippet.StartDate), "EEEEEE.dd MMM", {
              locale: dateLanguage,
            }),
            format(new Date(trippet.StartDate), "HH:mm"),
            format(new Date(trippet.EndDate), "EEEEEE.dd MMM", {
              locale: dateLanguage,
            }),
            format(new Date(trippet.EndDate), "HH:mm"),
            // trippet.Link,
            // trippet.Distance,
            trippet.IsWholeDay === "1" ? (
              <CheckCircleIcon className={classes.accept} />
            ) : (
              <CancelIcon className={classes.decline} />
            ),
            trippet.NumPublicDocuments,
            getTrippetTypeColor(trippet.TrippetTypeLook_ID),
            trippet,
          ),
        );
      });
      setChildrenRows(elements);
    }
  };

  useEffect(() => {
    if (trippets && trippets.length && trippetChildrenStatus) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trippets, trippetTypes, trippetChildrenStatus]);

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <Row
              key={row.fullTrippet.Trippet_ID}
              row={row}
              checkedTrippets={checkedTrippets}
              handleCheckboxChange={handleCheckboxChange}
              toogleChilds={toogleChilds}
              renderChildrenElements={renderChildrenElements}
              columns={columns}
              childrenColumns={childrenColumns}
              setSaveOrdering={setSaveOrdering}
            />
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                const renederActiveSortingArrow =
                  sortedElement === column.type
                    ? classNames(
                        classes.tableHeader,
                        classes.tableHeaderCellActive,
                      )
                    : classNames(classes.tableHeader, classes.tableHeaderCell);
                if (column.id === "documents") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ width: 150 }}
                    >
                      {t(column.label)}
                      <Tooltip
                        title={t("Total number of public documents")}
                        placement="top"
                        arrow
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </TableCell>
                  );
                } else if (
                  column.id !== "actions" &&
                  column.id !== "children"
                ) {
                  if (column.filter) {
                    let activeColor = activeFilters[column.filter]
                      ? classNames(classes.filterButton, classes.activeFilter)
                      : classes.filterButton;
                    const renderNumber = getNumberOfActiveFilters(column);

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : "asc"
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                        <IconButton
                          className={activeColor}
                          onClick={handleOpenFilterModal(column)}
                          disabled={disabledFilters[column.filter]}
                        >
                          <Badge badgeContent={renderNumber} color="primary">
                            <FilterListIcon />
                          </Badge>
                        </IconButton>
                      </TableCell>
                    );
                  } else {
                    if (
                      column.id === "isWholeDay" ||
                      column.id === "startTime" ||
                      column.id === "endTime" ||
                      column.id === "startDate" ||
                      column.id === "endDate"
                    ) {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={renederActiveSortingArrow}
                          style={{ width: 120 }}
                        >
                          <TableSortLabel
                            onClick={handleSortBy(column.id)}
                            active={true}
                            direction={
                              sortedElement === column.type
                                ? sortedBy[sortedElement]
                                : "asc"
                            }
                          >
                            {t(column.label)}
                          </TableSortLabel>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={renederActiveSortingArrow}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            onClick={handleSortBy(column.id)}
                            active={true}
                            direction={
                              sortedElement === column.type
                                ? sortedBy[sortedElement]
                                : "asc"
                            }
                          >
                            {t(column.label)}
                          </TableSortLabel>
                        </TableCell>
                      );
                    }
                  }
                } else {
                  if (column.id === "actions") {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeaderCell}
                        style={{ minWidth: column.minWidth }}
                      >
                        <Checkbox
                          checked={allChecked}
                          onChange={handleAllChecked}
                          color="primary"
                          indeterminate={indeterminate}
                        />
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeaderCell}
                        style={{ minWidth: column.minWidth }}
                      >
                        {t(column.label)}
                      </TableCell>
                    );
                  }
                }
              })}
            </TableRow>
          </TableHead>
          {renderRows}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TrippetDataTableThree;
