import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import TripModal from "../TripForm/TripForm";
import BuddiesModal from "../BuddiesModal/BuddiesModal";
import { findProxyInfo, findOrganizerInfo } from "../BuddiesModal/helper";
import { getTripById } from "../../store/actions/actions/trip";
import { showSnackBar } from "../../store/actions/actions/root";
import uniqid from "uniqid";

const TripBoard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isTripModalOpen, setIsTripModalOpen] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);
  const [isOpenParticipantsHandling, setIsOpenParticipantsHandling] = useState(
    false,
  );
  const { t } = useTranslation();
  const currentTrip = useSelector(state => state.trip.currentTrip);
  const allUsers = useSelector(state => state.app.allUsers);
  const user = useSelector(state => state.user.user);

  const handleTripModalOpen = () => {
    setIsTripModalOpen(true);
  };

  const handleTripModalClose = () => {
    setIsTripModalOpen(false);
  };

  const fetchCurrentTripData = () => {
    dispatch(getTripById(currentTrip.Trip_ID, user.User_ID));
  };

  const handleOpenParticipantsHandlingModal = () => {
    if (currentTrip && currentTrip.tripName) {
      fetchCurrentTripData();
      setIsOpenParticipantsHandling(true);
    } else {
      dispatch(
        showSnackBar({
          type: "info",
          message: `${t("Please begin with")} "${t("Create New Trip")}"`,
        }),
      );
    }
  };

  const handleCloseParticipantsHandlingModal = () => {
    setIsOpenParticipantsHandling(false);
  };

  const handleEditTrip = () => {
    setDefaultValues(currentTrip);
    if (currentTrip && currentTrip.tripName) {
      handleTripModalOpen();
    } else {
      dispatch(
        showSnackBar({
          type: "info",
          message: `${t("Please begin with")} "${t("Create New Trip")}"`,
        }),
      );
    }
  };

  const renderTripName =
    currentTrip && currentTrip.Name ? currentTrip.Name : "Trip Name";

  const renderTripDate =
    currentTrip && currentTrip.startDate && currentTrip.endDate
      ? `${format(new Date(currentTrip.startDate), "dd.MM.yyyy")} - ${format(
          new Date(currentTrip.endDate),
          "dd.MM.yyyy",
        )}`
      : "dd.mm.yyyy - dd.mm.yyyy";

  const renderParticipantsStatusColor = status => {
    if (status === "1" || status === null || status === "0") {
      return classes.pending;
    } else if (status === "2") {
      return classes.accepted;
    } else if (status === "3") {
      return classes.rejected;
    }
  };

  const findUserInfo = participant => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      allUsers.forEach(user => {
        if (participant.TripParticipantUser_ID === user.User_ID) {
          data = renderUserInfo(user);
        } else {
          if (participant.InviteeEmail) {
            data = renderUserInfo(participant);
          }
        }
      });
      return data;
    }
  };

  const findCreatorInfo = participant => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      allUsers.forEach(user => {
        if (participant === user.User_ID) {
          data = renderUserInfo(user);
        }
      });
      return data;
    }
  };

  const renderUserInfo = participant => {
    if (participant.Picture) {
      return (
        <img
          src={participant.Picture}
          alt={participant.TripParticipantInitials}
          className={classes.avatarImage}
        />
      );
    } else {
      return `${participant.Initials || participant.TripParticipantInitials}`;
    }
  };

  const renderParticipants =
    currentTrip && currentTrip.Participants
      ? currentTrip.Participants.map(participant => {
          return (
            <Tooltip
              title={participant.TripParticipantDisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar
                className={renderParticipantsStatusColor(
                  participant.ParticipantStatus_ID,
                )}
              >
                {findUserInfo(participant)}
              </Avatar>
            </Tooltip>
          );
        })
      : null;

  const renderProxies =
    currentTrip && currentTrip.Proxies
      ? currentTrip.Proxies.map(proxy => {
          return (
            <Tooltip
              title={proxy.TripProxyDisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar
                className={renderParticipantsStatusColor(
                  proxy.TripProxyAcceptanceStatus,
                )}
              >
                {findProxyInfo(allUsers, proxy, classes)}
              </Avatar>
            </Tooltip>
          );
        })
      : null;

  const renderOrganizers =
    currentTrip && currentTrip.Organizers
      ? currentTrip.Organizers.map(organizer => {
          return (
            <Tooltip
              title={organizer.TripOrganizerDisplayName}
              placement="top"
              key={uniqid()}
              arrow
            >
              <Avatar
                className={renderParticipantsStatusColor(
                  organizer.TripOrganizerAcceptanceStatus,
                )}
              >
                {findOrganizerInfo(allUsers, organizer, classes)}
              </Avatar>
            </Tooltip>
          );
        })
      : null;

  const renderCreator =
    currentTrip && currentTrip.OwnerUser_ID ? (
      <Tooltip
        title={currentTrip.OwnerDisplayName}
        placement="top"
        key={currentTrip.OwnerUser_ID}
        arrow
      >
        <Avatar>{findCreatorInfo(currentTrip.ownerTrip)}</Avatar>
      </Tooltip>
    ) : null;

  return (
    <Grid container className={classes.tripBoard}>
      <Grid container justify="center" alignItems="center" item xs={12} md={12}>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="space-between"
          className={classes.tripNameContainer}
          onClick={handleEditTrip}
        >
          <Grid item xs={12} md={6} className={classes.tripName}>
            <Typography variant="subtitle1" component="h2" noWrap>
              {renderTripName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={classes.tripDate}
            container
            justify="flex-end"
            alignItems="center"
          >
            <div>
              {t("Date")}: <span>{renderTripDate}</span>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.secondRowContainer}
          alignItems="center"
          onClick={handleOpenParticipantsHandlingModal}
        >
          <Grid item xs={3} lg={2} xl={2}>
            <div className={classes.buddies}>
              {t("Owner")}:
              <div className={classes.avatar}>{renderCreator}</div>
            </div>
          </Grid>
          <Grid item xs={2} lg={3} xl={4} className={classes.proxy}>
            <div>{t("Trip buddies")}:</div>
            <div className={classes.buddies}>
              {t("Participants")}:
              <div className={classes.avatar}>
                <AvatarGroup max={10}>{renderParticipants}</AvatarGroup>
              </div>
            </div>
          </Grid>
          <Grid item xs={2} lg={3} xl={2} className={classes.buddies}>
            {t("Organizer")}:
            <div className={classes.avatar}>
              <AvatarGroup max={3}>{renderOrganizers}</AvatarGroup>
            </div>
          </Grid>
          <Grid item xs={5} lg={2} xl={4} className={classes.buddies}>
            {t("Proxies")}:
            <div className={classes.avatar}>
              <AvatarGroup max={2}>{renderProxies}</AvatarGroup>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {isTripModalOpen && (
        <TripModal
          open={isTripModalOpen}
          handleTripModalClose={handleTripModalClose}
          defaultValues={defaultValues}
        />
      )}
      <BuddiesModal
        open={isOpenParticipantsHandling}
        handleClose={handleCloseParticipantsHandlingModal}
      />
    </Grid>
  );
};

export default TripBoard;
