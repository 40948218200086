import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { duplicateTrippet } from '../../store/actions/actions/trippet';
import { getTripById } from '../../store/actions/actions/trip';
import { setLastTrip } from '../../store/actions/actions/user';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RecommendationModal from '../../components/RecommendationModal/RecommendationModal';

const useStyles = makeStyles(theme => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    top: '-27px !important',
    left: '-8px !important',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    '& > button': {
      width: 40,
      height: 40,
      backgroundColor: '#0E84B8',
    },
  },
}));

const TrippetButton = ({
  data,
  isOpenTrippetFrom,
  allUsers,
  handleConfirmDeleteModalOpen,
  setDeletedTrippet,
  filterResults,
  sortedElement,
  sortedBy,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const user = useSelector(state => state.user.user);
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleParticipantsModalOpen = event => {
    event.stopPropagation();
    setIsParticipantsModalOpen(true);
  };

  const handleParticipantsModalClose = event => {
    event.stopPropagation();
    setIsParticipantsModalOpen(false);
    handleClose();
  };

  const handleClick = (trippet, action) => event => {
    event.stopPropagation();
    const { Trip_ID, Trippet_ID, Title, CreatorUser_ID } = trippet.fullTrippet;
    if (action === 'duplicate') {
      const body = {
        SourceTrippet_ID: Trippet_ID,
        Title: `${Title} / duplicated`,
        User_ID: user.User_ID,
        CreatorUser_ID: user.User_ID,
        OwnerUser_ID: user.User_ID,
      };
      dispatch(
        duplicateTrippet(
          JSON.stringify(body),
          Trip_ID,
          user.User_ID,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
          t
        ),
      );
    } else if (action === 'delete') {
      handleConfirmDeleteModalOpen();
      setDeletedTrippet({
        body: Trippet_ID,
        trip_id: Trip_ID,
        CreatorUser_ID: CreatorUser_ID,
      });
    } else if (action === 'open') {
      dispatch(getTripById(data.fullTrippet.Trip_ID, user.User_ID));
      const lastTripBody = {
        type: 'settrip',
        LastTrip_ID: data.fullTrippet.Trip_ID,
        User_ID: user.User_ID,
      };
      dispatch(setLastTrip(JSON.stringify(lastTripBody)));
      history.push('/');
    } else if (action === 'recommend') {
      handleParticipantsModalOpen(event);
    }
  };

  const actions = [
    { icon: <OpenInNewIcon />, name: t('Open'), action: 'open' },
    { icon: <FileCopyIcon />, name: t('Duplicate'), action: 'duplicate' },
    { icon: <ShareIcon />, name: t('Recommend'), action: 'recommend' },
    { icon: <DeleteIcon />, name: t('Delete'), action: 'delete' },
  ];

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="right"
        onClick={event => event.stopPropagation()}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            disabled={isOpenTrippetFrom}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement="top"
            onClick={handleClick(data, action.action)}
          />
        ))}
      </SpeedDial>
      <RecommendationModal
        open={isParticipantsModalOpen}
        handleClose={handleParticipantsModalClose}
        allUsers={allUsers}
        trippet={data}
      />
    </div>
  );
};

export default TrippetButton;
