import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewDepartment, changeDepartment, getAllDepartments } from '../../store/actions/actions/department';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import * as userAccess from '../../userAccess';

const DepartmentModal = ({ open, handleDepartmentModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [formError, setFormError] = useState(false);
  const allDepartments = useSelector(state => state.department.allDepartments);
  const currentDepartment = useSelector(state => state.department.currentDepartment);
  const [values, setValues] = useState({
    ParentDepartment_ID: null,
    Name: '',
    Acronym: '',
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure Departments are already loaded
    if (!allDepartments)
    {
      dispatch(getAllDepartments(null));
      return;
    }
    if (defaultValues) {
        if (currentDepartment)
        {
            setValues({
                Department_ID: currentDepartment.Department_ID,
                ParentDepartment_ID: currentDepartment.ParentDepartment_ID,
                Name: currentDepartment.Name,
                Acronym: currentDepartment.Acronym,
            });
        }
        else
        {
            setValues({
                Department_ID: defaultValues.Department_ID,
                ParentDepartment_ID: defaultValues.ParentDepartment_ID,
                Name: defaultValues.Name,
                Acronym: defaultValues.Acronym,
            });
        }
        
        handleIsEditMode(true);
    } else {
      setValues({
        Department_ID: 0,
        ParentDepartment_ID: null,
        Name: '',
        Acronym: '',
      });
      handleIsEditMode(false);
    }
  }, [allDepartments, defaultValues, currentDepartment, dispatch]);

  const validateForm = ({ Name }) => {
    if (Name) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };


  const handleSubmit = e => {
    e.stopPropagation();
    e.preventDefault();
    const data = {
        Department_ID: +values.Department_ID,
        ParentDepartment_ID: values.ParentDepartment_ID,
        Name: values.Name,
        Acronym: values.Acronym,
    };
    if (isEditMode) {
      if (validateForm(data)) {
        dispatch(changeDepartment(JSON.stringify(data)));
        handleDepartmentModalClose();
      }
    } else {
      if (validateForm(data)) {
        dispatch(createNewDepartment(JSON.stringify(data)));
        handleDepartmentModalClose();
      }
    }
  };

  const handleInputs = e => {
    e.stopPropagation();
    setValues({...values, [e.target.name]: e.target.value});
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const renderTitle = defaultValues ? t('Edit Department') : t('New Department');
  
  const renderDepartments =
    allDepartments && allDepartments.length > 0
      ? allDepartments.map(cat => {
          if (Number(cat.Department_ID) === Number(values.Department_ID))
            return null;
          return (
            <MenuItem key={cat.Department_ID} value={cat.Department_ID}>{cat.Name}</MenuItem>
          );
        })
      : null;
    
  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;


  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_Departments))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_Departments));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={12} className={classes.departmentName}>
                <FormControl variant="outlined" className={classes.selectView}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Parent Department')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Parent Department')}
                    name="ParentDepartment_ID"
                    value={values.ParentDepartment_ID}
                    onChange={handleInputs}
                  >
                    {renderDepartments}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} className={classes.departmentName}>
                <FormControl variant="outlined" className={classes.selectView}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="Name"
                    label={t('Name')}
                    name="Name"
                    autoFocus
                    value={values.Name}
                    onChange={handleInputs}
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} className={classes.departmentName}>
                <FormControl variant="outlined" className={classes.selectView}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="Acronym"
                    label={t('Acronym')}
                    name="Acronym"
                    autoFocus
                    value={values.Acronym}
                    onChange={handleInputs}
                    />
                </FormControl>
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            autoFocus
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleDepartmentModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default DepartmentModal;
