import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allAdminRoleAccesses: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'RefTable',
  sortByDirection: 'asc',
  currentAdminRoleAccess: {
    AdminRoleAccess_ID: 0,
    AdminRole_ID: 0,
    RefTable: 0,
    Read: 0,
    Write: 0,
    Change: 0,
    Delete: 0,
  },
};

const adminRoleAccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_ADMINROLEACCESS: {
      return {
        ...state,
        currentAdminRoleAccess: {
            AdminRoleAccess_ID: action.payload.AdminRoleAccess_ID,
            AdminRole_ID: action.payload.AdminRole_ID,
            RefTable: action.payload.Name,
            Read: action.payload.Read,
            Write: action.payload.Write,
            Change: action.payload.Change,
            Delete: action.payload.Delete,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_ADMINROLEACCESSES: {
      return {
        ...state,
        allAdminRoleAccesses: action.payload,
      };
    }
    case adminActionTypes.SAVE_ADMINROLEACCESS_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_ADMINROLEACCESS_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_ADMINROLEACCESS_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_ADMINROLEACCESS_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allAdminRoleAccesses: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'RefTable',
        sortByDirection: 'asc',
        currentAdminRoleAccess: {
            AdminRoleAccess_ID: 0,
            AdminRole_ID: 0,
            RefTable: 0,
            Read: 0,
            Write: 0,
            Change: 0,
            Delete: 0,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default adminRoleAccessReducer;