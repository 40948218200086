import React, { useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewPlanningStatus, changePlanningStatus } from '../../store/actions/actions/planningStatus';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import {getEntireLanguages} from '../../store/actions/actions/language';
import {getEntireTrips} from '../../store/actions/actions/trip';
import PreviewIcon from '../PreviewIcon/PreviewIcon';
import IconPicker from '../IconPicker/IconPicker';
import { SketchPicker } from 'react-color'
import colorStyles from '../ColorPicker/styles';
import * as userAccess from '../../userAccess';

const PlanningStatusModal = ({ open, handlePlanningStatusModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [namesError, setNamesError] = useState(false);
  const [formError, setFormError] = useState(false);
  const entireLanguages = useSelector(state => state.language.entireLanguages);
  const entireTrips = useSelector(state => state.trip.entireTrips);
  const currentPlanningStatus = useSelector(state => state.planningStatus.currentPlanningStatus);
  const [values, setValues] = useState({
    Trip_ID: '',
    Type: 1,
    Name: '',
    Comments: '',
    IsFinal: false,
    SortIndex: 0,
    MaterialIO: '',
    Color: '',
    Icon: '',
    TripName: '',
    Names: [],
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure languages is already loaded
    if (!entireLanguages)
    {
      dispatch(getEntireLanguages(null));
      return;
    }
    // make sure trips are already loaded
    if (!entireTrips)
    {
      dispatch(getEntireTrips(null));
      return;
    }
    if (defaultValues) {
        // when there is a new language, the name for that language in 'Names' is missing
        // so we need to iterate through entireLanguages and push missed ones
        const names = currentPlanningStatus ? currentPlanningStatus.Names : defaultValues.Names;
        if (names && Array.isArray(names))
          entireLanguages.forEach(name => {
            var found = false;
            names.forEach(name2 => {
              if (Number(name2.Language_ID) === Number(name.Language_ID))
                found = true;
            });
            if (!found)
              names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
          });
        if (currentPlanningStatus)
        {
            setValues({
                PlanningStatus_ID: currentPlanningStatus.PlanningStatus_ID,
                Trip_ID: currentPlanningStatus.Trip_ID,
                Type: currentPlanningStatus.Type,
                Name: currentPlanningStatus.Name,
                Comments: currentPlanningStatus.Comments,
                IsFinal: currentPlanningStatus.IsFinal,
                SortIndex: currentPlanningStatus.SortIndex,
                MaterialIO: currentPlanningStatus.MaterialIO,
                Color: currentPlanningStatus.Color ?? '',
                Icon: currentPlanningStatus.Icon,
                TripName: currentPlanningStatus.TripName,
                Names: names,
            });
        }
        else
        {
            setValues({
                PlanningStatus_ID: defaultValues.PlanningStatus_ID,
                Trip_ID: defaultValues.Trip_ID,
                Type: defaultValues.Type,
                Name: defaultValues.Name,
                Comments: defaultValues.Comments,
                IsFinal: defaultValues.IsFinal,
                SortIndex: defaultValues.SortIndex,
                MaterialIO: defaultValues.MaterialIO,
                Color: defaultValues.Color ?? '',
                Icon: defaultValues.Icon,
                TripName: defaultValues.TripName,
                Names: names,
            });
        }
        
        handleIsEditMode(true);
    } else {
      
      const names = [];
      entireLanguages.forEach(name => {
        names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
      });
      setValues({
        PlanningStatus_ID: 0,
        Trip_ID: '',
        Type: 1,
        Name: '',
        Comments: '',
        IsFinal: '',
        SortIndex: '',
        MaterialIO: '',
        Color: '',
        Icon: '',
        TripName: '',
        Names: names,
      });
      handleIsEditMode(false);
    }
  }, [defaultValues, currentPlanningStatus, dispatch, entireLanguages, entireTrips]);

  const validateForm = ({ Names }) => {
    if (Names && Names.length > 0) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };


  const handleSubmit = e => {
    e.stopPropagation();
    if (!namesError) {
        e.preventDefault();
        const data = {
            PlanningStatus_ID: +values.PlanningStatus_ID,
            Trip_ID: +values.Trip_ID,
            Type: values.Type,
            Name: values.Name,
            Comments: values.Comments,
            IsFinal: values.IsFinal,
            SortIndex: values.SortIndex,
            MaterialIO: values.MaterialIO,
            Color: values.Color,
            Icon: values.Icon,
            TripName: values.TripName,
            Names: values.Names,
        };
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changePlanningStatus(JSON.stringify(data)));
          handlePlanningStatusModalClose();
        }
      } else {
        if (validateForm(data)) {
          dispatch(createNewPlanningStatus(JSON.stringify(data)));
          handlePlanningStatusModalClose();
        }
      }
    }
  };

  const setMaterialIO = icon => {
    setValues({...values, MaterialIO: icon});
  };
  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name.indexOf("Name") === 0) // it is Names list
    {
        const names = [];
        values.Names.forEach(name => {
            if ("Name" + name.Language_ID === e.target.name)
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: e.target.value});
            else
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: name.Name});
        });
        setValues({...values, Names: names});
        setNamesError(names.length === 0);
    }
    else
    {
        setValues({...values, [e.target.name]: e.target.value});
    }
  };

  const clearIcon = () => {
    setValues({...values, Icon: ''});
  };

  const setIcon = file => {
    setValues({...values, Icon: file});
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (color) => {
    setValues({...values, Color: color.hex.substring(1)});
  };
  
  const colorChooserStyle =  {
    width: '100%',
    height:'100%',
    borderRadius: '2px',
    background: `#${values.Color}`,
  };

  const renderTitle = defaultValues ? t('Edit Planning Status') : t('New Planning Status');
  const renderNames =
    values.Names && values.Names.length > 0
      ? values.Names.map(name => {
          return (
            <TableRow key={name.Language_ID} className={classes.rowContainer}>
                <TableCell>
                    <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id={"Name" + name.Language_ID}
                    label={name.LanguageAbbreviation}
                    name={"Name" + name.Language_ID}
                    value={name.Name}
                    onChange={handleInputs}
                    />
                </TableCell>
              </TableRow>
          );
        })
      : null;
     
  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;

    const renderTrips =
    entireTrips && entireTrips.length > 0
    ? entireTrips.map(trip => {
        return (
          <MenuItem value={trip.Trip_ID}>{trip.Name}</MenuItem>
        );
      })
    : null;

  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_PlanningStatuses))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_PlanningStatuses));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.planningStatusName}>
                <Grid container>
                  <Grid item xs={12} className={classes.languageName}>
                    <FormControl variant="outlined" className={classes.selectView}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        {t('Trip')}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t('Trip')}
                        name="Trip_ID"
                        value={values.Trip_ID}
                        onChange={handleInputs}
                        margin="none"
                        autoFocus
                      >
                        {renderTrips}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={classes.planningStatusName}>
                    <FormControl variant="outlined" className={classes.selectView}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        {t('Type')}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t('Type')}
                        name="Type"
                        value={values.Type}
                        onChange={handleInputs}
                      >
                        <MenuItem value="1">{t('Only Trips')}</MenuItem>
                        <MenuItem value="2">{t('Only Trippets')}</MenuItem>
                        <MenuItem value="3">{t('Both')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={classes.languageName}>
                    <Grid container>
                      <Grid item xs={10} className={classes.languageName}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="Color"
                          label={t('Color')}
                          name="Color"
                          autoComplete="Color"
                          autoFocus
                          value={values.Color}
                          onChange={handleInputs}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <div style={ colorStyles.swatch } onClick={ handleColorClick }>
                          <div style={ colorChooserStyle } />
                        </div>
                        { displayColorPicker ? <div style={ colorStyles.popover }>
                        <div style={ colorStyles.cover } onClick={ handleColorClose }/>
                          <SketchPicker color={ values.Color } onChange={ handleColorChange } />
                        </div> : null }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.languageName}>
                    <Grid container>
                      <Grid item xs={10} className={classes.languageName}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          id="MaterialIO"
                          label={t('MaterialIO')}
                          name="MaterialIO"
                          autoComplete="MaterialIO"
                          value={values.MaterialIO}
                          onChange={handleInputs}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconPicker selectedIcon={values.MaterialIO} iconSelected={setMaterialIO} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}  className={classes.languageName}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      type='number'
                      id="SortIndex"
                      label={t('SortIndex')}
                      name="SortIndex"
                      autoComplete="SortIndex"
                      value={values.SortIndex}
                      onChange={handleInputs}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.planningStatusName}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.IsFinal) === 1}
                            onChange={handleInputs}
                            name="IsFinal"
                            id="IsFinal"
                            color="primary"
                            value={Number(values.IsFinal) === 1 ? 0 : 1}
                        />
                        }
                        label={t('IsFinal')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <PreviewIcon currentImage={values.Icon} pictureUrl={values.Icon} clearIcon={clearIcon} setIcon={setIcon}
                type='language' dispatch={dispatch} t={t} />
              </Grid>
              <Grid item xs={12} md={12} className={classes.planningStatusName}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="Comments"
                  label={t('Meaning (please describe briefly)')}
                  name="Comments"
                  autoComplete="Comments"
                  value={values.Comments}
                  onChange={handleInputs}
                />
              </Grid>
              
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
              
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  className={classes.title}
                >
                  {t('Languages')}
                </Typography>
              </Grid>
            </Grid>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                   
                    </TableRow>
                </TableHead>
                <TableBody>{renderNames}</TableBody>
                </Table>
            </TableContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handlePlanningStatusModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default PlanningStatusModal;
