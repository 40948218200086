import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Badge from "@material-ui/core/Badge";
import { useTranslation } from "react-i18next";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { format } from "date-fns";
import uniqid from "uniqid";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotification,
  updateNotification,
} from "../../store/actions/actions/notifications";
import {
  getTripById,
  updateTripParticipantStatus,
  updateTripProxyStatus,
  updateTripOrganizerStatus,
} from "../../store/actions/actions/trip";
import {
  updateOrganizersStatus,
  updateParticipantStatus,
} from "../../store/actions/actions/trippet";
import {
  getAllPrivateDocumentsFromTheSingleTrippet,
  getAllPublicDocumentsFromTheSingleTrippet,
  getTrippetImages,
  saveCurrentTrippet,
  showTrippetForm,
} from "../../store/actions/actions/trippet";
import { setLastTrip } from "../../store/actions/actions/user";
import { useHistory } from "react-router-dom";
import { showSnackBar } from "../../store/actions/actions/root";
import { getUserNotification } from "../../store/actions/actions/notifications";
import {
  updateTrippetParticipants,
  updateTrippetOrganizers,
  updateNotificationTripOrganizer,
  updateNotificationTripProxies,
  updateNotificatoinTripParticipants,
} from "./helper";

const useStyles = makeStyles({
  root: {
    maxWidth: 950,
    margin: "20px auto",
  },
  media: {
    height: 120,
  },
  deleteBtn: {
    color: "red",
  },
  participants: {
    marginTop: 15,
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  creatorContainer: {
    display: "flex",
    alignItems: "center",
    "& > div": {
      marginLeft: 60,
    },
  },
  loggerName: {
    marginRight: 10,
    marginLeft: 15,
  },
  notificationTitle: {
    display: "flex",
    flex: "1",
    alignItems: "center",
  },
  title: {
    marginLeft: 15,
    maxWidth: 550,
  },
  avatar: {
    width: 35,
    height: 35,
    fontSize: 14,
  },
  avatarGroup: {
    "& > div": {
      width: 35,
      height: 35,
      fontSize: 14,
    },
  },
  avatarImage: {
    height: "100%",
    width: "100%",
  },
  pointer: {
    cursor: "pointer",
  },
  topInformation: {
    display: "flex",
    alignItems: "center",
  },
  creatorWrapper: {
    display: "flex",
    alignItems: "center",
  },
  creatorName: {
    marginRight: 10,
    fontSize: 16,
  },
  info: {
    marginRight: 5,
    fontWeight: "bold",
    fontSize: 14,
  },
});

const Notification = ({
  notification,
  allUsers,
  user,
  checkBoxes,
  handleNotificationCheckBox,
  handleTripModalOpen,
  setClickedTrip,
  allTrips,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  // const [actionType, setActionType] = useState('');
  const allMyTrippets = useSelector(state => state.trippet.allMyTrippets);
  const notificationSeen = useSelector(state => state.notification.seen);
  const notificationTripName = useSelector(
    state => state.notification.tripName,
  );
  const notificationTrippetName = useSelector(
    state => state.notification.trippetName,
  );
  const notificationCreator = useSelector(state => state.notification.creator);
  const notificationStartDate = useSelector(
    state => state.notification.startDate,
  );
  const notificationEndDate = useSelector(state => state.notification.endDate);
  // RefTable = 1 / trips
  // RefTable = 2 / trippets

  const {
    TripName,
    TrippetTitle,
    LogDate,
    LoggerUser_ID,
    Seen,
    RefTable,
    LogUser_ID,
    TripDescription,
    TrippetDescription,
    Trip_ID,
    TripParticipants,
    TrippetParticipants,
    RefTable_ID,
    LoggerUserDisplayName,
    TripOwnerUser_ID,
    TrippetOwnerUser_ID,
    TripOwnerDisplayName,
    TrippetOwnerDisplayName,
  } = notification;

  const findOwner = value =>
    allUsers && allUsers.find(user => user.User_ID === value);

  const renderNotificationTitle = () => {
    if (RefTable === "1") {
      return TripName;
    } else if (RefTable === "2") {
      return TrippetTitle;
    }
  };

  const renederDescription = () => {
    if (RefTable === "1") {
      return TripDescription;
    } else if (RefTable === "2") {
      return TrippetDescription;
    }
  };

  const converToLocalTime = date => {
    let newDate = date.split("");
    newDate.push("Z");
    let localTime = new Date(newDate.join(""));
    return format(new Date(localTime), "dd.MM.yyyy HH:mm");
  };

  const handleClickIcon = () => {
    let data = {
      body: {
        LogUser_ID: LogUser_ID,
        Seen: "1",
      },
      User_ID: user.User_ID,
    };
    dispatch(updateNotification(data));
  };

  const renderDateAndTime =
    LogDate && LogDate ? converToLocalTime(LogDate) : "";

  const renderNotificationIcon =
    RefTable && RefTable === "1" ? <BeachAccessIcon /> : <AccountTreeIcon />;

  const renderNotificationCreatorName = TrippetOwnerDisplayName
    ? TrippetOwnerDisplayName
    : TripOwnerDisplayName;

  const renderNotificationCreatorId = TripOwnerUser_ID
    ? TripOwnerUser_ID
    : TrippetOwnerUser_ID;

  const renderSeen =
    Seen && Seen === "0" ? (
      <div onClick={handleClickIcon} className={classes.pointer}>
        <Badge variant="dot" invisible={false} color="secondary">
          {renderNotificationIcon}
        </Badge>
      </div>
    ) : (
      renderNotificationIcon
    );

  const renderCreatorAvatar = id => {
    let user = findOwner(id);
    if (user) {
      if (user.Picture) {
        return (
          <Tooltip
            title={user.DisplayName}
            placement="top"
            key={uniqid()}
            arrow
          >
            <Avatar className={classes.avatar}>
              <img
                src={user.Picture}
                alt={user.Initials}
                className={classes.avatarImage}
              />
            </Avatar>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip
            title={user.DisplayName}
            placement="top"
            key={uniqid()}
            arrow
          >
            <Avatar className={classes.avatar}>{`${user.Initials}`}</Avatar>
          </Tooltip>
        );
      }
    }
  };

  const renderLoggerAvatar = () => {
    let user = findOwner(LoggerUser_ID);
    if (user) {
      if (user.Picture) {
        return (
          <Tooltip
            title={user.DisplayName}
            placement="top"
            key={uniqid()}
            arrow
          >
            <Avatar className={classes.avatar}>
              <img
                src={user.Picture}
                alt={user.Initials}
                className={classes.avatarImage}
              />
            </Avatar>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip
            title={user.DisplayName}
            placement="top"
            key={uniqid()}
            arrow
          >
            <Avatar className={classes.avatar}>{`${user.Initials}`}</Avatar>
          </Tooltip>
        );
      }
    }
  };

  const handleDeleteNotification = () => {
    let data = {
      body: {
        LogUser_ID: LogUser_ID,
      },
      User_ID: user.User_ID,
    };
    dispatch(deleteNotification(data));
  };

  const renderUserInfo = participant => {
    if (participant.Picture) {
      return (
        <img
          src={participant.Picture}
          alt={participant.TripParticipantInitials}
          className={classes.avatarImage}
        />
      );
    } else {
      return `${participant.Initials}`;
    }
  };

  const renderUserTrippetInfo = participant => {
    if (participant.Picture) {
      return (
        <img
          src={participant.Picture}
          alt={participant.TrippetParticipantInitials}
          className={classes.avatarImage}
        />
      );
    } else {
      return `${participant.Initials}`;
    }
  };

  const findUserInfo = participant => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      allUsers.forEach(user => {
        if (participant.TripParticipantUser_ID === user.User_ID) {
          data = renderUserInfo(user);
        }
      });
      return data;
    }
  };

  const findUserTrippetInfo = participant => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      allUsers.forEach(user => {
        if (participant.TrippetParticipantUser_ID === user.User_ID) {
          data = renderUserTrippetInfo(user);
        }
      });
      return data;
    }
  };

  const renderParticipants = value => {
    let allParticipants = value;
    return (
      allParticipants &&
      allParticipants.map(participant => (
        <Tooltip
          title={participant.TripParticipantDisplayName}
          placement="top"
          key={uniqid()}
          arrow
        >
          <Avatar className={classes.avatar}>
            {findUserInfo(participant)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const renderTrippetParticipants = value => {
    let allParticipants = value;
    return (
      allParticipants &&
      allParticipants.map(participant => (
        <Tooltip
          title={participant.TrippetParticipantDisplayName}
          placement="top"
          key={uniqid()}
          arrow
        >
          <Avatar className={classes.avatar}>
            {findUserTrippetInfo(participant)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const renderNotificationParticipants = () => {
    if (RefTable === "1") {
      if (TripParticipants && TripParticipants.length > 0) {
        return renderParticipants(TripParticipants);
      }
    } else if (RefTable === "2") {
      if (TrippetParticipants && TrippetParticipants.length > 0) {
        return renderTrippetParticipants(TrippetParticipants);
      }
    }
  };

  const findTrippet = id => {
    let trippet = null;
    if (allMyTrippets) {
      allMyTrippets.forEach(trp => {
        if (trp.Trippet_ID === id) {
          trippet = trp;
        }
      });
    }
    return trippet;
  };

  const findTrip = id => {
    let trip = null;
    if (allTrips) {
      allTrips.forEach(trp => {
        if (trp.Trip_ID === id) {
          trip = trp;
        }
      });
    }
    return trip;
  };

  const handleTrippet = trippet => {
    const privateDocument = {
      Trippet_ID: trippet.Trippet_ID,
      IsPrivate: "1",
      CreatorUser_ID: user.User_ID,
    };
    dispatch(
      getAllPrivateDocumentsFromTheSingleTrippet(
        JSON.stringify(privateDocument),
      ),
    );

    const publicDocument = {
      Trippet_ID: trippet.Trippet_ID,
      IsPrivate: "0",
    };
    dispatch(
      getAllPublicDocumentsFromTheSingleTrippet(JSON.stringify(publicDocument)),
    );

    // dispatch(getTrippetPicture(pictureId));
    const trippetImage = {
      Trippet_ID: trippet.Trippet_ID,
    };
    dispatch(getTrippetImages(JSON.stringify(trippetImage)));
    let body = {
      fullTrippet: {
        ...trippet,
      },
    };
    dispatch(saveCurrentTrippet(body));
    dispatch(showTrippetForm(body));
  };

  const handleTrip = () => {
    dispatch(getTripById(Trip_ID, user.User_ID));
    const lastTripBody = {
      LastTrip_ID: Trip_ID,
      User_ID: user.User_ID,
    };
    dispatch(setLastTrip(JSON.stringify(lastTripBody)));
    history.push("/");
  };

  const handleClickOpen = () => {
    if (RefTable === "1") {
      let trip = findTrip(Trip_ID);
      if (trip) {
        handleTrip();
        if (Seen === "0") {
          handleClickIcon();
        }
      } else {
        dispatch(
          showSnackBar({
            type: "info",
            message: t("Please check your trip invitation or recommendation"),
          }),
        );
      }
    } else if (RefTable === "2") {
      const trippet = findTrippet(RefTable_ID);
      if (trippet) {
        handleTrip();
        handleTrippet(trippet);
        if (Seen === "0") {
          handleClickIcon();
        }
      } else {
        dispatch(
          showSnackBar({
            type: "info",
            message: t(
              "Please check your trippet invitation or recommendation",
            ),
          }),
        );
      }
    }
  };

  const handleClickView = () => {
    if (RefTable === "1") {
      let trip = findTrip(Trip_ID);
      if (trip) {
        setClickedTrip(trip);
        handleTripModalOpen();
        if (Seen === "0") {
          handleClickIcon();
        }
      } else {
        dispatch(
          showSnackBar({
            type: "info",
            message: t("Please check your trip invitation or recommendation"),
          }),
        );
      }
    } else if (RefTable === "2") {
      const trippet = findTrippet(RefTable_ID);
      if (trippet) {
        handleTrippet(trippet);
        if (Seen === "0") {
          handleClickIcon();
        }
      } else {
        dispatch(
          showSnackBar({
            type: "info",
            message: t(
              "Please check your trippet invitation or recommendation",
            ),
          }),
        );
      }
    }
  };

  const getAllNotification = () => {
    let body = {
      User_ID: user.User_ID,
      OrderBy: "LogDate",
      OrderDir: "desc",
      Seen: notificationSeen,
      Trip_ID: notificationTripName,
      Trippet_ID: notificationTrippetName,
      LoggerUser_ID: notificationCreator,
      LogDateStart: notificationStartDate,
      LogDateEnd: notificationEndDate,
    };
    dispatch(getUserNotification(JSON.stringify(body)));
  };

  const handleTrippetInvitation = action => () => {
    updateTrippetParticipants(
      action,
      notification,
      user,
      dispatch,
      updateParticipantStatus,
    );
    updateTrippetOrganizers(
      action,
      notification,
      user,
      dispatch,
      updateOrganizersStatus,
    );
    handleClickIcon();
    getAllNotification();
    handleDeleteNotification();
    if (action === "accept") {
      dispatch(
        showSnackBar({
          type: "success",
          message: t("You successfully accepted trippet invitation."),
        }),
      );
    } else {
      dispatch(
        showSnackBar({
          type: "success",
          message: t("You successfully declined trippet invitation."),
        }),
      );
    }
  };

  const handleTripInvitation = action => () => {
    updateNotificatoinTripParticipants(
      action,
      notification,
      user,
      dispatch,
      updateTripParticipantStatus,
    );
    updateNotificationTripProxies(
      action,
      notification,
      user,
      dispatch,
      updateTripProxyStatus,
    );
    updateNotificationTripOrganizer(
      action,
      notification,
      user,
      dispatch,
      updateTripOrganizerStatus,
    );
    handleClickIcon();
    getAllNotification();
    handleDeleteNotification();
    if (action === "accept") {
      dispatch(
        showSnackBar({
          type: "success",
          message: t("You successfully accepted trip invitation."),
        }),
      );
    } else {
      dispatch(
        showSnackBar({
          type: "success",
          message: t("You successfully declined trip invitation."),
        }),
      );
    }
  };

  const renederButtons = () => {
    if (RefTable === "1") {
      let trip = findTrip(Trip_ID);
      if (trip) {
        return (
          <Fragment>
            <Button size="small" color="primary" onClick={handleClickOpen}>
              {t("Open")}
            </Button>
            <Button size="small" color="primary" onClick={handleClickView}>
              {t("View Trip")}
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={handleDeleteNotification}
            >
              {t("Delete")}
            </Button>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Button
              size="small"
              color="primary"
              onClick={handleTripInvitation("accept")}
            >
              {t("Accept")}
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={handleTripInvitation("decline")}
            >
              {t("Decline")}
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={handleDeleteNotification}
            >
              {t("Delete")}
            </Button>
          </Fragment>
        );
      }
    } else if (RefTable === "2") {
      const trippet = findTrippet(RefTable_ID);
      if (trippet) {
        return (
          <Fragment>
            <Button size="small" color="primary" onClick={handleClickOpen}>
              {t("Open")}
            </Button>
            <Button size="small" color="primary" onClick={handleClickView}>
              {t("View Trippet")}
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={handleDeleteNotification}
            >
              {t("Delete")}
            </Button>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Button
              size="small"
              color="primary"
              onClick={handleTrippetInvitation("accept")}
            >
              {t("Accept")}
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={handleTrippetInvitation("decline")}
            >
              {t("Decline")}
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={handleDeleteNotification}
            >
              {t("Delete")}
            </Button>
          </Fragment>
        );
      }
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <div>
          <div className={classes.topInformation}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.notificationTitle}
            >
              {renderSeen}
              <span className={classes.title}>{renderNotificationTitle()}</span>
            </Typography>
            <div className={classes.creatorWrapper}>
              <span className={classes.info}>
                {t("Buddy who made the change")}:
              </span>
              <span className={classes.creatorName}>
                {renderNotificationCreatorName}
              </span>
              {renderCreatorAvatar(renderNotificationCreatorId)}
            </div>
          </div>
          <div className={classes.topContainer}>
            <Typography variant="body2" component="p">
              {renderDateAndTime}
            </Typography>
            <div className={classes.creatorContainer}>
              {renderLoggerAvatar()}
              <span className={classes.loggerName}>
                {LoggerUserDisplayName}
              </span>
            </div>
          </div>
        </div>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          gutterBottom
        >
          {renederDescription()}
        </Typography>
        <div className={classes.participants}>
          <Typography variant="subtitle1" color="textSecondary" component="p">
            Participants
          </Typography>
          <AvatarGroup max={5}>{renderNotificationParticipants()}</AvatarGroup>
        </div>
      </CardContent>
      <CardActions>
        {renederButtons()}
        <FormControlLabel
          control={
            <Checkbox
              checked={checkBoxes[LogUser_ID]}
              onChange={handleNotificationCheckBox(LogUser_ID)}
              name="select"
              color="primary"
            />
          }
          label={t("Select")}
        />
      </CardActions>
    </Card>
  );
};

export default Notification;
