import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  modalTitle: {
    paddingBottom: 0,
    '& > h2': {
      fontWeight: 'bold',
    },
  },
  addParticipants: {
    width: 60,
    height: 40,
    marginLeft: 5,
  },
  avatar: {
    width: 41,
    height: 41,
    marginLeft: 5,
    fontSize: 14,
  },
  tripName: {
    marginBottom: `15px !important`,
  },
  tripNameView: {
    marginBottom: 5,
  },
  errorText: {
    color: theme.palette.secondary.main,
  },
  inviteUsersBtn: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 13,
  },
  participantsTitle: {
    fontWeight: 'bold',
    minWidth: 90,
  },
  sectionTitle: {
    display: 'block',
    fontSize: 12,
    color: 'grey',
  },
  spacing: {
    marginBottom: 10,
  },
  spacingRoles: {
    marginBottom: '10px !important',
  },
  descriptionView: {
    marginBottom: 10,
    minHeight: 100,
  },
  accepted: {
    border: `4px solid #579e38`,
  },
  pending: {
    border: `4px solid ${theme.palette.electricBlue[100]}`,
  },
  rejected: {
    border: `4px solid #cc3333`,
  },
  avatarImage: {
    width: '100%',
    height: '100%',
  },
  modalTopContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 12px 18px -19px rgba(0,0,0,0.75)',
    marginBottom: 15,
    "& > button":{
      marginRight: 5,
    }
  },
  pickers: {
    "& > div": {
      width: '100%'
    }
  },
  tripViewAvatar: {
    width: '41px !important',
    height: '41px !important',
    marginLeft: 5,
    fontSize: '13px !important',
  },
  role: {
    "& > label":{
      marginLeft: 0,
    }
  },
  textAreaField: {
    "& > div":{
      minHeight: 140,
    }
  }
}));

export default useStyles;
