import * as actionTypes from '../actionTypes/actionTypes';

export const changeParticipantsSortingCriteria = payload => ({
  type: actionTypes.CHANGE_PARTICIPANTS_SORTING_CRITERIA,
  payload,
});

export const changeCalendarView = payload => ({
  type: actionTypes.CHANGE_CALENDAR_VIEW,
  payload,
});

export const changeLanguage = payload => ({
  type: actionTypes.CHANGE_LANGUAGE,
  payload,
});

export const changeMapZoom = payload => ({
  type: actionTypes.CHANGE_MAP_ZOOM,
  payload,
});

export const changeCockpitView = payload => ({
  type: actionTypes.CHANGE_COCKPIT_VIEW,
  payload,
});

export const changeVisibleWeeksCount = payload => ({
  type: actionTypes.CHANGE_VISIBLE_WEEKS_COUNT,
  payload,
});

export const setStartWeekMonday = payload => ({
  type: actionTypes.SET_START_WEEK_MONDAY,
  payload,
});

export const setWorkWeek = payload => ({
  type: actionTypes.SET_WORK_WEEK,
  payload,
});

export const setTaskView = payload => ({
  type: actionTypes.SET_TASK_VIEW,
  payload,
});

export const setCalendarViewName = payload => ({
  type: actionTypes.SET_CALENDAR_VIEW_NAME,
  payload,
});
