import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import ChipInput from 'material-ui-chip-input';

const InvitationalModal = ({ open, handleClose, handleSend }) => {
  const [values, setValues] = useState([]);
  const { t } = useTranslation();

  const handleAddChip = chip => {
    setValues([...values, chip]);
  };

  const handleDeleteChip = (chip, index) => {
    let array = [...values];
    array.splice(index, 1);
    setValues(array);
  };

  const handleSaveAndSave = event => {
    handleSend(values);
    handleClose(event);
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClick={event => event.stopPropagation()}
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Who do you want to send to?')}
        </DialogTitle>
        <DialogContent>
          <ChipInput
            value={values}
            onAdd={chip => handleAddChip(chip)}
            onDelete={(chip, index) => handleDeleteChip(chip, index)}
            fullWidthInput={true}
            fullWidth={true}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('Exit')}
          </Button>
          <Button
            onClick={handleSaveAndSave}
            color="primary"
            autoFocus
            variant="contained"
          >
            {t('Send')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InvitationalModal;
