import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  calendarRoot: {
    width: '100%',
    height: '650px !important',
    overflow: 'hidden',
    marginBottom: 20,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  selectView: {
    marginBottom: 10,
    width: 150,
    [theme.breakpoints.up('xl')]: {
      width: 250,
    },
  },
  eventIcon: {
    fontSize: 13,
    position: 'relative',
    top: 2,
    marginRight: 5,
  },
  eventImage: {
    fontSize: 13,
    position: 'relative',
    top: 2,
    marginRight: 5,
    width: 15,
    "& > img":{
      width: 15,
    }
  },
  actionButtons: {
    height: 56,
    flexDirection: 'row',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 10,
    },
  },
  prevBtn: {
    margin: '0 10px',
    border: '1px solid #aeaeae',
    '& > span > svg': {
      position: 'relative',
      left: 5,
    },
  },
  nextBtn: {
    border: '1px solid #aeaeae',
  },
  date: {
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 20,
    marginLeft: 40,
    [theme.breakpoints.up('xl')]: {
      marginLeft: 80,
      fontSize: 22,
    },
  },
  week: {
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 20,
    marginLeft: 20,
  },
  weeksDateView: {
    fontSize: 16,
    marginLeft: 15,
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('xl')]: {
      marginLeft: 30,
      fontSize: 16,
    },
  },
  moreTitle: {
    color: '#008De5',
    float: 'right',
    display: 'inline-block',
    height: 27,
    padding: 5,
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 700,
  },
  leftPadding: {
    paddingLeft: 0,
  },
}));

export default useStyles;
