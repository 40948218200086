import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allAdminRoles: null,
  entireAdminRoles: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'Name',
  sortByDirection: 'asc',
  currentAdminRole: {
    AdminRole_ID: 0,
    IsDefault: 0,
    Name: '',
  },
};

const adminRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_ADMINROLE: {
      return {
        ...state,
        currentAdminRole: {
            AdminRole_ID: action.payload.AdminRole_ID,
            IsDefault: action.payload.IsDefault,
            Name: action.payload.Name,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_ADMINROLES: {
      return {
        ...state,
        allAdminRoles: action.payload,
      };
    }
    case adminActionTypes.SAVE_ENTIRE_ADMINROLES: {
      return {
        ...state,
        entireAdminRoles: action.payload,
      };
    }
    case adminActionTypes.SAVE_ADMINROLE_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_ADMINROLE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_ADMINROLE_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_ADMINROLE_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allAdminRoles: null,
        entireAdminRoles: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentAdminRole: {
            AdminRole_ID: 0,
            IsDefault: 0,
            Name: '',
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default adminRoleReducer;