/**
 * Converts degrees to radians.
 *
 * @param degrees Number of degrees.
 */
const degreesToRadians = degrees => {
  return (degrees * Math.PI) / 180;
};

/**
 * Returns the distance between 2 points of coordinates in Google Maps
 * Haversine formula
 * @param placeOne Point A information from google place API
 * @param placeOneLat Latitude of the point A
 * @param placeOneLng Longitude of the point A
 * @param placeTwo Point B information from google place API
 * @param placeTwoLat Latitude of the point A
 * @param placeTwoLng Longitude of the point B
 */

export const getFlightDistance = (
  placeOne,
  placeOneLat,
  placeOneLng,
  placeTwo,
  placeTwoLat,
  placeTwoLng,
) => {
  // The radius of the planet earth in meters
  if (placeOne && placeTwo) {
    let R = 6371;
    let dLat = degreesToRadians(placeTwoLat - placeOneLat);
    let dLong = degreesToRadians(placeTwoLng - placeOneLng);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreesToRadians(placeOneLat)) *
        Math.cos(degreesToRadians(placeOneLat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);

    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance = R * c;
    return `${distance.toFixed(2)} km`;
  }
};
