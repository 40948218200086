import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PreviewPicture from './PreviewPicture';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { validateImageFormat } from '../../components/TrippetForm/validateFileFormat';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useDispatch } from 'react-redux';
import {
  deleteUploadedProfilePicture,
  uploadFile,
} from '../../store/actions/actions/profile';

const useStyles = makeStyles(theme => ({
  addButton: {
    height: 40,
    width: 250,
    backgroundColor: '#008De5',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  uploadPictureContainer: {
    marginTop: 75,
    minHeight: 300,
    textAlign: 'center',
  },
  pictureActionContainer: {
    marginTop: 10,
  },
  inputAdornment: {
    '& > div > input': {
      paddingLeft: 5,
    },
  },
  errorText: {
    color: theme.palette.secondary.main,
  },
  pictureTitleContainer: {
    textAlign: 'left',
    paddingLeft: 10,
    paddingRight: 20,
  },
}));

const UploadPicture = ({ t, values, setValues }) => {
  const classes = useStyles();
  const [wrongPictureFormat, setWrongPictureFormat] = useState(true);
  const dispatch = useDispatch();

  const displayImage = event => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      setWrongPictureFormat(validateImageFormat(file));
      if (validateImageFormat(file)) {
        setValues({
          ...values,
          pictureUrl: reader.result,
        });
        let formData = new FormData();
        formData.append('file', file);
        formData.append('ext', 'image');
        formData.append('type', 'user');
        dispatch(uploadFile(formData, 'image', file.name, t));
      }
    };
    reader.readAsDataURL(file);
  };

  const renderErrorMessage = wrongPictureFormat ? null : (
    <FormHelperText className={classes.errorText}>
      {t('This image format is not supported.')}
    </FormHelperText>
  );

  return (
    <div className={classes.uploadPictureContainer}>
      <PreviewPicture
        pictureUrl={values.pictureUrl}
        values={values}
        setValues={setValues}
        dispatch={dispatch}
        deleteProfileImage={deleteUploadedProfilePicture}
      />
      {renderErrorMessage}
      <Grid container className={classes.pictureActionContainer}>
        <Grid item xs={12} container justify="center">
          <Button
            variant="contained"
            component="label"
            className={classes.addButton}
            startIcon={<SaveAltIcon />}
          >
            {t('Upload Picture')}
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={displayImage}
            />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadPicture;
