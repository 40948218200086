import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import KeepTrippetsModal from '../KeepTrippetsModal/KeepTrippetsModal';

const ForceDeleteModal = ({
  open,
  handleClose,
  trip,
  allMyTrippets,
  handleForceDelete,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [isKeepTrippetsModalOpen, setIsKeepTrippetsModalOpen] = useState(false);
  const [pickedTrippets, setPickedTrippets] = useState(null);

  const handleCloseModal = event => {
    event.stopPropagation();
    handleClose();
  };

  const handleSaveTrippets = values => {
    if (values) {
      setPickedTrippets(values);
    } else {
      setPickedTrippets(null);
    }
  };

  const handleKeepTrippetseModalClose = () => {
    setIsKeepTrippetsModalOpen(false);
  };

  const handleKeepTrippetseModalOpen = () => {
    setIsKeepTrippetsModalOpen(true);
  };

  const handleKeepTrippets = event => {
    event.stopPropagation();
    handleKeepTrippetseModalOpen();
  };

  const handleForceDeleteButton = () => {
    handleForceDelete(pickedTrippets);
    handleClose();
  };

  const renderPickedTrippets =
    pickedTrippets && pickedTrippets.length > 0
      ? pickedTrippets.map(trippet => (
          <li key={trippet.Trippet_ID}>{trippet.Title}</li>
        ))
      : null;

  const renderTitle = () => {
    if (pickedTrippets && pickedTrippets.length > 0) {
      return t('You selected below trippets');
    } else {
      return t(`You didn't select any trippet`);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {t('Are you sure you want to delete?')}
          </DialogContentText>
          <div>
            <h3 style={{ fontWeight: 500 }}>{renderTitle()}:</h3>
            <ul>{renderPickedTrippets}</ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseModal} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            autoFocus
            onClick={handleKeepTrippets}
            color="primary"
            variant="contained"
          >
            {t('Keep Trippets')}
          </Button>
          <Button
            onClick={handleForceDeleteButton}
            color="primary"
            variant="contained"
          >
            {t('Force Delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <KeepTrippetsModal
        open={isKeepTrippetsModalOpen}
        handleClose={handleKeepTrippetseModalClose}
        trip={trip}
        handleSaveTrippets={handleSaveTrippets}
        allMyTrippets={allMyTrippets}
      />
    </div>
  );
};

export default ForceDeleteModal;
