import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 250;

const useAdminStyles = makeStyles(theme => ({
  languagesTitle: {
    width: 85,
  },
  drawerOpen: {
    width: drawerWidth,
    overflowY: 'scroll',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export default useAdminStyles;
