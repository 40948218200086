import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import uniqid from "uniqid";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { showSnackBar } from "../../store/actions/actions/root";
import {
  updateParticipantStatus,
  updateOrganizersStatus,
} from "../../store/actions/actions/trippet";
import useStyles from "./trippetViewModeStyles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";

const TrippetViewMode = ({
  open,
  handleClose,
  defaultValues,
  type,
  filterResults,
  sortedElementMyTrippets,
  sortedByMyTrippets,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector(state => state.user.user);
  const allUsersRoles = useSelector(state => state.app.allUsersRoles);
  const [values, setValues] = useState({
    tripName: "",
    trippetName: "",
    fromDate: "",
    participants: "",
    organizers: "",
    proxies: "",
    message: "",
    trip_id: "",
    trippet_id: "",
    startDate: "",
    endDate: "",
    price: "",
    ownerName: "",
    isWholeDay: "",
    distance: "",
    isPrivate: "",
    link: "",
    currencyName: "",
    modifiedDate: "",
    fromPlace: "",
    toPlace: "",
    type: "",
    userRole: "",
    userRole_ID: "",
    userRoleName: "",
    userTrippet_ID: "",
  });

  useEffect(() => {
    if (defaultValues && allUsersRoles && allUsersRoles.length > 0 && user) {
      let userRoleId = defaultValues.fullTrippet.UserRole_ID;
      let typeId = "";
      allUsersRoles.forEach(role => {
        if (role.UserRole_ID === userRoleId) {
          typeId = role.Type;
        }
      });
      let userTrippet_ID = "";
      // if (typeId === "1") {
      //   userTrip_ID = defaultValues.fullTrippet.TrippetProxy_ID;
      // } else
      if (typeId === "2") {
        userTrippet_ID = defaultValues.fullTrippet.TrippetParticipant_ID;
      } else if (typeId === "3" || typeId === "1") {
        userTrippet_ID = defaultValues.fullTrippet.TrippetOrganizer_ID;
      }
      setValues({
        tripName: defaultValues.fullTrippet.TripName,
        trippetName: defaultValues.fullTrippet.Title,
        startDate: defaultValues.fullTrippet.StartDate,
        endDate: defaultValues.fullTrippet.EndDate,
        participants: defaultValues.fullTrippet.Participants,
        organizers: defaultValues.fullTrippet.Organizers,
        proxies: defaultValues.fullTrippet.Proxies,
        trippetTypeId: defaultValues.fullTrippet.TrippetTypeLook_ID,
        trip_id: defaultValues.fullTrippet.Trip_ID,
        trippet_id: defaultValues.fullTrippet.Trippet_ID,
        price: defaultValues.fullTrippet.Price,
        ownerName: defaultValues.fullTrippet.OwnerDisplayName,
        isWholeDay: defaultValues.fullTrippet.IsWholeDay,
        distance: defaultValues.fullTrippet.Distance,
        isPrivate: defaultValues.fullTrippet.IsPrivate,
        link: defaultValues.fullTrippet.Link,
        currencyName: defaultValues.fullTrippet.CurrencyName,
        modifiedDate: defaultValues.fullTrippet.ModifiedDate,
        fromPlace: defaultValues.fullTrippet.FromPlace,
        toPlace: defaultValues.fullTrippet.ToPlace,
        type: typeId,
        userRole_ID: defaultValues.fullTrippet.UserRole_ID,
        userRoleName: defaultValues.fullTrippet.UserRoleName,
        userTrippet_ID: userTrippet_ID,
      });
    }
  }, [
    defaultValues,
    user.FirstName,
    user.Initials,
    user.LastName,
    user.User_ID,
    allUsersRoles,
    user,
  ]);

  const renderParticipantsStatusColor = status => {
    if (status === "1" || status === null || status === "0") {
      return classes.pending;
    } else if (status === "2") {
      return classes.accepted;
    } else if (status === "3") {
      return classes.rejected;
    }
  };

  const renderParticipants =
    values.participants && values.participants.length > 0 ? (
      values.participants.map(participant => (
        <Tooltip
          title={participant.TrippetParticipantDisplayName}
          placement="top"
          key={
            participant.TrippetParticipantUser_ID ||
            participant.User_ID ||
            participant.key
          }
          arrow
        >
          <Avatar
            className={classNames(
              classes.trippetViewAvatar,
              renderParticipantsStatusColor(participant.ParticipantStatus_ID),
            )}
          >
            {`${participant.Initials ||
              participant.TrippetParticipantInitials}`}
          </Avatar>
        </Tooltip>
      ))
    ) : (
      <CancelIcon />
    );

  const renderOrganizers =
    values.organizers && values.organizers.length > 0 ? (
      values.organizers.map(organizer => (
        <Tooltip
          title={organizer.TrippetOrganizerDisplayName}
          placement="top"
          key={uniqid()}
          arrow
        >
          <Avatar
            className={classNames(
              classes.trippetViewAvatar,
              renderParticipantsStatusColor(
                organizer.TrippetOrganizerAcceptanceStatus,
              ),
            )}
          >
            {`${organizer.Initials || organizer.TrippetOrganizerInitials}`}
          </Avatar>
        </Tooltip>
      ))
    ) : (
      <CancelIcon />
    );

  const renderProxies =
    values.proxies && values.proxies.length > 0 ? (
      values.proxies.map(proxy => (
        <Avatar
          key={uniqid()}
          className={classNames(
            classes.trippetViewAvatar,
            renderParticipantsStatusColor(proxy.TrippetProxyAcceptanceStatus),
          )}
        >
          {`${proxy.Initials || proxy.TrippetProxyInitials}`}
        </Avatar>
      ))
    ) : (
      <CancelIcon />
    );

  const handleAccept = () => {
    if (values.type === "2") {
      let body = {
        TrippetParticipant_ID: values.userTrippet_ID,
        TrippetParticipantUserRole_ID: values.userRole_ID,
        ParticipantStatus_ID: 2,
      };
      dispatch(
        updateParticipantStatus(
          JSON.stringify(body),
          user.User_ID,
          sortedElementMyTrippets,
          sortedByMyTrippets[sortedElementMyTrippets],
          filterResults,
          defaultValues.fullTrippet.CreatorUser_ID,
        ),
      );
    } else if (values.type === "1" || values.type === "3") {
      let body = {
        TrippetOrganizer_ID: values.userTrippet_ID,
        TrippetOrganizerUserRole_ID: values.userRole_ID,
        TrippetOrganizerAcceptanceStatus: 2,
      };
      dispatch(
        updateOrganizersStatus(
          JSON.stringify(body),
          user.User_ID,
          sortedElementMyTrippets,
          sortedByMyTrippets[sortedElementMyTrippets],
          filterResults,
          defaultValues.fullTrippet.CreatorUser_ID,
        ),
      );
    }
    dispatch(
      showSnackBar({
        type: "success",
        message: t("You successfully saved the trip invitation"),
      }),
    );
    handleClose();
  };

  const handleDecline = () => {
    if (values.type === "2") {
      let body = {
        TrippetParticipant_ID: values.userTrippet_ID,
        TrippetParticipantUserRole_ID: values.userRole_ID,
        ParticipantStatus_ID: 3,
      };
      dispatch(
        updateParticipantStatus(
          JSON.stringify(body),
          user.User_ID,
          sortedElementMyTrippets,
          sortedByMyTrippets[sortedElementMyTrippets],
          filterResults,
          defaultValues.fullTrippet.CreatorUser_ID,
        ),
      );
    } else if (values.type === "1" || values.type === "3") {
      let body = {
        TrippetOrganizer_ID: values.userTrippet_ID,
        TrippetOrganizerUserRole_ID: values.userRole_ID,
        TrippetOrganizerAcceptanceStatus: 3,
      };
      dispatch(
        updateOrganizersStatus(
          JSON.stringify(body),
          user.User_ID,
          sortedElementMyTrippets,
          sortedByMyTrippets[sortedElementMyTrippets],
          filterResults,
          defaultValues.fullTrippet.CreatorUser_ID,
        ),
      );
    }
    dispatch(
      showSnackBar({
        type: "success",
        message: t("You successfully saved the trip invitation"),
      }),
    );
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
      styles={{ zIndex: 9999 }}
    >
      {" "}
      <DialogTitle id="responsive-dialog-title" className={classes.modalTitle}>
        {t("Trippet Overview")}
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <Grid container>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("Trippet Name")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.trippetName}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("Trip Name")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.tripName}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("Owner Name")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.ownerName}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("Start Date")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values &&
                values.startDate &&
                `${format(
                  new Date(values.startDate),
                  "EEEEEE.dd MMM",
                )} | ${format(new Date(values.startDate), "HH:mm")}`}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("End Date")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values &&
                values.endDate &&
                `${format(
                  new Date(values.endDate),
                  "EEEEEE.dd MMM",
                )} | ${format(new Date(values.endDate), "HH:mm")}`}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid
              item
              xs={6}
              className={classes.sectionRolesTitle}
              container
              alignItems="center"
            >
              {t("Participants")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.participants && renderParticipants}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionRolesTitle}
              container
              alignItems="center"
            >
              {t("Organizers")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.organizers && renderOrganizers}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionRolesTitle}
              container
              alignItems="center"
            >
              {t("Proxies")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.proxies && renderProxies}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid
              item
              xs={6}
              className={classes.sectionTitle}
              style={{ marginBottom: 7 }}
            >
              {t("Is All Day")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.isWholeDay === "0" ? (
                <CancelIcon />
              ) : (
                <CheckCircleIcon />
              )}
            </Grid>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("Is Private")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.isPrivate === "0" ? (
                <CancelIcon />
              ) : (
                <CheckCircleIcon />
              )}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("Price")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && `${values.price} ${values.currencyName}`}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("From - To")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && `${values.fromPlace} - ${values.toPlace}`}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("Owner")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.ownerName}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("Link")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.link ? values.link : "-"}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("Distance")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values && values.distance ? values.distance : "-"}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={6} className={classes.sectionTitle}>
              {t("Last Changed")}
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.sectionValue}
              container
              justify="flex-start"
            >
              {values &&
                values.modifiedDate &&
                `${format(
                  new Date(values.modifiedDate),
                  "EEEEEE.dd MMM",
                )} | ${format(new Date(values.modifiedDate), "HH:mm")}`}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 7 }}>
            <Grid item xs={12} className={classes.sectionTitle}>
              {t("Select your role")}
            </Grid>
            <Grid item xs={12}>
              <b>{values && values.userRoleName}</b>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Exit")}
        </Button>
        {type === "received" && (
          <Button autoFocus onClick={handleDecline} color="secondary">
            {t("Decline")}
          </Button>
        )}
        {type === "received" && (
          <Button
            autoFocus
            onClick={handleAccept}
            color="primary"
            variant="contained"
          >
            {t("Accept")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TrippetViewMode;
