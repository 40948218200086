import * as actionTypes from '../actions/actionTypes/actionTypes';

const initialState = {
  profilePictureURL: '',
  type: '',
  FirstName: '',
  LastName: '',
  Email: '',
  Gender: '',
  HomeAddress: '',
  PhoneNumber: '',
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_UPLOADED_PROFILE_PICTURE: {
      return {
        ...state,
        profilePictureURL: action.payload.file,
        type: action.payload.type,
      };
    }
    case actionTypes.DELETE_UPLOADED_PROFILE_PICTURE: {
      return {
        ...state,
        profilePictureURL: '',
        type: '',
      };
    }
    case actionTypes.SAVE_USER_INFORMATION: {
      return {
        ...state,
        FirstName: action.payload.FirstName,
        LastName: action.payload.LastName,
        Email: action.payload.Email,
        Gender: action.payload.Gender,
        HomeAddress: action.payload.HomeAddress,
        PhoneNumber: action.payload.PhoneNumber,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        profilePictureURL: '',
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default profileReducer;
