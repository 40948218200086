import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const MessageUserModal = ({ open, handleClose, user }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [counter, setCounter] = useState(0);

  const handleMessage = event => {
    setMessage(event.target.value);
    setCounter(event.target.value.length);
  }

  const handleSend = () => {
    user.item.InviteMessage = message;
    handleClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.container}
      >
        <div className={classes.modalTopContainer}>
          <DialogTitle id="alert-dialog-title">{t("Message User")}</DialogTitle>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.modalContentContainer}>
          <div className={classes.title}>{t("Send a personal message to the user")}</div>
          <div className={classes.user}>{user && user.name}?</div>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="message"
            label={t('Type your personal message.')}
            name="message"
            autoComplete="trip-message"
            autoFocus
            value={message}
            onChange={handleMessage}
            className={classes.inputs}
            multiline
            rowsMax="4"
          />
          <FormHelperText>
            {`${counter} / 1500`}
          </FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleSend} color="primary" variant="contained">
            {t("Send")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MessageUserModal;
