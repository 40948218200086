import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { columns } from "./columns";
import useStyles from "./styles";
import SettingCategoryButton from "./SettingCategoryButton";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { BASE_URL } from "../../config";
import * as userAccess from "../../userAccess";
import SettingCategoryModal from "../../components/Setting/SettingCategoryForm";
import Row from './Row';
import { saveRows } from '../../store/actions/actions/settingCategory';


const createData = (SettingCategory_ID, Comments, Name, Names, Items) => {
  return {
    SettingCategory_ID,
    Comments,
    Name,
    Names,
    Items,
  };
};

const SettingCategoryTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedSettingCategory,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const allSettingCategories = useSelector(state => state.settingCategory.allSettingCategories);
  const [clickedSettingCategory, setClickedSettingCategory] = useState([]);
  const [isSettingCategoryModalOpen, setIsSettingCategoryModalOpen] = useState(
    false,
  );
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(
    useSelector(state => state.user.user),
    userAccess.Table_Settings,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.SettingCategory_ID,
            item.Comments,
            item.Name,
            item.Names,
            item.Items,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedSettingCategory = value => {
    setClickedSettingCategory(value);
  };

  const handleSettingCategoryModalOpen = () => {
    setIsSettingCategoryModalOpen(true);
  };

  const handleSettingCategoryModalClose = () => {
    setIsSettingCategoryModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);


  useEffect(() => {
    if(allSettingCategories && allSettingCategories.length > 0){
      let data = {};
      allSettingCategories.forEach(category => {
        data[category.SettingCategory_ID] = false;
      });
      dispatch(saveRows(data));
    }
  },[allSettingCategories, dispatch])


  const renderData = (column, value, row) => {
    if (column.id === "actions") {
      return (
        <SettingCategoryButton
          data={row}
          handleClickedSettingCategory={handleClickedSettingCategory}
          handleSettingCategoryModalOpen={handleSettingCategoryModalOpen}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedSettingCategory={setDeletedSettingCategory}
          disabledDelete={disableDelete}
        />
      );
    } else if (column.id === "Icon" && value) {
      return (
        <img
          src={`${BASE_URL}${value.substr(1)}`}
          className={classes.avatar}
          alt="Icon"
        />
      );
    } else if (column.id === "Color" && value) {
      return (
        <span>
          {value}{" "}
          <span
            style={{
              backgroundColor: "#" + value,
              width: "30px",
              display: "inline-block",
            }}
          >
            &nbsp;
          </span>
        </span>
      );
    } else {
      return value;
    }
  };


  const renderRows =
  rows && rows.length
    ? rows.map(row => {
        return (<Row id={row.SettingCategory_ID} key={row.SettingCategory_ID} row={row} renderData={renderData}/>)
    })
    : null;


  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== "actions") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id ? sortedBy : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <SettingCategoryModal
        open={isSettingCategoryModalOpen}
        handleSettingCategoryModalClose={handleSettingCategoryModalClose}
        defaultValues={clickedSettingCategory}
      />
    </Paper>
  );
};

export default SettingCategoryTable;
