import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allPlanningStatuses: null,
  filterTrip_ID: -1, // -1 means all
  filterTrips: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'SortIndex',
  sortByDirection: 'asc',
  currentPlanningStatus: {
    PlanningStatus_ID: 0,
    Trip_ID: null,
    Type: 3,
    Name: '',
    Comments: '',
    IsFinal: false,
    SortIndex: 0,
    MaterialIO: '',
    Color: '',
    Icon: '',
    TripName: '',
    Names: []
  },
};

const planningStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_PLANNINGSTATUS: {
      return {
        ...state,
        currentPlanningStatus: {
            PlanningStatus_ID: action.payload.PlanningStatus_ID,
            Trip_ID: action.payload.Trip_ID,
            Type: action.payload.Type,
            Name: action.payload.Name,
            Comments: action.payload.Comments,
            IsFinal: action.payload.IsFinal,
            SortIndex: action.payload.SortIndex,
            MaterialIO: action.payload.MaterialIO,
            Color: action.payload.Color,
            Icon: action.payload.Icon,
            TripName: action.payload.TripName,
            Names: action.payload.Names,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_PLANNINGSTATUSES: {
      return {
        ...state,
        allPlanningStatuses: action.payload,
      };
    }
    case adminActionTypes.SAVE_PLANNINGSTATUS_FILTERTRIPID: {
      return {
        ...state,
        filterTrip_ID: action.payload,
      };
    }
    case adminActionTypes.SAVE_PLANNINGSTATUS_FILTERTRIPS: {
      return {
        ...state,
        filterTrips: action.payload,
      };
    }
    case adminActionTypes.SAVE_PLANNINGSTATUS_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_PLANNINGSTATUS_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_PLANNINGSTATUS_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_PLANNINGSTATUS_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allPlanningStatuses: null,
        filterTrip_ID: -1,
        filterTrips: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'SortIndex',
        sortByDirection: 'asc',
        currentPlanningStatus: {
            PlanningStatus_ID: 0,
            Trip_ID: null,
            Type: '',
            Name: '',
            Comments: '',
            IsFinal: false,
            SortIndex: 0,
            MaterialIO: '',
            Color: '',
            Icon: '',
            TripName: '',
            Names: []
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default planningStatusReducer;