import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '15px 0',
    textAlign: 'center',
  },
  containerMyTrip: {
    paddingBottom: 50,
  },
  rootPaper: {
    width: '100%',
  },
  container: {
    maxHeight: 740,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  space: {
    margin: '15px 0',
  },
  accepted: {
    border: `4px solid #579e38 !important`,
  },
  pending: {
    border: `4px solid ${theme.palette.electricBlue[100]} !important`,
  },
  rejected: {
    border: `4px solid #cc3333 !important`,
  },
  tableHeaderCell: {
    fontWeight: 'bold !important',
    zIndex: 10,
  },
  rowContainer: {
    cursor: 'pointer',
  },
  tableCell: {
    border: '1px solid #aeaeae',
    borderLeft: `5px solid transparent`,
  },
  avatar: {
    width: 41,
    height: 41,
    fontSize: 14,
  },
  avatarGroup: {
    minHeight: 35,

    '& > div': {
      width: 41,
      height: 41,
      fontSize: 14,
    },
  },
  blue: {
    borderLeft: `4px solid ${theme.palette.freshAir[100]}`,
  },
  orange: {
    borderLeft: `4px solid ${theme.palette.bananaMania[100]}`,
  },
  green: {
    borderLeft: `4px solid ${theme.palette.greenPowder[100]}`,
  },
  trippetFormContainer: {
    position: 'relative',
  },
  dotsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  dots: {
    display: 'flex',
    margin: '0 0 15px 0',
  },
  dot: {
    width: 12,
    height: 12,
    border: `1px solid #0E84B8`,
    borderRadius: '50%',
    margin: '0 5px',
    cursor: 'pointer',
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: '#0E84B8',
    },
  },
  checked: {
    backgroundColor: '#0E84B8',
  },
  accept: {
    color: '#42ce23',
  },
  decline: {
    color: '#ef2828',
  },
  backToCockpit: {
    '& > a': {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      color: '#000000',
      textDecoration: 'none',
      fontWeight: 'normal',
      '& > svg': {
        fontSize: 32,
      },
      '& > h2': {
        paddingLeft: 10,
      },
    },
  },
  activeFilter: {
    color: '#009bfc',
  },
  activeButton: {
    color: theme.palette.electricBlue[100],
  },
  avatarImage: {
    height: '100%',
    width: '100%',
  },
  infoIcon: {
    fontSize: 14,
    cursor: 'help'
  },
  tableHeaderCellActive: {
    fontWeight: 'bold !important',
    "& > span > svg":{
      color: '#009bfc !important',
    }
  },
  tableHeader: {
    position: 'relative',
  },
  filterButton: {
    position: 'absolute',
    right: 0,
    top: 4,
  },
  trippetTypeName: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    "& > span": {
      paddingRight: 4,
    },
    "& > img": {
      width: 25,
      paddingRight: 4,
    }
  }
}));

export default useStyles;
