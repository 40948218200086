import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import {
  savePaginationPage,
  deleteSingleTrippet,
  deleteMultipleTrippet,
} from "../../store/actions/actions/trippet";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  formControlSelect: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 400,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  selectHolder: {
    marginTop: -40,
    padding: "0 20px",
    marginBottom: 20,
  },
  container: {
    "& > div > div": {
      width: 500,
      textAlign: "center",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(child, keptChildren, theme) {
  return {
    fontWeight:
      keptChildren.indexOf(child.Trippet_ID) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DeleteSingleTrippet = ({
  open,
  handleClose,
  trippets,
  tripId,
  userId,
  sortedElement,
  sortedBy,
  filterResults,
  clearAllSelected,
  type,
  CreatorUser_ID,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [keptChildren, setKeptChildren] = useState([]);
  const [children, setChildren] = useState(null);

  useEffect(() => {
    if (trippets && trippets.length > 0 && type === 0) {
      if (trippets[0].Children && trippets[0].Children.length > 0) {
        setChildren(trippets[0].Children);
      } else {
        setChildren(null);
      }
    } else if (trippets && trippets.length > 0 && type === 1) {
      let list = [];
      // eslint-disable-next-line
      trippets.map(trippet => {
        if (trippet.Children && trippet.Children.length > 0) {
          trippet.Children.forEach(child => list.push(child));
        }
      });
      setChildren(list);
    }
  }, [trippets, type]);

  const handleChange = event => {
    setKeptChildren(event.target.value);
  };

  const handleDelete = event => {
    event.stopPropagation();
    if (type === 0) {
      let body = {
        Trippet_ID: trippets[0].Trippet_ID,
        KeepChildren: [],
      };
      if (keptChildren && keptChildren.length > 0) {
        keptChildren.forEach(child => body.KeepChildren.push(child.Trippet_ID));
      }
      dispatch(
        deleteSingleTrippet(
          JSON.stringify(body),
          tripId,
          userId,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
          CreatorUser_ID
        ),
      );
      dispatch(savePaginationPage(1));
      clearAllSelected();
      handleClose();
    } else {
      let body = {
        Trippets: [],
        KeepChildren: [],
      };
      trippets.forEach(child => body.Trippets.push({
        Trippet_ID: child.Trippet_ID,
      }));
      if (keptChildren && keptChildren.length > 0) {
        keptChildren.forEach(child => body.KeepChildren.push(child.Trippet_ID));
      }
      dispatch(
        deleteMultipleTrippet(
          body,
          tripId,
          userId,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
          CreatorUser_ID
        ),
      );
      dispatch(savePaginationPage(1));
      clearAllSelected();
      handleClose();
    }
  };

  const renderClass =
    type === 0 ? classes.formControlSelect : classes.formControl;

  const renderSelect =
    children && children.length > 0 ? (
      <FormControl
        className={classNames(renderClass, classes.noLabel)}
        fullWidth
      >
        <Select
          multiple
          displayEmpty
          value={keptChildren}
          onChange={handleChange}
          input={<Input />}
          renderValue={selected => {
            if (selected.length === 0) {
              return <em>{t("Select children which you want to keep")}</em>;
            }
            let items = [];
            selected.forEach(select => items.push(select.Title));
            return items.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>{t("Select children:")}</em>
          </MenuItem>
          {children.map(child => (
            <MenuItem
              key={child.Trippet_ID}
              value={child}
              style={getStyles(child, keptChildren, theme)}
            >
              {child.Title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : null;

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.container}
      >
        <DialogContent>
          <DialogContentText style={{ marginBottom: 30 }}>
            {t("Are you sure you want to delete?")}
          </DialogContentText>
        </DialogContent>
        <div className={classes.selectHolder}>{renderSelect}</div>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteSingleTrippet;
