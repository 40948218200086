import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { columns } from "./columns";
import useStyles from "./styles";
import ParticipantStatusButton from "./ParticipantStatusButton";
import { getParticipantStatusById } from "../../store/actions/actions/participantStatus";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CheckIcon from "@material-ui/icons/Check";
import * as userAccess from "../../userAccess";
import ParticipantStatusModal from "../../components/ParticipantStatusForm/ParticipantStatusForm";

const createData = (
  ParticipantStatus_ID,
  Type,
  Name,
  Comments,
  IsDefault,
  Listing,
  SortIndex,
  Names,
) => {
  return {
    ParticipantStatus_ID,
    Type,
    Name,
    Comments,
    IsDefault,
    Listing,
    SortIndex,
    Names,
  };
};

const ParticipantStatusTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedParticipantStatus,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedParticipantStatus, setClickedParticipantStatus] = useState([]);
  const [
    isParticipantStatusModalOpen,
    setIsParticipantStatusModalOpen,
  ] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(
    useSelector(state => state.user.user),
    userAccess.Table_ParticipantStatuses,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.ParticipantStatus_ID,
            item.Type,
            item.Name,
            item.Comments,
            item.IsDefault,
            item.Listing,
            item.SortIndex,
            item.Names,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedParticipantStatus = value => {
    setClickedParticipantStatus(value);
  };

  const handleParticipantStatusModalOpen = () => {
    setIsParticipantStatusModalOpen(true);
  };

  const handleParticipantStatusModalClose = () => {
    setIsParticipantStatusModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === "actions") {
      return (
        <ParticipantStatusButton
          data={row}
          handleClickedParticipantStatus={handleClickedParticipantStatus}
          handleParticipantStatusModalOpen={handleParticipantStatusModalOpen}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedParticipantStatus={setDeletedParticipantStatus}
          disabledDelete={disableDelete || Number(row.IsDefault) === 1}
        />
      );
    } else if (column.id === "Type") {
      if (Number(value) === 1) return "Trips";
      if (Number(value) === 2) return "Trippets";
      return "Both";
    } else if (column.id === "Listing") {
      if (Number(value) === 1) return "Invitations";
      if (Number(value) === 2) return "My Trips/Trippets";
      return "Other";
    } else if (column.id === "IsDefault" && value) {
      return Number.parseInt(value) === 1 ? <CheckIcon /> : null;
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    dispatch(getParticipantStatusById(value.ParticipantStatus_ID));
    let recordBody = {
      ParticipantStatus_ID: value.ParticipantStatus_ID,
      Type: value.Type,
      Comments: value.Comments,
      IsDefault: value.IsDefault,
      Listing: value.Listing,
      SortIndex: value.SortIndex,
      Icon: value.Icon,
      MaterialIO: value.MaterialIO,
      Names: value.Names,
    };
    handleClickedParticipantStatus(recordBody);
    handleParticipantStatusModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.ParticipantStatus_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0
                    ? classNames(classes.tableCell, classes[row.color])
                    : classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== "actions") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id ? sortedBy : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <ParticipantStatusModal
        open={isParticipantStatusModalOpen}
        handleParticipantStatusModalClose={handleParticipantStatusModalClose}
        defaultValues={clickedParticipantStatus}
      />
    </Paper>
  );
};

export default ParticipantStatusTable;
