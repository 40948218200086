import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { columns } from './columns';
import useStyles from './styles';
import AdminRoleAccessButton from './AdminRoleAccessButton';
import { getAdminRoleAccessById } from '../../store/actions/actions/adminRoleAccess';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CheckIcon from '@material-ui/icons/Check';
import * as userAccess from '../../userAccess';
import AdminRoleAccessModal from '../../components/AdminRole/AdminRoleAccessForm';

const createData = (
    AdminRoleAccess_ID,
    AdminRole_ID,
    RefTable,
    Read,
    Write,
    Change,
    Delete,
) => {
  return {
    AdminRoleAccess_ID,
    AdminRole_ID,
    RefTable,
    Read,
    Write,
    Change,
    Delete,
  };
};

const AdminRoleAccessTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedAdminRoleAccess,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedAdminRoleAccess, setClickedAdminRoleAccess] = useState([]);
  const [isAdminRoleAccessModalOpen, setIsAdminRoleAccessModalOpen] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(useSelector(state => state.user.user), userAccess.Table_AdminRoles);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.AdminRoleAccess_ID,
            item.AdminRole_ID,
            item.RefTable,
            item.Read,
            item.Write,
            item.Change,
            item.Delete,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedAdminRoleAccess = value => {
    setClickedAdminRoleAccess(value);
  };

  const handleAdminRoleAccessModalOpen = () => {
    setIsAdminRoleAccessModalOpen(true);
  };

  const handleAdminRoleAccessModalClose = () => {
    setIsAdminRoleAccessModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const refTables = {10: 'AdminRoles', 20: 'UserRoles', 30: 'Users', 40: 'Settings', 50: 'Preferences', 60: 'Countries'
    , 70: 'Languages', 80: 'Departments', 90: 'PlanningStatuses', 100: 'Trips', 110: 'Trippets'
    , 120: 'TripParticipants', 130: 'TrippetParticipants'
    , 140: 'Currencies', 150: 'ParticipantStatuses', 160: 'TrippetTypes'
    , 170: 'VerificationCodes', 180: 'Config'};
  const renderData = (column, value, row) => {
    if (column.id === 'actions') {
        return (
            <AdminRoleAccessButton
              data={row}
              handleClickedAdminRoleAccess={handleClickedAdminRoleAccess}
              handleAdminRoleAccessModalOpen={handleAdminRoleAccessModalOpen}
              handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
              setDeletedAdminRoleAccess={setDeletedAdminRoleAccess}
              disabledDelete={disableDelete}
            />
          );
    } if (column.id === 'RefTable') {
      return refTables[value];
    } else {
      return Number.parseInt(value) === 1 ? <CheckIcon /> : null;
    }
  };

  const handleClickRow = value => () => {
    dispatch(getAdminRoleAccessById(value.AdminRoleAccess_ID));
      let recordBody = {
        AdminRoleAccess_ID: value.AdminRoleAccess_ID,
        AdminRole_ID: value.AdminRole_ID,
        RefTable: value.RefTable,
        Read: value.Read,
        Write: value.Write,
        Change: value.Change,
        Delete: value.Delete,
      };
      handleClickedAdminRoleAccess(recordBody);
      handleAdminRoleAccessModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.AdminRoleAccess_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName = classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== 'actions') {
                    return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.tableHeaderCell}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            onClick={handleSortBy(column.id)}
                            active={sortedElement === column.id}
                            direction={
                              sortedElement === column.id
                                ? sortedBy
                                : 'asc'
                            }
                          >
                            {t(column.label)}
                          </TableSortLabel>
                        </TableCell>
                      );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <AdminRoleAccessModal
        open={isAdminRoleAccessModalOpen}
        handleAdminRoleAccessModalClose={handleAdminRoleAccessModalClose}
        defaultValues={clickedAdminRoleAccess}
      />
    </Paper>
  );
};

export default AdminRoleAccessTable;
