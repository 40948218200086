import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PlaceholderImage from '../../assets/placeholder-image.png';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { validateImageFormat } from '../TrippetForm/validateFileFormat';
import { BASE_URL } from '../../config';
import { showSnackBar } from '../../store/actions/actions/root';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(() => ({
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      position: 'relative',
      width: 150,
      height: 150,
    },
  },
  deleteIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.5);',
    borderRadius: 5,
    transition: 'all .7s',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1);',
    },
  },
  browseIcon: {
    position: 'absolute',
    top: 5,
    left: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.5);',
    borderRadius: 5,
    transition: 'all .7s',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1);',
    },
  },
  previewImage: {
    width: '100%',
    height: '100%',
  },
}));

const PreviewIcon = ({
  currentImage,
  pictureUrl,
  clearIcon,
  setIcon,
  type,
  dispatch,
  t
}) => {
  const classes = useStyles();

  const [wrongPictureFormat, setWrongPictureFormat] = useState(true);

  const deleteImage = () => {
    clearIcon();
  };

  const displayImage = event => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      setWrongPictureFormat(validateImageFormat(file));
      if (validateImageFormat(file)) {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('ext', 'image');
        formData.append('type', type);
        //dispatch(uploadFile(formData, 'image', file.name));

        fetch(`${BASE_URL}api/upload.php`, {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            if (response.status === 200) {
              return response.text();
            } else if (response.status === 501) {
              dispatch(showSnackBar({type: 'error', message: 'Something went wrong'}));
            }
          })
          .then(response => {
            const data = JSON.parse(response);
            setIcon(data.File); 
          })
          .catch(error => console.log(error));
      }
    };
    reader.readAsDataURL(file);
  };
  const renderURL = currentImage
    ? `${BASE_URL}${currentImage && currentImage.substr(1) }`
    : pictureUrl;

  const renderImage =
    pictureUrl || currentImage ? (
      <img src={renderURL} alt="Icon" className={classes.previewImage} />
    ) : (
      <img
        alt="placeholder"
        src={PlaceholderImage}
        className={classes.previewImage}
      />
    );
  const renderErrorMessage = wrongPictureFormat ? null : (
    <FormHelperText className={classes.errorText}>
      {t('This image format is not supported.')}
    </FormHelperText>
  );

  return (
    <div className={classes.previewContainer}>
      <div>
        {renderImage}
        <div className={classes.deleteIcon} onClick={deleteImage}>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </div>
        <div className={classes.browseIcon} onClick={deleteImage}>
          <Button 
            variant="contained"
            component="label">
            <AddIcon />
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={displayImage}
            />
          </Button>
        </div>
      </div>
      {renderErrorMessage}
    </div>
  );
};

export default PreviewIcon;