export const columns = [
    { id: 'actions', label: '', minWidth: 70, type: 'actions' },
    { id: 'TripName', label: 'Trip', minWidth: 40, type: 'Name', filter: 'tripnames', },
    { id: 'SortIndex', label: 'Sort#', minWidth: 40, type: 'Name' },
    { id: 'Name', label: 'Name', minWidth: 80, type: 'Name' },
    {
      id: 'Color',
      label: 'Color',
      minWidth: 40,
      align: 'center',
      type: 'Name',
    },
    { id: 'IsTransport', label: 'IsTransport', minWidth: 40, align: 'center', type: 'Name' },
    { id: 'Icon', label: 'Icon', minWidth: 80, align: 'center', type: 'Name' },
  ];
  