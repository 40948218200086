import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Avatar from "@material-ui/core/Avatar";
import TableRow from "@material-ui/core/TableRow";
import { columns } from "./columns";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import deLocale from "date-fns/locale/de";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import TrippetButton from "./TrippetButton";
import TrippetInvitationButton from "./TrippetInvitationButton";
import TrippetRecommendationButton from "./TrippetRecommendationButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getAllPublicDocumentsFromTheSingleTrippet,
  getAllPrivateDocumentsFromTheSingleTrippet,
  getTrippetImages,
} from "../../store/actions/actions/trippet";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Badge from "@material-ui/core/Badge";
import { getTrippetTypeIcon, getTrippetPlanningStatusIcon } from './helper';

const createData = (
  type,
  title,
  tripTitle,
  startDate,
  endDate,
  from,
  to,
  participants,
  organizers,
  status,
  lastChanged,
  color,
  fullTrippet,
) => {
  return {
    type,
    title,
    tripTitle,
    startDate,
    endDate,
    from,
    to,
    participants,
    organizers,
    status,
    lastChanged,
    color,
    fullTrippet,
  };
};

const TrippetDataTable = ({
  trippets,
  handleTrippetFormOpen,
  isOpenTrippetFrom,
  type,
  allUsers,
  handleConfirmDeleteModalOpen,
  setDeletedTrippet,
  findTheTrippetPictureId,
  findPublicTrippetDocument,
  findPrivateTrippetDocument,
  sortedElement,
  sortedBy,
  handleSortBy,
  handleOpenFilterModal,
  disabledFilters,
  activeFilters,
  filterResults,
  getNumberOfActiveFilters,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dateLanguage, setLanguage] = useState("");
  const dispatch = useDispatch();
  const language = useSelector(state => state.programSettings.language);
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const user = useSelector(state => state.user.user);
  const trippetsStatus = useSelector(state => state.trippet.trippetStatus);
  const { t } = useTranslation();
  useEffect(() => {
    if (language === "en") {
      setLanguage("");
    } else if (language === "de") {
      setLanguage(deLocale);
    }
  }, [language]);

  const findUserInfo = participant => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      let id =
        participant.TrippetParticipantUser_ID ||
        participant.TrippetOrganizerUser_ID ||
        participant.TrippetProxyUser_ID;
      allUsers.forEach(user => {
        if (id === user.User_ID) {
          data = renderUserInfo(user);
        }
      });
      return data;
    }
  };

  const renderUserInfo = participant => {
    if (participant.Picture) {
      let initials =
        participant.TrippetParticipantInitials ||
        participant.TrippetOrganizerInitials ||
        participant.TrippetProxyInitials;
      return (
        <img
          src={participant.Picture}
          alt={initials}
          className={classes.avatarImage}
        />
      );
    } else {
      return `${participant.Initials}`;
    }
  };

  const renderParticipantsStatusColor = status => {
    if (status === "1" || status === null || status === "0") {
      return classes.pending;
    } else if (status === "2") {
      return classes.accepted;
    } else if (status === "3") {
      return classes.rejected;
    }
  };

  const renderParticipants = value => {
    let allParticipants = value;
    return (
      allParticipants &&
      allParticipants.map(participant => (
        <Tooltip
          title={participant.TrippetParticipantDisplayName}
          placement="top"
          key={participant.TrippetParticipantUser_ID}
          arrow
        >
          <Avatar
            className={renderParticipantsStatusColor(
              participant.ParticipantStatus_ID,
            )}
          >
            {/* {`${participant.TrippetParticipantInitials}`} */}
            {findUserInfo(participant)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const renderOrganizers = value => {
    let allOrganizers = value;
    return (
      allOrganizers &&
      allOrganizers.map(organizer => (
        <Tooltip
          title={organizer.TrippetOrganizerDisplayName}
          placement="top"
          key={organizer.TrippetOrganizerUser_ID}
          arrow
        >
          <Avatar
            className={renderParticipantsStatusColor(
              organizer.TrippetOrganizerAcceptanceStatus,
            )}
          >
            {/* {`${participant.TrippetParticipantInitials}`} */}
            {findUserInfo(organizer)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const renderProxies = value => {
    let allProxies = value;
    return (
      allProxies &&
      allProxies.map(proxy => (
        <Tooltip
          title={proxy.TrippetProxyDisplayName}
          placement="top"
          key={proxy.TrippetProxyUser_ID}
          arrow
        >
          <Avatar
            className={renderParticipantsStatusColor(
              proxy.TrippetProxyAcceptanceStatus,
            )}
          >
            {/* {`${participant.TrippetParticipantInitials}`} */}
            {findUserInfo(proxy)}
          </Avatar>
        </Tooltip>
      ))
    );
  };

  const getTrippetTypeColor = data => {
    if (trippetTypes) {
      let element = trippetTypes.find(type => data === type.TrippetTypeLook_ID);
      if (element) {
        return element.Color;
      }
    }
  };

  const converToLocalTime = date => {
    let newDate = date.split("");
    newDate.push("Z");
    let localTime = new Date(newDate.join(""));
    return format(new Date(localTime), "dd.MM.yyyy HH:mm");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (trippets && trippets.length) {
      trippets.forEach(trippet => {
        elements.push(
          createData(
            getTrippetTypeIcon(trippet.TrippetTypeLook_ID, trippetTypes, classes) || "",
            trippet.Title,
            trippet.TripName,
            `${format(
              new Date(trippet.StartDate || trippet.TrippetStartDate),
              "EEEEEE.dd MMM",
              {
                locale: dateLanguage,
              },
            )} | ${format(
              new Date(trippet.StartDate || trippet.TrippetStartDate),
              "HH:mm",
            )}`,
            `${format(
              new Date(trippet.EndDate || trippet.TrippetEndDate),
              "EEEEEE.dd MMM",
              {
                locale: dateLanguage,
              },
            )} | ${format(
              new Date(trippet.EndDate || trippet.TrippetEndDate),
              "HH:mm",
            )}`,
            trippet.FromPlace || trippet.TrippetFromPlace,
            trippet.ToPlace || trippet.TrippetToPlace,
            trippet.Participants,
            trippet.Organizers,
            getTrippetPlanningStatusIcon(trippet.OwnerPlanningStatus_ID, trippetsStatus, classes),
            converToLocalTime(trippet.ModifiedDate),
            getTrippetTypeColor(trippet.TrippetTypeLook_ID || ""),
            trippet,
          ),
        );
      });
      setRows(elements);
    }
  };

  useEffect(() => {
    if (trippets && trippets.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trippets, trippetTypes, trippetsStatus]);

  const renderData = (column, value, row) => {
    if (column.id === "participants") {
      return (
        <AvatarGroup max={2} className={classes.avatarGroup}>
          {renderParticipants(value)}
        </AvatarGroup>
      );
    } else if (column.id === "organizers") {
      return (
        <AvatarGroup max={2} className={classes.avatarGroup}>
          {renderOrganizers(value)}
        </AvatarGroup>
      );
    }
    if (column.id === "proxies") {
      return (
        <AvatarGroup max={2} className={classes.avatarGroup}>
          {renderProxies(value)}
        </AvatarGroup>
      );
    } else if (column.id === "actions") {
      if (type === "my-trippets") {
        return (
          <TrippetButton
            data={row}
            isOpenTrippetFrom={isOpenTrippetFrom}
            allUsers={allUsers}
            handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
            setDeletedTrippet={setDeletedTrippet}
            filterResults={filterResults}
            sortedElement={sortedElement}
            sortedBy={sortedBy}
          />
        );
      } else if (type === "invitations") {
        return (
          <TrippetInvitationButton
            data={row}
            isOpenTrippetFrom={isOpenTrippetFrom}
          />
        );
      } else if (type === "recommendations") {
        return (
          <TrippetRecommendationButton
            data={row}
            isOpenTrippetFrom={isOpenTrippetFrom}
          />
        );
      }
    } else {
      return value;
    }
  };

  const handleRowClick = rowData => () => {
    if (!isOpenTrippetFrom) {
      const privateDocument = {
        Trippet_ID: rowData.fullTrippet.Trippet_ID,
        IsPrivate: "1",
        CreatorUser_ID: user.User_ID,
      };
      dispatch(
        getAllPrivateDocumentsFromTheSingleTrippet(
          JSON.stringify(privateDocument),
        ),
      );

      const publicDocument = {
        Trippet_ID: rowData.fullTrippet.Trippet_ID,
        IsPrivate: "0",
      };
      dispatch(
        getAllPublicDocumentsFromTheSingleTrippet(
          JSON.stringify(publicDocument),
        ),
      );

      const trippetImage = {
        Trippet_ID: rowData.fullTrippet.Trippet_ID,
      };
      dispatch(getTrippetImages(JSON.stringify(trippetImage)));
      handleTrippetFormOpen(rowData);
    }
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.fullTrippet.Trippet_ID}
              onClick={handleRowClick(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName =
                  index === 0 ? `5px solid #${row.color}` : classes.tableCell;
                if (column.label === "To") {
                  return (
                    <Tooltip
                      arrow
                      title={`${value} ${
                        JSON.parse(row.fullTrippet.ToData).formatted_address
                      }`}
                      placement="top"
                      key={column.id}
                    >
                      <TableCell align={column.align} className={styleName}>
                        {renderData(column, value, row)}
                      </TableCell>
                    </Tooltip>
                  );
                } else if (column.label === "From") {
                  if (JSON.parse(row.fullTrippet.FromData)) {
                    return (
                      <Tooltip
                        arrow
                        title={`${value} ${
                          JSON.parse(row.fullTrippet.FromData).formatted_address
                        }`}
                        placement="top"
                        key={column.id}
                      >
                        <TableCell align={column.align} className={styleName}>
                          {renderData(column, value, row)}
                        </TableCell>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <TableCell
                        align={column.align}
                        className={styleName}
                        key={column.id}
                      >
                        {renderData(column, value, row)}
                      </TableCell>
                    );
                  }
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableCell}
                      style={{ borderLeft: styleName }}
                    >
                      {renderData(column, value, row)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                const renederActiveSortingArrow =
                  sortedElement === column.type
                    ? classes.tableHeaderCellActive
                    : classes.tableHeaderCell;
                if (column.id !== "actions") {
                  if (column.filter) {
                    let activeColor = activeFilters[column.filter]
                      ? classes.activeFilter
                      : "";
                    const renderNumber = getNumberOfActiveFilters(column);

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth, width: column.width }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : "asc"
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                        <IconButton
                          onClick={handleOpenFilterModal(column)}
                          disabled={disabledFilters[column.filter]}
                          className={activeColor}
                        >
                          <Badge badgeContent={renderNumber} color="primary">
                            <FilterListIcon />
                          </Badge>
                        </IconButton>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : "asc"
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                      </TableCell>
                    );
                  }
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TrippetDataTable;
