import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import ChipInput from "material-ui-chip-input";
import { createParticipant, createProxy, createOrganizer } from "./helper";

const ExternalUserModal = ({
  open,
  handleClose,
  handleSave,
  type,
  trip,
  title,
  role,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);
  const [message, setMessage] = useState("");
  const [values, setValues] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (!flag) {
      setValues([]);
      setMessage("");
      setCounter(0);
      setFlag(true);
    }
  }, [flag]);

  const handleAddChip = chip => {
    setValues([...values, chip]);
  };

  const handleDeleteChip = (chip, index) => {
    let array = [...values];
    array.splice(index, 1);
    setValues(array);
  };

  const handleMessage = event => {
    setMessage(event.target.value);
    setCounter(event.target.value.length);
  };

  const handleSaveUsers = () => {
    if (message) {
      let array = [...values];
      let buddies = [];
      array.forEach(item => {
        if (type === "Participants") {
          let buddy = createParticipant(
            item,
            role.UserRole_ID,
            trip.Trip_ID,
            type,
            message,
          );
          buddies.push(buddy);
        } else if (type === "Organizers") {
          let buddy = createOrganizer(
            item,
            role.UserRole_ID,
            trip.Trip_ID,
            type,
            message,
          );
          buddies.push(buddy);
        } else if (type === "Proxies") {
          let buddy = createProxy(
            item,
            role.UserRole_ID,
            trip.Trip_ID,
            type,
            message,
          );
          buddies.push(buddy);
        }
      });
      handleSave(buddies, type);
      handleClose();
      setFlag(false);
    } else {
      let array = [...values];
      let buddies = [];
      array.forEach(item => {
        if (type === "Participants") {
          let buddy = createParticipant(
            item,
            role.UserRole_ID,
            trip.Trip_ID,
            type,
            "",
          );
          buddies.push(buddy);
        } else if (type === "Organizers") {
          let buddy = createOrganizer(
            item,
            role.UserRole_ID,
            trip.Trip_ID,
            type,
            "",
          );
          buddies.push(buddy);
        } else if (type === "Proxies") {
          let buddy = createProxy(
            item,
            role.UserRole_ID,
            trip.Trip_ID,
            type,
            "",
          );
          buddies.push(buddy);
        }
      });
      handleSave(buddies, type);
      handleClose();
      setFlag(false);
    }
  };

  const handleCloseModal = () => {
    setFlag(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.container}
      >
        <div className={classes.modalTopContainer}>
          <DialogTitle id="alert-dialog-title">{`${t(
            `Invite New Users as`,
          )}  ${title}`}</DialogTitle>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.modalContentContainer}>
          <div className={classes.topContent}>
            <div>
              {t("Invite your friends to this Trip and to join TRIP*PERFECT!")}
            </div>
            <div>{t(`(some copy personalized for ${title}?)`)}</div>
          </div>
          <div>
            <ChipInput
              value={values}
              onAdd={chip => handleAddChip(chip)}
              onDelete={(chip, index) => handleDeleteChip(chip, index)}
              fullWidthInput={true}
              fullWidth={true}
              variant="outlined"
              label={t("Enter users emails")}
              className={classes.inputs}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="message"
              label={t("Type your personal message.")}
              name="message"
              autoComplete="trip-message"
              autoFocus
              value={message}
              onChange={handleMessage}
              className={classes.inputs}
              multiline
              rowsMax="4"
            />
            <FormHelperText>{`${counter} / 1500`}</FormHelperText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleSaveUsers} color="primary" variant="contained">
            {t("Invite")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExternalUserModal;
