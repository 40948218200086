import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import Logo from "../../assets/TP-logo-800px.png";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { checkVerificationCode } from "../../store/actions/actions/verificationCode";

const Verification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [accessCode, setAccessCode] = useState("");
  const [errorMessage] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    const body = {
      VerificationCode: accessCode,
    }
    dispatch(checkVerificationCode(JSON.stringify(body), history, t))
  };

  const handleChange = event => {
    setAccessCode(event.target.value);
  };

  const renderErrorMessage = errorMessage ? (
    <FormHelperText className={classes.errorMessage}>
      {t("Access denied")}
    </FormHelperText>
  ) : null;

  return (
    <div className={classes.container}>
      <Grid container className={classes.pageContainer}>
        <Grid item xs={12} container justify="center" className={classes.image}>
          <img src={Logo} alt="logo" />
        </Grid>
        <Grid item xs={12} container justify="center" className={classes.text}>
          <div>{t("Please type in your access code")},</div>
          <div>{t("then you will get to the sign up / sign in area")}.</div>
        </Grid>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="accessCode"
            label={t("Access Code")}
            name="accessCode"
            autoComplete="accessCode"
            autoFocus
            value={accessCode}
            onChange={handleChange}
            type="text"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
          >
            {t("Enter")}
          </Button>
          {renderErrorMessage}
        </form>
      </Grid>
    </div>
  );
};

export default Verification;
