import React, { useEffect, useState } from "react";
import withAdminDashboard from "../../hoc/withAdminDashboard/withAdminDashboard";
import withTitle from "../../hoc/withTitle";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTrippetTypeCategories,
  deleteTrippetTypeCategory,
  saveCurrentPage,
  createPerTripTrippetTypeCategory,
  saveFilterTrip_ID
} from "../../store/actions/actions/trippetTypeCategory";
import Table from "./Table";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import { ROWS_PER_PAGE } from "../../config";
import * as userAccess from "../../userAccess";
import TrippetTypeCategoryModal from "../../components/TrippetType/TrippetTypeCategoryForm";
import TripSelector from "../tripSelector/TripSelector";

const TrippetTypeCategories = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const filterTrip_ID = useSelector(state => state.trippetTypeCategory.filterTrip_ID);
  const allTrippetTypeCategories = useSelector(
    state => state.trippetTypeCategory.allTrippetTypeCategories,
  );
  const paginationData = useSelector(
    state => state.trippetTypeCategory.paginationData,
  );
  const currentPage = useSelector(
    state => state.trippetTypeCategory.currentPage,
  );
  const [deletedTrippetTypeCategory, setDeletedTrippetTypeCategory] = useState(
    null,
  );
  const [isOpenConfirmDeleteModal, setisOpenConfirmDeleteModal] = useState(
    false,
  );

  const [openTripSelector, setOpenTripSelector] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortedElement, setSortedElement] = useState("Name");
  const [sortedDir, setSortedDir] = useState("asc");
  const [
    isTrippetTypeCategoryModalOpen,
    setIsTrippetTypeCategoryModalOpen,
  ] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    const bodySearch = {
      Trip_ID: filterTrip_ID,
      Page: currentPage,
      RowsPerPage: ROWS_PER_PAGE,
      OrderBy: sortedElement,
      Language_ID: "2",
      OrderDir: sortedDir,
    };

    dispatch(getAllTrippetTypeCategories(JSON.stringify(bodySearch)));
  }, [sortedElement, sortedDir, currentPage, filterTrip_ID, dispatch]);

  useEffect(() => {
    if (paginationData) {
      setTotalItemsCount(paginationData.Num);
    }
  }, [paginationData]);

  const handleSortDir = id => () => {
    setSortedDir(sortedElement === id && sortedDir === "asc" ? "desc" : "asc");
    setSortedElement(id);
  };

  const onPaginationChange = activePage => {
    dispatch(saveCurrentPage(activePage));
  };

  const handleConfirmDeleteModalClose = () => {
    setisOpenConfirmDeleteModal(false);
  };

  const handleConfirmDeleteModalOpen = () => {
    setisOpenConfirmDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(
      deleteTrippetTypeCategory(JSON.stringify(deletedTrippetTypeCategory)),
    );
  };

  const handleTrippetTypeCategoryModalOpen = () => {
    setIsTrippetTypeCategoryModalOpen(true);
  };

  const handleTrippetTypeCategoryModalClose = () => {
    setIsTrippetTypeCategoryModalOpen(false);
  };

  const handleNewClick = () => {
    setDefaultValues(null);
    handleTrippetTypeCategoryModalOpen();
  };

  const handleAddTripClick = () =>
  {
    setOpenTripSelector(!openTripSelector);
  }
  const setIsOpen = (isOpen) =>
  {
    setOpenTripSelector(isOpen);
  }
  const setSelectedTrip = (trip) =>
  {
    console.log(trip.fullTrip.Trip_ID);
    dispatch(saveFilterTrip_ID(trip.fullTrip.Trip_ID));
    dispatch(createPerTripTrippetTypeCategory(JSON.stringify({Trip_ID: trip.fullTrip.Trip_ID})));
    setOpenTripSelector(false);
  }
  return userAccess.canRead(user, userAccess.Table_TrippetTypes) ? (
    <Grid container justify="center" className={classes.containerMyTrip}>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {t("Trippet Type Categories")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Fab
            variant="extended"
            className={classes.addTripButton}
            onClick={handleNewClick}
            disabled={!userAccess.canWrite(user, userAccess.Table_TrippetTypes)}
          >
            <AddIcon className={classes.extendedIcon} />
            {t("Type Category")}
          </Fab>
          <Fab
            variant="extended"
            className={classes.addTripButton}
            onClick={handleAddTripClick}
            disabled={!userAccess.canWrite(user, userAccess.Table_TrippetTypes)}
          >
            <AddIcon className={classes.extendedIcon} />
            {t("Duplicate Per Trip")}
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          data={allTrippetTypeCategories}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedTrippetTypeCategory={setDeletedTrippetTypeCategory}
          sortedElement={sortedElement}
          sortedBy={sortedDir}
          handleSortBy={handleSortDir}
        />
      </Grid>
      <AdminPagination
        activePage={currentPage}
        itemsPerPage={ROWS_PER_PAGE}
        totalItemsCount={totalItemsCount}
        onChange={onPaginationChange}
        title="trippet type categories"
      />
      <ConfirmDeleteModal
        open={isOpenConfirmDeleteModal}
        handleClose={handleConfirmDeleteModalClose}
        handleDelete={handleDelete}
      />
      <TrippetTypeCategoryModal
        open={isTrippetTypeCategoryModalOpen}
        handleTrippetTypeCategoryModalClose={
          handleTrippetTypeCategoryModalClose
        }
        defaultValues={defaultValues}
      />
      <TripSelector
        openTripSelector={openTripSelector}
        setIsOpen={setIsOpen}
        setSelectedTrip={setSelectedTrip}
      />
    </Grid>
  ) : (
    <div>NO ACCESS</div>
  );
};

export default withTitle(
  withAdminDashboard(TrippetTypeCategories),
  "Admin Trippet Type Categories",
);
