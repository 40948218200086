import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allUserRoles: null,
  entireUserRoles: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'Name',
  sortByDirection: 'asc',
  currentUserRole: {
    UserRole_ID: 0,
    Name: '',
    Type: 0,
    TripView : 0,
    TripEdit : 0,
    TripDelete : 0,
    TripParticipantView : 0,
    TripParticipantAdd : 0,
    TripParticipantEdit : 0,
    TripParticipantDelete : 0,
    TripProxyView : 0,
    TripProxyAdd : 0,
    TripProxyEdit : 0,
    TripProxyDelete : 0,
    TripOrganizerView : 0,
    TripOrganizerAdd : 0,
    TripOrganizerEdit : 0,
    TripOrganizerDelete : 0,
    TrippetView : 0,
    TrippetAdd : 0,
    TrippetEdit : 0,
    TrippetDelete : 0,
    TrippetJoin : 0,
    TrippetParticipantView : 0,
    TrippetParticipantAdd : 0,
    TrippetParticipantEdit : 0,
    TrippetParticipantDelete : 0,
    TrippetProxyView : 0,
    TrippetProxyAdd : 0,
    TrippetProxyEdit : 0,
    TrippetProxyDelete : 0,
    TrippetOrganizerView : 0,
    TrippetOrganizerAdd : 0,
    TrippetOrganizerEdit : 0,
    TrippetOrganizerDelete : 0,
    TrippetDocumentView : 0,
    TrippetDocumentAdd : 0,
    TrippetDocumentEdit : 0,
    TrippetDocumentDelete : 0,
    TrippetPictureView : 0,
    TrippetPictureAdd : 0,
    TrippetPictureEdit : 0,
    TrippetPictureDelete : 0,
    TrippetChat : 0
  },
};

const userRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_USERROLE: {
      return {
        ...state,
        currentUserRole: {
            UserRole_ID: action.payload.UserRole_ID,
            Name: action.payload.Name,
            Type: action.payload.Type,
            TripView : action.payload.TripView,
            TripEdit : action.payload.TripEdit,
            TripDelete : action.payload.TripDelete,
            TripParticipantView : action.payload.TripParticipantView,
            TripParticipantAdd : action.payload.TripParticipantAdd,
            TripParticipantEdit : action.payload.TripParticipantEdit,
            TripParticipantDelete : action.payload.TripParticipantDelete,
            TripProxyView : action.payload.TripProxyView,
            TripProxyAdd : action.payload.TripProxyAdd,
            TripProxyEdit : action.payload.TripProxyEdit,
            TripProxyDelete : action.payload.TripProxyDelete,
            TripOrganizerView : action.payload.TripOrganizerView,
            TripOrganizerAdd : action.payload.TripOrganizerAdd,
            TripOrganizerEdit : action.payload.TripOrganizerEdit,
            TripOrganizerDelete : action.payload.TripOrganizerDelete,
            TrippetView : action.payload.TrippetView,
            TrippetAdd : action.payload.TrippetAdd,
            TrippetEdit : action.payload.TrippetEdit,
            TrippetDelete : action.payload.TrippetDelete,
            TrippetJoin : action.payload.TrippetJoin,
            TrippetParticipantView : action.payload.TrippetParticipantView,
            TrippetParticipantAdd : action.payload.TrippetParticipantAdd,
            TrippetParticipantEdit : action.payload.TrippetParticipantEdit,
            TrippetParticipantDelete : action.payload.TrippetParticipantDelete,
            TrippetProxyView : action.payload.TrippetProxyView,
            TrippetProxyAdd : action.payload.TrippetProxyAdd,
            TrippetProxyEdit : action.payload.TrippetProxyEdit,
            TrippetProxyDelete : action.payload.TrippetProxyDelete,
            TrippetOrganizerView : action.payload.TrippetOrganizerView,
            TrippetOrganizerAdd : action.payload.TrippetOrganizerAdd,
            TrippetOrganizerEdit : action.payload.TrippetOrganizerEdit,
            TrippetOrganizerDelete : action.payload.TrippetOrganizerDelete,
            TrippetDocumentView : action.payload.TrippetDocumentView,
            TrippetDocumentAdd : action.payload.TrippetDocumentAdd,
            TrippetDocumentEdit : action.payload.TrippetDocumentEdit,
            TrippetDocumentDelete : action.payload.TrippetDocumentDelete,
            TrippetPictureView : action.payload.TrippetPictureView,
            TrippetPictureAdd : action.payload.TrippetPictureAdd,
            TrippetPictureEdit : action.payload.TrippetPictureEdit,
            TrippetPictureDelete : action.payload.TrippetPictureDelete,
            TrippetChat : action.payload.TrippetChat
        },
      };
    }
    case adminActionTypes.SAVE_ALL_USERROLES: {
      return {
        ...state,
        allUserRoles: action.payload,
      };
    }
    case adminActionTypes.SAVE_ENTIRE_USERROLES: {
      return {
        ...state,
        entireUserRoles: action.payload,
      };
    }
    case adminActionTypes.SAVE_USERROLE_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_USERROLE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_USERROLE_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_USERROLE_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allUserRoles: null,
        entireUserRoles: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentUserRole: {
            UserRole_ID: 0,
            Name: '',
            Type: 0,
            TripView : 0,
            TripEdit : 0,
            TripDelete : 0,
            TripParticipantView : 0,
            TripParticipantAdd : 0,
            TripParticipantEdit : 0,
            TripParticipantDelete : 0,
            TripProxyView : 0,
            TripProxyAdd : 0,
            TripProxyEdit : 0,
            TripProxyDelete : 0,
            TripOrganizerView : 0,
            TripOrganizerAdd : 0,
            TripOrganizerEdit : 0,
            TripOrganizerDelete : 0,
            TrippetView : 0,
            TrippetAdd : 0,
            TrippetEdit : 0,
            TrippetDelete : 0,
            TrippetJoin : 0,
            TrippetParticipantView : 0,
            TrippetParticipantAdd : 0,
            TrippetParticipantEdit : 0,
            TrippetParticipantDelete : 0,
            TrippetProxyView : 0,
            TrippetProxyAdd : 0,
            TrippetProxyEdit : 0,
            TrippetProxyDelete : 0,
            TrippetOrganizerView : 0,
            TrippetOrganizerAdd : 0,
            TrippetOrganizerEdit : 0,
            TrippetOrganizerDelete : 0,
            TrippetDocumentView : 0,
            TrippetDocumentAdd : 0,
            TrippetDocumentEdit : 0,
            TrippetDocumentDelete : 0,
            TrippetPictureView : 0,
            TrippetPictureAdd : 0,
            TrippetPictureEdit : 0,
            TrippetPictureDelete : 0,
            TrippetChat : 0
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default userRoleReducer;