import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { columns } from "./columns";
import useStyles from "./styles";
import PreferenceCategoryButton from "./PreferenceCategoryButton";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { BASE_URL } from "../../config";
import * as userAccess from "../../userAccess";
import PreferenceCategoryModal from "../../components/Preference/PreferenceCategoryForm";
import Row from './Row';
import { saveRows } from '../../store/actions/actions/settingCategory';

const createData = (PreferenceCategory_ID, Comments, Name, Names, Items) => {
  return {
    PreferenceCategory_ID,
    Comments,
    Name,
    Names,
    Items,
  };
};

const PreferenceCategoryTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedPreferenceCategory,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const allPreferenceCategories = useSelector(state => state.preferenceCategory.allPreferenceCategories);
  const [rows, setRows] = useState([]);
  const [clickedPreferenceCategory, setClickedPreferenceCategory] = useState(
    [],
  );
  const [
    isPreferenceCategoryModalOpen,
    setIsPreferenceCategoryModalOpen,
  ] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(
    useSelector(state => state.user.user),
    userAccess.Table_Preferences,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.PreferenceCategory_ID,
            item.Comments,
            item.Name,
            item.Names,
            item.Items,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedPreferenceCategory = value => {
    setClickedPreferenceCategory(value);
  };

  const handlePreferenceCategoryModalOpen = () => {
    setIsPreferenceCategoryModalOpen(true);
  };

  const handlePreferenceCategoryModalClose = () => {
    setIsPreferenceCategoryModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  
  useEffect(() => {
    if(allPreferenceCategories && allPreferenceCategories.length > 0){
      let data = {};
      allPreferenceCategories.forEach(category => {
        data[category.PreferenceCategory_ID] = false;
      });
      dispatch(saveRows(data));
    }
  },[allPreferenceCategories, dispatch])

  const renderData = (column, value, row) => {
    if (column.id === "actions") {
      return (
        <PreferenceCategoryButton
          data={row}
          handleClickedPreferenceCategory={handleClickedPreferenceCategory}
          handlePreferenceCategoryModalOpen={handlePreferenceCategoryModalOpen}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedPreferenceCategory={setDeletedPreferenceCategory}
          disabledDelete={disableDelete}
        />
      );
    } else if (column.id === "Icon" && value) {
      return (
        <img
          src={`${BASE_URL}${value.substr(1)}`}
          className={classes.avatar}
          alt="Icon"
        />
      );
    } else if (column.id === "Color" && value) {
      return (
        <span>
          {value}{" "}
          <span
            style={{
              backgroundColor: "#" + value,
              width: "30px",
              display: "inline-block",
            }}
          >
            &nbsp;
          </span>
        </span>
      );
    } else {
      return value;
    }
  };
  
  const renderRows = 
  rows && rows.length
    ? rows.map(row => {
        return (<Row id={row.PreferenceCategory_ID} key={row.PreferenceCategory_ID} row={row} renderData={renderData}/>)
    })
    : null;


  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== "actions") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id ? sortedBy : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <PreferenceCategoryModal
        open={isPreferenceCategoryModalOpen}
        handlePreferenceCategoryModalClose={handlePreferenceCategoryModalClose}
        defaultValues={clickedPreferenceCategory}
      />
    </Paper>
  );
};

export default PreferenceCategoryTable;
