import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-GB";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Badge from "@material-ui/core/Badge";
import classNames from "classnames";
import {
  getTrippetTypeIcon,
  getTrippetPlanningStatusIcon,
} from "../../pages/myTrippets/helper";
import Checkbox from "@material-ui/core/Checkbox";
import Row from "./Row";
import { columns, childrenColumns } from "./columns";

const createData = (
  type,
  title,
  startDate,
  startTime,
  endDate,
  endTime,
  from,
  to,
  distance,
  status,
  lastChanged,
  color,
  fullTrippet,
) => {
  return {
    type,
    title,
    startDate,
    startTime,
    endDate,
    endTime,
    from,
    to,
    distance,
    status,
    lastChanged,
    color,
    fullTrippet,
  };
};

const TrippetDataTable = ({
  sortedElement,
  sortedBy,
  handleSortBy,
  handleOpenFilterModal,
  disabledFilters,
  activeFilters,
  filterResults,
  getNumberOfActiveFilters,
  checkedTrippets,
  handleCheckboxChange,
  handleAllChecked,
  allChecked,
  selectedTrippets,
  toogleChilds,
  setSaveOrdering,
  indeterminate,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dateLanguage, setLanguage] = useState("");
  const trippets = useSelector(
    state => state.trippet.parentsTrippetsWithChildren,
  );
  const language = useSelector(state => state.programSettings.language);
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const trippetCategories = useSelector(
    state => state.trippet.trippetCategories,
  );
  const trippetChildrenStatus = useSelector(
    state => state.trippet.trippetChildrenStatus,
  );
  const { t } = useTranslation();
  const trippetsStatus = useSelector(state => state.trippet.trippetStatus);

  useEffect(() => {
    if (language === "en") {
      setLanguage(enLocale);
    } else if (language === "de") {
      setLanguage(deLocale);
    }
  }, [language]);

  const getTrippetTypeColor = data => {
    if (trippetTypes) {
      let element = trippetTypes.find(type => data === type.TrippetTypeLook_ID);
      if(element){
        return element.Color;
      }
      return "";
    }
  };

  const converToLocalTime = date => {
    let newDate = date.split("");
    newDate.push("Z");
    let localTime = new Date(newDate.join(""));
    return format(new Date(localTime), "dd.MM.yyyy HH:mm");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (trippets && trippets.length) {
      trippets.forEach(trippet => {
        elements.push(
          createData(
            getTrippetTypeIcon(
              trippet.TrippetTypeLook_ID,
              trippetTypes,
              classes,
            ),
            trippet.Title,
            format(new Date(trippet.StartDate), "EEEEEE.dd MMM", {
              locale: dateLanguage,
            }),
            format(new Date(trippet.StartDate), "HH:mm"),
            format(new Date(trippet.EndDate), "EEEEEE.dd MMM", {
              locale: dateLanguage,
            }),
            format(new Date(trippet.EndDate), "HH:mm"),
            trippet.FromPlace,
            trippet.ToPlace,
            trippet.Distance,
            getTrippetPlanningStatusIcon(
              trippet.OwnerPlanningStatus_ID,
              trippetsStatus,
              classes,
            ),
            converToLocalTime(trippet.ModifiedDate),
            getTrippetTypeColor(trippet.TrippetTypeLook_ID),
            trippet,
          ),
        );
      });
      setRows(elements);
    }
  };

  const renderChildrenElements = (list, setChildrenRows) => {
    let elements = [];
    if (list && list.length) {
      list.forEach(trippet => {
        elements.push(
          createData(
            getTrippetTypeIcon(
              trippet.TrippetTypeLook_ID,
              trippetTypes,
              classes,
            ),
            trippet.Title,
            format(new Date(trippet.StartDate), "EEEEEE.dd MMM", {
              locale: dateLanguage,
            }),
            format(new Date(trippet.StartDate), "HH:mm"),
            format(new Date(trippet.EndDate), "EEEEEE.dd MMM", {
              locale: dateLanguage,
            }),
            format(new Date(trippet.EndDate), "HH:mm"),
            trippet.FromPlace,
            trippet.ToPlace,
            trippet.Distance,
            getTrippetPlanningStatusIcon(
              trippet.OwnerPlanningStatus_ID,
              trippetsStatus,
              classes,
            ),
            converToLocalTime(trippet.ModifiedDate),
            getTrippetTypeColor(trippet.TrippetTypeLook_ID),
            trippet,
          ),
        );
      });
      setChildrenRows(elements);
    }
  };

  useEffect(() => {
    if (
      trippets &&
      trippets.length &&
      trippetChildrenStatus &&
      trippetTypes &&
      trippetTypes.length > 0 &&
      trippetsStatus &&
      trippetsStatus.length > 0 &&
      trippetCategories &&
      trippetCategories.length > 0
    ) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trippets,
    trippetTypes,
    trippetChildrenStatus,
    trippetsStatus,
    trippetCategories,
  ]);

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <Row
              key={row.fullTrippet.Trippet_ID}
              row={row}
              checkedTrippets={checkedTrippets}
              handleCheckboxChange={handleCheckboxChange}
              toogleChilds={toogleChilds}
              renderChildrenElements={renderChildrenElements}
              columns={columns}
              childrenColumns={childrenColumns}
              setSaveOrdering={setSaveOrdering}
            />
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                const renederActiveSortingArrow =
                  sortedElement === column.type
                    ? classNames(
                        classes.tableHeader,
                        classes.tableHeaderCellActive,
                      )
                    : classNames(classes.tableHeader, classes.tableHeaderCell);
                if (column.id !== "actions" && column.id !== "children") {
                  if (column.filter) {
                    let activeColor = activeFilters[column.filter]
                      ? classNames(classes.filterButton, classes.activeFilter)
                      : classes.filterButton;
                    const renderNumber = getNumberOfActiveFilters(column);
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          className={classes.activeArrow}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : "asc"
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                        <IconButton
                          onClick={handleOpenFilterModal(column)}
                          disabled={disabledFilters[column.filter]}
                          className={activeColor}
                        >
                          <Badge badgeContent={renderNumber} color="primary">
                            <FilterListIcon />
                          </Badge>
                        </IconButton>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          className={classes.activeArrow}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : "asc"
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                      </TableCell>
                    );
                  }
                } else {
                  if (column.id === "actions") {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeaderCell}
                        style={{ minWidth: column.minWidth }}
                      >
                        <Checkbox
                          checked={allChecked}
                          onChange={handleAllChecked}
                          color="primary"
                          // indeterminate={
                          //   selectedTrippets &&
                          //   selectedTrippets.length > 0 &&
                          //   !allChecked
                          // }
                          indeterminate={indeterminate}
                        />
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeaderCell}
                        style={{ minWidth: column.minWidth }}
                      >
                        {t(column.label)}
                      </TableCell>
                    );
                  }
                }
              })}
            </TableRow>
          </TableHead>
          {renderRows}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TrippetDataTable;
