import React, { useEffect, useState } from "react";
import withDashboard from "../../hoc/withDashboard/withDashboard";
import withTitle from "../../hoc/withTitle";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTrips,
  saveTripSortElement,
  saveTripSortByDirection,
  saveTripInvitationSortElement,
  saveTripInvitationSortByDirection,
  getAllSentInvitation,
  getTripFilterData,
  clearFilterTripResults,
  saveTripUsedFilters,
  getAllSentTripRecommendations,
  getAllTripRecommendations,
  deleteTripParticipant,
  deleteTrip,
  saveCurrentTrip,
  forceDeleteTrip,
  deleteTripOrganizer,
  deleteTripProxy,
  getTripReceivedInvitation,
} from "../../store/actions/actions/trip";
import Table from "./Table";
import RecomendationTable from "./Recommendation/Table";
import InvitationsTable from "./Invitations/Table";
import Button from "@material-ui/core/Button";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link, useHistory } from "react-router-dom";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import OwnerConfirmationDeleteModal from "../../components/OwnerConfirmationDeleteModal/OwnerConfirmationDeleteModal";
import { setLastTrip, getUserByToken } from "../../store/actions/actions/user";
import Pagination from "../../components/Pagination/Pagination";
import FilterModal from "../../components/TripFilterModal/TripFilterModal";

const MyTrips = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(state => state.user.user);
  const allTrips = useSelector(state => state.trip.allTrips);
  const paginationData = useSelector(state => state.trip.paginationData);
  const allTripRecivedInvitations = useSelector(
    state => state.trip.allTripRecivedInvitations,
  );
  const allTripSentInvitations = useSelector(
    state => state.trip.allTripSentInvitations,
  );
  const allTripRecommendations = useSelector(
    state => state.trip.allTripRecommendations,
  );
  const allSentTripRecommendations = useSelector(
    state => state.trip.allSentTripRecommendations,
  );
  const filterData = useSelector(state => state.trip.filterData);
  const allUsers = useSelector(state => state.app.allUsers);
  const tripSentInvitationalPagination = useSelector(
    state => state.trip.tripSentInvitationalPagination,
  );
  const tripRecivedInvitationalPagination = useSelector(
    state => state.trip.tripRecivedInvitationalPagination,
  );
  const tripSentRecommendationPagination = useSelector(
    state => state.trip.tripSentRecommendationPagination,
  );
  const tripRecivedRecommendationPagination = useSelector(
    state => state.trip.tripRecivedRecommendationPagination,
  );
  const [deletedTrip, setDeletedTrip] = useState(null);
  const [allMyActiveTrip, setAllMyActiveTrip] = useState(null);
  const [invitationType, setInvitationType] = useState("received");
  const [isOpenConfirmDeleteModal, setisOpenConfirmDeleteModal] = useState(
    false,
  );
  const [isMyRecommendations, setIsMyRecommendations] = useState(true);
  const [recommendationType, setRecommendationType] = useState("received");
  const [page, setPage] = useState(1);
  const [pageInvitation, setPageInvitation] = useState(1);
  const [pageRecommendation, setPageRecommendation] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [totalItemsCountInvitation, setTotalItemsCountInvitation] = useState(0);
  const [
    totalItemsCountRecommendation,
    setTotalItemsCountRecommendation,
  ] = useState(0);
  const [sortedElement, setSortedElement] = useState("ModifiedDate");
  const [lastUsedInvitationTable, setLastUsedInvitationTable] = useState(
    "received",
  );
  const [tripInvitationTable, setTripInvitationTable] = useState(null);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [userTripRole, setUserTripRole] = useState("");
  const [sortedElementInvitation, setSortedElementInvitation] = useState(
    "ModifiedDate",
  );
  const [sortedBy, setSortedBy] = useState({
    Name: "asc",
    StartDate: "asc",
    Message: "asc",
    DisplayName: "asc",
    OwnerUser_ID: "asc",
    OwnerPlanningStatusName: "asc",
    ModifiedDate: "desc",
    NumTrippets: "asc",
  });
  const [sortedByInvitation, setSortedByInvitation] = useState({
    Name: "asc",
    StartDate: "asc",
    Message: "asc",
    DisplayName: "asc",
    OwnerUser_ID: "asc",
    OwnerPlanningStatusName: "asc",
    ModifiedDate: "desc",
    NumTrippets: "asc",
    UserRoleName: "asc",
  });
  const [invitationActiveClass, setInvitationActiveClass] = useState({
    sent: false,
    received: true,
  });
  const [recommendationctiveClass, setRecommendationctiveClass] = useState({
    sent: false,
    received: true,
  });
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [disabledFilters, setDisabledFilters] = useState({
    startdates: false,
    participants: false,
    owners: false,
    ownerplanningstatuses: false,
  });
  const [activeFilters, setActiveFilters] = useState({
    startdates: false,
    participants: false,
    owners: false,
    ownerplanningstatuses: false,
  });
  const [clickedFilter, setClickedFilter] = useState(null);
  const [filterResults, setFilterResults] = useState([]);
  const [usedFilters, setUsedFilets] = useState([]);
  const [pickedDeletedTrip, setPickedDeletedTrip] = useState(null);
  const [
    isOpenOwnerConfirmDeleteModal,
    setIsOpenOwnerConfirmDeleteModal,
  ] = useState(false);
  useEffect(() => {
    if (paginationData) {
      setTotalItemsCount(+paginationData.Num);
    }
  }, [paginationData]);

  useEffect(() => {
    if (
      invitationType &&
      tripRecivedInvitationalPagination &&
      tripSentInvitationalPagination
    ) {
      if (invitationType === "sent") {
        setTotalItemsCountInvitation(+tripSentInvitationalPagination.Num);
      } else {
        setTotalItemsCountInvitation(+tripRecivedInvitationalPagination.Num);
      }
    }
  }, [
    tripSentInvitationalPagination,
    tripRecivedInvitationalPagination,
    invitationType,
  ]);

  useEffect(() => {
    if (
      // eslint-disable-next-line
      (recommendationType &&
        // eslint-disable-next-line
        tripRecivedRecommendationPagination) ||
      tripSentRecommendationPagination
    ) {
      if (recommendationType === "sent" && tripSentRecommendationPagination) {
        setTotalItemsCountRecommendation(+tripSentRecommendationPagination.Num);
      } else {
        setTotalItemsCountRecommendation(
          +tripRecivedRecommendationPagination.Num,
        );
      }
    }
  }, [
    tripSentRecommendationPagination,
    tripRecivedRecommendationPagination,
    recommendationType,
  ]);

  useEffect(() => {
    let array = [];
    if (allTrips && allTrips.length > 0) {
      allTrips.forEach(trip => {
        // if (trip.IsDeleted === null || trip.IsDeleted === '0') {
        //   array.push(trip);
        // }
        array.push(trip);
      });
      setAllMyActiveTrip(array);
    } else {
      setAllMyActiveTrip([]);
    }
  }, [allTrips]);

  useEffect(() => {
    if (user) {
      const bodyTrips = {
        User_ID: user.User_ID,
        Type: 2,
        Page: page,
        RowsPerPage: 10,
        OrderBy: sortedElement,
        Language_ID: user.Language_ID,
        IsDeleted: "0",
        OrderDir: sortedBy[sortedElement],
      };
      if (filterResults.length > 0) {
        dispatch(saveTripUsedFilters(filterResults));
        filterResults.forEach(item => {
          if (item !== [] || item !== "") {
            bodyTrips[item.name] = item.body;
          }
        });
      } else {
        dispatch(saveTripUsedFilters(null));
      }
      dispatch(getAllTrips(JSON.stringify(bodyTrips)));
    }
  }, [
    sortedElement,
    sortedBy,
    page,
    dispatch,
    user.User_ID,
    filterResults,
    user,
  ]);

  useEffect(() => {
    if (user) {
      if (invitationType === "sent") {
        const sentInvitation = {
          Language_ID: user.Language_ID,
          Type: 4,
          User_ID: user.User_ID,
          ParticipantStatus_ID: 1,
          IsDeleted: 0,
          Page: pageInvitation,
          RowsPerPage: 10,
          OrderBy: sortedElementInvitation,
          OrderDir: sortedByInvitation[sortedElementInvitation],
        };
        dispatch(getAllSentInvitation(JSON.stringify(sentInvitation)));
      } else {
        const recivedInvitation = {
          Language_ID: user.Language_ID,
          Type: 3,
          User_ID: user.User_ID,
          ParticipantStatus_ID: 1,
          IsDeleted: 0,
          Page: pageInvitation,
          RowsPerPage: 10,
          OrderBy: sortedElementInvitation,
          OrderDir: sortedByInvitation[sortedElementInvitation],
        };
        dispatch(getTripReceivedInvitation(JSON.stringify(recivedInvitation)));
      }
    }
  }, [
    sortedElementInvitation,
    sortedByInvitation,
    dispatch,
    user.User_ID,
    user,
    pageInvitation,
    invitationType,
  ]);

  useEffect(() => {
    if (user) {
      if (allTripRecivedInvitations && lastUsedInvitationTable === "received") {
        setTripInvitationTable(allTripRecivedInvitations);
        setInvitationType("received");
        setInvitationActiveClass({
          received: true,
          sent: false,
        });
      } else {
        setTripInvitationTable(allTripSentInvitations);
        setInvitationType("sent");
        setInvitationActiveClass({
          received: false,
          sent: true,
        });
      }
    }
  }, [
    allTripRecivedInvitations,
    allTripSentInvitations,
    lastUsedInvitationTable,
    user,
  ]);

  useEffect(() => {
    if (user) {
      if (recommendationType === "sent") {
        const body = {
          TripRecommendationSenderUser_ID: user.User_ID,
          Page: pageRecommendation,
          RowsPerPage: 10,
        };
        dispatch(getAllSentTripRecommendations(JSON.stringify(body)));
      } else {
        const body = {
          TripRecommendationRecipientUser_ID: user.User_ID,
          Page: pageRecommendation,
          RowsPerPage: 10,
        };
        dispatch(getAllTripRecommendations(JSON.stringify(body)));
      }
    }
  }, [dispatch, pageRecommendation, recommendationType, user]);

  const resetSoryBy = () => {
    setSortedBy({
      Name: "asc",
      StartDate: "asc",
      Message: "asc",
      Participants: "asc",
      OwnerUser_ID: "asc",
      OwnerPlanningStatusName: "asc",
      ModifiedDate: "asc",
    });
  };

  const resetSoryByInvitation = () => {
    setSortedByInvitation({
      Name: "asc",
      StartDate: "asc",
      Message: "asc",
      Participants: "asc",
      OwnerUser_ID: "asc",
      OwnerPlanningStatusName: "asc",
      ModifiedDate: "asc",
      UserRoleName: "asc",
    });
  };

  const handleSortBy = id => () => {
    switch (id) {
      case "date":
        setSortedElement("StartDate");
        dispatch(saveTripSortElement("StartDate"));
        if (sortedBy.StartDate === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            StartDate: "desc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              StartDate: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            StartDate: "asc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              StartDate: "asc",
            }),
          );
        }
        break;
      case "name":
        setSortedElement("Name");
        dispatch(saveTripSortElement("Name"));
        if (sortedBy.Name === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Name: "desc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              Name: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Name: "asc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              Name: "asc",
            }),
          );
        }
        break;
      case "participants":
        setSortedElement("DisplayName");
        dispatch(saveTripSortElement("DisplayName"));
        if (sortedBy.DisplayName === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            DisplayName: "desc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              DisplayName: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            DisplayName: "asc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              DisplayName: "asc",
            }),
          );
        }
        break;
      case "numTrippets":
        setSortedElement("NumTrippets");
        dispatch(saveTripSortElement("NumTrippets"));
        if (sortedBy.NumTrippets === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            NumTrippets: "desc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              NumTrippets: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            NumTrippets: "asc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              NumTrippets: "asc",
            }),
          );
        }
        break;
      case "owner":
        setSortedElement("OwnerUser_ID");
        dispatch(saveTripSortElement("OwnerUser_ID"));
        if (sortedBy.OwnerUser_ID === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            OwnerUser_ID: "desc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              OwnerUser_ID: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            OwnerUser_ID: "asc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              OwnerUser_ID: "asc",
            }),
          );
        }
        break;
      case "message":
        setSortedElement("Message");
        dispatch(saveTripSortElement("Message"));
        if (sortedBy.Message === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Message: "desc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              Message: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            Message: "asc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              Message: "asc",
            }),
          );
        }
        break;
      case "status":
        setSortedElement("OwnerPlanningStatusName");
        dispatch(saveTripSortElement("OwnerPlanningStatusName"));
        if (sortedBy.OwnerPlanningStatusName === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            OwnerPlanningStatusName: "desc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              OwnerPlanningStatusName: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            OwnerPlanningStatusName: "asc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              OwnerPlanningStatusName: "asc",
            }),
          );
        }
        break;
      case "lastChanged":
        setSortedElement("ModifiedDate");
        dispatch(saveTripSortElement("ModifiedDate"));
        if (sortedBy.ModifiedDate === "asc") {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            ModifiedDate: "desc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              ModifiedDate: "desc",
            }),
          );
        } else {
          resetSoryBy();
          setSortedBy({
            ...sortedBy,
            ModifiedDate: "asc",
          });
          dispatch(
            saveTripSortByDirection({
              ...sortedBy,
              ModifiedDate: "asc",
            }),
          );
        }
        break;
      default:
        break;
    }
  };

  const handleSortByInvitation = id => () => {
    switch (id) {
      case "date":
        setSortedElementInvitation("StartDate");
        dispatch(saveTripInvitationSortElement("StartDate"));
        if (sortedByInvitation.StartDate === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            StartDate: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              StartDate: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            StartDate: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              StartDate: "asc",
            }),
          );
        }
        break;
      case "name":
        setSortedElementInvitation("Name");
        dispatch(saveTripInvitationSortElement("Name"));
        if (sortedByInvitation.Name === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            Name: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              Name: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            Name: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              Name: "asc",
            }),
          );
        }
        break;
        case "role":
        setSortedElementInvitation("UserRoleName");
        dispatch(saveTripInvitationSortElement("UserRoleName"));
        if (sortedByInvitation.UserRoleName === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            UserRoleName: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              UserRoleName: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            UserRoleName: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              UserRoleName: "asc",
            }),
          );
        }
        break;
      case "numTrippets":
        setSortedElementInvitation("NumTrippets");
        dispatch(saveTripInvitationSortElement("NumTrippets"));
        if (sortedByInvitation.NumTrippets === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            NumTrippets: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              NumTrippets: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            NumTrippets: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              NumTrippets: "asc",
            }),
          );
        }
        break;
      case "participants":
        setSortedElementInvitation("DisplayName");
        dispatch(saveTripInvitationSortElement("DisplayName"));
        if (sortedByInvitation.DisplayName === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            DisplayName: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              DisplayName: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            DisplayName: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              DisplayName: "asc",
            }),
          );
        }
        break;
      case "organizers":
        setSortedElementInvitation("DisplayName");
        dispatch(saveTripInvitationSortElement("DisplayName"));
        if (sortedByInvitation.DisplayName === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            DisplayName: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              DisplayName: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            DisplayName: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              DisplayName: "asc",
            }),
          );
        }
        break;
      case "proxies":
        setSortedElementInvitation("DisplayName");
        dispatch(saveTripInvitationSortElement("DisplayName"));
        if (sortedByInvitation.DisplayName === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            DisplayName: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              DisplayName: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            DisplayName: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              DisplayName: "asc",
            }),
          );
        }
        break;
      case "owner":
        setSortedElementInvitation("OwnerUser_ID");
        dispatch(saveTripInvitationSortElement("OwnerUser_ID"));
        if (sortedByInvitation.OwnerUser_ID === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            OwnerUser_ID: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              OwnerUser_ID: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            OwnerUser_ID: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              OwnerUser_ID: "asc",
            }),
          );
        }
        break;
      case "message":
        setSortedElementInvitation("Message");
        dispatch(saveTripInvitationSortElement("Message"));
        if (sortedByInvitation.Message === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            Message: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              Message: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            Message: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              Message: "asc",
            }),
          );
        }
        break;
      case "status":
        setSortedElementInvitation("OwnerPlanningStatusName");
        dispatch(saveTripInvitationSortElement("OwnerPlanningStatusName"));
        if (sortedByInvitation.OwnerPlanningStatusName === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            OwnerPlanningStatusName: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              OwnerPlanningStatusName: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            OwnerPlanningStatusName: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              OwnerPlanningStatusName: "asc",
            }),
          );
        }
        break;
      case "lastChanged":
        setSortedElementInvitation("ModifiedDate");
        dispatch(saveTripInvitationSortElement("ModifiedDate"));
        if (sortedByInvitation.ModifiedDate === "asc") {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            ModifiedDate: "desc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              ModifiedDate: "desc",
            }),
          );
        } else {
          resetSoryByInvitation();
          setSortedByInvitation({
            ...sortedByInvitation,
            ModifiedDate: "asc",
          });
          dispatch(
            saveTripInvitationSortByDirection({
              ...sortedByInvitation,
              ModifiedDate: "asc",
            }),
          );
        }
        break;
      default:
        break;
    }
  };

  const getUser = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const body = {
        Token: token,
        type: "autologin",
      };
      dispatch(getUserByToken(JSON.stringify(body, history)));
    }
  };

  const onPaginationChange = activePage => {
    setPage(activePage);
  };

  const onPaginationChangeInvitation = activePage => {
    setPageInvitation(activePage);
  };

  const onPaginationChangeRecommendation = activePage => {
    setPageRecommendation(activePage);
  };

  const handleRecommendations = value => () => {
    setIsMyRecommendations(value);
    if (value) {
      setRecommendationType("received");
      setPageRecommendation(1);
      setRecommendationctiveClass({
        received: true,
        sent: false,
      });
    } else {
      setRecommendationType("sent");
      setPageRecommendation(1);
      setRecommendationctiveClass({
        received: false,
        sent: true,
      });
    }
  };

  const handleConfirmDeleteModalClose = () => {
    setisOpenConfirmDeleteModal(false);
  };

  const handleConfirmDeleteModalOpen = () => {
    setisOpenConfirmDeleteModal(true);
  };

  const handleOwnerConfirmDeleteModalClose = () => {
    setIsOpenOwnerConfirmDeleteModal(false);
  };

  const handleOwnerConfirmDeleteModalOpen = () => {
    setIsOpenOwnerConfirmDeleteModal(true);
  };

  const handleTripInvitationTable = value => () => {
    setLastUsedInvitationTable(value);
    if (value === "sent") {
      setPageInvitation(1);
      setTripInvitationTable(allTripSentInvitations);
      setInvitationType("sent");
      setInvitationActiveClass({
        received: false,
        sent: true,
      });
    } else {
      setTripInvitationTable(allTripRecivedInvitations);
      setInvitationType("received");
      setPageInvitation(1);
      setInvitationActiveClass({
        received: true,
        sent: false,
      });
    }
  };

  const handleLastTrip = () => {
    if (user.lasttrip === deletedTrip.Trip_ID) {
      let index = allTrips.findIndex(
        trip => trip.Trip_ID === deletedTrip.Trip_ID,
      );
      let trips = [...allTrips];
      trips.splice(index, 1);
      const lastTripBody = {
        type: "settrip",
        LastTrip_ID: trips[trips.length - 1].Trip_ID,
        User_ID: user.User_ID,
      };
      dispatch(setLastTrip(JSON.stringify(lastTripBody)));
      dispatch(saveCurrentTrip(trips[trips.length - 1]));
      getUser();
    }
  };

  const handleForceDelete = trippets => {
    if (trippets) {
      let trippetsId = [];
      trippets.forEach(trippet => trippetsId.push(trippet.Trippet_ID));
      const body = {
        Trip_ID: deletedTrip.Trip_ID,
        KeepTrippet_IDs: trippetsId,
      };
      dispatch(
        forceDeleteTrip(
          JSON.stringify(body),
          user.User_ID,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
          t,
        ),
      );
      handleLastTrip();
      setPage(1);
    } else {
      const body = {
        Trip_ID: deletedTrip.Trip_ID,
        KeepTrippet_IDs: null,
      };
      dispatch(
        forceDeleteTrip(
          JSON.stringify(body),
          user.User_ID,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
          t,
        ),
      );
      handleLastTrip();
      setPage(1);
    }
  };

  const handleDeleteParticipants = () => {
    if (currentParticipant && userTripRole === "participant") {
      const body = {
        TripParticipant_ID: currentParticipant.TripParticipant_ID,
        Trip_ID: currentParticipant.Trip_ID,
      };
      dispatch(
        deleteTripParticipant(
          JSON.stringify(body),
          user.User_ID,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
          t,
        ),
      );
      handleLastTrip();
      setPage(1);
    } else if (currentParticipant && userTripRole === "proxy") {
      const body = {
        TripProxy_ID: currentParticipant.TripProxy_ID,
        Trip_ID: currentParticipant.Trip_ID,
      };
      dispatch(
        deleteTripProxy(
          JSON.stringify(body),
          user.User_ID,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
          t,
        ),
      );
      handleLastTrip();
      setPage(1);
    } else if (currentParticipant && userTripRole === "organizer") {
      const body = {
        TripOrganizer_ID: currentParticipant.TripOrganizer_ID,
        Trip_ID: currentParticipant.Trip_ID,
      };
      dispatch(
        deleteTripOrganizer(
          JSON.stringify(body),
          user.User_ID,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
          t,
        ),
      );
      handleLastTrip();
      setPage(1);
    }
  };

  const handleDeleteAsOwner = () => {
    if (deletedTrip) {
      const body = {
        Trip_ID: deletedTrip.Trip_ID,
      };
      dispatch(
        deleteTrip(
          JSON.stringify(body),
          user.User_ID,
          sortedElement,
          sortedBy[sortedElement],
          filterResults,
        ),
      );
      handleLastTrip();
      setPage(1);
    }
  };

  const handleDelete = (role, trip, participant) => {
    if (role) {
      setUserTripRole(role);
      if (role === "owner") {
        setPickedDeletedTrip(trip);
        handleOwnerConfirmDeleteModalOpen();
      } else if (role === "participant") {
        handleConfirmDeleteModalOpen();
        setCurrentParticipant(participant);
      } else if (role === "organizer") {
        handleConfirmDeleteModalOpen();
        setCurrentParticipant(participant);
      } else if (role === "proxy") {
        handleConfirmDeleteModalOpen();
        setCurrentParticipant(participant);
      }
    }
  };

  const handleOpenFilterModal = column => () => {
    if (column.filter) {
      setIsFilterModalOpen(true);
      setClickedFilter(column);
      let body = {
        path: column.filter,
        data: {
          Language_ID: user.Language_ID,
          User_ID: user.User_ID,
          OrderBy: column.type,
          OrderDir: "asc",
          Type: 2,
        },
      };
      if (filterResults.length > 0) {
        filterResults.forEach(item => {
          if (item !== [] || item !== "") {
            if (item.type !== column.filter) {
              body.data[item.name] = item.body;
            }
          }
        });
      }
      dispatch(getTripFilterData(body, t));
    }
  };

  const checkUsedFilters = filterName => {
    let status = "";
    if (usedFilters.length > 0) {
      usedFilters.forEach(filter => {
        if (filter === filterName) {
          status = false;
        } else {
          status = true;
        }
      });
    } else {
      status = true;
    }
    return status;
  };

  const checkActiveFilters = filters => {
    let allFilters = {
      startdates: false,
      participants: false,
      owners: false,
      ownerplanningstatuses: false,
    };
    if (filters.length > 0) {
      filters.forEach(filter => {
        allFilters = {
          ...allFilters,
          [filter]: true,
        };
      });
    } else {
      allFilters = {
        startdates: false,
        participants: false,
        owners: false,
        ownerplanningstatuses: false,
      };
    }
    setActiveFilters(allFilters);
  };

  useEffect(() => {
    checkActiveFilters(usedFilters);
    if (usedFilters.length >= 3) {
      let filters = {
        startdates: true,
        participants: true,
        owners: true,
        ownerplanningstatuses: true,
      };
      usedFilters.forEach(filter => {
        filters = {
          ...filters,
          [filter]: false,
        };
      });
      setDisabledFilters(filters);
    } else {
      setDisabledFilters({
        startdates: false,
        participants: false,
        owners: false,
        ownerplanningstatuses: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedFilters]);

  const clearFilteredData = name => {
    if (name) {
      let array = [...usedFilters];
      array.forEach((filter, index) => {
        if (filter === name) {
          array.splice(index, 1);
        }
      });
      setUsedFilets(array);
    }
  };

  const handleFilterValues = (item, values) => {
    let array = [...filterResults];
    if (item === "startdates") {
      if (values && values.length === 0) {
        clearFilteredData("startdates");
        array.forEach((item, index) => {
          if (item.type === "startdates") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "startdates") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "StartDates",
          body: [],
          type: "startdates",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.StartDate);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "participants") {
      if (values && values.length === 0) {
        clearFilteredData("participants");
        array.forEach((item, index) => {
          if (item.type === "participants") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "participants") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "TripParticipantUser_IDs",
          body: [],
          type: "participants",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.TripParticipantUser_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "owners") {
      if (values && values.length === 0) {
        clearFilteredData("owners");
        array.forEach((item, index) => {
          if (item.type === "owners") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "owners") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "OwnerUser_IDs",
          body: [],
          type: "owners",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.OwnerUser_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    } else if (item === "ownerplanningstatuses") {
      if (values && values.length === 0) {
        clearFilteredData("ownerplanningstatuses");
        array.forEach((item, index) => {
          if (item.type === "ownerplanningstatuses") {
            array.splice(index, 1);
          }
        });
        setFilterResults(array);
      } else {
        array.forEach((item, index) => {
          if (item.type === "ownerplanningstatuses") {
            array.splice(index, 1);
          }
        });
        let data = {
          name: "OwnerPlanningStatus_IDs",
          body: [],
          type: "ownerplanningstatuses",
          data: values,
        };
        values.forEach(item => {
          data.body.push(item.OwnerPlanningStatus_ID);
        });
        array.push(data);
        setFilterResults(array);
        if (checkUsedFilters(data.type)) {
          setUsedFilets([...usedFilters, data.type]);
        }
      }
    }
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
    dispatch(clearFilterTripResults());
  };

  const renderRecommendationTable = isMyRecommendations
    ? allTripRecommendations
    : allSentTripRecommendations;

  return (
    <Grid container justify="center" className={classes.containerMyTrip}>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid item xs={12} className={classes.backToCockpit}>
        <Link to="/">
          <KeyboardBackspaceIcon />
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            className={classes.title}
            id={"Invitations"}
          >
            {t("Back to Cockpit")}
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.title}
        >
          {t("My Trips")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Table
          data={allMyActiveTrip}
          allUsers={allUsers}
          type="my-trip"
          user={user}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedTrip={setDeletedTrip}
          sortedElement={sortedElement}
          sortedBy={sortedBy}
          handleSortBy={handleSortBy}
          handleOpenFilterModal={handleOpenFilterModal}
          disabledFilters={disabledFilters}
          activeFilters={activeFilters}
          filterResults={filterResults}
          setUserTripRole={setUserTripRole}
          handleDelete={handleDelete}
        />
      </Grid>
      <Pagination
        activePage={page}
        itemsPerPage={10}
        totalItemsCount={totalItemsCount}
        name="trips"
        onChange={onPaginationChange}
      />
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.title}
          id={"Invitations"}
        >
          {t("Invitations")}
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Button
          onClick={handleTripInvitationTable("received")}
          className={invitationActiveClass.received ? classes.activeButton : ""}
        >
          {t("Received")}
        </Button>
        <Button
          onClick={handleTripInvitationTable("sent")}
          className={invitationActiveClass.sent ? classes.activeButton : ""}
        >
          {t("Sent")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <InvitationsTable
          data={tripInvitationTable}
          allUsers={allUsers}
          type="invitations"
          user={user}
          invitationType={invitationType}
          sortedElement={sortedElementInvitation}
          sortedBy={sortedByInvitation}
          handleSortBy={handleSortByInvitation}
          filterResults={filterResults}
          sortedElementMyTrips={sortedElement}
          sortedByMyTrips={sortedBy}
        />
      </Grid>
      <Pagination
        activePage={pageInvitation}
        itemsPerPage={10}
        totalItemsCount={totalItemsCountInvitation}
        onChange={onPaginationChangeInvitation}
        name="trips"
      />
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.title}
          id={"Recommendations"}
        >
          {t("Recommendations")}
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Button
          onClick={handleRecommendations(true)}
          className={
            recommendationctiveClass.received ? classes.activeButton : ""
          }
        >
          {t("Received")}
        </Button>
        <Button
          onClick={handleRecommendations(false)}
          className={recommendationctiveClass.sent ? classes.activeButton : ""}
        >
          {t("Sent")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <RecomendationTable
          data={renderRecommendationTable}
          allUsers={allUsers}
          type="recommendations"
          user={user}
          recommendationType={recommendationType}
        />
      </Grid>
      <Pagination
        activePage={pageRecommendation}
        itemsPerPage={10}
        totalItemsCount={totalItemsCountRecommendation}
        onChange={onPaginationChangeRecommendation}
        name="trips"
      />
      <ConfirmDeleteModal
        open={isOpenConfirmDeleteModal}
        handleClose={handleConfirmDeleteModalClose}
        handleDelete={handleDeleteParticipants}
      />
      <FilterModal
        open={isFilterModalOpen}
        handleClose={handleCloseFilterModal}
        name={clickedFilter}
        handleFilterValues={handleFilterValues}
        filterResults={filterResults}
        filterData={filterData}
      />
      <OwnerConfirmationDeleteModal
        open={isOpenOwnerConfirmDeleteModal}
        handleClose={handleOwnerConfirmDeleteModalClose}
        handleForceDelete={handleForceDelete}
        trip={pickedDeletedTrip}
        handleDeleteParticipants={handleDeleteParticipants}
        userTripRole={userTripRole}
        handleDeleteAsOwner={handleDeleteAsOwner}
      />
    </Grid>
  );
};

export default withTitle(withDashboard(MyTrips), "My Trips");
