import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Logo from "../../assets/TP-logo-800px.png";
import useStyles from "../signIn/styles";
import Copyright from "../../components/Copyright";
import Typography from "@material-ui/core/Typography";
import withTitle from "../../hoc/withTitle";
import { useTranslation } from "react-i18next";
import { resetUserPassword } from "../../store/actions/actions/user";
import { useHistory, withRouter, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";

const ChangePassword = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [values, setValues] = useState({
    email: "",
    password: "",
    code: "",
  });

  useEffect(() => {
    if (props.location && props.location.search) {
      let params = queryString.parse(props.location.search);
      if (params && params.code) {
        setValues({
          ...values,
          code: params.code,
        });
      }
    }
    // eslint-disable-next-line
  }, [props.location]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleSubmit = event => {
    event.preventDefault();
    const body = {
      Email: values.email,
      Password: values.password,
      Token: values.code,
    };
    dispatch(resetUserPassword(JSON.stringify(body), props.history, t));
    history.push("/login");
  };

  const handleInputs = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image}>
        <img src={Logo} alt="logo" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        className={classes.formContainer}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("Reset Password")}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="12_email"
              label={t("Email Address")}
              name="email"
              autoComplete="off"
              autoFocus
              value={values.email}
              onChange={handleInputs}
              type="email"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="12_code"
              label={t("Code")}
              name="code"
              autoComplete="off"
              value={values.code}
              onChange={handleInputs}
              type="text"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("Password")}
              value={values.password}
              type={showPassword ? "text" : "password"}
              id="12_password"
              autoComplete="off"
              onChange={handleInputs}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t("Change Password")}
            </Button>
            <Grid container>
              <Grid item>
                <Link to="/login" variant="body2" className={classes.link}>
                  {t("Already have an account? Sign in")}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default withRouter(withTitle(ChangePassword, "Change Password"));
