import React from 'react';
import Pagination from 'react-js-pagination';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ROWS_PER_PAGE } from '../../config';

const useStyles = makeStyles(theme => ({
  pagination: {
    width: '100%',
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > ul': {
      display: 'flex',
      justifyContent: 'center',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      width: '100%',
    },
    '& > ul > li': {
      width: 45,
      height: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.text[100],
      cursor: 'pointer',
      transition: 'all .6s',
      backgroundColor: '#F2F2F2',
      borderRadius: 2,
      userSelect: 'none',
      margin: '0 5px',
      padding: '0 20px',
      '&:hover': {
        backgroundColor: '#E0E0E0',
      },
    },
    '& > ul > li > a': {
      textDecoration: 'none',
      color: theme.palette.text[100],
    },
    '& > ul > li.active': {
      backgroundColor: '#E0E0E0',
      '& > a': {
        color: theme.palette.text[100],
      },
    },
    '& > ul > li.disabled': {
      cursor: 'no-drop',
      '& > a': {
        color: 'gray',
        cursor: 'no-drop',
      },
      '&:hover': {
        backgroundColor: '#F2F2F2',
      },
    },
  },
  paginationInfo: {
    width: '33.3%',
    textAlign: 'right',
    fontSize: 16,
  },
  placeholder: {
    width: '33.3%',
  },
}));

const AdminPaginationComponent = props => {
  const classes = useStyles();
  const { activePage, itemsPerPage, totalItemsCount, onChange, title } = props;

  const displayTrippetsNumber = () => {
    let a = (activePage - 1) * ROWS_PER_PAGE + 1;
    let b = activePage * ROWS_PER_PAGE;
    let fromNumber = 0;
    let toNumber = 0;
    if (a > totalItemsCount) {
      fromNumber = totalItemsCount;
    } else {
      fromNumber = a;
    }

    if (b < totalItemsCount) {
      toNumber = b;
    } else {
      toNumber = totalItemsCount;
    }
    return `Showing ${title} ${fromNumber} to ${toNumber} of ${totalItemsCount}`;
  };
  return (
    <div className={classes.pagination}>
      <div className={classes.placeholder} />
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={totalItemsCount}
        onChange={onChange}
      />
      <div className={classes.paginationInfo}>{displayTrippetsNumber()}</div>
    </div>
  );
};

AdminPaginationComponent.propTypes = {
  activePage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default AdminPaginationComponent;
