import { BASE_URL } from '../../config';
import { showSnackBar } from '../../store/actions/actions/root';
import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewAdminRole, changeAdminRole, getAllAdminRoles } from '../../store/actions/actions/adminRole';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import AdminRoleAccessList from '../../components/AdminRoleAccessTable/AdminRoleAccessList';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../admin.css';
import * as userAccess from '../../userAccess';

const AdminRoleModal = ({ open, handleAdminRoleModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [formError, setFormError] = useState(false);
  const allAdminRoles = useSelector(state => state.adminRole.allAdminRoles);
  const currentAdminRole = useSelector(state => state.adminRole.currentAdminRole);
  const [values, setValues] = useState({
    IsDefault: 0,
    Name: ''
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure AdminRoles are already loaded
    if (!allAdminRoles)
    {
      dispatch(getAllAdminRoles(null));
      return;
    }
    if (defaultValues) {
        if (currentAdminRole)
        {
            setValues({
                AdminRole_ID: currentAdminRole.AdminRole_ID,
                IsDefault: currentAdminRole.IsDefault,
                Name: currentAdminRole.Name,
            });
        }
        else
        {
            setValues({
                AdminRole_ID: defaultValues.AdminRole_ID,
                IsDefault: defaultValues.IsDefault,
                Name: defaultValues.Name,
            });
        }
        
        handleIsEditMode(true);
    } else {
      setValues({
        AdminRole_ID: 0,
        IsDefault: 0,
        Name: '',
      });
      handleIsEditMode(false);
    }
  }, [allAdminRoles, defaultValues, currentAdminRole, dispatch]);

  const validateForm = ({ Name }) => {
    if (Name) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };

  const submitValues = () => {
    const data = {
      AdminRole_ID: +values.AdminRole_ID,
      IsDefault: values.IsDefault,
      Name: values.Name,
    };
    if (isEditMode) {
      if (validateForm(data)) {
        dispatch(changeAdminRole(JSON.stringify(data)));
        handleAdminRoleModalClose();
      }
    } else {
      if (validateForm(data)) {
        dispatch(createNewAdminRole(JSON.stringify(data)));
        handleAdminRoleModalClose();
      }
    }
  };

  const handleSubmit = e => {
    e.stopPropagation();
    if (!nameError) {
        e.preventDefault();
      
      // check IsDefault
      if (Number(values.IsDefault) === 1)
      {
        fetch(`${BASE_URL}api/adminrole/search.php`, {
          method: 'POST',
          body: JSON.stringify({IsDefault: 1}),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => {
            if (response.status === 200) {
              return response.text();
            } else if (response.status === 304) {
              dispatch(showSnackBar({type: 'error', message: 'Something went wrong!'}));
            }
          })
          .then(response => {
            const data = JSON.parse(response);
            if (data.Num > 0 && Number(data.Data[0].AdminRole_ID) !== Number(values.AdminRole_ID))
            {
              // we have already another default ParticipantStatus.
              // get confirm
              confirmAlert({
                title: 'Change default Admin Role',
                message: 'There is already a default Admin Role. Do you want to replace it?.',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => submitValues()
                  },
                  {
                    label: 'No',
                  }
                ]
              });
            }
            else
            {
              // it is the only default value
              submitValues();
            }
          })
          .catch(error => console.log(error));
      }
      else
        submitValues();   
      
    }
  };

  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name === 'Name') {
      if (e.target.value.length === 0) {
        setNameError(true);
      } else {
        setNameError(false);
      }
    }
    setValues({...values, [e.target.name]: e.target.value});
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const renderTitle = defaultValues ? t('Edit Admin Role') : t('New Admin Role');
      
  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;


  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_AdminRoles))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_AdminRoles));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={12} className={classes.adminRoleName}>
                <FormControl variant="outlined" className={classes.selectView}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="Name"
                    label={t('Name')}
                    name="Name"
                    autoFocus
                    value={values.Name}
                    onChange={handleInputs}
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} className={classes.adminRoleName}>
                <FormControlLabel
                      control={
                      <Checkbox
                          checked={Number(values.IsDefault) === 1}
                          onChange={handleInputs}
                          name="IsDefault"
                          id="IsDefault"
                          color="primary"
                          value={Number(values.IsDefault) === 1 ? 0 : 1}
                      />
                      }
                      label={t('IsDefault')}
                  />
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
              
              <Grid item xs={12}>
                {isEditMode && <AdminRoleAccessList data={currentAdminRole} />}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleAdminRoleModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default AdminRoleModal;
