import React, { useState, useEffect, Fragment } from 'react';
import useStyles from './styles';
import {
  GoogleMap,
  Polyline,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showTrippetForm } from '../../store/actions/actions/trippet';
import { saveMarkers, savePaths } from '../../store/actions/actions/map';
import { useTranslation } from 'react-i18next';

const Map = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const zoom = useSelector(state => state.programSettings.zoom);
  const paths = useSelector(state => state.map.paths);
  const markers = useSelector(state => state.map.markers);
  const trippets = useSelector(state => state.trippet.allTrippets);
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [infoWindowDetails, setInfoWindowDetails] = useState(null);

  const getTrippetTypeColor = data => {
    if (trippetTypes && trippetTypes.length > 0) {
      let element = trippetTypes.find(type => data === type.TrippetTypeLook_ID);
      if(element){
        return element.Color;
      }else {
        return "";
      }
    }
  };

  useEffect(() => {
    if (trippets) {
      let allPaths = [];
      let allMarkers = [];
      trippets.forEach(trippet => {
        let latFrom = '';
        let lngFrom = '';
        let latTo = '';
        let lngTo = '';
        if (
          trippet &&
          trippet.FromData &&
          trippet.FromData !== "''" &&
          trippet.FromData !== `""`
        ) {
          if (trippet && JSON.parse(trippet.FromData)) {
            if (trippet && JSON.parse(trippet.FromData) !== '{}') {
              let place = JSON.parse(trippet.FromData);
              latFrom = place.geometry.location.lat;
              lngFrom = place.geometry.location.lng;
            }
          }
        }
        if (
          trippet &&
          trippet.ToData &&
          trippet.ToData !== "''" &&
          trippet.ToData !== `""`
        ) {
          if (trippet && JSON.parse(trippet.ToData)) {
            if (trippet && JSON.parse(trippet.ToData) !== '{}') {
              let place = JSON.parse(trippet.ToData);
              latTo = place.geometry.location.lat;
              lngTo = place.geometry.location.lng;
            }
          }
        }
        if (latFrom && lngFrom && latTo && lngTo) {
          let data = [
            { lat: latFrom, lng: lngFrom },
            { lat: latTo, lng: lngTo },
          ];
          let body = {
            path: data,
            trippet: trippet,
          };
          allPaths.push(body);
        } else {
          if (latTo && lngTo) {
            let color = getTrippetTypeColor(trippet.TrippetTypeLook_ID);
            let data = {
              color: color,
              path: { lat: latTo, lng: lngTo },
              trippet: trippet,
            };
            allMarkers.push(data);
          }
        }
      });
      dispatch(savePaths(allPaths));
      dispatch(saveMarkers(allMarkers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, trippets, trippetTypes]);

  const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 4,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  const renderTransportMarkers =
    paths && paths.length > 0
      ? paths.map(item => {
          // let icon = {
          //   path:
          //     'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
          //   fillColor: '#EA4335',
          //   fillOpacity: 0.9,
          //   anchor: window.google.maps.Point(0, 0),
          //   strokeWeight: 0,
          //   scale: 0.7,
          // };
          return (
            <Fragment key={item.trippet.Trippet_ID}>
              <Polyline path={item.path} options={options} geodesic={true} />
              <Marker
                position={item.path[0]}
                onClick={e => onClickMarker(e, item.trippet, item.path[0])}
              />
              <Marker
                position={item.path[1]}
                onClick={e => onClickMarker(e, item.trippet, item.path[1])}
              />
            </Fragment>
          );
        })
      : null;

  const onClickMarker = (event, trippet, position) => {
    setShowInfoWindow(true);
    let details = {
      title: trippet.Title,
      type: trippet.TrippetTypeLookName,
      place: trippet.ToPlace,
      position: position,
      trippet: trippet,
      FromData: trippet.FromData,
      ToData: trippet.ToData,
    };
    setInfoWindowDetails(details);
  };

  const onCloseClickInfo = () => {
    setShowInfoWindow(false);
    setInfoWindowDetails(null);
  };

  const handleClickMoreInfo = () => {
    let body = {
      fullTrippet: {
        ...infoWindowDetails.trippet,
      },
    };
    dispatch(showTrippetForm(body));
    setShowInfoWindow(false);
    setInfoWindowDetails(null);
  };

  const renderMarkers =
    markers && markers.length
      ? markers.map(marker => {
          let icon = {
            path:
              'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
            fillColor: '#00ff00',
            fillOpacity: 0.9,
            anchor: window.google.maps.Point(0, 0),
            strokeWeight: 0,
            scale: 0.7,
          };
          return (
            <Marker
              key={marker.trippet.Trippet_ID}
              position={marker.path}
              icon={icon}
              onClick={e => onClickMarker(e, marker.trippet, marker.path)}
            />
          );
        })
      : null;

  const infoWindowOptions = {
    maxWidth: '520px',
  };
  const renderInfoWindow =
    showInfoWindow && infoWindowDetails ? (
      <InfoWindow
        position={infoWindowDetails.position}
        onCloseClick={onCloseClickInfo}
        options={infoWindowOptions}
      >
        <div className={classes.infoWindow}>
          <h4>
            <span>{t('Title')}:</span> {infoWindowDetails.title}
          </h4>
          <h4>
            <span>{t('Type')}:</span> {infoWindowDetails.type}
          </h4>
          {JSON.parse(infoWindowDetails.FromData) && (
            <h4>
              <span>{t('From')}:</span>{' '}
              {JSON.parse(infoWindowDetails.FromData) &&
                JSON.parse(infoWindowDetails.FromData).name}
            </h4>
          )}
          {JSON.parse(infoWindowDetails.FromData) && (
            <h4>
              <span>{t('From address')}:</span>{' '}
              {JSON.parse(infoWindowDetails.FromData) &&
                JSON.parse(infoWindowDetails.FromData).formatted_address}
            </h4>
          )}
          {JSON.parse(infoWindowDetails.FromData) &&
            JSON.parse(infoWindowDetails.FromData)
              .international_phone_number && (
              <h4>
                <span>{t('From Phone number')}:</span>{' '}
                {JSON.parse(infoWindowDetails.FromData) &&
                  JSON.parse(infoWindowDetails.FromData)
                    .international_phone_number}
              </h4>
            )}
          <h4>
            <span>{t('To')}:</span>{' '}
            {JSON.parse(infoWindowDetails.ToData) &&
              JSON.parse(infoWindowDetails.ToData).name}
          </h4>
          {JSON.parse(infoWindowDetails.ToData) && (
            <h4>
              <span>{t('To address')}:</span>{' '}
              {JSON.parse(infoWindowDetails.ToData) &&
                JSON.parse(infoWindowDetails.ToData).formatted_address}
            </h4>
          )}
          {JSON.parse(infoWindowDetails.ToData) &&
            JSON.parse(infoWindowDetails.ToData).international_phone_number && (
              <h4>
                <span>{t('To Phone number')}:</span>{' '}
                {JSON.parse(infoWindowDetails.ToData) &&
                  JSON.parse(infoWindowDetails.ToData)
                    .international_phone_number}
              </h4>
            )}
          {infoWindowDetails.trippet.Distance ? (
            <h4>
              <span>{t('Distance')}:</span> {infoWindowDetails.trippet.Distance}
            </h4>
          ) : null}
          <Button
            color="primary"
            className={classes.infoButton}
            onClick={handleClickMoreInfo}
          >
            {t('More information')}
          </Button>
        </div>
      </InfoWindow>
    ) : null;

  return (
    <div className={classes.mapContainer}>
      <GoogleMap
        id="trip-perfect-map"
        mapContainerStyle={{
          height: '650px',
          width: '100%',
        }}
        zoom={zoom}
        center={{
          lat: 48.137154,
          lng: 11.576124,
        }}
      >
        {renderMarkers}
        {renderInfoWindow}
        {renderTransportMarkers}
      </GoogleMap>
    </div>
  );
};

export default Map;
