/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import ActionButton from './ActionButton';
import uniqid from 'uniqid';

const useStyles = makeStyles({
  table: {
    minWidth: 900,
    boxShadow: 'none'
  },
  rowTitle: {
    fontSize: 12,
  },
  rowTitleWidth: {
    maxWidth: 45,
  },
  accepted: {
    color: '#66B66F',
    textTransform: 'uppercase',
    fontSize: 12,
  },
  declined: {
    color: '#E14747',
    textTransform: 'uppercase',
    fontSize: 12,
  },
  pending: {
    color: '#FF981F',
    textTransform: 'uppercase',
    fontSize: 12,
  }
});

function createData(name, status, action, item) {
  return { name, status, action, item };
}

const BuddyRoleManagementTable = ({ users, handleChangeRadio, allRoles, uniqueUsers, setUniqueUsers }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);


  const renderStatus = status => {
    if (status === '1' || status === null || status === '0') {
      return <span className={classes.pending}>Pending</span>
    } else if (status === '2') {
      return <span className={classes.accepted}>Confirmed</span>
    } else if (status === '3') {
      return <span className={classes.declined}>DECLINED</span>
    }
  };

  const overRideUndefinedID = item => {
    let user = {...item};
    if('TripParticipantUser_ID' in user){
      if(!user.TripParticipantUser_ID){
        user.TripParticipantUser_ID = `${user.InviteeEmail}-${user.TripParticipant_ID}`
      }
    }else if('TripOrganizerUser_ID' in user){
      if(!user.TripOrganizerUser_ID){
        user.TripOrganizerUser_ID = `${user.InviteeEmail}-${user.TripOrganizer_ID}`
      }
    }else if('TripProxyUser_ID' in user){
      if(!user.TripProxyUser_ID){
        user.TripProxyUser_ID = `${user.InviteeEmail}-${user.TripProxy_ID}`
      }
    }
    return user;
  }

  const renderRadioButtons = item => {
    if (allRoles && allRoles.length > 0) {
      return allRoles.map(role => {
        let user = overRideUndefinedID(item);
        let userID = user.TripParticipantUser_ID || item.User_ID || user.TripOrganizerUser_ID || user.TripProxyUser_ID;
        return (
          <TableCell align="center" key={role.UserRole_ID}>
            <Checkbox
              checked={users[userID][userID][role.UserRole_ID]}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={handleChangeRadio((user.TripParticipantUser_ID || item.User_ID || user.TripOrganizerUser_ID || user.TripProxyUser_ID), role.UserRole_ID)}
            />
          </TableCell>
        )
      })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (uniqueUsers && uniqueUsers.length > 0) {
      uniqueUsers.forEach(item => {
        elements.push(
          createData(
            item.TripParticipantDisplayName || item.DisplayName || item.TripOrganizerDisplayName || item.TripProxyDisplayName,
            item.ParticipantStatus_ID || item.Status || item.TripProxyAcceptanceStatus || item.TripOrganizerAcceptanceStatus,
            'action',
            item
          ),
        );
      });
      setRows(elements);
    }
  };

  useEffect(() => {
    if (uniqueUsers && uniqueUsers.length > 0) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueUsers]);

  const renderRolesCell = () => {
    if (allRoles && allRoles.length > 0) {
      return allRoles.map(role =>
        <TableCell key={role.UserRole_ID} className={classNames(classes.rowTitle, classes.rowTitleWidth)} align="center">{role.Name}</TableCell>
      )
    }
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table" stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.rowTitle}>Buddies</TableCell>
            {renderRolesCell()}
            <TableCell className={classes.rowTitle} align="left">Status</TableCell>
            <TableCell className={classes.rowTitle} align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={uniqid()}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              {renderRadioButtons(row.item)}
              <TableCell align="left">{renderStatus(row.status)}</TableCell>
              <TableCell align="center">
                <ActionButton user={row} users={users} uniqueUsers={uniqueUsers} setUniqueUsers={setUniqueUsers} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BuddyRoleManagementTable;
