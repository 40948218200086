import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import PeopleIcon from "@material-ui/icons/People";
import BuddyRoleManagement from "../BuddyRoleManagement/BuddyRoleManagement";
import ExternalUserModal from "../ExternalUserModal/ExternalUserModal";
import InternalUserModal from "../InternalUsersModal/InternalUsersModal";
import { useSelector, useDispatch } from "react-redux";
import { changeTrip } from "../../store/actions/actions/trip";
import Tooltip from "@material-ui/core/Tooltip";
import {
  findCreatorInfo,
  renderParticipantsStatusColor,
  findProxyInfo,
  findParticipantsInfo,
  findOrganizerInfo,
  createOrganizer,
  createParticipant,
  createProxy,
} from "./helper";
import classNames from "classnames";
import uniqid from "uniqid";
import {showBuddyTable, hideBuddyTable } from '../../store/actions/actions/root';

const BuddiesModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openExternalUserModal, setOpenExternalUserModal] = useState(false);
  const [openInternalUserModal, setOpenInternalUserModal] = useState(false);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [role, setRole] = useState("");
  const [externalType, setExternalType] = useState("");
  const currentTrip = useSelector(state => state.trip.currentTrip);
  const allUsers = useSelector(state => state.app.allUsers);
  const allUsersRoles = useSelector(state => state.app.allUsersRoles);
  const user = useSelector(state => state.user.user);
  const usedFilters = useSelector(state => state.trip.usedFilters);
  const sortElement = useSelector(state => state.trip.sortElement);
  const sortByDirection = useSelector(state => state.trip.sortByDirection);
  const buddyManagement = useSelector(state => state.app.buddyManagement);
  const [values, setValues] = useState({
    participants: "",
    proxies: "",
    organizers: "",
    externalUsers: "",
  });
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (currentTrip) {
      setValues({
        participants: currentTrip.Participants,
        proxies: currentTrip.Proxies,
        organizers: currentTrip.Organizers,
        externalUsers: "",
      });
      setClicked(true);
    }
  }, [currentTrip, clicked]);

  const handleCloseModal = () => {
    setType("");
    setTitle("");
    setValues({
      participants: "",
      proxies: "",
      organizers: "",
      externalUsers: "",
    });
    setClicked(false);
    handleClose();
  };

  const handleOpenManagementModal = () => {
    dispatch(showBuddyTable());
  };

  const handleCloseManagementModal = () => {
    dispatch(hideBuddyTable());
  };

  const handleOpenExternalUserModal = (type, title, role) => () => {
    setExternalType(type);
    setTitle(title);
    setRole(role);
    setOpenExternalUserModal(true);
  };

  const handleCloseExternalUserModal = () => {
    setExternalType("");
    setTitle("");
    setRole("");
    setOpenExternalUserModal(false);
  };

  const handleOpenInternalUserModal = (type, title, role) => () => {
    setType(type);
    setTitle(title);
    setRole(role);
    setOpenInternalUserModal(true);
  };

  const handleCloseInternalUserModal = () => {
    setType("");
    setTitle("");
    setRole("");
    setOpenInternalUserModal(false);
  };

  const handleSaveExternalUsersEmail = (emails, type) => {
    let participants = [];
    let organizers = [];
    let proxies = [];
    if (type === "Participants") {
      if (emails && emails.length > 0) {
        emails.forEach(email => {
          email.external = true;
          participants.push(email);
        });
      }
    } else if (type === "Organizers") {
      if (emails && emails.length > 0) {
        emails.forEach(email => {
          email.external = true;
          organizers.push(email);
        });
      }
    } else if (type === "Proxies") {
      if (emails && emails.length > 0) {
        emails.forEach(email => {
          email.external = true;
          proxies.push(email);
        });
      }
    }
    setValues({
      ...values,
      participants: [...values.participants, ...participants],
      organizers: [...values.organizers, ...organizers],
      proxies: [...values.proxies, ...proxies],
    });
  };

  const handleSubmit = e => {
    e.stopPropagation();
    const tripParticipants = [];
    const tripOrganizers = [];
    const tripProxies = [];
    values.participants.forEach(participant => {
      if (participant.User_ID === user.User_ID) {
        tripParticipants.push({
          TripParticipantUser_ID:
            participant.TripParticipantUser_ID || participant.User_ID,
          TripParticipantUserRole_ID:
            participant.TripParticipantUserRole_ID ||
            participant.UserRole_ID ||
            "2",
          ParticipantStatus_ID: participant.ParticipantStatus_ID || 2,
          PlanningStatus_ID: participant.PlanningStatus_ID || "",
          InviteMessage: participant.InviteMessage || "",
          InviteeEmail: participant.InviteeEmail || "",
        });
      } else {
        if (isNaN(participant.TripParticipantUser_ID)) {
          participant.TripParticipantUser_ID = "";
        }
        tripParticipants.push({
          TripParticipantUser_ID:
            participant.TripParticipantUser_ID || participant.User_ID,
          TripParticipantUserRole_ID:
            participant.TripParticipantUserRole_ID ||
            participant.UserRole_ID ||
            "2",
          ParticipantStatus_ID: participant.ParticipantStatus_ID || 1,
          PlanningStatus_ID: participant.PlanningStatus_ID || "",
          InviteMessage: participant.InviteMessage || "",
          InviteeEmail: participant.InviteeEmail || "",
        });
      }
    });
    if (values.externalUsers && values.externalUsersEmail.length > 0) {
      values.externalUsersEmail.forEach(email =>
        tripParticipants.push({
          TripParticipantUserRole_ID: 1,
          ParticipantStatus_ID: email.ParticipantStatus_ID || 1,
          PlanningStatus_ID: email.PlanningStatus_ID || "",
          InviteeEmail: email,
        }),
      );
    }

    values.organizers.forEach(organizer => {
      if (organizer.User_ID === user.User_ID) {
        tripOrganizers.push({
          TripOrganizerUser_ID:
            organizer.TripOrganizerUser_ID || organizer.User_ID,
          TripOrganizerUserRole_ID:
            organizer.TripOrganizerUserRole_ID || organizer.UserRole_ID || "3",
          TripOrganizerAcceptanceStatus:
            organizer.TripOrganizerAcceptanceStatus || 2,
          TripOrganizerEnableStatus: organizer.TripOrganizerEnableStatus || "",
          InviteMessage: organizer.InviteMessage || "",
          InviteeEmail: organizer.InviteeEmail || "",
        });
      } else {
        if (isNaN(organizer.TripOrganizerUser_ID)) {
          organizer.TripOrganizerUser_ID = "";
        }
        tripOrganizers.push({
          TripOrganizerUser_ID:
            organizer.TripOrganizerUser_ID || organizer.User_ID,
          TripOrganizerUserRole_ID:
            organizer.TripOrganizerUserRole_ID || organizer.UserRole_ID || "3",
          TripOrganizerAcceptanceStatus:
            organizer.TripOrganizerAcceptanceStatus || 1,
          TripOrganizerEnableStatus: organizer.TripOrganizerEnableStatus || "",
          InviteMessage: organizer.InviteMessage || "",
          InviteeEmail: organizer.InviteeEmail || "",
        });
      }
    });

    values.proxies.forEach(proxy => {
      if (proxy.User_ID === user.User_ID) {
        tripProxies.push({
          TripProxyUser_ID: proxy.TripProxyUser_ID || proxy.User_ID,
          TripProxyUserRole_ID:
            proxy.TripProxyUserRole_ID || proxy.UserRole_ID || "1",
          TripProxyAcceptanceStatus: proxy.TripProxyAcceptanceStatus || 2,
          TripProxyEnableStatus: proxy.TripProxyEnableStatus || "",
          InviteMessage: proxy.InviteMessage || "",
          InviteeEmail: proxy.InviteeEmail || "",
        });
      } else {
        if (isNaN(proxy.TripProxyUser_ID)) {
          proxy.TripProxyUser_ID = "";
        }
        tripProxies.push({
          TripProxyUser_ID: proxy.TripProxyUser_ID || proxy.User_ID,
          TripProxyUserRole_ID:
            proxy.TripProxyUserRole_ID || proxy.UserRole_ID || "1",
          TripProxyAcceptanceStatus: proxy.TripProxyAcceptanceStatus || 1,
          TripProxyEnableStatus: proxy.TripProxyEnableStatus || "",
          InviteMessage: proxy.InviteMessage || "",
          InviteeEmail: proxy.InviteeEmail || "",
        });
      }
    });

    let trip = {
      Trip_ID: currentTrip.Trip_ID,
      Proxies: tripProxies,
      Participants: tripParticipants,
      Organizers: tripOrganizers,
    };

    dispatch(
      changeTrip(
        JSON.stringify(trip),
        user.User_ID,
        sortElement,
        sortByDirection[sortElement],
        usedFilters,
      ),
    );
    handleClose();
  };

  const renderCreator =
    currentTrip && currentTrip.CreatorUser_ID ? (
      <Tooltip
        title={currentTrip.OwnerDisplayName}
        placement="top"
        key={currentTrip.OwnerUser_ID}
        arrow
      >
        <Avatar>
          {findCreatorInfo(allUsers, currentTrip.ownerTrip, classes)}
        </Avatar>
      </Tooltip>
    ) : null;

  const handleSaveParticipants = (selectedUsers, type, role) => {
    selectedUsers.type = type;
    let array = [];
    if (type === "Participants") {
      selectedUsers.forEach(par => {
        let buddy = createParticipant(par, role.UserRole_ID);
        array.push(buddy);
      });
      let tripParticipants = [...values.participants, ...array];
      setValues({
        ...values,
        participants: tripParticipants,
      });
    } else if (type === "Organizers") {
      selectedUsers.forEach(par => {
        let buddy = createOrganizer(par, role.UserRole_ID);
        array.push(buddy);
      });
      let tripOrganizers = [...values.organizers, ...array];
      setValues({
        ...values,
        organizers: tripOrganizers,
      });
    } else if (type === "Proxies") {
      selectedUsers.forEach(par => {
        let buddy = createProxy(par, role.UserRole_ID);
        array.push(buddy);
      });
      let tripProxies = [...values.proxies, ...array];
      setValues({
        ...values,
        proxies: tripProxies,
      });
    }
  };

  const getRoleType = type => {
    if (type === "1") {
      return "Proxies";
    } else if (type === "2") {
      return "Participants";
    } else if (type === "3") {
      return "Organizers";
    }
  };

  const renderBuddies = (type, roleID) => {
    if (type === "1") {
      let list = [];
      if (values.proxies && values.proxies.length > 0) {
        values.proxies.forEach(proxy => {
          if (proxy.TripProxyUserRole_ID === roleID) {
            list.push(proxy);
          }
        });
      }
      return renderProxies(list);
    } else if (type === "2") {
      let list = [];
      if (values.participants && values.participants.length > 0) {
        values.participants.forEach(participant => {
          if (participant.TripParticipantUserRole_ID === roleID) {
            list.push(participant);
          }
        });
      }
      return renderParticipants(list);
    } else if (type === "3") {
      let list = [];
      if (values.organizers && values.organizers.length > 0) {
        values.organizers.forEach(organizer => {
          if (organizer.TripOrganizerUserRole_ID === roleID) {
            list.push(organizer);
          }
        });
      }
      return renderOrganizers(list);
    }
  };

  const renderOrganizers = list => {
    return list.map(organizer => {
      const status =
        organizer.TripOrganizerAcceptanceStatus === undefined
          ? organizer.Status
          : organizer.TripOrganizerAcceptanceStatus;
      const title =
        organizer.TripOrganizerDisplayName === undefined
          ? organizer.DisplayName
          : organizer.TripOrganizerDisplayName;
      return (
        <Tooltip
          title={title}
          placement="top"
          arrow
          key={
            organizer.TripOrganizerUser_ID ||
            organizer.User_ID ||
            organizer.key || uniqid()
          }
        >
          <Avatar
            className={classNames(
              classes.avatar,
              renderParticipantsStatusColor(status, classes),
            )}
          >
            {findOrganizerInfo(allUsers, organizer, classes)}
          </Avatar>
        </Tooltip>
      );
    })
  };

  const renderProxies = list => {
    return list.map(proxy => {
      const status =
        proxy.TripProxyAcceptanceStatus === undefined
          ? proxy.Status
          : proxy.TripProxyAcceptanceStatus;
      const title =
        proxy.TripProxyDisplayName === undefined
          ? proxy.DisplayName
          : proxy.TripProxyDisplayName;
      return (
        <Tooltip
          title={title}
          placement="top"
          arrow
          key={proxy.TripProxyUser_ID || proxy.User_ID || proxy.key || uniqid()}
        >
          <Avatar
            className={classNames(
              classes.avatar,
              renderParticipantsStatusColor(status, classes),
            )}
          >
            {findProxyInfo(allUsers, proxy, classes)}
          </Avatar>
        </Tooltip>
      );
    });
  };

  const renderParticipants = list => {
    return list.map(participant => {
      const status =
        participant.ParticipantStatus_ID === undefined
          ? participant.Status
          : participant.ParticipantStatus_ID;
      const title =
        participant.TripParticipantDisplayName === undefined
          ? participant.DisplayName
          : participant.TripParticipantDisplayName;
      return (
        <Tooltip
          title={title}
          placement="top"
          arrow
          key={
            participant.TripParticipantUser_ID ||
            participant.User_ID ||
            participant.key ||
            uniqid()
          }
        >
          <Avatar
            className={classNames(
              classes.avatar,
              renderParticipantsStatusColor(status, classes),
            )}
          >
            {findParticipantsInfo(allUsers, participant, classes)}
          </Avatar>
        </Tooltip>
      );
    });
  };

  const renderBuddiesRoles =
    allUsersRoles && allUsersRoles.length > 0
      ? allUsersRoles.map(role => (
          <Grid key={role.UserRole_ID} item xs={12} className={classes.buddy}>
            <div className={classes.buddyLeft}>
              <Button
                className={classes.addButton}
                color="primary"
                variant="contained"
                onClick={handleOpenInternalUserModal(
                  getRoleType(role.Type),
                  role.Name,
                  role,
                )}
              >
                <AddIcon />
              </Button>
              <div className={classes.title}>{t(role.Name)}</div>
              <AvatarGroup max={9}>
                {renderBuddies(role.Type, role.UserRole_ID)}
              </AvatarGroup>
            </div>
            <Button
              className={classes.externalButton}
              color="primary"
              onClick={handleOpenExternalUserModal(
                getRoleType(role.Type),
                role.Name,
                role,
              )}
            >
              <AddIcon />
              {t("New users as")}
              {` ${role.Name}`}
            </Button>
          </Grid>
        ))
      : null;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.container}
      >
        <div className={classes.modalTopContainer}>
          <DialogTitle id="alert-dialog-title">
            {t("Add Trip Buddies")}
          </DialogTitle>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.modalContentContainer}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.creator}>
                <div>{t("Trip Creator")}</div>
                <div className={classes.avatar}>{renderCreator}</div>
              </div>
            </Grid>
            {renderBuddiesRoles}
            <Grid item xs={12} className={classes.section}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleOpenManagementModal}
              >
                <PeopleIcon /> {t("Buddy role management")}
              </Button>
              <div>{t("(Including deletion)")}</div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {t("Exit")}
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            {t("Save and Share")}
          </Button>
        </DialogActions>
      </Dialog>
      <BuddyRoleManagement
        open={buddyManagement}
        handleClose={handleCloseManagementModal}
        participants={values.participants}
        organizers={values.organizers}
        proxies={values.proxies}
        values={values}
        setValues={setValues}
      />
      <ExternalUserModal
        open={openExternalUserModal}
        handleClose={handleCloseExternalUserModal}
        handleSave={handleSaveExternalUsersEmail}
        type={externalType}
        title={title}
        trip={currentTrip}
        role={role}
      />
      <InternalUserModal
        open={openInternalUserModal}
        handleClose={handleCloseInternalUserModal}
        type={type}
        allUsers={allUsers}
        participants={values.participants}
        organizers={values.organizers}
        proxies={values.proxies}
        handleSaveParticipants={handleSaveParticipants}
        title={title}
        role={role}
      />
    </div>
  );
};

export default BuddiesModal;
