import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
// use the same styles as end user section
import useStyles from "../Navigation/styles";
import useAdminStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LanguageIcon from "@material-ui/icons/Language";
import FlagIcon from "@material-ui/icons/Flag";
import MoneyIcon from "@material-ui/icons/Money";
import Badge from "@material-ui/core/Badge";
import { useSelector, useDispatch } from "react-redux";
import { getAllLanguages } from "../../store/actions/actions/language";
import { getAllCountries } from "../../store/actions/actions/country";
import { getAllCurrencies } from "../../store/actions/actions/currency";
import { getAllPlanningStatuses } from "../../store/actions/actions/planningStatus";
import { getAllParticipantStatuses } from "../../store/actions/actions/participantStatus";
import { getAllTrippetTypeCategories } from "../../store/actions/actions/trippetTypeCategory";
import { getAllTrippetTypeLooks } from "../../store/actions/actions/trippetTypeLook";
import { getAllSettingCategories } from "../../store/actions/actions/settingCategory";
import { getAllSettingItems } from "../../store/actions/actions/settingItem";
import { getAllPreferenceCategories } from "../../store/actions/actions/preferenceCategory";
import { getAllPreferenceItems } from "../../store/actions/actions/preferenceItem";
import { getAllDepartments } from "../../store/actions/actions/department";
import { getAllAdminRoles } from "../../store/actions/actions/adminRole";
import { getAllUserRoles } from "../../store/actions/actions/userRole";
import { getAllUsers } from "../../store/actions/actions/user";
import { getAllVerificationCodes } from "../../store/actions/actions/verificationCode";
import { getAllConfigValues } from "../../store/actions/actions/config";
import { getEntireTrips } from "../../store/actions/actions/trip";
import * as userAccess from "../../userAccess";

const AdminNavigation = props => {
  const { open, handleDrawerClose, handleDrawerOpen } = props;
  const classes = useStyles();
  const adminClasses = useAdminStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.user.user);

  const handleNavigation = open ? (
    <div className={classes.navigationButton}>
      <IconButton onClick={handleDrawerClose}>
        <ChevronLeftIcon />
      </IconButton>
    </div>
  ) : (
    <div className={classes.navigationButton}>
      <IconButton onClick={handleDrawerOpen}>
        <ChevronRightIcon />
      </IconButton>
    </div>
  );

  const handleLanguages = () => {
    dispatch(getAllLanguages(null));
    history.push("/languages");
  };
  const handleConfig = () => {
    dispatch(getAllConfigValues(null));
    history.push("/config");
  };
  const handleCountries = () => {
    dispatch(getAllCountries(null));
    history.push("/countries");
  };
  const handleCurrencies = () => {
    dispatch(getAllCurrencies(null));
    history.push("/currencies");
  };
  const handlePlanningStatuses = () => {
    dispatch(getAllPlanningStatuses(null));
    dispatch(getEntireTrips(null));
    history.push("/planningstatuses");
  };
  const handleParticipantStatuses = () => {
    dispatch(getAllParticipantStatuses(null));
    history.push("/participantstatuses");
  };
  const handleTrippetTypeCategories = () => {
    dispatch(getAllTrippetTypeCategories(null));
    dispatch(getEntireTrips(null));
    history.push("/trippettypecategories");
  };
  const handleTrippetTypeLooks = () => {
    dispatch(getAllTrippetTypeLooks(null));
    history.push("/trippettypelooks");
  };
  const handleSettingCategories = () => {
    dispatch(getAllSettingCategories(null));
    history.push("/settingcategories");
  };
  const handleSettingItems = () => {
    dispatch(getAllSettingItems(null));
    history.push("/settingitems");
  };
  const handlePreferenceCategories = () => {
    dispatch(getAllPreferenceCategories(null));
    history.push("/preferencecategories");
  };
  const handlePreferenceItems = () => {
    dispatch(getAllPreferenceItems(null));
    history.push("/preferenceitems");
  };
  const handleDepartments = () => {
    dispatch(getAllDepartments(null));
    history.push("/departments");
  };
  const handleAdminRoles = () => {
    dispatch(getAllAdminRoles(null));
    history.push("/adminroles");
  };
  const handleUserRoles = () => {
    dispatch(getAllUserRoles(null));
    history.push("/userroles");
  };
  const handleUsers = () => {
    dispatch(getAllUsers(null));
    history.push("/users");
  };
  const handleVerificationCodes = () => {
    dispatch(getAllVerificationCodes(null));
    history.push("/verificationcodes");
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [adminClasses.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar} />

      <Divider />
      <List>
        <ListItem className={classes.navigationArrow}>
          {handleNavigation}
        </ListItem>
        {userAccess.canRead(user, userAccess.Table_Languages) ? (
          <ListItem button onClick={handleLanguages}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <LanguageIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("Languages")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
        {userAccess.canRead(user, userAccess.Table_Config) ? (
          <ListItem button onClick={handleConfig}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <LanguageIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("Config")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
        {userAccess.canRead(user, userAccess.Table_Countries) ? (
          <ListItem button onClick={handleCountries}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <FlagIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("Countries")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
        {userAccess.canRead(user, userAccess.Table_Currencies) ? (
          <ListItem button onClick={handleCurrencies}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <MoneyIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("Currencies")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
        {userAccess.canRead(user, userAccess.Table_PlanningStatuses) ? (
          <ListItem button onClick={handlePlanningStatuses}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <BeachAccessIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("Planning Status")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
        {userAccess.canRead(user, userAccess.Table_ParticipantStatuses) ? (
          <ListItem button onClick={handleParticipantStatuses}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <BeachAccessIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("Participant Status")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
      </List>
      {userAccess.canRead(user, userAccess.Table_TrippetTypes) ? (
        <div>
          <Divider />
          <List>
            <ListItem button onClick={handleTrippetTypeCategories}>
              <ListItemIcon>
                <Badge badgeContent={0} color="secondary">
                  <FlagIcon className={classes.blueIcon} />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={t("Trippet Type Categories")}
                className={adminClasses.languagesTitle}
              />
            </ListItem>
            <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
            <ListItem button onClick={handleTrippetTypeLooks}>
              <ListItemIcon>
                <Badge badgeContent={0} color="secondary">
                  <FlagIcon className={classes.blueIcon} />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={t("Trippet Type Looks")}
                className={adminClasses.languagesTitle}
              />
            </ListItem>
            </Hidden>
          </List>
        </div>
      ) : null}
      {userAccess.canRead(user, userAccess.Table_Settings) ? (
        <div>
          <List>
            <ListItem button onClick={handleSettingCategories}>
              <ListItemIcon>
                <Badge badgeContent={0} color="secondary">
                  <FlagIcon className={classes.blueIcon} />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={t("Setting Categories")}
                className={adminClasses.languagesTitle}
              />
            </ListItem>
            <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
            <ListItem button onClick={handleSettingItems}>
              <ListItemIcon>
                <Badge badgeContent={0} color="secondary">
                  <FlagIcon className={classes.blueIcon} />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={t("Setting Items")}
                className={adminClasses.languagesTitle}
              />
            </ListItem>
            </Hidden>
          </List>
        </div>
      ) : null}
      {userAccess.canRead(user, userAccess.Table_Preferences) ? (
        <div>
          <List>
            <ListItem button onClick={handlePreferenceCategories}>
              <ListItemIcon>
                <Badge badgeContent={0} color="secondary">
                  <FlagIcon className={classes.blueIcon} />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={t("Preference Categories")}
                className={adminClasses.languagesTitle}
              />
            </ListItem>
            <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
            <ListItem button onClick={handlePreferenceItems}>
              <ListItemIcon>
                <Badge badgeContent={0} color="secondary">
                  <FlagIcon className={classes.blueIcon} />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={t("Preference Items")}
                className={adminClasses.languagesTitle}
              />
            </ListItem>
            </Hidden>
          </List>
        </div>
      ) : null}
      <Divider />
      <List>
        {userAccess.canRead(user, userAccess.Table_Departments) ? (
          <ListItem button onClick={handleDepartments}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <FlagIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("Departments")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
        {userAccess.canRead(user, userAccess.Table_AdminRoles) ? (
          <ListItem button onClick={handleAdminRoles}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <FlagIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("Admin Roles")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
        {userAccess.canRead(user, userAccess.Table_UserRoles) ? (
          <ListItem button onClick={handleUserRoles}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <FlagIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("User Roles")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
        {userAccess.canRead(user, userAccess.Table_Users) ? (
          <ListItem button onClick={handleUsers}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <FlagIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("Users")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
        {userAccess.canRead(user, userAccess.Table_VerificationCodes) ? (
          <ListItem button onClick={handleVerificationCodes}>
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <FlagIcon className={classes.blueIcon} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={t("Verification Codes")}
              className={adminClasses.languagesTitle}
            />
          </ListItem>
        ) : null}
      </List>
      <Divider />
    </Drawer>
  );
};

export default AdminNavigation;
