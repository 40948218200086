import React, { useRef, Fragment, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import EuroIcon from "@material-ui/icons/Euro";
import LockIcon from "@material-ui/icons/Lock";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import classNames from "classnames";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import CameraAltRoundedIcon from "@material-ui/icons/CameraAltRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import PictureSlider from "../PictureSlider/PictureSlider";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import uniqid from "uniqid";
import FormHelperText from '@material-ui/core/FormHelperText';

const BigForm = ({
  classes,
  t,
  values,
  handleInputs,
  handleUploadDocument,
  setValues,
  renderPrivateDocumentMessage,
  renderPublicDocumentMessage,
  renderPublicDocumentName,
  renderPersonalDocumentName,
  personalFileURL,
  allPublicDocuments,
  notes,
  handleNotes,
  message,
  handleChatMessage,
  typing,
  typingUser,
  messages,
  renderMessages,
  isEditMode,
  getUploadParams,
  handleChangeStatus,
  handleChangeStatusPublicDocument,
  handleUploadPictureModalOpen,
  renderImage,
  allTrippetPictures,
  deleteTrippetPicture,
  selectedImage,
  handlePreviewModalImageModalOpen,
  descriptionError,
  getLinkValue
}) => {
  const chatRef = useRef(null);

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatRef && chatRef.current) {
        chatRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
    scrollToBottom();
  }, [messages]);

  const dropZoneStyles = {
    previewContainer: {
      padding: "0 10px",
    },
  };

  return (
    <Fragment>
      <Grid
        item
        xs={12}
        md={9}
        container
        direction="row"
        alignItems="center"
        className={classes.bigFormLeft}
      >
        <Grid item xs={12} md={5}>
          <Grid container item xs={12} md={12}>
            <Grid container item xs={12} justify="center">
              <div
                className={classes.previewContainer}
                onClick={handlePreviewModalImageModalOpen}
              >
                <div>
                  {renderImage}
                  <div className={classes.deleteIcon}>
                    <IconButton
                      onClick={deleteTrippetPicture}
                      disabled={!selectedImage}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid container item xs={12} md={12} alignItems="center">
              <PictureSlider pictures={allTrippetPictures} />
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.containerInputs}
            direction="row"
            justify="space-around"
          >
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("Currency")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label={t("Currency")}
                  name="currency"
                  value={values.currency}
                  onChange={handleInputs}
                >
                  <MenuItem value="euro">{t("Euro")}</MenuItem>
                  <MenuItem value="usd">{t("USD")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                name="price"
                type="number"
                step=".01"
                value={values.price}
                className={classes.inputAdornment}
                placeholder={t("Price")}
                onChange={handleInputs}
                label={t("Price")}
                id="priceId"
                InputProps={{
                  startAdornment:
                    values.currency === "euro" ? (
                      <EuroIcon />
                    ) : (
                      <AttachMoneyIcon />
                    ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <div
                className={classes.cameraIcon}
                onClick={handleUploadPictureModalOpen}
              >
                <CameraAltRoundedIcon />
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.containerInputs} direction="row">
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                name="link"
                value={values.link}
                placeholder={t("Link")}
                onChange={handleInputs}
                className={classes.inputAdornment}
                label={t("Link")}
                id="linkId"
                InputProps={{
                  startAdornment: (
                    <Tooltip
                      title={t("Click here to open the link")}
                      placement="top"
                      arrow
                    >
                      {values.link ? (
                        <a
                          href={`//${getLinkValue(values.link)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e => e.stopPropagation()}
                        >
                          <LinkIcon
                            className={values.link ? classes.linkIcon : ""}
                          />
                        </a>
                      ) : (
                        <LinkIcon />
                      )}
                    </Tooltip>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {/* <Grid container className={classes.containerInputs}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                multiline
                fullWidth
                rowsMax={6}
                rows={6}
                name="description"
                value={values.description}
                className={classes.inputAdornment}
                placeholder={t("Description")}
                onChange={handleInputs}
                label={t("Description")}
                id="descriptionId"
              />
            </Grid>
            <FormHelperText>{values.description.length} / 1500</FormHelperText>
            <Grid item xs={12}>
            {descriptionError && <FormHelperText className={classes.errorText}>{t('Description is too long')}</FormHelperText>}
            </Grid>
          </Grid> */}
        </Grid>
        <Grid item xs={12} md={7} className={classes.messagesContainer}>
          {isEditMode ? (
            <div className={classes.allMessages}>
              <div className={classes.messages}>
                {renderMessages()}
                <div ref={chatRef} />
              </div>
              <div className={classes.chatMessage}>
                {typing && typingUser ? (
                  <div
                    className={classNames(
                      classes.message,
                      classes.leftMessage,
                      classes.typing,
                    )}
                  >
                    <h3>{typingUser} is typing...</h3>
                  </div>
                ) : null}
                <TextField
                  variant="outlined"
                  fullWidth
                  name="chatMessage"
                  value={message}
                  placeholder={t("Type a message ...")}
                  onChange={handleChatMessage}
                  onKeyPress={event =>
                    event.key === "Enter" && handleChatMessage(event)
                  }
                  label={t("Message")}
                  id="chatMessageId"
                />
              </div>
            </div>
          ) : (
            <div className={classes.allMessagesPlaceholder}>
              <div>{t('Chatbox appears after save and share')}</div>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
        <Grid container className={classes.containerInputs}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                multiline
                fullWidth
                rowsMax={6}
                rows={6}
                name="description"
                value={values.description}
                className={classes.inputAdornment}
                placeholder={t("Description")}
                onChange={handleInputs}
                label={t("Description")}
                id="descriptionId"
              />
            </Grid>
            <FormHelperText>{values.description.length} / 1500</FormHelperText>
            <Grid item xs={12}>
            {descriptionError && <FormHelperText className={classes.errorText}>{t('Description is too long')}</FormHelperText>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        container
        direction="column"
        alignItems="center"
        className={classes.bigFormRight}
      >
        <Grid item xs={12} container direction="row" alignItems="center">
          <Grid item xs={12}>
            <h3>{t("Docs (e.g Booking confirmation)")}</h3>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} className={classes.dropZoneDocument}>
              <Tooltip
                title={t("Allowed file types are pdf, docs, txt, xlsx.")}
                placement="top"
                arrow
              >
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
              <Dropzone
                getUploadParams={getUploadParams}
                classNames={dropZoneStyles}
                addClassNames={classes.dropZoneDocument}
                onChangeStatus={handleChangeStatusPublicDocument}
                accept="application/zip/*,application/pdf/*,text/plain/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document/*,application/msword/*,application/vnd.ms-excel/*,"
                inputContent={(_files, extra) => {
                  return extra.reject ? (
                    t("ZIP, PDF, TXT, DOCS, XLSX files only")
                  ) : (
                    <span className={classes.dropZoneLabel} key={uniqid()}>
                      {t("Upload or Drag Files, images are not allowed")}
                    </span>
                  );
                }}
                styles={{
                  dropzoneReject: {
                    borderColor: "red",
                    backgroundColor: "#DAA",
                  },
                  inputLabel: (_files, extra) =>
                    extra.reject ? { color: "red" } : {},
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <h4>
                {allPublicDocuments
                  ? renderPublicDocumentName
                  : t("Document name")}
              </h4>
            </Grid>
            {renderPublicDocumentMessage}
          </Grid>
        </Grid>
        <Grid item xs={12} container direction="row" alignItems="center">
          <Grid item xs={12}>
            <h3 className={classes.personalAreaTitle}>
              {t("Personal area - not shared")} <LockIcon />
            </h3>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              {/* <div className={classes.uploadDocument}>
                <LockIcon /> <span>{t('Upload Document')}</span>
              </div> */}
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12} className={classes.dropZoneDocument}>
              <Tooltip
                title={t("Allowed file types are pdf, docs, txt, xlsx.")}
                placement="top"
                arrow
              >
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
              <Dropzone
                getUploadParams={getUploadParams}
                classNames={dropZoneStyles}
                addClassNames={classes.dropZoneDocument}
                onChangeStatus={handleChangeStatus}
                accept="application/zip/*,application/pdf/*,text/plain/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document/*,application/msword/*,application/vnd.ms-excel/*"
                inputContent={(_files, extra) =>
                  extra.reject ? (
                    t("ZIP, PDF, TXT, DOCS, XLSX files only")
                  ) : (
                    <span className={classes.dropZoneLabel} key={uniqid()}>
                      {t("Upload or Drag Files, images are not allowed")}
                    </span>
                  )
                }
                styles={{
                  dropzoneReject: {
                    borderColor: "red",
                    backgroundColor: "#DAA",
                  },
                  inputLabel: (_files, extra) =>
                    extra.reject ? { color: "red" } : {},
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <h4>
                {personalFileURL
                  ? renderPersonalDocumentName
                  : t("Document name")}
              </h4>
            </Grid>
            {renderPrivateDocumentMessage}
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <h3 className={classes.personalAreaTitle}>
                {t("Notes")} <LockIcon />
              </h3>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                multiline
                fullWidth
                rowsMax={4}
                name="notes"
                value={notes}
                className={classes.inputAdornment}
                placeholder={t("Notes")}
                onChange={handleNotes}
                label={t("Notes")}
                id="notesId"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default BigForm;
