import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  addTripetButtonText: {
    transition: 'all .7s',
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 170,
  },
  addTrippetButton: {
    marginBottom: 15,
    width: 30,
    transition: 'all .7s',
    '&:hover': {
      width: 220,
    },
  },
  trippetTableContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center ',
  },
  rootPaper: {
    width: '100%',
  },
  container: {
    maxHeight: 770,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  // tableHeader: {
  //   position: 'relative',
  // },
  // filterButton: {
  //   position: 'absolute',
  //   right: 0,
  //   top: 4,
  // },
  tableHeaderCell: {
    fontWeight: 'bold !important',
  },
  rowContainer: {
    cursor: 'pointer',
  },
  tableCell: {
    border: '1px solid #aeaeae',
    borderLeft: `5px solid transparent`,
  },
  avatar: {
    width: 41,
    height: 41,
    fontSize: 14,
  },
  avatarOwner: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 41,
    height: 41,
    fontSize: 14,
  },
  avatarGroup: {
    minHeight: 35,
    position: 'relative',
    zIndex: 0,
    '& > div': {
      width: 41,
      height: 41,
      fontSize: 14,
    },
  },
  blue: {
    borderLeft: `5px solid ${theme.palette.freshAir[100]}`,
  },
  orange: {
    borderLeft: `5px solid ${theme.palette.bananaMania[100]}`,
  },
  green: {
    borderLeft: `5px solid ${theme.palette.greenPowder[100]}`,
  },
  dotsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dots: {
    display: 'flex',
    margin: '0 0 15px 0',
  },
  dot: {
    width: 12,
    height: 12,
    border: `1px solid #0E84B8`,
    borderRadius: '50%',
    margin: '0 5px',
    cursor: 'pointer',
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: '#0E84B8',
    },
  },
  checked: {
    backgroundColor: '#0E84B8',
  },
  accept: {
    color: '#42ce23',
  },
  decline: {
    color: '#ef2828',
  },
  accepted: {
    border: `4px solid #579e38 !important`,
  },
  pending: {
    border: `4px solid ${theme.palette.electricBlue[100]} !important`,
  },
  rejected: {
    border: `4px solid #cc3333 !important`,
  },
  filtersContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  filterContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
  },
  filterSelect: {
    width: 150,
    marginRight: 150,
  },
  deleteIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
  },
  tableFilterItem: {
    width: 10,
  },
  activeFilter: {
    color: '#009bfc',
  },
  avatarImage: {
    width: '100%',
    height: '100%',
  },
  infoIcon: {
    fontSize: 14,
    cursor: 'help'
  },
  activeArrow: {
    "& > svg": {
      color: '#009bfc',
    }
  },
  tableHeaderCellActive: {
    fontWeight: 'bold !important',
    "& > span > svg":{
      color: '#009bfc !important',
    }
  },
  trippetTypeName: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    "& > span": {
      paddingRight: 4,
    },
    "& > img": {
      width: 25,
      paddingRight: 4,
    }
  },
  dndIcon: {
    color: '#8e8989',
  },
  saveOrder: {
    color: '#14AF9D',
  }
}));

export default useStyles;
