import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '15px 0',
    textAlign: 'center',
  },
  containerMyTrip: {
    paddingBottom: 50,
  },
  rootPaper: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  space: {
    margin: '15px 0',
  },
  accepted: {
    border: `4px solid #579e38 !important`,
  },
  pending: {
    border: `4px solid ${theme.palette.electricBlue[100]} !important`,
  },
  rejected: {
    border: `4px solid #cc3333 !important`,
  },
  tableHeaderCell: {
    fontWeight: 'bold !important',
    zIndex: 3,
  },
  rowContainer: {
    cursor: 'pointer',
  },
  tableCell: {
    border: '1px solid #aeaeae',
    borderLeft: `5px solid transparent`,
  },
  avatar: {
    width: 41,
    height: 41,
    fontSize: 14,
  },
  avatarGroup: {
    '& > div': {
      width: 41,
      height: 41,
      fontSize: 14,
    },
  },
  backToCockpit: {
    '& > a': {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      color: '#000000',
      textDecoration: 'none',
      fontWeight: 'normal',
      '& > svg': {
        fontSize: 32,
      },
      '& > h2': {
        paddingLeft: 10,
      },
    },
  },
  activeButton: {
    color: theme.palette.electricBlue[100],
  },
  activeFilter: {
    color: '#009bfc',
  },
  avatarImage: {
    height: '100%',
    width: '100%',
  },
  tableHeaderCellActive: {
    fontWeight: 'bold !important',
    "& > span > svg":{
      color: '#009bfc !important',
    }
  },
}));

export default useStyles;
