// Admin roles and role accesses
export const Table_AdminRoles = 10;
// User roles and allows
export const Table_UserRoles = 20;
export const Table_Users = 30;
// all setting related tables (setting categories, items)
export const Table_Settings = 40;
// all preferences related tables (categories, items)
export const Table_Preferences = 50;
export const Table_Countries = 60;
export const Table_Languages = 70;
export const Table_Departments = 80;
export const Table_PlanningStatuses = 90;
export const Table_Trips = 100;
export const Table_Trippets = 110;
export const Table_TripParticipents = 120;
export const Table_TrippetParticipents = 130;
export const Table_Currencies = 140;
export const Table_ParticipantStatuses = 150;
export const Table_TrippetTypes = 160;
export const Table_VerificationCodes = 170;
export const Table_Config = 180;

export function hasAdminAccess(user){
    if (!user)
        return false;
    if (!user.AdminRoleAccesses)
        return false;
    for (var i = 0; i < user.AdminRoleAccesses.length; i++)
        if (user.AdminRoleAccesses[i].Read && Number(user.AdminRoleAccesses[i].Read) === 1)
            return true;
    return false;
}

// user: current loggedin user
// table: one of refTables
export function canRead(user, table){
    if (!user)
        return false;
    if (!user.AdminRoleAccesses)
        return false;
    for (var i = 0; i < user.AdminRoleAccesses.length; i++)
        if (Number(user.AdminRoleAccesses[i].RefTable) === table)
            return user.AdminRoleAccesses[i].Read && Number(user.AdminRoleAccesses[i].Read) === 1;
    return false;
}

export function canWrite(user, table){
    if (!user)
        return false;
    if (!user.AdminRoleAccesses)
        return false;
    for (var i = 0; i < user.AdminRoleAccesses.length; i++)
        if (Number(user.AdminRoleAccesses[i].RefTable) === table)
            return user.AdminRoleAccesses[i].Write && Number(user.AdminRoleAccesses[i].Write) === 1;
    return false;
}

export function canChange(user, table){
    if (!user)
        return false;
    if (!user.AdminRoleAccesses)
        return false;
    for (var i = 0; i < user.AdminRoleAccesses.length; i++)
        if (Number(user.AdminRoleAccesses[i].RefTable) === table)
            return user.AdminRoleAccesses[i].Change && Number(user.AdminRoleAccesses[i].Change) === 1;
    return false;
}

export function canDelete(user, table){
    if (!user)
        return false;
    if (!user.AdminRoleAccesses)
        return false;
    for (var i = 0; i < user.AdminRoleAccesses.length; i++)
        if (Number(user.AdminRoleAccesses[i].RefTable) === table)
            return user.AdminRoleAccesses[i].Delete && Number(user.AdminRoleAccesses[i].Delete) === 1;
    return false;
}