import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    top: '-27px !important',
    left: '-8px !important',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    '& > button': {
      width: 40,
      height: 40,
      backgroundColor: '#0E84B8',
    },
  },
}));

const TripRecommendationButton = ({ data, type }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = action => event => {
    if (action === 'accept') {
      //accept
    } else if (action === 'decline') {
      //decline
    }
  };

  const actions = [
    { icon: <CheckIcon />, name: t('Copy to My Trips'), action: 'accept' },
    { icon: <CloseIcon />, name: t('Delete'), action: 'decline' },
  ];

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="right"
        onClick={event => event.stopPropagation()}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement="top"
            onClick={handleClick(action.action)}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default TripRecommendationButton;
