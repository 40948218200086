import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import AdminNavigation from '../../components/AdminNavigation/AdminNavigation';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Logo from '../../assets/TP-logo-250px.png';
import useStyles from '../withDashboard/styles';
import { Link } from 'react-router-dom';
import NavigationMenu from '../../components/NavigationMenu/NavigationMenu';
import IconButton from '@material-ui/core/IconButton';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { BASE_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import { getUserNotification } from '../../store/actions/actions/notifications';
import {
  getCustomerLogo,
} from "../../store/actions/actions/root";

const withAdminDashboard = Component => props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationSeen = useSelector(
    state => state.notification.seen,
  );
  const interval = useSelector(state => state.notification.interval);
  const notificationTripName = useSelector(
    state => state.notification.tripName,
  );
  const notificationTrippetName = useSelector(
    state => state.notification.trippetName,
  );
  const notificationCreator = useSelector(
    state => state.notification.creator,
  );
  const notificationStartDate = useSelector(
    state => state.notification.startDate,
  );
  const notificationEndDate = useSelector(
    state => state.notification.endDate,
  );
  const customerLogo = useSelector(state => state.app.customerLogo);
  const user = useSelector(state => state.user.user);
  const profileImage = useSelector(state => state.user.user.Picture);
  const profilePictureURL = useSelector(
    state => state.profile.profilePictureURL,
  );
  const allNotifications = useSelector(state => state.notification.notifications);
  const [nonSeenNotification, setNonSeenNotification] = useState(0);
  const [customerLogoUrl, setCustomerLogoUrl] = useState(null);

  useEffect(() => {
    if (allNotifications && allNotifications.length > 0) {
      let number = 0;
      allNotifications.forEach(notification => {
        if (notification.Seen === '0') {
          number = number + 1;
        }
      })
      setNonSeenNotification(number);
    }
  }, [allNotifications]);

  useEffect(() => {
    if (user && interval) {
      let body = {
        User_ID: user.User_ID,
        OrderBy: "LogDate",
        OrderDir: 'desc',
        Seen: notificationSeen,
        Trip_ID: notificationTripName,
        Trippet_ID: notificationTrippetName,
        LoggerUser_ID: notificationCreator,
        LogDateStart: notificationStartDate,
        LogDateEnd: notificationEndDate,
      };
      dispatch(getUserNotification(JSON.stringify(body)));
    }
  }, [user, interval, dispatch, notificationSeen, notificationTrippetName, notificationCreator, notificationStartDate, notificationTripName, notificationEndDate]);

  useEffect(() => {
    if (user && interval) {
      let id = setInterval(() => {
        let body = {
          User_ID: user.User_ID,
          OrderBy: "LogDate",
          OrderDir: 'desc',
          Seen: notificationSeen,
          Trip_ID: notificationTripName,
          Trippet_ID: notificationTrippetName,
          LoggerUser_ID: notificationCreator,
          LogDateStart: notificationStartDate,
          LogDateEnd: notificationEndDate,
        };
        dispatch(getUserNotification(JSON.stringify(body)));
      }, interval);
      return () => clearInterval(id);
    }
  }, [user, interval, dispatch, notificationSeen, notificationTrippetName, notificationCreator, notificationStartDate, notificationTripName, notificationEndDate]);

  useEffect(() => {
    if (user) {
      dispatch(getCustomerLogo());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (
      customerLogo &&
      customerLogo.Values &&
      customerLogo.Values.length > 0 &&
      user
    ) {
      customerLogo.Values.forEach(logo => {
        if (logo.Language_ID === user.Language_ID) {
          let path = `${BASE_URL}${logo.Value.substr(1)}`
          setCustomerLogoUrl(path);
        }
      });
    }
  }, [customerLogo, user]);

  const handleClickDownDropDownMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDownDropDownMenu = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickNotification = () => {
    history.push('/notifications')
  }

  const userAvatar = user ? `${user.Initials}` : <PersonRoundedIcon />;

  const renderProfilePicture =
    profileImage && profileImage ? (
      <img
        src={profileImage}
        alt="profile"
        className={classes.profilePicture}
      />
    ) : profilePictureURL ? (
      <img
        src={`${BASE_URL}${profilePictureURL}`}
        alt="profile"
        className={classes.profilePicture}
      />
    ) : (
          userAvatar
        );

  const renderUserName = user ? `${user.FirstName} ${user.LastName}` : '';

  const renderCustomerLogo = customerLogoUrl ?  <img src={customerLogoUrl} alt="Customer Logo" /> : null;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavigationMenu
        anchorEl={anchorEl}
        handleClose={handleCloseDownDropDownMenu}
      />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <Link to="/" className={classes.logo}>
            <img src={Logo} alt="Trip Perfect Logo" />
          </Link>
          <div className={classes.userInfo}>
          <div className={classes.customerLogo}>
          {renderCustomerLogo}
            </div>
          <div className={classes.notification}>
              <IconButton onClick={handleClickNotification}>
                <Badge badgeContent={nonSeenNotification} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </div>
            <IconButton onClick={handleClickDownDropDownMenu}>
              <Avatar aria-controls="simple-menu" aria-haspopup="true">
                {renderProfilePicture}
              </Avatar>
            </IconButton>
            <span>{renderUserName}</span>
          </div>
        </Toolbar>
      </AppBar>
      <AdminNavigation
        open={open}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Component {...props} />
      </main>
    </div>
  );
};

export default withAdminDashboard;
