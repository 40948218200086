import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    '& > div > div': {
      maxWidth: 750,
    }
  },
  modalTopContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 12px 18px -19px rgba(0,0,0,0.75)',
    marginBottom: 15,
    "& > button":{
      marginRight: 5,
    }
  },
  modalContentContainer: {
    minWidth: 650,
    marginBottom: 15,
    paddingBottom: 10,
    boxShadow: '0px 12px 18px -19px rgba(0,0,0,0.75)',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      fontSize: 14,
      width: 35,
      height: 35,
      marginLeft: 10,
    },
  },
  avatarImage: {
    width: '100%',
    height: '100%',
  },
  creator: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    borderBottom: '1px solid #aeaeae',
    paddingBottom: 10,
  },
  buddy: {
    borderBottom: '1px solid #aeaeae',
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarSize: {
    fontSize: 14,
  },
  buddyLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    height: 40,
    minWidth: 40,
    width: 40,
    borderRadius: '50%',
    marginRight: 15,
  },
  externalButton: {
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    "& > span > svg":{
      fontSize: 14,
    }
  },
  section: {
    boxShadow: '0px 12px 18px -19px rgba(0,0,0,0.75)',
    paddingBottom: 30,
    paddingTop: 30,
    textAlign: 'center',
    "& > button > span > svg": {
      marginRight: 10,
    },
    "& > div": {
    marginTop: 10,
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#C3C3C3'
    }
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: theme.palette.electricBlue[100],
  },
  user: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 20,

  },
  inputs: {
    "& > div ": {
      minHeight: 100
    },
  },
}));

export default useStyles;