import * as actionTypes from '../actionTypes/actionTypes';
import { showSnackBar } from './root';
import { BASE_URL } from '../../../config';
import {
  clearAllTrippet,
  getAllTrippetsFromTheTrip,
  getAllTrippets,
} from './trippet';

export const saveCurrentTrip = payload => ({
  type: actionTypes.SAVE_CURRENT_TRIP,
  payload,
});

export const getTripById = (id, userId) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/trip/getfull.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentTrip(data));
          let trippetBody;
          if (data.OwnerUser_ID === userId) {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +data.Trip_ID,
              Page: 1,
              OrderBy: 'ModifiedDate',
              OrderDir: 'desc',
              RowsPerPage: 50,
              User_ID: userId,
              // Type: 2,
            };
          } else {
            trippetBody = {
              Language_ID: 2,
              Trip_ID: +data.Trip_ID,
              Page: 1,
              OrderBy: 'ModifiedDate',
              OrderDir: 'desc',
              RowsPerPage: 50,
              User_ID: userId,
              // Type: 2,
            };
          }
          dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewTrip = (body, userId) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trip/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(clearAllTrippet());
          dispatch(saveCurrentTrip(data));
          const trippetBody = {
            Language_ID: 2,
            Trip_ID: data.Trip_ID,
            Page: 1,
            OrderBy: 'ModifiedDate',
            OrderDir: 'desc',
            RowsPerPage: 50,
            User_ID: userId,
            Type: 2,
          };
          dispatch(getAllTrippetsFromTheTrip(JSON.stringify(trippetBody)));
          const bodyTrips = {
            User_ID: userId,
            Type: 2,
            Page: 1,
            RowsPerPage: 10,
            OrderBy: 'ModifiedDate',
            OrderDir: 'asc',
            Language_ID: 2,
          };
          dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeTrip = (body, userId, orderBy, orderDir, filterResults) => {
  return dispatch => {
    if (body) {
      let updatedBody = JSON.parse(body);
      updatedBody.CurrentUser_ID = userId;
      fetch(`${BASE_URL}api/trip/updatefull.php`, {
        method: 'POST',
        body: JSON.stringify(updatedBody),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(getTripById(data.Trip_ID, userId));
          const bodyTrips = {
            User_ID: userId,
            Type: 2,
            Page: 1,
            RowsPerPage: 10,
            OrderBy: orderBy,
            OrderDir: orderDir,
            Language_ID: 2,
            IsDeleted: '0',
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              bodyTrips[filter.name] = filter.body;
            });
          }
          dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeTripWithoutParticipants = (body, userId, orderBy, orderDir, filterResults) => {
  return dispatch => {
    if (body) {
      let updatedBody = JSON.parse(body);
      updatedBody.CurrentUser_ID = userId;
      fetch(`${BASE_URL}api/trip/update.php`, {
        method: 'POST',
        body: JSON.stringify(updatedBody),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(getTripById(data.Trip_ID, userId));
          const bodyTrips = {
            User_ID: userId,
            Type: 2,
            Page: 1,
            RowsPerPage: 10,
            OrderBy: orderBy,
            OrderDir: orderDir,
            Language_ID: 2,
            IsDeleted: '0',
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              bodyTrips[filter.name] = filter.body;
            });
          }
          dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveTripAndRecommend = (body, userId) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trip/recommendation/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(() => {
          const body = { TripRecommendationRecipientUser_ID: userId };
          dispatch(getAllTripRecommendations(JSON.stringify(body)));
          const bodySentRecommendations = {
            TripRecommendationSenderUser_ID: userId,
          };
          dispatch(
            getAllSentTripRecommendations(
              JSON.stringify(bodySentRecommendations),
            ),
          );
          const totalNumberOfTripRecommendations = { User_ID: userId };

          dispatch(
            getTotalTripRecommendationsNumber(
              JSON.stringify(totalNumberOfTripRecommendations),
            ),
          );
          dispatch(
            showSnackBar({
              type: 'success',
              message: 'The recommendation has been successfully sent!',
            }),
          );
        })
        .catch(error => console.log(error));
    }
  };
};

export const getAllReceivedInvitation = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(() => {
        // const data = JSON.parse(response);
        // dispatch(saveAllRecievedTripInvitationsPagination(data));
        // dispatch(saveAllRecivedTripInvitations(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const getTripReceivedInvitation = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/receivedinvitations.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllRecievedTripInvitationsPagination(data));
        dispatch(saveAllRecivedTripInvitations(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const updateParticipantStatus = (
  body,
  userId,
  filterResults,
  sortedElementMyTrips,
  sortedByMyTrips,
) => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/updateparticipant.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(() => {
        const bodyTrips = {
          User_ID: userId,
          Type: 2,
          Page: 1,
          RowsPerPage: 10,
          OrderBy: sortedElementMyTrips,
          OrderDir: sortedByMyTrips[sortedElementMyTrips],
          Language_ID: 2,
          IsDeleted: '0',
        };
        if (filterResults) {
          filterResults.forEach(filter => {
            bodyTrips[filter.name] = filter.body;
          });
        }
        dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        const receivedInvitation = {
          Language_ID: 2,
          Type: 3,
          User_ID: userId,
          ParticipantStatus_ID: 1,
          IsDeleted: 0,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
        };
        dispatch(getTripReceivedInvitation(JSON.stringify(receivedInvitation)));
        const allTrippetsBody = {
          Language_ID: 2,
          Type: 2,
          User_ID: userId,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
          Page: 1,
          RowsPerPage: 10,
        };
        dispatch(getAllTrippets(JSON.stringify(allTrippetsBody)));
      })
      .catch(error => console.log(error));
  };
};

export const updateProxyStatus = (
  body,
  userId,
  filterResults,
  sortedElementMyTrips,
  sortedByMyTrips,
) => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/updateproxy.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(() => {
        const bodyTrips = {
          User_ID: userId,
          Type: 2,
          Page: 1,
          RowsPerPage: 10,
          OrderBy: sortedElementMyTrips,
          OrderDir: sortedByMyTrips[sortedElementMyTrips],
          Language_ID: 2,
          IsDeleted: '0',
        };
        if (filterResults) {
          filterResults.forEach(filter => {
            bodyTrips[filter.name] = filter.body;
          });
        }
        dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        const receivedInvitation = {
          Language_ID: 2,
          Type: 3,
          User_ID: userId,
          ParticipantStatus_ID: 1,
          IsDeleted: 0,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
        };
        dispatch(getTripReceivedInvitation(JSON.stringify(receivedInvitation)));
        const allTrippetsBody = {
          Language_ID: 2,
          Type: 2,
          User_ID: userId,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
          Page: 1,
          RowsPerPage: 10,
        };
        dispatch(getAllTrippets(JSON.stringify(allTrippetsBody)));
      })
      .catch(error => console.log(error));
  };
};

export const updateOrganizerStatus = (
  body,
  userId,
  filterResults,
  sortedElementMyTrips,
  sortedByMyTrips,
) => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/updateorganizer.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(() => {
        const bodyTrips = {
          User_ID: userId,
          Type: 2,
          Page: 1,
          RowsPerPage: 10,
          OrderBy: sortedElementMyTrips,
          OrderDir: sortedByMyTrips[sortedElementMyTrips],
          Language_ID: 2,
          IsDeleted: '0',
        };
        if (filterResults) {
          filterResults.forEach(filter => {
            bodyTrips[filter.name] = filter.body;
          });
        }
        dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        const receivedInvitation = {
          Language_ID: 2,
          Type: 3,
          User_ID: userId,
          ParticipantStatus_ID: 1,
          IsDeleted: 0,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
        };
        dispatch(getTripReceivedInvitation(JSON.stringify(receivedInvitation)));
        const allTrippetsBody = {
          Language_ID: 2,
          Type: 2,
          User_ID: userId,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
          Page: 1,
          RowsPerPage: 10,
        };
        dispatch(getAllTrippets(JSON.stringify(allTrippetsBody)));
      })
      .catch(error => console.log(error));
  };
};

export const saveAllSentTripInvitationsPagination = payload => ({
  type: actionTypes.SAVE_TRIP_SENT_INVITATION_PAGINATION,
  payload,
});

export const saveAllRecievedTripInvitationsPagination = payload => ({
  type: actionTypes.SAVE_TRIP_RECIEVED_INVITATION_PAGINATION,
  payload,
});

export const saveAllSentTripRecommendationPagination = payload => ({
  type: actionTypes.SAVE_TRIP_SENT_RECOMMENDATION_PAGINATION,
  payload,
});

export const saveAllRecievedTripRecommendationPagination = payload => ({
  type: actionTypes.SAVE_TRIP_RECIEVED_RECOMMENDATION_PAGINATION,
  payload,
});

export const getAllSentInvitation = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllSentTripInvitationsPagination(data));
        dispatch(saveAllSentTripInvitations(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const saveAllTrips = payload => ({
  type: actionTypes.SAVE_ALL_TRIPS,
  payload,
});

export const saveEntireTrips = payload => ({
  type: actionTypes.SAVE_ENTIRE_TRIPS,
  payload,
});

export const saveTripSortElement = payload => ({
  type: actionTypes.SAVE_TRIP_SORT_ELEMENT,
  payload,
});

export const saveTripSortByDirection = payload => ({
  type: actionTypes.SAVE_TRIP_SORT_BY_DIRECTION,
  payload,
});

export const saveTripInvitationSortElement = payload => ({
  type: actionTypes.SAVE_TRIP_INVITATION_SORT_ELEMENT,
  payload,
});

export const saveTripInvitationSortByDirection = payload => ({
  type: actionTypes.SAVE_TRIP_INVITATION_SORT_BY_DIRECTION,
  payload,
});

export const saveTripInvitationTotalNumber = payload => ({
  type: actionTypes.SAVE_TRIP_INVITATIONAL_TOTAL_NUMBER,
  payload,
});

export const saveAllRecivedTripInvitations = payload => ({
  type: actionTypes.SAVE_TRIP_RECIVED_INVITATIONAL,
  payload,
});

export const saveAllSentTripInvitations = payload => ({
  type: actionTypes.SAVE_TRIP_SENT_INVITATIONAL,
  payload,
});

export const saveAllTripRecommendations = payload => ({
  type: actionTypes.SAVE_ALL_TRIP_RECOMMENDATIONS,
  payload,
});

export const saveAllSentTripRecommendations = payload => ({
  type: actionTypes.SAVE_ALL_SENT_TRIP_RECOMMENDATIONS,
  payload,
});

export const saveTotalTripRecommendations = payload => ({
  type: actionTypes.SAVE_TOTAL_TRIP_RECOMMENDATIONS,
  payload,
});

export const saveTripPaginationData = payload => ({
  type: actionTypes.SAVE_TRIP_PAGINATION_DATA,
  payload,
});

export const getAllTrips = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllTrips(data.Data));
        dispatch(saveTripPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};


export const getEntireTrips = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: 10000,
            Page: 1,
            OrderBy: 'Name',
            OrderDir: 'asc'
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/trip/searchforfilter.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveEntireTrips(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const getTotalTripRecommendationsNumber = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/recommendation/getnum.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveTotalTripRecommendations(data.Num));
      })
      .catch(error => console.log(error));
  };
};

export const getAllTripRecommendations = body => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/recommendation/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllRecievedTripRecommendationPagination(data));
        dispatch(saveAllTripRecommendations(data.Data));
      })
      .catch(error => console.log(error));
  };
};

export const getAllSentTripRecommendations = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trip/recommendation/search.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveAllSentTripRecommendationPagination(data));
          dispatch(saveAllSentTripRecommendations(data.Data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const deleteTrip = (body, userId, orderBy, orderDir, filterResults) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trip/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          // const data = JSON.parse(response);
          // // dispatch(saveAllTrips(data));
          const bodyTrips = {
            User_ID: userId,
            Type: 2,
            Page: 1,
            RowsPerPage: 10,
            OrderBy: orderBy,
            OrderDir: orderDir,
            Language_ID: 2,
            IsDeleted: '0',
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              bodyTrips[filter.name] = filter.body;
            });
          }
          dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const forceDeleteTrip = (
  body,
  userId,
  orderBy,
  orderDir,
  filterResults,
  t
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trip/forcedelete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(() => {
          dispatch(
            showSnackBar({
              type: 'success',
              message: t('The trip has been deleted'),
            }),
          );
          const bodyTrips = {
            User_ID: userId,
            Type: 2,
            Page: 1,
            RowsPerPage: 10,
            OrderBy: orderBy,
            OrderDir: orderDir,
            Language_ID: 2,
            IsDeleted: '0',
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              bodyTrips[filter.name] = filter.body;
            });
          }
          console.log('bodyTrips', bodyTrips);
          dispatch(getAllTrips(JSON.stringify(bodyTrips)));
          const allTrippetsBody = {
            Language_ID: 2,
            Type: 2,
            User_ID: userId,
            OrderBy: 'ModifiedDate',
            OrderDir: 'desc',
            Page: 1,
            RowsPerPage: 10,
          };
          dispatch(getAllTrippets(JSON.stringify(allTrippetsBody)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const deleteTripParticipant = (
  body,
  userId,
  orderBy,
  orderDir,
  filterResults,
  t
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trip/deleteparticipant.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(() => {
          dispatch(
            showSnackBar({
              type: 'success',
              message: t('The trip has been deleted'),
            }),
          );
          const bodyTrips = {
            User_ID: userId,
            Type: 2,
            Page: 1,
            RowsPerPage: 10,
            OrderBy: orderBy,
            OrderDir: orderDir,
            Language_ID: 2,
            // IsDeleted: '0',
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              bodyTrips[filter.name] = filter.body;
            });
          }
          dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const deleteTripOrganizer = (
  body,
  userId,
  orderBy,
  orderDir,
  filterResults,
  t
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trip/deleteorganizer.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(() => {
          dispatch(
            showSnackBar({
              type: 'success',
              message: t('The trip has been deleted'),
            }),
          );
          const bodyTrips = {
            User_ID: userId,
            Type: 2,
            Page: 1,
            RowsPerPage: 10,
            OrderBy: orderBy,
            OrderDir: orderDir,
            Language_ID: 2,
            IsDeleted: '0',
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              bodyTrips[filter.name] = filter.body;
            });
          }
          dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const deleteTripProxy = (
  body,
  userId,
  orderBy,
  orderDir,
  filterResults,
  t
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trip/deleteproxy.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: t('Something went wrong'),
              }),
            );
          }
        })
        .then(() => {
          dispatch(
            showSnackBar({
              type: 'success',
              message: t('The trip has been deleted'),
            }),
          );
          const bodyTrips = {
            User_ID: userId,
            Type: 2,
            Page: 1,
            RowsPerPage: 10,
            OrderBy: orderBy,
            OrderDir: orderDir,
            Language_ID: 2,
            IsDeleted: '0',
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              bodyTrips[filter.name] = filter.body;
            });
          }
          dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveTripUsedFilters = payload => ({
  type: actionTypes.SAVE_TRIP_USED_FILTERS,
  payload,
});

export const duplicateTrip = (
  body,
  userId,
  orderBy,
  orderDir,
  filterResults,
  t
) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trip/duplicate.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: t('Something went wrong'),
              }),
            );
          }
        })
        .then(() => {
          const bodyTrips = {
            User_ID: userId,
            Type: 2,
            Page: 1,
            RowsPerPage: 10,
            OrderBy: orderBy,
            OrderDir: orderDir,
            Language_ID: 2,
            IsDeleted: '0',
          };
          if (filterResults) {
            filterResults.forEach(filter => {
              bodyTrips[filter.name] = filter.body;
            });
          }
          dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        })
        .catch(error => console.log(error));
    }
  };
};

// trip filters

export const saveFilterTripResults = payload => ({
  type: actionTypes.SAVE_FILTER_TRIP_RESULTS,
  payload,
});

export const clearFilterTripResults = payload => ({
  type: actionTypes.CLEAR_FILTER_TRIP_RESULTS,
  payload,
});

export const getTripFilterData = (body, t) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/trip/filter/${body.path}.php`, {
        method: 'POST',
        body: JSON.stringify(body.data),
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: t('Something went wrong'),
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveFilterTripResults(data));
        })
        .catch(error => console.log(error));
    }
  };
};


export const updateTripParticipantStatus = (
  body,
  userId,
  filterResults,
  sortedElementMyTrips,
  sortedByMyTrips,
) => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/updateparticipant.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(() => {
        const bodyTrips = {
          User_ID: userId,
          Type: 2,
          Page: 1,
          RowsPerPage: 10,
          OrderBy: sortedElementMyTrips,
          OrderDir: sortedByMyTrips[sortedElementMyTrips],
          Language_ID: 2,
          IsDeleted: '0',
        };
        if (filterResults) {
          filterResults.forEach(filter => {
            bodyTrips[filter.name] = filter.body;
          });
        }
        dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        const receivedInvitation = {
          Language_ID: 2,
          Type: 3,
          User_ID: userId,
          ParticipantStatus_ID: 1,
          IsDeleted: 0,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
        };
        dispatch(getTripReceivedInvitation(JSON.stringify(receivedInvitation)));
        const allTrippetsBody = {
          Language_ID: 2,
          Type: 2,
          User_ID: userId,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
          Page: 1,
          RowsPerPage: 10,
        };
        dispatch(getAllTrippets(JSON.stringify(allTrippetsBody)));
      })
      .catch(error => console.log(error));
  };
};

export const updateTripProxyStatus = (
  body,
  userId,
  filterResults,
  sortedElementMyTrips,
  sortedByMyTrips,
) => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/updateproxy.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(() => {
        const bodyTrips = {
          User_ID: userId,
          Type: 2,
          Page: 1,
          RowsPerPage: 10,
          OrderBy: sortedElementMyTrips,
          OrderDir: sortedByMyTrips[sortedElementMyTrips],
          Language_ID: 2,
          IsDeleted: '0',
        };
        if (filterResults) {
          filterResults.forEach(filter => {
            bodyTrips[filter.name] = filter.body;
          });
        }
        dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        const receivedInvitation = {
          Language_ID: 2,
          Type: 3,
          User_ID: userId,
          ParticipantStatus_ID: 1,
          IsDeleted: 0,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
        };
        dispatch(getTripReceivedInvitation(JSON.stringify(receivedInvitation)));
        const allTrippetsBody = {
          Language_ID: 2,
          Type: 2,
          User_ID: userId,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
          Page: 1,
          RowsPerPage: 10,
        };
        dispatch(getAllTrippets(JSON.stringify(allTrippetsBody)));
      })
      .catch(error => console.log(error));
  };
};

export const updateTripOrganizerStatus = (
  body,
  userId,
  filterResults,
  sortedElementMyTrips,
  sortedByMyTrips,
) => {
  return dispatch => {
    fetch(`${BASE_URL}api/trip/updateorganizer.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(() => {
        const bodyTrips = {
          User_ID: userId,
          Type: 2,
          Page: 1,
          RowsPerPage: 10,
          OrderBy: sortedElementMyTrips,
          OrderDir: sortedByMyTrips[sortedElementMyTrips],
          Language_ID: 2,
          IsDeleted: '0',
        };
        if (filterResults) {
          filterResults.forEach(filter => {
            bodyTrips[filter.name] = filter.body;
          });
        }
        dispatch(getAllTrips(JSON.stringify(bodyTrips)));
        const receivedInvitation = {
          Language_ID: 2,
          Type: 3,
          User_ID: userId,
          ParticipantStatus_ID: 1,
          IsDeleted: 0,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
        };
        dispatch(getTripReceivedInvitation(JSON.stringify(receivedInvitation)));
        const allTrippetsBody = {
          Language_ID: 2,
          Type: 2,
          User_ID: userId,
          OrderBy: 'ModifiedDate',
          OrderDir: 'desc',
          Page: 1,
          RowsPerPage: 10,
        };
        dispatch(getAllTrippets(JSON.stringify(allTrippetsBody)));
      })
      .catch(error => console.log(error));
  };
};
