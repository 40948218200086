export const columns = [
  { id: 'actions', label: '', minWidth: 70, type: 'actions' },
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    type: 'TrippetTypeLookName',
    filter: 'typelooks',
  },
  {
    id: 'title',
    label: 'Trippet Title',
    minWidth: 80,
    type: 'Title',
    filter: 'title',
  },
    { id: 'tripTitle', label: 'Trip Title', minWidth: 100, type: 'TripName' },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 80,
    type: 'StartDate',
    filter: 'startdates',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 80,
    type: 'EndDate',
    filter: 'enddates',
  },
  {
    id: 'from',
    label: 'From',
    minWidth: 80,
    type: 'FromPlace',
  },
  {
    id: 'to',
    label: 'To',
    minWidth: 80,
    type: 'ToPlace',
  },
  {
    id: 'participants',
    label: 'Participants',
    minWidth: 80,
    type: 'DisplayName',
    filter: 'participants',
  },
  {
    id: 'organizers',
    label: 'Responsible',
    minWidth: 80,
    type: 'DisplayName',
    filter: 'organizers',
  },
  // {
  //   id: 'proxies',
  //   label: 'Proxies',
  //   minWidth: 80,
  //   type: 'DisplayName',
  //   filter: 'proxies',
  // },
  {
    id: 'status',
    label: 'Status',
    minWidth: 110,
    type: 'OwnerPlanningStatus_ID',
    filter: 'ownerplanningstatuses',
    align: 'center',
  },
  {
    id: 'lastChanged',
    label: 'Last Changed',
    minWidth: 80,
    align: 'center',
    type: 'ModifiedDate',
  },
];
