import * as actionTypes from '../actionTypes/actionTypes';

export const savePaths = payload => ({
  type: actionTypes.SAVE_PATHS,
  payload,
});

export const saveMarkers = payload => ({
  type: actionTypes.SAVE_MARKERS,
  payload,
});
