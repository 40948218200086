
import { BASE_URL } from '../../config';
import { showSnackBar } from '../../store/actions/actions/root';
import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { changeUser, getAllUsers, saveCurrentUser } from '../../store/actions/actions/user';
import { getEntireCountries } from '../../store/actions/actions/country';
import { getEntireAdminRoles } from '../../store/actions/actions/adminRole';
import { getEntireDepartments } from '../../store/actions/actions/department';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import PreviewIcon from '../PreviewIcon/PreviewIcon';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../admin.css';
import * as userAccess from '../../userAccess';

const UserModal = ({ open, handleUserModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [displayNameError, setDisplayNameError] = useState(false);
  const [formError, setFormError] = useState(false);
  const currentUser = useSelector(state => state.user.currentUser);
  const entireCountries = useSelector(state => state.country.entireCountries);
  const entireAdminRoles = useSelector(state => state.adminRole.entireAdminRoles);
  const entireDepartments = useSelector(state => state.department.entireDepartments);
  const [values, setValues] = useState({
    User_ID: 0,
    FirstName: '',
    LastName: '',
    Email: '',
    Password: '',
    DisplayName: '',
    Gender: '',
    HomeAddress: '',
    PhoneNumber: '',
    Country_ID: null,
    BaseCurrency_ID: null,
    BirthDate: '',
    AdminRole_ID: null,
    Language_ID: null,
    Department_ID: null,
    TimeZone: '',
    TimeZoneDesc: '',
    Status: '',
    Picture: '',
    CountryName: '',
    BaseCurrencyName: '',
    DepartmentName: '',
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure dropdowns is already loaded
    if (!entireCountries)
    {
      dispatch(getEntireCountries(null));
    }
    if (!entireAdminRoles)
    {
      dispatch(getEntireAdminRoles(null));
    }
    if (!entireDepartments)
    {
      dispatch(getEntireDepartments(null));
    }
    if (defaultValues) {
        if (currentUser)
        {
            setValues({
              User_ID: currentUser.User_ID,
              FirstName: currentUser.FirstName,
              LastName: currentUser.LastName,
              Email: currentUser.Email,
              Password: currentUser.Password,
              DisplayName: currentUser.DisplayName,
              Gender: currentUser.Gender,
              HomeAddress: currentUser.HomeAddress,
              PhoneNumber: currentUser.PhoneNumber,
              Country_ID: currentUser.Country_ID,
              BaseCurrency_ID: currentUser.BaseCurrency_ID,
              BirthDate: currentUser.BirthDate,
              AdminRole_ID: currentUser.AdminRole_ID,
              Language_ID: currentUser.Language_ID,
              Department_ID: currentUser.Department_ID,
              TimeZone: currentUser.TimeZone,
              TimeZoneDesc: currentUser.TimeZoneDesc,
              Status: currentUser.Status,
              Picture: currentUser.Picture,
              CountryName: currentUser.CountryName,
              BaseCurrencyName: currentUser.BaseCurrencyName,
              DepartmentName: currentUser.DepartmentName,
            });
        }
        else
        {
            setValues({
                User_ID: defaultValues.User_ID,
                FirstName: defaultValues.FirstName,
                LastName: defaultValues.LastName,
                Email: defaultValues.Email,
                Password: defaultValues.Password,
                DisplayName: defaultValues.DisplayName,
                Gender: defaultValues.Gender,
                HomeAddress: defaultValues.HomeAddress,
                PhoneNumber: defaultValues.PhoneNumber,
                Country_ID: defaultValues.Country_ID,
                BaseCurrency_ID: defaultValues.BaseCurrency_ID,
                BirthDate: defaultValues.BirthDate,
                AdminRole_ID: defaultValues.AdminRole_ID,
                Language_ID: defaultValues.Language_ID,
                Department_ID: defaultValues.Department_ID,
                TimeZone: defaultValues.TimeZone,
                TimeZoneDesc: defaultValues.TimeZoneDesc,
                Status: defaultValues.Status,
                Picture: defaultValues.Picture,
                CountryName: defaultValues.CountryName,
                BaseCurrencyName: defaultValues.BaseCurrencyName,
                DepartmentName: defaultValues.DepartmentName,
            });
        }
        
        handleIsEditMode(true);
    } else {
      setValues({
        User_ID: 0,
        FirstName: '',
        LastName: '',
        Email: '',
        Password: '',
        DisplayName: '',
        Gender: '',
        HomeAddress: '',
        PhoneNumber: '',
        Country_ID: null,
        BaseCurrency_ID: null,
        BirthDate: '',
        AdminRole_ID: null,
        Language_ID: null,
        Department_ID: null,
        TimeZone: '',
        TimeZoneDesc: '',
        Status: '',
        Picture: '',
        CountryName: '',
        BaseCurrencyName: '',
        DepartmentName: '',
      });
      handleIsEditMode(false);
    }
  }, [defaultValues, currentUser, dispatch, entireAdminRoles, entireCountries, entireDepartments]);

  const validateForm = ({ Email, Password, DisplayName }) => {
    var anyError = false;
    if (!isEditMode)
    {
      if (Password)
        setPasswordError(false);
      else
      {
        setPasswordError(true);
        anyError = true;
      }
    }
    if (Email)
      setEmailError(false);
    else
    {
      setEmailError(true);
      anyError = true;
    }
    if (DisplayName)
      setDisplayNameError(false);
    else
    {
      setDisplayNameError(true);
      anyError = true;
    }
    setFormError(anyError);
    return !anyError;
  };


  const handleSubmit = e => {
    e.stopPropagation();
    if (!emailError && !passwordError && !displayNameError) {
        e.preventDefault();
        const data = {
            User_ID: +values.User_ID,
            FirstName: values.FirstName,
            LastName: values.LastName,
            Email: values.Email,
            Password: values.Password,
            DisplayName: values.DisplayName,
            Gender: values.Gender,
            HomeAddress: values.HomeAddress,
            PhoneNumber: values.PhoneNumber,
            Country_ID: values.Country_ID,
            BaseCurrency_ID: values.BaseCurrency_ID,
            BirthDate: values.BirthDate,
            AdminRole_ID: values.AdminRole_ID,
            Language_ID: values.Language_ID,
            Department_ID: values.Department_ID,
            TimeZone: values.TimeZone,
            TimeZoneDesc: values.TimeZoneDesc,
            Status: values.Status,
            Picture: values.Picture,
            CountryName: values.CountryName,
            BaseCurrencyName: values.BaseCurrencyName,
            DepartmentName: values.DepartmentName,
        };
      var jsonData = JSON.stringify(data);
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changeUser(jsonData));
          handleUserModalClose();
        }
      } else {
        if (validateForm(data)) {
          // we need to call createNewUser localy to handle return value
          // dispatch(createNewUser(JSON.stringify(data)));
          fetch(`${BASE_URL}api/user/create.php`, {
            method: 'POST',
            body: jsonData,
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(response => {
              if (response.status === 201) {
                return response.text();
              } 
              if (response.status === 503 && response.type === 'cors')
              {
                return response.text();
              }
              else  {
                dispatch(
                  showSnackBar({
                    type: 'error',
                    message: 'Something went wrong!',
                  }),
                );
              }
            })
            .then(response => {
              if (response)
              {
                const res = JSON.parse(response);
                if (res.ErrorCode)
                {
                  confirmAlert({
                    title: 'New User',
                    message: 'Error: Code = ' + res.ErrorCode + ", Message = " + res.ErrorMsg,
                    buttons: [
                      {
                        label: 'Close',
                      }
                    ]
                  });
                }
                else
                {
                  dispatch(saveCurrentUser(res));
                  dispatch(getAllUsers());
                  handleUserModalClose();
                }
              }
            })
            .catch(error => console.log(error));
            
          
          
        }
      }
    }
  };

  const clearPicture = () => {
    setValues({...values, Picture: ''});
  };

  const setPicture = file => {
    setValues({...values, Picture: file});
  };
  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name === 'Email') {
      if (e.target.value.length === 0) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
    else if (e.target.name === 'Password') {
      if (e.target.value.length === 0) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
    }
    else if (e.target.name === 'DisplayName') {
      if (e.target.value.length === 0) {
        setDisplayNameError(true);
      } else {
        setDisplayNameError(false);
      }
    }
    setValues({...values, [e.target.name]: e.target.value});
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const renderTitle = defaultValues ? t('Edit User') : t('New User');
      
  const renderCountries =
  entireCountries && entireCountries.length > 0
    ? entireCountries.map(country => {
        return (
          <MenuItem key={country.Country_ID} value={country.Country_ID}>{country.Name}</MenuItem>
        );
      })
    : null;

  const renderAdminRoles =
  entireAdminRoles && entireAdminRoles.length > 0
    ? entireAdminRoles.map(adminRole => {
        return (
          <MenuItem key={adminRole.AdminRole_ID} value={adminRole.AdminRole_ID}>{adminRole.Name}</MenuItem>
        );
      })
    : null;

  const renderDepartments =
  entireDepartments && entireDepartments.length > 0
    ? entireDepartments.map(department => {
        return (
          <MenuItem key={department.Department_ID} value={department.Department_ID}>{department.Name}</MenuItem>
        );
      })
    : null;

  const renderEmailError = emailError
    ? t('Email is required.')
    : null;

  const renderPasswordError = passwordError
    ? t('Password is required.')
    : null;

  const renderDisplayNameError = displayNameError
    ? t('Display Name is required.')
    : null;

  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;


  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_Users))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_Users));

  const renderPassword =
  !isEditMode
    ? (<Grid item xs={12}className={classes.languageName}>
      <TextField
        variant="outlined"
        required
        fullWidth
        id="Password"
        label={t('Password')}
        name="Password"
        autoComplete="Password"
        value={values.Password}
        onChange={handleInputs}
      />
      <FormHelperText className={classes.errorText}>
        {renderPasswordError}
      </FormHelperText>
    </Grid>)
    : null;


  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.languageName}>
                <Grid container>
                  <Grid item xs={12}className={classes.languageName}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="Email"
                      label={t('Email')}
                      name="Email"
                      autoComplete="Email"
                      autoFocus
                      value={values.Email}
                      onChange={handleInputs}
                      disabled={values.User_ID > 0}
                    />
                    <FormHelperText className={classes.errorText}>
                      {renderEmailError}
                    </FormHelperText>
                  </Grid>
                  {renderPassword}
                  <Grid item xs={12} md={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      id="DisplayName"
                      label={t('DisplayName')}
                      name="DisplayName"
                      autoComplete="DisplayName"
                      value={values.DisplayName}
                      onChange={handleInputs}
                    />
                    <FormHelperText className={classes.errorText}>
                      {renderDisplayNameError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" className={classes.selectView}
                      margin="normal">
                      <InputLabel id="demo-simple-select-outlined-label">
                        {t('Status')}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t('Status')}
                        name="Status"
                        value={values.Status}
                        onChange={handleInputs}
                      >
                        <MenuItem value="1">{t('Enabled')}</MenuItem>
                        <MenuItem value="2">{t('Disabled')}</MenuItem>
                        <MenuItem value="3">{t('Deleted')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} className={classes.languageName}>
                <PreviewIcon currentImage={values.Picture} pictureUrl={values.Picture} clearIcon={clearPicture} setIcon={setPicture}
                  type='user' dispatch={dispatch} t={t} />
              </Grid>
              <Grid item xs={12} md={6} className={classes.languageName}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  id="FirstName"
                  label={t('First Name')}
                  name="FirstName"
                  autoComplete="FirstName"
                  value={values.FirstName}
                  onChange={handleInputs}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.languageName}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  id="LastName"
                  label={t('Last Name')}
                  name="LastName"
                  autoComplete="LastName"
                  value={values.LastName}
                  onChange={handleInputs}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" className={classes.selectView}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Gender')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Gender')}
                    name="Gender"
                    value={values.Gender}
                    onChange={handleInputs}
                  >
                    <MenuItem value="1">{t('Undefined')}</MenuItem>
                    <MenuItem value="2">{t('Male')}</MenuItem>
                    <MenuItem value="3">{t('Female')}</MenuItem>
                    <MenuItem value="4">{t('Other')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} className={classes.languageName}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="BirthDate"
                  label={t('Birth Date')}
                  name="BirthDate"
                  autoComplete="BirthDate"
                  value={values.BirthDate}
                  onChange={handleInputs}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.countryName}>
                <FormControl variant="outlined" className={classes.selectView} margin="normal">
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Country')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Country')}
                    name="Country_ID"
                    value={values.Country_ID}
                    onChange={handleInputs}
                  >
                    {renderCountries}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} className={classes.languageName}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  id="BirthDate"
                  label={t('Phone Number')}
                  name="PhoneNumber"
                  autoComplete="PhoneNumber"
                  value={values.PhoneNumber}
                  onChange={handleInputs}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.languageName}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="HomeAddress"
                  label={t('Home Address')}
                  name="HomeAddress"
                  autoComplete="HomeAddress"
                  value={values.HomeAddress}
                  onChange={handleInputs}
                />
              </Grid>
              {userAccess.canWrite(user, userAccess.Table_AdminRoles) ? 
              <Grid item xs={12} md={6} className={classes.countryName}>
                <FormControl variant="outlined" className={classes.selectView} margin="normal">
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Admin Role')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Admin Role')}
                    name="AdminRole_ID"
                    value={values.AdminRole_ID}
                    onChange={handleInputs}
                  >
                    {renderAdminRoles}
                  </Select>
                </FormControl>
              </Grid>
              : null}
              <Grid item xs={12} md={6} className={classes.countryName}>
                <FormControl variant="outlined" className={classes.selectView} margin="normal">
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Department')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Department')}
                    name="Department_ID"
                    value={values.Department_ID}
                    onChange={handleInputs}
                  >
                    {renderDepartments}
                  </Select>
                </FormControl>
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleUserModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default UserModal;