import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const KeepTrippetsModal = ({
  open,
  handleClose,
  handleSaveTrippets,
  trip,
  allMyTrippets,
}) => {
  const [arrayTrippets, setArrayTrippets] = useState([]);
  const [values, setValues] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  useEffect(() => {
    if (trip) {
      setArrayTrippets(allMyTrippets);
    }
  }, [allMyTrippets, trip]);

  const handleSave = () => {
    handleSaveTrippets(values);
    handleClose();
  };

  const handleChange = (event, values) => {
    setValues(values);
  };

  const handleSelectAll = event => {
    setIsAllSelected(event.target.checked);
    if (event.target.checked === true) {
      setValues([...allMyTrippets]);
    } else {
      setValues([]);
    }
  };

  const handleDeleteSelectedTrippets = () => {
    setValues([]);
    setIsAllSelected(false);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{t('Trippets')}</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllSelected}
                onChange={handleSelectAll}
                name="privateTrip"
                color="primary"
              />
            }
            label={t('Select all')}
          />
          <Button color="primary" onClick={handleDeleteSelectedTrippets}>
            {t('Unselect all')}
          </Button>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={arrayTrippets}
            disableCloseOnSelect
            onChange={handleChange}
            value={values}
            getOptionLabel={option => option.Title}
            renderOption={(option, { selected }) => (
              <Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  name={option.user_id}
                  color="primary"
                />
                {option.Title}
              </Fragment>
            )}
            style={{ width: 500 }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={t('Trippets')}
                placeholder={t('Trippets')}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('Exit')}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            autoFocus
            variant="contained"
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default KeepTrippetsModal;
