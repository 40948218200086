import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import {store} from '../../store';

export const saveCurrentUserRoleAllow = payload => ({
  type: adminActionTypes.SAVE_CURRENT_USERROLEALLOW,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_USERROLEALLOW_CURRENT_PAGE,
  payload,
});

export const getUserRoleAllowById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/userrole/allow/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentUserRoleAllow(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewUserRoleAllow = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/userrole/allow/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503 && response.type !== 'cors') {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentUserRoleAllow(data));
          dispatch(getAllUserRoleAllows(JSON.stringify(
            {
              GiverUserRole_ID: data.GiverUserRole_ID,
              RowsPerPage: ROWS_PER_PAGE,
              Page: store.getState().userRoleAllow.currentPage
            })));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeUserRoleAllow = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/userrole/allow/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(body);
          dispatch(getUserRoleAllowById(body.UserRoleAllow_ID));
          dispatch(getAllUserRoleAllows(JSON.stringify(
            {
              GiverUserRole_ID: data.GiverUserRole_ID,
              RowsPerPage: ROWS_PER_PAGE,
              Page: store.getState().userRoleAllow.currentPage
            })));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllUserRoleAllows = payload => ({
  type: adminActionTypes.SAVE_ALL_USERROLEALLOWS,
  payload,
});

export const saveUserRoleAllowSortElement = payload => ({
  type: adminActionTypes.SAVE_USERROLEALLOW_SORT_ELEMENT,
  payload,
});

export const saveUserRoleAllowSortByDirection = payload => ({
  type: adminActionTypes.SAVE_USERROLEALLOW_SORT_BY_DIRECTION,
  payload,
});

export const saveUserRoleAllowPaginationData = payload => ({
  type: adminActionTypes.SAVE_USERROLEALLOW_PAGINATION_DATA,
  payload,
});

export const getAllUserRoleAllows = body => {
  return dispatch => {
    // UserRole_ID is required.
    if (!body)
      return;
    fetch(`${BASE_URL}api/userrole/allow/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllUserRoleAllows(data.Data));
        dispatch(saveUserRoleAllowPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

// provide GiverUserRole_ID to reload relevant data
export const deleteUserRoleAllow = (body, GiverUserRole_ID) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/userrole/allow/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else 
            dispatch(getAllUserRoleAllows(JSON.stringify(
              {
                GiverUserRole_ID: GiverUserRole_ID,
                RowsPerPage: ROWS_PER_PAGE,
                Page: store.getState().userRoleAllow.currentPage
              })));
        })
        .catch(error => console.log(error));
    }
  };
};
