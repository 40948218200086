import { ROWS_PER_PAGE } from "../../config";
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUserRoleAllows,
  deleteUserRoleAllow,
  saveCurrentPage,
} from "../../store/actions/actions/userRoleAllow";
import Table from "./Table";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import * as userAccess from "../../userAccess";
import UserRoleAllowModal from "../../components/UserRole/UserRoleAllowForm";

const UserRoleAllowList = ({ data }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const allUserRoleAllows = useSelector(
    state => state.userRoleAllow.allUserRoleAllows,
  );
  const paginationData = useSelector(
    state => state.userRoleAllow.paginationData,
  );
  const currentPage = useSelector(state => state.userRoleAllow.currentPage);
  const [deletedUserRoleAllow, setDeletedUserRoleAllow] = useState(null);
  const [isOpenConfirmDeleteModal, setisOpenConfirmDeleteModal] = useState(
    false,
  );

  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortedElement, setSortedElement] = useState("TakerUserRoleName");
  const [sortedDir, setSortedDir] = useState("asc");
  const [defaultValues, setDefaultValues] = useState({});
  const [isUserRoleAllowModalOpen, setIsUserRoleAllowModalOpen] = useState(
    false,
  );

  useEffect(() => {
    setDefaultValues({ GiverUserRole_ID: data.UserRole_ID });
    const bodySearch = {
      Page: currentPage,
      RowsPerPage: ROWS_PER_PAGE,
      OrderBy: sortedElement,
      Language_ID: "2",
      OrderDir: sortedDir,
      GiverUserRole_ID: data.UserRole_ID,
    };

    dispatch(getAllUserRoleAllows(JSON.stringify(bodySearch)));
  }, [data, sortedElement, sortedDir, currentPage, dispatch]);

  useEffect(() => {
    if (paginationData) {
      setTotalItemsCount(paginationData.Num);
    }
  }, [paginationData]);

  const handleSortDir = id => () => {
    setSortedDir(sortedElement === id && sortedDir === "asc" ? "desc" : "asc");
    setSortedElement(id);
  };

  const onPaginationChange = activePage => {
    dispatch(saveCurrentPage(activePage));
  };

  const handleConfirmDeleteModalClose = () => {
    setisOpenConfirmDeleteModal(false);
  };

  const handleConfirmDeleteModalOpen = () => {
    setisOpenConfirmDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(
      deleteUserRoleAllow(
        JSON.stringify(deletedUserRoleAllow),
        data.UserRole_ID,
      ),
    );
  };

  const handleUserRoleAllowModalOpen = () => {
    setIsUserRoleAllowModalOpen(true);
  };

  const handleUserRoleAllowModalClose = () => {
    setIsUserRoleAllowModalOpen(false);
  };

  const handleNewAllowClick = () => {
    //setDefaultValues(null);
    handleUserRoleAllowModalOpen();
  };

  return (
    <Grid container justify="center" className={classes.containerMyTrip}>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {t("Allow List")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Fab
            variant="extended"
            className={classes.addTripButton}
            onClick={handleNewAllowClick}
            disabled={!userAccess.canWrite(user, userAccess.Table_UserRoles)}
          >
            <AddIcon className={classes.extendedIcon} />
            {t("Add Allow")}
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          data={allUserRoleAllows}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedUserRoleAllow={setDeletedUserRoleAllow}
          sortedElement={sortedElement}
          sortedBy={sortedDir}
          handleSortBy={handleSortDir}
        />
      </Grid>
      <AdminPagination
        activePage={currentPage}
        itemsPerPage={ROWS_PER_PAGE}
        totalItemsCount={totalItemsCount}
        onChange={onPaginationChange}
        title="allows"
      />
      <ConfirmDeleteModal
        open={isOpenConfirmDeleteModal}
        handleClose={handleConfirmDeleteModalClose}
        handleDelete={handleDelete}
      />
      <UserRoleAllowModal
        open={isUserRoleAllowModalOpen}
        handleUserRoleAllowModalClose={handleUserRoleAllowModalClose}
        defaultValues={defaultValues}
      />
    </Grid>
  );
};

export default UserRoleAllowList;
