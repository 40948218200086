import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
  },
  pageContainer: {
    width: 400,
    margin: 'auto',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > img': {
      width: '100%',
      height: 'auto',
    },
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  text: {
    textAlign: 'center',
    width: 250,
    "& > div": {
      marginTop: theme.spacing(1),
      fontSize: 18,
    }
  },
  submit: {
    marginTop: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text[100],
    transition: 'all .7s',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  errorMessage: {
    color: theme.palette.secondary.main,
    fontSize: 12,
  },
}));

export default useStyles;
