import * as actionTypes from "../actions/actionTypes/actionTypes";

const initialState = {
  allTrippets: null,
  pagination: null,
  currentTrippet: null,
  currentTrippetNotes: null,
  allMyTrippets: null,
  allTrippetInvitations: null,
  trippetChildrenStatus: null,
  trippetSentInvitation: null,
  trippetReceivedInvitation: null,
  allTrippetRecommendations: null,
  allSentTrippetRecommendations: null,
  totalNumberOfRecommendations: null,
  trippetSentInvitationPagination: null,
  trippetRecievedInvitationPagination: null,
  trippetSentRecommendationPagination: null,
  trippetRecievedRecommendationPagination: null,
  trippetImage: [],
  personalDocument: [],
  publicDocument: [],
  selectedImage: null,
  isOpenTrippetFrom: false,
  pickedTime: "",
  calendarInstance: null,
  trippetTypes: null,
  trippetStatus: null,
  trippetCategories: null,
  allTrippetsPictures: null,
  allTrippetsDocuments: null,
  paginationDataMyTrippets: null,
  pageMytrippets: 1,
  currentTrippetChat: null,
  tripTotalPrice: null,
  sortElementMyTrippets: "ModifiedDate",
  sortByDirectionMyTrippets: {
    Title: "asc",
    TrippetTypeLookName: "asc",
    StartDate: "asc",
    EndDate: "asc",
    FromPlace: "asc",
    ToPlace: "asc",
    DisplayName: "asc",
    Day: "asc",
    Price: "asc",
    Link: "asc",
    Description: "asc",
    IsWholeDay: "asc",
    OwnerUser_ID: "asc",
    Distance: "asc",
    TripName: "asc",
    DefaultPicture: "asc",
    NumTrippets: "asc",
    ModifiedDate: "desc",
  },
  sortElementMyTrippetsInvitation: "ModifiedDate",
  sortByDirectionMyTrippetsInvitation: {
    Title: "asc",
    TrippetTypeLookName: "asc",
    StartDate: "asc",
    NumTrippets: "asc",
    EndDate: "asc",
    FromPlace: "asc",
    ToPlace: "asc",
    DefaultPicture: "asc",
    DisplayName: "asc",
    Day: "asc",
    Price: "asc",
    Link: "asc",
    Description: "asc",
    IsWholeDay: "asc",
    OwnerUser_ID: "asc",
    Distance: "asc",
    TripName: "asc",
    ModifiedDate: "desc",
  },
  sortElement: "ModifiedDate",
  filterData: null,
  sortByDirection: {
    Title: "asc",
    TrippetTypeLook_ID: "asc",
    StartDate: "asc",
    EndDate: "asc",
    FromPlace: "asc",
    ToPlace: "asc",
    DisplayName: "asc",
    Day: "asc",
    Price: "asc",
    Link: "asc",
    Description: "asc",
    IsWholeDay: "asc",
    OwnerUser_ID: "asc",
    Distance: "asc",
    ModifiedDate: "desc",
  },
  page: 1,
  usedFilters: null,
  parentsTrippetsWithChildren: null,
  parentsTrippetsWithChildrenNum: 0,
  parentsTrippet: null,
};
const trippetReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_UPLOADED_FILE:
      let files = [...(state[action.payload.type] || [])];
      files.push(action.payload);
      return {
        ...state,
        [action.payload.type]: files,
      };
    case actionTypes.SAVE_TRIPPET_PRIVATE_DOCUMENTS:
      return {
        ...state,
        personalDocument: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_PUBLIC_DOCUMENTS:
      return {
        ...state,
        publicDocument: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_IMAGES:
      return {
        ...state,
        trippetImage: action.payload,
      };
    case actionTypes.SAVE_SELECTED_IMAGE:
      return {
        ...state,
        selectedImage: action.payload,
      };
    case actionTypes.CLEAR_SELECTED_IMAGE:
      return {
        ...state,
        selectedImage: null,
      };
    case actionTypes.CLEAR_TRIPPET_IMAGES:
      return {
        ...state,
        trippetImage: [],
        selectedImage: null,
      };

    case actionTypes.SAVE_ALL_TRIPPETS:
      return {
        ...state,
        allTrippets: action.payload,
      };
    case actionTypes.SVE_TRIP_TOTAL_PRICE:
      return {
        ...state,
        tripTotalPrice: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_CATEGORY:
      return {
        ...state,
        trippetCategories: action.payload,
      };
    case actionTypes.SAVE_CURRENT_TRIPPET:
      return {
        ...state,
        currentTrippet: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_SORT_ELEMENT:
      return {
        ...state,
        sortElement: action.payload,
      };
    case actionTypes.SAVE_MY_TRIPPET_SENT_INVITATION:
      return {
        ...state,
        trippetSentInvitation: action.payload,
      };
    case actionTypes.SAVE_MY_TRIPPET_RECEIVED_INVITATION:
      return {
        ...state,
        trippetReceivedInvitation: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_PAGE_PAGIONATION:
      return {
        ...state,
        page: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_SORT_BY_DIRECTION:
      return {
        ...state,
        sortByDirection: action.payload,
      };
    case actionTypes.SAVE_MY_TRIPPET_SORT_ELEMENT:
      return {
        ...state,
        sortElementMyTrippets: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_USED_FILTERS:
      return {
        ...state,
        usedFilters: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_INVITATION_SORT_ELEMENT:
      return {
        ...state,
        sortElementMyTrippetsInvitation: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_INVITATION_SORT_BY_DIRECTION:
      return {
        ...state,
        sortByDirectionMyTrippetsInvitation: action.payload,
      };
    case actionTypes.SAVE_FILTER_TRIPPET_RESULTS: {
      return {
        ...state,
        filterData: action.payload,
      };
    }
    case actionTypes.CLEAR_FILTER_TRIPPET_RESULTS: {
      return {
        ...state,
        filterData: null,
      };
    }
    case actionTypes.SAVE_MY_TRIPPET_PAGE_PAGIONATION:
      return {
        ...state,
        pageMytrippets: action.payload,
      };
    case actionTypes.SAVE_MY_TRIPPET_SORT_BY_DIRECTION:
      return {
        ...state,
        sortByDirectionMyTrippets: action.payload,
      };
    case actionTypes.SAVE_ALL_CURRENT_TRIPPET_NOTES:
      return {
        ...state,
        currentTrippetNotes: action.payload,
      };
    case actionTypes.CLEAR_ALL_CURRENT_TRIPPET_NOTES:
      return {
        ...state,
        currentTrippetNotes: null,
      };
    case actionTypes.SAVE_TRIPPET_PAGINATION_DATA:
      return {
        ...state,
        pagination: action.payload,
      };
    case actionTypes.SAVE_MY_TRIPPETS_PAGINATION_DATA:
      return {
        ...state,
        paginationDataMyTrippets: action.payload,
      };
    case actionTypes.SAVE_TOTAL_TRIPPET_RECOMMENDATIONS_NUMBER:
      return {
        ...state,
        totalNumberOfRecommendations: action.payload,
      };
    case actionTypes.DELETE_IMAGE:
      return {
        ...state,
        trippetImage: [],
        selectedImage: null,
      };
    case actionTypes.SAVE_TRIPPET_TYPES:
      return {
        ...state,
        trippetTypes: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_STATUS:
      return {
        ...state,
        trippetStatus: action.payload,
      };
    case actionTypes.SAVE_SINGLE_TRIPPET_CHAT:
      return {
        ...state,
        currentTrippetChat: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_SENT_INVITATION_PAGINATION:
      return {
        ...state,
        trippetSentInvitationPagination: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_RECIEVED_INVITATION_PAGINATION:
      return {
        ...state,
        trippetRecievedInvitationPagination: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_SENT_RECOMMENDATION_PAGINATION:
      return {
        ...state,
        trippetSentRecommendationPagination: action.payload,
      };
    case actionTypes.SAVE_TRIPPET_RECIEVED_RECOMMENDATION_PAGINATION:
      return {
        ...state,
        trippetRecievedRecommendationPagination: action.payload,
      };
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allTrippets: null,
        currentTrippet: null,
        trippetChildrenStatus: null,
        currentTrippetNotes: null,
        trippetImage: [],
        personalDocument: [],
        publicDocument: [],
        selectedImage: null,
        isOpenTrippetFrom: false,
        pickedTime: "",
        allMyTrippets: null,
        calendarInstance: null,
        trippetTypes: null,
        allTrippetInvitations: null,
        allTrippetsPictures: null,
        allTrippetsDocuments: null,
        currentTrippetChat: null,
        paginationDataMyTrippets: null,
        sortElementMyTrippets: "ModifiedDate",
        filterData: null,
        usedFilters: null,
        tripTotalPrice: null,
        trippetSentInvitationPagination: null,
        trippetRecievedInvitationPagination: null,
        trippetSentRecommendationPagination: null,
        trippetRecievedRecommendationPagination: null,
        parentsTrippetsWithChildren: null,
        parentsTrippetsWithChildrenNum: 0,
        sortByDirectionMyTrippets: {
          Title: "asc",
          TrippetTypeLook_ID: "asc",
          StartDate: "asc",
          EndDate: "asc",
          FromPlace: "asc",
          ToPlace: "asc",
          DisplayName: "asc",
          Day: "asc",
          Price: "asc",
          Link: "asc",
          Description: "asc",
          IsWholeDay: "asc",
          OwnerUser_ID: "asc",
          Distance: "asc",
          NumTrippets: "asc",
          ModifiedDate: "desc",
        },
        sortElement: "ModifiedDate",
        sortByDirection: {
          Title: "asc",
          TrippetTypeLook_ID: "asc",
          StartDate: "asc",
          EndDate: "asc",
          FromPlace: "asc",
          ToPlace: "asc",
          DisplayName: "asc",
          Day: "asc",
          Price: "asc",
          Link: "asc",
          Description: "asc",
          IsWholeDay: "asc",
          OwnerUser_ID: "asc",
          Distance: "asc",
          ModifiedDate: "desc",
        },
        sortElementMyTrippetsInvitation: "ModifiedDate",
        sortByDirectionMyTrippetsInvitation: {
          Title: "asc",
          TrippetTypeLook_ID: "asc",
          StartDate: "asc",
          NumTrippets: "asc",
          EndDate: "asc",
          FromPlace: "asc",
          ToPlace: "asc",
          DisplayName: "asc",
          Day: "asc",
          Price: "asc",
          Link: "asc",
          Description: "asc",
          IsWholeDay: "asc",
          OwnerUser_ID: "asc",
          Distance: "asc",
          TripName: "asc",
          ModifiedDate: "desc",
        },
      };
    }
    case actionTypes.CLEAR_ALL_TRIPPETS:
      return {
        ...state,
        allTrippets: null,
        currentTrippet: null,
        trippetImage: [],
        personalDocument: [],
        publicDocument: [],
        selectedImage: null,
      };
    case actionTypes.CLEAR_FILES:
      return {
        ...state,
        trippetImage: [],
        personalDocument: [],
        selectedImage: null,
        publicDocument: [],
      };
    case actionTypes.SHOW_TRIPPET_FORM:
      return {
        ...state,
        isOpenTrippetFrom: true,
        currentTrippet: action.payload,
      };
    case actionTypes.SAVE_PICKED_TIME_FROM_CALENDAR:
      return {
        ...state,
        pickedTime: action.payload,
      };
    case actionTypes.HIDE_TRIPPET_FORM:
      return {
        ...state,
        isOpenTrippetFrom: false,
        currentTrippet: null,
        pickedTime: "",
      };
    case actionTypes.SAVE_ALL_MY_TRIPPETS:
      return {
        ...state,
        allMyTrippets: action.payload,
      };
    case actionTypes.SAVE_CALENDAR_INSTANCE:
      return {
        ...state,
        calendarInstance: action.payload,
      };
    case actionTypes.SAVE_ALL_TRIPPETS_INVITATIONS:
      return {
        ...state,
        allTrippetInvitations: action.payload,
      };
    case actionTypes.SAVE_ALL_TRIPPETS_PICTURES:
      return {
        ...state,
        allTrippetsPictures: action.payload,
      };
    case actionTypes.SAVE_ALL_TRIPPETS_DOCUMENTS:
      return {
        ...state,
        allTrippetsDocuments: action.payload,
      };
    case actionTypes.SAVE_ALL_TRIPPET_RECOMMENDATIONS:
      return {
        ...state,
        allTrippetRecommendations: action.payload,
      };
    case actionTypes.SAVE_ALL_SENT_TRIPPET_RECOMMENDATIONS:
      return {
        ...state,
        allSentTrippetRecommendations: action.payload,
      };
    case actionTypes.SAVE_PARENT_TRIPPET_CHILDREN:
      return {
        ...state,
        parentsTrippet: action.payload,
      };
    case actionTypes.CLEAR_PARENT_TRIPPET_CHILDREN:
      return {
        ...state,
        parentsTrippet: null,
      };
    case actionTypes.SAVE_PARENT_TRIPPET_WITH_CHILDREN:
      return {
        ...state,
        parentsTrippetsWithChildren: action.payload.Data,
        parentsTrippetsWithChildrenNum: action.payload.Num,
      };
    case actionTypes.SAVE_TRIPPET_CHILDREN_STATUS:
      return {
        ...state,
        trippetChildrenStatus: action.payload,
      };
    case actionTypes.CLEAR_TRIPPET_CATEGORIES_AND_TYPES:
      return {
        ...state,
        trippetCategories: null,
        trippetTypes: null
      }
    default:
      return {
        ...state,
      };
  }
};

export default trippetReducer;
