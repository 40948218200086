import React from 'react';

export const findCreatorInfo = (allUsers, participant, classes) => {
  if (allUsers && allUsers.length > 0) {
    let data = '';
    allUsers.forEach(user => {
      if (participant === user.User_ID) {
        data = renderUserInfo(user, classes);
      }
    });
    return data;
  }
};

export const renderUserInfo = (participant, classes) => {
  if (participant.Picture) {
    return (
      <img
        src={participant.Picture}
        alt={participant.TripParticipantInitials || participant.Initials}
        className={classes.avatarImage}
      />
    );
  } else {
    return `${participant.Initials || participant.TripParticipantInitials}`;
  }
};

export const renderParticipantsStatusColor = (status, classes) => {
  if (status === '1' || status === null || status === '0') {
    return classes.pending;
  } else if (status === '2') {
    return classes.accepted;
  } else if (status === '3') {
    return classes.rejected;
  }
};

export const findParticipantsInfo = (allUsers, participant, classes) => {
  if (allUsers && allUsers.length > 0) {
    let data = '';
    allUsers.forEach(user => {
      if (participant.TripParticipantUser_ID === user.User_ID || participant.User_ID === user.User_ID) {
        data = renderUserInfo(user, classes);
      }else {
        if(participant.InviteeEmail){
          data = renderUserInfo(participant, classes)
        }
      }
    });
    return data;
  }
};

export const renderProxyInfo = (participant, classes) => {
  if (participant.Picture) {
    return (
      <img
        src={participant.Picture}
        alt={participant.TripProxyInitials || participant.Initials}
        className={classes.avatarImage}
      />
    );
  } else {
    return `${participant.Initials || participant.TripProxyInitials}`;
  }
};

export const findProxyInfo = (allUsers, proxy, classes) => {
  if (allUsers && allUsers.length > 0) {
    let data = '';
    allUsers.forEach(user => {
      if (proxy.TripProxyUser_ID === user.User_ID || proxy.User_ID === user.User_ID) {
        data = renderProxyInfo(user, classes);
      }else {
        if(proxy.InviteeEmail){
          data = renderProxyInfo(proxy, classes)
        }
      }
    });
    return data;
  }
};

export const renderOrganizerInfo = (participant, classes) => {
  if (participant.Picture) {
    return (
      <img
        src={participant.Picture}
        alt={participant.TripOrganizerInitials || participant.Initials}
        className={classes.avatarImage}
      />
    );
  } else {
    return `${participant.Initials || participant.TripOrganizerInitials}`;
  }
};

export const findOrganizerInfo = (allUsers, organizer, classes) => {
  if (allUsers && allUsers.length > 0) {
    let data = '';
    allUsers.forEach(user => {
      if (organizer.TripOrganizerUser_ID === user.User_ID || organizer.User_ID === user.User_ID) {
        data = renderOrganizerInfo(user, classes);
      }else {
        if(organizer.InviteeEmail){
          data = renderOrganizerInfo(organizer, classes)
        }
      }
    });
    return data;
  }
};

export const createParticipant = (item, role) => {
  let name = item.TripOrganizerDisplayName || item.DisplayName || item.TripParticipantDisplayName || item.TripProxyDisplayName;
  let Initials = item.TripParticipantInitials || item.Initials || item.TripProxyInitials || item.TripOrganizerInitials;
  let id = item.TripParticipantUser_ID || item.User_ID || item.TripOrganizerUser_ID || item.TripProxyUser_ID;
  let data = {
    InviteMessage: item.InviteMessage || null,
    InviteeEmail: item.InviteeEmail || null,
    ParticipantStatusName: null,
    ParticipantStatus_ID: item.ParticipantStatus_ID || "1",
    PlanningStatusName: null,
    PlanningStatus_ID: null,
    TripParticipantDisplayName: name,
    TripParticipantInitials: Initials,
    TripParticipantUserRole_ID: role,
    TripParticipantUser_ID: id,
    Trip_ID: item.Trip_ID,
    id: id,
    type: item.type,
  }
  return data;
}

export const createProxy = (item, role) => {
  let name = item.TripOrganizerDisplayName || item.DisplayName || item.TripParticipantDisplayName || item.TripProxyDisplayName;
  let Initials = item.TripParticipantInitials || item.Initials || item.TripProxyInitials || item.TripOrganizerInitials;
  let id = item.TripParticipantUser_ID || item.User_ID || item.TripOrganizerUser_ID || item.TripProxyUser_ID;
  let data = {
    InviteMessage: item.InviteMessage || null,
    InviteeEmail: item.InviteeEmail || null,
    TripProxyAcceptanceStatus: item.TripProxyAcceptanceStatus || "1",
    TripProxyDisplayName: name,
    TripProxyInitials: Initials,
    TripProxyUserRole_ID: role,
    TripProxyUser_ID: id,
    Trip_ID: item.Trip_ID,
    id: id,
    type: item.type,
  }
  return data;
}


export const createOrganizer = (item, role) => {
  let name = item.TripOrganizerDisplayName || item.DisplayName || item.TripParticipantDisplayName || item.TripProxyDisplayName;
  let Initials = item.TripParticipantInitials || item.Initials || item.TripProxyInitials || item.TripOrganizerInitials;
  let id = item.TripParticipantUser_ID || item.User_ID || item.TripOrganizerUser_ID || item.TripProxyUser_ID;
  let data = {
    InviteMessage: item.InviteMessage || null,
    InviteeEmail: item.InviteeEmail || null,
    TripOrganizerAcceptanceStatus: item.TripOrganizerAcceptanceStatus || "1",
    TripOrganizerDisplayName: name,
    TripOrganizerInitials: Initials,
    TripOrganizerUserRole_ID: role,
    TripOrganizerUser_ID: id,
    Trip_ID: item.Trip_ID,
    id: id,
    type: item.type,
  }
  return data;
}