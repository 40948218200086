import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import useStyles from './styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import {BASE_URL} from '../../config';
import { useDispatch } from 'react-redux';
import { saveSelectedImage } from '../../store/actions/actions/trippet'

const SampleNextArrow = props => {
  const classes = useStyles();
  const { style, onClick } = props;
  return (
    <span
      className={classes.leftArrow}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <ArrowBackIosRoundedIcon />
    </span>
  );
};

const SamplePrevArrow = props => {
  const classes = useStyles();
  const { style, onClick } = props;
  return (
    <span
      onClick={onClick}
      className={classes.rightArrow}
      style={{ ...style, display: 'block' }}
    >
      <ArrowForwardIosRoundedIcon />
    </span>
  );
};

const PictureSlider = ({ pictures }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isInfinite, setIsInfinite] = useState(false);

  const handleImageClick = (picture) => () => {
    dispatch(saveSelectedImage(picture))
  }

  useEffect(() => {
    if(pictures){
      if(pictures.length > 3){
        setIsInfinite(true);
      }else {
        setIsInfinite(false)
      }
    }
  }, [pictures]);

  const renderSlides = pictures && pictures.length > 0 ? (
    pictures.map(picture => (
      <div className={classes.slide} key={picture.TrippetPicture_ID} onClick={handleImageClick(picture)}>
        <img src={`${BASE_URL}${picture.File && picture.File.substr(1)}`} alt={picture.Title} />
      </div>
    ))
  ) : null;

  const settings = {
    dots: false,
    infinite: isInfinite,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div
      style={{
        width: '350px',
        margin: '0 auto'
      }}
    >
      <Slider {...settings}>
      {renderSlides}
      </Slider>
    </div>
  );
};

export default PictureSlider;