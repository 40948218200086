import { ROWS_PER_PAGE } from "../../config";
import React, { useEffect, useState } from "react";
import withAdminDashboard from "../../hoc/withAdminDashboard/withAdminDashboard";
import withTitle from "../../hoc/withTitle";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAdminRoles,
  deleteAdminRole,
  saveCurrentPage,
} from "../../store/actions/actions/adminRole";
import { saveCurrentPage as saveCurrentPageAccess } from "../../store/actions/actions/adminRoleAccess";
import Table from "./Table";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import * as userAccess from "../../userAccess";
import AdminRoleModal from "../../components/AdminRole/AdminRoleForm";

const AdminRoles = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const allAdminRoles = useSelector(state => state.adminRole.allAdminRoles);
  const paginationData = useSelector(state => state.adminRole.paginationData);
  const currentPage = useSelector(state => state.adminRole.currentPage);
  const [deletedAdminRole, setDeletedAdminRole] = useState(null);
  const [isOpenConfirmDeleteModal, setisOpenConfirmDeleteModal] = useState(
    false,
  );

  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortedElement, setSortedElement] = useState("Name");
  const [sortedDir, setSortedDir] = useState("asc");
  const [isAdminRoleModalOpen, setIsAdminRoleModalOpen] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    const bodySearch = {
      Page: currentPage,
      RowsPerPage: ROWS_PER_PAGE,
      OrderBy: sortedElement,
      Language_ID: "2",
      OrderDir: sortedDir,
    };

    dispatch(getAllAdminRoles(JSON.stringify(bodySearch)));
  }, [sortedElement, sortedDir, currentPage, dispatch]);

  useEffect(() => {
    if (paginationData) {
      setTotalItemsCount(paginationData.Num);
    }
  }, [paginationData]);

  const handleSortDir = id => () => {
    setSortedDir(sortedElement === id && sortedDir === "asc" ? "desc" : "asc");
    setSortedElement(id);
  };

  const onPaginationChange = activePage => {
    dispatch(saveCurrentPage(activePage));
  };

  const handleConfirmDeleteModalClose = () => {
    setisOpenConfirmDeleteModal(false);
  };

  const handleConfirmDeleteModalOpen = () => {
    setisOpenConfirmDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteAdminRole(JSON.stringify(deletedAdminRole)));
  };

  const handleAdminRoleModalOpen = () => {
    setIsAdminRoleModalOpen(true);
  };

  const handleAdminRoleModalClose = () => {
    setIsAdminRoleModalOpen(false);
  };

  const handleNewClick = () => {
    setDefaultValues(null);
    dispatch(saveCurrentPageAccess(1));
    handleAdminRoleModalOpen();
  };

  return userAccess.canRead(user, userAccess.Table_AdminRoles) ? (
    <Grid container justify="center" className={classes.containerMyTrip}>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {t("Admin Roles")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Fab
            variant="extended"
            className={classes.addTripButton}
            onClick={handleNewClick}
            disabled={!userAccess.canWrite(user, userAccess.Table_AdminRoles)}
          >
            <AddIcon className={classes.extendedIcon} />
            {t("Admin Role")}
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          data={allAdminRoles}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedAdminRole={setDeletedAdminRole}
          sortedElement={sortedElement}
          sortedBy={sortedDir}
          handleSortBy={handleSortDir}
        />
      </Grid>
      <AdminPagination
        activePage={currentPage}
        itemsPerPage={ROWS_PER_PAGE}
        totalItemsCount={totalItemsCount}
        onChange={onPaginationChange}
        title={"admin roles"}
      />
      <ConfirmDeleteModal
        open={isOpenConfirmDeleteModal}
        handleClose={handleConfirmDeleteModalClose}
        handleDelete={handleDelete}
      />
      <AdminRoleModal
        open={isAdminRoleModalOpen}
        handleAdminRoleModalClose={handleAdminRoleModalClose}
        defaultValues={defaultValues}
      />
    </Grid>
  ) : (
    <div>NO ACCESS</div>
  );
};

export default withTitle(withAdminDashboard(AdminRoles), "Admin Admin Roles");
