import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allCountries: null,
  entireCountries: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'Name',
  sortByDirection: 'asc',
  currentCountry: {
    Country_ID: 0,
    Code: '',
    Icon: '',
    MaterialIO: '',
    Names: []
  },
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_COUNTRY: {
      return {
        ...state,
        currentCountry: {
            Country_ID: action.payload.Country_ID,
            Code: action.payload.Code,
            Icon: action.payload.Icon,
            MaterialIO: action.payload.MaterialIO,
            Names: action.payload.Names,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_COUNTRIES: {
      return {
        ...state,
        allCountries: action.payload,
      };
    }
    case adminActionTypes.SAVE_ENTIRE_COUNTRIES: {
      return {
        ...state,
        entireCountries: action.payload,
      };
    }
    case adminActionTypes.SAVE_COUNTRY_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_COUNTRY_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_COUNTRY_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_COUNTRY_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allCountries: null,
        entireCountries: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentCountry: {
            Country_ID: 0,
            Code: '',
            Icon: '',
            MaterialIO: '',
            Names: []
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default countryReducer;