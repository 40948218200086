import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {getUserById} from '../../store/actions/actions/user';

const useStyles = makeStyles(theme => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    top: '-27px !important',
    left: '-8px !important',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    '& > button': {
      width: 40,
      height: 40,
      backgroundColor: '#0E84B8',
    },
  },
}));

const UserButton = ({
  data,
  handleClickedUser,
  handleUserModalOpen,
  handleConfirmDeleteModalOpen,
  handleConfirmForceDeleteModalOpen,
  setDeletedUser,
  setForceDeletedUser,
  disabledDelete,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = action => event => {
    event.stopPropagation();
    event.preventDefault();
    if (action === 'delete') {
        const body = { User_ID: data.User_ID };
        setDeletedUser(body);
        handleConfirmDeleteModalOpen();
    } 
    else if (action === 'forcedelete') {
        const body = { User_ID: data.User_ID };
        setForceDeletedUser(body);
        handleConfirmForceDeleteModalOpen();
    } else if (action === 'open') {
      dispatch(getUserById(data.User_ID));
      let recordBody = {
        User_ID: data.User_ID,
        FirstName: data.FirstName,
        LastName: data.LastName,
        Email: data.Email,
        DisplayName: data.DisplayName,
        Gender: data.Gender,
        HomeAddress: data.HomeAddress,
        PhoneNumber: data.PhoneNumber,
        Country_ID: data.Country_ID,
        BaseCurrency_ID: data.BaseCurrency_ID,
        BirthDate: data.BirthDate,
        AdminRole_ID: data.AdminRole_ID,
        Language_ID: data.Language_ID,
        Department_ID: data.Department_ID,
        TimeZone: data.TimeZone,
        TimeZoneDesc: data.TimeZoneDesc,
        Status: data.Status,
        Picture: data.Picture,
        CountryName: data.CountryName,
        BaseCurrencyName: data.BaseCurrencyName,
        DepartmentName: data.DepartmentName,
      };
      handleClickedUser(recordBody);
      handleUserModalOpen();
    }
  };

  const actions = [
    { icon: <OpenInNewIcon />, name: t('Open'), action: 'open' },
    { icon: <DeleteIcon />, name: t('Delete'), action: 'delete' },
    { icon: <DeleteForeverIcon />, name: t('Forece Delete'), action: 'forcedelete' },
  ];

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="right"
        onClick={handleClick("")}
      >
        {actions.map(action => {
          if (action.action === 'delete' || action.action === 'forcedelete') {
            return (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipPlacement="top"
                onClick={handleClick(action.action)}
                disabled={disabledDelete}
              />
            );
          } else {
            return (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipPlacement="top"
                onClick={handleClick(action.action)}
              />
            );
          }
        })}
      </SpeedDial>
    </div>
  );
};

export default UserButton;
