import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allPreferenceItems: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'Name',
  sortByDirection: 'asc',
  currentPreferenceItem: {
    PreferenceItem_ID: 0,
    PreferenceCategory_ID: 0,
    PreferenceCategoryName: '',
    Name: '',
    Names: []
  },
};

const preferenceItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_PREFERENCEITEM: {
      return {
        ...state,
        currentPreferenceItem: {
            PreferenceItem_ID: action.payload.PreferenceItem_ID,
            PreferenceCategory_ID: action.payload.PreferenceCategory_ID,
            PreferenceCategoryName: action.payload.PreferenceCategoryName,
            Name: action.payload.Name,
            Names: action.payload.Names,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_PREFERENCEITEMS: {
      return {
        ...state,
        allPreferenceItems: action.payload,
      };
    }
    case adminActionTypes.SAVE_PREFERENCEITEM_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_PREFERENCEITEM_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_PREFERENCEITEM_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_PREFERENCEITEM_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allPreferenceItems: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentPreferenceItem: {
            PreferenceItem_ID: 0,
            PreferenceCategory_ID: '',
            PreferenceCategoryName: '',
            Name: '',
            Names: []
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default preferenceItemReducer;