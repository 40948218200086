import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { columns } from './columns';
import useStyles from './styles';
import TrippetTypeLookButton from './TrippetTypeLookButton';
import { getTrippetTypeLookById } from '../../store/actions/actions/trippetTypeLook';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { BASE_URL } from '../../config';
import * as userAccess from '../../userAccess';
import TrippetTypeLookModal from '../../components/TrippetType/TrippetTypeLookForm';

const createData = (
    TrippetTypeLook_ID,
    TrippetTypeCategory_ID,
    TrippetTypeCategoryName,
    MaterialIO,
    Color,
    Icon,
    SortIndex,
    Name,
    Names,
) => {
  return {
    TrippetTypeLook_ID,
    TrippetTypeCategory_ID,
    TrippetTypeCategoryName,
    MaterialIO,
    Color,
    Icon,
    SortIndex,
    Name,
    Names,
  };
};

const TrippetTypeLookTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedTrippetTypeLook,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedTrippetTypeLook, setClickedTrippetTypeLook] = useState([]);
  const [isTrippetTypeLookModalOpen, setIsTrippetTypeLookModalOpen] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(useSelector(state => state.user.user), userAccess.Table_TrippetTypes);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(
          createData(
            item.TrippetTypeLook_ID,
            item.TrippetTypeCategory_ID,
            item.TrippetTypeCategoryName,
            item.MaterialIO,
            item.Color,
            item.Icon,
            item.SortIndex,
            item.Name,
            item.Names,
          ),
        );
      });
      setRows(elements);
    }
  };

  const handleClickedTrippetTypeLook = value => {
    setClickedTrippetTypeLook(value);
  };

  const handleTrippetTypeLookModalOpen = () => {
    setIsTrippetTypeLookModalOpen(true);
  };

  const handleTrippetTypeLookModalClose = () => {
    setIsTrippetTypeLookModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === 'actions') {
        return (
            <TrippetTypeLookButton
              data={row}
              handleClickedTrippetTypeLook={handleClickedTrippetTypeLook}
              handleTrippetTypeLookModalOpen={handleTrippetTypeLookModalOpen}
              handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
              setDeletedTrippetTypeLook={setDeletedTrippetTypeLook}
              disabledDelete={disableDelete}
            />
          );
    }
    else if (column.id === 'Icon' && value)
    {
      return <img src={`${BASE_URL}${value.substr(1)}`} className={classes.avatar}  alt='Icon' />
    }
    else if (column.id === 'Color' && value)
    {
      return <span>{value} <span style={{backgroundColor: '#' + value, width: '30px', display: 'inline-block'}}>&nbsp;</span></span>
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    dispatch(getTrippetTypeLookById(value.TrippetTypeLook_ID));
      let recordBody = {
        TrippetTypeLook_ID: value.TrippetTypeLook_ID,
        TrippetTypeCategory_ID: value.TrippetTypeCategory_ID,
        TrippetTypeCategoryName: value.TrippetTypeCategoryName,
        MaterialIO: value.MaterialIO,
        Color: value.Color,
        Icon: value.Icon,
        SortIndex: value.SortIndex,
        Names: value.Names,
      };
      handleClickedTrippetTypeLook(recordBody);
      handleTrippetTypeLookModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.TrippetTypeLook_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName = classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== 'actions') {
                    return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.tableHeaderCell}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            onClick={handleSortBy(column.id)}
                            active={sortedElement === column.id}
                            direction={
                              sortedElement === column.id
                                ? sortedBy
                                : 'asc'
                            }
                          >
                            {t(column.label)}
                          </TableSortLabel>
                        </TableCell>
                      );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <TrippetTypeLookModal
        open={isTrippetTypeLookModalOpen}
        handleTrippetTypeLookModalClose={handleTrippetTypeLookModalClose}
        defaultValues={clickedTrippetTypeLook}
      />
    </Paper>
  );
};

export default TrippetTypeLookTable;
