export const columns = [
  { id: 'actions', label: '', minWidth: 60, type: 'actions' },
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    type: 'TrippetTypeLookName',
    filter: 'typelooks',
  },
  {
    id: 'title',
    label: 'Trippet Title',
    minWidth: 80,
    type: 'Title',
    filter: 'title',
  },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 80,
    type: 'StartDate',
    filter: 'startdates',
  },
  {
    id: 'startTime',
    label: 'Time',
    minWidth: 80,
    type: 'StartDate',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 80,
    type: 'EndDate',
    filter: 'enddates',
  },
  {
    id: 'endTime',
    label: 'Time',
    minWidth: 80,
    type: 'EndDate',
  },
  {
    id: 'distance',
    label: 'Distance',
    minWidth: 80,
    type: 'Distance',
  },
  // {
  //   id: 'status',
  //   label: 'Status',
  //   minWidth: 80,
  //   type: 'OwnerPlanningStatus_ID',
  //   filter: 'ownerplanningstatuses',
  // },
  {
    id: 'isPrivate',
    label: 'Private',
    minWidth: 80,
    type: 'IsPrivate',
    filter: 'isprivates',
  },
];
