import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { columns } from './columns';
import useStyles from '../styles';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import TrippetButton from '../TrippetButton';
import {
  getAllPublicDocumentsFromTheSingleTrippet,
  getAllPrivateDocumentsFromTheSingleTrippet,
  showTrippetForm,
  getTrippetImages
} from '../../../store/actions/actions/trippet';
import PictureThumbnail from '../../../assets/thumbnail.png'
import { useTranslation } from 'react-i18next';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { BASE_URL } from '../../../config';
import Badge from '@material-ui/core/Badge';

const createData = (
  type,
  title,
  startDate,
  startTime,
  endDate,
  endTime,
  image,
  documents,
  color,
  fullTrippet,
) => {
  return {
    type,
    title,
    startDate,
    startTime,
    endDate,
    endTime,
    image,
    documents,
    color,
    fullTrippet,
  };
};

const TrippetDataTableFive = ({
  setDeletedTrippet,
  handleConfirmDeleteModalOpen,
  handleSortBy,
  sortedBy,
  sortedElement,
  handleOpenFilterModal,
  disabledFilters,
  activeFilters,
  filterResults,
  getNumberOfActiveFilters

}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dateLanguage, setLanguage] = useState('');
  const dispatch = useDispatch();
  const trippets = useSelector(state => state.trippet.allTrippets);
  const language = useSelector(state => state.programSettings.language);
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const user = useSelector(state => state.user.user);

  const { t } = useTranslation();
  const isOpenTrippetFrom = useSelector(
    state => state.trippet.isOpenTrippetFrom,
  );

  useEffect(() => {
    if (language === 'en') {
      setLanguage('');
    } else if (language === 'de') {
      setLanguage(deLocale);
    }
  }, [language]);

  const getTrippetTypeColor = data => {
    if (trippetTypes) {
      let element = trippetTypes.find(type => data === type.TrippetTypeLook_ID);
      return element.Color;
    }
  };

  const renderImage = path => {
    if (path) {
      return <img style={{ width: 100 }} src={`${BASE_URL}${path && path.substr(1)}`} alt="trippetImage" />
    } else {
      return <img style={{ width: 100 }} src={PictureThumbnail} alt="thumbnail" />
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (trippets && trippets.length) {
      trippets.forEach(trippet => {
        elements.push(
          createData(
            trippet.TrippetTypeLookName,
            trippet.Title,
            format(new Date(trippet.StartDate), 'EEEEEE.dd MMM', {
              locale: dateLanguage,
            }),
            format(new Date(trippet.StartDate), 'HH:mm'),
            format(new Date(trippet.EndDate), 'EEEEEE.dd MMM', {
              locale: dateLanguage,
            }),
            format(new Date(trippet.EndDate), 'HH:mm'),
            renderImage(trippet.Thumbnail),
            trippet.NumPublicDocuments,
            getTrippetTypeColor(trippet.TrippetTypeLook_ID),
            trippet,
          ),
        );
      });
      setRows(elements);
    }
  };

  useEffect(() => {
    if (trippets && trippets.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trippets, trippetTypes]);

  const renderData = (column, value, row) => {
    if (column.id === 'actions') {
      return (
        <TrippetButton
          trippet={row}
          isOpenTrippetFrom={isOpenTrippetFrom}
          setDeletedTrippet={setDeletedTrippet}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          sortedBy={sortedBy}
          sortedElement={sortedElement}
          filterResults={filterResults}
        />
      );
    } else {
      return value;
    }
  };

  const handleTrippetFormOpen = rowData => {
    dispatch(showTrippetForm(rowData));
  };

  const handleRowClick = rowData => () => {
    if (!isOpenTrippetFrom) {
      const privateDocument = {
        Trippet_ID: rowData.fullTrippet.Trippet_ID,
        IsPrivate: '1',
        CreatorUser_ID: user.User_ID,
      }
      dispatch(getAllPrivateDocumentsFromTheSingleTrippet(JSON.stringify(privateDocument)));

      const publicDocument = {
        Trippet_ID: rowData.fullTrippet.Trippet_ID,
        IsPrivate: '0',
      }
      dispatch(getAllPublicDocumentsFromTheSingleTrippet(JSON.stringify(publicDocument)));

      const trippetImage = {
        Trippet_ID: rowData.fullTrippet.Trippet_ID,
      }
      dispatch(getTrippetImages(JSON.stringify(trippetImage)))
      handleTrippetFormOpen(rowData);
    }
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.fullTrippet.Trippet_ID}
            onClick={handleRowClick(row)}
            className={classes.rowContainer}
          >
            {columns.map((column, index) => {
              const value = row[column.id];
              const styleName =
                index === 0 ? `5px solid #${row.color}` : classes.tableCell;
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableCell}
                  style={{ borderLeft: styleName }}
                >
                  {renderData(column, value, row)}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                const renederActiveSortingArrow = sortedElement === column.type ? classes.tableHeaderCellActive : classes.tableHeaderCell;
                if (column.id !== 'actions' && column.id !== 'image' && column.id !== 'documents') {
                  if (column.filter) {
                    let activeColor = activeFilters[column.filter]
                      ? classes.activeFilter
                      : '';
                    const renderNumber = getNumberOfActiveFilters(column);

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : 'asc'
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                        <IconButton
                          className={activeColor}
                          onClick={handleOpenFilterModal(column)}
                          disabled={disabledFilters[column.filter]}
                        >
                           <Badge badgeContent={renderNumber} color="primary">
                            <FilterListIcon />
                          </Badge>
                        </IconButton>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={renederActiveSortingArrow}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          onClick={handleSortBy(column.id)}
                          active={true}
                          direction={
                            sortedElement === column.type
                              ? sortedBy[sortedElement]
                              : 'asc'
                          }
                        >
                          {t(column.label)}
                        </TableSortLabel>
                      </TableCell>
                    );
                  }
                } else {
                  if (column.id === 'documents') {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeaderCell}
                        style={{ minWidth: column.minWidth }}
                      >
                        {t(column.label)}
                        <Tooltip
                          title={t('Total number of public documents')}
                          placement="top"
                          arrow
                        >
                          <InfoIcon className={classes.infoIcon} />
                        </Tooltip>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeaderCell}
                        style={{ minWidth: column.minWidth }}
                      >
                        {t(column.label)}
                      </TableCell>
                    );
                  }
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TrippetDataTableFive;
