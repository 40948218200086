import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import {clearTrippetImages} from '../../store/actions/actions/trippet';
import {useDispatch} from 'react-redux';

const UploadPictureModal = ({ open, handleClose, getUploadParams, handleChangeStatus, classes }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(clearTrippetImages());
    handleClose();
  };

  const dropZoneStyles = {
    previewContainer: {
      padding: '0 10px',
    },
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
          style={{ width: 550 }}
        >
          <DialogContentText>{t('Upload picture')}</DialogContentText>
          <Dropzone
            getUploadParams={getUploadParams}
            classNames={dropZoneStyles}
            onChangeStatus={handleChangeStatus}
            addClassNames={classes.dropZoneDocument}
            accept="image/jpg/*,image/png/*,image/gif*"
            inputContent={(_files, extra) => (extra.reject ? t('PNG, JPEG and GIF files only') : t('Upload or Drag Images'))}
            styles={{
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (_files, extra) => (extra.reject ? { color: 'red' } : {}),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseModal} color="primary">
            {t('Discard')}
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadPictureModal;
