import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../config';
import TextField from '@material-ui/core/TextField';

const PreviewModalImage = ({ open, handleClose, picture, classes, handleImageComments, defaultPicture }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [comments, setComments] = useState('');
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    if (picture && picture.Comments) {
      setComments(picture.Comments)
    }
  }, [picture])

  useEffect(() => {
    if (defaultPicture) {
      if (defaultPicture.File === picture.File) {
        setIsDefault(true);
      } else {
        setIsDefault(false);
      }
    } else {
      setIsDefault(false);
    }
  }, [defaultPicture, picture])


  const handleComment = (event) => {
    setComments(event.target.value);
  }

  const handleSave = () => {
    handleImageComments(picture, comments, isDefault);
    handleClose();
    setComments('');
  }

  const handleCloseButton = () => {
    handleClose();
    setComments('');
  };

  const handleSetDefault = event => {
    setIsDefault(event.target.checked)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseButton}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
        >
          <DialogContentText>{picture && picture.Title}</DialogContentText>
          <div>
            <img
              src={`${BASE_URL}${picture && picture.File && picture.File.substr(1)}`}
              alt={picture && picture.Title}
              className={classes.fullSizePreviewImage} />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isDefault}
                onChange={handleSetDefault}
                name="privateTrip"
                color="primary"
              />
            }
            label={t('Trippet Default Image')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="comments"
            label={t('Comment')}
            placeholder={t('Image comment')}
            name="comment"
            autoComplete="trip-message"
            autoFocus
            value={comments}
            onChange={handleComment}
            multiline
            rowsMax="4"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseButton} color="primary">
            {t('Close')}
          </Button>
          <Button autoFocus onClick={handleSave} color="primary">
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PreviewModalImage;
