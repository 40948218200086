export const columns = [
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    type: 'TrippetTypeLookName',
  },
  { id: 'title', label: 'Trippet Title', minWidth: 80, type: 'Title' },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 80,
    type: 'StartDate',
  },
  {
    id: 'startTime',
    label: 'Time',
    minWidth: 80,
    type: 'StartDate',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 80,
    type: 'EndDate',
  },
  {
    id: 'endTime',
    label: 'Time',
    minWidth: 80,
    type: 'EndDate',
  },
  {
    id: 'link',
    label: 'Link',
    minWidth: 80,
    type: 'Link',
  },
  {
    id: 'distance',
    label: 'Distance',
    minWidth: 80,
    type: 'Distance',
  },
  {
    id: 'isWholeDay',
    label: 'All day',
    minWidth: 80,
    type: 'IsWholeDay',
  },
];
