import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewUserRole, changeUserRole } from '../../store/actions/actions/userRole';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import UserRoleAllowList from '../../components/UserRoleAllowTable/UserRoleAllowList';
import * as userAccess from '../../userAccess';

const UserRoleModal = ({ open, handleUserRoleModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [formError, setFormError] = useState(false);
  const currentUserRole = useSelector(state => state.userRole.currentUserRole);
  const [values, setValues] = useState({
    Name: '',
    Type: 0,
    TripView: 0,
    TripEdit: 0,
    TripDelete: 0,
    TripParticipantView: 0,
    TripParticipantAdd: 0,
    TripParticipantEdit: 0,
    TripParticipantDelete: 0,
    TripProxyView: 0,
    TripProxyAdd: 0,
    TripProxyEdit: 0,
    TripProxyDelete: 0,
    TripOrganizerView: 0,
    TripOrganizerAdd: 0,
    TripOrganizerEdit: 0,
    TripOrganizerDelete: 0,
    TrippetView: 0,
    TrippetAdd: 0,
    TrippetEdit: 0,
    TrippetDelete: 0,
    TrippetJoin: 0,
    TrippetParticipantView: 0,
    TrippetParticipantAdd: 0,
    TrippetParticipantEdit: 0,
    TrippetParticipantDelete: 0,
    TrippetProxyView: 0,
    TrippetProxyAdd: 0,
    TrippetProxyEdit: 0,
    TrippetProxyDelete: 0,
    TrippetOrganizerView: 0,
    TrippetOrganizerAdd: 0,
    TrippetOrganizerEdit: 0,
    TrippetOrganizerDelete: 0,
    TrippetDocumentView: 0,
    TrippetDocumentAdd: 0,
    TrippetDocumentEdit: 0,
    TrippetDocumentDelete: 0,
    TrippetPictureView: 0,
    TrippetPictureAdd: 0,
    TrippetPictureEdit: 0,
    TrippetPictureDelete: 0,
    TrippetChat: 0
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    if (defaultValues) {
        if (currentUserRole)
        {
            setValues({
                UserRole_ID: currentUserRole.UserRole_ID,
                Name: currentUserRole.Name,
                Type: currentUserRole.Type,
                TripView: currentUserRole.TripView,
                TripEdit: currentUserRole.TripEdit,
                TripDelete: currentUserRole.TripDelete,
                TripParticipantView: currentUserRole.TripParticipantView,
                TripParticipantAdd: currentUserRole.TripParticipantAdd,
                TripParticipantEdit: currentUserRole.TripParticipantEdit,
                TripParticipantDelete: currentUserRole.TripParticipantDelete,
                TripProxyView: currentUserRole.TripProxyView,
                TripProxyAdd: currentUserRole.TripProxyAdd,
                TripProxyEdit: currentUserRole.TripProxyEdit,
                TripProxyDelete: currentUserRole.TripProxyDelete,
                TripOrganizerView: currentUserRole.TripOrganizerView,
                TripOrganizerAdd: currentUserRole.TripOrganizerAdd,
                TripOrganizerEdit: currentUserRole.TripOrganizerEdit,
                TripOrganizerDelete: currentUserRole.TripOrganizerDelete,
                TrippetView: currentUserRole.TrippetView,
                TrippetAdd: currentUserRole.TrippetAdd,
                TrippetEdit: currentUserRole.TrippetEdit,
                TrippetDelete: currentUserRole.TrippetDelete,
                TrippetJoin: currentUserRole.TrippetJoin,
                TrippetParticipantView: currentUserRole.TrippetParticipantView,
                TrippetParticipantAdd: currentUserRole.TrippetParticipantAdd,
                TrippetParticipantEdit: currentUserRole.TrippetParticipantEdit,
                TrippetParticipantDelete: currentUserRole.TrippetParticipantDelete,
                TrippetProxyView: currentUserRole.TrippetProxyView,
                TrippetProxyAdd: currentUserRole.TrippetProxyAdd,
                TrippetProxyEdit: currentUserRole.TrippetProxyEdit,
                TrippetProxyDelete: currentUserRole.TrippetProxyDelete,
                TrippetOrganizerView: currentUserRole.TrippetOrganizerView,
                TrippetOrganizerAdd: currentUserRole.TrippetOrganizerAdd,
                TrippetOrganizerEdit: currentUserRole.TrippetOrganizerEdit,
                TrippetOrganizerDelete: currentUserRole.TrippetOrganizerDelete,
                TrippetDocumentView: currentUserRole.TrippetDocumentView,
                TrippetDocumentAdd: currentUserRole.TrippetDocumentAdd,
                TrippetDocumentEdit: currentUserRole.TrippetDocumentEdit,
                TrippetDocumentDelete: currentUserRole.TrippetDocumentDelete,
                TrippetPictureView: currentUserRole.TrippetPictureView,
                TrippetPictureAdd: currentUserRole.TrippetPictureAdd,
                TrippetPictureEdit: currentUserRole.TrippetPictureEdit,
                TrippetPictureDelete: currentUserRole.TrippetPictureDelete,
                TrippetChat: currentUserRole.TrippetChat
            });
        }
        else
        {
            setValues({
                UserRole_ID: defaultValues.UserRole_ID,
                Name: defaultValues.Name,
                Type: defaultValues.Type,
                TripView: defaultValues.TripView,
                TripEdit: defaultValues.TripEdit,
                TripDelete: defaultValues.TripDelete,
                TripParticipantView: defaultValues.TripParticipantView,
                TripParticipantAdd: defaultValues.TripParticipantAdd,
                TripParticipantEdit: defaultValues.TripParticipantEdit,
                TripParticipantDelete: defaultValues.TripParticipantDelete,
                TripProxyView: defaultValues.TripProxyView,
                TripProxyAdd: defaultValues.TripProxyAdd,
                TripProxyEdit: defaultValues.TripProxyEdit,
                TripProxyDelete: defaultValues.TripProxyDelete,
                TripOrganizerView: defaultValues.TripOrganizerView,
                TripOrganizerAdd: defaultValues.TripOrganizerAdd,
                TripOrganizerEdit: defaultValues.TripOrganizerEdit,
                TripOrganizerDelete: defaultValues.TripOrganizerDelete,
                TrippetView: defaultValues.TrippetView,
                TrippetAdd: defaultValues.TrippetAdd,
                TrippetEdit: defaultValues.TrippetEdit,
                TrippetDelete: defaultValues.TrippetDelete,
                TrippetJoin: defaultValues.TrippetJoin,
                TrippetParticipantView: defaultValues.TrippetParticipantView,
                TrippetParticipantAdd: defaultValues.TrippetParticipantAdd,
                TrippetParticipantEdit: defaultValues.TrippetParticipantEdit,
                TrippetParticipantDelete: defaultValues.TrippetParticipantDelete,
                TrippetProxyView: defaultValues.TrippetProxyView,
                TrippetProxyAdd: defaultValues.TrippetProxyAdd,
                TrippetProxyEdit: defaultValues.TrippetProxyEdit,
                TrippetProxyDelete: defaultValues.TrippetProxyDelete,
                TrippetOrganizerView: defaultValues.TrippetOrganizerView,
                TrippetOrganizerAdd: defaultValues.TrippetOrganizerAdd,
                TrippetOrganizerEdit: defaultValues.TrippetOrganizerEdit,
                TrippetOrganizerDelete: defaultValues.TrippetOrganizerDelete,
                TrippetDocumentView: defaultValues.TrippetDocumentView,
                TrippetDocumentAdd: defaultValues.TrippetDocumentAdd,
                TrippetDocumentEdit: defaultValues.TrippetDocumentEdit,
                TrippetDocumentDelete: defaultValues.TrippetDocumentDelete,
                TrippetPictureView: defaultValues.TrippetPictureView,
                TrippetPictureAdd: defaultValues.TrippetPictureAdd,
                TrippetPictureEdit: defaultValues.TrippetPictureEdit,
                TrippetPictureDelete: defaultValues.TrippetPictureDelete,
                TrippetChat: defaultValues.TrippetChat
            });
        }
        
        handleIsEditMode(true);
    } else {
      setValues({
        UserRole_ID: 0,
        Name: '',
        Type: 0,
        TripView: 0,
        TripEdit: 0,
        TripDelete: 0,
        TripParticipantView: 0,
        TripParticipantAdd: 0,
        TripParticipantEdit: 0,
        TripParticipantDelete: 0,
        TripProxyView: 0,
        TripProxyAdd: 0,
        TripProxyEdit: 0,
        TripProxyDelete: 0,
        TripOrganizerView: 0,
        TripOrganizerAdd: 0,
        TripOrganizerEdit: 0,
        TripOrganizerDelete: 0,
        TrippetView: 0,
        TrippetAdd: 0,
        TrippetEdit: 0,
        TrippetDelete: 0,
        TrippetJoin: 0,
        TrippetParticipantView: 0,
        TrippetParticipantAdd: 0,
        TrippetParticipantEdit: 0,
        TrippetParticipantDelete: 0,
        TrippetProxyView: 0,
        TrippetProxyAdd: 0,
        TrippetProxyEdit: 0,
        TrippetProxyDelete: 0,
        TrippetOrganizerView: 0,
        TrippetOrganizerAdd: 0,
        TrippetOrganizerEdit: 0,
        TrippetOrganizerDelete: 0,
        TrippetDocumentView: 0,
        TrippetDocumentAdd: 0,
        TrippetDocumentEdit: 0,
        TrippetDocumentDelete: 0,
        TrippetPictureView: 0,
        TrippetPictureAdd: 0,
        TrippetPictureEdit: 0,
        TrippetPictureDelete: 0,
        TrippetChat: 0
      });
      handleIsEditMode(false);
    }
  }, [defaultValues, currentUserRole]);

  const validateForm = ({ Name }) => {
    if (Name) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };


  const handleSubmit = e => {
    e.stopPropagation();
    if (!nameError) {
        e.preventDefault();
        const data = {
            UserRole_ID: +values.UserRole_ID,
            Name: values.Name,
            Type: values.Type,
            TripView: values.TripView,
            TripEdit: values.TripEdit,
            TripDelete: values.TripDelete,
            TripParticipantView: values.TripParticipantView,
            TripParticipantAdd: values.TripParticipantAdd,
            TripParticipantEdit: values.TripParticipantEdit,
            TripParticipantDelete: values.TripParticipantDelete,
            TripProxyView: values.TripProxyView,
            TripProxyAdd: values.TripProxyAdd,
            TripProxyEdit: values.TripProxyEdit,
            TripProxyDelete: values.TripProxyDelete,
            TripOrganizerView: values.TripOrganizerView,
            TripOrganizerAdd: values.TripOrganizerAdd,
            TripOrganizerEdit: values.TripOrganizerEdit,
            TripOrganizerDelete: values.TripOrganizerDelete,
            TrippetView: values.TrippetView,
            TrippetAdd: values.TrippetAdd,
            TrippetEdit: values.TrippetEdit,
            TrippetDelete: values.TrippetDelete,
            TrippetJoin: values.TrippetJoin,
            TrippetParticipantView: values.TrippetParticipantView,
            TrippetParticipantAdd: values.TrippetParticipantAdd,
            TrippetParticipantEdit: values.TrippetParticipantEdit,
            TrippetParticipantDelete: values.TrippetParticipantDelete,
            TrippetProxyView: values.TrippetProxyView,
            TrippetProxyAdd: values.TrippetProxyAdd,
            TrippetProxyEdit: values.TrippetProxyEdit,
            TrippetProxyDelete: values.TrippetProxyDelete,
            TrippetOrganizerView: values.TrippetOrganizerView,
            TrippetOrganizerAdd: values.TrippetOrganizerAdd,
            TrippetOrganizerEdit: values.TrippetOrganizerEdit,
            TrippetOrganizerDelete: values.TrippetOrganizerDelete,
            TrippetDocumentView: values.TrippetDocumentView,
            TrippetDocumentAdd: values.TrippetDocumentAdd,
            TrippetDocumentEdit: values.TrippetDocumentEdit,
            TrippetDocumentDelete: values.TrippetDocumentDelete,
            TrippetPictureView: values.TrippetPictureView,
            TrippetPictureAdd: values.TrippetPictureAdd,
            TrippetPictureEdit: values.TrippetPictureEdit,
            TrippetPictureDelete: values.TrippetPictureDelete,
            TrippetChat: values.TrippetChat
        };
      if (isEditMode) {
        if (validateForm(data)) {
          dispatch(changeUserRole(JSON.stringify(data)));
          handleUserRoleModalClose();
        }
      } else {
        if (validateForm(data)) {
          dispatch(createNewUserRole(JSON.stringify(data)));
          handleUserRoleModalClose();
        }
      }
    }
  };

  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name === 'Name') {
      if (e.target.value.length === 0) {
        setNameError(true);
      } else {
        setNameError(false);
      }
    }
    setValues({...values, [e.target.name]: e.target.value});
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const renderTitle = defaultValues ? t('Edit User Role') : t('New User Role');
      
  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;

  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_UserRoles))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_UserRoles));
    
  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.userRoleName}>
                <FormControl variant="outlined" className={classes.selectView}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="Name"
                    label={t('Name')}
                    name="Name"
                    autoFocus
                    value={values.Name}
                    onChange={handleInputs}
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" className={classes.selectView}
                  margin="normal">
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('Type')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label={t('Type')}
                    name="Type"
                    value={values.Type}
                    onChange={handleInputs}
                  >
                    <MenuItem value="1">{t('Proxy')}</MenuItem>
                    <MenuItem value="2">{t('Participant')}</MenuItem>
                    <MenuItem value="3">{t('Organizer')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('Trip')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripView) === 1}
                            onChange={handleInputs}
                            name="TripView"
                            id="TripView"
                            color="primary"
                            value={Number(values.TripView) === 1 ? 0 : 1}
                        />
                        }
                        label={t('View')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripEdit) === 1}
                            onChange={handleInputs}
                            name="TripEdit"
                            id="TripEdit"
                            color="primary"
                            value={Number(values.TripEdit) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Edit')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripDelete) === 1}
                            onChange={handleInputs}
                            name="TripDelete"
                            id="TripDelete"
                            color="primary"
                            value={Number(values.TripDelete) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Delete')}
                    />
                </Grid>
              </Grid>
              
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('TripParticipant')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripParticipantView) === 1}
                            onChange={handleInputs}
                            name="TripParticipantView"
                            id="TripParticipantView"
                            color="primary"
                            value={Number(values.TripParticipantView) === 1 ? 0 : 1}
                        />
                        }
                        label={t('View')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripParticipantAdd) === 1}
                            onChange={handleInputs}
                            name="TripParticipantAdd"
                            id="TripParticipantAdd"
                            color="primary"
                            value={Number(values.TripParticipantAdd) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Add')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripParticipantEdit) === 1}
                            onChange={handleInputs}
                            name="TripParticipantEdit"
                            id="TripParticipantEdit"
                            color="primary"
                            value={Number(values.TripParticipantEdit) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Edit')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripParticipantDelete) === 1}
                            onChange={handleInputs}
                            name="TripParticipantDelete"
                            id="TripParticipantDelete"
                            color="primary"
                            value={Number(values.TripParticipantDelete) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Delete')}
                    />
                </Grid>
              </Grid>
              
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('TripProxy')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripProxyView) === 1}
                            onChange={handleInputs}
                            name="TripProxyView"
                            id="TripProxyView"
                            color="primary"
                            value={Number(values.TripProxyView) === 1 ? 0 : 1}
                        />
                        }
                        label={t('View')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripProxyAdd) === 1}
                            onChange={handleInputs}
                            name="TripProxyAdd"
                            id="TripProxyAdd"
                            color="primary"
                            value={Number(values.TripProxyAdd) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Add')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripProxyEdit) === 1}
                            onChange={handleInputs}
                            name="TripProxyEdit"
                            id="TripProxyEdit"
                            color="primary"
                            value={Number(values.TripProxyEdit) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Edit')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripProxyDelete) === 1}
                            onChange={handleInputs}
                            name="TripProxyDelete"
                            id="TripProxyDelete"
                            color="primary"
                            value={Number(values.TripProxyDelete) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Delete')}
                    />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('TripOrganizer')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripOrganizerView) === 1}
                            onChange={handleInputs}
                            name="TripOrganizerView"
                            id="TripOrganizerView"
                            color="primary"
                            value={Number(values.TripOrganizerView) === 1 ? 0 : 1}
                        />
                        }
                        label={t('View')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripOrganizerAdd) === 1}
                            onChange={handleInputs}
                            name="TripOrganizerAdd"
                            id="TripOrganizerAdd"
                            color="primary"
                            value={Number(values.TripOrganizerAdd) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Add')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripOrganizerEdit) === 1}
                            onChange={handleInputs}
                            name="TripOrganizerEdit"
                            id="TripOrganizerEdit"
                            color="primary"
                            value={Number(values.TripOrganizerEdit) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Edit')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TripOrganizerDelete) === 1}
                            onChange={handleInputs}
                            name="TripOrganizerDelete"
                            id="TripOrganizerDelete"
                            color="primary"
                            value={Number(values.TripOrganizerDelete) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Delete')}
                    />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('Trippet')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetView) === 1}
                            onChange={handleInputs}
                            name="TrippetView"
                            id="TrippetView"
                            color="primary"
                            value={Number(values.TrippetView) === 1 ? 0 : 1}
                        />
                        }
                        label={t('View')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetAdd) === 1}
                            onChange={handleInputs}
                            name="TrippetAdd"
                            id="TrippetAdd"
                            color="primary"
                            value={Number(values.TrippetAdd) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Add')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetEdit) === 1}
                            onChange={handleInputs}
                            name="TrippetEdit"
                            id="TrippetEdit"
                            color="primary"
                            value={Number(values.TrippetEdit) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Edit')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetDelete) === 1}
                            onChange={handleInputs}
                            name="TrippetDelete"
                            id="TrippetDelete"
                            color="primary"
                            value={Number(values.TrippetDelete) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Delete')}
                    />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('Trippet')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetJoin) === 1}
                            onChange={handleInputs}
                            name="TrippetJoin"
                            id="TrippetJoin"
                            color="primary"
                            value={Number(values.TrippetJoin) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Join')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetChat) === 1}
                            onChange={handleInputs}
                            name="TrippetChat"
                            id="TrippetChat"
                            color="primary"
                            value={Number(values.TrippetChat) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Chat')}
                    />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('TrippetParticipant')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetParticipantView) === 1}
                            onChange={handleInputs}
                            name="TrippetParticipantView"
                            id="TrippetParticipantView"
                            color="primary"
                            value={Number(values.TrippetParticipantView) === 1 ? 0 : 1}
                        />
                        }
                        label={t('View')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetParticipantAdd) === 1}
                            onChange={handleInputs}
                            name="TrippetParticipantAdd"
                            id="TrippetParticipantAdd"
                            color="primary"
                            value={Number(values.TrippetParticipantAdd) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Add')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetParticipantEdit) === 1}
                            onChange={handleInputs}
                            name="TrippetParticipantEdit"
                            id="TrippetParticipantEdit"
                            color="primary"
                            value={Number(values.TrippetParticipantEdit) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Edit')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetParticipantDelete) === 1}
                            onChange={handleInputs}
                            name="TrippetParticipantDelete"
                            id="TrippetParticipantDelete"
                            color="primary"
                            value={Number(values.TrippetParticipantDelete) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Delete')}
                    />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('TrippetProxy')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetProxyView) === 1}
                            onChange={handleInputs}
                            name="TrippetProxyView"
                            id="TrippetProxyView"
                            color="primary"
                            value={Number(values.TrippetProxyView) === 1 ? 0 : 1}
                        />
                        }
                        label={t('View')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetProxyAdd) === 1}
                            onChange={handleInputs}
                            name="TrippetProxyAdd"
                            id="TrippetProxyAdd"
                            color="primary"
                            value={Number(values.TrippetProxyAdd) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Add')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetProxyEdit) === 1}
                            onChange={handleInputs}
                            name="TrippetProxyEdit"
                            id="TrippetProxyEdit"
                            color="primary"
                            value={Number(values.TrippetProxyEdit) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Edit')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetProxyDelete) === 1}
                            onChange={handleInputs}
                            name="TrippetProxyDelete"
                            id="TrippetProxyDelete"
                            color="primary"
                            value={Number(values.TrippetProxyDelete) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Delete')}
                    />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('TrippetOrganizer')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetOrganizerView) === 1}
                            onChange={handleInputs}
                            name="TrippetOrganizerView"
                            id="TrippetOrganizerView"
                            color="primary"
                            value={Number(values.TrippetOrganizerView) === 1 ? 0 : 1}
                        />
                        }
                        label={t('View')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetOrganizerAdd) === 1}
                            onChange={handleInputs}
                            name="TrippetOrganizerAdd"
                            id="TrippetOrganizerAdd"
                            color="primary"
                            value={Number(values.TrippetOrganizerAdd) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Add')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetOrganizerEdit) === 1}
                            onChange={handleInputs}
                            name="TrippetOrganizerEdit"
                            id="TrippetOrganizerEdit"
                            color="primary"
                            value={Number(values.TrippetOrganizerEdit) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Edit')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetOrganizerDelete) === 1}
                            onChange={handleInputs}
                            name="TrippetOrganizerDelete"
                            id="TrippetOrganizerDelete"
                            color="primary"
                            value={Number(values.TrippetOrganizerDelete) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Delete')}
                    />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('TrippetDocument')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetDocumentView) === 1}
                            onChange={handleInputs}
                            name="TrippetDocumentView"
                            id="TrippetDocumentView"
                            color="primary"
                            value={Number(values.TrippetDocumentView) === 1 ? 0 : 1}
                        />
                        }
                        label={t('View')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetDocumentAdd) === 1}
                            onChange={handleInputs}
                            name="TrippetDocumentAdd"
                            id="TrippetDocumentAdd"
                            color="primary"
                            value={Number(values.TrippetDocumentAdd) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Add')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetDocumentEdit) === 1}
                            onChange={handleInputs}
                            name="TrippetDocumentEdit"
                            id="TrippetDocumentEdit"
                            color="primary"
                            value={Number(values.TrippetDocumentEdit) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Edit')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetDocumentDelete) === 1}
                            onChange={handleInputs}
                            name="TrippetDocumentDelete"
                            id="TrippetDocumentDelete"
                            color="primary"
                            value={Number(values.TrippetDocumentDelete) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Delete')}
                    />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    gutterBottom
                    className={classes.namesTitle}
                  >
                    {t('TrippetPicture')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetPictureView) === 1}
                            onChange={handleInputs}
                            name="TrippetPictureView"
                            id="TrippetPictureView"
                            color="primary"
                            value={Number(values.TrippetPictureView) === 1 ? 0 : 1}
                        />
                        }
                        label={t('View')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetPictureAdd) === 1}
                            onChange={handleInputs}
                            name="TrippetPictureAdd"
                            id="TrippetPictureAdd"
                            color="primary"
                            value={Number(values.TrippetPictureAdd) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Add')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetPictureEdit) === 1}
                            onChange={handleInputs}
                            name="TrippetPictureEdit"
                            id="TrippetPictureEdit"
                            color="primary"
                            value={Number(values.TrippetPictureEdit) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Edit')}
                    />
                </Grid>
                <Grid item xs={12} md={2} className={classes.userRoleName}>
                  <FormControlLabel
                        control={
                        <Checkbox
                            checked={Number(values.TrippetPictureDelete) === 1}
                            onChange={handleInputs}
                            name="TrippetPictureDelete"
                            id="TrippetPictureDelete"
                            color="primary"
                            value={Number(values.TrippetPictureDelete) === 1 ? 0 : 1}
                        />
                        }
                        label={t('Delete')}
                    />
                </Grid>
              </Grid>
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
              
              <Grid item xs={12}>
                {isEditMode && <UserRoleAllowList data={currentUserRole} />}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleUserRoleModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default UserRoleModal;
