import { BASE_URL } from '../../config';
import { showSnackBar } from '../../store/actions/actions/root';
import React, { useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createNewParticipantStatus, changeParticipantStatus } from '../../store/actions/actions/participantStatus';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import {getEntireLanguages} from '../../store/actions/actions/language';
import PreviewIcon from '../PreviewIcon/PreviewIcon';
import IconPicker from '../IconPicker/IconPicker';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../admin.css';
import * as userAccess from '../../userAccess';

const ParticipantStatusModal = ({ open, handleParticipantStatusModalClose, defaultValues }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditMode, handleIsEditMode] = useState(false);
  const [namesError, setNamesError] = useState(false);
  const [formError, setFormError] = useState(false);
  const entireLanguages = useSelector(state => state.language.entireLanguages);
  const currentParticipantStatus = useSelector(state => state.participantStatus.currentParticipantStatus);
  const [values, setValues] = useState({
    Type: 1,
    Icon: '',
    MaterialIO: '',
    IsDefault: false,
    Comments: '',
    Listing: 1,
    SortIndex: 0,
    Names: [],
  });

  // checking if we are in editting or creating mode
  // setting default values if we are in editting mode
  useEffect(() => {
    // make sure languages is already loaded
    if (!entireLanguages)
    {
      dispatch(getEntireLanguages(null));
      return;
    }
    if (defaultValues) {
        // when there is a new language, the name for that language in 'Names' is missing
        // so we need to iterate through entireLanguages and push missed ones
        const names = currentParticipantStatus ? currentParticipantStatus.Names : defaultValues.Names;
        if (names && Array.isArray(names))
          entireLanguages.forEach(name => {
            var found = false;
            names.forEach(name2 => {
              if (Number(name2.Language_ID) === Number(name.Language_ID))
                found = true;
            });
            if (!found)
              names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
        });
        if (currentParticipantStatus)
        {
            setValues({
                Type: currentParticipantStatus.Type,
                Icon: currentParticipantStatus.Icon,
                MaterialIO: currentParticipantStatus.MaterialIO,
                IsDefault: currentParticipantStatus.IsDefault,
                Comments: currentParticipantStatus.Comments,
                Listing: currentParticipantStatus.Listing,
                SortIndex: currentParticipantStatus.SortIndex,
                Names: names,
                ParticipantStatus_ID: currentParticipantStatus.ParticipantStatus_ID,
            });
        }
        else
        {
            setValues({
                Type: defaultValues.Type,
                Icon: defaultValues.Icon,
                MaterialIO: defaultValues.MaterialIO,
                IsDefault: defaultValues.IsDefault,
                Comments: defaultValues.Comments,
                Listing: defaultValues.Listing,
                SortIndex: defaultValues.SortIndex,
                Names: names,
                ParticipantStatus_ID: defaultValues.ParticipantStatus_ID,
            });
        }
        
        handleIsEditMode(true);
    } else {
      
      const names = [];
      entireLanguages.forEach(name => {
        names.push({LanguageAbbreviation: name.Abbreviation, Language_ID: name.Language_ID, Name: ''});
      });
      setValues({
        ParticipantStatus_ID: 0,
        Type: 1,
        Icon: '',
        MaterialIO: '',
        IsDefault: '',
        Comments: '',
        Listing: 1,
        SortIndex: 0,
        Names: names,
      });
      handleIsEditMode(false);
    }
  }, [defaultValues, currentParticipantStatus, dispatch, entireLanguages]);

  const validateForm = ({ Names }) => {
    if (Names && Names.length > 0) {
      setFormError(false);
      return true;
    } else {
      setFormError(true);
      return false;
    }
  };

  const submitValues = () => {
    const data = {
      ParticipantStatus_ID: +values.ParticipantStatus_ID,
      Type: values.Type,
      Icon: values.Icon,
      MaterialIO: values.MaterialIO,
      IsDefault: values.IsDefault,
      Comments: values.Comments,
      Listing: values.Listing,
      SortIndex: values.SortIndex,
      Names: values.Names,
    };
    if (isEditMode) {
      if (validateForm(data)) {
        dispatch(changeParticipantStatus(JSON.stringify(data)));
        handleParticipantStatusModalClose();
      }
    } else {
      if (validateForm(data)) {
        dispatch(createNewParticipantStatus(JSON.stringify(data)));
        handleParticipantStatusModalClose();
      }
    }
  };

  const handleSubmit = e => {
    e.stopPropagation();
    if (!namesError) {
        e.preventDefault();
        
      // check IsDefault
      if (Number(values.IsDefault) === 1)
      {
      fetch(`${BASE_URL}api/participantstatus/search.php`, {
          method: 'POST',
          body: JSON.stringify({IsDefault: 1}),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => {
            if (response.status === 200) {
              return response.text();
            } else if (response.status === 304) {
              dispatch(showSnackBar({type: 'error', message: 'Something went wrong!'}));
            }
          })
          .then(response => {
            const data = JSON.parse(response);
            if (data.Num > 0 && Number(data.Data[0].ParticipantStatus_ID) !== Number(values.ParticipantStatus_ID))
            {
              // we have already another default ParticipantStatus.
              // get confirm
              confirmAlert({
                title: 'Change default Participant Status',
                message: 'There is already a default Participant Status. Do you want to replace it?.',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => submitValues()
                  },
                  {
                    label: 'No',
                  }
                ]
              });
            }
            else
            {
              // it is the only default value
              submitValues();
            }
          })
          .catch(error => console.log(error));
      }
      else
        submitValues();
    }
  };

  const setMaterialIO = icon => {
    setValues({...values, MaterialIO: icon});
  };
  const handleInputs = e => {
    e.stopPropagation();
    if (e.target.name.indexOf("Name") === 0) // it is Names list
    {
        const names = [];
        values.Names.forEach(name => {
            if ("Name" + name.Language_ID === e.target.name)
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: e.target.value});
            else
                names.push({LanguageAbbreviation: name.LanguageAbbreviation, Language_ID: name.Language_ID, Name: name.Name});
        });
        setValues({...values, Names: names});
        setNamesError(names.length === 0);
    }
    else
    {
        setValues({...values, [e.target.name]: e.target.value});
    }
  };

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const clearIcon = () => {
    setValues({...values, Icon: ''});
  };

  const setIcon = file => {
    setValues({...values, Icon: file});
  };

  const renderTitle = defaultValues ? t('Edit Participant Status') : t('New Participant Status');
  const renderNames =
    values.Names && values.Names.length > 0
      ? values.Names.map(name => {
          return (
            <TableRow key={name.Language_ID} className={classes.rowContainer}>
                <TableCell>
                    <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id={"Name" + name.Language_ID}
                    label={name.LanguageAbbreviation}
                    name={"Name" + name.Language_ID}
                    value={name.Name}
                    onChange={handleInputs}
                    />
                </TableCell>
              </TableRow>
          );
        })
      : null;
     
  const renderNamesError = namesError
    ? t('Names are required.')
    : null;

  const renderFormError = formError
    ? t('All fields with * are required.')
    : null;


  const user = useSelector(state => state.user.user);
  const disableSave = (isEditMode && !userAccess.canChange(user, userAccess.Table_ParticipantStatuses))
    || (!isEditMode && !userAccess.canWrite(user, userAccess.Table_ParticipantStatuses));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {renderTitle}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.participantStatusName}>
                  <Grid container>
                    <Grid item xs={12} md={6} className={classes.participantStatusName}>
                      <FormControl variant="outlined" className={classes.selectView}>
                        <InputLabel id="demo-simple-select-outlined-label">
                          {t('Type')}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label={t('Type')}
                          name="Type"
                          value={values.Type}
                          onChange={handleInputs}
                        >
                          <MenuItem value="1">{t('Only Trips')}</MenuItem>
                          <MenuItem value="2">{t('Only Trippets')}</MenuItem>
                          <MenuItem value="3">{t('Both')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.participantStatusName}>
                      <FormControl variant="outlined" className={classes.selectView}>
                        <InputLabel id="demo-simple-select-outlined-label">
                          {t('Listing')}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label={t('Listing')}
                          name="Listing"
                          value={values.Listing}
                          onChange={handleInputs}
                        >
                          <MenuItem value="1">{t('Invitations')}</MenuItem>
                          <MenuItem value="2">{t('My Trips')}</MenuItem>
                          <MenuItem value="3">{t('Other')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.languageName}>
                      <Grid container>
                        <Grid item xs={10} className={classes.languageName}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            id="MaterialIO"
                            label={t('MaterialIO')}
                            name="MaterialIO"
                            autoComplete="MaterialIO"
                            value={values.MaterialIO}
                            onChange={handleInputs}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconPicker selectedIcon={values.MaterialIO} iconSelected={setMaterialIO} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}  className={classes.languageName}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type='number'
                        id="SortIndex"
                        label={t('SortIndex')}
                        name="SortIndex"
                        autoComplete="SortIndex"
                        value={values.SortIndex}
                        onChange={handleInputs}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.participantStatusName}>
                      <FormControlLabel
                          control={
                          <Checkbox
                              checked={Number(values.IsDefault) === 1}
                              onChange={handleInputs}
                              name="IsDefault"
                              id="IsDefault"
                              color="primary"
                              value={Number(values.IsDefault) === 1 ? 0 : 1}
                          />
                          }
                          label={t('IsDefault')}
                      />
                    </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <PreviewIcon currentImage={values.Icon} pictureUrl={values.Icon} clearIcon={clearIcon} setIcon={setIcon}
                type='language' dispatch={dispatch} t={t} />
              </Grid>
              <Grid item xs={12} md={12} className={classes.participantStatusName}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="Comments"
                  label={t('Comments')}
                  name="Comments"
                  autoComplete="Comments"
                  value={values.Comments}
                  onChange={handleInputs}
                />
              </Grid>
              
              <FormHelperText className={classes.errorText}>
                {renderFormError}
              </FormHelperText>
              
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  className={classes.title}
                >
                  {t('Languages')}
                </Typography>
              </Grid>
            </Grid>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                   
                    </TableRow>
                </TableHead>
                <TableBody>{renderNames}</TableBody>
                </Table>
            </TableContainer>
            <FormHelperText className={classes.errorText}>
              {renderNamesError}
            </FormHelperText>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalOpen} color="primary">
            {t('Exit')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleCloseParenModal={handleParticipantStatusModalClose}
        handleClose={handleConfirmModalClose}
      />
    </Fragment>
  );
};

export default ParticipantStatusModal;
