import * as actionTypes from '../actions/actionTypes/actionTypes';

const initialState = {
  participantsSorting: 'lastname',
  language: 'en',
  calendarView: 'month',
  cockpitView: 1,
  zoom: 5,
  visibleWeeksCount: 0,
  startWeekMonday: true,
  workWeek: false,
  taskView: true,
  calendarViewName: 'month',
};

const programSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PARTICIPANTS_SORTING_CRITERIA: {
      return {
        ...state,
        participantsSorting: action.payload,
      };
    }
    case actionTypes.CHANGE_CALENDAR_VIEW: {
      return {
        ...state,
        calendarView: action.payload,
      };
    }
    case actionTypes.CHANGE_MAP_ZOOM:
      return {
        ...state,
        zoom: action.payload,
      };
    case actionTypes.CHANGE_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }
    case actionTypes.CHANGE_COCKPIT_VIEW: {
      return {
        ...state,
        cockpitView: action.payload,
      };
    }
    case actionTypes.CHANGE_VISIBLE_WEEKS_COUNT: {
      return {
        ...state,
        visibleWeeksCount: action.payload,
      };
    }
    case actionTypes.SET_START_WEEK_MONDAY: {
      return {
        ...state,
        startWeekMonday: action.payload,
      };
    }
    case actionTypes.SET_WORK_WEEK: {
      return {
        ...state,
        workWeek: action.payload,
      };
    }
    case actionTypes.SET_TASK_VIEW: {
      return {
        ...state,
        taskView: action.payload,
      };
    }
    case actionTypes.SET_CALENDAR_VIEW_NAME: {
      return {
        ...state,
        calendarViewName: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        participantsSorting: 'lastname',
        language: 'en',
        calendarView: 'month',
        visibleWeeksCount: 0,
        startWeekMonday: true,
        workWeek: false,
        taskView: true,
        calendarViewName: 'month',
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default programSettingsReducer;
