import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { createTrippetRecommendation } from '../../store/actions/actions/trippet';
import { saveTripAndRecommend } from '../../store/actions/actions/trip';

const RecommendationModal = ({
  open,
  handleClose,
  allUsers,
  trippet,
  trip,
}) => {
  const dispatch = useDispatch();
  const [arrayParticipants, setArrayParticipants] = useState([]);
  const [values, setValues] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const sortCriterium = useSelector(
    state => state.programSettings.participantsSorting,
  );
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    if (allUsers) {
      const users = [...allUsers];

      if (sortCriterium === 'lastname') {
        users.sort((a, b) => a.LastName.localeCompare(b.LastName));
        setArrayParticipants(users);
      } else if (sortCriterium === 'firstname') {
        users.sort((a, b) => a.FirstName.localeCompare(b.FirstName));
        setArrayParticipants(users);
      } else {
        setArrayParticipants(users);
      }
    }
  }, [allUsers, sortCriterium]);

  const handleSend = event => {
    event.stopPropagation();
    if (values && values.length > 0) {
      if (trip) {
        const recommendationsIds = [];
        values.forEach(participant =>
          recommendationsIds.push({
            TripRecommendationRecipientUser_ID: `${participant.User_ID}`,
          }),
        );

        const body = {
          Trip_ID: trip.Trip_ID,
          TripRecommendationSenderUser_ID: `${trip.TripRecommendationSenderUser_ID}`,
          Message: '',
          Recipients: recommendationsIds,
        };
        dispatch(saveTripAndRecommend(JSON.stringify(body), user.User_ID));
        handleClose(event);
      } else {
        const { OwnerTrippet, Trippet_ID, OwnerUser_ID } = trippet.fullTrippet;
        const recommendationsIds = [];
        values.forEach(recommendation =>
          recommendationsIds.push({
            TrippetRecommendationRecipientUser_ID: recommendation.User_ID,
          }),
        );
        const body = {
          Trippet_ID: Trippet_ID,
          TrippetRecommendationSenderUser_ID: OwnerTrippet || OwnerUser_ID,
          Message: '',
          Recipients: recommendationsIds,
        };
        dispatch(
          createTrippetRecommendation(JSON.stringify(body), user.User_ID, t),
        );
        handleClose(event);
      }
    }
  };

  const handleChange = (event, newValues) => {
    event.stopPropagation();
    setValues(newValues);
  };

  const renderFirstNameAndLastName = participant =>
    sortCriterium === 'lastname'
      ? `${participant.LastName} ${participant.FirstName}`
      : `${participant.FirstName} ${participant.LastName}`;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClick={event => event.stopPropagation()}
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Who do you want to send to?')}
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={arrayParticipants}
            disableCloseOnSelect
            onChange={handleChange}
            value={values}
            getOptionLabel={option => renderFirstNameAndLastName(option)}
            renderOption={(option, { selected }) => (
              <Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  name={option.user_id}
                  color="primary"
                />
                {renderFirstNameAndLastName(option)}
              </Fragment>
            )}
            style={{ width: 500 }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={t('Participants')}
                placeholder={t('Participants')}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('Exit')}
          </Button>
          <Button
            onClick={handleSend}
            color="primary"
            autoFocus
            variant="contained"
          >
            {t('Send')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RecommendationModal;
