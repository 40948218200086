import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const DeleteUserModal = ({ open, handleClose, user, uniqueUsers, setUniqueUsers }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // const [message, setMessage] = useState('');
  // const [counter, setCounter] = useState(0);

  // const handleMessage = event => {
  //   setMessage(event.target.value);
  //   setCounter(event.target.value.length);
  // };
  const handleSave = () => {
    let allTripUsers = [...uniqueUsers];
    uniqueUsers.forEach((tripUser, index) => {
      if ((tripUser.TripProxyUser_ID || tripUser.TripParticipantUser_ID || tripUser.TripOrganizerUser_ID || tripUser.User_ID) === (user.item.TripProxyUser_ID || user.item.TripParticipantUser_ID || user.item.TripOrganizerUser_ID || user.item.User_ID)) {
        allTripUsers.splice(index, 1);
      }
    })
    setUniqueUsers(allTripUsers);
    handleClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.container}
      >
        <div className={classes.modalTopContainer}>
          <DialogTitle id="alert-dialog-title">{t("Delete User")}</DialogTitle>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.modalContentContainer}>
          <div className={classes.title}>{t("Do you really want to delete user?")}</div>
          <div className={classes.user}>{user && user.name}?</div>
          {/* <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="message"
            label={t('Leave a message to the user here (optionally)')}
            name="message"
            autoComplete="trip-message"
            autoFocus
            value={message}
            onChange={handleMessage}
            className={classes.inputs}
            multiline
            rowsMax="4"
          />
          <FormHelperText>
            {`${counter} / 1500`}
          </FormHelperText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteUserModal;
