import * as actionTypes from "../actionTypes/actionTypes";
import * as adminActionTypes from "../actionTypes/adminActionTypes";
import { BASE_URL, ROWS_PER_PAGE } from "../../../config";
import { showSnackBar } from "./root";
import { getTripById } from "./trip";
import { store } from "../../store";

// ------------------------
// Frontend user management
// ------------------------
export const loginUser = (body, routerHistory, t) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/login.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 405) {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("Wrong password"),
              }),
            );
          } else if (response.status === 402) {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("User is disabled"),
              }),
            );
          } else if (response.status === 404 || response.status === 400) {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("The user does not exist"),
              }),
            );
          } else if (response.status === 403) {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("User is deleted"),
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          localStorage.setItem("token", data.Token);
          dispatch(saveUser(data));
          routerHistory.push("/");
          dispatch(
            showSnackBar({
              type: "success",
              message: t("You are logged in"),
            }),
          );
          if (data.LastTrip_ID > 0) {
            dispatch(getTripById(data.LastTrip_ID, data.User_ID));
          }
        })
        .catch(error => console.log(error));
    }
  };
};

export const createAccount = (body, routerHistory) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/create.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "The email is already used!",
              }),
            );
          } else if (response.status === 302) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong!",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          localStorage.setItem("token", data.Token);
          dispatch(saveUser(data));
          routerHistory.push("/");
          dispatch(
            showSnackBar({
              type: "success",
              message: "You are logged in!",
            }),
          );
        })
        .catch(error => console.log(error));
    }
  };
};

export const getUserByToken = (body, routerHistory) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/login.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong!",
              }),
            );
            routerHistory.push("/login");
            localStorage.removeItem("token");
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          localStorage.setItem("token", data.Token);
          dispatch(saveUser(data));
          if (data.LastTrip_ID > 0) {
            dispatch(getTripById(data.LastTrip_ID, data.User_ID));
          }
        })
        .catch(error => {
          localStorage.removeItem("token");
          console.log(error);
        });
    }
  };
};

export const saveUser = payload => ({
  type: actionTypes.SAVE_USER,
  payload,
});

export const setLastTrip = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/update.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 302) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong!",
              }),
            );
          }
        })
        .then(() => {})
        .catch(error => console.log(error));
    }
  };
};

// ------------------------
// Backend user management
// ------------------------

export const saveCurrentUser = payload => ({
  type: adminActionTypes.SAVE_CURRENT_USER,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_USER_CURRENT_PAGE,
  payload,
});

export const getUserById = id => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/user/get.php?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentUser(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewUser = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/create.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "The email is already used!",
              }),
            );
          } else if (response.status === 302) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong!",
              }),
            );
          } else if (response.status === 503 && response.type !== "cors") {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong!",
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentUser(data));
          dispatch(getAllUsers());
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeUser = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/update.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong!",
              }),
            );
          }
        })
        .then(response => {
          dispatch(getUserById(body.User_ID));
          dispatch(getAllUsers(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllUsers = payload => ({
  type: adminActionTypes.SAVE_ALL_USERS,
  payload,
});

export const saveUserSortElement = payload => ({
  type: adminActionTypes.SAVE_USER_SORT_ELEMENT,
  payload,
});

export const saveUserSortByDirection = payload => ({
  type: adminActionTypes.SAVE_USER_SORT_BY_DIRECTION,
  payload,
});

export const saveUserPaginationData = payload => ({
  type: adminActionTypes.SAVE_USER_PAGINATION_DATA,
  payload,
});

export const getAllUsers = body => {
  return dispatch => {
    if (!body) {
      const searchBody = {
        RowsPerPage: ROWS_PER_PAGE,
        Page: store.getState().user.currentPage,
        OrderBy: store.getState().user.sortElement,
        OrderDir: store.getState().user.sortByDirection,
      };
      body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/user/search.php`, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: "error",
              message: "Something went wrong!",
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllUsers(data.Data));
        dispatch(saveUserPaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteUser = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/delete.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong!",
              }),
            );
          }
        })
        .then(response => {
          dispatch(getAllUsers(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const forceDeleteUser = body => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/forcedelete.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong!",
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: "error",
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else dispatch(getAllUsers(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeUserPassword = (body, t) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/changepassword.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 503 || response.type !== "cors") {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("Something went wrong!"),
              }),
            );
          } else if (
            response.status === 300 ||
            response.status === 301 ||
            response.status === 400 ||
            response.status === 403
          ) {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("Something went wrong!"),
              }),
            );
          }
        })
        .then(() => {
          dispatch(
            showSnackBar({
              type: "success",
              message: t("The password has been changed"),
            }),
          );
        })
        .catch(error => console.log(error));
    }
  };
};

export const requestChangeUserPassword = (body, t) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/requestpassword.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 503 || response.type !== "cors") {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("Something went wrong!"),
              }),
            );
          } else if (
            response.status === 300 ||
            response.status === 301 ||
            response.status === 400 ||
            response.status === 403
          ) {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("Something went wrong!"),
              }),
            );
          }
        })
        .then(() => {
          dispatch(
            showSnackBar({
              type: "success",
              message: t("The code has been sent to your email"),
            }),
          );
        })
        .catch(error => console.log(error));
    }
  };
};

export const resetUserPassword = (body, t) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/user/resetpassword.php`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 503 || response.type !== "cors") {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("Something went wrong!"),
              }),
            );
          } else if (
            response.status === 300 ||
            response.status === 301 ||
            response.status === 400 ||
            response.status === 403
          ) {
            dispatch(
              showSnackBar({
                type: "error",
                message: t("Something went wrong!"),
              }),
            );
          }
        })
        .then(() => {
          dispatch(
            showSnackBar({
              type: "success",
              message: t("The password has been sent reseted"),
            }),
          );
        })
        .catch(error => console.log(error));
    }
  };
};
