import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { showSnackBar } from './root';
import { BASE_URL } from '../../../config';

export const saveAllConfigs = payload => ({
  type: adminActionTypes.SAVE_ALL_CONFIGS,
  payload,
});

export const updateValues = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/config/updatevalues.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(
            showSnackBar({
              type: 'success',
              message: 'Config Saved!',
            }),
          );
            var data = JSON.parse(body);
            const searchBody = {
                Language_ID: data.Language_ID
            };
            body = JSON.stringify(searchBody);
          dispatch(getAllConfigValues(body));
        })
        .catch(error => console.log(error));
    }
  };
};

export const getAllConfigValues = body => {
  return dispatch => {
    if (!body)
    {
        return;
    }
    fetch(`${BASE_URL}api/config/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllConfigs(data.Data));
      })
      .catch(error => console.log(error));
  };
};

