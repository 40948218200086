import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import CheckIcon from "@material-ui/icons/Check";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import {
  updateParticipantStatus,
  updateOrganizersStatus,
} from "../../store/actions/actions/trippet";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    top: "-27px !important",
    left: "-8px !important",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    "& > button": {
      width: 40,
      height: 40,
      backgroundColor: "#0E84B8",
    },
  },
}));

const TripInvitationButton = ({
  data,
  type,
  user,
  filterResultsMyTrippets,
  sortedElementMyTrippets,
  sortedByMyTrippets,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const updateParticipants = action => {
    if (action === "accept") {
      if (data) {
        if (data.participants && data.participants.length > 0) {
          data.participants.forEach(participant => {
            if (participant.TrippetParticipantUser_ID === user.User_ID) {
              let body = {
                TrippetParticipant_ID: participant.TrippetParticipant_ID,
                TrippetParticipantUserRole_ID:
                  participant.TrippetParticipantUserRole_ID,
                ParticipantStatus_ID: 2,
              };
              dispatch(
                updateParticipantStatus(
                  JSON.stringify(body),
                  user.User_ID,
                  sortedElementMyTrippets,
                  sortedByMyTrippets[sortedElementMyTrippets],
                  filterResultsMyTrippets,
                  data.fullTrippet.CreatorUser_ID,
                ),
              );
            }
          });
        }
      }
    } else if (action === "decline") {
      if (data) {
        if (data.participants && data.participants.length > 0) {
          data.participants.forEach(participant => {
            if (participant.TrippetParticipantUser_ID === user.User_ID) {
              let body = {
                TrippetParticipant_ID: participant.TrippetParticipant_ID,
                TrippetParticipantUserRole_ID:
                  participant.TrippetParticipantUserRole_ID,
                ParticipantStatus_ID: 3,
              };
              dispatch(
                updateParticipantStatus(
                  JSON.stringify(body),
                  user.User_ID,
                  sortedElementMyTrippets,
                  sortedByMyTrippets[sortedElementMyTrippets],
                  filterResultsMyTrippets,
                  data.fullTrippet.CreatorUser_ID,
                ),
              );
            }
          });
        }
      }
    }
  };

  const updateOrganizers = action => {
    if (action === "accept") {
      if (data) {
        if (
          data.fullTrippet &&
          data.fullTrippet.Organizers &&
          data.fullTrippet.Organizers.length > 0
        ) {
          data.fullTrippet.Organizers.forEach(participant => {
            if (participant.TrippetOrganizerUser_ID === user.User_ID) {
              let body = {
                TrippetOrganizerUser_ID: participant.TrippetOrganizerUser_ID,
                TrippetOrganizerUserRole_ID:
                  participant.TrippetOrganizerUserRole_ID,
                TrippetOrganizerAcceptanceStatus: 2,
                TrippetOrganizer_ID: participant.TrippetOrganizer_ID,
              };
              dispatch(
                updateOrganizersStatus(
                  JSON.stringify(body),
                  user.User_ID,
                  sortedElementMyTrippets,
                  sortedByMyTrippets[sortedElementMyTrippets],
                  filterResultsMyTrippets,
                  data.fullTrippet.CreatorUser_ID,
                ),
              );
            }
          });
        }
      }
    }
    if (action === "decline") {
      if (
        data.fullTrippet &&
        data.fullTrippet.Organizers &&
        data.fullTrippet.Organizers.length > 0
      ) {
        data.fullTrippet.Organizers.forEach(participant => {
          if (participant.TrippetOrganizerUser_ID === user.User_ID) {
            let body = {
              TrippetOrganizerUser_ID: participant.TrippetOrganizerUser_ID,
              TrippetOrganizerUserRole_ID:
                participant.TrippetOrganizerUserRole_ID,
              TrippetOrganizerAcceptanceStatus: 3,
              TrippetOrganizer_ID: participant.TrippetOrganizer_ID,
            };
            dispatch(
              updateOrganizersStatus(
                JSON.stringify(body),
                user.User_ID,
                sortedElementMyTrippets,
                sortedByMyTrippets[sortedElementMyTrippets],
                filterResultsMyTrippets,
                data.fullTrippet.CreatorUser_ID,
              ),
            );
          }
        });
      }
    }
  };

  const handleClick = action => event => {
    event.stopPropagation();
    updateParticipants(action);
    updateOrganizers(action);
  };

  const actions = [
    { icon: <CheckIcon />, name: t("Copy to My Trippets"), action: "accept" },
    { icon: <CloseIcon />, name: t("Decline"), action: "decline" },
  ];

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="right"
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement="top"
            onClick={handleClick(action.action)}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default TripInvitationButton;
