export const columns = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 50,
    align: 'center',
    type: 'StartDate',
    filter: 'startdates',
    width: '13%',
  },
  { id: 'name', label: 'Trip Title', minWidth: 50, type: 'Name', width: '5%' },
  {
    id: 'owner',
    label: 'Owner',
    minWidth: 50,
    align: 'left',
    type: 'OwnerUser_ID',
    filter: 'owners',
    width: '10%'
  },
  {
    id: 'message',
    label: 'Message',
    minWidth: 140,
    align: 'left',
    type: 'Message',
    width: '28%',
  },
];
