import * as actionTypes from "../actions/actionTypes/actionTypes";

const initialState = {
  isSnackBarOpen: false,
  snackBarType: "",
  snackBarMessage: "",
  allUsers: null,
  google: "",
  allUsersRoles: null,
  departments: null,
  countries: null,
  currencies: null,
  permissionTable: false,
  buddyManagement: false,
  customerLogo: null,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_SNACKBAR:
      return {
        ...state,
        isSnackBarOpen: true,
        snackBarMessage: action.payload.message,
        snackBarType: action.payload.type,
      };
    case actionTypes.HIDE_SNACKBAR:
      return {
        ...state,
        isSnackBarOpen: false,
        snackBarMessage: "",
        snackBarType: "",
      };
    case actionTypes.SAVE_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case actionTypes.SAVE_GOOGLE_OBJECT:
      return {
        ...state,
        google: action.payload,
      };
    case actionTypes.SAVE_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case actionTypes.SAVE_ALL_USER_ROLES:
      return {
        ...state,
        allUsersRoles: action.payload,
      };
    case actionTypes.SAVE_ALL_COUNTIRES:
      return {
        ...state,
        countries: action.payload,
      };
    case actionTypes.SAVE_ALL_CURRENCIES:
      return {
        ...state,
        currencies: action.payload,
      };
    case actionTypes.SHOW_PERMISSION_TABLE:
      return {
        ...state,
        permissionTable: true,
      };
    case actionTypes.HIDE_PERMISSION_TABLE:
      return {
        ...state,
        permissionTable: false,
      };
    case actionTypes.SHOW_BUDDY_TABLE:
      return {
        ...state,
        buddyManagement: true,
      };
    case actionTypes.HIDE_BUDDY_TABLE:
      return {
        ...state,
        buddyManagement: false,
      };
    case actionTypes.SAVE_CUSTOMER_LOGO:
      return {
        ...state,
        customerLogo: action.payload,
      };
    case actionTypes.CLEAR_CUSTOMER_LOGO:
      return {
        ...state,
        customerLogo: null,
      };
    case actionTypes.LOGOUT: {
      return {
        ...state,
        isSnackBarOpen: false,
        snackBarType: "",
        snackBarMessage: "",
        allUsers: null,
        allUsersRoles: null,
        google: "",
        departments: "",
        customerLogo: null,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default rootReducer;
