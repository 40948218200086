import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'relative',
    '& > div': {
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      overflow: 'inherit',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  navigationButton: {
    textAlign: 'right',
    width: 25,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    '& > button': {
      padding: '10px 0',
    },
  },
  blueIcon: {
    color: theme.palette.electricBlue[100],
  },
  title: {
    textAlign: 'center',
  },
  navigationArrow: {
    justifyContent: 'flex-end',
  },
  myTripsTitle: {
    width: 85,
  },
  totalNumberBadge: {
    paddingLeft: 50,

    '& > span': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      borderRadius: '50%',
      width: 25,
      height: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 15,
    },
  },
  totalNumberBadgeRed: {
    paddingLeft: 50,

    '& > span': {
      backgroundColor: '#FF5A5F',
      color: '#FFFFFF',
      borderRadius: '50%',
      width: 25,
      height: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 15,
    },
  },
  totalNumberBadgeInvitations: {
    paddingLeft: 63,
  },
  totalNumberBadgeRecommendations: {
    paddingLeft: 15,
  },
  addTripButtonNavigation: {
    color: 'white !important',
    backgroundColor: '#14AF9D !important',
    margin: '15px auto',
    width: 200,
    display: 'flex',
    transition: 'all .4s',
    "&:hover":{
      backgroundColor: '#129989 !important'
    }
  },

  addTripButtonText: {
    opacity: 1,
  },
  addTripButtonTextClosed: {
    opacity: 0
  },
  addTripButtonNavigationClosed: {
    color: 'white !important',
    backgroundColor: '#14AF9D !important',
    width: '48px !important',
    marginLeft: 5,
    transition: 'all .4s',
    "&:hover":{
      backgroundColor: '#129989 !important'
    },
    "& > span > svg": {
      position: 'relative',
      left: 62,
    }
  },
}));

export default useStyles;
