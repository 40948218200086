import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { columns } from "./columns";
import useStyles from "./styles";
import UserRoleButton from "./UserRoleButton";
import { getUserRoleById } from "../../store/actions/actions/userRole";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import * as userAccess from "../../userAccess";
import UserRoleModal from "../../components/UserRole/UserRoleForm";

const createData = (UserRole_ID, Name, Type) => {
  return {
    UserRole_ID,
    Name,
    Type,
  };
};

const UserRoleTable = ({
  data,
  handleConfirmDeleteModalOpen,
  setDeletedUserRole,
  sortedElement,
  sortedBy,
  handleSortBy,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clickedUserRole, setClickedUserRole] = useState([]);
  const [isUserRoleModalOpen, setIsUserRoleModalOpen] = useState(false);
  const { t } = useTranslation();
  const disableDelete = !userAccess.canDelete(
    useSelector(state => state.user.user),
    userAccess.Table_UserRoles,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElements = () => {
    let elements = [];
    if (data && data.length) {
      data.forEach(item => {
        elements.push(createData(item.UserRole_ID, item.Name, item.Type));
      });
      setRows(elements);
    }
  };

  const handleClickedUserRole = value => {
    setClickedUserRole(value);
  };

  const handleUserRoleModalOpen = () => {
    setIsUserRoleModalOpen(true);
  };

  const handleUserRoleModalClose = () => {
    setIsUserRoleModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length) {
      renderElements();
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderData = (column, value, row) => {
    if (column.id === "actions") {
      return (
        <UserRoleButton
          data={row}
          handleClickedUserRole={handleClickedUserRole}
          handleUserRoleModalOpen={handleUserRoleModalOpen}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedUserRole={setDeletedUserRole}
          disabledDelete={disableDelete}
        />
      );
    } else if (column.id === "Type") {
      if (Number.parseInt(value) === 1) return "Proxy";
      if (Number.parseInt(value) === 2) return "Participant";
      if (Number.parseInt(value) === 3) return "Organizer";
      return "Unknown";
    } else {
      return value;
    }
  };

  const handleClickRow = value => () => {
    dispatch(getUserRoleById(value.UserRole_ID));
    let recordBody = {
      UserRole_ID: value.UserRole_ID,
      Name: value.Name,
      Type: value.Type,
    };
    handleClickedUserRole(recordBody);
    handleUserRoleModalOpen();
  };

  const renderRows =
    rows && rows.length
      ? rows.map(row => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.UserRole_ID}
              onClick={handleClickRow(row)}
              className={classes.rowContainer}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
                const styleName = classes.tableCell;
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={styleName}
                  >
                    {renderData(column, value, row)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      : null;

  return (
    <Paper className={classes.rootPaper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== "actions") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id ? sortedBy : "asc"
                        }
                      >
                        {t(column.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {t(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <UserRoleModal
        open={isUserRoleModalOpen}
        handleUserRoleModalClose={handleUserRoleModalClose}
        defaultValues={clickedUserRole}
      />
    </Paper>
  );
};

export default UserRoleTable;
