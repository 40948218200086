import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {getTrippetTypeCategoryById} from '../../store/actions/actions/trippetTypeCategory';

const useStyles = makeStyles(theme => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    top: '-27px !important',
    left: '-8px !important',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    '& > button': {
      width: 40,
      height: 40,
      backgroundColor: '#0E84B8',
    },
  },
}));

const TrippetTypeCategoryButton = ({
  data,
  handleClickedTrippetTypeCategory,
  handleTrippetTypeCategoryModalOpen,
  handleConfirmDeleteModalOpen,
  setDeletedTrippetTypeCategory,
  disabledDelete,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = action => event => {
    event.stopPropagation();
    event.preventDefault();
    if (action === 'delete') {
        const body = { TrippetTypeCategory_ID: data.TrippetTypeCategory_ID };
        setDeletedTrippetTypeCategory(body);
        handleConfirmDeleteModalOpen();
    } else if (action === 'open') {
      dispatch(getTrippetTypeCategoryById(data.TrippetTypeCategory_ID));
      let recordBody = {
        TrippetTypeCategory_ID: data.TrippetTypeCategory_ID,
        Trip_ID: data.Trip_ID,
        Color: data.Color,
        Icon: data.Icon,
        MaterialIO: data.MaterialIO,
        Comments: data.Comments,
        IsTransport: data.IsTransport,
        SortIndex: data.SortIndex,
        Name: data.Name,
        TripName: data.TripName,
        Names: data.Names,
        Looks: data.Looks,
      };
      handleClickedTrippetTypeCategory(recordBody);
      handleTrippetTypeCategoryModalOpen();
    }
  };

  const actions = [
    { icon: <OpenInNewIcon />, name: t('Open'), action: 'open' },
    { icon: <DeleteIcon />, name: t('Delete'), action: 'delete' },
  ];

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="right"
        onClick={handleClick("")}
      >
        {actions.map(action => {
          if (action.action === 'delete') {
            return (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipPlacement="top"
                onClick={handleClick(action.action)}
                disabled={disabledDelete}
              />
            );
          } else {
            return (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipPlacement="top"
                onClick={handleClick(action.action)}
              />
            );
          }
        })}
      </SpeedDial>
    </div>
  );
};

export default TrippetTypeCategoryButton;
