export const columns = [
  { id: 'actions', label: '', minWidth: 70 },
  { id: 'title', label: 'Trippet Title', minWidth: 80 },
  { id: 'tripTitle', label: 'Trip Title', minWidth: 100 },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 80,
  },
  {
    id: 'startTime',
    label: 'Time',
    minWidth: 80,
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 80,
  },
  {
    id: 'endTime',
    label: 'Time',
    minWidth: 80,
  },
  {
    id: 'from',
    label: 'From',
    minWidth: 80,
  },
  {
    id: 'to',
    label: 'To',
    minWidth: 80,
  },
  {
    id: 'sender',
    label: 'Sender',
    minWidth: 80,
  },
  {
    id: 'participants',
    label: 'Participants',
    minWidth: 80,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
  },
  {
    id: 'lastChanged',
    label: 'Last Changed',
    minWidth: 80,
    align: 'center',
  },
];
