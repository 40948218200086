import React, { useEffect, useState } from 'react';
import withAdminDashboard from '../../hoc/withAdminDashboard/withAdminDashboard';
import withTitle from '../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import useStyle from './styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllUsers,
    deleteUser,
    forceDeleteUser,
    saveCurrentPage,
} from '../../store/actions/actions/user';
import Table from './Table';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import AdminPagination from '../../components/AdminPagination/AdminPagination';
import { ROWS_PER_PAGE } from '../../config';
import * as userAccess from '../../userAccess';
import UserModal from '../../components/UserForm/UserForm';

const Users = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const allUsers = useSelector(state => state.user.allUsers);
  const paginationData = useSelector(state => state.user.paginationData);
  const currentPage = useSelector(state => state.user.currentPage);
  const [deletedUser, setDeletedUser] = useState(null);
  const [forceDeletedUser, setForceDeletedUser] = useState(null);
  const [isOpenConfirmDeleteModal, setisOpenConfirmDeleteModal] = useState(false);
  const [isOpenConfirmForceDeleteModal, setisOpenConfirmForceDeleteModal] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortedElement, setSortedElement] = useState('DisplayName');
  const [sortedDir, setSortedDir] = useState('asc');
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    const bodySearch = {
      Page: currentPage,
      RowsPerPage: ROWS_PER_PAGE,
      OrderBy: sortedElement,
      Language_ID: '2',
      OrderDir: sortedDir,
    };

    dispatch(getAllUsers(JSON.stringify(bodySearch)));
  }, [sortedElement, sortedDir, currentPage, dispatch]);

  useEffect(() => {
    if (paginationData) {
      setTotalItemsCount(paginationData.Num);
    }
  }, [paginationData]);

  const handleSortDir = id => () => {
    setSortedDir(sortedElement === id && sortedDir === 'asc' ? 'desc' : 'asc');
    setSortedElement(id);
  };

  const onPaginationChange = activePage => {
    dispatch(saveCurrentPage(activePage));
  };

  const handleConfirmDeleteModalClose = () => {
    setisOpenConfirmDeleteModal(false);
  };

  const handleConfirmDeleteModalOpen = () => {
    setisOpenConfirmDeleteModal(true);
  };

  const handleConfirmForceDeleteModalClose = () => {
    setisOpenConfirmForceDeleteModal(false);
  };

  const handleConfirmForceDeleteModalOpen = () => {
    setisOpenConfirmForceDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteUser(JSON.stringify(deletedUser, null, t)));
  };

  const handleForceDelete = () => {
    dispatch(forceDeleteUser(JSON.stringify(forceDeletedUser)));
  };

  const handleUserModalOpen = () => {
    setIsUserModalOpen(true);
  };

  const handleUserModalClose = () => {
    setIsUserModalOpen(false);
  };

  const handleNewClick = () => {
    setDefaultValues(null);
    handleUserModalOpen();
  };

  return userAccess.canRead(user, userAccess.Table_Users) ? (
    <Grid container justify="center" className={classes.containerMyTrip}>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {t('Users')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Fab
            variant="extended"
            className={classes.addTripButton}
            onClick={handleNewClick}
            disabled={!userAccess.canWrite(user, userAccess.Table_Users)}
          >
            <AddIcon className={classes.extendedIcon} />
            {t('User')}
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          data={allUsers}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          handleConfirmForceDeleteModalOpen={handleConfirmForceDeleteModalOpen}
          setDeletedUser={setDeletedUser}
          setForceDeletedUser={setForceDeletedUser}
          sortedElement={sortedElement}
          sortedBy={sortedDir}
          handleSortBy={handleSortDir}
        />
      </Grid>
      <AdminPagination
        activePage={currentPage}
        itemsPerPage={ROWS_PER_PAGE}
        totalItemsCount={totalItemsCount}
        onChange={onPaginationChange}
        title='users'
      />
      <ConfirmDeleteModal
        open={isOpenConfirmDeleteModal}
        handleClose={handleConfirmDeleteModalClose}
        handleDelete={handleDelete}
      />
      <ConfirmDeleteModal
        open={isOpenConfirmForceDeleteModal}
        handleClose={handleConfirmForceDeleteModalClose}
        handleDelete={handleForceDelete}
      />
     <UserModal
        open={isUserModalOpen}
        handleUserModalClose={handleUserModalClose}
        defaultValues={defaultValues}
      />
    </Grid>
  )
  : <div>NO ACCESS</div>;
};

export default withTitle(withAdminDashboard(Users), 'Admin Users');
