import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import uniqid from "uniqid";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.electricBlue[100],
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  accept: {
    color: "#42ce23",
  },
  decline: {
    color: "#ef2828",
  },
  containerTable: {
    maxHeight: 250,
  },
  searchField: {
    marginBottom: 10,
  }
});

const TableOne = ({ trip_ID, allTrips, buddy }) => {
  const [rows, setRows] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [search, setSearch] = useState("");
  const classes = useStyles();

  const createData = (name, accepted, declined, pending) => {
    return { name, accepted, declined, pending };
  };

  useEffect(() => {
    if (trip_ID && allTrips) {
      const trip = allTrips.find(trip => trip.Trip_ID === trip_ID);
      setSelectedTrip(trip);
    }
    return () => {
      setSelectedTrip(null);
    };
  }, [trip_ID, allTrips]);

  const isPending = status => {
    if (status === "1" || status === null || status === "0") {
      return <DoneIcon className={classes.accept} />;
    } else {
      return <CloseIcon className={classes.decline} />;
    }
  };

  const isAccepted = status => {
    if (status === "2") {
      return <DoneIcon className={classes.accept} />;
    } else {
      return <CloseIcon className={classes.decline} />;
    }
  };

  const isDeclined = status => {
    if (status === "3") {
      return <DoneIcon className={classes.accept} />;
    } else {
      return <CloseIcon className={classes.decline} />;
    }
  };

  const renderElements = trip => {
    let allBuddies = [];
    if (buddy === "participants") {
      let participants = trip.Participants;
      participants.forEach(buddy => {
        let element = createData(
          buddy.TripParticipantDisplayName,
          isAccepted(buddy.ParticipantStatus_ID),
          isDeclined(buddy.ParticipantStatus_ID),
          isPending(buddy.ParticipantStatus_ID),
        );
        allBuddies.push(element);
      });
    } else if (buddy === "organizers") {
      let organizers = trip.Organizers;
      organizers.forEach(buddy => {
        let element = createData(
          buddy.TripOrganizerDisplayName,
          isAccepted(buddy.TripOrganizerAcceptanceStatus),
          isDeclined(buddy.TripOrganizerAcceptanceStatus),
          isPending(buddy.TripOrganizerAcceptanceStatus),
        );
        allBuddies.push(element);
      });
    } else if (buddy === "proxies") {
      let proxies = trip.Proxies;
      proxies.forEach(buddy => {
        let element = createData(
          buddy.TripProxyDisplayName,
          isAccepted(buddy.TripProxyAcceptanceStatus),
          isDeclined(buddy.TripProxyAcceptanceStatus),
          isPending(buddy.TripProxyAcceptanceStatus),
        );
        allBuddies.push(element);
      });
    }
    setRows(allBuddies);
  };

  useEffect(() => {
    if (selectedTrip) {
      renderElements(selectedTrip);
    }
    // eslint-disable-next-line
  }, [selectedTrip, buddy]);

  const handleSearch = event => {
    setSearch(event.target.value);
  };

  const filteredCountries = rows.filter(buddy => {
    return buddy.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });
  return (
    <Fragment>
      <TextField
        id="outlined-basic"
        label="Search buddy by name"
        variant="outlined"
        value={search}
        onChange={handleSearch}
        className={classes.searchField}
      />
      <TableContainer component={Paper} className={classes.containerTable}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Accepted</StyledTableCell>
              <StyledTableCell align="left">Declined</StyledTableCell>
              <StyledTableCell align="left">Pending</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCountries.map(row => (
              <StyledTableRow key={uniqid()}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.accepted}</TableCell>
                <TableCell align="left">{row.declined}</TableCell>
                <TableCell align="left">{row.pending}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default TableOne;
