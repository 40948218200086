import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  slide: {
    width: '100%',
    display: 'inline-block',
    backgroundColor: 'green',
    textAlign: 'center',
    position: 'realtive',
    height: 70,
    overflow: 'hidden',
    cursor: 'pointer',
    "& > img": {
      width: '100%',
      minHeight: 70,
    }
  },
  rightArrow: {
    display: 'inline',
    position: 'absolute',
    top: 24,
    right: -33,
    cursor: 'pointer',
    "& > svg": {
      color: "black"
    }
  },
  leftArrow: {
    display: 'inline',
    position: 'absolute',
    top: 24,
    left: -33,
    cursor: 'pointer',
    "& > svg": {
      color: "black"
    }
  }
}));

export default useStyles;
