import React, { useEffect, useState } from "react";
import withAdminDashboard from "../../hoc/withAdminDashboard/withAdminDashboard";
import withTitle from "../../hoc/withTitle";
import Typography from "@material-ui/core/Typography";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPlanningStatuses,
  deletePlanningStatus,
  saveCurrentPage,
  createPerTripPlanningStatus,
  saveFilterTrip_ID,
} from "../../store/actions/actions/planningStatus";
import Table from "./Table";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import { ROWS_PER_PAGE } from "../../config";
import * as userAccess from "../../userAccess";
import PlanningStatusModal from "../../components/PlanningStatusForm/PlanningStatusForm";
import TripSelector from "../tripSelector/TripSelector";

const PlanningStatuses = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const filterTrip_ID = useSelector(state => state.planningStatus.filterTrip_ID);
  const allPlanningStatuses = useSelector(
    state => state.planningStatus.allPlanningStatuses,
  );
  const paginationData = useSelector(
    state => state.planningStatus.paginationData,
  );
  const currentPage = useSelector(state => state.planningStatus.currentPage);
  const [deletedPlanningStatus, setDeletedPlanningStatus] = useState(null);
  const [isOpenConfirmDeleteModal, setisOpenConfirmDeleteModal] = useState(
    false,
  );

  const [openTripSelector, setOpenTripSelector] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortedElement, setSortedElement] = useState("Name");
  const [sortedDir, setSortedDir] = useState("asc");
  const [isPlanningStatusModalOpen, setIsPlanningStatusModalOpen] = useState(
    false,
  );
  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    const bodySearch = {
      Trip_ID: filterTrip_ID,
      Page: currentPage,
      RowsPerPage: ROWS_PER_PAGE,
      OrderBy: sortedElement,
      Language_ID: "2",
      OrderDir: sortedDir,
    };

    dispatch(getAllPlanningStatuses(JSON.stringify(bodySearch)));
  }, [sortedElement, sortedDir, currentPage, filterTrip_ID, dispatch]);

  useEffect(() => {
    if (paginationData) {
      setTotalItemsCount(paginationData.Num);
    }
  }, [paginationData]);

  const handleSortDir = id => () => {
    setSortedDir(sortedElement === id && sortedDir === "asc" ? "desc" : "asc");
    setSortedElement(id);
  };

  const onPaginationChange = activePage => {
    dispatch(saveCurrentPage(activePage));
  };

  const handleConfirmDeleteModalClose = () => {
    setisOpenConfirmDeleteModal(false);
  };

  const handleConfirmDeleteModalOpen = () => {
    setisOpenConfirmDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(deletePlanningStatus(JSON.stringify(deletedPlanningStatus)));
  };

  const handlePlanningStatusModalOpen = () => {
    setIsPlanningStatusModalOpen(true);
  };

  const handlePlanningStatusModalClose = () => {
    setIsPlanningStatusModalOpen(false);
  };

  const handleNewClick = () => {
    setDefaultValues(null);
    handlePlanningStatusModalOpen();
  };
  const handleAddTripClick = () =>
  {
    setOpenTripSelector(!openTripSelector);
  }
  const setIsOpen = (isOpen) =>
  {
    setOpenTripSelector(isOpen);
  }
  const setSelectedTrip = (trip) =>
  {
    console.log(trip.fullTrip.Trip_ID);
    dispatch(saveFilterTrip_ID(trip.fullTrip.Trip_ID));
    dispatch(createPerTripPlanningStatus(JSON.stringify({Trip_ID: trip.fullTrip.Trip_ID})));
    setOpenTripSelector(false);
  }
  return userAccess.canRead(user, userAccess.Table_PlanningStatuses) ? (
    <Grid container justify="center" className={classes.containerMyTrip}>
      <Grid item xs={12} className={classes.space}></Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {t("Planning Statuses")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Fab
            variant="extended"
            className={classes.addTripButton}
            onClick={handleNewClick}
            disabled={
              !userAccess.canWrite(user, userAccess.Table_PlanningStatuses)
            }
          >
            <AddIcon className={classes.extendedIcon} />
            {t("Planning Status")}
          </Fab>
          <Fab
            variant="extended"
            className={classes.addTripButton}
            onClick={handleAddTripClick}
            disabled={!userAccess.canWrite(user, userAccess.Table_PlanningStatuses)}
          >
            <AddIcon className={classes.extendedIcon} />
            {t("Duplicate Per Trip")}
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          data={allPlanningStatuses}
          handleConfirmDeleteModalOpen={handleConfirmDeleteModalOpen}
          setDeletedPlanningStatus={setDeletedPlanningStatus}
          sortedElement={sortedElement}
          sortedBy={sortedDir}
          handleSortBy={handleSortDir}
        />
      </Grid>
      <AdminPagination
        activePage={currentPage}
        itemsPerPage={ROWS_PER_PAGE}
        totalItemsCount={totalItemsCount}
        onChange={onPaginationChange}
        title="planning statuses"
      />
      <ConfirmDeleteModal
        open={isOpenConfirmDeleteModal}
        handleClose={handleConfirmDeleteModalClose}
        handleDelete={handleDelete}
      />
      <PlanningStatusModal
        open={isPlanningStatusModalOpen}
        handlePlanningStatusModalClose={handlePlanningStatusModalClose}
        defaultValues={defaultValues}
      />
      <TripSelector
        openTripSelector={openTripSelector}
        setIsOpen={setIsOpen}
        setSelectedTrip={setSelectedTrip}
      />
    </Grid>
  ) : (
    <div>NO ACCESS</div>
  );
};

export default withTitle(
  withAdminDashboard(PlanningStatuses),
  "Admin Planning Statuses",
);
