import React from 'react';
import { Route } from 'react-router-dom';
import VerificationPage from '../pages/verification/Verfication';

function PrivateRoute({ component: Component, ...rest }) {
  const isVerified = localStorage.getItem('verificationCode');
  return (
    <Route
      {...rest}
      render={props => (isVerified ? <Component {...props} /> : <VerificationPage />)}
    />
  );
}

export default PrivateRoute;
