import React, { useState, useEffect } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SmallForm from "./SmallForm";
import BigForm from "./BigForm";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSelector, useDispatch } from "react-redux";
import { validateFileFormat } from "./validateFileFormat";
import FormHelperText from "@material-ui/core/FormHelperText";
import { differenceInCalendarDays } from "date-fns";
import {
  createTrippet,
  uploadFile,
  changeTrippet,
  createTrippetNotes,
  getAllCurrentTrippetNotes,
  deleteTrippetNotes,
  clearCurrentTrippetNotes,
  savePaginationPage,
  getCurrentTrippetChat,
  saveUploadedFile,
  deleteTrippetDocument,
  deleteTrippetImage,
  saveTrippetImages,
  updateTrippetPicture,
  clearSelectedImage,
  saveSelectedImage,
  getAllParentTrippets,
} from "../../store/actions/actions/trippet";
import { showSnackBar } from "../../store/actions/actions/root";
import { format, formatDistance } from "date-fns";
import ParticipantsModal from "../ParticipantsModal/ParticipantsModal";
import ResponsibleModal from "../ResponsibleModal/ResponsibleModal";
import { getFlightDistance } from "./getFlightDistance";
import Avatar from "@material-ui/core/Avatar";
import { BASE_URL } from "../../config";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import AirplanemodeActiveIcon from "@material-ui/icons/AirplanemodeActive";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import RecommendationModal from "../RecommendationModal/RecommendationModal";
import uniqid from "uniqid";
import MenuItem from "@material-ui/core/MenuItem";
import io from "socket.io-client";
import ReactEmoji from "react-emoji";
import { BRANCH_NAME, CHAT_ENDPOINT } from "../../config";
import InvitationalModal from "../InvitationalModal/InvitationalModal";
import { Tooltip } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-GB";
import CloseIcon from "@material-ui/icons/Close";
import UploadPictureModal from "../UploadPictureModal/UploadPictureModal";
import PlaceholderImage from "../../assets/placeholder-image.png";
import PreviewModalImage from "../PreviewModalImage/PreviewModalImage";
import { GrDocumentPdf, GrDocumentTxt, GrDocumentZip } from "react-icons/gr";
import { FaFileWord, FaFileExcel } from "react-icons/fa";
let socket;

const TrippetForm = ({
  handleClose,
  handleConfirmModalOpen,
  currentTrippet,
  pickedTime,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentTrip = useSelector(state => state.trip.currentTrip);
  const user = useSelector(state => state.user.user);
  const allUsers = useSelector(state => state.app.allUsers);
  const google = useSelector(state => state.app.google);
  const allTrippetImages = useSelector(state => state.trippet.trippetImage);
  const allPublicDocuments = useSelector(state => state.trippet.publicDocument);
  const allPersonalDocuments = useSelector(
    state => state.trippet.personalDocument,
  );
  const calendarInstance = useSelector(state => state.trippet.calendarInstance);
  const trippetTypes = useSelector(state => state.trippet.trippetTypes);
  const trippetCategories = useSelector(
    state => state.trippet.trippetCategories,
  );
  const currentTrippetNotes = useSelector(
    state => state.trippet.currentTrippetNotes,
  );
  const trippetStatus = useSelector(state => state.trippet.trippetStatus);
  const sortElement = useSelector(state => state.trippet.sortElement);
  const parentsTrippet = useSelector(state => state.trippet.parentsTrippet);
  const sortByDirection = useSelector(state => state.trippet.sortByDirection);
  const usedFilters = useSelector(state => state.trippet.usedFilters);
  const selectedImage = useSelector(state => state.trippet.selectedImage);
  const allUsersRoles = useSelector(state => state.app.allUsersRoles);
  const currentTrippetChat = useSelector(
    state => state.trippet.currentTrippetChat,
  );
  const [autocompleteFrom, setAutocompleteFrom] = useState(null);
  const [tripOwner, setTripOwner] = useState(null);
  const [autocompleteTo, setAutocompleteTo] = useState(null);
  const [expandFullForm, setExpandFullForm] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [startDate, handleStartDate] = useState();
  const [endDate, handleEndDate] = useState();
  const [startTime, handleStartTime] = useState();
  const [endTime, handleEndTime] = useState();
  const [privateTrip, isPrivateTrip] = useState(false);
  const [isAllDay, setIsAllDay] = useState(false);
  const [startTrippetDay, setStartTrippetDay] = useState(0);
  const [endTrippetDay, setEndTrippetDay] = useState(0);
  const [tripTotalDays, setTripTotalDays] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const [defaultPicture, setDefaultPicture] = useState(null);
  const [isRecommendationModalOpen, setIsRecommendationModalOpen] = useState(
    false,
  );
  const [distanceBetweenToPlaces, setDistanceBetweenToPlaces] = useState("");
  const [isResponsibleModalOpen, setIsResponsibleModalOpen] = useState(false);
  const [autocompleteFromEdit, setAutocompleteFromEdit] = useState(null);
  const [autocompleteToEdit, setAutocompleteToEdit] = useState(null);
  const [externalUsersEmail, setExternalUsersEmail] = useState(null);
  const [trippetRecommendationInfo, setTrippetRecommendationInfo] = useState(
    null,
  );
  const [disabledRefreshButton, setDisabledRefreshButton] = useState(true);
  const [wrongFormatDocument, setWrongFormatDocument] = useState({
    publicDocument: true,
    personalDocument: true,
  });
  const [errorTripNameMessage, setErrorTripNameMessage] = useState(false);
  const [errorTypeMessage, setErrorTypeMessage] = useState(false);
  const [disabledFromField, setDisabledFromField] = useState(false);
  const [fromPlaceCopy, setFromPlaceCopy] = useState("");
  const [showTimeZones] = useState(false);
  const [notes, setNotes] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [isInvitationalModalOpen, setIsInvitationalModalOpen] = useState(false);
  const language = useSelector(state => state.programSettings.language);
  const [dateLanguage, setLanguage] = useState("");
  const [isUploadPictureModalOpen, setIsUploadPictureModalOpen] = useState(
    false,
  );
  const [values, setValues] = useState({
    trippetName: "",
    status: "",
    from: "",
    fromInfo: "",
    to: "",
    toInfo: "",
    type: "",
    startTimeZone: "",
    endTimeZone: "",
    pictureUrl: "",
    pictureFile: "",
    pictureTitle: "",
    link: "",
    price: "",
    description: "",
    publicDocument: "",
    personalDocument: [],
    distance: "",
    responsible: "",
    organizers: "",
    participants: "",
    id: "",
    isChildOf: "",
    currency: "euro",
  });
  const [message, setMessage] = useState("");
  const [newMessage, setNewMessage] = useState(null);
  const [trippetTypesWithCategories, setTrippetTypesWithCategories] = useState(
    null,
  );
  const [connected, setConnected] = useState(true);
  const [typing, setTyping] = useState(false);
  const [typingUser, setTypingUser] = useState("");
  const [isPreviewModalImageOpen, setIsPreviewModalImageOpen] = useState(false);
  const [lastTypingTime, setLastTypingTime] = useState(new Date().getTime());
  const [messages, setMessages] = useState([]);
  const [isFromFieldLeft, setIsFromFieldLeft] = useState(true);
  const ENDPOINT = CHAT_ENDPOINT;
  const TYPING_TIMER_LENGTH = 1000; // ms

  useEffect(() => {
    if (language === "en") {
      setLanguage(enLocale);
    } else if (language === "de") {
      setLanguage(deLocale);
    }
  }, [language]);

  useEffect(() => {
    if (user && currentTrip) {
      let data = {
        Language_ID: user.Language_ID,
        Trip_ID: currentTrip.Trip_ID,
        IsChildOf: "",
        OnlyParents: 1,
        AppendChildren: 0,
      };
      dispatch(getAllParentTrippets(JSON.stringify(data)));
    }
  }, [user, currentTrip, dispatch]);

  useEffect(() => {
    if (
      currentTrippet &&
      currentTrippet.fullTrippet &&
      currentTrippet.fullTrippet.Trippet_ID
    ) {
      let body = {
        Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
        OrderBy: "TrippetChat_ID",
        OrderDir: "asc",
      };
      dispatch(getCurrentTrippetChat(JSON.stringify(body)));
    }
  }, [currentTrippet, dispatch]);

  useEffect(() => {
    if (currentTrippetChat) {
      let chat = [];
      currentTrippetChat.forEach(item => {
        let data = {
          UserDisplayName: item.TrippetChatFromDisplayName,
          Message: item.Chat,
          User_ID: item.TrippetChatFromUser_ID,
          TrippetChat_ID: item.TrippetChat_ID,
          CreateDate: item.CreateDate,
        };
        chat.push(data);
      });
      setMessages(chat);
    }
  }, [currentTrippetChat]);

  useEffect(() => {
    if (user && currentTrippet && isEditMode) {
      socket = io(ENDPOINT);
      socket.emit("join", {
        User_ID: user.User_ID,
        Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
        UserDisplayName: user.DisplayName,
        Branch: BRANCH_NAME,
      });

      return () => {
        socket.emit("userleft");
        clearTimeout(timeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentTrippet, ENDPOINT, isEditMode]);

  useEffect(() => {
    if (values.description) {
      if (values.description.length > 1500) {
        setDescriptionError(true);
      } else {
        setDescriptionError(false);
      }
    }
  }, [values.description]);

  useEffect(() => {
    if (newMessage) {
      let allMessages = [...messages];
      allMessages.push(newMessage);
      setMessages(allMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage]);

  useEffect(() => {
    if (isEditMode) {
      socket.on("message", data => {
        setNewMessage(data);
        // let allMessages = [...messages];
        // allMessages.push(data);
        // setMessages(allMessages);
      });

      socket.on("joined", data => {
        let element = data;
        element.type = "joined";
        setNewMessage(element);
      });

      socket.on("userleft", data => {
        let element = data;
        element.type = "left";
        setNewMessage(element);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    if (isEditMode) {
      socket.on("login", data => {
        setConnected(true);
        socket.emit("echo", "hi");
      });

      // Whenever the server emits 'error'
      // it is the response of 'message' in case of error on the server, just to this user
      // data: {Error: 'error msg'}
      socket.on("failed", data => {
        console.log("Error: " + data.Error);
      });

      // Whenever the server emits 'typing', show the typing message
      // it is the response of 'typing', to other users in the trippet room
      // data: {UserDisplayName: 'umed'}
      socket.on("typing", data => {
        if (data.UserDisplayName) {
          setTyping(true);
          setTypingUser(data.UserDisplayName);
        }
      });

      // Whenever the server emits 'stoptyping', kill the typing message
      // it is the response of 'stoptyping', to all users in the trippet room
      // data: {UserDisplayName: 'umed'}
      socket.on("stoptyping", data => {
        setTyping(false);
        setTypingUser("");
      });

      socket.on("disconnect", () => {
        dispatch(
          showSnackBar({
            type: "info",
            message: t("You have been disconnected."),
          }),
        );
      });

      socket.on("reconnect", () => {
        // rejoin
        socket.emit("join", {
          User_ID: user.User_ID,
          Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
          UserDisplayName: user.DisplayName,
          Branch: BRANCH_NAME,
        });
      });

      socket.on("reconnect_error", () => {
        dispatch(
          showSnackBar({
            type: "error",
            message: t("Attempt to reconnect has failed."),
          }),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  const sendMessage = event => {
    event.preventDefault();
    if (message && connected) {
      setMessage("");
      let chatUsers = [];
      chatUsers.push(currentTrippet.fullTrippet.CreatorUser_ID);
      if (values.participants && values.participants.length > 0) {
        values.participants.forEach(par => {
          if (
            par.TrippetParticipantUser_ID !==
            currentTrippet.fullTrippet.CreatorUser_ID
          ) {
            chatUsers.push(par.TrippetParticipantUser_ID);
          }
        });
      }
      socket.emit("message", { Chat: message, Tos: chatUsers });
    }
  };

  const updateTyping = () => {
    if (connected) {
      if (!typing) {
        setTyping(true);
        socket.emit("typing");
      }
      setLastTypingTime(new Date().getTime());

      const timeout = setTimeout(() => {
        let typingTimer = new Date().getTime();
        let timeDiff = typingTimer - lastTypingTime;
        if (timeDiff >= TYPING_TIMER_LENGTH && typing) {
          socket.emit("stoptyping");
          setTyping(false);
        }
      }, TYPING_TIMER_LENGTH);
      setTimeoutId(timeout);
    }
  };

  const handleChatMessage = event => {
    setMessage(event.target.value);
    updateTyping();
    setTyping(true);
    if (event.target.value && event.key === "Enter") {
      sendMessage(event);
      socket.emit("stoptyping");
      setTyping(false);
    }
  };

  // setting if we are in edit mode
  // if we are in eddit mode we are setting default value for the Big and Small form
  // checking if we have picked date and time from Calendar and setting that as default value
  useEffect(() => {
    if (currentTrippet) {
      setIsEditMode(true);
      const {
        Title,
        OwnerPlanningStatus_ID,
        TrippetTypeLook_ID,
        Link,
        Price,
        Description,
        Distance,
        Trip_ID,
        StartDate,
        EndDate,
        FromPlace,
        ToPlace,
        Participants,
        FromData,
        ToData,
        IsWholeDay,
        IsPrivate,
        Organizers,
        IsChildOf,
      } = currentTrippet.fullTrippet;
      let totalTripDays = differenceInCalendarDays(
        new Date(currentTrip.startDate),
        new Date(currentTrip.endDate),
      );
      allUsers &&
        allUsers.forEach(user => {
          if (user.User_ID === currentTrippet.fullTrippet.CreatorUser_ID) {
            setTripOwner(user);
          }
        });
      setAutocompleteFromEdit({
        getPlace() {
          return JSON.parse(`${FromData}`);
        },
      });
      setAutocompleteToEdit({
        getPlace() {
          return JSON.parse(`${ToData}`);
        },
      });
      const allday = IsWholeDay === "1" ? true : false;
      const privateTrip = IsPrivate === "1" ? true : false;
      isPrivateTrip(privateTrip);
      setIsAllDay(allday);
      setTripTotalDays(Math.abs(totalTripDays) + 1);
      setValues({
        ...values,
        trippetName: Title,
        status: OwnerPlanningStatus_ID,
        type: TrippetTypeLook_ID,
        link: Link,
        price: `${Price}`,
        description: Description,
        id: Trip_ID,
        from: FromPlace,
        to: ToPlace,
        participants: Participants,
        fromInfo: JSON.parse(`${FromData}`),
        toInfo: JSON.parse(`${ToData}`),
        responsible: Organizers,
        isChildOf: IsChildOf,
      });
      setDistanceBetweenToPlaces(Distance);
      handleStartDate(new Date(StartDate));
      handleEndDate(new Date(EndDate));
      handleStartTime(new Date(StartDate));
      handleEndTime(new Date(EndDate));
    } else {
      setIsEditMode(false);
      if (currentTrip && allUsers) {
        allUsers.forEach(user => {
          if (user.User_ID === currentTrip.ownerTrip) {
            setTripOwner(user);
          }
        });
        if (currentTrip.startDate && currentTrip.endDate) {
          let defaultTime = new Date();
          if (pickedTime) {
            handleStartDate(new Date(`${pickedTime.start}`));
            let startHours = new Date(`${pickedTime.start}`).getHours();
            let startMinute = new Date(`${pickedTime.start}`).getMinutes();
            handleStartTime(
              defaultTime.setHours(startHours, startMinute, 0, 0),
            );

            handleEndDate(new Date(`${pickedTime.end}`));
            let endHours = new Date(`${pickedTime.end}`).getHours();
            let endMinute = new Date(`${pickedTime.end}`).getMinutes();
            handleEndTime(defaultTime.setHours(endHours, endMinute, 0, 0));

            let totalTripDays = differenceInCalendarDays(
              new Date(currentTrip.startDate),
              new Date(currentTrip.endDate),
            );
            setTripTotalDays(Math.abs(totalTripDays) + 1);
          } else {
            handleStartDate(new Date(currentTrip.startDate));
            handleStartTime(defaultTime.setHours(0, 0, 0, 0));
            handleEndDate(new Date(currentTrip.endDate));
            handleEndTime(defaultTime.setHours(0, 0, 0, 0));
            let totalTripDays = differenceInCalendarDays(
              new Date(currentTrip.startDate),
              new Date(currentTrip.endDate),
            );
            setTripTotalDays(Math.abs(totalTripDays) + 1);
          }
        } else {
          let defaultTime = new Date();
          handleStartDate(new Date());
          handleEndDate(new Date());
          handleStartTime(defaultTime.setHours(0, 0, 0, 0));
          handleEndTime(defaultTime.setHours(0, 0, 0, 0));
          let totalTripDays = differenceInCalendarDays(new Date(), new Date());
          setTripTotalDays(Math.abs(totalTripDays) + 1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrippet, allUsers, currentTrip, pickedTime]);

  const renderUserChatInfo = (participant, message) => {
    if (participant.Picture) {
      return (
        <img
          src={participant.Picture}
          alt={participant.TrippetParticipantInitials}
          className={classes.avatarImage}
        />
      );
    } else {
      return participant.Initials;
    }
  };

  const findUserInfo = participant => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      allUsers.forEach(user => {
        if (participant.User_ID === user.User_ID) {
          data = renderUserChatInfo(user, participant.Message);
        }
      });
      return data;
    }
  };

  const converToLocalTime = date => {
    let newDate = date.split("");
    newDate.push("Z");
    let localTime = new Date(newDate.join(""));
    // return format(new Date(localTime), 'dd.MM.yyyy HH:mm');
    return localTime;
  };
  var decodeEntities = (function() {
    // this prevents any overhead from creating the object each time
    var element = document.createElement("div");

    function decodeHTMLEntities(str) {
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }

      return str;
    }

    return decodeHTMLEntities;
  })();
  const renderMessages = () => {
    if (messages && user) {
      return messages.map((msg, index) => {
        if (msg.type) {
          if (msg.type === "left") {
            return (
              <div className={classes.leftChat} key={index}>
                <p>{msg.UserDisplayName} has left the chat.</p>
              </div>
            );
          } else {
            return (
              <div className={classes.joinChat} key={index}>
                <p>{msg.UserDisplayName} has joined the chat.</p>
              </div>
            );
          }
        } else {
          if (msg.User_ID === user.User_ID) {
            const messageTime = formatDistance(
              new Date(),
              new Date(`${converToLocalTime(msg.CreateDate)}`),
              { addSuffix: true, locale: dateLanguage },
            );

            return (
              <ListItem
                key={msg.TrippetChat_ID}
                className={classes.listItemChat}
              >
                <Grid container className={classes.chatImageContainerLeft}>
                  <Grid item>
                    <Tooltip title={msg.UserDisplayName} arrow placement="top">
                      <Avatar>{findUserInfo(msg)}</Avatar>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container className={classes.ChatUserInformation}>
                  <Grid item xs={12}>
                    <ListItemText
                      align="left"
                      secondary={msg.UserDisplayName}
                      className={classes.messageFullName}
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title={messageTime} arrow placement="top">
                      <ListItemText
                        align="left"
                        secondary={
                          msg &&
                          format(
                            new Date(`${msg.CreateDate}`),
                            "EEEEEE dd. LLL",
                          )
                        }
                        className={classes.messageTimeRight}
                      ></ListItemText>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10}>
                    <ListItemText
                      align="left"
                      primary={ReactEmoji.emojify(decodeEntities(msg.Message))}
                      className={classes.singleMessage}
                    ></ListItemText>
                  </Grid>
                </Grid>
              </ListItem>
            );
          } else {
            const messageTime = formatDistance(
              new Date(),
              new Date(`${converToLocalTime(msg.CreateDate)}`),
              { addSuffix: true, locale: dateLanguage },
            );
            return (
              <ListItem
                key={msg.TrippetChat_ID}
                className={classes.listItemChatRight}
              >
                <Grid container className={classes.ChatUserInformation}>
                  <Grid item xs={12}>
                    <ListItemText
                      align="right"
                      secondary={msg.UserDisplayName}
                      className={classes.messageFullName}
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={10}>
                    <ListItemText
                      align="right"
                      primary={ReactEmoji.emojify(decodeEntities(msg.Message))}
                      className={classes.singleMessage}
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title={messageTime} arrow placement="top">
                      <ListItemText
                        align="left"
                        secondary={
                          msg &&
                          format(
                            new Date(`${msg.CreateDate}`),
                            "EEEEEE dd. LLL",
                          )
                        }
                        className={classes.messageTimeRight}
                      ></ListItemText>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container className={classes.chatImageContainerRight}>
                  <Grid item>
                    <Tooltip title={msg.UserDisplayName} arrow placement="top">
                      <Avatar>{findUserInfo(msg)}</Avatar>
                    </Tooltip>
                  </Grid>
                </Grid>
              </ListItem>
            );
          }
        }
      });
    }
  };

  // setting day range and counting total trip days
  useEffect(() => {
    let totalDays = differenceInCalendarDays(
      new Date(currentTrip.StartDate),
      new Date(currentTrip.EndDate),
    );

    let startTrippetDayDifference = differenceInCalendarDays(
      new Date(currentTrip.EndDate),
      new Date(startDate),
    );

    let endTrippetDayDifference = differenceInCalendarDays(
      new Date(endDate),
      new Date(currentTrip.EndDate),
    );

    let renderStartDay = 1;
    if (startTrippetDayDifference === Math.abs(totalDays) + 1) {
      renderStartDay = Math.abs(totalDays) + 1 - startTrippetDayDifference - 1;
    } else if (startTrippetDayDifference > Math.abs(totalDays) + 1) {
      renderStartDay = Math.abs(totalDays) + 1 - startTrippetDayDifference - 1;
    } else if (startTrippetDayDifference > 0) {
      renderStartDay = Math.abs(totalDays) + 1 - startTrippetDayDifference;
    } else if (startTrippetDayDifference < 0) {
      renderStartDay =
        Math.abs(totalDays) + 1 + Math.abs(startTrippetDayDifference);
    } else if (startTrippetDayDifference === 0) {
      renderStartDay = Math.abs(totalDays) + 1;
    } else {
      renderStartDay =
        Math.abs(totalDays) + 1 - Math.abs(startTrippetDayDifference) - 1;
    }

    let renderEndDay = 1;
    if (endTrippetDayDifference > Math.abs(totalDays) + 1) {
      renderEndDay = endTrippetDayDifference + Math.abs(totalDays) + 1;
    } else if (endTrippetDayDifference === 0) {
      renderEndDay = Math.abs(totalDays) + 1;
    } else if (endTrippetDayDifference > 0) {
      renderEndDay = endTrippetDayDifference + Math.abs(totalDays) + 1;
    } else if (endTrippetDayDifference < 0) {
      if (Math.abs(totalDays) - Math.abs(endTrippetDayDifference) < 0) {
        renderEndDay = Math.abs(totalDays) - Math.abs(endTrippetDayDifference);
      } else {
        renderEndDay =
          Math.abs(totalDays) + 1 - Math.abs(endTrippetDayDifference);
      }
    }
    setStartTrippetDay(renderStartDay);
    setEndTrippetDay(renderEndDay);
  }, [
    startDate,
    endDate,
    currentTrip.endDate,
    tripTotalDays,
    currentTrip.StartDate,
    currentTrip.EndDate,
  ]);

  useEffect(() => {
    if (values.type && trippetCategories) {
      trippetTypes.forEach(trippetType => {
        if (trippetType.TrippetTypeLook_ID === values.type) {
          trippetCategories.forEach(trippetCategory => {
            if (
              trippetCategory.TrippetTypeCategory_ID ===
              trippetType.TrippetTypeCategory_ID
            ) {
              if (trippetCategory.IsTransport === "0") {
                setDisabledFromField(true);
                setDistanceBetweenToPlaces("");
                setFromPlaceCopy(values.from);
                setValues({
                  ...values,
                  from: "",
                });
              } else {
                setDisabledFromField(false);
                if (fromPlaceCopy) {
                  setValues({
                    ...values,
                    from: fromPlaceCopy,
                  });
                }
              }
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type, trippetCategories]);

  useEffect(() => {
    if (currentTrippet) {
      const body = {
        Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
        TrippetNoteUser_ID: user.User_ID,
      };
      dispatch(getAllCurrentTrippetNotes(JSON.stringify(body)));
    }
  }, [currentTrippet, dispatch, user.User_ID]);

  useEffect(() => {
    if (currentTrippetNotes && user && currentTrippet) {
      currentTrippetNotes.forEach(note => {
        if (
          note.TrippetNoteUser_ID === user.User_ID &&
          note.Trippet_ID === currentTrippet.fullTrippet.Trippet_ID
        ) {
          setNotes(note.Notes);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrippetNotes, user, currentTrippet]);

  const callBackDistance = (response, status) => {
    if (status === "OK") {
      if (response.rows[0].elements[0].status === "OK") {
        setDistanceBetweenToPlaces(
          `${response.rows[0].elements[0].distance["text"]}, ${response.rows[0].elements[0].duration["text"]}`,
        );
      } else {
        setDistanceBetweenToPlaces(0);
      }
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDistanceGoogle = (
    placeOne,
    placeOneLat,
    placeOneLng,
    placeTwo,
    placeTwoLat,
    placeTwoLng,
  ) => {
    if (placeOne && placeTwo) {
      let startPlaceName = placeOne.formatted_address;
      let endPlaceName = placeTwo.formatted_address;
      let startPlace = new google.maps.LatLng(placeOneLat, placeOneLng);
      let endPlace = new google.maps.LatLng(placeTwoLat, placeTwoLng);
      let service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [startPlaceName, startPlace],
          destinations: [endPlaceName, endPlace],
          travelMode: window.google.maps.TravelMode.DRIVING,
          avoidHighways: false,
          avoidTolls: false,
        },
        callBackDistance,
      );
    }
  };

  const getDistanceGoogleWalk = (
    placeOne,
    placeOneLat,
    placeOneLng,
    placeTwo,
    placeTwoLat,
    placeTwoLng,
  ) => {
    if (placeOne && placeTwo) {
      let startPlaceName = placeOne.formatted_address;
      let endPlaceName = placeTwo.formatted_address;
      let startPlace = new google.maps.LatLng(placeOneLat, placeOneLng);
      let endPlace = new google.maps.LatLng(placeTwoLat, placeTwoLng);
      let service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [startPlaceName, startPlace],
          destinations: [endPlaceName, endPlace],
          travelMode: window.google.maps.TravelMode.WALKING,
          avoidHighways: false,
          avoidTolls: false,
        },
        callBackDistance,
      );
    }
  };

  // checking if we we have type filght or walk or driving
  // and depend of if its true or false calling different methods
  useEffect(() => {
    if (values.type === "flight") {
      if (
        autocompleteTo &&
        autocompleteTo.getPlace() &&
        autocompleteFrom &&
        autocompleteFrom.getPlace()
      ) {
        let placeOne = autocompleteFrom.getPlace();
        let placeTwo = autocompleteTo.getPlace();
        setDistanceBetweenToPlaces(
          getFlightDistance(
            placeOne,
            placeOne.geometry.location.lat(),
            placeOne.geometry.location.lng(),
            placeTwo,
            placeTwo.geometry.location.lat(),
            placeTwo.geometry.location.lng(),
          ),
        );
      } else if (
        autocompleteTo &&
        !autocompleteTo.getPlace() &&
        autocompleteFrom &&
        !autocompleteFrom.getPlace()
      ) {
        if (autocompleteFromEdit && autocompleteToEdit) {
          let placeOne = autocompleteFromEdit.getPlace();
          let placeTwo = autocompleteToEdit.getPlace();
          if (placeOne && placeTwo) {
            setDistanceBetweenToPlaces(
              getFlightDistance(
                placeOne,
                placeOne.geometry.location.lat,
                placeOne.geometry.location.lng,
                placeTwo,
                placeTwo.geometry.location.lat,
                placeTwo.geometry.location.lng,
              ),
            );
          }
        }
      } else if (
        autocompleteTo &&
        autocompleteTo.getPlace() &&
        autocompleteFrom &&
        !autocompleteFrom.getPlace()
      ) {
        let placeOne = autocompleteFromEdit.getPlace();
        let placeTwo = autocompleteTo.getPlace();
        setDistanceBetweenToPlaces(
          getFlightDistance(
            placeOne,
            placeOne.geometry.location.lat,
            placeOne.geometry.location.lng,
            placeTwo,
            placeTwo.geometry.location.lat(),
            placeTwo.geometry.location.lng(),
          ),
        );
      } else if (
        autocompleteTo &&
        !autocompleteTo.getPlace() &&
        autocompleteFrom &&
        autocompleteFrom.getPlace()
      ) {
        let placeOne = autocompleteFrom.getPlace();
        let placeTwo = autocompleteToEdit.getPlace();
        setDistanceBetweenToPlaces(
          getFlightDistance(
            placeOne,
            placeOne.geometry.location.lat(),
            placeOne.geometry.location.lng(),
            placeTwo,
            placeTwo.geometry.location.lat,
            placeTwo.geometry.location.lng,
          ),
        );
      }
    } else if (values.type === "walk") {
      if (
        autocompleteTo &&
        autocompleteTo.getPlace() &&
        autocompleteFrom &&
        autocompleteFrom.getPlace()
      ) {
        let placeOne = autocompleteFrom.getPlace();
        let placeTwo = autocompleteTo.getPlace();
        getDistanceGoogleWalk(
          placeOne,
          placeOne.geometry.location.lat(),
          placeOne.geometry.location.lng(),
          placeTwo,
          placeTwo.geometry.location.lat(),
          placeTwo.geometry.location.lng(),
        );
      } else if (
        autocompleteTo &&
        !autocompleteTo.getPlace() &&
        autocompleteFrom &&
        !autocompleteFrom.getPlace()
      ) {
        if (autocompleteFromEdit && autocompleteToEdit) {
          let placeOne = autocompleteFromEdit.getPlace();
          let placeTwo = autocompleteToEdit.getPlace();
          if (placeOne && placeTwo) {
            getDistanceGoogleWalk(
              placeOne,
              placeOne.geometry.location.lat,
              placeOne.geometry.location.lng,
              placeTwo,
              placeTwo.geometry.location.lat,
              placeTwo.geometry.location.lng,
            );
          }
        }
      } else if (
        autocompleteTo &&
        autocompleteTo.getPlace() &&
        autocompleteFrom &&
        !autocompleteFrom.getPlace()
      ) {
        let placeOne = autocompleteFromEdit.getPlace();
        let placeTwo = autocompleteTo.getPlace();
        getDistanceGoogleWalk(
          placeOne,
          placeOne.geometry.location.lat,
          placeOne.geometry.location.lng,
          placeTwo,
          placeTwo.geometry.location.lat(),
          placeTwo.geometry.location.lng(),
        );
      } else if (
        autocompleteTo &&
        !autocompleteTo.getPlace() &&
        autocompleteFrom &&
        autocompleteFrom.getPlace()
      ) {
        let placeOne = autocompleteFrom.getPlace();
        let placeTwo = autocompleteToEdit.getPlace();
        getDistanceGoogleWalk(
          placeOne,
          placeOne.geometry.location.lat(),
          placeOne.geometry.location.lng(),
          placeTwo,
          placeTwo.geometry.location.lat,
          placeTwo.geometry.location.lng,
        );
      }
    } else {
      if (
        autocompleteTo &&
        autocompleteTo.getPlace() &&
        autocompleteFrom &&
        autocompleteFrom.getPlace()
      ) {
        let placeOne = autocompleteFrom.getPlace();
        let placeTwo = autocompleteTo.getPlace();
        getDistanceGoogle(
          placeOne,
          placeOne.geometry.location.lat(),
          placeOne.geometry.location.lng(),
          placeTwo,
          placeTwo.geometry.location.lat(),
          placeTwo.geometry.location.lng(),
        );
      } else if (
        autocompleteTo &&
        !autocompleteTo.getPlace() &&
        autocompleteFrom &&
        !autocompleteFrom.getPlace()
      ) {
        if (
          autocompleteFromEdit &&
          autocompleteToEdit &&
          autocompleteFromEdit.getPlace() &&
          autocompleteToEdit.getPlace()
        ) {
          let placeOne = autocompleteFromEdit.getPlace();
          let placeTwo = autocompleteToEdit.getPlace();
          if (placeOne && placeTwo) {
            getDistanceGoogle(
              placeOne,
              placeOne.geometry.location.lat,
              placeOne.geometry.location.lng,
              placeTwo,
              placeTwo.geometry.location.lat,
              placeTwo.geometry.location.lng,
            );
          }
        }
      } else if (
        autocompleteTo &&
        autocompleteTo.getPlace() &&
        autocompleteFrom &&
        !autocompleteFrom.getPlace()
      ) {
        if (autocompleteFromEdit && autocompleteFromEdit.getPlace()) {
          let placeOne = autocompleteFromEdit.getPlace();
          let placeTwo = autocompleteTo.getPlace();
          if (placeOne && placeTwo) {
            getDistanceGoogle(
              placeOne,
              placeOne.geometry.location.lat,
              placeOne.geometry.location.lng,
              placeTwo,
              placeTwo.geometry.location.lat(),
              placeTwo.geometry.location.lng(),
            );
          }
        }
      } else if (
        autocompleteTo &&
        !autocompleteTo.getPlace() &&
        autocompleteFrom &&
        autocompleteFrom.getPlace()
      ) {
        if (autocompleteToEdit && autocompleteToEdit.getPlace()) {
          let placeOne = autocompleteFrom.getPlace();
          let placeTwo = autocompleteToEdit.getPlace();
          if (placeOne && placeTwo) {
            getDistanceGoogle(
              placeOne,
              placeOne.geometry.location.lat(),
              placeOne.geometry.location.lng(),
              placeTwo,
              placeTwo.geometry.location.lat,
              placeTwo.geometry.location.lng,
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type]);

  const renderDistanceIcon = () => {
    if (values.type === "walk") {
      return <DirectionsWalkIcon />;
    } else if (values.type === "flight") {
      return <AirplanemodeActiveIcon />;
    } else {
      return <DriveEtaIcon />;
    }
  };

  const renderDistance = distanceBetweenToPlaces ? distanceBetweenToPlaces : 0;

  const handleInputs = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckBox = event => {
    isPrivateTrip(event.target.checked);
  };

  // const handleCheckBoxTimeZones = event => {
  //   setShowTimeZones(event.target.checked);
  // };

  const handleIsAllDayCheckBox = event => {
    setIsAllDay(event.target.checked);
    if (event.target.checked) {
      let date = new Date();
      handleStartTime(date.setHours(0, 0, 0, 0));
      handleEndTime(date.setHours(23, 59, 0, 0));
    }
  };

  const handleClickExpandFullForm = () => {
    setExpandFullForm(!expandFullForm);
  };

  const handleUploadDocument = e => {
    e.persist();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setWrongFormatDocument({
        ...wrongFormatDocument,
        [e.target.name]: validateFileFormat(file),
      });
      if (validateFileFormat(file)) {
        setValues({
          ...values,
          [e.target.name]: file,
        });
        let formData = new FormData();
        formData.append("file", file);
        formData.append("ext", "document");
        formData.append("type", "trippetdocument");
        dispatch(uploadFile(formData, e.target.name, file.name, t));
      }
    };
    reader.readAsDataURL(file);
  };

  const getUploadParams = ({ file, meta }) => {
    if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/gif"
    ) {
      const body = new FormData();
      body.append("file", file);
      body.append("ext", "image");
      body.append("type", "trippetpicture");
      return { url: `${BASE_URL}api/upload.php`, body };
    } else {
      const body = new FormData();
      body.append("file", file);
      body.append("ext", "document");
      body.append("type", "trippetdocument");
      return { url: `${BASE_URL}api/upload.php`, body };
    }
  };

  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let data = JSON.parse(xhr.response);
      const body = {
        type: "personalDocument",
        file: data.File,
        File: data.File,
        name: meta.name,
        FileType: meta.type,
      };
      setValues({
        ...values,
        personalDocument: [...values.personalDocument, body],
      });
      dispatch(saveUploadedFile(body));
    }
  };

  const handleChangeStatusPictures = ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let data = JSON.parse(xhr.response);
      const body = {
        type: "trippetImage",
        file: data.File,
        File: data.File,
        Title: meta.name,
      };
      dispatch(saveUploadedFile(body));
    }
  };

  useEffect(() => {
    if (allTrippetImages && allTrippetImages.length === 1) {
      setDefaultPicture(allTrippetImages[0]);
    } else if (allTrippetImages.length === 0) {
      setDefaultPicture(null);
    }
  }, [allTrippetImages]);

  useEffect(() => {
    if (defaultPicture && allTrippetImages) {
      let image = allTrippetImages.find(
        image => image.File === defaultPicture.File,
      );
      dispatch(saveSelectedImage(image));
    } else {
      dispatch(clearSelectedImage());
    }
  }, [defaultPicture, dispatch, allTrippetImages]);

  useEffect(() => {
    if (
      currentTrippet &&
      currentTrippet.fullTrippet &&
      currentTrippet.fullTrippet.DefaultPicture
    ) {
      let body = {
        File: currentTrippet.fullTrippet.DefaultPicture,
      };
      setDefaultPicture(body);
    }
  }, [currentTrippet]);

  const handleUploadPictureModalClose = () => {
    setIsUploadPictureModalOpen(false);
  };

  const handleUploadPictureModalOpen = () => {
    setIsUploadPictureModalOpen(true);
  };

  const handleChangeStatusPublicDocument = ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let data = JSON.parse(xhr.response);
      const body = {
        type: "publicDocument",
        file: data.File,
        File: data.File,
        name: meta.name,
        FileType: meta.type,
      };
      setValues({
        ...values,
        personalDocument: [...values.personalDocument, body],
      });
      dispatch(saveUploadedFile(body));
    }
  };

  const handleRefresh = () => {
    if (
      autocompleteFrom &&
      !autocompleteFrom.getPlace() &&
      autocompleteTo &&
      !autocompleteTo.getPlace()
    ) {
      let placeOne = autocompleteFromEdit.getPlace();
      let placeTwo = autocompleteToEdit.getPlace();
      getDistanceGoogle(
        placeOne,
        placeOne.geometry.location.lat,
        placeOne.geometry.location.lng,
        placeTwo,
        placeTwo.geometry.location.lat,
        placeTwo.geometry.location.lng,
      );
    }
    if (autocompleteFrom && !autocompleteFrom.getPlace()) {
      let placeOne = autocompleteFromEdit.getPlace();
      let placeTwo = autocompleteTo.getPlace();
      getDistanceGoogle(
        placeOne,
        placeOne.geometry.location.lat,
        placeOne.geometry.location.lng,
        placeTwo,
        placeTwo.geometry.location.lat(),
        placeTwo.geometry.location.lng(),
      );
    }
    if (autocompleteTo && !autocompleteTo.getPlace()) {
      let placeOne = autocompleteFrom.getPlace();
      let placeTwo = autocompleteToEdit.getPlace();
      getDistanceGoogle(
        placeOne,
        placeOne.geometry.location.lat(),
        placeOne.geometry.location.lng(),
        placeTwo,
        placeTwo.geometry.location.lat,
        placeTwo.geometry.location.lng,
      );
    }
  };

  const onLoadTo = autocomplete => {
    setAutocompleteTo(autocomplete);
  };

  const onPlaceChangedTo = () => {
    if (autocompleteTo !== null) {
      setValues({
        ...values,
        to: autocompleteTo.getPlace().name,
        toInfo: autocompleteTo.getPlace(),
      });
      setDisabledRefreshButton(false);
      if (values.fromInfo) {
        if (values.type === "flight") {
          if (autocompleteFrom && !autocompleteFrom.getPlace()) {
            let placeOne = autocompleteFromEdit.getPlace();
            let placeTwo = autocompleteTo.getPlace();
            setDistanceBetweenToPlaces(
              getFlightDistance(
                placeOne,
                placeOne.geometry.location.lat,
                placeOne.geometry.location.lng,
                placeTwo,
                placeTwo.geometry.location.lat(),
                placeTwo.geometry.location.lng(),
              ),
            );
          } else {
            let placeOne = autocompleteFrom.getPlace();
            let placeTwo = autocompleteTo.getPlace();
            setDistanceBetweenToPlaces(
              getFlightDistance(
                placeOne,
                placeOne.geometry.location.lat(),
                placeOne.geometry.location.lng(),
                placeTwo,
                placeTwo.geometry.location.lat(),
                placeTwo.geometry.location.lng(),
              ),
            );
          }
        } else if (values.type === "walk") {
          if (autocompleteFrom && autocompleteFrom.getPlace()) {
            let placeOne = autocompleteFrom.getPlace();
            let placeTwo = autocompleteTo.getPlace();
            getDistanceGoogleWalk(
              placeOne,
              placeOne.geometry.location.lat(),
              placeOne.geometry.location.lng(),
              placeTwo,
              placeTwo.geometry.location.lat(),
              placeTwo.geometry.location.lng(),
            );
          }
        } else {
          if (autocompleteFrom && autocompleteFrom.getPlace()) {
            let placeOne = autocompleteFrom.getPlace();
            let placeTwo = autocompleteTo.getPlace();
            getDistanceGoogle(
              placeOne,
              placeOne.geometry.location.lat(),
              placeOne.geometry.location.lng(),
              placeTwo,
              placeTwo.geometry.location.lat(),
              placeTwo.geometry.location.lng(),
            );
          }
        }
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const onLoadFrom = autocomplete => {
    setAutocompleteFrom(autocomplete);
  };

  const onPlaceChangedFrom = () => {
    if (autocompleteFrom !== null) {
      setValues({
        ...values,
        from: autocompleteFrom.getPlace().name,
        fromInfo: autocompleteFrom.getPlace(),
      });
      setDisabledRefreshButton(false);
      if (values.toInfo) {
        if (values.type === "flight") {
          if (autocompleteTo && !autocompleteTo.getPlace()) {
            let placeOne = autocompleteFrom.getPlace();
            let placeTwo = autocompleteToEdit.getPlace();
            setDistanceBetweenToPlaces(
              getFlightDistance(
                placeOne,
                placeOne.geometry.location.lat(),
                placeOne.geometry.location.lng(),
                placeTwo,
                placeTwo.geometry.location.lat,
                placeTwo.geometry.location.lng,
              ),
            );
          } else {
            let placeOne = autocompleteFrom.getPlace();
            let placeTwo = autocompleteTo.getPlace();
            setDistanceBetweenToPlaces(
              getFlightDistance(
                placeOne,
                placeOne.geometry.location.lat(),
                placeOne.geometry.location.lng(),
                placeTwo,
                placeTwo.geometry.location.lat(),
                placeTwo.geometry.location.lng(),
              ),
            );
          }
        } else if (values.type === "walk") {
          if (autocompleteTo && autocompleteTo.getPlace()) {
            let placeOne = autocompleteFrom.getPlace();
            let placeTwo = autocompleteTo.getPlace();
            getDistanceGoogleWalk(
              placeOne,
              placeOne.geometry.location.lat(),
              placeOne.geometry.location.lng(),
              placeTwo,
              placeTwo.geometry.location.lat(),
              placeTwo.geometry.location.lng(),
            );
          }
        } else {
          if (autocompleteTo && autocompleteTo.getPlace()) {
            let placeOne = autocompleteFrom.getPlace();
            let placeTwo = autocompleteTo.getPlace();
            getDistanceGoogle(
              placeOne,
              placeOne.geometry.location.lat(),
              placeOne.geometry.location.lng(),
              placeTwo,
              placeTwo.geometry.location.lat(),
              placeTwo.geometry.location.lng(),
            );
          }
        }
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleParticipantsModalOpen = () => {
    setIsParticipantsModalOpen(true);
  };

  const handleParticipantsModalClose = () => {
    setIsParticipantsModalOpen(false);
  };

  const handleSaveParticipants = participants => {
    setValues({
      ...values,
      participants: participants,
    });
  };

  const handleResponsibleToModalOpen = () => {
    setIsResponsibleModalOpen(true);
  };

  const handleResponsibleToModalClose = () => {
    setIsResponsibleModalOpen(false);
  };

  const handleSaveResponsible = participants => {
    setValues({
      ...values,
      responsible: participants,
    });
  };

  const handleRecommendationModalOpen = event => {
    event.stopPropagation();
    setIsRecommendationModalOpen(true);
  };

  const handleRecommendationModalClose = event => {
    event.stopPropagation();
    setIsRecommendationModalOpen(false);
    handleClose();
  };

  const handleStartDateCalendar = date => {
    handleStartDate(date);
    handleEndDate(date);
  };

  const createTrippetNote = () => {
    if (currentTrippetNotes.length > 0 && user) {
      currentTrippetNotes.forEach(note => {
        if (note.TrippetNoteUser_ID === user.User_ID) {
          if (note.Notes !== notes) {
            const body = {
              Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
              TrippetNoteUser_ID: user.User_ID,
              Notes: notes,
            };

            const deletedNote = {
              TrippetNote_ID: note.TrippetNote_ID,
            };
            dispatch(createTrippetNotes(JSON.stringify(body)));
            dispatch(deleteTrippetNotes(JSON.stringify(deletedNote)));
          }
        }
      });
    } else {
      const body = {
        Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
        TrippetNoteUser_ID: user.User_ID,
        Notes: notes,
      };
      dispatch(createTrippetNotes(JSON.stringify(body)));
    }
  };

  const deleteTrippetNote = () => {
    currentTrippetNotes.forEach(note => {
      if (note.TrippetNoteUser_ID === user.User_ID) {
        if (note.Notes !== notes) {
          const deletedNote = {
            TrippetNote_ID: note.TrippetNote_ID,
          };
          dispatch(deleteTrippetNotes(JSON.stringify(deletedNote)));
        }
      }
    });
  };

  const handleNotes = event => {
    setNotes(event.target.value);
  };

  useEffect(() => {
    if (trippetCategories && trippetTypes) {
      let array = [];
      trippetCategories.forEach(trippetCategory => {
        let category = {
          type: "category",
          name: trippetCategory.Name,
          id: trippetCategory.TrippetTypeCategory_ID,
        };
        array.push(category);
        trippetTypes.forEach(trippetType => {
          if (
            trippetType.TrippetTypeCategory_ID ===
            trippetCategory.TrippetTypeCategory_ID
          ) {
            let type = {
              type: "type",
              name: trippetType.Name,
              id: trippetType.TrippetTypeLook_ID,
            };
            array.push(type);
          }
        });
      });
      setTrippetTypesWithCategories(array);
    }
  }, [trippetTypes, trippetCategories]);

  const renderTrippetTypes =
    trippetTypesWithCategories && trippetTypesWithCategories ? (
      trippetTypesWithCategories.map(type => {
        if (type.type === "category") {
          return (
            <MenuItem key={uniqid()} value={`${type.id}+C`} disabled>
              {type.name}
            </MenuItem>
          );
        } else {
          return (
            <MenuItem key={uniqid()} value={type.id}>
              {type.name}
            </MenuItem>
          );
        }
      })
    ) : (
      <MenuItem key={uniqid()} value="" disabled></MenuItem>
    );

  const renderTrippetStatus =
    trippetStatus && trippetStatus ? (
      trippetStatus.map(type => (
        <MenuItem key={type.PlanningStatus_ID} value={type.PlanningStatus_ID}>
          {type.Name}
        </MenuItem>
      ))
    ) : (
      <MenuItem key={uniqid()} value="" disabled></MenuItem>
    );

  const renderTripNameErrorMessage = errorTripNameMessage ? (
    <FormHelperText className={classes.errorText}>
      {t("Field is required.")}
    </FormHelperText>
  ) : null;

  const renderTypeErrorMessage = errorTypeMessage ? (
    <FormHelperText className={classes.errorText}>
      {t("Field is required.")}
    </FormHelperText>
  ) : null;

  const renderExpandIcon = expandFullForm ? (
    <div className={classes.expandIcon} onClick={handleClickExpandFullForm}>
      <ExpandLessIcon />
    </div>
  ) : (
    <div className={classes.expandIcon} onClick={handleClickExpandFullForm}>
      <ExpandMoreIcon />
    </div>
  );

  const renderPrivateDocumentMessage = wrongFormatDocument.personalDocument ? null : (
    <FormHelperText className={classes.errorText}>
      {t("This file format is not supported.")}
    </FormHelperText>
  );

  const renderPublicDocumentMessage = wrongFormatDocument.publicDocument ? null : (
    <FormHelperText className={classes.errorText}>
      {t("This file format is not supported.")}
    </FormHelperText>
  );

  const handleDeleteDocument = (document, type) => () => {
    if (type === "private") {
      const data = {
        body: {
          TrippetDocument_ID: document.TrippetDocument_ID,
        },
        query: {
          Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
          IsPrivate: "1",
          CreatorUser_ID: user.User_ID,
        },
        type: type,
      };
      dispatch(deleteTrippetDocument(data));
    } else {
      const data = {
        body: {
          TrippetDocument_ID: document.TrippetDocument_ID,
        },
        query: {
          Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
          IsPrivate: "0",
        },
        type: type,
      };
      dispatch(deleteTrippetDocument(data));
    }
  };

  const deleteTrippetPicture = event => {
    event.stopPropagation();
    if (selectedImage.TrippetPicture_ID) {
      const data = {
        body: {
          TrippetPicture_ID: selectedImage.TrippetPicture_ID,
        },
        query: {
          Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
        },
      };
      dispatch(deleteTrippetImage(data));
      setIsPreviewModalImageOpen(false);
    } else {
      if (allTrippetImages) {
        let allImages = [...allTrippetImages];
        allTrippetImages.forEach((picture, index) => {
          if (picture.File === selectedImage.File) {
            allImages.splice(index, 1);
            dispatch(saveTrippetImages(allImages));
          }
        });
      }
      setIsPreviewModalImageOpen(false);
    }

    if (selectedImage.File === defaultPicture.File) {
      if (allTrippetImages && allTrippetImages.length > 0) {
        setDefaultPicture(allTrippetImages[0]);
      } else {
        setDefaultPicture(null);
      }
    }
  };

  const handlePreviewModalImageModalClose = () => {
    setIsPreviewModalImageOpen(false);
  };

  const handlePreviewModalImageModalOpen = () => {
    setIsPreviewModalImageOpen(true);
  };

  const handleImageComments = (picture, comment, defaultPicture) => {
    if (comment) {
      let img = null;
      let allImages = [...allTrippetImages];
      let index = null;
      allImages.forEach((image, i) => {
        if (image.TrippetPicture_ID === picture.TrippetPicture_ID) {
          console.log("image", image);
          image.Comments = comment;
          index = i;
          img = {
            ...image,
          };
        }
      });
      if (currentTrippet && currentTrippet.fullTrippet.Trippet_ID) {
        if (img.TrippetPicture_ID) {
          const data = {
            body: img,
            Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
          };
          dispatch(updateTrippetPicture(data));
        } else {
          allImages[index] = img;
          saveTrippetImages(allImages);
        }
      } else {
        allImages[index] = img;
        saveTrippetImages(allImages);
      }
    }
    if (defaultPicture) {
      setDefaultPicture(picture);
    }
    if (allTrippetImages && allTrippetImages.length === 1) {
      setDefaultPicture(allTrippetImages[0]);
    }
  };

  const renderDocumentIcon = type => {
    if (type === "application/pdf") {
      return <GrDocumentPdf className={classes.documentIcon} />;
    } else if (type === "text/plain") {
      return <GrDocumentTxt className={classes.documentIcon} />;
    } else if (type === "application/zip") {
      return <GrDocumentZip className={classes.documentIcon} />;
    } else if (
      type === "application/vnd.ms-excel" ||
      type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <FaFileExcel className={classes.documentIcon} />;
    } else {
      return <FaFileWord className={classes.documentIcon} />;
    }
  };

  const renderImage = selectedImage ? (
    <img
      src={`${BASE_URL}${selectedImage.File && selectedImage.File.substr(1)}`}
      alt={selectedImage.Title}
      className={classes.previewImage}
    />
  ) : (
    <img
      alt="placeholder"
      src={PlaceholderImage}
      className={classes.previewImage}
    />
  );

  const renderPersonalDocumentName =
    allPersonalDocuments && allPersonalDocuments.length > 0
      ? allPersonalDocuments.map(document => (
          <div
            className={classes.singleDocument}
            key={document.File || document.file}
          >
            {renderDocumentIcon(document.FileType)}
            <a
              href={`${BASE_URL}api/download.php?f=${document.File}`}
              target="_blank"
              rel="noopener noreferrer"
              download={document.Title || document.name}
            >
              {(document && document.name) || document.Title}
            </a>
            <CloseIcon onClick={handleDeleteDocument(document, "private")} />
          </div>
        ))
      : null;

  const renderPublicDocumentName =
    allPublicDocuments && allPublicDocuments.length > 0
      ? allPublicDocuments.map(document => (
          <div
            className={classes.singleDocument}
            key={document.File || document.file}
          >
            {renderDocumentIcon(document.FileType)}
            <a
              href={`${BASE_URL}api/download.php?f=${document.File}`}
              download={document.Title || document.name}
              target="_blank"
              rel="noopener noreferrer"
              // onClick={handleDownload(document)}
            >
              {(document && document.name) || document.Title}
            </a>
            <CloseIcon onClick={handleDeleteDocument(document, "public")} />
          </div>
        ))
      : null;

  const getLinkValue = value => {
    let link = "";
    if (value) {
      console.log("value", value);
      if (value.substring(0, 7) === "http://") {
        let newLink = value.substring(7);
        link = newLink;
      } else if (value.substring(0, 8) === "https://") {
        let newLink = value.substring(8);
        link = newLink;
      } else {
        link = value;
      }
    }
    return link;
  };

  const showBigForm = expandFullForm ? (
    <BigForm
      classes={classes}
      values={values}
      t={t}
      handleInputs={handleInputs}
      handleUploadDocument={handleUploadDocument}
      setValues={setValues}
      wrongFormatDocument={wrongFormatDocument}
      renderPrivateDocumentMessage={renderPrivateDocumentMessage}
      renderPublicDocumentMessage={renderPublicDocumentMessage}
      renderPublicDocumentName={renderPublicDocumentName}
      renderPersonalDocumentName={renderPersonalDocumentName}
      personalFileURL={allPersonalDocuments}
      allPublicDocuments={allPublicDocuments}
      notes={notes}
      handleNotes={handleNotes}
      message={message}
      handleChatMessage={handleChatMessage}
      typing={typing}
      typingUser={typingUser}
      messages={messages}
      renderMessages={renderMessages}
      isEditMode={isEditMode}
      getUploadParams={getUploadParams}
      handleChangeStatus={handleChangeStatus}
      handleChangeStatusPublicDocument={handleChangeStatusPublicDocument}
      handleUploadPictureModalOpen={handleUploadPictureModalOpen}
      handleChangeStatusPictures={handleChangeStatusPictures}
      renderImage={renderImage}
      allTrippetPictures={allTrippetImages}
      deleteTrippetPicture={deleteTrippetPicture}
      selectedImage={selectedImage}
      handlePreviewModalImageModalOpen={handlePreviewModalImageModalOpen}
      descriptionError={descriptionError}
      getLinkValue={getLinkValue}
    />
  ) : null;

  const handleSubmit = () => {
    if (values.trippetName && values.type) {
      setErrorTripNameMessage(false);
      setErrorTypeMessage(false);
      let organizerRoleId = "";
      allUsersRoles.forEach(role => {
        if (role.Name === "Organizer") {
          organizerRoleId = role.UserRole_ID;
        }
      });
      if (!isEditMode) {
        const participantsIds = [];
        values.participants &&
          values.participants.forEach(participant => {
            if (participant.User_ID === user.User_ID) {
              participantsIds.push({
                TrippetParticipantUser_ID:
                  participant.TrippetParticipantUser_ID || participant.User_ID,
                TrippetParticipantUserRole_ID:
                  participant.TrippetParticipantUserRole_ID || "2",
                ParticipantStatus_ID: participant.ParticipantStatus_ID || 2,
                PlanningStatus_ID: participant.PlanningStatus_ID || "",
                InviteeEmail: "",
              });
            } else {
              participantsIds.push({
                TrippetParticipantUser_ID:
                  participant.TrippetParticipantUser_ID || participant.User_ID,
                TrippetParticipantUserRole_ID:
                  participant.TrippetParticipantUserRole_ID || "2",
                ParticipantStatus_ID: participant.ParticipantStatus_ID || 1,
                PlanningStatus_ID: participant.PlanningStatus_ID || "",
                InviteeEmail: "",
              });
            }
          });
        if (externalUsersEmail && externalUsersEmail.length > 0) {
          externalUsersEmail.forEach(email =>
            participantsIds.push({
              TripParticipantUserRole_ID:
                email.TripParticipantUserRole_ID || "2",
              ParticipantStatus_ID: email.ParticipantStatus_ID || "",
              PlanningStatus_ID: email.PlanningStatus_ID || "",
              InviteeEmail: email,
            }),
          );
        }
        const organizersIds = [];
        values.responsible &&
          values.responsible.forEach(org => {
            organizersIds.push({
              TrippetOrganizerUser_ID:
                org.TrippetOrganizerUser_ID || org.User_ID,
              TrippetOrganizerUserRole_ID:
                org.TrippetOrganizerUserRole_ID || organizerRoleId,
              TrippetOrganizerEnableStatus:
                org.TrippetOrganizerEnableStatus || 1,
              TrippetOrganizerAcceptanceStatus:
                org.TrippetOrganizerAcceptanceStatus || 1,
              InviteeEmail: "",
            });
          });

        const formatedStartDate = format(startDate, "yyyy-MM-dd");
        const formatedEndDate = format(endDate, "yyyy-MM-dd");
        const formatedStartTime = format(startTime, "HH:mm");
        const formatedEndTime = format(endTime, "HH:mm");
        const trippetStartTime = `${formatedStartDate}T${formatedStartTime}Z+01:00`;
        const trippetEndTime = `${formatedEndDate}T${formatedEndTime}Z+01:00`;
        let autocompleteFromObj = "";
        let autocompleteToObj = "";
        if (autocompleteFrom && autocompleteFrom.getPlace()) {
          autocompleteFromObj = autocompleteFrom.getPlace();
          delete autocompleteFromObj.adr_address;
          delete autocompleteFromObj.photos;
          delete autocompleteFromObj.utc_offset;
          delete autocompleteFromObj.utc_offset_minutes;
          delete autocompleteFromObj.html_attributions;
          delete autocompleteFromObj.website;
          delete autocompleteFromObj.vicinity;
          delete autocompleteFromObj.reviews;
          delete autocompleteFromObj.plus_code;
        }
        if (autocompleteTo && autocompleteTo.getPlace()) {
          autocompleteToObj = autocompleteTo.getPlace();
          delete autocompleteToObj.adr_address;
          delete autocompleteToObj.photos;
          delete autocompleteToObj.utc_offset;
          delete autocompleteToObj.utc_offset_minutes;
          delete autocompleteToObj.html_attributions;
          delete autocompleteToObj.website;
          delete autocompleteToObj.vicinity;
          delete autocompleteToObj.reviews;
          delete autocompleteToObj.plus_code;
        }

        const renderDistance = disabledFromField ? "" : distanceBetweenToPlaces;

        let trippetImages = [];

        if (allTrippetImages && allTrippetImages.length > 0) {
          allTrippetImages.forEach(image => {
            if (!image.TrippetPicture_ID) {
              let trippetImage = {
                Trippet_ID: null,
                Title: image.Title,
                Comments: image.Comments,
                File: image.File || image.file,
                CreatorUser_ID: user.User_ID,
                IsPrivate: "0",
              };
              trippetImages.push(trippetImage);
            }
          });
        }

        let trippetPublicDocuments = [];

        let trippetPrivateDocuments = [];

        if (allPersonalDocuments && allPersonalDocuments.length > 0) {
          allPersonalDocuments.forEach(document => {
            let trippetPrivateDocumentBody = {
              type: "create",
              Trippet_ID: null,
              Title: document.name,
              Comments: document.Comments,
              File: document.File || document.file,
              IsPrivate: 1,
              CreatorUser_ID: user.User_ID,
            };
            trippetPrivateDocuments.push(trippetPrivateDocumentBody);
          });
        }

        if (allPublicDocuments && allPublicDocuments.length > 0) {
          allPublicDocuments.forEach(document => {
            let trippetPrivateDocumentBody = {
              type: "create",
              Trippet_ID: null,
              Title: document.name,
              Comments: document.Comments,
              File: document.File || document.file,
              IsPrivate: 0,
              CreatorUser_ID: user.User_ID,
            };
            trippetPublicDocuments.push(trippetPrivateDocumentBody);
          });
        }

        const trippetBody = {
          Trip_ID: currentTrip.Trip_ID,
          IsChildOf: values.isChildOf,
          OwnerUser_ID: user.User_ID,
          CreatorUser_ID: user.User_ID,
          TrippetTypeLook_ID: values.type,
          Title: values.trippetName,
          StartDate: trippetStartTime,
          StartTimeZoneDesc: "Europe/Berlin",
          EndDate: trippetEndTime,
          EndTimeZoneDesc: "Europe/Berlin",
          IsWholeDay: isAllDay ? "1" : "0",
          StartDay: +startTrippetDay,
          EndDay: +endTrippetDay,
          FromPlace: disabledFromField ? "" : values.from,
          ToPlace: values.to,
          FromData: disabledFromField
            ? JSON.stringify("")
            : JSON.stringify(autocompleteFromObj)
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, ""),
          ToData: JSON.stringify(autocompleteToObj)
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          Distance: distanceBetweenToPlaces ? renderDistance : "",
          Duration: "25:15",
          Description: values.description,
          OwnerPlanningStatus_ID: values.status,
          Price: +values.price,
          Currency_ID: "2",
          ExchangeRate: "1",
          ExchangeRateDate: "2020-03-01T23:05",
          Link: values.link,
          IsPrivate: privateTrip ? "1" : "0",
          Capacity: "12",
          Participants: participantsIds,
          Organizers: organizersIds,
          DefaultPicture: defaultPicture ? defaultPicture.File : "",
        };
        dispatch(
          createTrippet(
            JSON.stringify(trippetBody),
            trippetImages,
            trippetPublicDocuments,
            trippetPrivateDocuments,
            currentTrip.Trip_ID,
            user.User_ID,
            notes,
            sortElement,
            sortByDirection[sortElement],
            currentTrip.CreatorUser_ID,
          ),
        );
        dispatch(clearSelectedImage());
        dispatch(savePaginationPage(1));
        handleClose();
        calendarInstance.render(true);
      } else {
        const participantsIds = [];
        values.participants &&
          values.participants.forEach(participant => {
            if (participant.User_ID === user.User_ID) {
              participantsIds.push({
                TrippetParticipantUser_ID:
                  participant.TrippetParticipantUser_ID || participant.User_ID,
                TrippetParticipantUserRole_ID:
                  participant.TrippetParticipantUserRole_ID || "2",
                ParticipantStatus_ID: participant.ParticipantStatus_ID || 2,
                PlanningStatus_ID: participant.PlanningStatus_ID || "",
                InviteeEmail: "",
              });
            } else {
              participantsIds.push({
                TrippetParticipantUser_ID:
                  participant.TrippetParticipantUser_ID || participant.User_ID,
                TrippetParticipantUserRole_ID:
                  participant.TrippetParticipantUserRole_ID || "2",
                ParticipantStatus_ID: participant.ParticipantStatus_ID || 1,
                PlanningStatus_ID: participant.PlanningStatus_ID || "",
                InviteeEmail: "",
              });
            }
          });
        if (externalUsersEmail && externalUsersEmail.length > 0) {
          externalUsersEmail.forEach(email =>
            participantsIds.push({
              TripParticipantUserRole_ID:
                email.TripParticipantUserRole_ID || "2",
              ParticipantStatus_ID: email.ParticipantStatus_ID || "",
              PlanningStatus_ID: email.PlanningStatus_ID || "",
              InviteeEmail: email,
            }),
          );
        }
        const organizersIds = [];
        values.responsible &&
          values.responsible.forEach(org => {
            organizersIds.push({
              TrippetOrganizerUser_ID:
                org.TrippetOrganizerUser_ID || org.User_ID,
              TrippetOrganizerUserRole_ID:
                org.TrippetOrganizerUserRole_ID || organizerRoleId,
              TrippetOrganizerEnableStatus:
                org.TrippetOrganizerEnableStatus || 1,
              TrippetOrganizerAcceptanceStatus:
                org.TrippetOrganizerAcceptanceStatus || 1,
              InviteeEmail: "",
            });
          });
        const formatedStartDate = format(startDate, "yyyy-MM-dd");
        const formatedEndDate = format(endDate, "yyyy-MM-dd");
        const formatedStartTime = format(startTime, "HH:mm:ss");
        const formatedEndTime = format(endTime, "HH:mm:ss");
        const trippetStartTime = `${formatedStartDate}T${formatedStartTime}Z+01:00`;
        const trippetEndTime = `${formatedEndDate}T${formatedEndTime}Z+01:00`;
        let autocompleteFromObj = "";
        let autocompleteToObj = "";
        let fromData = "";
        let toData = "";
        if (autocompleteFrom && autocompleteFrom.getPlace()) {
          autocompleteFromObj = autocompleteFrom.getPlace();
          delete autocompleteFromObj.adr_address;
          delete autocompleteFromObj.photos;
          delete autocompleteFromObj.utc_offset;
          delete autocompleteFromObj.utc_offset_minutes;
          delete autocompleteFromObj.html_attributions;
          delete autocompleteFromObj.website;
          delete autocompleteFromObj.vicinity;
          delete autocompleteFromObj.reviews;
          delete autocompleteFromObj.plus_code;

          fromData = JSON.stringify(autocompleteFromObj)
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        } else {
          fromData = JSON.stringify(autocompleteFromEdit.getPlace());
        }
        if (autocompleteTo && autocompleteTo.getPlace()) {
          autocompleteToObj = autocompleteTo.getPlace();
          delete autocompleteToObj.adr_address;
          delete autocompleteToObj.photos;
          delete autocompleteToObj.utc_offset;
          delete autocompleteToObj.utc_offset_minutes;
          delete autocompleteToObj.html_attributions;
          delete autocompleteToObj.website;
          delete autocompleteToObj.vicinity;
          delete autocompleteToObj.reviews;
          delete autocompleteToObj.plus_code;

          toData = JSON.stringify(autocompleteToObj)
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        } else {
          toData = JSON.stringify(autocompleteToEdit.getPlace());
        }
        const renderDistance = disabledFromField ? "" : distanceBetweenToPlaces;
        const trippetBody = {
          IsChildOf: values.IsChildOf,
          Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
          StartDay: +startTrippetDay,
          EndDay: +endTrippetDay,
          TrippetTypeLook_ID: values.type,
          StartDate: trippetStartTime,
          EndDate: trippetEndTime,
          FromPlace: disabledFromField ? "" : values.from,
          ToPlace: values.to,
          Title: values.trippetName,
          Description: values.description,
          FromData: disabledFromField ? JSON.stringify("") : fromData,
          ToData: toData,
          OwnerPlanningStatus_ID: values.status,
          Participants: participantsIds,
          Distance: distanceBetweenToPlaces ? renderDistance : "",
          Price: +values.price,
          Link: values.link,
          Notes: values.notes,
          IsWholeDay: isAllDay ? 1 : 0,
          IsPrivate: privateTrip ? "1" : "0",
          Organizers: organizersIds,
          DefaultPicture: defaultPicture ? defaultPicture.File : "",
        };

        let trippetImages = [];

        if (allTrippetImages && allTrippetImages.length > 0) {
          allTrippetImages.forEach(image => {
            if (!image.TrippetPicture_ID) {
              let trippetImage = {
                Trippet_ID: null,
                Title: image.Title,
                Comments: image.Comments,
                File: image.File || image.file,
                CreatorUser_ID: user.User_ID,
                IsPrivate: "0",
              };
              trippetImages.push(trippetImage);
            }
          });
        }

        // if (imageFileURL && imageFileURL.file) {
        //   trippetPictureBody = {
        //     CreatorUser_ID: user.User_ID,
        //     Title: values.pictureTitle,
        //     File: imageFileURL.file,
        //     Comments: '',
        //     IsPrivate: '0',
        //   };
        // }

        let trippetPrivateDocuments = [];
        let trippetPublicDocuments = [];

        if (allPersonalDocuments && allPersonalDocuments.length > 0) {
          allPersonalDocuments.forEach(document => {
            if (!document.TrippetDocument_ID) {
              let trippetPrivateDocumentBody = {
                type: "create",
                Trippet_ID: null,
                Title: document.name,
                Comments: "",
                File: document.File || document.file,
                IsPrivate: 1,
                CreatorUser_ID: user.User_ID,
              };
              trippetPrivateDocuments.push(trippetPrivateDocumentBody);
            }
          });
        }

        if (allPublicDocuments && allPublicDocuments.length > 0) {
          allPublicDocuments.forEach(document => {
            if (!document.TrippetDocument_ID) {
              let trippetPrivateDocumentBody = {
                type: "create",
                Trippet_ID: null,
                Title: document.name,
                Comments: "",
                File: document.File || document.file,
                IsPrivate: 0,
                CreatorUser_ID: user.User_ID,
              };
              trippetPublicDocuments.push(trippetPrivateDocumentBody);
            }
          });
        }
        dispatch(
          changeTrippet(
            JSON.stringify(trippetBody),
            trippetImages,
            trippetPublicDocuments,
            trippetPrivateDocuments,
            currentTrip.Trip_ID,
            user.User_ID,
            sortElement,
            sortByDirection[sortElement],
            usedFilters,
            currentTrippet.fullTrippet.CreatorUser_ID,
          ),
        );
        dispatch(clearSelectedImage());
        dispatch(savePaginationPage(1));
        if (notes) {
          createTrippetNote(values);
          dispatch(clearCurrentTrippetNotes());
        } else {
          deleteTrippetNote();
          dispatch(clearCurrentTrippetNotes());
        }

        handleClose();
        if (calendarInstance) {
          calendarInstance.render(true);
        }
      }
    } else if (values.trippetName) {
      setErrorTypeMessage(true);
      setErrorTripNameMessage(false);
    } else if (values.type) {
      setErrorTripNameMessage(true);
      setErrorTypeMessage(false);
    } else {
      setErrorTypeMessage(true);
      setErrorTripNameMessage(true);
    }
  };

  const handleInvitationalModalOpen = event => {
    event.stopPropagation();
    setIsInvitationalModalOpen(true);
  };

  const handleInvitationalModalClose = event => {
    event.stopPropagation();
    setIsInvitationalModalOpen(false);
  };

  const handleSendAsRecommendation = event => {
    const body = {
      fullTrippet: {
        OwnerTrippet: user.User_ID,
        Day: `${startTrippetDay} - ${endTrippetDay}`,
        trippettype: values.type,
        startdate_trippet: Math.floor(
          new Date(
            `${format(startDate, "yyyy-MM-dd")} ${format(
              startTime,
              "HH:mm:ss",
            )}`,
          ) / 1000,
        ),
        enddate_trippet: Math.floor(
          new Date(
            `${format(endDate, "yyyy-MM-dd")} ${format(endTime, "HH:mm:ss")}`,
          ) / 1000,
        ),
        from_trippet: values.from,
        to_trippet: values.to,
        title_trippet: values.trippetName,
        description_trippet: values.description,
        status: values.status,
        Trip_id: currentTrip.Trip_ID,
        Trippet_ID: currentTrippet.fullTrippet.Trippet_ID,
      },
    };
    setTrippetRecommendationInfo(body);
    handleRecommendationModalOpen(event);
  };

  const handleSaveExternalUsersEmail = emails => {
    if (emails && emails.length > 0) {
      setExternalUsersEmail(emails);
    }
  };

  const renderUserPicture = id => {
    if (allUsers && allUsers.length > 0) {
      let data = "";
      allUsers.forEach(user => {
        if (id === user.User_ID) {
          data = renderUserInfo(user);
        }
      });
      return data;
    }
  };

  const renderUserInfo = participant => {
    if (participant.Picture) {
      return (
        <img
          src={participant.Picture}
          alt={participant.TrippetParticipantInitials}
          className={classes.avatarImage}
        />
      );
    } else {
      return `${participant.Initials}`;
    }
  };

  const renderParticipantsStatusColor = status => {
    if (status === "1" || status === null || status === "0") {
      return classes.pending;
    } else if (status === "2") {
      return classes.accepted;
    } else if (status === "3") {
      return classes.rejected;
    }
  };

  const renderParticipants =
    values.participants && values.participants.length > 0
      ? values.participants.map(participant => (
          <Tooltip
            title={participant.TrippetParticipantDisplayName}
            placement="top"
            key={participant.TrippetParticipant_ID}
            arrow
          >
            <Avatar
              className={renderParticipantsStatusColor(
                participant.ParticipantStatus_ID,
              )}
              key={uniqid()}
            >
              {participant.TrippetParticipantUser_ID
                ? renderUserPicture(participant.TrippetParticipantUser_ID)
                : renderUserPicture(participant.User_ID)}
            </Avatar>
          </Tooltip>
        ))
      : [];

  const renderResponsible =
    values.responsible && values.responsible.length > 0
      ? values.responsible.map(participant => (
          <Tooltip
            title={participant.TrippetOrganizerDisplayName}
            placement="top"
            key={participant.TrippetOrganizer_ID}
            arrow
          >
            <Avatar
              className={renderParticipantsStatusColor(
                participant.TrippetOrganizerAcceptanceStatus,
              )}
              key={uniqid()}
            >
              {participant.TrippetOrganizerUser_ID
                ? renderUserPicture(participant.TrippetOrganizerUser_ID)
                : renderUserPicture(participant.User_ID)}
            </Avatar>
          </Tooltip>
        ))
      : [];
  const renderBorderTop = expandFullForm ? classes.borderTop : null;

  const renderIsPrivate = () => {
    // tripOwner?.User_ID === user?.User_ID ? (
    //   <FormControlLabel
    //     control={
    //       <Checkbox
    //         checked={privateTrip}
    //         onChange={handleCheckBox}
    //         name="privateTrip"
    //         color="primary"
    //       />
    //     }
    //     label={t('Private')}
    //   />
    // )
    if (isEditMode) {
      if (tripOwner) {
        if (tripOwner?.User_ID === user?.User_ID) {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={privateTrip}
                  onChange={handleCheckBox}
                  name="privateTrip"
                  color="primary"
                />
              }
              label={t("Private")}
            />
          );
        }
      }
    } else {
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={privateTrip}
              onChange={handleCheckBox}
              name="privateTrip"
              color="primary"
            />
          }
          label={t("Private")}
        />
      );
    }
  };

  const renderTrippetOwner = () => {
    if (isEditMode) {
      if (tripOwner) {
        if (tripOwner.Picture) {
          return (
            <img
              src={tripOwner.Picture}
              alt={tripOwner.Initials}
              className={classes.avatarImage}
            />
          );
        } else {
          return tripOwner.Initials;
        }
      }
    } else {
      if (user) {
        if (user.Picture) {
          return (
            <img
              src={user.Picture}
              alt={user.Initials}
              className={classes.avatarImage}
            />
          );
        } else {
          return user.Initials;
        }
      }
    }
  };

  const handleSwapDestinationFields = () => {
    setIsFromFieldLeft(!isFromFieldLeft);
  };

  const getTheParentName = id => {
    let name = "";
    if (parentsTrippet && parentsTrippet.length > 0) {
      let item = parentsTrippet.find(trippet => +trippet.Trippet_ID === +id);
      name = item.Title;
    }
    return name;
  };
  return (
    <div className={classes.trippetFormContainer}>
      <DialogContent>
        <Grid container alignItems="flex-start" justify="center">
          <SmallForm
            classes={classes}
            values={values}
            t={t}
            handleInputs={handleInputs}
            startDate={startDate}
            endDate={endDate}
            handleEndDate={handleEndDate}
            handleStartDate={handleStartDate}
            startTime={startTime}
            endTime={endTime}
            handleEndTime={handleEndTime}
            handleStartTime={handleStartTime}
            onPlaceChangedFrom={onPlaceChangedFrom}
            onLoadFrom={onLoadFrom}
            onPlaceChangedTo={onPlaceChangedTo}
            onLoadTo={onLoadTo}
            renderDistance={renderDistance}
            renderTrippetOwner={renderTrippetOwner}
            tripTotalDays={tripTotalDays}
            startTrippetDay={startTrippetDay}
            endTrippetDay={endTrippetDay}
            handleParticipantsModalOpen={handleParticipantsModalOpen}
            handleResponsibleToModalOpen={handleResponsibleToModalOpen}
            renderParticipants={renderParticipants}
            handleRefresh={handleRefresh}
            isEditMode={isEditMode}
            disabledRefreshButton={disabledRefreshButton}
            renderDistanceIcon={renderDistanceIcon}
            renderTripNameErrorMessage={renderTripNameErrorMessage}
            renderTypeErrorMessage={renderTypeErrorMessage}
            isAllDay={isAllDay}
            handleIsAllDayCheckBox={handleIsAllDayCheckBox}
            disabledFromField={disabledFromField}
            showTimeZones={showTimeZones}
            handleStartDateCalendar={handleStartDateCalendar}
            renderTrippetTypes={renderTrippetTypes}
            renderTrippetStatus={renderTrippetStatus}
            renderResponsible={renderResponsible}
            handleInvitationalModalOpen={handleInvitationalModalOpen}
            tripOwner={tripOwner}
            isFromFieldLeft={isFromFieldLeft}
            handleSwapDestinationFields={handleSwapDestinationFields}
            parentsTrippet={parentsTrippet}
            getTheParentName={getTheParentName}
          />
          {showBigForm}
        </Grid>
      </DialogContent>
      <DialogActions className={renderBorderTop}>
        <div className={classes.actionInformation}>
          <div>
            <span>{t("Sender")}</span>:{" "}
            {tripOwner && `${tripOwner.FirstName} ${tripOwner.LastName}`}
          </div>
          <div>
            <span>{t("Part of Trip")}</span>:{" "}
            {currentTrip && currentTrip.tripName}
          </div>
        </div>
        {renderExpandIcon}
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={showTimeZones}
              onChange={handleCheckBoxTimeZones}
              name="showTimeZone"
              color="primary"
            />
          }
          label={t('Show Time Zones')}
        /> */}
        {renderIsPrivate()}
        <Button autoFocus onClick={handleConfirmModalOpen} color="primary">
          {t("Exit")}
        </Button>
        <Button
          onClick={handleSendAsRecommendation}
          color="primary"
          variant="contained"
          disabled={!currentTrippet}
        >
          {t("Send as Recommendation")}
        </Button>
        <Button
          color="primary"
          autoFocus
          variant="contained"
          onClick={handleSubmit}
        >
          {t("Save and Share")}
        </Button>
      </DialogActions>
      <ParticipantsModal
        open={isParticipantsModalOpen}
        handleClose={handleParticipantsModalClose}
        handleSaveParticipants={handleSaveParticipants}
        allUsers={allUsers}
        participants={values.participants}
        trippet={true}
      />
      <ResponsibleModal
        open={isResponsibleModalOpen}
        handleClose={handleResponsibleToModalClose}
        handleSaveParticipants={handleSaveResponsible}
        allUsers={allUsers}
        participants={values.responsible}
      />
      <RecommendationModal
        open={isRecommendationModalOpen}
        handleClose={handleRecommendationModalClose}
        allUsers={allUsers}
        trippet={trippetRecommendationInfo}
      />
      <InvitationalModal
        open={isInvitationalModalOpen}
        handleClose={handleInvitationalModalClose}
        allUsers={allUsers}
        trippet
        trip
        handleSend={handleSaveExternalUsersEmail}
      />
      <UploadPictureModal
        open={isUploadPictureModalOpen}
        handleClose={handleUploadPictureModalClose}
        getUploadParams={getUploadParams}
        handleChangeStatus={handleChangeStatusPictures}
        classes={classes}
      />
      {selectedImage && isPreviewModalImageOpen && (
        <PreviewModalImage
          open={isPreviewModalImageOpen}
          handleClose={handlePreviewModalImageModalClose}
          picture={selectedImage}
          classes={classes}
          handleImageComments={handleImageComments}
          defaultPicture={defaultPicture}
        />
      )}
    </div>
  );
};

export default TrippetForm;
