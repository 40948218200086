import React, { useState, useEffect } from "react";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Navigation from "../../components/Navigation/Navigation";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Logo from "../../assets/TP-logo-250px.png";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import NavigationMenu from "../../components/NavigationMenu/NavigationMenu";
import IconButton from "@material-ui/core/IconButton";
import FeatureMenu from "../../components/FeatureMenu/FeatureMenu";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import {
  getAllTrips,
  getAllTripRecommendations,
  getTotalTripRecommendationsNumber,
  saveTripUsedFilters,
} from "../../store/actions/actions/trip";
import {
  getAllTrippets,
  getTrippetTypes,
  getTrippetStatus,
  getAllTrippetPictures,
  getAllTrippetDocuments,
  getTotalTrippetRecommendationsNumber,
  getTrippetCategory,
  saveTrippetUsedFilters,
} from "../../store/actions/actions/trippet";
import {
  getUserRoles,
  getAllUsers,
  getUserDepartments,
  getAllCountries,
  getAllCurrencies,
  getCustomerLogo,
} from "../../store/actions/actions/root";
import { getUserNotification } from "../../store/actions/actions/notifications";
import { BASE_URL } from "../../config";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import uniqid from "uniqid";

const withDashboard = Component => props => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [openFeatureMenu, setOpenFeatureMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [nonSeenNotification, setNonSeenNotification] = useState(0);
  const user = useSelector(state => state.user.user);
  const customerLogo = useSelector(state => state.app.customerLogo);
  const profileImage = useSelector(state => state.user.user.Picture);
  const usedFilters = useSelector(state => state.trip.usedFilters);
  const currentTrip = useSelector(state => state.trip.currentTrip);
  const sortElementTrip = useSelector(state => state.trip.sortElement);
  const sortByDirectionTrip = useSelector(state => state.trip.sortByDirection);
  const interval = useSelector(state => state.notification.interval);
  const allNotifications = useSelector(
    state => state.notification.notifications,
  );
  const sortElementMyTrippets = useSelector(
    state => state.trippet.sortElementMyTrippets,
  );
  const sortByDirectionMyTrippets = useSelector(
    state => state.trippet.sortByDirectionMyTrippets,
  );
  const usedTrippetFilters = useSelector(state => state.trippet.usedFilters);
  const profilePictureURL = useSelector(
    state => state.profile.profilePictureURL,
  );
  const notificationSeen = useSelector(state => state.notification.seen);
  const notificationTripName = useSelector(
    state => state.notification.tripName,
  );
  const notificationTrippetName = useSelector(
    state => state.notification.trippetName,
  );
  const notificationCreator = useSelector(state => state.notification.creator);
  const notificationStartDate = useSelector(
    state => state.notification.startDate,
  );
  const notificationEndDate = useSelector(state => state.notification.endDate);
  const [anchorElNotification, setAnchorElNotitication] = useState(null);
  const [lastThreeNotification, setLastThreeNotification] = useState(null);
  const [customerLogoUrl, setCustomerLogoUrl] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (allNotifications && allNotifications.length > 0) {
      let notifications = [...allNotifications];
      let lastThree = notifications.splice(0, 3);
      setLastThreeNotification(lastThree);
    }
  }, [allNotifications]);

  useEffect(() => {
    if (currentTrip && currentTrip.Name && user) {
      let body = {
        Trip_ID: currentTrip.Trip_ID,
        Language_ID: user.Language_ID,
      };
      dispatch(getTrippetTypes(JSON.stringify(body)));
      dispatch(getTrippetStatus(JSON.stringify(body)));
      dispatch(getTrippetCategory(JSON.stringify(body)));
      // dispatch(getTrippetCategory());
      // dispatch(getTrippetTypes());
    }
  }, [currentTrip, dispatch, user]);

  useEffect(() => {
    if (user && interval) {
      let body = {
        User_ID: user.User_ID,
        OrderBy: "LogDate",
        OrderDir: "desc",
        Seen: notificationSeen,
        Trip_ID: notificationTripName,
        Trippet_ID: notificationTrippetName,
        LoggerUser_ID: notificationCreator,
        LogDateStart: notificationStartDate,
        LogDateEnd: notificationEndDate,
      };
      dispatch(getUserNotification(JSON.stringify(body)));
    }
  }, [
    user,
    interval,
    dispatch,
    notificationSeen,
    notificationTrippetName,
    notificationCreator,
    notificationStartDate,
    notificationTripName,
    notificationEndDate,
  ]);

  useEffect(() => {
    if (user && interval) {
      let id = setInterval(() => {
        let body = {
          User_ID: user.User_ID,
          OrderBy: "LogDate",
          OrderDir: "desc",
          Seen: notificationSeen,
          Trip_ID: notificationTripName,
          Trippet_ID: notificationTrippetName,
          LoggerUser_ID: notificationCreator,
          LogDateStart: notificationStartDate,
          LogDateEnd: notificationEndDate,
        };
        dispatch(getUserNotification(JSON.stringify(body)));
      }, interval);
      return () => clearInterval(id);
    }
  }, [
    user,
    interval,
    dispatch,
    notificationSeen,
    notificationTrippetName,
    notificationCreator,
    notificationStartDate,
    notificationTripName,
    notificationEndDate,
  ]);

  useEffect(() => {
    if (allNotifications && allNotifications.length > 0) {
      let number = 0;
      allNotifications.forEach(notification => {
        if (notification.Seen === "0") {
          number = number + 1;
        }
      });
      setNonSeenNotification(number);
    }
  }, [allNotifications]);

  useEffect(() => {
    if (user) {
      let localStorageLanguageID = localStorage.getItem("language-TP");
      if (!localStorageLanguageID) {
        localStorage.setItem("language-TP", user.Language_ID);
      }
      dispatch(getUserRoles());
      dispatch(getUserDepartments());
      dispatch(getAllCountries());
      dispatch(getAllCurrencies());
      // get all information regarding all trips
      // const sentInvitationBody = { uid: user.User_ID, type: 'getinv' };
      const bodyTrips = {
        User_ID: user.User_ID,
        Type: 2,
        Page: 1,
        RowsPerPage: 10,
        OrderBy: sortElementTrip,
        OrderDir: sortByDirectionTrip[sortElementTrip],
        Language_ID: user.Language_ID,
        IsDeleted: "0",
      };
      if (usedFilters && usedFilters.length > 0) {
        usedFilters.forEach(filter => {
          bodyTrips[filter.name] = filter.body;
        });
      }
      dispatch(getAllTrips(JSON.stringify(bodyTrips)));
      const body = { TripRecommendationRecipientUser_ID: user.User_ID };
      dispatch(getAllTripRecommendations(JSON.stringify(body)));
      const totalNumberOfTripRecommendations = { User_ID: user.User_ID };
      dispatch(
        getTotalTripRecommendationsNumber(
          JSON.stringify(totalNumberOfTripRecommendations),
        ),
      );

      // get all information regarding all trippets
      const allTrippetsBody = {
        Language_ID: user.Language_ID,
        Type: 2,
        User_ID: user.User_ID,
        OrderBy: sortElementMyTrippets,
        OrderDir: sortByDirectionMyTrippets[sortElementMyTrippets],
        Page: 1,
        RowsPerPage: 10,
      };
      if (usedTrippetFilters && usedTrippetFilters.length > 0) {
        usedTrippetFilters.forEach(filter => {
          allTrippetsBody[filter.name] = filter.body;
        });
      }
      dispatch(getAllTrippets(JSON.stringify(allTrippetsBody)));
      dispatch(getAllTrippetPictures());
      dispatch(getAllTrippetDocuments());
      const totalNumberOfTrippetRecommendations = { User_ID: user.User_ID };

      dispatch(
        getTotalTrippetRecommendationsNumber(
          JSON.stringify(totalNumberOfTrippetRecommendations),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    user.User_ID,
    usedFilters,
    sortElementMyTrippets,
    sortByDirectionMyTrippets,
    sortByDirectionTrip,
    sortElementTrip,
  ]);

  useEffect(() => {
    if (user) {
      dispatch(getCustomerLogo());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (
      customerLogo &&
      customerLogo.Values &&
      customerLogo.Values.length > 0 &&
      user
    ) {
      customerLogo.Values.forEach(logo => {
        if (logo.Language_ID === user.Language_ID) {
          let path = `${BASE_URL}${logo.Value.substr(1)}`;
          setCustomerLogoUrl(path);
        }
      });
    }
  }, [customerLogo, user]);

  useEffect(() => {
    const body = {
      type: "getall",
    };
    dispatch(getAllUsers(JSON.stringify(body)));
  }, [dispatch]);

  const handleClickDownDropDownMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDownDropDownMenu = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleFeatureMenuOpen = () => {
    setOpenFeatureMenu(true);
  };

  const handleFeatureMenuClose = () => {
    setOpenFeatureMenu(false);
  };

  const handleClickNotification = () => {
    history.push("/notifications");
  };

  const handleClickNotificationIcon = event => {
    setAnchorElNotitication(event.currentTarget);
  };

  const handleCloseNotificationMenu = () => {
    setAnchorElNotitication(null);
  };

  const userAvatar = user ? `${user.Initials}` : <PersonRoundedIcon />;

  const renderProfilePicture =
    profileImage && profileImage ? (
      <img
        src={profileImage}
        alt="profile"
        className={classes.profilePicture}
      />
    ) : profilePictureURL ? (
      <img
        src={`${BASE_URL}${profilePictureURL}`}
        alt="profile"
        className={classes.profilePicture}
      />
    ) : (
      userAvatar
    );

  const renderUserName = user ? `${user.DisplayName}` : "";

  const renderCustomerLogo = customerLogoUrl ? (
    <img src={customerLogoUrl} alt="Customer Logo" />
  ) : null;

  const renderThreeNotification = () => {
    if (lastThreeNotification && lastThreeNotification.length > 0) {
      return lastThreeNotification.map(notification => {
        const { TripName, TrippetTitle, RefTable } = notification;
        const renderNotificationIcon =
          RefTable && RefTable === "1" ? (
            <BeachAccessIcon />
          ) : (
            <AccountTreeIcon />
          );
        const renderTitle = TrippetTitle ? TrippetTitle : TripName;
        return (
          <MenuItem
            key={uniqid()}
            className={classes.singleNotification}
            onClick={handleClickNotification}
          >
            {" "}
            {renderNotificationIcon} <div>{renderTitle}</div>
          </MenuItem>
        );
      });
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavigationMenu
        anchorEl={anchorEl}
        handleClose={handleCloseDownDropDownMenu}
      />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <Link
            to="/"
            className={classes.logo}
            onClick={() => {
              dispatch(saveTrippetUsedFilters(null));
              dispatch(saveTripUsedFilters(null));
            }}
          >
            <img src={Logo} alt="Trip Perfect Logo" />
          </Link>
          <div className={classes.userInfo}>
            <div className={classes.customerLogo}>{renderCustomerLogo}</div>
            <div className={classes.notification}>
              <IconButton
                onClick={handleClickNotificationIcon}
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                <Badge badgeContent={nonSeenNotification} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorElNotification}
                keepMounted
                open={Boolean(anchorElNotification)}
                onClose={handleCloseNotificationMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                {renderThreeNotification()}
                <MenuItem
                  className={classes.showMoreBtn}
                  onClick={handleClickNotification}
                >
                  <span color="primary">{t("Show More")}</span>
                </MenuItem>
              </Menu>
            </div>
            <IconButton onClick={handleClickDownDropDownMenu}>
              <Avatar aria-controls="simple-menu" aria-haspopup="true">
                {renderProfilePicture}
              </Avatar>
            </IconButton>
            <span>{renderUserName}</span>
          </div>
        </Toolbar>
      </AppBar>
      <Navigation
        open={open}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      />
      <FeatureMenu
        open={openFeatureMenu}
        handleDrawerClose={handleFeatureMenuClose}
        handleDrawerOpen={handleFeatureMenuOpen}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Component {...props} />
      </main>
    </div>
  );
};

export default withDashboard;
