import React, { useEffect, useState, Fragment } from "react";
import useStyle from "./styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterTrips,
  saveFilterTrip_ID
} from "../../store/actions/actions/trippetTypeCategory";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';

const TripFilter = ({openTripFilter, setIsOpen, setSelectedTrip}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const filterTrips = useSelector(state => state.trippetTypeCategory.filterTrips);
  const filterTrip_ID = useSelector(state => state.trippetTypeCategory.filterTrip_ID);
  
  const [filterResults, setFilterResults] = useState([]);
  useEffect(() => {
      dispatch(getFilterTrips(null));
  }, [dispatch]);
  
  useEffect(() => {
    let array = [];
    //array.push({Trip_ID: -1, TripName: 'all'});
    if (filterTrips && filterTrips.length > 0) {
        filterTrips.forEach(trip => {
        /*if (search)
        {
          if ( trip.Name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
            array.push(trip);
        }
        else*/
            array.push(trip);
      });
      setFilterResults(array);
    } else {
        setFilterResults([]);
    }
  }, [filterTrips, dispatch]);  
  
  useEffect(() => {
    if (filterTrips && filterTrips.length > 0) {
        filterTrips.forEach(trip => {
            if (parseInt(trip.Trip_ID) === parseInt(filterTrip_ID))
                setData(trip);
      });
    } else {
        setData([]);
    }
  }, [filterTrips, filterTrip_ID]);
  
  const handleCancelClick = () => {
    setIsOpen(false);
  };  
  
  const handleOkClick = () => {
    if (data)
        dispatch(saveFilterTrip_ID(data.Trip_ID));
    else
        dispatch(saveFilterTrip_ID(-1));
    setIsOpen(false);
  };

  const renderOptions = option => {
    if (option && option.TripName)
        return `${option.TripName}`;
    if (option && option.hasOwnProperty('TripName'))
        return "Default";
    return "";
  };
  const [data, setData] = useState([]);
  const handleChange = (event, values) => {
    setData(values);
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={openTripFilter}
        aria-labelledby="responsive-dialog-title"
        styles={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modalTitle}
        >
          {'Trip Filter'}
        </DialogTitle>
        <DialogContent>
          <Grid container justify="center" className={classes.containerMyTrip}>
            <Grid item xs={12}>
            <Autocomplete
                multiple={false}
                id="checkboxes-tags-demo"
                options={filterResults}
                disableCloseOnSelect
                onChange={handleChange}
                value={data}
                getOptionLabel={option => renderOptions(option)}
                renderOption={(option, { selected }) => (
                <Fragment>
                    {renderOptions(option)}
                </Fragment>
                )}
                style={{ width: 500 }}
                renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={t('Filter')}
                    placeholder={t('Filter')}
                />
                )}
            />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleOkClick} color="primary">
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    
  );
};

export default TripFilter;
