import * as adminActionTypes from '../actionTypes/adminActionTypes';
import { BASE_URL, ROWS_PER_PAGE } from '../../../config';
import { showSnackBar } from './root';
import {store} from '../../store';

export const saveCurrentVerificationCode = payload => ({
  type: adminActionTypes.SAVE_CURRENT_VERIFICATIONCODE,
  payload,
});

export const saveCurrentPage = payload => ({
  type: adminActionTypes.SAVE_VERIFICATIONCODE_CURRENT_PAGE,
  payload,
});

export const getVerificationCodeById = (id) => {
  return dispatch => {
    if (id) {
      fetch(`${BASE_URL}api/verification/get.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentVerificationCode(data));
        })
        .catch(error => console.log(error));
    }
  };
};

export const createNewVerificationCode = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/verification/create.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 201) {
            return response.text();
          } else if (response.status === 301) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'The email is already used!',
              }),
            );
          } else if (response.status === 302) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503 && response.type !== 'cors') {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          dispatch(saveCurrentVerificationCode(data));
          dispatch(getAllVerificationCodes());
        })
        .catch(error => console.log(error));
    }
  };
};

export const changeVerificationCode = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/verification/update.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getVerificationCodeById(body.VerificationCode_ID));
          dispatch(getAllVerificationCodes(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const checkVerificationCode = (body, history, t) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/verification/check.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 301 || response.status === 500 || response.status === 400 || response.status === 503 || response.status === 404) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: t('Code does not exist'),
              }),
            );
          }
        })
        .then((response) => {
          let data = JSON.parse(response);
          localStorage.setItem('verificationCode', data.VerificationCode);
          history.push('/login')
        })
        .catch(error => console.log(error));
    }
  };
};

export const saveAllVerificationCodes = payload => ({
  type: adminActionTypes.SAVE_ALL_VERIFICATIONCODES,
  payload,
});

export const saveVerificationCodeSortElement = payload => ({
  type: adminActionTypes.SAVE_VERIFICATIONCODE_SORT_ELEMENT,
  payload,
});

export const saveVerificationCodeSortByDirection = payload => ({
  type: adminActionTypes.SAVE_VERIFICATIONCODE_SORT_BY_DIRECTION,
  payload,
});

export const saveVerificationCodePaginationData = payload => ({
  type: adminActionTypes.SAVE_VERIFICATIONCODE_PAGINATION_DATA,
  payload,
});

export const getAllVerificationCodes = body => {
  return dispatch => {
    if (!body)
    {
        const searchBody = {
            RowsPerPage: ROWS_PER_PAGE,
            Page: store.getState().verificationCode.currentPage,
            OrderBy: store.getState().verificationCode.sortElement,
            OrderDir: store.getState().verificationCode.sortByDirection
        };
        body = JSON.stringify(searchBody);
    }
    fetch(`${BASE_URL}api/verification/search.php`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 304) {
          dispatch(
            showSnackBar({
              type: 'error',
              message: 'Something went wrong!',
            }),
          );
        }
      })
      .then(response => {
        const data = JSON.parse(response);
        dispatch(saveAllVerificationCodes(data.Data));
        dispatch(saveVerificationCodePaginationData(data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteVerificationCode = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/verification/delete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        })
        .then(response => {
          dispatch(getAllVerificationCodes(null));
        })
        .catch(error => console.log(error));
    }
  };
};

export const forceDeleteVerificationCode = (body) => {
  return dispatch => {
    if (body) {
      fetch(`${BASE_URL}api/verification/forcedelete.php`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 304) {
            dispatch(
              showSnackBar({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          } else if (response.status === 503) {
            return response.text();
          }
        })
        .then(response => {
          const data = JSON.parse(response);
          if (data.ErrorCode)
            dispatch(
              showSnackBar({
                type: 'error',
                message: "Something went wrong!\n" + data.ErrorMsg,
              }),
            );
          else
            dispatch(getAllVerificationCodes(null));
        })
        .catch(error => console.log(error));
    }
  };
};
