import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allVerificationCodes: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'CustomerName',
  sortByDirection: 'asc',
  // since we don't edit all fields, all fields are not listed (like LastTripID)
  currentVerificationCode: {
    VerificationCode_ID: 0,
    CustomerName: '',
    VerificationCode: '',
  },
};

const verificationCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_VERIFICATIONCODE: {
      return {
        ...state,
        currentVerificationCode: {
            VerificationCode_ID: action.payload.VerificationCode_ID,
            CustomerName: action.payload.CustomerName,
            VerificationCode: action.payload.VerificationCode,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_VERIFICATIONCODES: {
      return {
        ...state,
        allVerificationCodes: action.payload,
      };
    }
    case adminActionTypes.SAVE_VERIFICATIONCODE_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_VERIFICATIONCODE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_VERIFICATIONCODE_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_VERIFICATIONCODE_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        verificationCode: '',
        allVerificationCodes: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'CustomerName',
        sortByDirection: 'asc',
        currentVerificationCode: {
          VerificationCode_ID: 0,
          CustomerName: '',
          VerificationCode: '',
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default verificationCodeReducer;
