import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allDepartments: null,
  entireDepartments: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'Name',
  sortByDirection: 'asc',
  currentDepartment: {
    Department_ID: 0,
    ParentDepartment_ID: null,
    Name: '',
    Acronym: '',
    ParentDepartmentName: '',
  },
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_DEPARTMENT: {
      return {
        ...state,
        currentDepartment: {
            Department_ID: action.payload.Department_ID,
            ParentDepartment_ID: action.payload.ParentDepartment_ID,
            Acronym: action.payload.Acronym,
            Name: action.payload.Name,
            ParentDepartmentName: action.payload.ParentDepartmentName,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_DEPARTMENTS: {
      return {
        ...state,
        allDepartments: action.payload,
      };
    }
    case adminActionTypes.SAVE_ENTIRE_DEPARTMENTS: {
      return {
        ...state,
        entireDepartments: action.payload,
      };
    }
    case adminActionTypes.SAVE_DEPARTMENT_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_DEPARTMENT_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_DEPARTMENT_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_DEPARTMENT_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allDepartments: null,
        entireDepartments: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentDepartment: {
            Department_ID: 0,
            ParentDepartment_ID: null,
            Acronym: '',
            Name: '',
            ParentDepartmentName: ''
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default departmentReducer;