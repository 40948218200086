import * as actionTypes from '../actions/actionTypes/actionTypes';
import * as adminActionTypes from '../actions/actionTypes/adminActionTypes';

const initialState = {
  allCurrencies: null,
  paginationData: null,
  currentPage: 1,
  sortElement: 'Name',
  sortByDirection: 'asc',
  currentCurrency: {
    Currency_ID: 0,
    Code: '',
    Symbol: '',
    Names: []
  },
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SAVE_CURRENT_CURRENCY: {
      return {
        ...state,
        currentCurrency: {
            Currency_ID: action.payload.Currency_ID,
            Code: action.payload.Code,
            Symbol: action.payload.Symbol,
            Names: action.payload.Names,
        },
      };
    }
    case adminActionTypes.SAVE_ALL_CURRENCIES: {
      return {
        ...state,
        allCurrencies: action.payload,
      };
    }
    case adminActionTypes.SAVE_CURRENCY_PAGINATION_DATA: {
      return {
        ...state,
        paginationData: action.payload,
      };
    }
    case adminActionTypes.SAVE_CURRENCY_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case adminActionTypes.SAVE_CURRENCY_SORT_ELEMENT: {
      return {
        ...state,
        sortElement: action.payload,
      };
    }
    case adminActionTypes.SAVE_CURRENCY_SORT_BY_DIRECTION: {
      return {
        ...state,
        sortByDirection: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        allCurrencies: null,
        paginationData: null,
        currentPage: 1,
        sortElement: 'Name',
        sortByDirection: 'asc',
        currentCurrency: {
            Currency_ID: 0,
            Code: '',
            Symbol: '',
            Names: []
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default currencyReducer;